import * as API from '@/services/API'
import store from '@/store'

export default {
    getPvs() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/pvs`)
    },
    getPv(pv_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/pvs/${pv_id}`)
    },
    updateBesoin(pv_id: number, poste_id: number, entity_id: number, besoin: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/pvs/${pv_id}`, {
            poste_id: poste_id,
            entity_id: entity_id,
            besoin: besoin
        })
    },
    updatePv(pv_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/pvs/${pv_id}`, payload)
    },
    getBesoin(pv_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/pvs/${pv_id}/besoins`)
    },
    getPDF(pv_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/pvs/${pv_id}/pdf?format=b64`)
    },
    getBlocCalendrier(pv_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/pvs/${pv_id}/pdfcalendrier`,
            {
                headers: { 'X-Request-Retry': 0 }
            }
        )
    },
    getBlocNouvelleCandidature(pv_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/pvs/${pv_id}/pdfnouvellecandidature`)
    },
    getBlocNonReconduction(pv_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/pvs/${pv_id}/pdfnonreconduction`)
    },
    getBlocBesoin(pv_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/pvs/${pv_id}/pdfbesoin`)
    },
    getBlocAnnexe(pv_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/pvs/${pv_id}/pdfannexe`)
    }
}
