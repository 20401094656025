import { getError } from '@/utils/helpers'
import PhaseService from '@/services/PhaseService'
import { PhaseInterface, StatePhases } from '@/types/Phase'

export const namespaced = true

function setPhases(commit: Function, response: any) {
    commit('SET_MATIERES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StatePhases = {
    phases: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    phaseSelect: {}
}

export const mutations = {
    RESET_STATE(state: StatePhases) {
        Object.assign(state, {
            phases: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            phaseSelect: {}
        })
    },
    SET_MATIERES(state: any, phases: Array<PhaseInterface>) {
        state.phases = phases
    },
    SET_MORE_MATIERES(state: any, phases: Array<PhaseInterface>) {
        state.phases = state.phases.concat(phases)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_MATIERE_SELECT(state: any, phase: PhaseInterface) {
        state.phaseSelect = phase
    }
}

export const actions = {
    getPhases({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PhaseService.getPhases(params)
                .then((response) => {
                    setPhases(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPhase({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PhaseService.getPhase(params.phase_id)
                .then((response) => {
                    commit('SET_MATIERE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMorePhases({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PhaseService.getPhase(params.phase_id)
                .then((response) => {
                    commit('SET_MATIERE_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updatePhase({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PhaseService.updatePhase(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addPhase({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PhaseService.addPhase(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deletePhase({ commit }: { commit: any }, phase_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PhaseService.deletePhase(phase_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    phases: (state: any) => {
        return state.phases
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.phases.length
    },
    phaseSelect: (state: any) => {
        return state.phaseSelect
    },
    getPhaseById: (state: any) => (id: number) => {
        const phase = state.phases.filter((mat: any) => mat.id === id)[0]
        return phase
    },
    getCodePhase: (state: any) => (id: number) => {
        const phase = state.phases.filter((mat: any) => mat.id === id)[0]
        if (phase) {
            return phase.code
        } else {
            return 'NR'
        }
    },
    getPhasesObs: (state: any) => (liste_phase_obs: any) => {
        const tab_phases: Array<any> = []
        for (const mo in liste_phase_obs) {
            if (liste_phase_obs[mo].entity_id) {
                const phase_temp = state.phases.find((m: any) => m.id === liste_phase_obs[mo].entity_id)
                if (phase_temp) {
                    tab_phases.push(phase_temp)
                }
            }
        }

        return tab_phases
    }
}
