import { getError } from '@/utils/helpers'
import AffectationCorrecteurService from '@/services/AffectationCorrecteurService'
import { AffectationCorrecteurInterface, StateAffectationCorrecteurs } from '@/types/AffectationCorrecteur'

export const namespaced = true

function setAffectationCorrecteurs(commit: Function, response: any) {
    commit('SET_AFFECTATION_CORRECTEURS', response.data)
    commit('SET_META', response.meta)
    commit('SET_LINKS', response.links)
    commit('SET_TOTALROWS', response.data.length)
    // groupSeries(commit, response.data.data)
    commit('SET_LOADING', false)
}

export const state: StateAffectationCorrecteurs = {
    affectationCorrecteurs: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    affectationCorrecteurSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateAffectationCorrecteurs) {
        Object.assign(state, {
            affectationCorrecteurs: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            affectationCorrecteurSelect: {}
        })
    },
    SET_AFFECTATION_CORRECTEURS(
        state: StateAffectationCorrecteurs,
        affectationCorrecteurs: Array<AffectationCorrecteurInterface>
    ) {
        state.affectationCorrecteurs = affectationCorrecteurs
    },
    SET_MORE_AFFECTATION_CORRECTEURS(state: any, affectationCorrecteurs: Array<AffectationCorrecteurInterface>) {
        state.affectationCorrecteurs = state.affectationCorrecteurs.concat(affectationCorrecteurs)
    },
    SET_META(state: StateAffectationCorrecteurs, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: StateAffectationCorrecteurs, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: StateAffectationCorrecteurs, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: StateAffectationCorrecteurs, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: StateAffectationCorrecteurs, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_AFFECTATION_CORRECTEUR_SELECT(
        state: StateAffectationCorrecteurs,
        affectationCorrecteur: AffectationCorrecteurInterface
    ) {
        state.affectationCorrecteurSelect = affectationCorrecteur
    },
    /**
     * met a jours les salles id des affectations
     * @param state
     * @param affectationCorrecteurs tableau retourné par l'update sur le serveur (incomplet d'ou la mise a jours des donnée du store plutot qu'un remplacement)
     */
    UPDATE_AFFECTATION_CORRECTEURS(
        state: StateAffectationCorrecteurs,
        affectationCorrecteurs: Array<AffectationCorrecteurInterface>
    ) {
        for (let i = 0; i < state.affectationCorrecteurs.length; i++) {
            for (let j = 0; j < affectationCorrecteurs.length; j++) {
                if (state.affectationCorrecteurs[i].id === affectationCorrecteurs[j].id) {
                    state.affectationCorrecteurs[i].salle_id = affectationCorrecteurs[j].salle_id
                }
            }
        }
    }
}

export const actions = {
    getAffectationCorrecteurs({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationCorrecteurService.getAffectationCorrecteurs(params)
                .then((response) => {
                    setAffectationCorrecteurs(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getAffectationCorrecteur({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationCorrecteurService.getAffectationCorrecteur(params.affectationCorrecteur_id)
                .then((response) => {
                    commit('SET_AFFECTATION_CORRECTEUR_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreAffectationCorrecteurs({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationCorrecteurService.getAffectationCorrecteur(params.affectationCorrecteur_id)
                .then((response) => {
                    commit('SET_AFFECTATION_CORRECTEUR_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getReclamationCorrecteurs({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationCorrecteurService.getReclamationCorrecteurs(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    synchViatique({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationCorrecteurService.synchViatique()
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addAffectationCorrecteur({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationCorrecteurService.addAffectationCorrecteur(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteAffectationCorrecteur({ commit }: { commit: any }, affectationCorrecteur_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationCorrecteurService.deleteAffectationCorrecteur(affectationCorrecteur_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    affectationCorrecteurs: (state: StateAffectationCorrecteurs) => {
        return state.affectationCorrecteurs
    },
    meta: (state: StateAffectationCorrecteurs) => {
        return state.meta
    },
    links: (state: StateAffectationCorrecteurs) => {
        return state.links
    },
    loading: (state: StateAffectationCorrecteurs) => {
        return state.loading
    },
    error: (state: StateAffectationCorrecteurs) => {
        return state.error
    },
    totalRows: (state: StateAffectationCorrecteurs) => {
        return state.affectationCorrecteurs.length
    },
    affectationCorrecteurSelect: (state: StateAffectationCorrecteurs) => {
        return state.affectationCorrecteurSelect
    }
}
