import { getError } from '@/utils/helpers'
import AdresseCentreService from '@/services/AdresseCentreService'
import { AdresseCentreInterface, StateAdresseCentres } from '@/types/AdresseCentre'

export const namespaced = true

function setAdresseCentres(commit: Function, response: any) {
    commit('SET_CENTRES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_LOADING', false)
}

export const state: StateAdresseCentres = {
    adresseCentres: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    adresseCentreSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateAdresseCentres) {
        Object.assign(state, {
            adresseCentres: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            adresseCentreSelect: {}
        })
    },
    SET_CENTRES(state: any, adresseCentres: Array<AdresseCentreInterface>) {
        state.adresseCentres = adresseCentres
    },
    SET_MORE_CENTRES(state: any, adresseCentres: Array<AdresseCentreInterface>) {
        state.adresseCentres = state.adresseCentres.concat(adresseCentres)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CENTRE_SELECT(state: any, adresseCentre: AdresseCentreInterface) {
        state.adresseCentreSelect = adresseCentre
    }
}

export const actions = {
    getAdresseCentres({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AdresseCentreService.getAdresseCentres(params)
                .then((response) => {
                    setAdresseCentres(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getAdresseCentre({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AdresseCentreService.getAdresseCentre(params.adresseCentre_id)
                .then((response) => {
                    commit('SET_CENTRE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreAdresseCentres({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AdresseCentreService.getAdresseCentres(params)
                .then((response) => {
                    commit('SET_MORE_CENTRES', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateAdresseCentre({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AdresseCentreService.updateAdresseCentre(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addAdresseCentre({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AdresseCentreService.addAdresseCentre(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteAdresseCentre({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AdresseCentreService.deleteAdresseCentre(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    adresseCentres: (state: any) => {
        return state.adresseCentres
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.adresseCentres.length
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    adresseCentreSelect: (state: any) => {
        return state.adresseCentreSelect
    }
}
