import * as API from '@/services/API'
import store from '@/store'
import { AxiosRequestConfig } from 'axios'

export default {
    getDossierAdministratif(dossierId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/dossieradministratifs/${dossierId}`)
    },
    getDossiersAdministratifs(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/dossieradministratifs`, { params: payload })
    },
    updateDossierAdministratif(dossierId: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/dossieradministratifs/${dossierId}`, payload)
    },
    getAvatar(dossierId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/dossieradministratifs/${dossierId}/photo`)
    },
    storeAvatar(dossierId: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/dossieradministratifs/${dossierId}/photo`, payload)
    },
    storeCodeIntervenant(dossierId: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/dossieradministratifs/${dossierId}/user_code`, payload)
    },
    storeContrat(dossierId: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/dossieradministratifs/${dossierId}/contrats`, payload)
    },
    getContrats(dossierId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/dossieradministratifs/${dossierId}/contrats`)
    },
    getContrat(dossierId: number, contratId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/dossieradministratifs/${dossierId}/contrats/${contratId}`)
    },
    updateContrat(dossierId: number, contratId: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(
            `/sessions/${session_id}/dossieradministratifs/${dossierId}/contrats/${contratId}`,
            payload
        )
    },
    getPDF(dossierId: number, contratId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/dossieradministratifs/${dossierId}/contrats/${contratId}/pdf?format=b64`)
    },
    getDossierPDF(dossierId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/dossieradministratifs/${dossierId}/pdf?format=b64`,
            {headers: { 'X-Request-Retry': 0 }}
        )
    },
    deleteContrat(dossierId: number, contratId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/dossieradministratifs/${dossierId}/contrats/${contratId}`)
    },
    getTokenSignature(dossierId: number, contratId: number, mode: string) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/dossieradministratifs/${dossierId}/contrats/${contratId}/token_signature?mode=${mode}`
        )
    },
    getDossierAdministratifOuvert(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/dossieradministratifs/open`, { params: payload })
    },
    ouvrirDossierAdministratif(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const searchParams = new URLSearchParams(payload)
        return API.apiClient.post(`/sessions/${session_id}/dossieradministratifs/open?${searchParams.toString()}`)
    },
    relaunchDossierAdministratif(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const searchParams = new URLSearchParams(payload)
        return API.apiClient.post(`/sessions/${session_id}/dossieradministratifs/relaunch?${searchParams.toString()}`)
    },
    relaunchContratDossierAdministratif(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const searchParams = new URLSearchParams(payload)
        return API.apiClient.post(
            `/sessions/${session_id}/dossieradministratifs/relaunch_contrat?${searchParams.toString()}`
        )
    },
    relaunchOrdreMissionDossierAdministratif(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/dossieradministratifs/relaunch_mission`, payload)
    },
    getPostesAffectationsSansContrat(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const searchParams = new URLSearchParams(payload)
        return API.apiClient.get(
            `/sessions/${session_id}/dossieradministratifs_createcontrat_count?${searchParams.toString()}`
        )
    },
    storeMultiContrats(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/dossieradministratifs/contrats`, payload)
    },
    getDocumentsContrat(dossierId: number, contratId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/dossieradministratifs/${dossierId}/contrats/${contratId}/documents`
        )
    },
    uploadDocumentContrat(dossierId: number, contratId: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(
            `/sessions/${session_id}/dossieradministratifs/${dossierId}/contrats/${contratId}/documents`,
            payload
        )
    },
    getPdfDocumentContrat(dossierId: number, contratId: number, documentId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/dossieradministratifs/${dossierId}/contrats/${contratId}/documents/${documentId}?format=b64`)
    },
    deleteDocumentContrat(dossierId: number, contratId: number, documentId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(
            `/sessions/${session_id}/dossieradministratifs/${dossierId}/contrats/${contratId}/documents/${documentId}`
        )
    },
    uploadArchive(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/dossieradministratifs/contrats/documents`, payload)
    },
    exportPaie(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        if (payload.poste_id && payload.type_poste) {
            return API.apiClient.get(
                `/sessions/${session_id}/dossieradministratifs/contrats/paye?poste_id=${payload.poste_id}&type_poste=${payload.type_poste}`,
                params
            )
        } else if (payload.poste_id) {
            return API.apiClient.get(
                `/sessions/${session_id}/dossieradministratifs/contrats/paye?poste_id=${payload.poste_id}`,
                params
            )
        } else if (payload.type_poste) {
            return API.apiClient.get(
                `/sessions/${session_id}/dossieradministratifs/contrats/paye?type_poste=${payload.type_poste}`,
                params
            )
        } else {
            return API.apiClient.get(`/sessions/${session_id}/dossieradministratifs/contrats/paye`, params)
        }
    },
    uploadQuantity(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/dossieradministratifs/contrats/quantity`, payload)
    }
}
