import * as API from '@/services/API'
import store from '@/store'

export default {
    async getDocumentations(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/documentations`, { params: params })
    },
    async getDocumentation(documentation_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/documentations/${documentation_id}`)
    },
    async addDocumentation(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/documentations`, payload)
    },
    async deleteDocumentation(documentation_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/documentations/${documentation_id}`)
    },
    async getPdfDocumentation(documentation_id: number, document_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/documentations/${documentation_id}/documents/${document_id}?format=b64`)
    }
}
