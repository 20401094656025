import { getError } from '@/utils/helpers'
import PaysService from '@/services/PaysService'
import { PaysInterface, StatePays } from '@/types/Pays'

export const namespaced = true

export const state: StatePays = {
    pays: [],
    loading: false
}

export const mutations = {
    RESET_STATE(state: StatePays) {
        Object.assign(state, {
            pays: []
        })
    },
    SET_PAYS(state: any, pays: Array<PaysInterface>) {
        state.pays = pays
    },
    SET_LOADING(state: any, etat: boolean) {
        state.loading = etat
    }
}

export const actions = {
    getPays({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PaysService.getPays()
                .then((response) => {
                    commit('SET_PAYS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    pays: (state: any) => {
        return state.pays
    },
    getPaysById: (state: any) => (id: number) => {
        const p = state.pays.filter((pay: any) => pay.id === id)[0]
        return p
    }
}
