import CandidatService from '@/services/CandidatService'
import ExaminateurService from '@/services/ExaminateurService'
import { getError } from '@/utils/helpers'

export const namespaced = true

/* Definir interface */
export const state: any = {
    listeExaminateurs: [],
    trombinoscope: [],
    isLoading: false,
    loading_pres: false,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    back_url: null,
    meta: null,
    error: null
}

export const mutations: any = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            listeExaminateurs: [],
            trombinoscope: [],
            isLoading: false,
            loading_pres: false,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            back_url: null,
            meta: null,
            error: null
        })
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading_pres = loading
    },
    SET_EXAMINATEURS(state: any, examinateurs: any) {
        state.listeExaminateurs = examinateurs
    },
    SET_MORE_EXAMINATEURS(state: any, examinateurs: any) {
        state.listeExaminateurs = state.listeExaminateurs.concat(examinateurs)
    },
    SET_ERROR(state: any, error: any) {
        state.error = error
    },
    SET_TROMBINOSCOPE(state: any, examinateurs: any) {
        state.trombinoscope = examinateurs
    },
    SET_BACK_URL(state: any, back_url: string) {
        state.back_url = back_url
    }
}

export const actions: any = {
    getExaminateursPres({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ExaminateurService.getExaminateurPres(params)
                .then((response) => {
                    commit('SET_EXAMINATEURS', response.data.data)
                    commit('SET_META', response.data.meta)
                    commit('SET_TOTALROWS', response.data.data.length)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreExaminateursPres({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ExaminateurService.getExaminateurPres(params)
                .then((response) => {
                    commit('SET_MORE_EXAMINATEURS', response.data.data)
                    commit('SET_META', response.data.meta)
                    commit('SET_TOTALROWS', response.data.data.length)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getCandidatsCdcExport({ commit }: { commit: any }, params?: any) {
        return new Promise((resolve, reject) => {
            CandidatService.getCandidatsCdcExport(params.centre_id)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getExaminateursPresExcel({ commit }: { commit: any }, payload?: any) {
        return new Promise((resolve, reject) => {
            ExaminateurService.getExaminateurPresExcel(payload.params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getExaminateursPresPDF({ commit }: { commit: any }, payload?: any) {
        return new Promise((resolve, reject) => {
            ExaminateurService.getExaminateurPresPDF(payload.params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getTrombinoscope({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ExaminateurService.getTrombinoscope(params)
                .then((response) => {
                    commit('SET_TROMBINOSCOPE', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters: any = {
    listeExaminateurs(state: any) {
        return state.listeExaminateurs
    },
    isLoading(state: any) {
        return state.loading_pres
    },
    totalRows: (state: any) => {
        return state.totalRows
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    meta: (state: any) => {
        return state.meta
    }
}
