import store from '@/store/index'
import * as API from '@/services/API'

export default {
    getDemandeRemboursement(demandeRemboursementId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/demanderemboursements/${demandeRemboursementId}`)
    },
    getDemandeRemboursements(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/demanderemboursements`, { params: payload })
    },
    updateDemandeRemboursement(demandeRemboursementId: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/demanderemboursements/${demandeRemboursementId}`, payload)
    },
    addDemandeRemboursement(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/demanderemboursements`, payload)
    },
    deleteDemandeRemboursement(demandeRemboursementId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/demanderemboursements/${demandeRemboursementId}`)
    },
    getDocumentDemandeRemboursement(demandeRemboursementId: number, documentId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/demanderemboursements/${demandeRemboursementId}/documents/${documentId}?format=b64`)
    },
    deleteDocumentDemandeRemboursement(demandeRemboursementId: number, documentId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(
            `/sessions/${session_id}/demanderemboursements/${demandeRemboursementId}/documents/${documentId}`
        )
    },
    exportDemandesRemboursements() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/exportdemandesremboursements`)
    }
}
