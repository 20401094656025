import { getError } from '@/utils/helpers'
import SessionService from '@/services/SessionService'
import { etat_structure, SessionInterface, StateSessions } from '@/types/Session'

export const namespaced = true

function setSessions(commit: any, response: any) {
    commit('SET_SESSIONS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateSessions = {
    sessions: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    sessionActive: null,
    sessionSelect: null,
    currentPage: 1
}

export const mutations = {
    RESET_STATE(state: StateSessions) {
        Object.assign(state, {
            sessions: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            sessionActive: null,
            sessionSelect: null,
            currentPage: 1
        })
    },
    SET_SESSIONS(state: any, sessions: Array<SessionInterface>) {
        state.sessions = sessions

        if (state.sessions.length === 1) {
            state.sessionActive = state.sessions[0]
        }
    },
    UPDATE_SESSION(state: any, session: SessionInterface) {
        const index = state.sessions.findIndex((s: SessionInterface) => s.id === session.id)
        if (index !== -1) {
            state.sessions[index] = session
        }
    },
    SET_MORE_SESSIONS(state: any, sessions: Array<SessionInterface>) {
        state.sessions = state.sessions.concat(sessions)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_SESSION_SELECT(state: any, session: SessionInterface) {
        state.sessionSelect = session
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    }
}

export const actions = {
    addSession({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SessionService.addSession(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getSessions({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SessionService.getSessions(params)
                .then((response) => {
                    setSessions(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateSession({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SessionService.updateSession(params.session_id, params.payload)
                .then((response) => {
                    commit('SET_SESSION_SELECT', response.data.data)
                    commit('SET_LOADING', false)

                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreSessions({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SessionService.getSessions(params)
                .then((response) => {
                    commit('SET_MORE_SESSIONS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.data.meta ? response.data.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getSessionsActives({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SessionService.getSessionsActives()
                .then((response) => {
                    setSessions(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getSession({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SessionService.getSession(params.session_id)
                .then((response) => {
                    commit('SET_SESSION_SELECT', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    cloneSession({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SessionService.cloneSession(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    setGabaritHoraire({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SessionService.setGabaritHoraire(payload.session_id, payload.gabarit)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    },
    updateSessionPlages({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SessionService.updateSessionPlages(params)
                .then((response) => {
                    commit('SET_SESSION_SELECT', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    },
    updateSessionAfex({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SessionService.updateSessionAfex(params.session_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)

                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    saveRegles({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SessionService.save_regles(payload)
                .then((response) => {
                    commit('SET_SESSION_SELECT', response.data.data)
                    commit('UPDATE_SESSION', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateSessionInventaireFourniture({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SessionService.save_publication_inventaire(params.session_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)

                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getSessionsForEditUserAbility({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SessionService.getSessionsForEditUserAbility(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    sessions: (state: any) => {
        return state.sessions
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.sessions.length
    },
    sessionActive: (state: any) => {
        return state.sessionActive
    },
    sessionSelect: (state: any) => {
        return state.sessionSelect
    },
    sessionsValides: (state: any) => {
        return state.sessions.filter((session: any) => session.structure_valide === etat_structure.VALIDEE)
    },
    get_sessions_intervenants: (state: any) => (abilities: any) => {
        const sessions: Array<any> = []
        for (const a in abilities) {
            if (abilities[a].scope) {
                const temp_s = state.sessions.find((s: any) => s.id === abilities[a].scope && s.closed_at === null)

                if (temp_s && !sessions.find((se: any) => se.id === temp_s.id)) {
                    sessions.push(temp_s)
                }
            }
        }

        return sessions
    }
}
