import * as API from '@/services/API'
import store from '@/store'

export default {
    getUser(userId: number) {
        return API.apiClient.get(`/users/${userId}`)
    },
    getUsers(payload: any) {
        return API.apiClient.get('/users', { params: payload })
    },
    removeOTP(userId: number) {
        return API.apiClient.delete(`/users/${userId}/two-factor`)
    },
    resetPassword(userId: number) {
        return API.apiClient.delete(`/users/${userId}/reset-password`)
    },
    addUser(payload: any) {
        return API.apiClient.post('/users', payload)
    },
    updateUser(payload: any) {
        return API.apiClient.put(`/users/${payload.id}`, payload)
    },
    getReportingUser(userId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/reportingusers/${userId}`)
    },
    getReportingUsers(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/reportingusers`, { params: payload })
    },
    addReportingUser(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/reportingusers`, payload)
    },
    updateReportingUser(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/reportingusers/${payload.user_id}/workingestablishments/${payload.working_establishment_id}`, payload)
    },
    deleteReportingUser(user_id: number, working_establishment_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/reportingusers/${user_id}/workingestablishments/${working_establishment_id}`)
    },
    updateSelfUser(payload: any) {
        return API.apiClient.put('/user', payload)
    },
    getAbilities() {
        return API.apiClient.get('/abilities')
    },
    saveCommentaire(userId: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/comments/user/${userId}`, payload)
    },
    getDashboard(ability: string) {
        const session_id = store.getters['auth/user_session_id']
        if (ability) {
            return API.apiClient.get(`/sessions/${session_id}/dashboard/${ability}`)
        } else {
            return API.apiClient.get(`/sessions/${session_id}/dashboard`)
        }
    },
    getNotifications() {
        return API.apiClient.get('/user/notifications')
    },
    updateNotification(notificationId: number, payload: any) {
        return API.apiClient.put(`/user/notifications/${notificationId}`, payload)
    },
    getRoles(payload: any) {
        return API.apiClient.get('/roles', { params: payload })
    },
    addRole(payload: any) {
        return API.apiClient.post('/roles', payload)
    },
    updateRole(roleId: number, payload: any) {
        return API.apiClient.put(`/roles/${roleId}`, payload)
    },
    deleteRole(roleId: number) {
        return API.apiClient.delete(`/roles/${roleId}`)
    },
    getRolesGCI(payload: any) {
        return API.apiClient.get('/gciroles', { params: payload })
    },
    addRoleGCI(payload: any) {
        return API.apiClient.post('/gciroles', payload)
    },
    updateRoleGCI(roleId: number, payload: any) {
        return API.apiClient.put(`/gciroles/${roleId}`, payload)
    },
    deleteRoleGCI(roleId: number) {
        return API.apiClient.delete(`/gciroles/${roleId}`)
    },
    getRolesEst(payload: any) {
        return API.apiClient.get('/estroles', { params: payload })
    },
    addRoleEst(payload: any) {
        return API.apiClient.post('/estroles', payload)
    },
    updateRoleEst(payload: any) {
        return API.apiClient.put(`/estroles/${payload.id}`, payload)
    },
    deleteRoleEst(roleId: number) {
        return API.apiClient.delete(`/estroles/${roleId}`)
    },
    getDependencies() {
        return API.apiClient.get('/abilities/dependencies')
    },
    getFicheIntervenants(params: any) {
        return API.apiClient.get(`/sessions/${params.session_id}/ficheintervenants/${params.user_id}`)
    },
    getExternUsers(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/externusers`, { params: params })
    },
    getExternUsersFromSearch(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/externusers/search`, payload)
    },
    getExternUser(user_id: number, params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/externusers/${user_id}`, { params: params })
    },
    editExternUser(user_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/externusers/${user_id}`, payload)
    },
    importReportingUsers(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const formData = new FormData()
        formData.append('document', payload)
        return API.apiClient.post(`/sessions/${session_id}/reportingusers/import`, formData)
    }
}
