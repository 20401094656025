import { getError } from '@/utils/helpers'
import DecisionJuryService from '@/services/DecisionJuryService'
import Vue from 'vue'

export const namespaced = true

export const state: any = {
    decisions: [],
    decision_select: null,
    loading_decisions: false,
    error_decisions: null,
    meta: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            decisions: [],
            decision_select: null,
            loading_decisions: false,
            error_decisions: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0
        })
    },
    SET_DECISIONS(state: any, decisions: any) {
        state.decisions = decisions
    },
    SET_MORE_DECISIONS(state: any, decisions: any) {
        state.decisions = state.decisions.concat(decisions)
    },
    SET_LOADING(state: any, etat: boolean) {
        state.loading_decisions = etat
    },
    SET_ERROR(state: any, error: any) {
        state.error_decisions = error
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    ADD_DECISION(state: any, decision_new: any) {
        state.decisions.push(decision_new)
    },
    MAJ_DECISION(state: any, decision_up: any) {
        const index = state.decisions.map((c: any) => c.id).indexOf(decision_up.id)
        Vue.set(state.decisions, index, decision_up)
    },
    DELETE_DECISION(state: any, decision_id: any) {
        const index = state.decisions.map((c: any) => c.id).indexOf(decision_id)
        state.decisions.splice(index, 1)
    }
}

export const actions = {
    getDecisions({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DecisionJuryService.getDecisionsJury(params?.filters)
                .then((response) => {
                    commit('SET_DECISIONS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    commit('SET_META', response.data.meta ? response.data.meta : null)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreDecisions({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DecisionJuryService.getDecisionsJury(params.filters)
                .then((response) => {
                    commit('SET_MORE_DECISIONS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    commit('SET_META', response.data.meta ? response.data.meta : null)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addDecision({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DecisionJuryService.addDecision(payload)
                .then((response) => {
                    commit('ADD_DECISION', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateDecision({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DecisionJuryService.updateDecision(payload)
                .then((response) => {
                    commit('MAJ_DECISION', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteDecision({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DecisionJuryService.deleteDecision(payload)
                .then((response) => {
                    commit('DELETE_DECISION', payload.id)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    exportDecisions({ commit }: { commit: any }) {
        return new Promise((resolve, reject) => {
            DecisionJuryService.exportDecisions()
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    get_decisions: (state: any) => {
        return state.decisions
    }
}
