import { getError } from '@/utils/helpers'
import ConditionnementService from '@/services/ConditionnementService'

export const namespaced = true

function setConditionnements(commit: Function, response: any) {
    commit('SET_CONDITIONNEMENTS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
}

export const state: any = {
    conditionnements: [],
    conditionnement: {},
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            conditionnements: [],
            conditionnement: {},
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0
        })
    },
    SET_CONDITIONNEMENTS(state: any, conditionnements: any) {
        state.conditionnements = conditionnements
    },
    SET_MORE_CONDITIONNEMENTS(state: any, conditionnements: any) {
        state.conditionnements = state.tournees.concat(conditionnements)
    },
    SET_CONDITIONNEMENT(state: any, conditionnement: any) {
        state.conditionnement = conditionnement
    },
    ADD_CONDITIONNEMENT(state: any, conditionnement: any) {
        state.conditionnement = conditionnement
        state.conditionnements.push(conditionnement)
    },
    UPDATE_CONDITIONNEMENT(state: any, conditionnement: any) {
        state.conditionnement = conditionnement
        state.conditionnements = state.conditionnements.map((item: any) => {
            if (item.id === conditionnement.id) {
                return conditionnement
            }
            return item
        })
    },
    DELETE_CONDITIONNEMENT(state: any, conditionnement_id: number) {
        if (state.conditionnement.id === conditionnement_id) {
            state.conditionnement = {}
        }
        state.conditionnements = state.conditionnements.filter((conditionnement: any) => conditionnement.id !== conditionnement_id)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    }
}

export const actions = {
    getConditionnements({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConditionnementService.getConditionnements(params)
                .then((response) => {
                    setConditionnements(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getConditionnement({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConditionnementService.getConditionnement(params.conditionnement_id)
                .then((response) => {
                    commit('SET_CONDITIONNEMENT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getMoreConditionnements({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConditionnementService.getConditionnements(params.formulaire_id)
                .then((response) => {
                    commit('SET_MORE_CONDITIONNEMENTS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    createConditionnement({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConditionnementService.postConditionnement(params)
                .then((response) => {
                    commit('ADD_CONDITIONNEMENT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    updateConditionnement({ commit }: { commit: any }, params: { conditionnement_id: number; payload: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConditionnementService.putConditionnement(params.conditionnement_id, params.payload)
                .then((response) => {
                    commit('UPDATE_CONDITIONNEMENT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    deleteConditionnement({ commit }: { commit: any }, conditionnement_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConditionnementService.deleteConditionnement(conditionnement_id)
                .then((response) => {
                    commit('DELETE_CONDITIONNEMENT', conditionnement_id)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {
    conditionnements: (state: any): [] => {
        return state.conditionnements
    },
    conditionnement: (state: any): {} => {
        return state.conditionnement
    },
    meta: (state: any): any => {
        return state.meta
    },
    links: (state: any): string | null => {
        return state.links
    },
    loading: (state: any): boolean => {
        return state.loading
    },
    error: (state: any): any => {
        return state.error
    },
    totalRows: (state: any): number => {
        return state.totalRows
    }
}
