import * as API from '@/services/API'

export default {
    getDocumentType(documentId: number) {
        return API.apiClient.get(`/documenttypes/${documentId}`)
    },
    getDocumentTypes() {
        return API.apiClient.get('/documenttypes')
    }
}
