import * as API from '@/services/API'
import store from '@/store'

export default {
    storeDocument(dossierId: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const config = { headers: { 'Content-Type': 'multipart/form-data' } }
        return API.apiClient.post(`/sessions/${session_id}/dossieracademiques/${dossierId}/documents`, payload, config)
    },
    deleteDocument(dossierId: number, documentId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/dossieracademiques/${dossierId}/documents/${documentId}`)
    },
    getDocument(dossierId: number, documentId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/dossieracademiques/${dossierId}/documents/${documentId}?format=b64`,
            {
                headers: { 'X-Request-Retry': 0 }
            }
        )
    },
    rejeteDocument(dossierId: number, documentId: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(
            `/sessions/${session_id}/dossieracademiques/${dossierId}/documents/${documentId}`,
            payload
        )
    },
    storeDocumentAdm(dossierId: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const config = { headers: { 'Content-Type': 'multipart/form-data' } }
        return API.apiClient.post(
            `/sessions/${session_id}/dossieradministratifs/${dossierId}/documents`,
            payload,
            config
        )
    },
    deleteDocumentAdm(dossierId: number, documentId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(
            `/sessions/${session_id}/dossieradministratifs/${dossierId}/documents/${documentId}`
        )
    },
    getDocumentAdm(dossierId: number, documentId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/dossieradministratifs/${dossierId}/documents/${documentId}?format=b64`,
            {headers: { 'X-Request-Retry': 0 }}
        )
    },
    updateDocumentAdm(dossierId: number, documentId: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(
            `/sessions/${session_id}/dossieradministratifs/${dossierId}/documents/${documentId}`,
            payload
        )
    },
    getDocumentCandidat(candidatId: number, documentId: number, b64 = true) {
        const session_id = store.getters['auth/user_session_id']
        if (b64) {
            return API.apiClient.get(
                `/sessions/${session_id}/candidats/${candidatId}/documents/${documentId}?format=b64`
            )
        } else {
            return API.apiClient.get(`/sessions/${session_id}/candidats/${candidatId}/documents/${documentId}`)
        }
    },
    getDocumentsExaminateur(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        if (payload.user_id !== null) {
            return API.apiClient.get(`/sessions/${session_id}/examinateurs/documents/${payload.user_id}`, {
                params: payload.params
            })
        } else {
            return API.apiClient.get(`/sessions/${session_id}/examinateurs/documents`, { params: payload.params })
        }
    },
    getDocumentExaminateur(userId: number, documentId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/examinateurs/documents/${userId}/${documentId}?format=b64`)
    },
    storeDocumentExaminateur(userId: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const config = { headers: { 'Content-Type': 'multipart/form-data' } }
        return API.apiClient.post(`/sessions/${session_id}/examinateurs/documents/${userId}`, payload, config)
    },
    deleteDocumentExaminateur(userId: number, documentId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/examinateurs/documents/${userId}/${documentId}`)
    }
    // 'candidats/{candidat}/documents/{document}'
}
