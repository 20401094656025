import * as API from '@/services/API'
import store from '@/store/index'

export default {
    async getRankingGroupsConcourPhase(concour_id: number, phase_id: number, params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/concours/${concour_id}/phases/${phase_id}/rankinggroup`, { params: params })
    },
    async getRankingGroups(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/ranking_groups`, { params: params })
    },
    async getRankingGroup(ranking_group_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/ranking_groups/${ranking_group_id}`)
    }
}
