import { getError } from '@/utils/helpers'
import NotificationTemplateService from '@/services/NotificationTemplateService'
import { NotificationTemplateInterface, StateNotificationTemplates } from '@/types/NotificationTemplate'
import { StateImpressionCandidatInterface } from '@/types/Fourniture'

export const namespaced = true

function setNotificationTemplates(commit: Function, response: any) {
    commit('SET_NOTIFICATION_TEMPLATES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_LOADING', false)
}

export const state: StateNotificationTemplates = {
    notificationTemplates: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    notificationTemplateSelect: {},
    source_pdf: '',
    params: {
        direction: 'asc',
        sort: 'name',
        page: 1
    }
}

export const mutations = {
    RESET_STATE(state: StateNotificationTemplates) {
        Object.assign(state, {
            notificationTemplates: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            notificationTemplateSelect: {},
            source_pdf: '',
            params: {
                direction: 'asc',
                sort: 'name',
                page: 1
            }
        })
    },
    SET_NOTIFICATION_TEMPLATES(state: any, notificationTemplates: Array<NotificationTemplateInterface>) {
        state.notificationTemplates = notificationTemplates
    },
    SET_MORE_NOTIFICATION_TEMPLATES(state: any, notificationTemplates: Array<NotificationTemplateInterface>) {
        state.notificationTemplates = state.notificationTemplates.concat(notificationTemplates)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_SOURCE_PDF(state: any, source: string) {
        state.source_pdf = source
    },
    SET_NOTIFICATION_TEMPLATE_SELECT(state: any, notificationTemplate: NotificationTemplateInterface) {
        state.notificationTemplateSelect = notificationTemplate
    },
    SET_CURRENT_PAGE(state: StateImpressionCandidatInterface, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_PARAMS(state: any, params: any) {
        state.params = params
    }
}

export const actions = {
    getNotificationTemplates({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            NotificationTemplateService.getNotificationTemplates(params)
                .then((response) => {
                    setNotificationTemplates(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreNotificationTemplates({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            NotificationTemplateService.getNotificationTemplates(params)
                .then((response) => {
                    commit('SET_MORE_NOTIFICATION_TEMPLATES', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getNotificationTemplate({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            NotificationTemplateService.getNotificationTemplate(params.notificationTemplate_id)
                .then((response) => {
                    commit('SET_NOTIFICATION_TEMPLATE_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateNotificationTemplate({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            NotificationTemplateService.updateNotificationTemplate(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addNotificationTemplate({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            NotificationTemplateService.addNotificationTemplate(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteNotificationTemplate({ commit }: { commit: any }, notificationTemplate_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            NotificationTemplateService.deleteNotificationTemplate(notificationTemplate_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPDF({ commit }: { commit: any }, nt_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            NotificationTemplateService.getPDF(nt_id)
                .then((response) => {
                    commit('SET_SOURCE_PDF', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    notificationTemplates: (state: any) => {
        return state.notificationTemplates
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.notificationTemplates.length
    },
    notificationTemplateSelect: (state: any) => {
        return state.notificationTemplateSelect
    },
    source_pdf: (state: any) => {
        return state.source_pdf
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    params: (state: any) => {
        return state.params
    }
}
