import { getError } from '@/utils/helpers'
import RgpdEntityService from '@/services/RgpdEntityService'
import { RgpdEntityInterface, StateRgpdEntities } from '@/types/RgpdEntity'

export const namespaced = true

function setRgpdEntities(commit: Function, response: any) {
    commit('SET_MATIERES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateRgpdEntities = {
    rgpdEntities: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    rgpdEntitySelect: {},
    checkRgpd: []
}

export const mutations = {
    RESET_STATE(state: StateRgpdEntities) {
        Object.assign(state, {
            rgpdEntities: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            rgpdEntitySelect: {},
            checkRgpd: []
        })
    },
    SET_MATIERES(state: any, rgpdEntities: Array<RgpdEntityInterface>) {
        state.rgpdEntities = rgpdEntities
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_MATIERE_SELECT(state: any, rgpdEntity: RgpdEntityInterface) {
        state.rgpdEntitySelect = rgpdEntity
    },
    SET_CHECK_RGPD(state: any, datas: Array<any>) {
        state.checkRgpd = datas
    }
}

export const actions = {
    getRgpdEntities({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            RgpdEntityService.getRgpdEntities()
                .then((response) => {
                    setRgpdEntities(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getRgpdEntity({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            RgpdEntityService.getRgpdEntity(params.rgpdEntity_id)
                .then((response) => {
                    commit('SET_MATIERE_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateRgpdEntity({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            RgpdEntityService.updateRgpdEntity(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addRgpdEntity({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            RgpdEntityService.addRgpdEntity(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteRgpdEntity({ commit }: { commit: any }, rgpdEntity_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            RgpdEntityService.deleteRgpdEntity(rgpdEntity_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    checkRgpdEntity({ commit }: { commit: any }, rgpdEntity_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            RgpdEntityService.checkRgpdEntity(rgpdEntity_id)
                .then((response) => {
                    commit('SET_CHECK_RGPD', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    rgpdEntities: (state: any) => {
        return state.rgpdEntities
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.rgpdEntities.length
    },
    rgpdEntitySelect: (state: any) => {
        return state.rgpdEntitySelect
    },
    checkRgpd: (state: any) => {
        return state.checkRgpd
    }
}
