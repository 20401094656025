import * as API from '@/services/API'
import store from '@/store'

export default {
    async getCandidatJobs(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/candidatjobs`, { params: payload })
    },
    async importCandidatJobs(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const formData = new FormData()
        formData.append('document', payload)
        return await API.apiClient.post(`/sessions/${session_id}/candidatjobs/import`, formData)
    },
    async getDocument(candidatJob_id: number, document_uuid: string) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/candidatjobs/${candidatJob_id}/document/${document_uuid}?format=b64`)
    }
}
