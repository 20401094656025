export interface EnsembleInterface {
    id: number | 0,
    session_id: number | 0,
    concour_id: number | 0,
    groupe_epreuve_id: number | 0,
    centre_id: number | 0,
    salle_id: number | 0,
    name: string,
    type_ensemble: TypeEnsemble | 0,
    nb_estime: number | 0,
    groupeEpreuve: any | null,
    concour: any | null
}

export interface StateEnsembles {
    ensembles: Array<EnsembleInterface> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    ensembleSelect: EnsembleInterface | {}
}

export enum TypeEnsemble {
    TYPE_ENSEMBLE_PAR_EPREUVE = 0,
    TYPE_ENSEMBLE_INTERCLASSEMENT = 1,
    TYPE_ENSEMBLE_TP = 2,
}

interface TypeEnsembleSpec {
    index: number,
    libelle: string,
    plural: string,
    ordre: number
}

export function getTypeEnsembleSpec(typeEnsemble: TypeEnsemble): TypeEnsembleSpec {
    switch (typeEnsemble) {
        case TypeEnsemble.TYPE_ENSEMBLE_PAR_EPREUVE:
            return { index: 0, libelle: 'Équipe par épreuve', plural: 'Équipes par épreuve', ordre: 0 }
        case TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT:
            return { index: 1, libelle: "Équipe d'interclassement", plural: "Équipes d'interclassement", ordre: 2 }
        case TypeEnsemble.TYPE_ENSEMBLE_TP:
            return { index: 2, libelle: 'Équipe de TP', plural: 'Équipes de TP', ordre: 1 }
        default:
            return { index: 0, libelle: 'Équipe par épreuve', plural: 'Équipes par épreuve', ordre: 0 }
    }
}

export function getTypeEnsemble() {
    return [
        { index: TypeEnsemble.TYPE_ENSEMBLE_PAR_EPREUVE },
        { index: TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT },
        { index: TypeEnsemble.TYPE_ENSEMBLE_TP }
    ]
}

export function getTypeEnsembleOrder() {
    const type_ensemble_order = [
        { index: 0, libelle: 'Toutes les équipes par épreuve', ordre: 0 },
        { index: 2, libelle: 'Toutes les équipes de TP', ordre: 1 },
        { index: 1, libelle: "Toutes les équipes d'interclassement", ordre: 2 }
    ]

    return type_ensemble_order
}
