import * as API from '@/services/API'
import store from '@/store/index'

export default {
    async getDefinitionDesBarresListeNotes(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(
            `/sessions/${session_id}/concours/${payload.concour_id}/phases/${payload.phase_id}/candidatconcourphases`,
            { params: payload.params }
        )
    },
    async exportationDesListeNotes(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(
            `/sessions/${session_id}/concours/${payload.concour_id}/phases/${payload.phase_id}/candidatconcourphases_export`,
            payload
        )
    },
    async exportationDesListeNotesJO(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(
            `/sessions/${session_id}/concours/${payload.concour_id}/phases/${payload.phase_id}/candidatconcourphases_export_jo`,
            payload
        )
    },
    async exportListeCandidatAdmissiblesCng(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(
            `/sessions/${session_id}/concours/${payload.concour_id}/phases/${payload.phase_id}/candidatconcourphases_export_liste${payload.format ? `?format=${payload.format}` : ''}`,
            {
                ...payload,
                headers: {
                    ...payload.headers,
                    'X-Request-Retry': 0
                }
            }
        )
    }
}
