import * as API from '@/services/API'
import store from '@/store'
import { AxiosRequestConfig } from 'axios'

export default {
    async getSujet(sujetId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/sujets/${sujetId}`)
    },
    async getSujets(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/sujets`, { params: payload })
    },

    async updateSujet(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/sujets/${payload.id}`, payload)
    },
    async addSujet(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/sujets`, payload)
    },
    async deleteSujet(sujet_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/sujets/${sujet_id}`)
    },
    async getPrevisionnelSujets(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/previsionnel_sujets`, { params: payload })
    },
    async updatePrevisionnelSujets(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/previsionnel_sujets`, payload)
    },
    async updateMargePrevisionnelSujets(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/marge_previsionnel_sujets`, payload)
    },
    async exportPrevisionnelSujets() {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                // 'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return await API.apiClient.get(`/sessions/${session_id}/previsionnel_sujets_export`, params)
    },
    async saveMargeCommande(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/marge_commande_sujets`, payload)
    },
    async getPDFEtiquettesSujetsExport(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/etiquettes_sujets_export/${payload.id}`, {
            params: { format: 'b64' },
            headers: { 'X-Request-Retry': 0 }
        })
    },
    async getPDFEtiquettesSujetsAmenagementExport() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/etiquettes_sujets_candidat_handi_export`, {
            params: { format: 'b64' },
            headers: { 'X-Request-Retry': 0 }
        })
    },
    async getEtiquettesSujetsExport(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/etiquettes_sujets_export`, {
            params: params,
            headers: { 'X-Request-Retry': 0 }
        })
    },
    getEtiquettesCantinesExport() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/etiquettes_cantines_export`,
            {
                params: { format: 'b64' },
                headers: { 'X-Request-Retry': 0 }
            }
        )
    }

    // /previsionnel_sujets_export
}
