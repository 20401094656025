import * as API from '@/services/API'
import store from '@/store'
import { AxiosRequestConfig } from 'axios'

export default {
    getListeCandidats(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const concour_id = payload.concour_id
        const phase_id = payload.phase_id
        return API.apiClient.get(
            `/sessions/${session_id}/concours/${concour_id}/phases/${phase_id}/candidatconcourphases`,
            { params: payload.params }
        )
    },
    validationReclassement(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(
            `/sessions/${session_id}/concours/${payload.concour_id}/candidats/${payload.candidat_id}/classement/${payload.rang}`
        )
    },
    getReclamation(reclamation_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/reclamations/${reclamation_id}`)
    },
    updateReclamation(reclamation_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/reclamations/${reclamation_id}`, payload)
    },
    addReclamation(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/reclamations`, payload)
    },
    exporter() {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return API.apiClient.get(`/sessions/${session_id}/reclamations_export`, params)
    },
    addMessage(reclamation_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/reclamations/${reclamation_id}/messages`, payload)
    }
}


