import { getError } from '@/utils/helpers'
import RankingGroupService from '@/services/RankingGroupService'
import { RankingGroup } from '@/types/RankingGroup'

export const namespaced = true

export const state = {
    rankingGroupsConcourPhase: [] as RankingGroup[],
    rankingGroups: [] as RankingGroup[],
    rankingGroup: {} as RankingGroup,
    loading: false,
    error: null
}

export const mutations = {
    RESET_STATE(state: any): void {
        Object.assign(state, {
            rankingGroupsConcourPhase: [] as RankingGroup[],
            rankingGroups: [] as RankingGroup[],
            rankingGroup: {} as RankingGroup,
            loading: false,
            error: null
        })
    },
    SET_RANKING_GROUPS_CONCOUR_PHASE(state: any, rankingGroupsConcourPhase: RankingGroup[]): void {
        state.rankingGroupsConcourPhase = rankingGroupsConcourPhase
    },
    SET_RANKING_GROUPS(state: any, rankingGroups: RankingGroup[]): void {
        state.rankingGroups = rankingGroups
    },
    SET_RANKING_GROUP(state: any, rankingGroup: RankingGroup): void {
        state.rankingGroup = rankingGroup
    },
    SET_LOADING(state: any, loading: boolean): void {
        state.loading = loading
    },
    SET_ERROR(state: any, error: any): void {
        state.error = error
    }
}

export const actions = {
    getRankingGroupsConcourPhase({ commit }: { commit: any }, payload: {concour_id: number; phase_id: number; params?: any}) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            RankingGroupService.getRankingGroupsConcourPhase(payload.concour_id, payload.phase_id, payload.params)
                .then((response): void => {
                    commit('SET_RANKING_GROUPS_CONCOUR_PHASE', response.data.data)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getRankingGroups({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            RankingGroupService.getRankingGroups(params)
                .then((response): void => {
                    commit('SET_RANKING_GROUPS', response.data.data)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getRankingGroup({ commit }: { commit: any }, params: {ranking_group_id: number}) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            RankingGroupService.getRankingGroup(params.ranking_group_id)
                .then((response): void => {
                    commit('SET_RANKING_GROUP', response.data.data)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {
    rankingGroupsConcourPhase: (state: any): RankingGroup[] => {
        return state.rankingGroupsConcourPhase
    },
    rankingGroups: (state: any): RankingGroup[] => {
        return state.rankingGroups
    },
    rankingGroup: (state: any): RankingGroup => {
        return state.rankingGroup
    },
    loading: (state: any): boolean => {
        return state.loading
    },
    error: (state: any): any => {
        return state.error
    },
    getRankinGroupNameById: (state: any) => (id: any) => {
        return state.rankingGroupsConcourPhase.find((oa: any) => oa.id === id)
    }
}
