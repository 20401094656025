/* eslint-disable @typescript-eslint/no-unused-vars */
import {getError} from '@/utils/helpers'
import ExaminateurService from '@/services/ExaminateurService'
import Vue from 'vue'
import {ExaminateurInterface} from '@/types/GestionExaminateur'
import {TypeEnsemble} from '@/types/Ensemble'

export const namespaced = true

function setExaminateurs(commit: Function, response: any) {
    commit('SET_EXAMINATEURS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_LOADING', false)
}

/* Definir interface */
export const state: any = {
    listeExaminateurs: [],
    examinateurs_infos: [],
    isLoading: false,
    selectedExaminateur: null,
    editedExaminateur: null,
    loading: true,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    meta: null,
    examinateur_id: 0,
    examinateurs_equipes: [],
    liste_examinateurs_temps: [],
    error: null
}

export const mutations: any = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            listeExaminateurs: [],
            examinateurs_infos: [],
            isLoading: false,
            selectedExaminateur: null,
            editedExaminateur: null,
            loading: true,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            meta: null,
            examinateur_id: 0,
            examinateurs_equipes: [],
            liste_examinateurs_temps: [],
            error: null
        })
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_EXAMINATEURS(state: any, examinateurs: Array<any>) {
        for (const examinateur of examinateurs) {

            // Check si c'est un nouvel user ou une maj
            const index_u = state.listeExaminateurs.findIndex((u: any) => u.id === examinateur.id)
            let examinateur_push: any = null
            let isMaj = false
            if (index_u !== -1) {
                examinateur_push = examinateur
                isMaj = true
            } else {
                const examinateur_new: ExaminateurInterface = {
                    id: examinateur.id,
                    civility: examinateur.civility,
                    name: examinateur.name,
                    first_name: examinateur.first_name,
                    email: examinateur.email,
                    telephone: examinateur.telephone,
                    code: examinateur.code,
                    password_changed: examinateur.password_changed,
                    email_verified: examinateur.email_verified,
                    rgpd_accepted: examinateur.rgpd_accepted,
                    two_factor: examinateur.two_factor,
                    roles: examinateur.roles,
                    abilities: examinateur.abilities,
                    notified: examinateur.notified,
                    infosessionuser: examinateur.infosessionuser,
                    matiere: examinateur.matiere,
                    matieres: examinateur.matieres,
                    ensembles: examinateur.ensembles,
                    affectations: examinateur.affectations,
                    dossierAcademiques: [],
                    creneaux_count: 0,
                    creneaux_prio1_count: 0,
                    creneaux_prio2_count: 0,
                    creneaux_actifs_count: 0,
                    creneaux_candidats_count: 0,
                    vacations_count: examinateur.vacations_count,
                    incompatibilites_count: 0
                }
                examinateur_push = examinateur_new
            }

            // Push les informations d'incompatbilités
            if (examinateur.incompatibilites_count) {
                examinateur_push.dossierAcademiques = examinateur.dossierAcademiques
                examinateur_push.incompatibilites_count = examinateur.incompatibilites_count
            }

            // Push les informations de disponibilités
            if (examinateur.creneaux_count) {
                examinateur_push.creneaux_count = examinateur.creneaux_count
                examinateur_push.creneaux_prio1_count = examinateur.creneaux_prio1_count
                examinateur_push.creneaux_prio2_count = examinateur.creneaux_prio2_count
                examinateur_push.creneaux_actifs_count = examinateur.creneaux_actifs_count
                examinateur_push.creneaux_candidats_count = examinateur.creneaux_candidats_count
            }

            if (isMaj) {
                Vue.set(state.listeExaminateurs, index_u, examinateur_push)
            } else {
                state.listeExaminateurs.push(examinateur_push)
            }
        }
    },
    SET_SELECTED_EXAMINATEUR(state: any, examinateur: Array<any>) {
        state.selectedExaminateur = examinateur
    },
    SET_MORE_EXAMINATEURS(state: any, examinateurs: Array<any>) {
        for (const examinateur of examinateurs) {
            // Check si c'est un nouvel user ou une maj
            const index_u = state.listeExaminateurs.findIndex((u: any) => u.id === examinateur.id)
            let examinateur_push: any = null
            let isMaj = false
            if (index_u !== -1) {
                examinateur_push = examinateur
                isMaj = true
            } else {
                const examinateur_new: ExaminateurInterface = {
                    id: examinateur.id,
                    civility: examinateur.civility,
                    name: examinateur.name,
                    first_name: examinateur.first_name,
                    email: examinateur.email,
                    telephone: examinateur.telephone,
                    code: examinateur.code,
                    password_changed: examinateur.password_changed,
                    email_verified: examinateur.email_verified,
                    rgpd_accepted: examinateur.rgpd_accepted,
                    two_factor: examinateur.two_factor,
                    roles: examinateur.roles,
                    abilities: examinateur.abilities,
                    notified: examinateur.notified,
                    infosessionuser: examinateur.infosessionuser,
                    matiere: examinateur.matiere,
                    matieres: examinateur.matieres,
                    ensembles: examinateur.ensembles,
                    affectations: examinateur.affectations,
                    dossierAcademiques: [],
                    creneaux_count: 0,
                    creneaux_prio1_count: 0,
                    creneaux_prio2_count: 0,
                    creneaux_actifs_count: 0,
                    creneaux_candidats_count: 0,
                    vacations_count: examinateur.vacations_count,
                    incompatibilites_count: 0
                }
                examinateur_push = examinateur_new
            }

            // Push les informations d'incompatbilités
            if (examinateur.incompatibilites_count) {
                examinateur_push.dossierAcademiques = examinateur.dossierAcademiques
                examinateur_push.incompatibilites_count = examinateur.incompatibilites_count
            }

            // Push les informations de disponibilités
            if (examinateur.creneaux_count) {
                examinateur_push.creneaux_count = examinateur.creneaux_count
                examinateur_push.creneaux_prio1_count = examinateur.creneaux_prio1_count
                examinateur_push.creneaux_prio2_count = examinateur.creneaux_prio2_count
                examinateur_push.creneaux_actifs_count = examinateur.creneaux_actifs_count
                examinateur_push.creneaux_candidats_count = examinateur.creneaux_candidats_count
            }

            if (isMaj) {
                Vue.set(state.listeExaminateurs, index_u, examinateur_push)
            } else {
                state.listeExaminateurs.push(examinateur_push)
            }
        }
    },
    SET_LOADING(state: any, loading: boolean) {
        state.isLoading = loading
    },
    SET_EXAMINATEUR_ID(state: any, examinateur_id: number) {
        state.examinateur_id = examinateur_id
    },
    MAJ_INFO_SESSION_USER(state: any, params: any) {
        const index_u = state.listeExaminateurs.findIndex((u: any) => u.id === params.user_id)
        if (index_u !== -1) {
            const exam_temp = state.listeExaminateurs[index_u]
            exam_temp.infosessionuser = params.info_users
            Vue.set(state.listeExaminateurs, index_u, exam_temp)

            // Dans le cas des TP on maj aussi les autres examinateurs de l'équipe
            if (exam_temp.ensembles[0].type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_TP) {
                // TODO ensemble => ensembles
                const equipe = state.listeExaminateurs.filter(
                    (ex: any) => ex.ensembles[0].id === exam_temp.ensembles[0].id
                )
                for (const e in equipe) {
                    const index_u_eq = state.listeExaminateurs.findIndex((u: any) => u.id === equipe[e].id)
                    const temp_eq = state.listeExaminateurs[index_u_eq]
                    temp_eq.infosessionuser.disponibilites_validated_at = params.info_users.disponibilites_validated_at
                    temp_eq.infosessionuser.disponibilites_submitted_at = params.info_users.disponibilites_submitted_at
                    temp_eq.infosessionuser.commentaire_rejet = params.info_users.commentaire_rejet
                    Vue.set(state.listeExaminateurs, index_u_eq, temp_eq)
                }
            }
        }
    },
    SET_EXAMINATEURS_INFOS(state: any, examinateurs: Array<any>) {
        state.examinateurs_infos = examinateurs
    },
    SET_MORE_EXAMINATEURS_INFOS(state: any, examinateurs: Array<any>) {
        state.examinateurs_infos = state.examinateurs_infos.concat(examinateurs)
    },
    SET_EXAMINATEURS_EQUIPES(state: any, examinateurs: Array<any>) {
        state.examinateurs_equipes = examinateurs
    },
    MAJ_EXAMINATEUR_IN_LISTE_EXAMINATEUR(state: any, examinateur: any) {
        if (examinateur) {

            const index_u = state.listeExaminateurs.findIndex((u: any) => u.id === examinateur.id)
            const examinateur_push = state.listeExaminateurs[index_u]
            // Push les informations d'incompatbilités
            if (examinateur.incompatibilites_count) {
                examinateur_push.dossierAcademiques = examinateur.dossierAcademiques
                examinateur_push.incompatibilites_count = examinateur.incompatibilites_count
            }

            // Push les informations de disponibilités
            if (examinateur.creneaux_count) {
                examinateur_push.affectations = examinateur.affectations
                examinateur_push.creneaux_count = examinateur.creneaux_count
                examinateur_push.creneaux_prio1_count = examinateur.creneaux_prio1_count
                examinateur_push.creneaux_prio2_count = examinateur.creneaux_prio2_count
                examinateur_push.creneaux_actifs_count = examinateur.creneaux_actifs_count
                examinateur_push.creneaux_candidats_count = examinateur.creneaux_candidats_count
                examinateur_push.vacations_count = examinateur.vacations_count
            }
            if (index_u !== -1) {
                Vue.set(state.listeExaminateurs, index_u, examinateur_push)
            }
        }
    },
    SET_LISTE_EXAMINATEURS_TEMP(state: any, examinateur: any) {
        state.liste_examinateurs_temps.push(examinateur)
    },
    SET_ERROR(state: any, error: any) {
        state.error = error
    }
}

export const actions: any = {
    getExaminateurs({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ExaminateurService.getExaminateurs(params)
                .then((response) => {
                    setExaminateurs(commit, response)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    reject(error)
                })
        })
    },
    getMoreExaminateurs({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ExaminateurService.getExaminateurs(params)
                .then((response) => {
                    commit('SET_MORE_EXAMINATEURS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getExaminateursDisponibilite({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ExaminateurService.getExaminateursDisponibilite(params)
                .then((response) => {
                    setExaminateurs(commit, response)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    reject(error)
                })
        })
    },
    getMoreExaminateursDisponibilite({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ExaminateurService.getExaminateursDisponibilite(params)
                .then((response) => {
                    commit('SET_MORE_EXAMINATEURS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getExaminateur({ commit }: { commit: any }, params?: any) {
        return new Promise((resolve, reject) => {
            ExaminateurService.getExaminateurs(params)
                .then((response) => {
                    commit('MAJ_EXAMINATEUR_IN_LISTE_EXAMINATEUR', response.data.data[0])
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getExaminateurDisponibilite({ commit }: { commit: any }, params?: any) {
        return new Promise((resolve, reject) => {
            ExaminateurService.getExaminateursDisponibilite(params)
                .then((response) => {
                    commit('MAJ_EXAMINATEUR_IN_LISTE_EXAMINATEUR', response.data.data[0])
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getExaminateurByID({ commit }: { commit: any }, params?: any) {
        return new Promise((resolve, reject) => {
            ExaminateurService.getExaminateurs(params)
                .then((response) => {
                    commit('SET_LISTE_EXAMINATEURS_TEMP', response.data.data[0])
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    getDisponibilitesExaminateur({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ExaminateurService.getDisponibilitesExaminateur()
                .then((response) => {
                    commit('SET_SELECTED_EXAMINATEUR', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    reject(error)
                })
        })
    },
    getExaminateursEquipe({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ExaminateurService.getExaminateurs(params)
                .then((response) => {
                    commit('SET_EXAMINATEURS_EQUIPES', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    reject(error)
                })
        })
    },
    getPDFDisposExaminateur({ commit }: { commit: any }, params?: any) {
        return new Promise((resolve, reject) => {
            ExaminateurService.getPDFDisposExaminateur(params.user_id)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateExaminateursDisponibilite({ commit }: { commit: any }, params?: any) {
        return new Promise((resolve, reject) => {
            ExaminateurService.updateExaminateursDisponibilite(params)
                .then((response) => {
                    commit('MAJ_EXAMINATEUR_IN_LISTE_EXAMINATEUR', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters: any = {
    /*
    listeExaminateurs(state: { listeExaminateurs: any }) {
        return state.listeExaminateurs
    },
    isLoading(state: { isLoading: any }) {
        return state.isLoading
    },
    */
    listeExaminateurs(state: any) {
        return state.listeExaminateurs
    },
    isLoading(state: any) {
        return state.isLoading
    },
    totalRows: (state: any) => {
        return state.totalRows
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    meta: (state: any) => {
        return state.meta
    },
    selectedExaminateur: (state: any) => {
        return state.selectedExaminateur
    },
    examinateur_select: (state: any) => {
        if (state.listeExaminateurs.length !== 0) {
            return state.listeExaminateurs.find((exam: any) => exam.id === state.examinateur_id)
        } else {
            return state.selectedExaminateur
        }
    },
    examinateur_select_infos: (state: any) => {
        if (state.listeExaminateurs.length !== 0) {
            return state.listeExaminateurs.find((exam: any) => exam.id === state.examinateur_id)
        }
    },
    get_examinateurs_equipe: (state: any) => {
        return state.examinateurs_equipes
    },
    get_examinateur_temps: (state: any) => (id: number) => {
        return state.liste_examinateurs_temps.find((ex: any) => ex.id === id)
    },
    get_examinateur_matiere_ids: (state: any) => (examinateur: any) => {
        const retour:Array<any> = []
        for (const ensemble of examinateur.ensembles) {
            if (ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
                const affectations_examinateur = examinateur.affectations.filter(
                    (ae: any) => ae.user_id === examinateur.id || ae.remplacant_id === examinateur.id
                )
                if (affectations_examinateur.length > 0) {
                    const epreuve_correction_id = affectations_examinateur[0].epreuve_correction_id

                    for (const ge in ensemble.groupeEpreuve.epreuves) {
                        if (ensemble.groupeEpreuve.epreuves[ge].id === epreuve_correction_id) {
                            if(!retour.includes(ensemble.groupeEpreuve.epreuves[ge].matiere_id)) {

                                retour.push(ensemble.groupeEpreuve.epreuves[ge].matiere_id)
                            }
                        }
                    }
                }
            } else {
                if (!retour.includes(ensemble.groupeEpreuve.epreuves[0].matiere_id)) {
                    retour.push(ensemble.groupeEpreuve.epreuves[0].matiere_id)
                }
            }
        }
        return retour
    },
    get_examinateur_groupe_epreuve_epreuve_epreuve_correction_ids: (state: any) => (examinateur: any) => {
        const retour:Array<any> = []
        for (const ensemble of examinateur.ensembles) {
            if (ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
                const affectations_examinateur = examinateur.affectations.filter(
                    (ae: any) => ae.user_id === examinateur.id || ae.remplacant_id === examinateur.id
                )
                if (affectations_examinateur.length > 0) {
                    for (let i = 0; i < affectations_examinateur.length; i++) {
                        if (!retour.includes(affectations_examinateur[i].epreuve_correction_id)) {
                            retour.push(affectations_examinateur[i].epreuve_correction_id)
                        }
                    }
                }
            } else {
                if (!retour.includes(ensemble.groupeEpreuve.epreuves[0].epreuve_correction_id)) {
                    retour.push(ensemble.groupeEpreuve.epreuves[0].epreuves[0].epreuve_correction_id)
                }
            }
        }
        return retour
    },
    error_exa: (state: any) => {
        return state.error
    }
}
