import * as API from '@/services/API'

export default {
    async getGestionParticuliere(gestionParticuliereId: number) {
        return await API.apiClient.get(`/gestionparticulieres/${gestionParticuliereId}`)
    },
    async getGestionParticulieres(payload: any) {
        return await API.apiClient.get('/gestionparticulieres', { params: payload })
    },
    async updateGestionParticuliere(gestionParticuliereId: number, payload: any) {
        return await API.apiClient.put(`/gestionparticulieres/${gestionParticuliereId}`, payload)
    },
    async addGestionParticuliere(payload: any) {
        return await API.apiClient.post('/gestionparticulieres', payload)
    },
    async deleteGestionParticuliere(gestionParticuliere_id: number) {
        return await API.apiClient.delete(`/gestionparticulieres/${gestionParticuliere_id}`)
    }
}
