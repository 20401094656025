import { getError } from '@/utils/helpers'
import ImpressionsService from '@/services/ImpressionsService'
import { StateImpressionCandidatInterface } from '@/types/Fourniture'
import Vue from 'vue'

export const namespaced = true

function setTableauImpressionCandidat(commit: Function, response: any) {
    commit('SET_TABLEAU_IMPRESSION_CANDIDAT', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_LOADING_RECHERCHE', false)
    commit('SET_TOTAL', response.data.meta ? response.data.meta.total : 1)
}

export const state: StateImpressionCandidatInterface = {
    tableauImpressionsCandidat: [],
    loadingRecherche: false,
    loadingPdf: false,
    pdfView: [],
    meta: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    total: 0,
    error: null
}

export const mutations = {
    RESET_STATE(state: StateImpressionCandidatInterface) {
        Object.assign(state, {
            tableauImpressionsCandidat: [],
            loadingRecherche: false,
            error: null,
            loadingPdf: false,
            pdfView: [],
            meta: null
        })
    },
    SET_TABLEAU_IMPRESSION_CANDIDAT(state: StateImpressionCandidatInterface, tableauImpressionsCandidat: any) {
        state.tableauImpressionsCandidat = tableauImpressionsCandidat
    },
    SET_MORE_TABLEAU_IMPRESSION_CANDIDAT(state: StateImpressionCandidatInterface, tableauImpressionsCandidat: any) {
        state.tableauImpressionsCandidat = state.tableauImpressionsCandidat.concat(tableauImpressionsCandidat)
    },
    SET_LOADING_RECHERCHE(state: StateImpressionCandidatInterface, loadingRecherche: boolean) {
        state.loadingRecherche = loadingRecherche
    },
    SET_PDF(state: StateImpressionCandidatInterface, pdf: any) {
        state.pdfView = pdf
    },
    SET_LOADING_PDF(state: StateImpressionCandidatInterface, loadingPdf: boolean) {
        state.loadingPdf = loadingPdf
    },
    SET_ERROR(state: StateImpressionCandidatInterface, error: Array<any>) {
        state.error = error
    },
    RESET_ERROR(state: StateImpressionCandidatInterface) {
        state.error = null
    },
    SET_META(state: StateImpressionCandidatInterface, meta: Array<any>) {
        state.meta = meta
    },
    SET_TOTALROWS(state: StateImpressionCandidatInterface, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: StateImpressionCandidatInterface, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL(state: StateImpressionCandidatInterface, totalPage: number) {
        state.total = totalPage
    },
    SET_CURRENT_PAGE(state: StateImpressionCandidatInterface, currentPage: number) {
        state.currentPage = currentPage
    },
    RESET_META(state: StateImpressionCandidatInterface) {
        state.meta = null
        state.totalRows = 0
        state.currentPage = 1
        state.lastPage = 0
        state.total = 0
    },
    RESET_TABLEAU_IMPRESSION(state: StateImpressionCandidatInterface) {
        state.tableauImpressionsCandidat = []
    }
}

export const actions = {
    getTableauImpressionCandidat({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING_RECHERCHE', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            ImpressionsService.recherche(payload)
                .then((response) => {
                    setTableauImpressionCandidat(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_RECHERCHE', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreTableauImpressionCandidat({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING_RECHERCHE', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            ImpressionsService.recherche(payload)
                .then((response) => {
                    commit('SET_MORE_TABLEAU_IMPRESSION_CANDIDAT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_META', response.data.meta)
                    commit('SET_META', response.data.meta)
                    commit('SET_LOADING_RECHERCHE', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_RECHERCHE', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPdf({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING_PDF', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            ImpressionsService.getPDF(payload)
                .then((response) => {
                    commit('SET_PDF', response.data)
                    commit('SET_LOADING_PDF', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_PDF', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    RESET_TABLEAU_IMPRESSION_CANDIDAT({ commit }: { commit: any }) {
        commit('RESET_ERROR')
        commit('RESET_TABLEAU_IMPRESSION_CANDIDAT')
    },

    CONFIRMATION_IMPRESSION({ commit }: { commit: any }, payload?: any) {
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            ImpressionsService.recherche(payload)
                .then((response) => {
                    const CandidatResponse = response.data.data[0]
                    const index = state.tableauImpressionsCandidat.findIndex(
                        (el: { candidat_id: any; type: any; user_id: any }) =>
                            el.candidat_id === CandidatResponse.candidat_id &&
                            el.type === CandidatResponse.type &&
                            el.user_id === CandidatResponse.user_id
                    )
                    Vue.set(state.tableauImpressionsCandidat, index, CandidatResponse)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_RECHERCHE', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    tableauImpressionsCandidatTrie: (state: StateImpressionCandidatInterface) => {
        return state.tableauImpressionsCandidat
    },
    loadingRecherche: (state: StateImpressionCandidatInterface) => {
        return state.loadingRecherche
    },
    loadingPdf: (state: StateImpressionCandidatInterface) => {
        return state.loadingPdf
    },
    getPdf: (state: StateImpressionCandidatInterface) => {
        return state.pdfView
    },
    getError: (state: StateImpressionCandidatInterface) => {
        return state.error
    },
    meta: (state: StateImpressionCandidatInterface) => {
        return state.meta
    },
    currentPage: (state: StateImpressionCandidatInterface) => {
        return state.currentPage
    },
    lastPage: (state: StateImpressionCandidatInterface) => {
        return state.lastPage
    },
    total: (state: StateImpressionCandidatInterface) => {
        return state.total
    }
}
