export default async function auth({ to, next, store }: any) {
    const loginQuery = { path: '/login', query: { redirect: to.fullPath } }

    if (!store.getters['auth/authUser']) {
        await store.dispatch('auth/getAuthUser').then(() => {
            if (!store.getters['auth/authUser']) {
                next(loginQuery)
            } else {
                next()
            }
        })
    } else {
        next()
    }
}
