import { getError } from '@/utils/helpers'
import PosteOuvertService from '@/services/PosteOuvertService'
import { PosteOuvertInterface, StatePosteOuverts } from '@/types/PosteOuvert'

export const namespaced = true

function setPosteOuverts(commit: Function, response: any): void {
    commit('SET_POSTE_OUVERTS', response.data.data)
    commit('SET_LINKS', response.data.links)
    commit('SET_META', response.data.meta)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_LOADING', false)
}

export const state: StatePosteOuverts = {
    posteOuverts: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    currentPage: 0,
    lastPage: 0,
    totalPage: 0,
    posteOuvertSelect: {}
}

export const mutations = {
    RESET_STATE(state: StatePosteOuverts): void {
        Object.assign(state, {
            posteOuverts: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            currentPage: 0,
            lastPage: 0,
            totalPage: 0,
            posteOuvertSelect: {}
        })
    },
    SET_POSTE_OUVERTS(state: any, posteOuverts: Array<PosteOuvertInterface>): void {
        state.posteOuverts = posteOuverts
    },
    SET_MORE_POSTE_OUVERTS(state: any, posteOuverts: Array<PosteOuvertInterface>): void {
        state.posteOuverts = state.posteOuverts.concat(posteOuverts)
    },
    SET_META(state: any, meta: Array<any>): void {
        state.meta = meta
    },
    SET_TOTALROWS(state: any, totalRows: number): void {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number): void {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number): void {
        state.totalPage = totalPage
    },
    SET_CURRENT_PAGE(state: any, currentPage: number): void {
        state.currentPage = currentPage
    },
    SET_LINKS(state: any, links: Array<any>): void {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean): void {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>): void {
        state.error = error
    },
    SET_POSTE_OUVERT_SELECT(state: any, posteOuvert: PosteOuvertInterface): void {
        state.posteOuvertSelect = posteOuvert
    }
}

export const actions = {
    getPosteOuverts({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            PosteOuvertService.getPosteOuverts(params)
                .then((response): void => {
                    setPosteOuverts(commit, response)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getPosteOuvert({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            PosteOuvertService.getPosteOuvert(params.posteOuvert_id)
                .then((response): void => {
                    commit('SET_POSTE_OUVERT_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getMorePosteOuverts({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            PosteOuvertService.getPosteOuverts(params)
                .then((response): void => {
                    commit('SET_MORE_POSTE_OUVERTS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    updatePosteOuvert({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            PosteOuvertService.updatePosteOuvert(params)
                .then((response): void => {
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    posteOuvertUpdateSession ({ commit }: {commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            PosteOuvertService.posteOuvertUpdateSession(params)
                .then((response): void => {
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    addPosteOuvert ({ commit }: {commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            PosteOuvertService.addPosteOuvert(params)
                .then((response): void => {
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    deletePosteOuvert({ commit }: { commit: any }, posteOuvert_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            PosteOuvertService.deletePosteOuvert(posteOuvert_id)
                .then((response): void => {
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    import({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            PosteOuvertService.import(payload)
                .then((response): void => {
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    export({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            PosteOuvertService.export()
                .then((response): void => {
                    // setDemandesRemboursement(commit, response)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {
    posteOuverts: (state: any) => {
        return state.posteOuverts
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    totalRows: (state: any) => {
        return state.totalRows
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    error: (state: any) => {
        return state.error
    },
    posteOuvertSelect: (state: any) => {
        return state.posteOuvertSelect
    },
    getPosteOuvertById: (state: any) => (id: number) => {
        const posteOuvert = state.posteOuverts.filter((mat: any) => mat.id === id)[0]
        return posteOuvert
    }
}
