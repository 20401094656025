
























import { Vue, Component, Watch } from 'vue-property-decorator'
import NavBarTop from '@/components/NavBarTop.vue'
import SideBarLeft from '@/components/SideBarLeft.vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import { Ability } from '@/types/Ability'

@Component({
    computed: {
        asValidAccount() {
            const authUser = this.$store.getters['auth/authUser']
            return authUser && authUser.emailVerified && authUser.passwordChanged && authUser.rgpdAccepted
        },
        currentRouteName() {
            let routeName = ' '
            if (this.$route.meta && this.$route.meta.refererComponent) {
                routeName = this.$route.meta.refererComponent + ' | '
            }
            if (this.$route.name) {
                routeName += this.$route.name
            }

            return routeName
        },
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapGetters('user', ['dashboard', 'centreId']),
        ...mapState('auth', ['user_session_id', 'multi_session', 'user'])
    },
    components: {
        'nav-bar-top': NavBarTop,
        'side-bar-left': SideBarLeft
    },
    methods: {
        ...mapActions('dossieracademique', ['getDossiersAcademiques']),
        ...mapActions('dossieradministratif', ['getDossiersAdministratifs']),
        ...mapActions('centre', ['getCentres']),
        ...mapGetters('auth', ['is_intervenant_centre'])
    }
})

export default class App extends Vue {
    Ability = Ability
    countPassageAbilities = 0
    hasDroitSps = false
    hasDroitAdm = false
    isMobileDevice = false

    /**
     * Chargement des compteurs
     * @return {void}
     */
    @Watch('dashboard')
    loadCompteurs(): void {
        this.countPassageAbilities++
        const authUser = this.$store.getters['auth/authUser']
        if (authUser && authUser.abilities && this.countPassageAbilities === authUser.abilities.length) {
            // Compteurs chargés, on appelle le dashboard
            this.countPassageAbilities = 0
            this.initMenu()
        }
    }

    /**
     * Initialisation du menu
     * @return {Promise<void>}
     */
    async initMenu(): Promise<void> {
        if (this.$store.getters['auth/is_intervenant_centre']) {
            this.$store.state.user.centreId = null
            this.$store.state.user.conventionPublished = null
            await this.$store.dispatch('centre/getCentres')
            this.$store.state.user.centreId = this.$store.state.centre.centres[0]?.id || null
            this.$store.state.user.centreTypePassation = this.$store.state.centre.centres[0]?.type_passation || null
            this.$store.state.user.centre_organisation_ecrit_validated = this.$store.state.centre.centres[0]?.nb_intervenants_validated_at || null
            if (this.$store.state.centre.centres[0]) {
                this.$store.commit('centre/SET_CENTRE_SELECT', this.$store.state.centre.centres[0])
            }
            this.$store.state.user.conventionPublished = this.$store.state.centre.centres[0]?.convention_published_at || null
        }


        if (this.hasDroitSps) {
            this.$store.state.user.dossieracademiqueId = null
            await this.$store.dispatch('dossieracademique/getDossiersAcademiques')
            const da = this.$store.getters['dossieracademique/dossierAcademiqueByUserId'](this.$store.getters['auth/authUser'].id)
            if (da) {
                this.$store.state.user.dossieracademiqueId = da.courant?.id || da.id || null
            }
        }

        if (this.hasDroitAdm && !this.$store.state.dossieradministratif.loading) {
            this.$store.state.user.dossieradministratifId = null
            await this.$store.dispatch('dossieradministratif/getDossiersAdministratifs')
            this.$store.state.user.dossieradministratifId = this.$store.state.dossieradministratif.dossiersAdministratifs[0]?.id || null

            const dossiersAdministratifs = this.$store.getters['dossieradministratif/dossiersAdministratifs']
            if (dossiersAdministratifs[0]?.contrats?.length > 0) {
                this.$store.commit('dossieradministratif/SET_CONTRATS', dossiersAdministratifs[0].contrats)
                this.$store.state.user.hasContrat = true
            }
        }
    }

    /**
     * Montage du composant
     * @returns {void}
     */
    mounted(): void {
        // Detection si le device est un mobile
        this.isMobileDevice = 'ontouchstart' in document.documentElement;

        const authUser = this.$store.getters['auth/authUser']
        if (authUser && authUser.abilities && authUser.abilities.length !== 0) {
            this.$store.state.user.dashboard = []

            authUser.abilities.forEach((ability: any): void => {
                if (ability.name === Ability.RH_SPS_DOS_OWN_MANAGE) {
                    this.hasDroitSps = true
                }
                if (ability.name === Ability.RH_ADM_DOS_OWN_MANAGE) {
                    this.hasDroitAdm = true
                }
            })
        }
    }
}
