import { getError } from '@/utils/helpers'
import OrdreMissionService from '@/services/OrdreMissionService'
import { OrdreMissionInterface, StateOrdreMissions } from '@/types/OrdreMission'

export const namespaced = true

function setOrdreMissions(commit: Function, response: any) {
    commit('SET_ORDREMISSIONS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_LOADING', false)
}

export const state: StateOrdreMissions = {
    ordreMissions: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    save_finish: false,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    ordreMissionSelect: {},
    source_pdf: ''
}

export const mutations = {
    RESET_STATE(state: StateOrdreMissions) {
        Object.assign(state, {
            ordreMissions: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            ordreMissionSelect: {}
        })
    },
    SET_ORDREMISSIONS(state: any, ordreMissions: Array<OrdreMissionInterface>) {
        state.ordreMissions = []
        for (const o in ordreMissions) {
            const ordre_mission_temp: any = ordreMissions[o]
            let datas_array = []
            if (ordre_mission_temp.datas.length !== undefined) {
                datas_array = ordre_mission_temp.datas
            } else {
                datas_array.push(ordre_mission_temp.datas)
            }

            ordre_mission_temp.datas = datas_array
            state.ordreMissions.push(ordre_mission_temp)
        }
    },
    SET_MORE_ORDREMISSIONS(state: any, ordreMissions: Array<OrdreMissionInterface>) {
        state.ordreMissions = state.ordreMissions.concat(ordreMissions)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        // state.totalRows = state.ordreMissions.length
        state.totalRows = totalRows
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_ORDREMISSION(state: any, ordreMission: any) {
        let datas_array = []
        if (ordreMission.datas.length !== undefined) {
            datas_array = ordreMission.datas
        } else {
            datas_array.push(ordreMission.datas)
        }
        state.ordreMissionSelect = ordreMission
        state.ordreMissionSelect.datas = datas_array
    },
    SET_SAVE_FINISH(state: any, saveFinish: boolean) {
        state.save_finish = saveFinish
    },
    SET_SOURCE_PDF(state: any, source: string) {
        state.source_pdf = source
    }
}

export const actions = {
    getOrdreMissions({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            OrdreMissionService.getOrdreMissions(params)
                .then((response) => {
                    setOrdreMissions(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getOrdreMission({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            OrdreMissionService.getOrdreMission(params.ordremission_id)
                .then((response) => {
                    commit('SET_ORDREMISSION', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreOrdreMissions({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            OrdreMissionService.getOrdreMissions(params)
                .then((response) => {
                    commit('SET_MORE_ORDREMISSIONS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateOrdreMission({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        commit('SET_SAVE_FINISH', false)
        return new Promise((resolve, reject) => {
            OrdreMissionService.updateOrdreMission(params.ordremission_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_SAVE_FINISH', true)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateOrdreMissionByIntervenant({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        commit('SET_SAVE_FINISH', false)
        return new Promise((resolve, reject) => {
            OrdreMissionService.updateOrdreMissionByIntervenant(params.ordremission_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_SAVE_FINISH', true)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addOrdreMission({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        commit('SET_SAVE_FINISH', false)
        return new Promise((resolve, reject) => {
            OrdreMissionService.addOrdreMission(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_SAVE_FINISH', true)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteOrdreMission({ commit }: { commit: any }, ordremission_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            OrdreMissionService.deleteOrdreMission(ordremission_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDocumentOrdreMission({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            OrdreMissionService.getDocumentOrdreMission(params.ordremission_id, params.document_id)
                .then((response) => {
                    commit('SET_SOURCE_PDF', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    ordremissions: (state: any) => {
        return state.ordreMissions
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        // return state.ordreMissions.length
        return state.totalRows
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    ordreMissionSelect: (state: any) => {
        return state.ordreMissionSelect
    },
    source_pdf: (state: any) => {
        return state.source_pdf
    }
}
