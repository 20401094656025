import { getError } from '@/utils/helpers'
import CentreFournitureAdministrativeService from '@/services/CentreFournitureAdministrativeService'
import { CentreFAInterface, StateCentreFA } from '@/types/CentreFA'

export const namespaced = true

function setCentreFA(commit: Function, response: any) {
    commit('SET_CENTRE_FA', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateCentreFA = {
    centre_fa: [],
    meta: null,
    links: null,
    loading_cfa: false,
    error_cfa: null,
    totalRows: 0,
    centre_fa_select: {}
}

export const mutations = {
    RESET_STATE(state: StateCentreFA) {
        Object.assign(state, {
            centre_fa: [],
            meta: null,
            links: null,
            loading_cfa: false,
            error_cfa: null,
            totalRows: 0,
            centre_fa_select: {}
        })
    },
    SET_CENTRE_FA(state: any, centre_fa: Array<CentreFAInterface>) {
        state.centre_fa = centre_fa
    },
    SET_MORE_CENTRE_FA(state: any, centre_fa: Array<CentreFAInterface>) {
        state.centre_fa = state.centre_fa.concat(centre_fa)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading_cfa = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error_ce = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_CENTRE_FA_SELECT(state: any, centre_fa: CentreFAInterface) {
        state.centre_fa_select = centre_fa
    }
}

export const actions = {
    getCentreFournituresAdministratives({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreFournitureAdministrativeService.getCentreFournituresAdministratives(payload.centre_id, payload.params)
                .then((response) => {
                    setCentreFA(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreCentreFournituresAdministratives({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreFournitureAdministrativeService.getCentreFournituresAdministratives(payload.centre_id, payload.params)
                .then((response) => {
                    commit('SET_MORE_CENTRE_FA', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    saveCentreFournituresAdministratives({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreFournitureAdministrativeService.saveFournituresAdministratives(payload.centre_id, payload.params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateDateImpressionCentres({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreFournitureAdministrativeService.updateDateImpressionCentres(payload.centre_id, payload.params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateDateImpressionFournitures({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreFournitureAdministrativeService.updateDateImpressionFournitures(payload.fourniture_id, payload.params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPDF({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreFournitureAdministrativeService.getPDF(payload.centre_id)
                .then((response) => {
                    commit('SET_PDF', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    centre_fa: (state: any) => {
        return state.centre_fa
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading_cfa
    },
    error: (state: any) => {
        return state.error_cfa
    },
    totalRows: (state: any) => {
        return state.centre_fa.length
    },
    centre_fa_select: (state: any) => {
        return state.centre_fa_select
    }
}
