import * as API from '@/services/API'
import store from '@/store'

export default {
    async getConditionnement(conditionnement_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/conditionnements/${conditionnement_id}`)
    },
    async getConditionnements(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/conditionnements`, { params: payload })
    },
    async postConditionnement(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/conditionnements`, payload)
    },
    async putConditionnement(conditionnement_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/conditionnements/${conditionnement_id}`, payload)
    },
    async deleteConditionnement(conditionnement_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/conditionnements/${conditionnement_id}`)
    }
}
