import { getError } from '@/utils/helpers'
import ContratTypeService from '@/services/ContratTypeService'
import { ContratTypeInterface, StateContratType } from '@/types/ContratType'

export const namespaced = true
export const namespace = true

function setContrats(commit: any, response: any) {
    commit('SET_CONTRAT_TYPES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateContratType = {
    contrattypes: [],
    contrattype_select: null,
    meta: null,
    links: null,
    loading: false,
    error: null,
    lastPage: 0,
    totalRows: 0,
    source_pdf: ''
}

export const mutations = {
    RESET_STATE(state: StateContratType) {
        Object.assign(state, {
            contrattypes: [],
            contrattype_select: null,
            meta: null,
            links: null,
            loading: false,
            error: null,
            lastPage: 0,
            totalRows: 0,
            source_pdf: ''
        })
    },
    SET_CONTRAT_TYPES(state: any, contrattypes: Array<ContratTypeInterface>) {
        state.contrattypes = contrattypes
    },
    SET_CONTRAT_TYPE(state: any, contrattype: ContratTypeInterface) {
        state.contrattype_select = contrattype
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_PDF(state: any, source: string) {
        state.source_pdf = source
    }
}

export const actions = {
    getContratTypes({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ContratTypeService.getContratTypes(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    setContrats(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getContratType({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ContratTypeService.getContratType(params.contrat_type_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_CONTRAT_TYPE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateContratType({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ContratTypeService.updateContratType(params.contrattype_id, params.payload)
                .then((response) => {
                    commit('SET_CONTRAT_TYPE', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    saveContratType({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ContratTypeService.saveContratType(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_CONTRAT_TYPE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteContratType({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ContratTypeService.deleteContratType(params.contrattype_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPDF({ commit }: { commit: any }, contrattype_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ContratTypeService.getPDF(contrattype_id)
                .then((response) => {
                    commit('SET_PDF', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    contrattypes: (state: StateContratType) => {
        return state.contrattypes
    },
    contrattype_select: (state: StateContratType) => {
        return state.contrattype_select
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.concours.length
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    source_pdf: (state: any) => {
        return state.source_pdf
    }
}
