/* eslint-disable @typescript-eslint/no-unused-vars */
import {addDaysToDate, diffTwoDatesInDays, formatDateDocument, formatDateLibelle, formatDateYYYYMMDD, formatDayDate, getError} from '@/utils/helpers'
import CentreService from '@/services/CentreService'
import {CentreInterface, StateCentres} from '@/types/Centre'
import {PosteContext, PosteInterface} from '@/types/Poste'
import Vue from 'vue'
import {SalleInterface} from '@/types/Salle'

export const namespaced = true

function setCentres(commit: Function, response: any) {
    commit('SET_CENTRES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_LOADING', false)
}

function setConventions(commit: Function, response: any) {
    commit('SET_CONVENTIONS_ADM', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_LOADING', false)
}

function setCentreUsers(commit: Function, response: any) {
    commit('SET_CENTRE_USERS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_LOADING', false)
}

export const state = {
    centres: [],
    meta: null,
    links: null,
    loading: false,
    loading_edition: false,
    loading_search: false,
    loading_count: false,
    loading_pdf: false,
    error: null,
    error_conflict: {
        datas: [],
        message: null
    },
    countError: 0,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    centreSelect: {},
    usersFromSearch: null,
    centre_users_select: [],
    users_count: [],
    source_pdf: null,
    conventions: [],
    reponse_token: null,
    save_in_progress: false,
    importEstimationsList: [],
    order_save: false,
    datas_infos: null,
    centre_select_id: 0
}

export const mutations = {
    RESET_STATE(state: StateCentres) {
        Object.assign(state, {
            centres: [],
            meta: null,
            links: null,
            loading: false,
            loading_search: false,
            loading_count: false,
            loading_pdf: false,
            error: null,
            error_conflict: {
                datas: [],
                message: null
            },
            countError: 0,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            centreSelect: {},
            usersFromSearch: null,
            centre_users_select: [],
            users_count: [],
            source_pdf: null,
            conventions: [],
            reponse_token: null,
            order_save: false,
            datas_infos: null,
            centre_select_id: 0
        })
    },
    SET_CENTRES(state: any, centres: Array<any>) {
        state.centres = centres
    },
    SET_CONVENTIONS(state: any, conventions: Array<CentreInterface>) {
        state.conventions = conventions
    },
    SET_CONVENTIONS_ADM(state: any, centres: Array<CentreInterface>) {
        // state.conventions = centres.filter((c: CentreInterface) => c.type !== TypeCentre.TYPE_NON_CONVENTIONNE)
        state.conventions = centres.filter((c: CentreInterface) => c.conventionne)
        state.totalRows = state.conventions.length
    },
    SET_MORE_CENTRES(state: any, centres: Array<CentreInterface>) {
        state.centres = state.centres.concat(centres)
    },
    SET_MORE_CONVENTIONS(state: any, centres: Array<CentreInterface>) {
        // const non_coventionnes = centres.filter((c: CentreInterface) => c.type !== TypeCentre.TYPE_NON_CONVENTIONNE)
        const non_coventionnes = centres.filter((c: CentreInterface) => c.conventionne)
        state.conventions = state.conventions.concat(non_coventionnes)
        state.totalRows = state.conventions.length
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_LOADING_EDITION(state: any, loading: boolean) {
        state.loading_edition = loading
    },
    SET_LOADING_SEARCH(state: any, loading: boolean) {
        state.loading_search = loading
    },
    SET_LOADING_PDF(state: any, loading: boolean) {
        state.loading_pdf = loading
    },
    SET_LOADING_COUNT(state: any, loading: boolean) {
        state.loading_count = loading
    },
    SET_ERROR_FULL(state: any, error: Array<any>) {
        state.error = error
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error_conflict = {
            datas: [],
            message: null
        }
        state.error = error
        state.countError++
    },
    SET_ERROR_CONFLICT(state: any, error: any) {
        state.error_conflict = {
            datas: error.data,
            message: error.message
        }
        state.countError++
    },
    SET_ERROR_DATAS(state: any, datas: Array<any>) {
        state.error_conflict.datas = datas
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CENTRE_SELECT(state: any, centre: CentreInterface | null) {
        state.centreSelect = centre
    },
    SET_CENTRE_USERS(state: any, centre_users: Array<any>) {
        // Retrait du chef de centre et du chef d'établissement que l'on gère à part
        state.centre_users_select = []
        for (const cu in centre_users) {
            const filter_users = centre_users[cu].postes.filter(
                (p: PosteInterface) =>
                    p.context !== PosteContext.CONTEXT_CHEF_CENTRE &&
                    p.context !== PosteContext.CONTEXT_CORRESPONDANT_ORAL &&
                    p.context !== PosteContext.CONTEXT_RESPONSABLE_CENTRE
            )
            if (filter_users.length !== 0) {
                state.centre_users_select = state.centre_users_select.concat(centre_users[cu])
            }
        }
        state.totalRows = state.centre_users_select.length
    },
    SET_MORE_CENTRE_USERS(state: any, centre_users: Array<any>) {
        state.centre_users_select = state.centre_users_select.concat(centre_users)
    },
    SET_USER_FROM_SEARCH(state: any, usersFromSearch: Array<any>) {
        state.usersFromSearch = usersFromSearch
    },
    SET_USERS_COUNT(state: any, users_count: Array<any>) {
        state.users_count = users_count
    },
    SET_PDF_SOURCE(state: any, source: string) {
        state.source_pdf = source
    },
    MAJ_COLLECTION_UPDATE(state: any, centre: any) {
        const index = state.centres.map((c: any) => c.id).indexOf(centre.id)
        Vue.set(state.centres, index, centre)

        if (state.conventions.length !== 0) {
            const indexConv = state.conventions.map((c: any) => c.id).indexOf(centre.id)
            Vue.set(state.conventions, indexConv, centre)
        }
    },
    SET_REPONSE_TOKEN(state: any, response: any) {
        state.reponse_token = response
    },
    MAJ_SALLE_CENTRE_SELECT(state: any, salle: SalleInterface) {
        const index = state.centreSelect.salles.map((salle: SalleInterface) => salle.id).indexOf(salle.id)
        Vue.set(state.centreSelect.salles, index, salle)
    },
    ADD_SALLE_CENTRE_SELECT(state: any, salle: SalleInterface) {
        state.centreSelect.salles.push(salle)
    },
    SET_SAVE_IN_PROGRESS(state: any, etat: boolean) {
        state.save_in_progress = etat
    },
    MAJ_SALLES_CENTRE_SELECT(state: any, salles: Array<SalleInterface>) {
        state.centreSelect.salles = []
        state.centreSelect.salles = salles
    },
    SET_CENTRE_ESTIMATIONS_LIST(state: any, estimations_list: Array<any>) {
        // set du state récupérant la liste des estimations retournée par le serveur
        state.importEstimationsList = []
        state.importEstimationsList = estimations_list
    },
    SET_ORDER_SAVE(state: any, etat: boolean) {
        state.order_save = etat
    },
    SET_DATAS_INFORMATIONS(state: any, datas: any) {
        state.datas_infos = {
            id: state.centreSelect.id,
            message_candidat: state.centreSelect.message_candidat,
            email_contact_candidat: state.centreSelect.email_contact_candidat,
            message_adresses_submit: datas.params.message_adresses_submit,
            message_adresses_validate: datas.params.message_adresses_validate,
            commentaire_rejet: datas.params.commentaire_rejet
        }
        state.centre_select_id = state.centreSelect.id
    },
    ADD_CENTREUSER_LIGNE(state: any) {
        state.centreSelect.centreusers.push({
            id: 199,
            centre_id: 158,
            user_id: 1,
            user: {
                id: 1,
                civility: 'M.',
                name: 'DAUGA',
                first_name: 'Stéphane',
                email: 's.dauga@exatech-group.com',
                telephone: null,
                code: null,
                password_changed: true,
                email_verified: true,
                rgpd_accepted: true,
                two_factor: false,
                roles: [],
                abilities: [],
                notified: true
            },
            validated_at: null,
            dossier_administratif_id: null,
            postes: [
                {
                    id: 10,
                    type: 2,
                    context: 4,
                    name: 'Chef de centre adjoint',
                    name_plural: 'Chefs de centre adjoints',
                    regroupement: 'Administratif',
                    type_passation: 0,
                    entity_type: 'App\\Models\\Centre',
                    options: null,
                    options_affectation: { max: 1 },
                    temps: 100
                }
            ]
        })
    },
    ADD_CENTRE(state: any, centre: any) {
        state.centres.push(centre)
        state.meta.total = state.centres.length
    }
}

export const actions = {
    getCentres({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.getCentres(params)
                .then((response) => {
                    setCentres(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getCentre({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING_EDITION', true)
        return new Promise((resolve, reject) => {
            CentreService.getCentre(params.centre_id, params.params)
                .then((response) => {
                    commit('organisationecrit/RESET_STATE', null, { root: true })
                    commit('SET_CENTRE_SELECT', response.data.data)
                    commit('MAJ_COLLECTION_UPDATE', response.data.data)
                    commit('organisationecrit/SET_DICO_SALLES', response.data.data.salles, { root: true })
                    // commit('organisationecrit/PARSE_COLLECTION_SALLES', null, { root: true })
                    if (response.data.data.besoins) {
                        commit('organisationecrit/SET_BESOINS_CENTRE', response.data.data.besoins, { root: true })
                    }
                    if (response.data.data.precedent && response.data.data.precedent.besoins) {
                        commit('organisationecrit/SET_BESOINS_CENTRE_PRECEDENT', response.data.data.precedent.besoins, {
                            root: true
                        })
                    }

                    resolve(response)
                    commit('SET_LOADING_EDITION', false)
                })
                .catch((error) => {
                    commit('SET_LOADING_EDITION', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getCentreUsers({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.getCentreUsers(params.centre_id, params.params)
                .then((response) => {
                    setCentreUsers(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreCentres({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.getCentres(params)
                .then((response) => {
                    commit('SET_MORE_CENTRES', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreCentreUsers({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.getCentreUsers(params.centre_id, params.params)
                .then((response) => {
                    commit('SET_MORE_CENTRE_USERS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateCentre({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING_EDITION', true)
        return new Promise((resolve, reject) => {
            CentreService.updateCentre(params)
                .then((response) => {
                    commit('SET_LOADING_EDITION', false)
                    commit('MAJ_COLLECTION_UPDATE', response.data.data)
                    commit('SET_CENTRE_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_EDITION', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateCentreFormData({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING_EDITION', true)
        return new Promise((resolve, reject) => {
            CentreService.updateCentreFormData(params.centre_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING_EDITION', false)
                    commit('MAJ_COLLECTION_UPDATE', response.data.data)
                    commit('SET_CENTRE_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_EDITION', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addCentre({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING_EDITION', true)
        return new Promise((resolve, reject) => {
            CentreService.addCentre(params)
                .then((response) => {
                    commit('SET_LOADING_EDITION', false)
                    commit('ADD_CENTRE', response.data.data)
                    commit('SET_CENTRE_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_EDITION', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteCentre({ commit }: { commit: any }, centre_id: number) {
        commit('SET_LOADING_EDITION', true)
        return new Promise((resolve, reject) => {
            CentreService.deleteCentre(centre_id)
                .then((response) => {
                    commit('SET_LOADING_EDITION', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_EDITION', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteRIB({ commit }: { commit: any }, centre_id: number) {
        commit('SET_LOADING_EDITION', true)
        return new Promise((resolve, reject) => {
            CentreService.deleteRIB(centre_id)
                .then((response) => {
                    commit('SET_LOADING_EDITION', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_EDITION', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addDossierUser({ commit }: { commit: any }, params: any) {
        // commit('SET_LOADING', true)
        state.countError = 0
        return new Promise((resolve, reject) => {
            CentreService.addDossierUser(params.centre_id, params)
                .then((response) => {
                    // commit('SET_LOADING', false)
                    commit('SET_ERROR_FULL', null)
                    state.countError = 0
                    resolve(response)
                })
                .catch((error) => {
                    // commit('SET_LOADING', false)
                    if (error.response.status === 409) {
                        commit('SET_ERROR_CONFLICT', error.response.data)
                        reject(error)
                    } else {
                        commit('SET_ERROR_FULL', getError(error))
                        reject(error)
                    }
                })
        })
    },
    getDossierUser({ commit }: { commit: any }, params: { centre_id: number; payload: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.getDossierUser(params.centre_id, params.payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    dissociateUserFromCentre({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.dissociateUserFromCentre(params.centre_id, params.centre_user_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateUserFromCentre({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.updateUserFromCentre(params.centre_id, params.centre_user_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getUsersFromSearch({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING_SEARCH', true)
        return new Promise((resolve, reject) => {
            CentreService.getUsersFromSearch(params.centre_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING_SEARCH', false)
                    commit('SET_USER_FROM_SEARCH', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getUsersCount({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING_COUNT', true)
        return new Promise((resolve, reject) => {
            CentreService.getUsersCount(params.centre_id)
                .then((response) => {
                    commit('SET_LOADING_COUNT', false)
                    commit('SET_USERS_COUNT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_COUNT', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    validateIntervenantFromCentre({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.validateIntervenantFromCentre(params.centre_id, params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    },
    getConventionPDF({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING_PDF', true)
        return new Promise((resolve, reject) => {
            CentreService.getConventionPdf(params.centre_id)
                .then((response) => {
                    commit('SET_LOADING_PDF', false)
                    commit('SET_PDF_SOURCE', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_PDF', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getAnnexeConventionPDF({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING_PDF', true)
        return new Promise((resolve, reject) => {
            CentreService.getAnnexeConventionPdf(params.centre_id)
                .then((response) => {
                    commit('SET_LOADING_PDF', false)
                    commit('SET_PDF_SOURCE', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_PDF', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getRIBPdf({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING_PDF', true)
        return new Promise((resolve, reject) => {
            CentreService.getRIBPdf(params.centre_id)
                .then((response) => {
                    commit('SET_LOADING_PDF', false)
                    commit('SET_PDF_SOURCE', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_PDF', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getConventions({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.getConventions(params && params.centre_id ? params.centre_id : null)
                .then((response) => {
                    commit('SET_CONVENTIONS', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getTokenSignature({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.getTokenSignature(params.centre_id, params.mode)
                .then((response) => {
                    commit('SET_REPONSE_TOKEN', response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getConventionsAdm({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.getCentres(params)
                .then((response) => {
                    setConventions(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreConventionsAdm({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.getCentres(params)
                .then((response) => {
                    commit('SET_MORE_CONVENTIONS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getExportCentres({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.exportCentres()
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getExportSalles({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.exportSalles()
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getExportCentresEstimations({ commit }: { commit: any }) {
        return new Promise((resolve, reject) => {
            CentreService.exportCentresEstimations()
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    uploadCentresEstimations({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.uploadCentresEstimations(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_CENTRE_ESTIMATIONS_LIST', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    },
    manageInformations({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            commit('organisationecrit/PARSE_COLLECTION_SALLES', null, { root: true })
            commit('organisationecrit/SET_ERROR', null, { root: true })

            // toast.info('Enregistrement en cours ...')
            commit('organisationecrit/SET_NEW_ID', -1, { root: true })
            commit('SET_DATAS_INFORMATIONS', params)
            CentreService.updateCentre(state.datas_infos)
                .then((response) => {
                    // toast.success('Enregistrement terminé.')
                    commit('SET_LOADING', false)
                    commit('SET_SAVE_IN_PROGRESS', false)
                    commit('MAJ_COLLECTION_UPDATE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    relaunchCentre({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.relaunchCentre(params)
                .then((response) => {
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    relaunchCentres({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.relaunchCentres(params)
                .then((response) => {
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    majStatutFournitures({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.majStatutFournitures(params.centre_id, params.payload)
                .then((response) => {
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    relaunchSuiviFournituresCentre({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.relaunchSuiviFournituresCentre(params.centre_id)
                .then((response) => {
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    exportCommandeSujets({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.exportCommandeSujets(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    exportCommandeFournituresPap({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.exportCommandeFournituresPap()
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    exportDecisions({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.exportDecisions(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    exportCentresIndisponibilites({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.exportCentresIndisponibilites()
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    exportCentresRecapitulatif({ commit }: { commit: any }) {
        return new Promise((resolve, reject) => {
            CentreService.exportCentresRecapitulatif()
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateCentreFournituresStatut({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.updateCentreFournituresStatut(params)
                .then((response) => {
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateConditionnement({ commit }: { commit: any }, params: { centre_id: number; payload: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.updateConditionnement(params.centre_id, params.payload)
                .then((response) => {
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    }
}

export const getters = {
    centres: (state: any) => {
        return state.centres
    },
    centre_select_id: (state: any) => {
        return state.centreSelect.id
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    loading_search: (state: any) => {
        return state.loading_search
    },
    error: (state: any) => {
        return state.error
    },
    errorConflict: (state: any) => {
        return state.error_conflict
    },
    countError: (state: any) => {
        return state.countError
    },
    totalRows: (state: any) => {
        return state.centres.length
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    centreSelect: (state: any) => {
        return state.centreSelect
    },
    centre_users_select: (state: any) => {
        return state.centre_users_select
    },
    source_pdf: (state: any) => {
        return state.source_pdf
    },
    conventions: (state: any) => {
        return state.conventions
    },
    responseToken: (state: any) => {
        return state.response_token
    },
    loading_pdf: (state: any) => {
        return state.loading_pdf
    },
    loading_edition: (state: any) => {
        return state.loading_edition
    },
    save_in_progress: (state: any) => {
        return state.save_in_progress
    },
    informations_is_submit: (state: any) => {
        if (state.centreSelect && state.centreSelect.message_submitted_at && state.centreSelect.adresses_submitted_at) {
            return true
        } else {
            return false
        }
    },
    informations_is_validate: (state: any) => {
        if (state.centreSelect && state.centreSelect.message_validated_at && state.centreSelect.adresses_validated_at) {
            return true
        } else {
            return false
        }
    },
    salles_is_submit: (state: any) => {
        if (state.centreSelect && state.centreSelect.salles_submitted_at) {
            return true
        } else {
            return false
        }
    },
    salles_is_validate: (state: any) => {
        if (state.centreSelect && state.centreSelect.salles_validated_at) {
            return true
        } else {
            return false
        }
    },
    nb_intervenants_is_submit: (state: any) => {
        if (state.centreSelect && state.centreSelect.nb_intervenants_submitted_at) {
            return true
        } else {
            return false
        }
    },
    nb_intervenants_is_validate: (state: any) => {
        if (state.centreSelect && state.centreSelect.nb_intervenants_validated_at) {
            return true
        } else {
            return false
        }
    },
    has_commentaire_rejet_informations: (state: any) => {
        if (
            state.centreSelect &&
            state.centreSelect.commentaire_rejet &&
            state.centreSelect.commentaire_rejet.message_adresses
        ) {
            return true
        } else {
            return false
        }
    },
    has_commentaire_rejet_ecrit:
        (state: any) =>
            (from = '') => {
                if (
                    state.centreSelect &&
                state.centreSelect.commentaire_rejet &&
                (state.centreSelect.commentaire_rejet.salles || state.centreSelect.commentaire_rejet.besoins)
                ) {
                    if (
                        (state.centreSelect.commentaire_rejet.salles || state.centreSelect.commentaire_rejet.besoins) &&
                    from === ''
                    ) {
                        return true
                    } else if (state.centreSelect.commentaire_rejet.salles && from === 'salles') {
                        return true
                    } else if (state.centreSelect.commentaire_rejet.besoins && from === 'besoins') {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            },
    etat_centre_select: (state: any, getters: any) => {
        if (state.centreSelect && state.centreSelect.submitted_at) {
            if (getters.orga_is_validate && getters.informations_is_validate) {
                // Etat : Centre validé
                return { color: 'text-success', title: 'Centre validé' }
            } else if (
                state.centreSelect &&
                state.centreSelect.commentaire_rejet !== null &&
                state.centreSelect.commentaire_rejet.length !== 0
            ) {
                // Etat : Centre en correction
                return { color: 'text-danger', title: 'Corrections attendues' }
            } else {
                // Etat : Centre publié
                return { color: 'text-info', title: 'Centre publié' }
            }
        } else {
            // Etat : Centre créé
            return { color: 'text-light', title: 'Centre créé' }
        }
    },
    importEstimationsList: (state: any) => {
        return state.importEstimationsList
    },
    etat_centre_global: (state: any, getters: any) => (ligne: any) => {
        if (ligne.submitted_at) {
            if (ligne.adresses_validated_at && ligne.salles_validated_at) {
                return { color: 'success', title: 'Centre validé' }
            } else if (ligne.commentaire_rejet !== null && ligne.commentaire_rejet.length !== 0) {
                return { color: 'danger', title: 'Corrections attendues' }
            } else {
                return { color: 'info', title: 'Centre publié' }
            }
        } else {
            // Etat : Centre créé
            return { color: 'tertiary', title: 'Centre créé' }
        }
    },
    use_real_data: (state: any, getters: any) => (ligne: any) => {
        if (ligne.use_real_data_at) {
            return true
        } else {
            return false
        }
    },
    centre_select_chef_centre: (state: any) => {
        if (state.centreSelect && state.centreSelect.centreusers && state.centreSelect.centreusers.length > 0) {
            for (let i = 0; i < state.centreSelect.centreusers.length; i++) {
                if (state.centreSelect.centreusers[i].postes && state.centreSelect.centreusers[i].postes.length > 0) {
                    for (let j = 0; j < state.centreSelect.centreusers[i].postes.length; j++) {
                        if (state.centreSelect.centreusers[i].postes[j].context === PosteContext.CONTEXT_CHEF_CENTRE) {
                            return state.centreSelect.centreusers[i].user
                        }
                    }
                }
            }
        }
        return null
    },
    /** boolean, utilisé pour afficher le lien gestion des intervenants dans le dashboard  */
    is_nb_intervenants_validated_at: (state: any) => {
        if (state.centreSelect && state.centreSelect.nb_intervenants_validated_at) {
            return true
        } else {
            return false
        }
    },
    getNameCentreById: (state: any) => (id: number) => {
        const centre = state.centres.filter((c: any) => c.id === id)[0]
        return centre ? centre.name : ''
    },
    create_jours_livraison: () => (start_at: Date, end_at: Date) => {
        const journees: Array<any> = []
        // Créer toutes les journées entre les deux dates
        const daysDiff = diffTwoDatesInDays(start_at.toString(), end_at.toString())
        let currentDay = start_at
        for (let j = 0; j < daysDiff + 1; j++) {
            journees.push({
                jour: formatDateLibelle(currentDay), acro: formatDateDocument(currentDay), date: formatDateYYYYMMDD(currentDay), full: null, matin: null, aprem: null
            })
            currentDay = addDaysToDate(currentDay, 1)
        }

        return journees
    }
}
