import { formatDayDate, getError } from '@/utils/helpers'
import CandidatService from '@/services/CandidatService'
import {
    CandidatCommentaireInterface,
    CandidatEpreuveInterface,
    CandidatInterface,
    DataCandidatInterface,
    StateCandidats,
    TypeDataCandidat
} from '@/types/Candidat'
import Vue from 'vue'

export const namespaced = true

function setCandidats(commit: Function, response: any) {
    commit('SET_CANDIDATS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_LOADING', false)
}

function setPrevisionnelCandidatsEpreuves(commit: Function, response: any) {
    commit('SET_PREVISIONNEL_CANDIDATS_EPREUVES', response.data.data)
    commit('SET_LOADING', false)
}

/**
 * Pour un candidat récupéré en BDD, génération d'informations complémentaires
 * qui seront utilisées par l'interface
 */
function formatCandidat(candidat: CandidatInterface) {
    if (candidat.date_naissance) {
        const formatedDate: string | undefined = formatDayDate(new Date(candidat.date_naissance))
        if (formatedDate) {
            candidat.formattedDateNaissance = formatedDate
        }
    }

    if (candidat.name && candidat.first_name) {
        candidat.identite =
            candidat.name.toUpperCase() + ' ' + candidat.first_name[0].toUpperCase() + candidat.first_name.slice(1)
    }

    candidat.hasImportantComments = false
    if (!candidat.comments || candidat.comments.length === 0) {
        candidat.hasComments = false
        candidat.comments = []
    } else {
        candidat.hasComments = true
        for (const comment of candidat.comments) {
            if (comment.important === 1) {
                comment.important = true
                candidat.hasImportantComments = true
            } else {
                comment.important = false
            }
        }
    }

    if (candidat.amenagements && candidat.amenagements.length > 0) {
        for (const amenagement of candidat.amenagements) {
            if (amenagement.amenagement_id) {
                amenagement.id = amenagement.amenagement_id
            }
        }
    }
    return candidat
}

export const state: StateCandidats = {
    candidats: [],
    selectedCandidat: null,
    editedCandidat: null,
    meta: null,
    loading: false,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    liste_dossiers: [],
    loading_import: false,
    datasCandidat: [],
    error: null,
    candidatsWithImportantComments: [],
    loadingDashboardComment: false,
    datasRepartitionEquipes: {},
    previsionnelCandidatsEpreuves: []
}

export const mutations = {
    RESET_STATE(state: StateCandidats) {
        Object.assign(state, {
            candidats: [],
            selectedCandidat: null,
            editedCandidat: null,
            loading: false,
            meta: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            liste_dossiers: [],
            loading_import: false,
            datasCandidat: [],
            error: null,
            etatPrerequisRepartitionEquipes: {},
            previsionnelCandidatsEpreuves: []
        })
    },
    SET_CANDIDAT(state: any, candidat: CandidatInterface) {
        const indexCand = state.candidats.findIndex((c: any) => c.id === candidat.id)
        formatCandidat(candidat)
        if (indexCand === -1) {
            state.candidats.push(candidat)
        } else {
            state.candidats[indexCand] = candidat
        }
    },
    SET_CANDIDATS(state: any, candidats: Array<CandidatInterface>) {
        for (const candidat of candidats) {
            formatCandidat(candidat)
        }
        state.candidats = candidats
    },
    SET_MORE_CANDIDATS(state: any, candidats: Array<CandidatInterface>) {
        for (const candidat of candidats) {
            const indexCand = state.candidats.findIndex((c: any) => c.id === candidat.id)
            if (indexCand === -1) {
                formatCandidat(candidat)
                state.candidats.push(candidat)
            }
        }
    },
    SET_CANDIDAT_FILIERE(state: any, params: any) {
        if (params && params.candidatId && params.nomFiliere) {
            const indexCandidat = state.candidats.findIndex((c: CandidatInterface) => c.id === params.candidatId)
            if (indexCandidat !== -1) {
                state.candidats[indexCandidat].filiere = params.nomFiliere
            }
        }
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_SELECTED_CANDIDAT(state: any, idCandidat: number) {
        const indexCandidat = state.candidats.findIndex((cand: CandidatInterface) => cand.id === idCandidat)
        if (indexCandidat !== -1) {
            state.selectedCandidat = state.candidats[indexCandidat]
        }
    },
    SET_SELECTED_CANDIDAT_FROM_DASHBOARD(state: any, candidat: any) {
        state.selectedCandidat = candidat
    },
    SET_EDITED_CANDIDAT(state: any, candidatId: number) {
        const indexCandidat = state.candidats.findIndex((cand: CandidatInterface) => cand.id === candidatId)
        if (indexCandidat !== -1) {
            const candidatEdited = Object.assign({}, state.candidats[indexCandidat])

            // On effectue une copie de l'ensemble des objets inscriptions, amenagement, indisponibilites
            // afin de conserver les objets originaux

            candidatEdited.inscriptions = []
            if (state.candidats[indexCandidat].inscriptions && state.candidats[indexCandidat].inscriptions.length > 0) {
                for (const inscription of state.candidats[indexCandidat].inscriptions) {
                    candidatEdited.inscriptions.push(Object.assign({}, inscription))
                }
            }

            candidatEdited.amenagements = []
            if (state.candidats[indexCandidat].amenagements && state.candidats[indexCandidat].amenagements.length > 0) {
                for (const amenagement of state.candidats[indexCandidat].amenagements) {
                    candidatEdited.amenagements.push(Object.assign({}, amenagement))
                }
            }

            candidatEdited.indisponibilites = []
            if (
                state.candidats[indexCandidat].indisponibilites &&
                state.candidats[indexCandidat].indisponibilites.length > 0
            ) {
                for (const indisponibilite of state.candidats[indexCandidat].indisponibilites) {
                    candidatEdited.indisponibilites.push(Object.assign({}, indisponibilite))
                }
            }

            state.editedCandidat = candidatEdited
        }
    },
    RESET_EDITED_CANDIDAT(state: any) {
        state.editedCandidat = null
        state.selectedCandidat = null
    },
    MAJ_COLLECTION_UPDATE(state: any, candidat: any) {
        const index = state.candidats.map((c: any) => c.id).indexOf(candidat.id)
        if (index !== -1) {
            candidat = formatCandidat(candidat)
            Vue.set(state.candidats, index, candidat)
        }
    },
    MAJ_INDISPONIBILITES(state: any, indispos: any) {
        state.selectedCandidat.indisponibilites = indispos
    },
    ADD_EDITED_CANDIDAT_EPREUVE(state: any, epreuveId: number) {
        if (state.editedCandidat) {
            if (!state.editedCandidat.inscriptions) {
                state.editedCandidat.inscriptions = []
            }
            const newCandidatEpreuve: CandidatEpreuveInterface = {
                candidat_id: state.editedCandidat.id,
                centre_id: undefined,
                cle_epreuve_externe: null,
                demission: 0,
                epreuve_id: epreuveId,
                // note: undefined,
                // note_delib: undefined,
                note_brute: null,
                note_finale: null,
                salle_id: undefined,
                statut: 0
            }
            state.editedCandidat.inscriptions.push(newCandidatEpreuve)
        }
    },
    REMOVE_EDITED_CANDIDAT_EPREUVE(state: any, epreuveId: number) {
        if (state.editedCandidat && state.editedCandidat.inscriptions && state.editedCandidat.inscriptions.length > 0) {
            const indexCandidat = state.editedCandidat.inscriptions.findIndex(
                (inscription: CandidatEpreuveInterface) => inscription.epreuve_id === epreuveId
            )
            if (indexCandidat !== -1) {
                state.editedCandidat.inscriptions.splice(indexCandidat, 1)
            }
        }
    },
    SET_LISTE_DOSSIERS(state: any, dossiers: any) {
        state.liste_dossiers = dossiers
    },
    SET_LOADING_IMPORT(state: any, etat: boolean) {
        state.loading_import = etat
    },
    SET_DATAS_CANDIDAT(state: any, datascandidat: Array<DataCandidatInterface>) {
        state.datasCandidat = datascandidat
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    MAJ_EDITED_CANDIDAT_INSCRIPTION(state: any, inscriptions: any) {
        state.editedCandidat.inscriptions = inscriptions
    },
    MAJ_EDITED_CANDIDAT_SERIE(state: any, serie: any) {
        state.editedCandidat.serie = serie
        if (serie) {
            state.editedCandidat.serie_id = serie.id
        } else {
            state.editedCandidat.serie_id = null
        }
    },
    MAJ_EDITED_CANDIDAT_ENSEMBLES(state: any, ensembles: any) {
        state.editedCandidat.ensembles = ensembles
    },
    SET_DATA_CANDIDATS_WITH_IMPORTANT_COMMENTS(state: any, candidats: any) {
        state.candidatsWithImportantComments = candidats
    },
    // loading du dashboardOraux
    SET_LOADING_DASHBOARD_COMMENT(state: any, loading: any) {
        state.loadingDashboardComment = loading
    },
    SET_DATAS_REPARTITION_EQUIPES(state: any, datasRepartitionEquipes: any) {
        state.datasRepartitionEquipes = datasRepartitionEquipes
    },
    SET_PREVISIONNEL_CANDIDATS_EPREUVES(state: any, previsionnelCandidatsEpreuves: Array<any>) {
        state.previsionnelCandidatsEpreuves = previsionnelCandidatsEpreuves
    }
}

export const actions = {
    getCandidats({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.getCandidats(params)
                .then((response) => {
                    setCandidats(commit, response)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    commit('SET_META', response.data.meta ? response.data.meta : null)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getCandidat({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.getCandidat(params)
                .then((response) => {
                    commit('SET_CANDIDAT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getMoreCandidats({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.getCandidats(params)
                .then((response) => {
                    commit('SET_MORE_CANDIDATS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    updateCandidat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.updateCandidat(params)
                .then((response) => {
                    commit('MAJ_COLLECTION_UPDATE', response.data.data)
                    commit('MAJ_EDITED_CANDIDAT_INSCRIPTION', response.data.data.inscriptions)
                    commit('MAJ_EDITED_CANDIDAT_SERIE', response.data.data.serie)
                    commit('MAJ_INDISPONIBILITES', response.data.data.indisponibilites)

                    if (params && params.filiere) {
                        const paramsCommit = {
                            candidatId: params.id,
                            nomFiliere: params.filiere
                        }
                        commit('SET_CANDIDAT_FILIERE', paramsCommit)
                    }

                    commit('SET_EDITED_CANDIDAT', params.id)
                    commit('SET_SELECTED_CANDIDAT', params.id)
                    commit('SET_ERROR', null)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    updateEnsembleCandidat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.updateEnsembleCandidat(params)
                .then((response) => {
                    commit('MAJ_COLLECTION_UPDATE', response.data.data)
                    commit('MAJ_EDITED_CANDIDAT_ENSEMBLES', response.data.data.ensembles)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    addCommentCandidat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.addCommentCandidat(params)
                .then((response) => {
                    if (state.selectedCandidat) {
                        const commentTemp: CandidatCommentaireInterface = {
                            id: response.data.data.id,
                            important: response.data.data.important,
                            is_public: response.data.data.is_public,
                            body: response.data.data.body
                        }
                        const commentsTemp = JSON.parse(JSON.stringify(state.selectedCandidat.comments))
                        commentsTemp.push(commentTemp)
                        state.selectedCandidat.comments = commentsTemp
                    }
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    updateCommentCandidat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.updateCommentCandidat(params)
                .then((response) => {
                    if (state.selectedCandidat && state.selectedCandidat.comments) {
                        if (params) {
                            for (let i = 0; i < state.selectedCandidat.comments.length; i++) {
                                if (state.selectedCandidat.comments[i].id === params.comment_id) {
                                    state.selectedCandidat.comments[i].important = params.important
                                    state.selectedCandidat.comments[i].is_public = params.is_public
                                    state.selectedCandidat.comments[i].body = params.body
                                }
                            }
                        }
                    }
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    deleteCommentCandidat({ commit }: { commit: any }, comment_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.deleteCommentCandidat(comment_id)
                .then((response) => {
                    if (state.selectedCandidat && state.selectedCandidat.comments) {
                        const commentsTemp = []
                        for (let i = 0; i < state.selectedCandidat.comments.length; i++) {
                            if (state.selectedCandidat.comments[i].id !== comment_id) {
                                commentsTemp.push(state.selectedCandidat.comments[i])
                            }
                        }
                        state.selectedCandidat.comments = commentsTemp
                    }
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    updateCommentsCandidat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.updateCommentsCandidat(params)
                .then((response) => {
                    if (state.selectedCandidat) {
                        if (params && params.comments) {
                            for (const comment of params.comments) {
                                comment.important = comment.important === 1;
                            }
                        }
                        state.selectedCandidat.comments = params.comments
                    }
                    const candidat: any = state.candidats.filter((candidat) => candidat.id === params.id)
                    if (response.data.data[0] !== undefined) {
                        candidat[0].comments = response.data.data
                    } else {
                        candidat[0].comments = null
                    }

                    commit('MAJ_COLLECTION_UPDATE', candidat[0])

                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getDossiers({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.getDossiers(params)
                .then((response) => {
                    commit('SET_LISTE_DOSSIERS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    updateDossier({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        commit('SET_LOADING_IMPORT', true)
        return new Promise((resolve, reject) => {
            CandidatService.updateDossiers(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                    commit('SET_LOADING_IMPORT', false)
                })
        })
    },
    getDatasCandidat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.getDatasCandidat(params)
                .then((response) => {
                    commit('SET_DATAS_CANDIDAT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getCandidatsWithImportantComments({ commit }: { commit: any }) {
        commit('SET_LOADING_DASHBOARD_COMMENT', true)
        return new Promise((resolve, reject) => {
            CandidatService.getCandidatsWithImportantComments()
                .then((response) => {
                    commit('SET_DATA_CANDIDATS_WITH_IMPORTANT_COMMENTS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING_DASHBOARD_COMMENT', false)
                })
        })
    },
    getEtatPrerequisRepartitionEquipes({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.getEtatPrerequisRepartitionEquipes()
                .then((response) => {
                    commit('SET_DATAS_REPARTITION_EQUIPES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    lancementRepartitionEquipes({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.lancementRepartitionEquipes()
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    annulerRepartitionEquipes({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.annulerRepartitionEquipes()
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getPrevisionnelTransfere({ commit }: { commit: any }, params: { publication_id: number; payload: any }) {
        return new Promise((resolve, reject) => {
            CandidatService.getPrevisionnelTransfere(params.publication_id, params.payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    postTransfereDossier({ commit }: { commit: any }, params: { publication_id: number; payload: any }) {
        return new Promise((resolve, reject) => {
            CandidatService.postTransfereDossier(params.publication_id, params.payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getHistoriqueTransfert({ commit }: { commit: any }) {
        return new Promise((resolve, reject) => {
            CandidatService.getHistoriqueTransfert()
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updatePortail({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.updatePortail(params)
                .then((response) => {
                    // commit('MAJ_COLLECTION_UPDATE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getCandidatsAmenagements({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.getCandidatsAmenagements(params)
                .then((response) => {
                    setCandidats(commit, response)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    commit('SET_META', response.data.meta ? response.data.meta : null)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getMoreCandidatsAmenagements({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.getCandidatsAmenagements(params)
                .then((response) => {
                    commit('SET_MORE_CANDIDATS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getExportCandidatsAmenagements({ commit }: { commit: any }) {
        return new Promise((resolve, reject) => {
            CandidatService.getExportCandidatsAmenagements()
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPDFAmenagements({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            if (params.ecrit === true) {
                CandidatService.getPDFAmenagementsEcrit(params.candidat_id)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_ERROR', getError(error))
                        reject(error)
                    })
            } else {
                CandidatService.getPDFAmenagementsOral(params.candidat_id)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_ERROR', getError(error))
                        reject(error)
                    })
            }
        })
    },
    getExportCandidatsAdmissibles({ commit }: { commit: any }) {
        return new Promise((resolve, reject) => {
            CandidatService.getExportCandidatsAdmissibles()
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getExportCandidatsAdmission({ commit }: { commit: any }, params: { banque_id: number; phase_id: number }) {
        return new Promise((resolve, reject) => {
            CandidatService.getExportCandidatsAdmission(params.banque_id, params.phase_id)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPrevionnelCandidatEpreuves({ commit }: { commit: any }, params?: any) {
        // commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.getPrevionnelCandidatEpreuves(params)
                .then((response) => {
                    setPrevisionnelCandidatsEpreuves(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    // commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updatePrevionnelCandidatEpreuves({ commit }: { commit: any }, params: any) {
        // commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.updatePrevionnelCandidatEpreuves(params)
                .then((response) => {
                    // commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    // commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDemandeAmenagementCandidatEcritExport({ commit }: { commit: any }) {
        return new Promise((resolve, reject) => {
            CandidatService.getDemandeAmenagementCandidatEcritExport()
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDemandeAmenagementCandidatOralExport({ commit }: { commit: any }) {
        return new Promise((resolve, reject) => {
            CandidatService.getDemandeAmenagementCandidatOralExport()
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getRelevePdf({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.getRelevePdf(params.candidat_id, params.concour_id, params.phase_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    exportCandidatsConcours({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.exportCandidatsConcours()
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    exportCandidatsInscrits({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.exportCandidatsInscrits()
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    exportTemplateSeries({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.exportTemplateSeries()
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    importSeries({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.importSeries(payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    import({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.import(payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    retryPublication({ commit }: { commit: any }, params: { candidat_id: number; phase_id: number; payload: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.retryPublication(params.candidat_id, params.phase_id, params.payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getConvocationCandidat({ commit }: { commit: any }, payload: { candidat_id: number; phase_id: number }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.getConvocationCandidat(payload.candidat_id, payload.phase_id)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    }
    // candidatconcourphaselistes
}

export const getters = {
    candidats: (state: any) => {
        return state.candidats
    },
    selectedCandidat: (state: any) => {
        return state.selectedCandidat
    },
    editedCandidat: (state: any) => {
        return state.editedCandidat
    },
    error: (state: any) => {
        return state.error
    },
    meta: (state: any) => {
        return state.meta
    },
    loading: (state: any) => {
        return state.loading
    },
    totalRows: (state: any) => {
        return state.totalRows
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    datasCandidat: (state: any) => {
        return state.datasCandidat
    },
    csp_parents: (state: any) => {
        return state.datasCandidat.filter(
            (dc: DataCandidatInterface) => dc.type === TypeDataCandidat.TYPE_CATEGORIE_SOCIO_PROF
        )
    },
    edited_candidat_inscriptions: (state: any) => {
        return state.editedCandidat ? state.editedCandidat.inscriptions : undefined
    },
    candidats_with_important_comments: (state: any) => {
        return state.candidatsWithImportantComments
    },
    loading_dashboard_comment: (state: any) => {
        return state.loadingDashboardComment
    },
    datasRepartitionEquipes: (state: any) => {
        return state.datasRepartitionEquipes
    },
    getCorrectionOnViatique: () => (cle_epreuve_externe: string): string => {
        return process.env.VUE_APP_API_URL + '/oidc/auth/viatique/' + btoa(cle_epreuve_externe)
    },
    getPortail: () => (code: string) => {
        return process.env.VUE_APP_API_URL + '/oidc/auth/candidats/' + code
    }
}
