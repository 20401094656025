import { getError } from '@/utils/helpers'
import AffectationEquipeService from '@/services/AffectationEquipeService'
import {
    AffectationEquipesAuCentre,
    GetEnsembles,
    PayloadValidAffectationDesEquipesAuxCentres,
    StateAffectationEquipes
} from '@/types/AffectationEquipes'
import Vue from 'vue'

export const namespaced = true

function setAffectationEquipes(commit: Function, response: any) {
    commit('SET_AFFECTATION_EQUIPES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    // groupSeries(commit, response.data.data)
    commit('SET_LOADING', false)
}

export const state: StateAffectationEquipes = {
    affectationEquipes: [],
    meta: null,
    links: null,
    loading: false,
    loadingPopupEdit: false,
    error: null,
    totalRows: 0,
    affectationEquipeSelect: {},
    validationDesAffectationAuxCentres: 0,
    ensemblesDataCopy: []
}

export const mutations = {
    RESET_STATE(state: StateAffectationEquipes) {
        Object.assign(state, {
            affectationEquipes: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            affectationEquipeSelect: {},
            validationDesAffectationAuxCentres: 0,
            ensemblesDataCopy: []
        })
    },
    SET_AFFECTATION_EQUIPES(state: StateAffectationEquipes, affectationEquipes: any) {
        state.affectationEquipes = affectationEquipes
    },
    SET_MORE_AFFECTATION_EQUIPES(state: any, affectationEquipes: any) {
        state.affectationEquipes = state.affectationEquipes.concat(affectationEquipes)
    },
    SET_META(state: StateAffectationEquipes, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: StateAffectationEquipes, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: StateAffectationEquipes, loading: boolean) {
        state.loading = loading
    },
    SET_LOADING_POPUP_EDIT(state: StateAffectationEquipes, loading: boolean) {
        state.loadingPopupEdit = loading
    },
    SET_ERROR(state: StateAffectationEquipes, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: StateAffectationEquipes, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_AFFECTATION_EQUIPE_SELECT(state: StateAffectationEquipes, affectationEquipes: any) {
        state.affectationEquipeSelect = affectationEquipes
    },
    SET_VALIDATION_AFFECTATION_EQUIPES_AUX_CENTRES(
        state: StateAffectationEquipes,
        validationDesAffectationAuxCentres: any
    ) {
        state.validationDesAffectationAuxCentres = validationDesAffectationAuxCentres
    },
    UPDATE_EQUIPE_VIA_SET(state: StateAffectationEquipes, equipe: any) {
        const index = state.affectationEquipes.map((c: any) => c.id).indexOf(equipe.id)
        Vue.set(state.affectationEquipes, index, equipe)
    },
    UPDATE_ENSEMBLE_DATA_COPY(state: StateAffectationEquipes, dataCopy: any) {
        state.ensemblesDataCopy = dataCopy
    }
}

export const actions = {
    getAffectationEquipes({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationEquipeService.getCentresAfex(params)
                .then((response: any) => {
                    setAffectationEquipes(commit, response)
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },

    /** Valide / invalide la liste des equipes des centres  */
    validationAffectationDesEquipesAuxCentres(
        {
            commit
        }: {
            commit: any
        },
        params: PayloadValidAffectationDesEquipesAuxCentres
    ) {
        return new Promise((resolve, reject) => {
            AffectationEquipeService.validAffectationDesEquipesAuxCentres(params)
                .then((response: any) => {
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    },

    getEnsembles({ commit }: { commit: any }, params: GetEnsembles) {
        /* Vu avec Menchi 10000 pour byPass la pagination */
        params.perPage = 10000
        return new Promise((resolve, reject) => {
            AffectationEquipeService.getEnsembles(params)
                .then((response: any) => {
                    commit('UPDATE_ENSEMBLE_DATA_COPY', response.data.data)
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },

    /** Ajoute / supprime des équipes du centre (utilisé dans la popupEditAffectionEquipe) */
    putAffectationEquipesAuCentre({ commit }: { commit: any }, params: AffectationEquipesAuCentre) {
        commit('SET_LOADING_POPUP_EDIT', true)
        return new Promise((resolve, reject) => {
            AffectationEquipeService.affectationEquipesAuCentre(params)
                .then((response: any) => {
                    commit('UPDATE_EQUIPE_VIA_SET', response.data.data)
                    /* setAffectationEquipes(commit, response) */
                    commit('SET_LOADING_POPUP_EDIT', false)
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_LOADING_POPUP_EDIT', false)
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    }
}

export const getters = {
    GET_LISTE_EQUIPE(state: StateAffectationEquipes) {
        return state.affectationEquipes
    },
    GET_LOADING(state: StateAffectationEquipes) {
        return state.loading
    },
    GET_META(state: StateAffectationEquipes) {
        return state.meta
    },
    GET_LOADING_POPUP_EDIT(state: StateAffectationEquipes) {
        return state.loadingPopupEdit
    },
    GET_ERROR(state: StateAffectationEquipes) {
        return state.error
    }
}
