import { getError } from '@/utils/helpers'
import GroupeExaminateurUserService from '@/services/GroupeExaminateurUserService'
import { GroupeExaminateurUserInterface, StateGroupeExaminateurUsers } from '@/types/GroupeExaminateurUser'
import Vue from 'vue'

export const namespaced = true

function setGroupeExaminateurUsers(commit: Function, response: any) {
    commit('SET_GROUPE_EXAMINATEUR_USERS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateGroupeExaminateurUsers = {
    groupeExaminateurUsers: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    groupeExaminateurUserSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateGroupeExaminateurUsers) {
        Object.assign(state, {
            groupeExaminateurUsers: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            groupeExaminateurUserSelect: {}
        })
    },
    SET_GROUPE_EXAMINATEUR_USERS(state: any, groupeExaminateurUsers: Array<GroupeExaminateurUserInterface>) {
        state.groupeExaminateurUsers = groupeExaminateurUsers
    },
    SET_MORE_GROUPE_EXAMINATEUR_USERS(state: any, groupeExaminateurUsers: Array<GroupeExaminateurUserInterface>) {
        state.groupeExaminateurUsers = state.groupeExaminateurUsers.concat(groupeExaminateurUsers)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_GROUPE_EXAMINATEUR_USER_SELECT(state: any, groupeExaminateurUser: GroupeExaminateurUserInterface) {
        state.groupeExaminateurUserSelect = groupeExaminateurUser
    },
    MAJ_GROUPE_EXAMINATEUR_USER(state: any, groupeExaminateurUser: any) {
        const index = state.groupeExaminateurUsers.map((c: any) => c.id).indexOf(groupeExaminateurUser.id)
        Vue.set(state.groupeExaminateurUsers, index, groupeExaminateurUser)
    }
}

export const actions = {
    getGroupeExaminateurUsers({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GroupeExaminateurUserService.getGroupeExaminateurUsers(params)
                .then((response) => {
                    setGroupeExaminateurUsers(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getGroupeExaminateurUser({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GroupeExaminateurUserService.getGroupeExaminateurUsers(params.groupeExaminateurUser_id)
                .then((response) => {
                    commit('SET_GROUPE_EXAMINATEUR_USER_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreGroupeExaminateurUsers({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GroupeExaminateurUserService.getGroupeExaminateurUser(params.groupeExaminateurUser_id)
                .then((response) => {
                    commit('SET_GROUPE_EXAMINATEUR_USER_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateGroupeExaminateurUser({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GroupeExaminateurUserService.updateGroupeExaminateurUser(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    if (response && response.data && response.data.data) {
                        commit('MAJ_GROUPE_EXAMINATEUR_USER', response.data.data)
                    }
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addGroupeExaminateurUser({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GroupeExaminateurUserService.addGroupeExaminateurUser(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteGroupeExaminateurUser({ commit }: { commit: any }, groupeExaminateurUser_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GroupeExaminateurUserService.deleteGroupeExaminateurUser(groupeExaminateurUser_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    groupeExaminateurUsers: (state: any) => {
        return state.groupeExaminateurUsers
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.groupeExaminateurUsers.length
    },
    groupeExaminateurUserSelect: (state: any) => {
        return state.groupeExaminateurUserSelect
    },
    getGroupeExaminateurUserById: (state: any) => (id: number) => {
        const groupeExaminateurUser = state.groupeExaminateurUsers.filter((mat: any) => mat.id === id)[0]
        return groupeExaminateurUser
    }
}
