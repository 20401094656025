import CompteCandidatService from '@/services/CompteCandidatService'
import { CompteCandidatInterface, CompteCandidatState } from '@/types/CompteCandidat'
import { getError } from '@/utils/helpers'

export const namespaced = true

export const state: CompteCandidatState = {
    comptes: [],
    compte: {
        id: 0,
        code: 0,
        name: '',
        first_name: '',
        email: '',
        candidats: []
    },
    loading: false,
    error: null
}

export const mutations = {
    RESET_STATE(state: CompteCandidatState) {
        Object.assign(state, {
            comptes: [],
            compte: {
                id: 0,
                code: 0,
                name: '',
                first_name: '',
                email: '',
                candidats: []
            },
            loading: false,
            error: null
        })
    },
    SET_COMPTES(state: any, comptes: CompteCandidatInterface[]) {
        state.comptes = comptes
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: any) {
        state.error = error
    }
}

export const actions = {
    getCompteCandidats({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CompteCandidatService.getCompteCandidats(params)
                .then((response: any) => {
                    commit('SET_COMPTES', response.data.data)
                    commit('SET_ERROR', null)
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    updateCompteCandidats({ commit }: { commit: any }, params: {compte_candidat_id: number; payload: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CompteCandidatService.updateCompteCandidats(params.compte_candidat_id, params.payload)
                .then((response: any) => {
                    commit('SET_ERROR', null)
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {
    comptes: (state: any): CompteCandidatInterface[] => {
        return state.comptes
    },
    compte: (state: any): CompteCandidatInterface => {
        return state.compte
    },
    loading: (state: any): boolean => {
        return state.loading
    }
}
