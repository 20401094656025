export enum TypeSalle {
    STANDARD,
    TP,
}

export enum TypeAmenagement {
    AMENAGEMENT_AUCUN,
    AMENAGEMENT_HANDICAP,
    AMENAGEMENT_ISOLE,
}

export interface SalleInterface {
    id: number,
    code: string,
    name: string,
    capacite_std: number | null,
    capacite_max: number | null,
    type: number | null,
    amenagement: number | null,
    centre_id: number,
    concours: Array<any> | [],
    total_capacite: number | null
}

export interface StateSalles {
    salleSelect: SalleInterface | null,
    loading: boolean | null,
    save_in_progress: boolean | null,
    soumettre_ok: boolean | null,
    error: any | null
}

export function getTypeSalle(type: TypeSalle): any {
    switch (type) {
        case TypeSalle.STANDARD:
            return { index: 0, libelle: 'Standard' }
        case TypeSalle.TP:
            return { index: 1, libelle: 'Salle de TP' }
        default:
            return { index: 0, libelle: 'Standard' }
    }
}

export function getTypeAmenagement(type: TypeAmenagement): any {
    switch (type) {
        case TypeAmenagement.AMENAGEMENT_AUCUN:
            return { index: 0, libelle: 'Standard', icone: 'eye' }
        case TypeAmenagement.AMENAGEMENT_HANDICAP:
            return { index: 1, libelle: 'Séparée', icone: 'wheelchair' }
        case TypeAmenagement.AMENAGEMENT_ISOLE:
            return { index: 2, libelle: 'Isolée', icone: 'cube' }
        default:
            return { index: 0, libelle: 'Standard' }
    }
}

export function getEnumTypeAmenagement() {
    const enumTypeAmenagement = []
    enumTypeAmenagement.push({
        id: getTypeAmenagement(TypeAmenagement.AMENAGEMENT_AUCUN).index,
        name: getTypeAmenagement(TypeAmenagement.AMENAGEMENT_AUCUN).libelle
    })
    enumTypeAmenagement.push({
        id: getTypeAmenagement(TypeAmenagement.AMENAGEMENT_HANDICAP).index,
        name: getTypeAmenagement(TypeAmenagement.AMENAGEMENT_HANDICAP).libelle
    })
    enumTypeAmenagement.push({
        id: getTypeAmenagement(TypeAmenagement.AMENAGEMENT_ISOLE).index,
        name: getTypeAmenagement(TypeAmenagement.AMENAGEMENT_ISOLE).libelle
    })

    return enumTypeAmenagement
}
