export enum TypeReclamation {
    TYPE_PASSATION_ECRIT = 1, // Pas utilisé
    TYPE_NOTE_ECRIT = 2, // réclamations écrit
    TYPE_PASSATION_ORAL = 3, // réclamations oral
    TYPE_NOTE_ORAL = 4, // réclamations post-concours
}

interface TypeReclamationSpec {
    index: number,
    libelle: string
}

export function getEtatSpecTypeReclamation(type: TypeReclamation): TypeReclamationSpec {
    switch (type) {
        case TypeReclamation.TYPE_PASSATION_ECRIT:
            return { index: TypeReclamation.TYPE_PASSATION_ECRIT, libelle: '--' }
        case TypeReclamation.TYPE_NOTE_ECRIT:
            return { index: TypeReclamation.TYPE_NOTE_ECRIT, libelle: 'Réclamations écrit' }
        case TypeReclamation.TYPE_PASSATION_ORAL:
            return { index: TypeReclamation.TYPE_PASSATION_ORAL, libelle: 'Réclamations oral' }
        case TypeReclamation.TYPE_NOTE_ORAL:
            return { index: TypeReclamation.TYPE_NOTE_ORAL, libelle: 'Réclamations post-concours' }
        default:
            return { index: 0, libelle: '-' }
    }
}

export enum TypeReponseReclamation {
    TYPE_REPONSE_ACCEPTEE = 1,
    TYPE_REPONSE_REFUSEE = 2,
    TYPE_REPONSE_REJETEE = 3,
}

interface TypeReponseReclamationSpec {
    index: number,
    libelle: string
}

export function getEtatSpecTypeReponseReclamation(typeReponse: TypeReponseReclamation): TypeReponseReclamationSpec {
    switch (typeReponse) {
        case TypeReponseReclamation.TYPE_REPONSE_ACCEPTEE:
            return { index: TypeReponseReclamation.TYPE_REPONSE_ACCEPTEE, libelle: 'Acceptée' }
        case TypeReponseReclamation.TYPE_REPONSE_REFUSEE:
            return { index: TypeReponseReclamation.TYPE_REPONSE_REFUSEE, libelle: 'Refusée' }
        case TypeReponseReclamation.TYPE_REPONSE_REJETEE:
            return { index: TypeReponseReclamation.TYPE_REPONSE_REJETEE, libelle: 'Rejetée' }
        default:
            return { index: 0, libelle: '-' }
    }
}

export enum TypeRecorrectionReclamation {
    TYPE_RECORRECTION_NON_DEMANDEE = 0,
    TYPE_RECORRECTION_DEMANDEE = 1,
    TYPE_RECORRECTION_TRAITEE = 2
}

export function getTypeRecorrectionReclamation(type: TypeRecorrectionReclamation): EtatSpec {
    switch (type) {
        case TypeRecorrectionReclamation.TYPE_RECORRECTION_NON_DEMANDEE:
            return {
                index: TypeRecorrectionReclamation.TYPE_RECORRECTION_NON_DEMANDEE,
                libelle: 'Non demandée',
                color: 'text-secondary',
                icon: 'circle'
            }
        case TypeRecorrectionReclamation.TYPE_RECORRECTION_DEMANDEE:
            return {
                index: TypeRecorrectionReclamation.TYPE_RECORRECTION_DEMANDEE,
                libelle: 'Demandée',
                color: 'text-info',
                icon: 'circle'
            }
        case TypeRecorrectionReclamation.TYPE_RECORRECTION_TRAITEE:
            return {
                index: TypeRecorrectionReclamation.TYPE_RECORRECTION_TRAITEE,
                libelle: 'Traitée',
                color: 'text-success',
                icon: 'circle'
            }
    }
}

export enum Etat {
    ETAT_NON_TRAITE,
    ETAT_EN_COURS,
    ETAT_TRAITE,
    ETAT_SIGNE,
    ETAT_REJETEE,
    ETAT_ANNULEE,
    ETAT_INVALIDEE
}

interface EtatSpec {
    index: number,
    libelle: string,
    color: string,
    icon: string
}

export function getEtatSpecReclamation(etat: Etat, customLibelle?: string): EtatSpec {
    switch (etat) {
        case Etat.ETAT_EN_COURS:
            return {
                index: Etat.ETAT_EN_COURS,
                libelle: customLibelle || 'Réclamation en cours de traitement',
                color: 'text-info',
                icon: 'circle'
            }
        case Etat.ETAT_TRAITE:
            return {
                index: Etat.ETAT_TRAITE,
                libelle: customLibelle || 'Réclamation traitée',
                color: 'text-success',
                icon: 'circle'
            }
        case Etat.ETAT_SIGNE:
            return {
                index: Etat.ETAT_SIGNE,
                libelle: customLibelle || 'Traitement de la déclaration signé par la direction',
                color: 'text-success',
                icon: 'check'
            }
        case Etat.ETAT_REJETEE:
            return {
                index: Etat.ETAT_REJETEE,
                libelle: customLibelle || 'Réclamation rejetée',
                color: 'text-danger',
                icon: 'circle'
            }
        case Etat.ETAT_ANNULEE:
            return {
                index: Etat.ETAT_ANNULEE,
                libelle: customLibelle || 'Réclamation annulée',
                color: 'text-secondary',
                icon: 'times-circle'
            }
        default:
            return {
                index: Etat.ETAT_NON_TRAITE,
                libelle: customLibelle || 'Réclamation non traitée',
                color: 'text-secondary',
                icon: 'circle'
            }
    }
}

export enum EtatReclamationViatique {
    DECLAREE = 'D',
    CONFIRMEE = 'C',
    INFIRMEE = 'I'
}

export interface ReclamationsInterface {
    id: number | 0,
    code: string | null,
    identite: string | null,
    filiere: string | null,
    epreuve: string | null,
    message: string | null,
    messageToCand: string | null,
    noteBrute: number | 0,
    noteFinale: number | 0,
    noteRectifiee: number | 0,
    reclamation_validated_at: Date,
    statut: number | 0
}

export interface StateReclamationsInterface {
    reclamations_ecrit: Array<ReclamationsInterface> | [],
    reclamations_oral: Array<ReclamationsInterface> | [],
    reclamations_post: Array<ReclamationsInterface> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    currentPage: number | 0,
    lastPage: number | 0,
    totalPage: number | 0,
    reclamationSelect: ReclamationsInterface | {},
    reclamations_ecrit_compteurs: Array<string> | null
}
