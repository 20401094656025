import * as API from '@/services/API'
import store from '@/store/index'

export default {
    getDecisionsJury(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/decisionjurys`, { params: payload })
    },
    addDecision(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/decisionjurys`, payload)
    },
    updateDecision(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/decisionjurys/${payload.id}`, payload)
    },
    deleteDecision(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/decisionjurys/${payload.id}`)
    },
    exportDecisions() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/decisionjurys_export`, {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    }
}
