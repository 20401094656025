// PosteOuvert

import * as API from '@/services/API'
import store from '@/store/index'

export default {
    async getPosteOuvert(posteOuvertId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/posteouverts/${posteOuvertId}`)
    },
    async getPosteOuverts(payload: any = null) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/posteouverts`, { params: payload })
    },
    async updatePosteOuvert(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        if (payload.id) {
            return await API.apiClient.put(`/sessions/${session_id}/posteouverts/${payload.id}`, payload)
        } else {
            return await API.apiClient.post(`/sessions/${session_id}/posteouverts`, payload)
        }
    },
    async posteOuvertUpdateSession(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/posteouverts/updateSession`, payload)
    },
    async addPosteOuvert (payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/posteouverts`, payload)
    },
    async deletePosteOuvert(posteOuvertId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/posteouverts/${posteOuvertId}`)
    },
    async import(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const formData = new FormData()
        formData.append('document', payload)
        return await API.apiClient.post(`/sessions/${session_id}/posteouverts/import`, formData) // , config)
    },
    export() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/posteouverts/export`, {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    }
}
