import { getError } from '@/utils/helpers'
import JobDescriptionFormService from '@/services/JobDescriptionFormService'
import { JobDescriptionFormInterface, StateJobDescriptionForms } from '@/types/JobDescriptionForm'

// ------ job_profile_forms JobDescriptionForms

export const namespaced = true

function setJobDescriptionForms(commit: Function, response: any) {
    commit('SET_JOB_DESCRIPTION_FORMS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateJobDescriptionForms = {
    jobDescriptionForms: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    jobDescriptionFormSelect: null,
    piloteJobDescriptionFormSelect: null,
    concoursJobDescriptionFormSelect: null
}

export const mutations = {
    RESET_STATE(state: StateJobDescriptionForms) {
        Object.assign(state, {
            jobDescriptionForms: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            jobDescriptionFormSelect: null,
            concoursJobDescriptionFormSelect: null
        })
    },
    SET_JOB_DESCRIPTION_FORMS(state: any, jobDescriptionForms: Array<JobDescriptionFormInterface>) {
        state.jobDescriptionForms = jobDescriptionForms
    },
    ADD_JOB_DESCRIPTION_FORM(state: any, jobDescriptionForm: JobDescriptionFormInterface) {
        state.jobDescriptionForms.push(jobDescriptionForm)
        state.jobDescriptionFormSelect = jobDescriptionForm
    },
    UPDATE_JOB_DESCRIPTION_FORM(state: any, jobDescriptionForm: JobDescriptionFormInterface) {
        if (state.jobDescriptionFormSelect && state.jobDescriptionFormSelect.id === jobDescriptionForm.id) {
            state.jobDescriptionFormSelect = jobDescriptionForm
        }

        const index = state.jobDescriptionForms.findIndex((form: any) => form.id === jobDescriptionForm.id)
        if (index !== -1) {
            state.jobDescriptionForms[index] = jobDescriptionForm
        }
    },
    REMOVE_JOB_DESCRIPTION_FORM(state: any, jobDescriptionForm_id: number) {
        const index = state.jobDescriptionForms.findIndex((form: any) => form.id === jobDescriptionForm_id)
        if (index !== -1) {
            state.jobDescriptionForms.splice(index, 1)
        }
        state.jobDescriptionFormSelect = null
    },
    SET_MORE_JOB_DESCRIPTION_FORMS(state: any, jobDescriptionForms: Array<JobDescriptionFormInterface>) {
        state.jobDescriptionForms = state.jobDescriptionForms.concat(jobDescriptionForms)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_JOB_DESCRIPTION_FORM_SELECT(state: any, jobDescriptionForm: JobDescriptionFormInterface) {
        state.jobDescriptionFormSelect = jobDescriptionForm
    },
    SET_PILOTE_JOB_DESCRIPTION_FORM_SELECT(state: any, pilote: any) {
        state.piloteJobDescriptionFormSelect = pilote
    },
    SET_CONCOURS_JOB_DESCRIPTION_FORM_SELECT(state: any, concours: any) {
        state.concoursJobDescriptionFormSelect = concours
    }
}

export const actions = {
    getJobDescriptionForms({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobDescriptionFormService.getJobDescriptionForms(params)
                .then((response) => {
                    setJobDescriptionForms(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getJobDescriptionForm({ commit }: { commit: any }, params: any) {
        commit('SET_JOB_DESCRIPTION_FORM_SELECT', null)
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobDescriptionFormService.getJobDescriptionForm(params.jobDescriptionForm_id)
                .then((response) => {
                    commit('SET_JOB_DESCRIPTION_FORM_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateJobDescriptionForm({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobDescriptionFormService.updateJobDescriptionForm(params)
                .then((response) => {
                    commit('UPDATE_JOB_DESCRIPTION_FORM', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addJobDescriptionForm({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobDescriptionFormService.addJobDescriptionForm(params)
                .then((response) => {
                    commit('ADD_JOB_DESCRIPTION_FORM', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteJobDescriptionForm({ commit }: { commit: any }, jobDescriptionForm_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobDescriptionFormService.deleteJobDescriptionForm(jobDescriptionForm_id)
                .then((response) => {
                    commit('REMOVE_JOB_DESCRIPTION_FORM', jobDescriptionForm_id)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    storeDocument({ commit }: { commit: any }, params: any) {
        // commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobDescriptionFormService.storeDocument(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteDocument({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            JobDescriptionFormService.deleteDocument(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDocument({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            JobDescriptionFormService.getDocument(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    storeDocumentReference({ commit }: { commit: any }, params: any) {
        // commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobDescriptionFormService.storeDocumentReference(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteDocumentReference({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            JobDescriptionFormService.deleteDocumentReference(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDocumentReference({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            JobDescriptionFormService.getDocumentReference(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    cloneJobDescriptionForm({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            JobDescriptionFormService.cloneJobDescriptionForm(params).then((response) => {
                commit('ADD_JOB_DESCRIPTION_FORM', response.data.data)
                commit('SET_LOADING', false)
                resolve(response)
            }).catch((error) => {
                commit('SET_LOADING', false)
                commit('SET_ERROR', getError(error))
                reject(error)
            })
        })
    }
}

export const getters = {
    jobDescriptionForms: (state: any): JobDescriptionFormInterface[] => {
        return state.jobDescriptionForms
    },
    meta: (state: any): any => {
        return state.meta
    },
    links: (state: any): string | null => {
        return state.links
    },
    loading: (state: any): boolean => {
        return state.loading
    },
    error: (state: any): any => {
        return state.error
    },
    totalRows: (state: any): number => {
        return state.jobDescriptionForms.length
    },
    jobDescriptionFormSelect: (state: any): JobDescriptionFormInterface => {
        return state.jobDescriptionFormSelect
    },
    piloteJobDescriptionFormSelect: (state: any) => {
        return state.piloteJobDescriptionFormSelect
    },
    concoursJobDescriptionFormSelect: (state: any) => {
        return state.piloteJobDescriptionFormSelect
    },
    getJobDescriptionFormById: (state: any) => (id: number) => {
        const jobDescriptionForm = state.jobDescriptionForms.filter((mat: any) => mat.id === id)[0]
        return jobDescriptionForm
    }
}
