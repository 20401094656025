import * as API from '@/services/API'
import store from '@/store'
import { TypePlanification } from '@/types/Planification'

export default {
    // Historique avant édition
    getHistoPlanif(serie_id: number, type_planif: TypePlanification) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/histo_planif/${serie_id}/${type_planif}`)
    },
    getHistoPlanifSessionActive() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/histo_planif`)
    },
    createHistoPlanif(serie_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/histo_planif/${serie_id}`)
    },
    validateHistoPlanif(histo_planif_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/histo_planif/${histo_planif_id}`)
    },
    cancelHistoPlanif(histo_planif_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/histo_planif/${histo_planif_id}`)
    },
    undoHistoPlanif(histo_planif_id: number, action_planif_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/histo_planif/${histo_planif_id}/undo/${action_planif_id}`)
    },
    redoHistoPlanif(histo_planif_id: number, action_planif_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/histo_planif/${histo_planif_id}/redo/${action_planif_id}`)
    },
    getStatsPlanif(session_id: number, payload: any) {
        const user_session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${user_session_id}/planif/stats`, { params: payload })
    },
    planifier(session_id: number, payload: any) {
        const user_session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${user_session_id}/planif/planifier`, payload)
    },
    arreter() {
        const user_session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${user_session_id}/planif/arreter`)
    },
    deletePlanif(session_id: number, payload: any) {
        const user_session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${user_session_id}/planif/supprimer`, payload)
    },
    getAvancement(histo_planif: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/planif/avancement/${histo_planif}`)
    },
    validateSerie(serie_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/planif/series/${serie_id}`, payload)
    },
    getReglesSession() {
        const user_session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${user_session_id}/planif/regles`)
    },
    updateReglesSession(session_id: number, payload: any) {
        const user_session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${user_session_id}/planif/regles`, payload)
    },
    getActionsHisto(histo_planif: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/actions_histo_planif/${histo_planif}`)
    },
    getEtendue(serie_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/planif/etendu/${serie_id}`)
    }
}
