import { getError } from '@/utils/helpers'
import SuppleantService from '@/services/SuppleantService'
import { StateSuppleantInterface } from '@/types/Suppleant'
import Vue from 'vue'

export const namespaced = true

function setListeSuppleant(commit: Function, response: any) {
    commit('SET_EXAMINATEURS_AFFECTATIONS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
}

export const state: StateSuppleantInterface = {
    listeSuppleant: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    listeIntervenants: []
}

export const mutations = {
    RESET_STATE(state: StateSuppleantInterface) {
        Object.assign(state, {
            listeSuppleant: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            examinateurAffectationSelect: {},
            totalAffectactionsAPourvoir: 0, // total des affectations à pourvoir
            totalAffectationsPourvues: 0, // total des affectations pourvues
            listeIntervenants: []
        })
    },
    SET_EXAMINATEURS_AFFECTATIONS(state: StateSuppleantInterface, listeSuppleant: Array<any>) {
        state.listeSuppleant = listeSuppleant
    },
    SET_MORE_EXAMINATEURS_AFFECTATIONS(state: StateSuppleantInterface, listeSuppleant: any) {
        state.listeSuppleant = state.listeSuppleant.concat(listeSuppleant)
    },
    SET_LISTE_INTERVENANTS(state: any, listeIntervenants: number) {
        state.listeIntervenants = listeIntervenants
    },
    SET_META(state: StateSuppleantInterface, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: StateSuppleantInterface, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: StateSuppleantInterface, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: StateSuppleantInterface, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: StateSuppleantInterface, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: StateSuppleantInterface, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: StateSuppleantInterface, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CURRENT_PAGE(state: StateSuppleantInterface, currentPage: number) {
        state.currentPage = currentPage
    },
    // Remet à jour l'affectation qui vient d'être éditée avec maj du visuel du tableau sur la vue
    MAJ_DATAS_AFFECTATION_SELECT(state: any, params: any) {
        const tableau_copy = state.listeSuppleant
        const index = state.listeSuppleant.map((item: any) => item.id).indexOf(params.id)
        if (index > -1) {
            tableau_copy[index].affectations = params.affectations
            const ligne_modif = tableau_copy[index]

            Vue.set(state.listeSuppleant, index, ligne_modif)
        }
    }
}

export const actions = {
    getSuppleant({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SuppleantService.getSuppleant(params)
                .then((response) => {
                    setListeSuppleant(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreSuppleant({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SuppleantService.getSuppleant(params)
                .then((response) => {
                    commit('SET_MORE_EXAMINATEURS_AFFECTATIONS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getListeIntervenants({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SuppleantService.getListeIntervenants(payload)
                .then((response) => {
                    commit('SET_LISTE_INTERVENANTS', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getJoursDispoSuppleant({ commit }: { commit: any }, params: any) {
        // commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SuppleantService.getJoursDispoSuppleant(params.suppleant_id, params.examinateur_id)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    // commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    },
    updateJoursAvecSuppleants({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            SuppleantService.updateJoursAvecSuppleants(params.suppleant_id, params.ensemble_id, params.payload)
                .then((response) => {
                    // console.log('response.data.data :', response.data.data)
                    // commit('MAJ_DATAS_AFFECTATION_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    }
}

export const getters = {
    listeSuppleant: (state: StateSuppleantInterface) => {
        return state.listeSuppleant
    },
    listeIntervenants: (state: StateSuppleantInterface) => {
        return state.listeIntervenants
    },
    meta: (state: StateSuppleantInterface) => {
        return state.meta
    },
    links: (state: StateSuppleantInterface) => {
        return state.links
    },
    loading: (state: StateSuppleantInterface) => {
        return state.loading
    },
    error: (state: StateSuppleantInterface) => {
        return state.error
    },
    totalRows: (state: StateSuppleantInterface) => {
        return state.listeSuppleant.length
    },
    currentPage: (state: StateSuppleantInterface) => {
        return state.currentPage
    },
    lastPage: (state: StateSuppleantInterface) => {
        return state.lastPage
    },
    totalPage: (state: StateSuppleantInterface) => {
        return state.totalPage
    }
}
