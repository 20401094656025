import * as API from '@/services/API'
import store from '@/store/index'

export default {
    getCentreFournituresPapetieres(centre_id: string, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/centres/${centre_id}/centre_fourniture_papetieres`, {
            params: payload
        })
    },
    saveFournituresPapetieres(centre_id: string, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(
            `/sessions/${session_id}/centres/${centre_id}/centre_fourniture_papetieres_mass_update`,
            payload
        )
    },
    getPDF(centre_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/centres/${centre_id}/export_fourniture_papetiere`, {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    }
}
