import * as API from '@/services/API'
import store from '@/store/index'

export default {
    getCandidatsIncompatibles(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/incompatibilites`, { params: payload })
    },
    addCandidats(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/incompatibilites`, payload)
    },
    deleteCandidatsIncompatibles(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        payload._method = 'DELETE'
        return API.apiClient.post(`/sessions/${session_id}/incompatibilites`, payload)
    },
    rechercheParEtablissement(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/incompatibilites`, { params: payload })
    },
    rechercheParCandidat(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/incompatibilites`, { params: payload })
    },
    getAllEtablissements() {
        const session_id = store.getters['auth/user_session_id']
        const payload = {
            candidat: 1, 
            perPage: -1

        }
        return API.apiClient.get(`/sessions/${session_id}/etablissements`, { params: payload })
    },
    /* Récuperation de l'etat de soumission */
    putValidationListeIncompatibles(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/sessionusers/${payload.user_id}`, payload)
    },
    getValidationListeIncompatibles(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/sessionusers/${payload.user_id}`)
    }
}
