import { StateWorkingEstablishmentTypes, WorkingEstablishmentTypeInterface } from '@/types/WorkingEstablishmentType'
import { getError } from '@/utils/helpers'
import WorkingEstablishmentTypeService from '../../services/WorkingEstablishmentTypeService'

export const namespaced = true

export const state: StateWorkingEstablishmentTypes = {
    workingEstablishmentTypes: [] as Array<WorkingEstablishmentTypeInterface>,
    loading: false as boolean,
    error: null as any,
    meta: null as any,
    links: null as any
}

export const mutations = {
    RESET_STATE(state: StateWorkingEstablishmentTypes) {
        Object.assign(state, {
            workingEstablishmentTypes: [],
            loading: false,
            error: null,
            meta: null,
            links: null
        })
    },
    SET_WORKING_ESTABLISHMENT_TYPES(state: any, workingEstablishmentTypes: Array<WorkingEstablishmentTypeInterface>) {
        state.workingEstablishmentTypes = workingEstablishmentTypes
    },
    SET_MORE_WORKING_ESTABLISHMENT_TYPES(state: any, workingEstablishmentTypes: Array<WorkingEstablishmentTypeInterface>) {
        state.workingEstablishmentTypes = state.workingEstablishmentTypes.concat(workingEstablishmentTypes)
    },
    ADD_WORKING_ESTABLISHMENT_TYPE(state: any, workingEstablishmentType: WorkingEstablishmentTypeInterface) {
        state.workingEstablishmentTypes.push(workingEstablishmentType)
    },
    UPDATE_WORKING_ESTABLISHMENT_TYPE(state: any, workingEstablishmentType: WorkingEstablishmentTypeInterface) {
        const index = state.workingEstablishmentTypes.findIndex((w: WorkingEstablishmentTypeInterface) => w.id === workingEstablishmentType.id)
        if (index !== -1) {
            state.workingEstablishmentTypes.splice(index, 1, workingEstablishmentType)
        }
    },
    DELETE_WORKING_ESTABLISHMENT_TYPE(state: any, workingEstablishmentTypeId: number) {
        const index = state.workingEstablishmentTypes.findIndex((w: WorkingEstablishmentTypeInterface) => w.id === workingEstablishmentTypeId)
        if (index !== -1) {
            state.workingEstablishmentTypes.splice(index, 1)
        }
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_META(state: any, meta: any) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: any) {
        state.links = links
    }
}

export const actions = {
    getWorkingEstablishmentTypes({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            WorkingEstablishmentTypeService.getWorkingEstablishmentTypes(params)
                .then((response: any) => {
                    commit('SET_WORKING_ESTABLISHMENT_TYPES', response.data.data)
                    commit('SET_META', response.data.meta)
                    commit('SET_LINKS', response.data.links)
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getMoreWorkingEstablishmentTypes({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            WorkingEstablishmentTypeService.getWorkingEstablishmentTypes(params)
                .then((response: any) => {
                    commit('SET_MORE_WORKING_ESTABLISHMENT_TYPES', response.data.data)
                    commit('SET_META', response.data.meta)
                    commit('SET_LINKS', response.data.links)
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getWorkingEstablishmentType({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            WorkingEstablishmentTypeService.getWorkingEstablishmentType(params.working_establishment_type_id)
                .then((response: any) => {
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                }).
                finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    createWorkingEstablishmentType({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            WorkingEstablishmentTypeService.createWorkingEstablishmentType(params)
                .then((response: any) => {
                    commit('ADD_WORKING_ESTABLISHMENT_TYPE', response.data.data)
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                }).
                finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    editWorkingEstablishmentType({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            WorkingEstablishmentTypeService.editWorkingEstablishmentType(params)
                .then((response: any) => {
                    commit('UPDATE_WORKING_ESTABLISHMENT_TYPE', response.data.data)
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                }).
                finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    deleteWorkingEstablishmentType({ commit }: { commit: any }, workingEstablishmentTypeId: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            WorkingEstablishmentTypeService.deleteWorkingEstablishmentType(workingEstablishmentTypeId)
                .then((response: any) => {
                    commit('DELETE_WORKING_ESTABLISHMENT_TYPE', workingEstablishmentTypeId)
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                }).
                finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getActivityLog({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            WorkingEstablishmentTypeService.getActivityLog(params.working_establishment_type_id)
                .then((response: any) => {
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {
    workingEstablishmentTypes: (state: any) => {
        return state.workingEstablishmentTypes
    },
    workingEstablishmentTypeById: (state: any) => (id: number) => {
        return state.workingEstablishmentTypes.find((w: WorkingEstablishmentTypeInterface) => w.id == id)
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    }
}
