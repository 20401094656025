import { getError } from '@/utils/helpers'
import ImpressionsService from '@/services/ImpressionsService'
import { StateImpressionExaminateurInterface } from '@/types/Fourniture'

export const namespaced = true

function setTableauImpressionExaminateur(commit: Function, response: any) {
    commit('SET_TABLEAU_IMPRESSION_EXAMINATEUR', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_LOADING_RECHERCHE', false)
    commit('SET_TOTAL', response.data.meta ? response.data.meta.total : 1)
}

export const state: StateImpressionExaminateurInterface = {
    tableauImpressionsExaminateur: [],
    loadingRecherche: false,
    loadingPdf: false,
    pdfView: [],
    meta: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    total: 0,
    error: null
}

export const mutations = {
    RESET_STATE(state: StateImpressionExaminateurInterface) {
        Object.assign(state, {
            tableauImpressionsExaminateur: [],
            loadingRecherche: false,
            error: null,
            loadingPdf: false,
            pdfView: [],
            meta: null
        })
    },
    SET_TABLEAU_IMPRESSION_EXAMINATEUR(state: StateImpressionExaminateurInterface, tableauImpressionsExaminateur: any) {
        state.tableauImpressionsExaminateur = tableauImpressionsExaminateur
    },
    SET_MORE_TABLEAU_IMPRESSION_EXAMINATEUR(state: any, tableauImpressionsExaminateur: any) {
        state.tableauImpressionsExaminateur = state.tableauImpressionsExaminateur.concat(tableauImpressionsExaminateur)
    },
    SET_LOADING_RECHERCHE(state: StateImpressionExaminateurInterface, loadingRecherche: boolean) {
        state.loadingRecherche = loadingRecherche
    },
    SET_PDF(state: StateImpressionExaminateurInterface, pdf: any) {
        state.pdfView = pdf
    },
    SET_LOADING_PDF(state: StateImpressionExaminateurInterface, loadingPdf: boolean) {
        state.loadingPdf = loadingPdf
    },
    SET_ERROR(state: StateImpressionExaminateurInterface, error: Array<any>) {
        state.error = error
    },
    RESET_ERROR(state: StateImpressionExaminateurInterface) {
        state.error = null
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL(state: any, totalPage: number) {
        state.total = totalPage
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    RESET_META(state: any) {
        state.meta = null
        state.totalRows = 0
        state.currentPage = 1
        state.lastPage = 0
        state.total = 0
    },
    RESET_TABLEAU_IMPRESSION(state: any) {
        state.tableauImpressionsExaminateur = []
    }
}

export const actions = {
    getTableauImpressionExaminateur({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING_RECHERCHE', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            ImpressionsService.recherche(payload)
                .then((response) => {
                    setTableauImpressionExaminateur(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_RECHERCHE', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreTableauImpressionExaminateur({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING_RECHERCHE', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            ImpressionsService.recherche(payload)
                .then((response) => {
                    commit('SET_MORE_TABLEAU_IMPRESSION_EXAMINATEUR', response.data.data)
                    commit('SET_LOADING_RECHERCHE', false)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_META', response.data.meta)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_RECHERCHE', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPdf({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING_PDF', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            ImpressionsService.getPDF(payload)
                .then((response) => {
                    commit('SET_PDF', response.data)
                    commit('SET_LOADING_PDF', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_PDF', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    RESET_TABLEAU_IMPRESSION_EXAMINATEUR({ commit }: { commit: any }) {
        commit('RESET_ERROR')
        commit('RESET_TABLEAU_IMPRESSION_EXAMINATEUR')
    },

    CONFIRMATION_IMPRESSION({ commit }: { commit: any }, payload?: any) {
        // commit('SET_LOADING_IMPRESSION', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            ImpressionsService.recherche(payload)
                .then((response) => {
                    setTableauImpressionExaminateur(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_RECHERCHE', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    tableauImpressionsExaminateurTrie: (state: StateImpressionExaminateurInterface) => {
        return state.tableauImpressionsExaminateur
    },
    loadingRecherche: (state: StateImpressionExaminateurInterface) => {
        return state.loadingRecherche
    },
    loadingPdf: (state: StateImpressionExaminateurInterface) => {
        return state.loadingPdf
    },
    getPdf: (state: StateImpressionExaminateurInterface) => {
        return state.pdfView
    },
    getError: (state: StateImpressionExaminateurInterface) => {
        return state.error
    },
    meta: (state: StateImpressionExaminateurInterface) => {
        return state.meta
    },
    totalRows: () => {
        return 0
    },
    currentPage: (state: StateImpressionExaminateurInterface) => {
        return state.currentPage
    },
    lastPage: (state: StateImpressionExaminateurInterface) => {
        return state.lastPage
    },
    total: (state: StateImpressionExaminateurInterface) => {
        return state.total
    }
}
