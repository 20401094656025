import { TypeEnsemble } from '@/types/Ensemble'
import _ from 'lodash'

export const namespaced = true

/* Definir interface */
export const state: any = {
    listeExaminateurs: [],
    examinateurs_infos: [],
    isLoading: false,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    meta: null
}

export const mutations: any = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            listeExaminateurs: [],
            examinateurs_infos: [],
            isLoading: false,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            meta: null
        })
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LOADING(state: any, loading: boolean) {
        state.isLoading = loading
    },
    SET_EXAMINATEUR(state: any, ensembles: any) {
        let examinateurs: any = []
        ensembles.forEach((ensemble: any) => {
            ensemble.examinateurs.forEach((examinateur: any) => {
                // ajout du nom de l ensemble de l'examinateur
                examinateur.ensemble_name = ensemble.name
                examinateur.type_ensemble = ensemble.type_ensemble
                examinateur.groupe_epreuve_id = ensemble.groupe_epreuve_id

                // 1ere affectation de l'examinateur -> Parcours groupeEpreuve > Epreuves -> si id ok -> recupe matiere_id
                if (ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
                    const affectations_examinateur = ensemble.affectation_examinateurs.filter(
                        (ae: any) => ae.user_id === examinateur.id
                    )
                    const epreuve_correction_id = affectations_examinateur[0].epreuve_correction_id

                    for (const ge in ensemble.groupeEpreuve.epreuves) {
                        if (ensemble.groupeEpreuve.epreuves[ge].id === epreuve_correction_id) {
                            examinateur.matiere_id = ensemble.groupeEpreuve.epreuves[ge].matiere_id
                        }
                    }
                } else {
                    examinateur.matiere_id = ensemble.groupeEpreuve.epreuves[0].matiere_id
                }

                examinateurs.push(examinateur)
            })
        })

        examinateurs = _.orderBy(examinateurs, 'name', 'asc')
        state.listeExaminateurs = examinateurs
    }
}

export const actions: any = {}

export const getters: any = {
    listeExaminateurs(state: any) {
        return state.listeExaminateurs
    },
    isLoading(state: any) {
        return state.isLoading
    },
    totalRows: (state: any) => {
        return state.totalRows
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    meta: (state: any) => {
        return state.meta
    }
}
