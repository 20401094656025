import * as API from '@/services/API'
import store from '@/store'

export default {
    async getNotificationTemplate(notificationTemplateId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/notificationtemplates/${notificationTemplateId}`)
    },
    async getNotificationTemplates(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/notificationtemplates`, { params: payload })
    },
    async updateNotificationTemplate(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/notificationtemplates/${payload.id}`, payload)
    },
    async addNotificationTemplate(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/notificationtemplates`, payload)
    },
    async deleteNotificationTemplate(notificationTemplate_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/notificationtemplates/${notificationTemplate_id}`)
    },
    getPDF(nt_id: number) {
        // const session_id = store.getters['auth/user_session_id']
        // return API.apiClient.get(`/sessions/${session_id}/notificationtemplates/${nt_id}/pdf?format=b64`)
        return API.apiClient.get(`/notificationtemplates/${nt_id}/pdf?format=b64`)
    }
}
