export enum EtatDispo {
    ETAT_NON_PRIORITAIRE,
    ETAT_PRIORITAIRE,
    ETAT_INDISPONIBLE,
}

export enum EtatCreneau {
    CRENEAU_INACTIF,
    CRENEAU_ACTIF,
    CRENEAU_INDISPONIBLE,
}

export function getMessageControle(type_message: string, value: number, pause: number) {
    switch (type_message) {
        case 'max_cand_demi_journee':
            return 'Vous ne pouvez pas définir plus de ' + value + ' interrogations sur une demi-journée.'
        case 'max_cand_journee':
            return 'Vous ne pouvez pas définir plus de ' + value + ' interrogations sur une journée.'
        case 'max_5h':
            return (
                'Vous devez définir une pause minimale de ' +
                pause +
                ' minutes après ' +
                value +
                " heures d'interrogation consécutives"
            )
        case 'max_6h':
            return (
                "Vous ne pouvez pas définir d'autre séance dans la journée lorsque vous avez défini " +
                value +
                " heures d'interrogation d'affilées."
            )
    }
}

export interface StateDisponibilite {
    date_start: string,
    date_end: string,
    error_dispos: Array<string> | null,
    loading: boolean | false,
    loading_interface: boolean | false,
    examinateur_select: number | null,
    pause_count: number | null,
    creneaux_examinateur: Array<any> | [],
    creneaux_TP: Array<any> | [],
    creneaux_prio: number,
    creneaux_non_prio: number,
    creneaux_actifs: number,
    candidats_reels: Array<any> | null,
    candidats_reels_total: number,
    lock_creneau: Array<boolean> | null,
    messages_regles_journees: Array<string> | null,
    compteurs_series_journees: Array<string> | null,
    compteurs_serie_journee_TP: Array<string> | null,
    count_ligne_heure: number
}

