import { getError } from '@/utils/helpers'
import VilleService from '@/services/VilleService'
import { StateVilles, VilleInterface, ZoneInterface } from '@/types/Ville'
import Vue from 'vue'

export const namespaced = true

function setVilles(commit: Function, response: any) {
    commit('SET_VILLES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateVilles = {
    villes: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    villeSelect: {},
    zones: []
}

export const mutations = {
    RESET_STATE(state: StateVilles) {
        Object.assign(state, {
            villes: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            matiereSelect: {},
            zones: []
        })
    },
    SET_VILLES(state: any, villes: Array<VilleInterface>) {
        state.villes = villes
    },
    SET_MORE_VILLES(state: any, villes: Array<VilleInterface>) {
        state.villes = state.villes.concat(villes)
    },
    SET_ZONES(state: any, zones: Array<ZoneInterface>) {
        state.zones = zones
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_VILLE_SELECT(state: any, ville: VilleInterface) {
        state.villeSelect = ville
    },
    MAJ_COLLECTION_ADD(state: any, ville: VilleInterface) {
        state.villes.push(ville)
        state.totalRows = state.villes.length
    },
    MAJ_COLLECTION_UPDATE(state: any, ville: VilleInterface) {
        const index = state.villes.map((v: VilleInterface) => v.id).indexOf(ville.id)
        Vue.set(state.villes, index, ville)
        state.totalRows = state.villes.length
    },
    MAJ_COLLECTION_DELETE(state: any, ville_id: number) {
        const index = state.villes.map((v: VilleInterface) => v.id).indexOf(ville_id)
        state.villes.splice(index, 1)
        state.totalRows = state.villes.length
    },
    ADD_ZONE(state: any, zone: ZoneInterface) {
        state.zones.push(zone)
    },
    MAJ_ZONE(state: any, zone: ZoneInterface) {
        const index = state.zones.map((z: ZoneInterface) => z.id).indexOf(zone.id)
        Vue.set(state.zones, index, zone)
    }
}

export const actions = {
    getVilles({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            VilleService.getVilles(params)
                .then((response) => {
                    setVilles(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getVille({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            VilleService.getVille(params.ville_id)
                .then((response) => {
                    commit('SET_VILLE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreVilles({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            VilleService.getVilles(params)
                .then((response) => {
                    commit('SET_MORE_VILLES', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateVille({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            VilleService.updateVille(params)
                .then((response) => {
                    commit('MAJ_COLLECTION_UPDATE', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addVille({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            VilleService.addVille(params)
                .then((response) => {
                    commit('MAJ_COLLECTION_ADD', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteVille({ commit }: { commit: any }, ville_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            VilleService.deleteVille(ville_id)
                .then((response) => {
                    commit('MAJ_COLLECTION_DELETE', ville_id)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getZones({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            VilleService.getZones(params)
                .then((response) => {
                    commit('SET_ZONES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addZone({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            VilleService.addZone(params)
                .then((response) => {
                    commit('ADD_ZONE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateZone({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            VilleService.updateZone(params.zone_id, params.payload)
                .then((response) => {
                    commit('MAJ_ZONE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteZone({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            VilleService.deleteZone(params.zone_id)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    villes: (state: any) => {
        return state.villes
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.villes.length
    },
    villeSelect: (state: any) => {
        return state.villeSelect
    },
    zones: (state: any) => {
        return state.zones
    }
}
