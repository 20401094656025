// candidatconcourphaseliste

import * as API from '@/services/API'
import store from '@/store/index'

export default {
    async getCandidatConcourPhaseListe(candidatConcourPhaseListeId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(
            `/sessions/${session_id}/candidatconcourphaselistes/${candidatConcourPhaseListeId}`
        )
    },
    async getCandidatConcourPhaseListes(payload: any = null) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/candidatconcourphaselistes`, { params: payload })
    },
    async updateCandidatConcourPhaseListe(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/candidatconcourphaselistes/${payload.id}`, payload)
    },
    async addCandidatConcourPhaseListe(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/candidatconcourphaselistes`, payload)
    },
    async deleteCandidatConcourPhaseListe(candidatConcourPhaseListeId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(
            `/sessions/${session_id}/candidatconcourphaselistes/${candidatConcourPhaseListeId}`
        )
    },
    async import(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const formData = new FormData()
        formData.append('document', payload)
        return await API.apiClient.post(`/sessions/${session_id}/candidatconcourphaseliste/import`, formData)
    },
    export() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/candidatconcourphaselistes/export`, {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    },
    exportDecision(candidatconcourphaseliste: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/candidatconcourphaselistes/exportdecision/${candidatconcourphaseliste}?format=b64`,
            { headers: { 'X-Request-Retry': 0 }}
        )
    },
    exportListe(candidatconcourphaseliste: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/candidatconcourphaselistes/exportliste/${candidatconcourphaseliste}?format=b64`,
            { headers: { 'X-Request-Retry': 0 }}
        )
    },
    justificatifsChoixPoste () {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/candidatconcourphaselistes/export/justificatifs`,
            {headers: { 'X-Request-Retry': 0 }, responseType: 'blob'}
        )
    },
    async getDocument (payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/candidatconcourphaselistes/${payload.id}/documents/${payload.name}?format=b64`)
    }
}
