import * as API from '@/services/API'
import { AxiosRequestConfig } from 'axios'
import store from '@/store/index'

export default {
    async getCandidatEpreuve(candidatEpreuveId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/candidatEpreuves/${candidatEpreuveId}`)
    },
    async getCandidatEpreuves(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(
            `/sessions/${session_id}/epreuvecorrections/${payload.epreuveCorrectionId}/candidatepreuves`,
            { params: payload }
        )
    },
    async updateCandidatEpreuve(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/candidatEpreuves/${payload.id}`, payload)
    },
    async addCandidatEpreuve(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/candidatEpreuves`, payload)
    },
    async deleteCandidatEpreuve(candidatEpreuve_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/candidatEpreuves/${candidatEpreuve_id}`)
    },
    async exportCandidatEpreuves(payload: any) {
        //  GET /api/epreuvecorrections/{epreuvecorrection_id}/candidatepreuves_export
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return await API.apiClient.get(
            `/sessions/${session_id}/epreuvecorrections/${payload.epreuvecorrection_id}/candidatepreuves_export`,
            params
        )
    }
}
