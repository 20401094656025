import * as API from '@/services/API'

export default {
    async getBonusCandidat(bonusCandidatId: number) {
        return await API.apiClient.get(`/bonuscandidats/${bonusCandidatId}`)
    },
    async getBonusCandidats(payload: any) {
        return await API.apiClient.get('/bonuscandidats', { params: payload })
    },
    async updateBonusCandidat(payload: any) {
        return await API.apiClient.put(`/bonuscandidats/${payload.id}`, payload)
    },
    async addBonusCandidat(payload: any) {
        return await API.apiClient.post('/bonuscandidats', payload)
    },
    async deleteBonusCandidat(bonusCandidat_id: number) {
        return await API.apiClient.delete(`/bonuscandidats/${bonusCandidat_id}`)
    }
}
