import { getError } from '@/utils/helpers'
import MatiereService from '@/services/MatiereService'
import { MatiereInterface, StateMatieres } from '@/types/Matiere'

export const namespaced = true

function setMatieres(commit: Function, response: any) {
    commit('SET_MATIERES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
}

export const state: StateMatieres = {
    matieres: [],
    meta: null as StateMatieres['meta'],
    links: null as StateMatieres['links'],
    loading: false as StateMatieres['loading'],
    error: null as StateMatieres['error'],
    totalRows: 0 as StateMatieres['totalRows'],
    matiereSelect: {} as StateMatieres['matiereSelect']
}

export const mutations = {
    RESET_STATE(state: StateMatieres) {
        Object.assign(state, {
            matieres: [],
            meta: null as StateMatieres['meta'],
            links: null as StateMatieres['links'],
            loading: false as StateMatieres['loading'],
            error: null as StateMatieres['error'],
            totalRows: 0 as StateMatieres['totalRows'],
            matiereSelect: {} as StateMatieres['matiereSelect']
        })
    },
    SET_MATIERES(state: any, matieres: Array<MatiereInterface>) {
        state.matieres = matieres
    },
    SET_MORE_MATIERES(state: any, matieres: Array<MatiereInterface>) {
        state.matieres = state.matieres.concat(matieres)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_MATIERE_SELECT(state: any, matiere: MatiereInterface) {
        state.matiereSelect = matiere
    }
}

export const actions = {
    getMatieres({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            MatiereService.getMatieres(params)
                .then((response) => {
                    setMatieres(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getMatiere({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            MatiereService.getMatiere(params.matiere_id)
                .then((response) => {
                    commit('SET_MATIERE_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getMoreMatieres({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            MatiereService.getMatiere(params.matiere_id)
                .then((response) => {
                    commit('SET_MATIERE_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getSessionMatieres({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            MatiereService.getSessionMatieres(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    updateMatiere({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            MatiereService.updateMatiere(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    addMatiere({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            MatiereService.addMatiere(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    deleteMatiere({ commit }: { commit: any }, matiere_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            MatiereService.deleteMatiere(matiere_id)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {
    matieres: (state: any) => {
        return state.matieres
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.matieres.length
    },
    matiereSelect: (state: any) => {
        return state.matiereSelect
    },
    getMatiereById: (state: any) => (id: number) => {
        return state.matieres.filter((m: MatiereInterface): boolean => m.id === id)[0]
    },
    getCodeMatiere: (state: any) => (id: number) => {
        const matiere = state.matieres.filter((m: MatiereInterface): boolean => m.id === id)[0]
        if (matiere) {
            return matiere.code
        } else {
            return 'NR'
        }
    },
    getMatieresObs: (state: any) => (liste_matiere_obs: any) => {
        const tab_matieres: Array<any> = []
        for (const mo in liste_matiere_obs) {
            if (liste_matiere_obs[mo].entity_id) {
                const matiere_temp = state.matieres.find((m: MatiereInterface): boolean => m.id === liste_matiere_obs[mo].entity_id)
                if (matiere_temp) {
                    tab_matieres.push(matiere_temp)
                }
            }
        }

        return tab_matieres
    }
}
