import { getError } from '@/utils/helpers'
import CentreFourniturePapetiereService from '@/services/CentreFourniturePapetiereService'
import { CentreFPInterface, StateCentreFP } from '@/types/CentreFP'

export const namespaced = true

function setCentreFP(commit: Function, response: any) {
    commit('SET_CENTRE_FP', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateCentreFP = {
    centre_fp: [],
    meta: null,
    links: null,
    loading_cfp: false,
    error_cfp: null,
    totalRows: 0,
    centre_fp_select: {}
}

export const mutations = {
    RESET_STATE(state: StateCentreFP) {
        Object.assign(state, {
            centre_fp: [],
            meta: null,
            links: null,
            loading_cfp: false,
            error_cfp: null,
            totalRows: 0,
            centre_fp_select: {}
        })
    },
    SET_CENTRE_FP(state: any, centre_fp: Array<CentreFPInterface>) {
        state.centre_fp = centre_fp
    },
    SET_MORE_CENTRE_FP(state: any, centre_fp: Array<CentreFPInterface>) {
        state.centre_fp = state.centre_fp.concat(centre_fp)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading_cfp = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error_ce = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_CENTRE_FP_SELECT(state: any, centre_fp: CentreFPInterface) {
        state.centre_fp_select = centre_fp
    }
}

export const actions = {
    getCentreFournituresPapetieres({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreFourniturePapetiereService.getCentreFournituresPapetieres(payload.centre_id, payload.params)
                .then((response) => {
                    setCentreFP(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreCentreFournituresPapetieres({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreFourniturePapetiereService.getCentreFournituresPapetieres(payload.centre_id, payload.params)
                .then((response) => {
                    commit('SET_MORE_CENTRE_FP', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    saveCentreFournituresPapetieres({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreFourniturePapetiereService.saveFournituresPapetieres(payload.centre_id, payload.params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPDF({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreFourniturePapetiereService.getPDF(payload.centre_id)
                .then((response) => {
                    commit('SET_PDF', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    centre_fp: (state: any) => {
        return state.centre_fp
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading_cfp
    },
    error: (state: any) => {
        return state.error_cfp
    },
    totalRows: (state: any) => {
        return state.centre_fp.length
    },
    centre_fp_select: (state: any) => {
        return state.centre_fp_select
    }
}
