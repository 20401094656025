import * as API from '@/services/API'
import { AxiosRequestConfig } from 'axios'
import store from '@/store/index'

export default {
    getCentres(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/centres`, { params: payload })
    },
    getCentre(centreId: number, params: any) {
        const session_id = store.getters['auth/user_session_id']
        const searchParams = new URLSearchParams(params)
        return API.apiClient.get(`/sessions/${session_id}/centres/${centreId}?${searchParams.toString()}`)
    },
    getCentreUsers(centreId: number, params: any) {
        const session_id = store.getters['auth/user_session_id']
        const searchParams = new URLSearchParams(params)
        return API.apiClient.get(`/sessions/${session_id}/centres/${centreId}/users?${searchParams.toString()}`)
    },
    updateCentre(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/centres/${payload.id}`, payload)
    },
    updateCentreFormData(centre_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/centres/${centre_id}`, payload)
    },
    addCentre(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/centres`, payload)
    },
    deleteCentre(centre_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/centres/${centre_id}`)
    },
    addDossierUser(centre_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/centres/${centre_id}/users`, payload)
    },
    getDossierUser(centre_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const searchParams = new URLSearchParams(payload)
        return API.apiClient.get(`/sessions/${session_id}/centres/${centre_id}/users?${searchParams.toString()}`)
    },
    dissociateUserFromCentre(centre_id: number, centre_user_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/centres/${centre_id}/users/${centre_user_id}`, payload)
    },
    updateUserFromCentre(centre_id: number, centre_user_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/centres/${centre_id}/users/${centre_user_id}`, payload)
    },
    getUsersFromSearch(centre_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/centres/${centre_id}/users_search`, payload)
    },
    getUsersCount(centre_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/centres/${centre_id}/users_count`)
    },
    validateIntervenantFromCentre(centre_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/centres/${centre_id}`, payload)
    },
    getConventionPdf(centre_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/centres/${centre_id}/pdf?format=b64`,
            {headers: { 'X-Request-Retry': 0 }}
        )
    },
    getAnnexeConventionPdf(centre_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/centres/${centre_id}/pdfannexe?format=b64`,
            {headers: { 'X-Request-Retry': 0 }}
        )
    },
    getRIBPdf(centre_id: number) {
        const session_id = store.getters['auth/user_session_id']

        return API.apiClient.get(`/sessions/${session_id}/centres/${centre_id}/rib?format=b64`)
    },
    deleteRIB(centre_id: number) {
        const session_id = store.getters['auth/user_session_id']

        return API.apiClient.delete(`/sessions/${session_id}/centres/${centre_id}/rib`)
    },
    getConventions(centre_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/centres/${centre_id}/conventions`)
    },
    getTokenSignature(centreId: number, mode: string) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/centres/${centreId}/token_signature?mode=${mode}`)
    },
    exportCentres() {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return API.apiClient.get(`/sessions/${session_id}/centres_export`, params)
    },
    exportSalles() {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return API.apiClient.get(`/sessions/${session_id}/salles_export`, params)
    },
    exportCentresEstimations() {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return API.apiClient.get(`/sessions/${session_id}/centres_export_estimations`, params)
    },
    exportDecisions(centre_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/centres/${centre_id}/candidat_amenagements_export`, {responseType: 'blob', headers: { 'X-Request-Retry': 0 }})
    },
    exportCentresIndisponibilites() {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return API.apiClient.get(`/sessions/${session_id}/centres_export_indisponibilites`, params)
    },
    exportCentresRecapitulatif() {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return API.apiClient.get(`/sessions/${session_id}/centres_export_recapitulatif`, params)
    },
    uploadCentresEstimations(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/centres_import_estimations`, payload)
    },
    async updateOrganisation(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(
            `/sessions/${session_id}/centres/${params.centre_id}/update_organisation`,
            params
        )
    },
    async updateFicheHorairePosteUser(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(
            `/sessions/${session_id}/centres/${params.centre_id}/users/${params.user_id}/postes/${params.poste_id}/fiche_horaire`,
            params.fiche_horaire
        )
    },
    async publierFichesHoraires() {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/fiche_horaire_publish`)
    },
    async publierFichesHorairesIntervenants(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/centres/${params.centre_id}`, {
            fiches_intervenants_publish: params.fiches_intervenants_publish
        })
    },
    async getPdfFicheHoraire(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(
            `/sessions/${session_id}/centres/${params.centre_id}/users/${params.user_id}/postes/${params.poste_id}/pdf`,
            {headers: { 'X-Request-Retry': 0 }}
        )
    },
    async getFicheHoraire(params: any) {
        const session_id = store.getters['auth/user_session_id']
        if (params.centreId === null) {
            return await API.apiClient.get(`/sessions/${session_id}/fiche_horaire/centres`)
        } else {
            return await API.apiClient.get(`/sessions/${session_id}/fiche_horaire/centres/${params.centreId}`)
        }
    },
    async getTokenSignatureFicheHoraire(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(
            `/sessions/${session_id}/centres/${params.centre_id}/users/${params.user_id}/postes/${params.poste_id}/token_signature?mode=${params.mode}`
        )
    },
    async signatureFicheHoraire(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(
            `/sessions/${session_id}/centres/${params.centre_id}/users/${params.user_id}/postes/${params.poste_id}/signature`,
            params.payload
        )
    },
    async getPdfFichesHoraire(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(
            `/sessions/${session_id}/centres/${params.centre_id}/pdf_fiches`,
            {headers: { 'X-Request-Retry': 0 }}
        )
    },
    async getTokenSignatureFichesHoraire(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(
            `/sessions/${session_id}/centres/${params.centre_id}/token_signature_fiches?mode=${params.mode}`
        )
    },
    async signatureFichesHoraire(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(
            `/sessions/${session_id}/centres/${params.centre_id}/signature_fiches`,
            params.payload
        )
    },
    relaunchCentre(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/centres/${params.centre_id}/relaunch/${params.type}`)
    },
    relaunchCentres(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/centres_relaunch/${params.type}`)
    },
    majStatutFournitures(centre_id: number, params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/centres/${centre_id}/suivi_fournitures`, params)
    },
    relaunchSuiviFournituresCentre(centre_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/centres/${centre_id}/suivi_fournitures_relaunch`, {
            _method: 'PUT'
        })
    },
    exportCommandeSujets(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        if (payload !== null) {
            return API.apiClient.get(
                `/sessions/${session_id}/commande_sujets_export?filter-centre.ville.zone_id=${JSON.stringify(payload)}`,
                params
            )
        } else {
            return API.apiClient.get(`/sessions/${session_id}/commande_sujets_export`, params)
        }
    },
    exportCommandeFournituresPap() {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return API.apiClient.get(`/sessions/${session_id}/commande_fourniture_papetieres_export`, params)
    },
    updateCentreFournituresStatut(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/centres_fournitures_statut`, payload)
    },
    updateConditionnement(centre_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/centres/${centre_id}/centre_conditionnement`, payload)
    }
}
