



































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { functionsForAuth } from '@/types/AuthorisationMenu'
import { Ability } from '@/types/Ability'
import { Etat } from '@/types/DossierAdministratif'

interface SousMenu {
    readonly subName: string,
    readonly subAuth: string[] | boolean[],
    readonly subIcon: string,
    readonly subPath: string
}

interface Menu {
    readonly name: string,
    readonly id: string,
    readonly auth: string[] | boolean[],
    readonly icon: string,
    readonly dataBsParent: string,
    readonly childElement: boolean,
    readonly path?: string,
    readonly sousMenu?: SousMenu[]
}

@Component({
    computed: {
        ...mapGetters('session', ['sessionsValides']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('user', [
            'dossieracademiqueId',
            'dossieradministratifId',
            'hasContrat',
            'centreId',
            'centreTypePassation',
            'conventionPublished',
            'userAbilities',
            'has_poste_aca'
        ]),
        ...mapGetters('user', ['dashboard']),
        ...mapGetters('auth', ['is_intervenant_centre'])
    }
})

export default class SideBarLeft extends Vue {
    dataReady = false
    /* état de la barre
    navStyle change la classe 'is-close'/'is-open'
    */
    milieuMenuPremierNiveau: any = []
    bottomMenuPremierNiveau: any = []

    toggled = true
    navStyle = 'is-close'
    Ability = Ability
    visible = false

    /* gestion de l'icône du toggle btn */
    toggleBtnOpenIt = 'align-justify'
    toggleBtnCloseBtn = 'chevron-left'
    buttonIcon = this.toggleBtnOpenIt
    userId = this.$store.getters['auth/authUser'].id
    /* New Menu */
    selectedComponant = 'init'

    @Watch('authUser', { immediate: true, deep: true })
    @Watch('dossieracademiqueId')
    @Watch('dossieradministratifId')
    @Watch('hasContrat')
    @Watch('conventionPublished')
    setMenu(): void {
        this.initMenu()
    }

    @Watch('centreId')
    @Watch('centreTypePassation')
    setMenuCdc(): void {
        if (this.$store.getters['user/centreId'] || this.$store.getters['user/centreTypePassation']) {
            this.initMenu()
        }
    }

    @Watch('user_session_id')
    async refreshInterface(): Promise<void> {
        await this.load()
    }

    @Watch('has_poste_aca')
    refreshSideBar(): void {
        if (this.$store.state.user.has_poste_aca) {
            this.initMenu()
        }
    }

    /**
     * Active le reinit du composant sélectionné
     * @returns {void}
     */
    backComponant(): void {
        this.selectedComponant = 'init'
    }

    /**
     * Trie les infos du menu
     * @returns {void}
     */
    initMenu(): void {
        this.milieuMenuPremierNiveau = this.milieuMenuPremierNiveau.filter((e: any) => e.actif === true)
        this.milieuMenuPremierNiveau = functionsForAuth.menuFiltered(functionsForAuth.firstElements)
        this.bottomMenuPremierNiveau = functionsForAuth.menuFiltered(functionsForAuth.bottomElements)

        /* Filtre par niveau les elements à afficher */
        for (const niveau1 of this.milieuMenuPremierNiveau) {
            niveau1.menu = functionsForAuth.menuFiltered(niveau1.menu)
            niveau1.menu = niveau1.menu.filter((e: any) => e.actif === true)
            for (const niveau2 of niveau1.menu) {
                if (niveau2.sousMenu) {
                    niveau2.sousMenu = functionsForAuth.menuFiltered(niveau2.sousMenu)
                        .filter((e: any) => e.actif === true)
                }
            }
        }

        /* Boucle pour l'affichage du bottom menu */
        this.bottomMenuPremierNiveau = this.bottomMenuPremierNiveau.filter((bm: any) => bm.actif === true)
        for (const bottomN1 of this.bottomMenuPremierNiveau) {
            if (bottomN1 && bottomN1.childElement) {
                bottomN1.sousMenu = functionsForAuth.menuFiltered(bottomN1.sousMenu)
            }
        }

        // Filtre les élements selon actif
        this.milieuMenuPremierNiveau = this.milieuMenuPremierNiveau.filter((e: any) => e.actif === true)
        this.dataReady = true
    }

    /**
     * Fermeture barre de navigation
     * @returns {void}
     */
    closeNavbar(): void {
        if (!this.toggled) {
            this.navStyle = 'is-close'
            this.toggled = !this.toggled
            this.buttonIcon = this.toggleBtnOpenIt
        }
    }

    /**
     * Ouverture barre navigation
     * @returns {void}
     */
    openNavbar(): void {
        if (this.toggled) {
            this.navStyle = 'is-open'
            this.toggled = !this.toggled
            this.buttonIcon = this.toggleBtnCloseBtn
        }
    }

    /**
     * Au démarrage, filtre si des elements ne sont pas actif
     * @param {Array} sousmenus - Tableau des sous menus
     * @returns {Array} - Tableau des sous menus actifs
     */
    filterSousMenu(sousmenus: Array<any>): Array<any> {
        return sousmenus.filter((sm: any) => sm.actif === true)
    }

    /**
     * Chargement de l'interface
     * @returns {Promise<void>}
     */
    async load(): Promise<void> {
        const can = this.$store.getters['auth/can']
        const authUser = this.$store.getters['auth/authUser']
        if (authUser?.abilities?.length > 0) {
            // Init selon profil
            if (can(Ability.RH_ADM_DOS_OWN_MANAGE) || this.$store.getters['auth/is_intervenant_centre'] || can(Ability.RH_SPS_DOS_OWN_MANAGE)) {
                if (!(this.$store.getters['user/centreId'] !== null || this.$store.getters['user/conventionPublished'] !== null || this.$store.getters['user/dossieracademiqueId'] !== null || this.$store.getters['user/dossieradministratifId'] !== null || this.$store.getters['user/hasContrat'] !== null)) {
                    if (this.$store.getters['auth/is_intervenant_centre']) {
                        this.$store.state.user.centreId = this.$store.state.centre.centres[0]?.id || null
                        this.$store.state.user.centreTypePassation = this.$store.state.centre.centres[0]?.type_passation || null
                        this.$store.state.user.centre_organisation_ecrit_validated = this.$store.state.centre.centres[0]?.nb_intervenants_validated_at || null
                        if (this.$store.state.centre.centres[0]) {
                            this.$store.commit('centre/SET_CENTRE_SELECT', this.$store.state.centre.centres[0])
                        }

                        this.$store.state.user.conventionPublished = null
                        if (this.$store.state.user.centreId && (can(Ability.GCI_CONVENTION_OWN_VIEW) || can(Ability.GC_CONVENTION_VIEW))) {
                            await this.$store.dispatch('centre/getConventions', { centre_id: this.$store.state.user.centreId })
                            this.$store.state.user.conventionPublished = this.$store.state.centre.conventions[0]?.convention_published_at || null
                        }
                    }

                    if (can(Ability.RH_SPS_DOS_OWN_MANAGE) && !this.$store.getters['dossieracademique/loading']) {
                        this.$store.state.user.dossieracademiqueId = null

                        await this.$store.dispatch('dossieracademique/getDossiersAcademiques')
                        const da = this.$store.getters['dossieracademique/dossierAcademiqueByUserId'](this.$store.state.auth.user.id)
                        if (da) {
                            this.$store.state.user.dossieracademiqueId = da.courant?.id || da.id || null
                        }
                    }

                    if (can(Ability.RH_ADM_DOS_OWN_MANAGE) && !this.$store.getters['dossieradministratif/loading']) {
                        this.$store.state.user.dossieradministratifId = null
                        const dossiersAdministratifs = this.$store.getters['dossieradministratif/dossiersAdministratifs']
                        if (!dossiersAdministratifs?.length) {
                            await this.$store.dispatch('dossieradministratif/getDossiersAdministratifs')
                        }
                        this.loadChargementDossiersAdministratifs()
                    }
                }
            }
            this.initMenu()
        }

        /* Récup des informations de window.localStorage, si info, ouverture du composant correspondant */
        if (window.localStorage && window.localStorage['dernierDashboardUtilise' + this.userId]) {
            const userLastDashboard = window.localStorage['dernierDashboardUtilise' + this.userId]
            const filter = this.milieuMenuPremierNiveau.filter((each: any) => each.dashboardPath === userLastDashboard)
            if (filter && userLastDashboard && filter[0] && filter[0].name) {
                this.selectedComponant = filter[0].name
            }
        }
    }

    loadChargementDossiersAdministratifs(): void {
        const dossiersAdministratifs = this.$store.getters['dossieradministratif/dossiersAdministratifs']
        this.$store.state.user.dossieradministratifId = (dossiersAdministratifs[0]?.etat || 0) === Etat.ETAT_INVISIBLE ? null : dossiersAdministratifs[0].id

        if (dossiersAdministratifs.length > 0 && dossiersAdministratifs[0]?.contrats?.length > 0) {
            this.$store.commit('dossieradministratif/SET_CONTRATS', dossiersAdministratifs[0].contrats)
            this.$store.state.user.hasContrat = true
        }
    }

    async beforeMount(): Promise<void> {
        document.documentElement.style.setProperty('--color-1', process.env.VUE_APP_COLOR_1)
        document.documentElement.style.setProperty('--color-2', process.env.VUE_APP_COLOR_2)
        document.documentElement.style.setProperty('--color-3', process.env.VUE_APP_COLOR_3)

        if (this.$store.getters['auth/user_session_id'] !== null) {
            await this.load()
        }
    }

    /**
     * Récupère la valeur du lien cliqué afin de faire un select sur le bon composant
     * @param {string} name - Nom du composant
     * @returns {void}
     */
    affichageComposant(name: string): void {
        this.selectedComponant = name
    }

    /**
     * Vérifie si un window.localStorage existe et s'il contient la meme route que le bouton du menu
     * @param {string} dashboardRoute - Route du dashboard
     * @returns {boolean} - Retourne true si la route est la même
     */
    isLocalStorage(dashboardRoute: string): boolean {
        return !!(
            window.localStorage?.dernierDashboardUtilise &&
            window.localStorage[`dernierDashboardUtilise${this.userId}`] === dashboardRoute
        )
    }

    /**
     * Enregistre dans window.localStorage le lien du dashboard
     * @param {string} value - Route du dashboard
     * @returns {void}
     */
    bringMeTheDashboardOf(value: string): void {
        if (value) {
            if (window.localStorage) {
                window.localStorage[`dernierDashboardUtilise${this.userId}`] = value
            }
        }
    }

    /**
     * Au clic, récupère la liste des elements du tableau et contrôle si une des routes est active
     * @param {Array} tableauDesRoutes - Tableau des routes
     * @returns {boolean} - Retourne true si une des routes est active
     */
    bottomSelectedRoute(tableauDesRoutes: any): boolean {
        const routeParams = this.$route.path
        if (routeParams && tableauDesRoutes) {
            return tableauDesRoutes.some((each: { subPath: string }) => each.subPath === routeParams)
        }
        return false
    }

    /** vérifie si le lien actif est un lien vers dashboard  */
    dashboardIsSelected() {
        const routeParams = this.$route.path
        if (routeParams) {
            return this.milieuMenuPremierNiveau.some((each: any) => each?.dashboardPath === routeParams)
        }
        return false
    }

    /**  contrôle si un lien est actif, si oui, return true sauf si un lien dans le bottom est actif ou qu'on */
    thereIsAnActiveLink(idOfCollaspe: string) {
        const inBottom = this.bottomMenuPremierNiveau && this.bottomMenuPremierNiveau[0] ? this.bottomSelectedRoute(this.bottomMenuPremierNiveau[0].sousMenu) : true
        const id = document.querySelector(`#${idOfCollaspe}`)
        const show = id?.classList.contains('show')
        const dashboard = this.dashboardIsSelected()
        const findActive = id?.querySelector('.router-link-active')
        // Si un lien actif ou si pas de lien actif, vérifie si la classe show est présente et si un lien du bottom ou du type dashboard est actif. ça permet de laisser ouvert le collapse dans ce cas
        return !!(findActive || (inBottom && show) || (dashboard && show))
    }
}
