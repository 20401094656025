import * as API from '@/services/API'
import store from '@/store'

export default {
    async recherche(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/fournitures_examinateurs`, { params: payload })
    },
    getPDF(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/fournitures_examinateurs`, { params: payload, headers: { 'X-Request-Retry': 0 }})
    }
}
