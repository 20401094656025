import * as API from '@/services/API'
import store from '@/store'

export default {
    async getEnsemble(ensembleId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/ensembles/${ensembleId}`)
    },
    async getEnsembles(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        if (payload) {
            if (payload.session_id) {
                return await API.apiClient.get(`/sessions/${session_id}/ensembles?session_id=${payload.session_id}`, {
                    params: payload.params
                })
            } else if (payload.params) {
                return await API.apiClient.get(`/sessions/${session_id}/ensembles`, { params: payload.params })
            } else {
                return await API.apiClient.get(`/sessions/${session_id}/ensembles`, { params: payload })
            }
        } else {
            return await API.apiClient.get(`/sessions/${session_id}/ensembles`)
        }
    },
    async updateEnsemble(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/ensembles/${payload.id}`, payload)
    },
    async addEnsemble(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/ensembles`, payload)
    },
    async deleteEnsemble(ensemble_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/ensembles/${ensemble_id}`)
    },
    async getExcelEnsembles(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/ensembles?session_id=${payload.session_id}`, {
            params: payload.params,
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    }
}
