import store from '@/store/index'
import * as API from '@/services/API'
import { AxiosRequestConfig } from 'axios'

export default {
    getDemissions(type: any, params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/demissions/${type}`, { params: params })
    },
    updateDemission(type: any, candidat_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/demissions/${type}/candidats/${candidat_id}`, payload)
    },
    postDemission(type: any, candidat_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/demissions/${type}/candidats/${candidat_id}`, payload)
    },
    exportDemission(type: any) {
        // demissions_export
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return API.apiClient.get(`/sessions/${session_id}/demissions_export/${type}`, params)
    }
}
