import * as API from '@/services/API'
import store from '@/store/index'

export default {
    storeAvenant(contrat_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/contrats/${contrat_id}/avenants`, payload)
    },
    updateAvenant(contrat_id: number, avenant_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/contrats/${contrat_id}/avenants/${avenant_id}`, payload)
    },
    deleteAvenant(contrat_id: number, avenant_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/contrats/${contrat_id}/avenants/${avenant_id}`)
    },
    getPDF(contrat_id: number, avenant_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/contrats/${contrat_id}/avenants/${avenant_id}/pdf?format=b64`)
    },
    getTokenSignature(contrat_id: number, avenant_id: number, mode: string) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/contrats/${contrat_id}/avenants/${avenant_id}/token_signature?mode=${mode}`
        )
    }
}
