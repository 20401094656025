import * as API from '@/services/API'
import store from '@/store'

export default {
    async getGroupeComparaisonConcour(groupeId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/groupe_comparaison_concours/${groupeId}`)
    },
    async getGroupeComparaisonConcours(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/groupe_comparaison_concours`, { params: payload })
    },
    async updateGroupeComparaisonConcour(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/groupe_comparaison_concours/${payload.id}`, payload)
    },
    async addGroupeComparaisonConcour(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/groupe_comparaison_concours`, payload)
    },
    async deleteGroupeComparaisonConcour(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/groupe_comparaison_concours/${payload.id}`)
    }
}
