import { getError } from '@/utils/helpers'
import ConfigPhaseService from '@/services/ConfigPhaseService'
import { ConfigPhaseInterface, StateConfigPhases } from '@/types/ConfigPhase'

export const namespaced = true

function setConfigPhases(commit: Function, response: any) {
    commit('SET_MATIERES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateConfigPhases = {
    configPhases: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    configPhaseSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateConfigPhases) {
        Object.assign(state, {
            configPhases: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            configPhaseSelect: {}
        })
    },
    SET_MATIERES(state: any, configPhases: Array<ConfigPhaseInterface>) {
        state.configPhases = configPhases
    },
    SET_MORE_MATIERES(state: any, configPhases: Array<ConfigPhaseInterface>) {
        state.configPhases = state.configPhases.concat(configPhases)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_MATIERE_SELECT(state: any, configPhase: ConfigPhaseInterface) {
        state.configPhaseSelect = configPhase
    }
}

export const actions = {
    getConfigPhases({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConfigPhaseService.getConfigPhases(params)
                .then((response) => {
                    setConfigPhases(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getConfigPhase({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConfigPhaseService.getConfigPhase(params.configPhase_id)
                .then((response) => {
                    commit('SET_MATIERE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreConfigPhases({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConfigPhaseService.getConfigPhase(params.configPhase_id)
                .then((response) => {
                    commit('SET_MATIERE_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateConfigPhase({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConfigPhaseService.updateConfigPhase(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addConfigPhase({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConfigPhaseService.addConfigPhase(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteConfigPhase({ commit }: { commit: any }, configPhase_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConfigPhaseService.deleteConfigPhase(configPhase_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    configPhases: (state: any) => {
        return state.configPhases
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.configPhases.length
    },
    configPhaseSelect: (state: any) => {
        return state.configPhaseSelect
    },
    getConfigPhaseById: (state: any) => (id: number) => {
        const configPhase = state.configPhases.filter((mat: any) => mat.id === id)[0]
        return configPhase
    },
    getCodeConfigPhase: (state: any) => (id: number) => {
        const configPhase = state.configPhases.filter((mat: any) => mat.id === id)[0]
        if (configPhase) {
            return configPhase.code
        } else {
            return 'NR'
        }
    },
    getConfigPhasesObs: (state: any) => (liste_configPhase_obs: any) => {
        const tab_configPhases: Array<any> = []
        for (const mo in liste_configPhase_obs) {
            if (liste_configPhase_obs[mo].entity_id) {
                const configPhase_temp = state.configPhases.find(
                    (m: any) => m.id === liste_configPhase_obs[mo].entity_id
                )
                if (configPhase_temp) {
                    tab_configPhases.push(configPhase_temp)
                }
            }
        }

        return tab_configPhases
    }
}
