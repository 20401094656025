import { find, map, pick } from 'lodash'
import store from '@/store/index'

export default class Bouncer {
    id: any
    abilities: Array<any>
    roles: Array<any>

    constructor(user: any) {
        if (!user) {
            this.id = null
            this.abilities = []
            this.roles = []

            return
        }

        const abilityMapper = (ability: any) => {
            return pick(ability, [
                'id',
                'entity_id',
                'entity_type',
                'name',
                'forbidden',
                'only_owned',
                'title',
                'scope'
            ])
        }

        this.id = user.id
        this.roles = map(user.roles, (role) => pick(role, ['name', 'title']))
        this.abilities = map(user.abilities || [], abilityMapper)
    }

    // Find the abilities that give the user permission to do the ability we are
    // checking and if we have one and the ability isn't one that forbids them
    // then we return true.
    can(abilityName: string, entityType = null, entity: any = null) {
        // Filter abilities to only ones that might be relevant to the given ability name.
        const scope = store.getters['auth/user_session_id']
        const abilities = this.abilities.filter((ability: any) => {
            if (abilityName === ability.name || ability.name === '*') {
                if (ability.entity_type === '*') {
                    return true
                }

                // if the ability has only_owned set to true entities to be allowed to be accessed
                // then we need to check that the entity's user_id matches the id of our
                // user
                if (ability.only_owned) {
                    if (entity === null || entityType === null) {
                        return false
                    }

                    if (entityType === ability.entity_type && entity.user_id !== this.id) {
                        return false
                    }
                }

                if (ability.entity_type && entityType !== ability.entity_type) {
                    return false
                }

                if (ability.entity_id) {
                    if (!entity) {
                        return false
                    }

                    if (entity.id !== ability.entity_id) {
                        return false
                    }
                }

                if (entity) {
                    // permet de s'affranchir de droit globaux si un droit spécifique existe (ex : convention sign 'global' ne permet pas de signer une convention en particulier TDB GCI)
                    if (!ability.entity_id) {
                        return false
                    }

                    if (entity.id !== ability.entity_id) {
                        return false
                    }
                }

                if (ability.scope) {
                    // console.log(abilityName + ' : ' + ability.scope + ' ? ' + scope)
                    if (ability.scope !== scope) {
                        return false
                    }
                }

                return true
            }

            return false
        })

        // if there are no relevant abilities or some of the relevant abilities are
        // forbidden then return false
        if (abilities.length === 0 || abilities.some((ability) => ability.forbidden)) {
            // console.log(abilityName + ' ' + entityType + ' ' + entity.id + ' -')

            return false
        }

        // console.log(abilityName + ' ' + entityType + ' ' + entity.id + ' OK')

        return true
    }

    // Determine if the user's roles contain any of the roles we are looking for
    isA(roles: Array<any>) {
        roles = typeof roles === 'string' ? Array.from(roles) : roles

        return roles.some((name) => {
            return find(this.roles, { name })
        })
    }

    cannot(ability: any, entityType = null, entity = null) {
        return !this.can(ability, entityType, entity)
    }

    isNotA(roles: Array<any>) {
        return !this.isA(roles)
    }
}
