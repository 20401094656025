import { getError } from '@/utils/helpers'
import DemandeRemboursementService from '@/services/DemandeRemboursementService'
import { DemandeRemboursementInterface, StateDemandeRemboursement } from '@/types/DemandeRemboursement'
import Vue from 'vue'

export const namespaced = true

function setDemandesRemboursement(commit: Function, response: any) {
    commit('SET_DEMANDES_REMBOURSEMENT', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_LOADING', false)
}

export const state: StateDemandeRemboursement = {
    demandesRemboursement: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    source_pdf: '',
    demandeRemboursementSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateDemandeRemboursement) {
        Object.assign(state, {
            demandesRemboursement: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            source_pdf: '',
            demandeRemboursementSelect: {}
        })
    },
    SET_DEMANDES_REMBOURSEMENT(state: any, demandesRemboursement: Array<DemandeRemboursementInterface>) {
        state.demandesRemboursement = demandesRemboursement
    },
    SET_MORE_DEMANDES_REMBOURSEMENT(state: any, demandesRemboursement: Array<DemandeRemboursementInterface>) {
        state.demandesRemboursement = state.demandesRemboursement.concat(demandesRemboursement)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_DEMANDE_REMBOURSEMENT(state: any, demandeRemboursement: DemandeRemboursementInterface) {
        state.demandeRemboursementSelect = demandeRemboursement
    },
    SET_SOURCE_PDF(state: any, source: string) {
        state.source_pdf = source
    },
    MAJ_DEMANDES(state: any, demande: any) {
        const index = state.demandesRemboursement.map((item: any) => item.id).indexOf(demande.id)
        Vue.set(state.demandesRemboursement, index, demande)
    }
}

export const actions = {
    getDemandesRemboursement({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DemandeRemboursementService.getDemandeRemboursements(params)
                .then((response) => {
                    setDemandesRemboursement(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDemandeRemboursement({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DemandeRemboursementService.getDemandeRemboursement(params.demande_remboursement_id)
                .then((response) => {
                    commit('SET_DEMANDE_REMBOURSEMENT', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreDemandesRemboursement({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DemandeRemboursementService.getDemandeRemboursements(params)
                .then((response) => {
                    commit('SET_MORE_DEMANDES_REMBOURSEMENT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateDemandeRemboursement({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DemandeRemboursementService.updateDemandeRemboursement(params.demande_remboursement_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('MAJ_DEMANDES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addDemandeRemboursement({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DemandeRemboursementService.addDemandeRemboursement(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteDemandeRemboursement({ commit }: { commit: any }, demande_remboursement_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DemandeRemboursementService.deleteDemandeRemboursement(demande_remboursement_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDocumentDemandeRemboursement({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DemandeRemboursementService.getDocumentDemandeRemboursement(
                params.demande_remboursement_id,
                params.document_id
            )
                .then((response) => {
                    commit('SET_SOURCE_PDF', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteDocumentDemandeRemboursement({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DemandeRemboursementService.deleteDocumentDemandeRemboursement(
                params.demande_remboursement_id,
                params.document_id
            )
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    exportDemandesRemboursements({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DemandeRemboursementService.exportDemandesRemboursements()
                .then((response) => {
                    // setDemandesRemboursement(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    demandesRemboursement: (state: any) => {
        return state.demandesRemboursement
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.totalRows
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    demandeRemboursementSelect: (state: any) => {
        return state.demandeRemboursementSelect
    },
    source_pdf: (state: any) => {
        return state.source_pdf
    }
}
