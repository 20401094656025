import { getError } from '@/utils/helpers'
import DashboardEcritService from '@/services/DashboardEcritService'

export const namespaced = true

export const state: any = {
    dashboard_ecrits_informations: [],
    loading_dashboard_ecrit: false,
    dashboard_ecrit_error: null
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            dashboard_ecrits_informations: [],
            loading_dashboard_ecrit: false,
            dashboard_ecrit_error: null
        })
    },
    SET_DASHBOARD_ECRITS_INFORMATIONS(state: any, data: any) {
        state.dashboard_ecrits_informations = data
    },
    SET_LOADING_DASHBOARD(state: any, loading: boolean) {
        state.loading_dashboard_ecrit = loading
    },
    SET_DASHBOARD_ERROR(state: any, error: Array<any>) {
        state.dashboard_ecrit_error = error
    }
}

export const actions = {
    getDashboardInformations({ commit }: { commit: any }) {
        commit('SET_LOADING_DASHBOARD', true)
        return new Promise((resolve, reject) => {
            DashboardEcritService.getDashboardEcrit()
                .then((response) => {
                    commit('SET_DASHBOARD_ECRITS_INFORMATIONS', response.data.data)
                    commit('SET_LOADING_DASHBOARD', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_DASHBOARD', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    dashboard_ecrits_informations(state: any) {
        return state.dashboard_ecrits_informations
    },
    loading_dashboard(state: any) {
        return state.loading_dashboard_ecrit
    }
}
