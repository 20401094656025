import * as API from '@/services/API'
import store from '@/store/index'

export default {
    async getConcour(concourId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/concours/${concourId}`)
    },
    async getConcours(payload: any = null) {
        const session_id = store.getters['auth/user_session_id']
        // console.log('------------getBanques-------- : ' + session_id)

        if (payload) {
            if (payload.session_id) {
                return await API.apiClient.get(`/sessions/${session_id}/concours?session_id=${payload.session_id}`, {
                    params: payload.params
                })
            } else {
                return await API.apiClient.get(`/sessions/${session_id}/concours`, { params: payload.params })
            }
        } else {
            return await API.apiClient.get(`/sessions/${session_id}/concours`)
        }
    },
    async getConcoursActifs(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/concours`, { params: params })
    },
    async updateConcour(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/concours/${payload.id}`, payload)
    },
    async addConcour(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/concours`, payload)
    },
    async deleteConcour(concourId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/concours/${concourId}`)
    },
    async import(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const formData = new FormData()
        formData.append('document', payload)
        return await API.apiClient.post(`/sessions/${session_id}/concours/import`, formData) // , config)
    }
}
