import * as API from '@/services/API'
import store from '@/store'

export default {
    async getFourniture(fournitureId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/fourniture_papetieres/${fournitureId}`)
    },
    async getFournitures(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/fourniture_papetieres`, { params: payload })
    },
    async updateFourniture(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/fourniture_papetieres/${payload.id}`, payload)
    },
    async addFourniture(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/fourniture_papetieres`, payload)
    },
    async deleteFourniture(fourniture_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/fourniture_papetieres/${fourniture_id}`)
    },
    relaunchFournitures() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/suivi_fournitures_relaunch`, { _method: 'PUT' })
    }
}
