import { getError } from '@/utils/helpers'
import EnsembleAffectationsExaminateursService from '@/services/EnsembleAffectationsExaminateursService'
import {
    ExaminateurAffectationInterface,
    StateExaminateursAffectationsInterface
} from '@/types/EnsembleAffectationsExaminateurs'
import { TypeEnsemble } from '@/types/Ensemble'
import store from '@/store/index'
import Vue from 'vue'

export const namespaced = true

function setExaminateurs(commit: Function, response: any) {
    commit('SET_EXAMINATEURS_AFFECTATIONS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
}

export const state: StateExaminateursAffectationsInterface = {
    examinateursAffectations: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    examinateurAffectationSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateExaminateursAffectationsInterface) {
        Object.assign(state, {
            examinateursAffectations: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            examinateurAffectationSelect: {},
            totalAffectactionsAPourvoir: 0, // total des affectations à pourvoir
            totalAffectationsPourvues: 0, // total des affectations pourvues
            listeIntervenants: {}
        })
    },
    SET_EXAMINATEURS_AFFECTATIONS(state: any, examinateursAffectations: Array<ExaminateurAffectationInterface>) {
        state.examinateursAffectations = examinateursAffectations
    },
    SET_MORE_EXAMINATEURS_AFFECTATIONS(state: any, examinateursAffectations: Array<ExaminateurAffectationInterface>) {
        state.examinateursAffectations = state.examinateursAffectations.concat(examinateursAffectations)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_EXAMINATEUR_AFFECTATION_SELECT(state: any, examinateurAffectation: ExaminateurAffectationInterface) {
        state.examinateurAffectationSelect = examinateurAffectation
    },
    SET_TOTAL_AFFECTATION_A_POURVOIR(state: any, totalAffectationsAPourvoir: number) {
        state.totalAffectationsAPourvoir = totalAffectationsAPourvoir
    },
    SET_TOTAL_AFFECTATION_POURVUES(state: any, totalAffectationsPourvues: number) {
        state.totalAffectationsPourvues = totalAffectationsPourvues
    },
    SET_LISTE_INTERVENANTS(state: any, listeIntervenants: number) {
        state.listeIntervenants = listeIntervenants
    },

    // Remet à jour l'affectation qui vient d'être éditée avec maj du visuel du tableau sur la vue
    MAJ_DATAS_AFFECTATION_SELECT(state: any, params: any) {
        const tableau_copy = state.examinateursAffectations
        const index = state.examinateursAffectations.map((item: any) => item.id).indexOf(params.id)
        if (index > -1) {
            tableau_copy[index].user = params.user
            tableau_copy[index].user_id = params.user_id
            tableau_copy[index].remplacant = params.remplacant
            tableau_copy[index].remplacant_id = params.remplacant_id
            tableau_copy[index].affectations = params.affectations
            tableau_copy[index].ensemble = params.ensemble
            const ligne_modif = tableau_copy[index]

            Vue.set(state.examinateursAffectations, index, ligne_modif)
        }
    }
}

export const actions = {
    getEnsembleAffectationsExaminateurs({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EnsembleAffectationsExaminateursService.getEnsembleAffectationsExaminateurs(params)
                .then((response) => {
                    setExaminateurs(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreEnsembleAffectationsExaminateurs({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EnsembleAffectationsExaminateursService.getEnsembleAffectationsExaminateurs(params)
                .then((response) => {
                    commit('SET_MORE_EXAMINATEURS_AFFECTATIONS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getInfoTotauxAffectationsExaminateurs({ commit }: { commit: any }) {
        // commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EnsembleAffectationsExaminateursService.getInfoTotauxAffectationsExaminateurs()
                .then((response) => {
                    commit('SET_TOTAL_AFFECTATION_A_POURVOIR', response.data.data.total)
                    commit('SET_TOTAL_AFFECTATION_POURVUES', response.data.data.affectes)
                    // commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    // commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getListeIntervenants({ commit }: { commit: any }, payload: any) {
        return new Promise((resolve, reject) => {
            EnsembleAffectationsExaminateursService.getListeIntervenants(payload)
                .then((response) => {
                    commit('SET_LISTE_INTERVENANTS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateAffectationsEnsemble({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            EnsembleAffectationsExaminateursService.updateAffectationsEnsemble(
                params.ensemble_id,
                params.affectation_numero,
                params.payload
            )
                .then((response) => {
                    commit('MAJ_DATAS_AFFECTATION_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    },
    exportationAffectationsExaminateurs({ commit }: { commit: any }, payload?: any) {
        return new Promise((resolve, reject) => {
            EnsembleAffectationsExaminateursService.exportationAffectationsExaminateurs(payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    examinateursAffectations: (state: any) => {
        return state.examinateursAffectations
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.examinateursAffectations.length
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    examinateurAffectationSelect: (state: any) => {
        return state.examinateurAffectationSelect
    },
    totalAffectationsAPourvoir: (state: any) => {
        return state.totalAffectationsAPourvoir
    },
    totalAffectationsPourvues: (state: any) => {
        return state.totalAffectationsPourvues
    },
    getFiliereEpreuve: () => (ensemble: any, epreuveCorrection: any) => {
        let dataFiliere = ''
        let dataEpreuve = ''
        let dataFiliereEpreuve = ''
        let dataTypePassation = null
        if (ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
            dataFiliere = ensemble.concour.name
            dataEpreuve = epreuveCorrection.name
            dataFiliereEpreuve = ensemble.concour.name + '-' + epreuveCorrection.name
            dataTypePassation = epreuveCorrection.type_passation
        } else {
            for (let i = 0; i < ensemble.groupeEpreuve.epreuves.length; i++) {
                dataTypePassation = ensemble.groupeEpreuve.epreuves[i].type_passation
                for (let j = 0; j < ensemble.groupeEpreuve.epreuves[i].epreuves.length; j++) {
                    const concTemp = store.getters['concour/concourById'](
                        ensemble.groupeEpreuve.epreuves[i].epreuves[j].concour_id
                    )
                    if (concTemp) {
                        if (dataFiliere.length > 0) {
                            dataFiliere += ', '
                        }
                        if (dataEpreuve.length > 0) {
                            dataEpreuve += ', '
                        }
                        if (dataFiliereEpreuve.length > 0) {
                            dataFiliereEpreuve += ', '
                        }
                        const codeConcours = store.getters['concour/concourById'](
                            ensemble.groupeEpreuve.epreuves[i].epreuves[j].concour_id
                        ).code
                        dataFiliere += codeConcours
                        dataEpreuve += ensemble.groupeEpreuve.epreuves[i].epreuves[j].name
                        dataFiliereEpreuve += codeConcours + '-' + ensemble.groupeEpreuve.epreuves[i].epreuves[j].name
                    }
                }
            }
        }
        return {
            filiere: dataFiliere,
            epreuve: dataEpreuve,
            filiereEpreuve: dataFiliereEpreuve,
            typePassation: dataTypePassation
        }
    }
}
