import * as API from '@/services/API'
import store from '@/store/index'
import { AxiosRequestConfig } from 'axios'

export default {
    async getAffectationExaminateur(affectationExaminateurId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/affectationexaminateurs/${affectationExaminateurId}`)
    },
    async getAffectationExaminateurs(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/affectationexaminateurs`, { params: payload })
    },
    async updateAffectationExaminateur(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/affectationexaminateurs/${payload.id}`, payload)
    },
    async addAffectationExaminateur(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/affectationexaminateurs`, payload)
    },
    async deleteAffectationExaminateur(affectationExaminateur_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(
            `/sessions/${session_id}/affectationexaminateurs/${affectationExaminateur_id}`
        )
    },
    async updateAffectationExaminateurSalles(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(
            `/sessions/${session_id}/centres/${params.centre_id}/update_organisation`,
            params
        )
    },
    async getAffectationRecapitulatif() {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return await API.apiClient.get(`/sessions/${session_id}/intervenants_academiques_recapitulatif`, params)
    }
}
