import * as API from '@/services/API'
import store from '@/store'

export default {
    async getEtablissement(etablissementId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/etablissements/${etablissementId}`)
    },
    async getEtablissements(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/etablissements`, { params: payload })
    },
    async updateEtablissement(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/etablissements/${payload.id}`, payload)
    },
    async addEtablissement(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/etablissements`, payload)
    },
    async deleteEtablissement(etablissement_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/etablissements/${etablissement_id}`)
    },
    async importEtablissement(payload: any) {
        // const config = { headers: { 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' } }
        // const config = { headers: { 'Content-Type': 'text/csv' } }
        // const config = { headers: { 'Content-Type': 'application/vnd.ms-excel' } }
        const session_id = store.getters['auth/user_session_id']
        const formData = new FormData()
        formData.append('document', payload)
        return await API.apiClient.post(`/sessions/${session_id}/etablissements/import`, formData) // , config)
    },
    getEtablissementsByAcademie(academie: string, type: string) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/etablissements?academie=${academie}&type=${type}`)
    }
}
