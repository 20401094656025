import { StatePublication } from '@/types/CandidatIncompatiblePublicationDesSaisies'

export const namespaced = true

export const state: StatePublication = {
    date_start: '',
    date_end: '',
    user_selection: '',
    error: null
}

export const mutations: any = {
    RESET_STATE(state: StatePublication) {
        Object.assign(state, {
            date_start: '',
            date_end: '',
            user_selection: '',
            error: null
        })
    },
    SET_DATE_START(state: StatePublication, date_start: string) {
        state.date_start = date_start
    },

    SET_END_START(state: StatePublication, date_end: string) {
        state.date_end = date_end
    },

    SET_USER_SELECTION(state: StatePublication, user_selection: string) {
        state.user_selection = user_selection
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    }
}

export const actions: any = {
    //
}

export const getters: any = {
    error: (state: any) => {
        return state.error
    }
}
