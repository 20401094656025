import * as API from '@/services/API'
import {
    AffectationEquipesAuCentre,
    CentreEnsemble,
    GetEnsembles,
    PayloadValidAffectationDesEquipesAuxCentres
} from '@/types/AffectationEquipes'
import store from '@/store/index'

export default {
    async getCentresAfex(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/centres_afex`, { params: params })
    },
    // utilisé pour la popup popupEditAffectationEquipe
    async getCentreEnsemble(params: CentreEnsemble) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/centres_afex`, { params: params })
    },
    async getEnsembles(payload: GetEnsembles) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/ensembles`, { params: payload })
    },
    async validAffectationDesEquipesAuxCentres(payload: PayloadValidAffectationDesEquipesAuxCentres) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/afex`, payload)
        /* PUT /api/sessions/{session_id}/afex avec ensembles_centres_validate=0/1 */
    },
    async affectationEquipesAuCentre(payload: AffectationEquipesAuCentre) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/centres_afex/${payload.centre_id}`, payload)
    }
}
