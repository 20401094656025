import AuthService from '@/services/AuthService'
import store from '@/store'
import { StateAuthUser, UserInterface } from '@/types/User'
import Bouncer from '@/utils/bouncer'
import { getError } from '@/utils/helpers'

export const namespaced = true

export const state: StateAuthUser = {
    user: null,
    loading: false,
    error: null,
    changepassword: false,
    verifyemail: false,
    acceptRGPD: false,
    twoFactorQrcode: null,
    twoFactor: false,
    twoFactorRecoveryCode: null,
    maj: null,
    from_racine: false,
    user_session_id: null,
    multi_session: false
}

export const mutations = {
    RESET_STATE(state: StateAuthUser) {
        Object.assign(state, {
            user: null,
            loading: false,
            error: null,
            changepassword: false,
            verifyemail: false,
            acceptRGPD: false,
            telephone: null,
            from_racine: false,
            user_session_id: null,
            multi_session: false
        })
    },
    SET_USER(state: StateAuthUser, user: string) {
        if (user != null) {
            const u = JSON.parse(user)
            state.user = {
                id: u.id as number,
                civility: u.civility as string,
                name: u.name as string,
                first_name: u.first_name as string,
                email: u.email as string,
                emailVerified: u.email_verified as boolean,
                rgpdAccepted: u.rgpd_accepted as boolean,
                passwordChanged: u.password_changed as boolean,
                has_certificate: u.has_certificate as boolean,
                twoFactor: u.two_factor as boolean,
                phone: u.phone as string,
                mobile: u.mobile as string,
                abilities: u.abilities as Array<any>,
                abilities_ids: [],
                telephone: u.telephone as string,
                parameters: u.parameters as Array<any>
            }
            state.changepassword = u.password_changed as boolean
            state.verifyemail = u.email_verified as boolean
            state.acceptRGPD = u.rgpd_accepted as boolean

            // On stocke le user seulement s'il a valide les conditions : verifier email, reinit password, accept rgpd
            if (state.changepassword && state.verifyemail && state.acceptRGPD) {
                // on store le user pour gerer le cas d'un refresh qui entrainer une redirection car le user est recharger apres le test de droit d'acces à la page
                window.sessionStorage.setItem('user', JSON.stringify(state.user))
            }
        } else {
            state.user = null
            // on store le user pour gerer le cas d'un refresh qui entrainer une redirection car le user est recharger apres le test de droit d'acces à la page
            if (window.sessionStorage.user) {
                const user_parse = JSON.parse(window.sessionStorage.user)
                window.sessionStorage.removeItem('centreUserSelect' + user_parse.id)
            }
            window.sessionStorage.removeItem('user_session_id')
            window.sessionStorage.removeItem('user')
        }
    },
    SET_LOADING(state: StateAuthUser, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: StateAuthUser, error: Array<string>) {
        state.error = error
    },
    SET_ACCEPT_RGPD(state: StateAuthUser, accept: boolean) {
        if (state.user != null) {
            state.user.rgpdAccepted = accept
            state.acceptRGPD = accept
            if (!state.verifyemail) {
                state.verifyemail = true
            }

            if (state.changepassword && state.verifyemail && state.acceptRGPD) {
                // on store le user pour gerer le cas d'un refresh qui entrainer une redirection car le user est recharger apres le test de droit d'acces à la page
                window.sessionStorage.setItem('user', JSON.stringify(state.user))
            }
        }
    },
    SET_CHANGE_PASSWORD(state: StateAuthUser, accept: boolean) {
        if (state.user != null) {
            state.user.passwordChanged = accept
            state.changepassword = accept

            if (state.changepassword && state.verifyemail && state.acceptRGPD) {
                // on store le user pour gerer le cas d'un refresh qui entrainer une redirection car le user est recharger apres le test de droit d'acces à la page
                window.sessionStorage.setItem('user', JSON.stringify(state.user))
            }
        }
    },
    SET_TWO_FACTOR(state: StateAuthUser, value: boolean) {
        state.twoFactor = value
    },
    SET_TWO_FACTOR_QRCODE(state: StateAuthUser, response: any) {
        state.twoFactorQrcode = response
    },
    SET_TWO_FACTOR_RECOVERY_CODE(state: StateAuthUser, response: any) {
        state.twoFactorRecoveryCode = response
    },
    SET_FROM_RACINE(state: StateAuthUser, fr: boolean) {
        state.from_racine = fr
    },
    SET_USER_SESSION_ID(state: StateAuthUser, user_session_id: number) {
        state.user_session_id = user_session_id
    },
    SET_MULTI_SESSION(state: StateAuthUser, etat: boolean) {
        state.multi_session = etat
    }
}

export const actions = {
    logout({ commit }: any) {
        console.log('logout')
        return AuthService.logout()
            .then(() => {
                console.log('logout then')
                commit('SET_USER', null)
                store.dispatch('reset')
                window.location.href = '/login'
            })
            .catch((error) => {
                console.log('logout error')
                commit('SET_ERROR', getError(error))
            })
    },
    getAuthUser({ commit }: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AuthService.getAuthUser()
                .then((response) => {
                    commit('SET_USER', JSON.stringify(response.data.data))
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_USER', null)
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    },
    setAcceptRGPD({ commit }: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AuthService.sendAcceptRGPD()
                .then((response) => {
                    commit('SET_ACCEPT_RGPD', true)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    changePassword({ commit }: any, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AuthService.updatePassword(payload)
                .then((response) => {
                    commit('SET_CHANGE_PASSWORD', true)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    },
    verifyEmail({ commit }: any, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AuthService.sendVerification(payload)
                .then((response) => {
                    commit('SET_VERIFIED_EMAIL', true)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    setTwoFactorAuthentication({ commit }: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AuthService.twoFactorAuthentication()
                .then((response) => {
                    commit('SET_TWO_FACTOR', true)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    removeTwoFactorAuthentication({ commit }: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AuthService.deleteTwoFactorAuthentication()
                .then((response) => {
                    commit('SET_TWO_FACTOR', false)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getOtpQrCode({ commit }: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AuthService.twoFactorQrCode()
                .then((response) => {
                    commit('SET_TWO_FACTOR_QRCODE', response.data.svg)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getTwoFactorRecoveryCodes({ commit }: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AuthService.twoFactorRecoveryCodes()
                .then((response) => {
                    commit('SET_TWO_FACTOR_RECOVERY_CODE', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    async sendtwoFactorChallenge({ commit }: any, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AuthService.twoFactorChallenge(payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    authUser: (state: StateAuthUser) => {
        return state.user
    },
    isAdmin: () => {
        return true // state.user ? state.user.isAdmin : false
    },
    error: (state: StateAuthUser) => {
        return state.error
    },
    loading: (state: StateAuthUser) => {
        return state.loading
    },
    loggedIn: (state: StateAuthUser) => {
        return !!state.user // TODO faire quelque chose de plus malin pour tester si l'utilisateur est connecter
    },
    bouncer: (state: StateAuthUser) => {
        if (!state.user) {
            // si les droit sont testé avant que le user soit recharger on le recupere du session storage
            const u = window.sessionStorage.getItem('user')
            if (u) {
                state.user = JSON.parse(u)
            }
        }
        return new Bouncer(state.user)
    },
    can: (state: StateAuthUser, getters: any) => {
        return getters.bouncer.can.bind(getters.bouncer)
    },
    cannot: (state: StateAuthUser, getters: any) => {
        return getters.bouncer.cannot.bind(getters.bouncer)
    },
    isA: (state: StateAuthUser, getters: any) => {
        return getters.bouncer.isA.bind(getters.bouncer)
    },
    isNotA: (state: StateAuthUser, getters: any) => {
        return getters.bouncer.isNotA.bind(getters.bouncer)
    },
    isPasswordChanged: (state: StateAuthUser) => {
        if (state.user != null) {
            const u: UserInterface = state.user
            return u.passwordChanged
        } else {
            return null
        }
    },
    isEmailVerified: (state: StateAuthUser) => {
        if (state.user != null) {
            const u: UserInterface = state.user
            return u.emailVerified
        } else {
            return null
        }
    },
    isAcceptedRGPD: (state: StateAuthUser) => {
        if (state.user != null) {
            const u: UserInterface = state.user
            return u.rgpdAccepted
        } else {
            return null
        }
    },
    changePassword: (state: StateAuthUser) => {
        return state.changepassword
    },
    verifyEmail: (state: StateAuthUser) => {
        return state.verifyemail
    },
    maj: (state: StateAuthUser) => {
        return state.maj
    },
    user_session_id: (state: StateAuthUser) => {
        return state.user_session_id
    },
    is_intervenant_centre: (state: StateAuthUser) => {
        let isI = false

        const check_abilities = state.user?.abilities.filter(
            (a: any) => a.name.indexOf('gci') !== -1 && a.scope === state.user_session_id
        )
        if (check_abilities?.length !== 0) {
            isI = true
        }

        return isI
    },
    is_intervenant_centre_nodirection: (state: StateAuthUser) => {
        let isI = false

        const check_abilities = state.user?.abilities.filter(
            (a: any) => a.name.indexOf('own') !== -1 && a.scope === state.user_session_id
        )
        if (check_abilities?.length !== 0) {
            isI = true
        }

        return isI
    },
    findParameter: (state: StateAuthUser) => (code: string) => {
        return state.user?.parameters.find((p: any) => p.code === code)
    }
}
