import * as API from '@/services/API'
import store from '@/store/index'

export default {
    getConventionTypes() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/conventiontypes`)
    },
    getConventionType(convention_type_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/conventiontypes/${convention_type_id}`)
    },
    saveConventionType(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/conventiontypes`, payload)
    },
    updateConventionType(convention_type_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/conventiontypes/${convention_type_id}`, payload)
    },
    deleteConventionType(convention_type_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/conventiontypes/${convention_type_id}`)
    },
    getPDF(convention_type_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/conventiontypes/${convention_type_id}/pdf?format=b64`,
            {headers: { 'X-Request-Retry': 0 }}
        )
    },
    getPDFAnnexe(convention_type_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/conventiontypes/${convention_type_id}/pdfAnnexe?format=b64`,
            {headers: { 'X-Request-Retry': 0 }}
        )
    }
}
