import { getError } from '@/utils/helpers'
import ConcourPhaseService from '@/services/ConcourPhaseService'
import { ConcourPhaseInterface, StateConcourPhases } from '@/types/ConcourPhase'

export const namespaced = true

function setConcourPhases(commit: Function, response: any) {
    commit('SET_CONCOUR_PHASES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateConcourPhases = {
    concourPhases: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    concourPhaseSelect: {},
    phaseAjustementCompteurs: null,
    phaseCompteurs: null
}

export const mutations = {
    RESET_STATE(state: StateConcourPhases) {
        Object.assign(state, {
            concourPhases: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            concourPhaseSelect: {},
            phaseAjustementCompteurs: null,
            phaseCompteurs: null
        })
    },
    SET_CONCOUR_PHASES(state: any, concourPhases: Array<ConcourPhaseInterface>) {
        state.concourPhases = concourPhases
    },
    SET_MORE_CONCOUR_PHASES(state: any, concourPhases: Array<ConcourPhaseInterface>) {
        state.concourPhases = state.concourPhases.concat(concourPhases)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_CONCOUR_PHASE_SELECT(state: any, concourPhase: ConcourPhaseInterface) {
        state.concourPhaseSelect = concourPhase
    },
    SET_CONCOUR_PHASE_AJUSTEMENT_COMPTEURS(state: any, phaseAjustementCompteurs: Array<any>) {
        state.phaseAjustementCompteurs = phaseAjustementCompteurs
    },
    SET_CONCOUR_PHASE_COMPTEURS(state: any, phaseCompteurs: Array<any>) {
        state.phaseCompteurs = phaseCompteurs
    }
}

export const actions = {
    getConcourPhases({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourPhaseService.getConcourPhases(params)
                .then((response) => {
                    setConcourPhases(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreConcourPhases({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourPhaseService.getConcourPhase(params.concourPhase_id)
                .then((response) => {
                    commit('SET_MORE_CONCOUR_PHASES', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getConcourPhase({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourPhaseService.getConcourPhase(params.concourPhase_id)
                .then((response) => {
                    commit('SET_CONCOUR_PHASE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateConcourPhase({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourPhaseService.updateConcourPhase(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addConcourPhase({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourPhaseService.addConcourPhase(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteConcourPhase({ commit }: { commit: any }, concourPhase_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourPhaseService.deleteConcourPhase(concourPhase_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateConcourPhaseAjustement({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourPhaseService.updateConcourPhaseAjustement(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getViatiqueResultState({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourPhaseService.getViatiqueResultState()
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    startViatiqueResultState({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourPhaseService.startViatiqueResult()
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getConcourPhaseAjustementCompteurs({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourPhaseService.getConcourPhaseAjustementCompteurs()
                .then((response) => {
                    commit('SET_CONCOUR_PHASE_AJUSTEMENT_COMPTEURS', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getConcourPhaseCompteurs({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourPhaseService.getConcourPhaseCompteurs(params)
                .then((response) => {
                    commit('SET_CONCOUR_PHASE_COMPTEURS', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    exportConcoursPhasePV({ commit }: { commit: any }, params: { phase_id: number }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourPhaseService.exportConcoursPhasePV(params.phase_id)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    exportConcoursPhaseExterne({ commit }: { commit: any }, params: { phase_id: number }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourPhaseService.exportConcoursPhaseExterne(params.phase_id)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    exportConcoursPhaseComparaisonConcours({ commit }: { commit: any }, params: { phase_id: number }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourPhaseService.exportConcoursPhaseComparaisonConcours(params.phase_id)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {
    concourPhases: (state: any) => {
        return state.concourPhases
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.meta.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.concourPhases.length
    },
    total: (state: any) => {
        return state.meta.total
    },
    currentPage: (state: any) => {
        return state.meta.current_page
    },
    totalPage: (state: any) => {
        return state.meta.total_page
    },
    concourPhaseSelect: (state: any) => {
        return state.concourPhaseSelect
    },
    getConcourPhaseById: (state: any) => (id: number) => {
        const concourPhase = state.concourPhases.filter((concPhas: any) => concPhas.id === id)[0]
        return concourPhase
    },
    getConcourPhaseByConcourIdAndPhaseId: (state: any) => (concourId: number, phaseId: number) => {
        const concourPhase = state.concourPhases.filter(
            (concPhas: any) => concPhas.concour_id === concourId && concPhas.phase_id === phaseId
        )[0]
        return concourPhase
    },
    getPhaseById: (state: any) => (id: number) => {
        const concPhase = state.concourPhases.filter((concPhas: any) => concPhas.phase_id === id)[0]
        if (concPhase) {
            return concPhase.phase
        } else {
            return null
        }
    },
    phaseAjustementCompteurs: (state: any) => {
        return state.phaseAjustementCompteurs
    },
    phaseCompteurs: (state: any) => {
        return state.phaseCompteurs
    }
}
