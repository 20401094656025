import * as API from '@/services/API'
import store from '@/store'

export default {
    async getEnveloppe(enveloppeId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/enveloppes/${enveloppeId}`)
    },
    async getEnveloppes(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/enveloppes`, { params: payload })
    },
    async updateEnveloppe(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/enveloppes/${payload.id}`, payload)
    },
    async addEnveloppe(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/enveloppes`, payload)
    },
    async deleteEnveloppe(enveloppe_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/enveloppes/${enveloppe_id}`)
    }
}
