import * as API from '@/services/API'
import store from '@/store'

/** return la session du params / payload s'il existe, sinon le choix de l utilisateur  */
export function filterSessionControleur(paramsPayload: any | undefined) {
    if (paramsPayload && paramsPayload !== undefined) {
        switch (true) {
            case paramsPayload['filter-session'] &&
                paramsPayload['filter-session'] !== null &&
                paramsPayload['filter-session'] !== undefined:
                return paramsPayload['filter-session']
            case paramsPayload.session_id &&
                paramsPayload.session_id !== null &&
                paramsPayload.session_id !== undefined:
                return paramsPayload.session_id
            default:
                return store.getters['auth/user_session_id']
        }
    } else {
        return store.getters['auth/user_session_id']
    }
}

export default {
    async getSerie(serieId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/series/${serieId}`)
    },
    async getSeries(payload: any) {
        const session_id = filterSessionControleur(payload)
        // return await API.apiClient.get('/series', { params: payload })
        if (payload) {
            if (payload.session_id) {
                return await API.apiClient.get(`/sessions/${session_id}/series`, { params: payload.params })
            } else if (payload.params) {
                return await API.apiClient.get(`/sessions/${session_id}/series`, { params: payload.params })
            } else {
                return await API.apiClient.get(`/sessions/${session_id}/series`, { params: payload })
            }
        } else {
            return await API.apiClient.get(`/sessions/${session_id}/series`)
        }
    },
    async updateSerie(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/series/${payload.id}`, payload)
    },
    async updateSeries(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/series`, payload)
    },
    async addSerie(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/series`, payload)
    },
    async deleteSerie(serie_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/series/${serie_id}`)
    }
}

