import * as API from '@/services/API'
import store from '@/store'

export default {
    getListeCentres(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/repartition_candidat_centres`, { params: payload })
    },
    getListeCentre(centre_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/repartition_candidat_centres/${centre_id}`)
    },
    getListeCandidats(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/repartition_candidats`, { params: payload })
    },
    deplacerCandidat(candidat_id: number, centre_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/repartition_candidats/${candidat_id}`, {
            _method: 'PUT',
            centre_id: centre_id
        })
    },
    deplacerCandidatMass(candidats_ids: Array<number>, centre_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/repartition_candidats_mass_update`, {
            _method: 'PUT',
            candidat_ids: candidats_ids,
            centre_id: centre_id
        })
    },
    deplacerCandidatSalle(candidat_id: number, centre_id: number, salle_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/repartition_candidats/${candidat_id}`, {
            _method: 'PUT',
            centre_id: centre_id,
            salle_id: salle_id
        })
    },
    postPlacementCandidats(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/placement_candidats`, payload)
    },
    verrouillerPlacement(centre_id: number, placement_lock: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/centres/${centre_id}`, {
            _method: 'PUT',
            placement_lock: placement_lock
        })
    },
    exporterRepartitionCandidats(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/repartition_candidats_export`, {
            params: payload,
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    },
    exporterDeplacementCandidats() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/repartition_candidats_deplacement_export `, {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    }
}
