import * as API from '@/services/API'
import store from '@/store'

export default {
    async getWorkingEstablishmentTypes(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/workingestablishmenttypes`, { params: payload })
    },
    async getWorkingEstablishmentType(workingEstablishmentTypeId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/workingestablishmenttypes/${workingEstablishmentTypeId}`)
    },
    async createWorkingEstablishmentType(workingEstablishmentType: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/workingestablishmenttypes`, workingEstablishmentType)
    },
    async editWorkingEstablishmentType(workingEstablishmentType: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/workingestablishmenttypes/${workingEstablishmentType.id}`, workingEstablishmentType)
    },
    async deleteWorkingEstablishmentType(workingEstablishmentTypeId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/workingestablishmenttypes/${workingEstablishmentTypeId}`)
    },
    async getActivityLog(workingEstablishmentTypeId: number | null) {
        const session_id = store.getters['auth/user_session_id']
        if (workingEstablishmentTypeId) {
            return await API.apiClient.get(`/sessions/${session_id}/activitylogs/workingestablishmenttype/${workingEstablishmentTypeId}`)
        } else {
            return await API.apiClient.get(`/sessions/${session_id}/activitylogs/workingestablishmenttype`)
        }
    }
}
