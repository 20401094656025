import { getError } from '@/utils/helpers'
import Vue from 'vue'
import RepartitionCandidats from '@/services/RepartitionCandidats'
import _ from 'lodash'

export const namespaced = true

function setListeCentres(commit: Function, response: any) {
    commit('SET_LISTE_CENTRES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_LOADING', false)
}

function setListePlacements(commit: Function, response: any) {
    commit('SET_LISTE_PLACEMENTS', response.data.data)
    if (response.data.meta) {
        commit('SET_META_CANDIDATS', response.data.meta)
        commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    }
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

function setListeCandidats(commit: Function, response: any) {
    commit('SET_LISTE_CANDIDATS', response.data.data)
    if (response.data.meta) {
        commit('SET_META_CANDIDATS', response.data.meta)
        commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    }

    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

// Creer la repartition par concours pour les centres
function create_repartition_concours(liste_centres: Array<any>) {
    for (const c in liste_centres) {
        liste_centres[c].repartition_concours = []

        if (liste_centres[c].salles.length !== 0 && liste_centres[c].salles_validated_at !== null) {
            // Parcours les salles du centre si elles sont renseignées
            for (const s in liste_centres[c].salles) {
                // Parcours les concours de la salle
                const concours_salle = liste_centres[c].salles[s].concours
                for (const cc in concours_salle) {
                    liste_centres[c].repartition_concours.push({
                        concour_id: concours_salle[cc].concour_id,
                        nb_candidats: concours_salle[cc].nb_candidats !== null ? concours_salle[cc].nb_candidats : 0,
                        capacite: concours_salle[cc].nb_candidats_prevision
                    })
                }
            }
        } else {
            // On parcours les estimations sur le concours car le centre n'a pas finalisé son organisation des écrits
            liste_centres[c].concours.forEach(() => {
                // Parcours les concours du centre
                const centre_concour = liste_centres[c].concours
                for (const cc in centre_concour) {
                    if (
                        !liste_centres[c].repartition_concours.find((c: any) => c.concour_id === centre_concour[cc].id)
                    ) {
                        liste_centres[c].repartition_concours.push({
                            concour_id: centre_concour[cc].id,
                            nb_candidats:
                                centre_concour[cc].nb_candidats !== null ? centre_concour[cc].nb_candidats : 0,
                            capacite: centre_concour[cc].nb_candidats_prevision
                        })
                    }
                }
            })
        }

        // Check les filières saturées dans le centre
        const tab_concours: { [index: string]: any } = {}
        for (const x in liste_centres[c].repartition_concours) {
            if (!tab_concours[liste_centres[c].repartition_concours[x].concour_id]) {
                tab_concours[liste_centres[c].repartition_concours[x].concour_id] = {
                    capacite: 0,
                    nb_candidats: 0,
                    saturation: 0
                }
            }
            tab_concours[liste_centres[c].repartition_concours[x].concour_id].capacite +=
                liste_centres[c].repartition_concours[x].capacite
            tab_concours[liste_centres[c].repartition_concours[x].concour_id].nb_candidats +=
                liste_centres[c].repartition_concours[x].nb_candidats

            if (
                tab_concours[liste_centres[c].repartition_concours[x].concour_id].nb_candidats >
                tab_concours[liste_centres[c].repartition_concours[x].concour_id].capacite
            ) {
                // Centre saturé
                tab_concours[liste_centres[c].repartition_concours[x].concour_id].saturation = 1
            } else if (
                tab_concours[liste_centres[c].repartition_concours[x].concour_id].nb_candidats ===
                tab_concours[liste_centres[c].repartition_concours[x].concour_id].capacite
            ) {
                // Centre à l'équilibre
                tab_concours[liste_centres[c].repartition_concours[x].concour_id].saturation = 2
            } else {
                // Centre non saturée
                tab_concours[liste_centres[c].repartition_concours[x].concour_id].saturation = 0
            }
        }

        let has_saturation = 0
        for (const t in tab_concours) {
            if (tab_concours[t].saturation === 1) {
                has_saturation = 1
            }
        }
        if (has_saturation) {
            // Si une des filières est saturée, le flag du centre passe à saturé également
            liste_centres[c].flag_saturation = 1
        } else {
            liste_centres[c].flag_saturation = 0
        }

        liste_centres[c].saturation = tab_concours

        // console.log(liste_centres[c])
    }

    return liste_centres
}

export const state: any = {
    liste_centres: [],
    liste_placements: [],
    liste_candidats: [],
    meta: null,
    meta_candidats: null,
    links: null,
    loading_repartition: false,
    error_repartition: null,
    totalRows: 0,
    lastPage: 0,
    currentPage: 1
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            liste_centres: [],
            liste_placements: [],
            liste_candidats: [],
            meta: null,
            meta_candidats: null,
            links: null,
            loading_repartition: false,
            error_repartition: null,
            totalRows: 0,
            lastPage: 0,
            currentPage: 1
        })
    },
    SET_LISTE_CENTRES(state: any, centres: Array<any>) {
        state.liste_centres = []
        state.liste_centres = create_repartition_concours(centres)
    },
    SET_MORE_LISTE_CENTRES(state: any, centres: Array<any>) {
        state.liste_centres = state.liste_centres.concat(centres)
        state.liste_centres = create_repartition_concours(state.liste_centres)
    },
    SET_LISTE_PLACEMENTS(state: any, placements: Array<any>) {
        state.liste_placements = placements
    },
    SET_MORE_LISTE_PLACEMENTS(state: any, placements: Array<any>) {
        state.liste_placements = state.liste_placements.concat(placements)
    },
    SET_LISTE_CANDIDATS(state: any, candidats: Array<any>) {
        state.liste_candidats = candidats
    },
    SET_MORE_LISTE_CANDIDATS(state: any, candidats: Array<any>) {
        state.liste_candidats = state.liste_candidats.concat(candidats)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_META_CANDIDATS(state: any, meta: Array<any>) {
        state.meta_candidats = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading_repartition = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error_repartition = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    MAJ_CANDIDAT(state: any, candidat: any) {
        const index = state.liste_candidats.map((c: any) => c.id).indexOf(candidat.id)
        Vue.set(state.liste_candidats, index, candidat)
    },
    MAJ_CANDIDAT_PLACEMENT(state: any, candidat: any) {
        const index = state.liste_placements.map((c: any) => c.id).indexOf(candidat.id)
        Vue.set(state.liste_placements, index, candidat)
    },
    DELETE_CANDIDAT(state: any, candidat: any) {
        const index = state.liste_candidats.map((c: any) => c.id).indexOf(candidat.id)
        state.liste_candidats.splice(index, 1)
    },
    MAJ_CENTRE(state: any, centre: any) {
        const index = state.liste_centres.map((c: any) => c.id).indexOf(centre.id)
        Vue.set(state.liste_centres, index, centre)
        state.liste_centres = create_repartition_concours(state.liste_centres)
    }
}

export const actions = {
    getListeCentres({ commit }: { commit: any }, params?: any) {
        return new Promise((resolve, reject) => {
            RepartitionCandidats.getListeCentres(params)
                .then((response) => {
                    setListeCentres(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreListeCentres({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            RepartitionCandidats.getListeCentres(params)
                .then((response) => {
                    commit('SET_MORE_LISTE_CENTRES', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getListeCentre({ commit }: { commit: any }, params?: any) {
        return new Promise((resolve, reject) => {
            RepartitionCandidats.getListeCentre(params.centre_id)
                .then((response) => {
                    commit('MAJ_CENTRE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getListePlacements({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            RepartitionCandidats.getListeCandidats(params)
                .then((response) => {
                    setListePlacements(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreListePlacements({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            RepartitionCandidats.getListeCandidats(params)
                .then((response) => {
                    commit('SET_MORE_LISTE_PLACEMENTS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getListeCandidats({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            RepartitionCandidats.getListeCandidats(params)
                .then((response) => {
                    setListeCandidats(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreListeCandidats({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            RepartitionCandidats.getListeCandidats(params)
                .then((response) => {
                    commit('SET_MORE_LISTE_CANDIDATS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deplacerCandidat({ commit }: { commit: any }, params?: any) {
        return new Promise((resolve, reject) => {
            RepartitionCandidats.deplacerCandidat(params.candidat_id, params.centre_id)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deplacerCandidatMass({ commit }: { commit: any }, params?: any) {
        return new Promise((resolve, reject) => {
            RepartitionCandidats.deplacerCandidatMass(params.candidat_ids, params.centre_id)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getExaminateursPresExcel({ commit }: { commit: any }, payload?: any) {
        return new Promise((resolve, reject) => {
            RepartitionCandidats.exporterRepartitionCandidats(payload.params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deplacerCandidatSalle({ commit }: { commit: any }, params?: any) {
        return new Promise((resolve, reject) => {
            RepartitionCandidats.deplacerCandidatSalle(params.candidat_id, params.centre_id, params.salle_id)
                .then((response) => {
                    commit('MAJ_CANDIDAT_PLACEMENT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    verouillerPlacement({ commit }: { commit: any }, params?: any) {
        return new Promise((resolve, reject) => {
            RepartitionCandidats.verrouillerPlacement(params.centre_id, params.placement_lock)
                .then((response) => {
                    commit('MAJ_CENTRE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    postPlacementCandidats({ commit }: { commit: any }, params?: any) {
        return new Promise((resolve, reject) => {
            RepartitionCandidats.postPlacementCandidats(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getListePlacements_candidat_only({ commit }: { commit: any }, params?: any) {
        return new Promise((resolve, reject) => {
            RepartitionCandidats.getListeCandidats(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getExportDeplacements({ commit }: { commit: any }) {
        return new Promise((resolve, reject) => {
            RepartitionCandidats.exporterDeplacementCandidats()
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    liste_centres: (state: any) => {
        return state.liste_centres
    },
    liste_candidats: (state: any) => {
        return state.liste_candidats
    },
    liste_placements: (state: any) => {
        return state.liste_placements
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading_repartition
    },
    error: (state: any) => {
        return state.error_repartition
    },
    totalRows: (state: any) => {
        return state.villes.length
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    get_capacite_centre: () => (centre_select: any) => {
        let capacite = 0
        for (const r in centre_select.repartition_concours) {
            capacite += centre_select.repartition_concours[r].capacite
        }

        return capacite
    },
    get_candidats_affectes_centre: () => (centre_select: any) => {
        let candidats_affectes = 0
        for (const r in centre_select.repartition_concours) {
            candidats_affectes += centre_select.repartition_concours[r].nb_candidats
        }

        return candidats_affectes
    },
    get_centres_satures: (state: any) => {
        return state.liste_centres.filter((c: any) => c.flag_saturation === 1).length
    }
}
