import { getError } from '@/utils/helpers'
import PlanningService from '@/services/PlanningService'

export const namespaced = true

function setSeancesExaminateurs(commit: Function, response: any) {
    commit('SET_SEANCES_EXAMINATEUR', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_LOADING', false)
    commit('SET_TOTAL', response.data.meta ? response.data.meta.total : 1)
}

function setSeancesCandidats(commit: Function, response: any) {
    commit('SET_SEANCES_CANDIDATS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_LOADING', false)
    commit('SET_TOTAL', response.data.meta ? response.data.meta.total : 1)
}

function setPlanningsNotifications(commit: Function, response: any) {
    commit('SET_SEANCES_NOTIFICATIONS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_LOADING', false)
    commit('SET_TOTAL', response.data.meta ? response.data.meta.total : 1)
}

export const state: any = {
    seances_examinateurs: [],
    seances_candidats: [],
    seances_notifications: [],
    loading_planning: false,
    loadingPdf: false,
    loading_notify: false,
    pdfView: [],
    meta: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    total: 0,
    error_plannings: null
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            seances_examinateurs: [],
            seances_candidats: [],
            seances_notifications: [],
            loading_planning: false,
            loadingPdf: false,
            loading_notify: false,
            pdfView: [],
            meta: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            total: 0,
            error_plannings: null
        })
    },
    SET_SEANCES_EXAMINATEUR(state: any, seances: any) {
        state.seances_examinateurs = seances
    },
    SET_MORE_SEANCES_EXAMINATEUR(state: any, seances: any) {
        state.seances_examinateurs = state.seances_examinateurs.concat(seances)
    },
    SET_SEANCES_CANDIDATS(state: any, seances: any) {
        state.seances_candidats = seances
    },
    SET_MORE_SEANCES_CANDIDATS(state: any, seances: any) {
        state.seances_candidats = state.seances_candidats.concat(seances)
    },
    SET_SEANCES_NOTIFICATIONS(state: any, seances: any) {
        state.seances_notifications = seances
    },
    SET_MORE_SEANCES_NOTIFICATIONS(state: any, seances: any) {
        state.seances_notifications = state.seances_notifications.concat(seances)
    },
    SET_LOADING(state: any, state_loading: boolean) {
        state.loading_planning = state_loading
    },
    SET_LOADING_NOTIFY(state: any, state_loading: boolean) {
        state.loading_notify = state_loading
    },
    SET_PDF(state: any, pdf: any) {
        state.pdfView = pdf
    },
    SET_LOADING_PDF(state: any, loadingPdf: boolean) {
        state.loadingPdf = loadingPdf
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error_plannings = error
    },
    RESET_ERROR(state: any) {
        state.error_plannings = null
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL(state: any, totalPage: number) {
        state.total = totalPage
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    RESET_META(state: any) {
        state.meta = null
        state.totalRows = 0
        state.currentPage = 1
        state.lastPage = 0
        state.total = 0
    },
    RESET_SEANCES(state: any) {
        state.seances_examinateurs = []
        state.seances_candidats = []
        state.seances_notifications = []
    }
}

export const actions = {
    getSeances({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            PlanningService.recherche(payload.params)
                .then((response) => {
                    if (payload.mode === 'examinateur') {
                        setSeancesExaminateurs(commit, response)
                    } else if (payload.mode === 'candidat') {
                        setSeancesCandidats(commit, response)
                    }

                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreSeances({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            PlanningService.recherche(payload.params)
                .then((response) => {
                    if (payload.mode === 'examinateur') {
                        commit('SET_MORE_SEANCES_EXAMINATEUR', response.data.data)
                    } else if (payload.mode === 'candidat') {
                        commit('SET_MORE_SEANCES_CANDIDATS', response.data.data)
                    }

                    commit('SET_LOADING', false)
                    commit('SET_META', response.data.meta)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getSeancesNotifications({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            PlanningService.get_plannings_notifications(payload)
                .then((response) => {
                    setPlanningsNotifications(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreSeancesNotifications({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            PlanningService.recherche(payload.params)
                .then((response) => {
                    commit('SET_MORE_SEANCES_NOTIFICATIONS', response.data.data)
                    commit('SET_LOADING', false)
                    commit('SET_META', response.data.meta)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPDF({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING_PDF', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            PlanningService.getPDF(payload.params)
                .then((response) => {
                    commit('SET_LOADING_PDF', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_PDF', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getExcel({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING_PDF', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            PlanningService.getExcel(payload.params)
                .then((response) => {
                    commit('SET_LOADING_PDF', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_PDF', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getBilanJournalier({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING_PDF', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            PlanningService.getBilanJournalier(payload.params)
                .then((response) => {
                    commit('SET_LOADING_PDF', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_PDF', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    sendNotify({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING_NOTIFY', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            PlanningService.get_plannings_notifications(payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_NOTIFY', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getEmploiTempsPDF({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING_PDF', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            PlanningService.getEmploiTempsPDF(payload.params)
                .then((response) => {
                    commit('SET_LOADING_PDF', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_PDF', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    tableauImpressionsExaminateurTrie: (state: any) => {
        return state.tableauImpressionsExaminateur
    },
    loading_plannings: (state: any) => {
        return state.loading_planning
    },
    loadingPdf: (state: any) => {
        return state.loadingPdf
    },
    getPdf: (state: any) => {
        return state.pdfView
    },
    getError: (state: any) => {
        return state.error_plannings
    },
    meta: (state: any) => {
        return state.meta
    },
    totalRows: () => {
        return 0
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    total: (state: any) => {
        return state.total
    }
}
