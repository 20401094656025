import * as API from '@/services/API'
import store from '@/store'

export default {
    async getSuppleant(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/ensembles_affectations_examinateurs`, {
            params: payload
        })
    },
    async getListeIntervenants(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/intervenants`, { params: payload })
    },
    async getJoursDispoSuppleant(suppleant_id: number, examinateur_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/suppleants_afex/${suppleant_id}/${examinateur_id}`)
    },
    async updateJoursAvecSuppleants(suppleant_id: number, ensemble_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(
            `/sessions/${session_id}/suppleants_afex/${suppleant_id}/${ensemble_id}`,
            payload
        )
    }
}
