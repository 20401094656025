import { getError } from '@/utils/helpers'
import FormulaireService from '@/services/FormulaireService'
import { FormulaireInterface, StateFormulaires } from '@/types/Formulaire'

export const namespaced = true

function setFormulaires(commit: Function, response: any) {
    commit('SET_FORMULAIRES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateFormulaires = {
    formulaires: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    formulaireSelect: null,
    piloteFormulaireSelect: null,
    concoursFormulaireSelect: null
}

export const mutations = {
    RESET_STATE(state: StateFormulaires) {
        Object.assign(state, {
            formulaires: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            formulaireSelect: null,
            concoursFormulaireSelect: null
        })
    },
    SET_FORMULAIRES(state: any, formulaires: Array<FormulaireInterface>) {
        state.formulaires = formulaires
    },
    ADD_FORMULAIRE(state: any, formulaire: FormulaireInterface) {
        state.formulaires.push(formulaire)
        state.formulaireSelect = formulaire
    },
    UPDATE_FORMULAIRE(state: any, formulaire: FormulaireInterface) {
        if (state.formulaireSelect && state.formulaireSelect.id === formulaire.id) {
            state.formulaireSelect = formulaire
        }

        const index = state.formulaires.findIndex((form: any) => form.id === formulaire.id)
        if (index !== -1) {
            state.formulaires[index] = formulaire
        }
    },
    REMOVE_FORMULAIRE(state: any, formulaire_id: number) {
        const index = state.formulaires.findIndex((form: any) => form.id === formulaire_id)
        if (index !== -1) {
            state.formulaires.splice(index, 1)
        }
        state.formulaireSelect = null
    },
    SET_MORE_FORMULAIRES(state: any, formulaires: Array<FormulaireInterface>) {
        state.formulaires = state.formulaires.concat(formulaires)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_FORMULAIRE_SELECT(state: any, formulaire: FormulaireInterface) {
        state.formulaireSelect = formulaire
    },
    SET_PILOTE_FORMULAIRE_SELECT(state: any, pilote: any) {
        state.piloteFormulaireSelect = pilote
    },
    SET_CONCOURS_FORMULAIRE_SELECT(state: any, concours: any) {
        state.concoursFormulaireSelect = concours
    }
}

export const actions = {
    getFormulaires({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FormulaireService.getFormulaires(params)
                .then((response) => {
                    setFormulaires(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getFormulaire({ commit }: { commit: any }, params: any) {
        commit('SET_FORMULAIRE_SELECT', null)
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FormulaireService.getFormulaire(params.formulaire_id)
                .then((response) => {
                    commit('SET_FORMULAIRE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreFormulaires({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FormulaireService.getFormulaire(params.formulaire_id)
                .then((response) => {
                    commit('SET_FORMULAIRE_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateFormulaire({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FormulaireService.updateFormulaire(params)
                .then((response) => {
                    commit('UPDATE_FORMULAIRE', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addFormulaire({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FormulaireService.addFormulaire(params)
                .then((response) => {
                    commit('ADD_FORMULAIRE', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteFormulaire({ commit }: { commit: any }, formulaire_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FormulaireService.deleteFormulaire(formulaire_id)
                .then((response) => {
                    commit('REMOVE_FORMULAIRE', formulaire_id)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPiloteFormulaire({ commit }: { commit: any }, params: any) {
        commit('SET_PILOTE_FORMULAIRE_SELECT', null)
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FormulaireService.getPiloteFormulaire(params.formulaire_id)
                .then((response) => {
                    commit('SET_PILOTE_FORMULAIRE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getConcoursFormulaire({ commit }: { commit: any }, params: any) {
        commit('SET_CONCOURS_FORMULAIRE_SELECT', null)
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FormulaireService.getConcoursFormulaire(params.formulaire_id)
                .then((response) => {
                    commit('SET_CONCOURS_FORMULAIRE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    storeDocument({ commit }: { commit: any }, params: any) {
        // commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FormulaireService.storeDocument(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteDocument({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            FormulaireService.deleteDocument(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDocument({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            FormulaireService.getDocument(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    storeDocumentReference({ commit }: { commit: any }, params: any) {
        // commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FormulaireService.storeDocumentReference(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteDocumentReference({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            FormulaireService.deleteDocumentReference(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDocumentReference({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            FormulaireService.getDocumentReference(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateConcoursFormulaire({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            FormulaireService.updateConcoursFormulaire(params).then((response) => {
                commit('UPDATE_FORMULAIRE', response.data.data)
                commit('SET_LOADING', false)
                resolve(response)
            }).catch((error) => {
                commit('SET_LOADING', false)
                commit('SET_ERROR', getError(error))
                reject(error)
            })
        })
    },
    cloneFormulaire({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            FormulaireService.cloneFormulaire(params).then((response) => {
                commit('ADD_FORMULAIRE', response.data.data)
                commit('SET_LOADING', false)
                resolve(response)
            }).catch((error) => {
                commit('SET_LOADING', false)
                commit('SET_ERROR', getError(error))
                reject(error)
            })
        })
    }
}

export const getters = {
    formulaires: (state: any): FormulaireInterface[] => {
        return state.formulaires
    },
    meta: (state: any): any => {
        return state.meta
    },
    links: (state: any): string | null => {
        return state.links
    },
    loading: (state: any): boolean => {
        return state.loading
    },
    error: (state: any): any => {
        return state.error
    },
    totalRows: (state: any): number => {
        return state.formulaires.length
    },
    formulaireSelect: (state: any): FormulaireInterface => {
        return state.formulaireSelect
    },
    piloteFormulaireSelect: (state: any) => {
        return state.piloteFormulaireSelect
    },
    concoursFormulaireSelect: (state: any) => {
        return state.piloteFormulaireSelect
    },
    getFormulaireById: (state: any) => (id: number) => {
        const formulaire = state.formulaires.filter((mat: any) => mat.id === id)[0]
        return formulaire
    }
}
