export enum EtatHistoPlanification {
    ETAT_EN_COURS = 0,
    ETAT_VALIDE = 1,
    ETAT_ANNULE = 2,
}

export enum TypePlanification {
    AUCUN = -1,
    TYPE_PLANIF_AUTO = 0,
    TYPE_PLANIF_MANUEL = 1,
}

export enum EtatActionPlanification {
    ETAT_ANNULER = 0,
    ETAT_APPLIQUER = 1,
}

export enum EtatSuppressionPlanification {
    PLANIF_AUTO = 1,
    PLANIF_MANUEL = 2,
    ALL_PLANIF = 3,
}

export enum EtatPlanificationAuto {
    NON_LANCEE = 0,
    EN_COURS = 1,
    TERMINEE_STATS = 2,
    TERMINEE_ARRETER = 3,
    CONSULTATION = 4,
    ARRET_EN_COURS = 5,
}

export enum EtatPlanificationSerie {
    NON_PLANIFER = 0,
    EN_COURS = 1,
    PRET_VALIDER = 2,
}

export enum TypeRegle {
    NB_EPREUVES = 'nb',
    AJOUT_TEMPS = '+',
    AJOUT_TEMPS_TRANSPORT = 't',
    BLOCAGE = 'b',
}

export function getLibelleTypePlanification(type_planif: TypePlanification) {
    switch (type_planif) {
        case TypePlanification.TYPE_PLANIF_AUTO:
            return 'Automatique'
        case TypePlanification.TYPE_PLANIF_MANUEL:
            return 'Manuelle'
    }
}

export function getItemEtatSerie(etat: EtatPlanificationSerie) {
    switch (etat) {
        case EtatPlanificationSerie.NON_PLANIFER:
            return { title: 'Non planifiée', icon: 'circle', color: 'danger' }
        case EtatPlanificationSerie.EN_COURS:
            return { title: 'En cours de planification', icon: 'circle', color: 'info' }
        case EtatPlanificationSerie.PRET_VALIDER:
            return { title: 'Planification prête pour validation', icon: 'circle', color: 'success' }
    }
}

export function getTypeRegle(type: TypeRegle) {
    switch (type) {
        case TypeRegle.NB_EPREUVES:
            return { type: TypeRegle.NB_EPREUVES, name: "Règle de nombre d'épreuves" }
        case TypeRegle.AJOUT_TEMPS:
            return { type: TypeRegle.AJOUT_TEMPS, name: "Règle d'ajout de temps entre chaque passage d'épreuve" }
        case TypeRegle.AJOUT_TEMPS_TRANSPORT:
            return {
                type: TypeRegle.AJOUT_TEMPS_TRANSPORT,
                name: "Règle d'ajout de temps de transport pour changer de centre"
            }
        case TypeRegle.BLOCAGE:
            return { type: TypeRegle.BLOCAGE, name: 'Règle de blocage' }
    }
}
