import * as API from '@/services/API'
import store from '@/store'

export default {
    async getHistoriqueNotification(historiqueNotificationId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/historiquenotification/${historiqueNotificationId}`)
    },
    async getHistoriqueNotifications(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/historiquenotification`, { params: payload })
    },
    async updateHistoriqueNotification(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/historiquenotification/${payload.id}`, payload)
    },
    async addHistoriqueNotification(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/historiquenotification`, payload)
    },
    async deleteHistoriqueNotification(historiqueNotification_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/historiquenotification/${historiqueNotification_id}`)
    },
    export() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/historiquenotification/export`, {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    }
}
