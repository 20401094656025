import { getError } from '@/utils/helpers'
import PublicationService from '@/services/PublicationsService'
import { display_group_publishable } from '@/types/Publishable'
import _ from 'lodash'
import Vue from 'vue'
import { ParamsTrie, PublicationInterface } from '@/types/Publication'

export const namespaced = true

export const state: any = {
    publications: null,
    loading_publications: false,
    error_publications: null,
    params_publications: {
        direction: '',
        sort: '',
        page: 0
    }
}

export const mutations = {
    RESET_STATE(state: any): void {
        Object.assign(state, {
            publications: null,
            loading_publications: false,
            error_publications: null,
            params_publications: {
                direction: '',
                sort: '',
                page: 0
            }
        })
    },
    SET_PUBLICATIONS(state: any, publications: PublicationInterface[]): void {
        Object.freeze(publications)
        state.publications = publications
    },
    SET_LOADING(state: any, etat: boolean): void {
        state.loading_publications = etat
    },
    SET_ERROR(state: any, error: any): void {
        state.error_publications = error
    },
    SET_UPDATE_PUBLICATION(state: any, publication: any): void {
        const index = state.publications.map((p: any) => p.id).indexOf(publication.id)
        const publisTemp = JSON.parse(JSON.stringify(state.publications))
        Vue.set(publisTemp, index, publication)
        state.publications = publisTemp
    },
    SET_UPDATE_TRANSFERED(state: any, publication: any): void {
        const index = state.publications.map((p: any) => p.id).indexOf(publication.id)
        state.publications[index].datas_transfered = publication.datas_transfered
    },
    SET_PARAMS(state: any, params: ParamsTrie): void {
        Object.freeze(params)
        state.params_publications = params
    }
}

export const actions = {
    getPublications({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            PublicationService.getPublications()
                .then((response): void => {
                    commit('SET_PUBLICATIONS', response.data.data)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    updatePublication({ commit }: { commit: any }, params: { publication_id: number; payload: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            PublicationService.updatePublication(params.publication_id, params.payload)
                .then((response): void => {
                    commit('SET_UPDATE_PUBLICATION', response.data.data)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    tryValidateDatas({ commit }: { commit: any }, publication_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            PublicationService.tryValidateDatas(publication_id)
                .then((response): void => {
                    commit('SET_UPDATE_TRANSFERED', response.data.data)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    retryValidateDatas({ commit }: { commit: any }, publication_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            PublicationService.retryValidateDatas(publication_id)
                .then((response): void => {
                    commit('SET_UPDATE_TRANSFERED', response.data.data)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {
    get_publications: (state: any): PublicationInterface[] => {
        return state.publications || []
    },
    get_publications_inscriptions: (state: any): PublicationInterface[] => {
        return _.orderBy(
            state.publications
                ?.filter((p: any): boolean => p.publishable.display_group === display_group_publishable.INSCRIPTIONS) || [],
            'publishable.name',
            'asc'
        )
    },
    get_publications_resultats: (state: any): PublicationInterface[] => {
        return _.orderBy(
            state.publications
                ?.filter((p: any): boolean => p.publishable.display_group === display_group_publishable.RESULTATS) || [],
            'publishable.name',
            'asc'
        )
    },
    get_publications_reclamations: (state: any): PublicationInterface[] => {
        return _.orderBy(
            state.publications
                ?.filter((p: any): boolean => p.publishable.display_group === display_group_publishable.RECLAMATIONS) || [],
            'publishable.name',
            'asc'
        )
    },
    get_publications_organisations_ecrit: (state: any): PublicationInterface[] => {
        return _.orderBy(
            state.publications?.filter(
                (p: any): boolean =>
                    p.publishable.display_group === display_group_publishable.ORGANISATION &&
                        p.publishable.display_interface.indexOf('admissibilite') !== -1
            ) || [],
            'publishable.name',
            'asc'
        )
    },
    get_publications_organisations_oraux: (state: any): PublicationInterface[] => {
        return _.orderBy(
            state.publications?.filter(
                (p: any): boolean =>
                    p.publishable.display_group === display_group_publishable.ORGANISATION &&
                    p.publishable.display_interface.indexOf('admission') !== -1
            ) || [],
            'publishable.name',
            'asc'
        )
    },
    get_publications_copies: (state: any): PublicationInterface[] => {
        return _.orderBy(
            state.publications
                ?.filter((p: any): boolean => p.publishable.display_group === display_group_publishable.COPIE) || [],
            'publishable.name',
            'asc'
        )
    },
    get_publications_bourse_emploi: (state: any): PublicationInterface[] => {
        return _.orderBy(
            state.publications
                ?.filter((p: any): boolean => p.publishable.display_group === display_group_publishable.BOURSE_EMPLOI) || [],
            'publishable.name',
            'asc'
        )
    },
    get_params(state: any): ParamsTrie {
        return state.params_publications
    },
    get_loading(state: any): boolean {
        return state.loading_publications
    }
}
