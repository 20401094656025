import CandidatService from '@/services/CandidatService'
import { getError } from '@/utils/helpers'

export const namespaced = true

/* Definir interface */
export const state: any = {
    listeCandidats: [],
    isLoading: false,
    loading_cand_cdc: false,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    meta: null,
    error: null
}

export const mutations: any = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            listeCandidats: [],
            isLoading: false,
            loading_cand_cdc: false,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            meta: null,
            error: null
        })
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading_cand_cdc = loading
    },
    SET_CANDIDATS(state: any, candidats: any) {
        state.listeCandidats = candidats
    },
    SET_MORE_CANDIDATS(state: any, candidats: any) {
        state.listeCandidats = state.listeCandidats.concat(candidats)
    },
    SET_ERROR(state: any, error: any) {
        state.error = error
    }
}

export const actions: any = {
    getCandidatsCdc({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.getCandidatsCdc(params)
                .then((response) => {
                    commit('SET_CANDIDATS', response.data.data)
                    commit('SET_META', response.data.meta)
                    commit('SET_TOTALROWS', response.data.data.length)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreCandidatsCdc({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.getCandidatsCdc(params)
                .then((response) => {
                    commit('SET_MORE_CANDIDATS', response.data.data)
                    commit('SET_META', response.data.meta)
                    commit('SET_TOTALROWS', response.data.data.length)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getCandidatsCdcExport({ commit }: { commit: any }, params?: any) {
        return new Promise((resolve, reject) => {
            CandidatService.getCandidatsCdcExport(params.centre_id)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters: any = {
    listeCandidats(state: any) {
        return state.listeCandidats
    },
    isLoading(state: any) {
        return state.loading_cand_cdc
    },
    totalRows: (state: any) => {
        return state.totalRows
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    meta: (state: any) => {
        return state.meta
    }
}
