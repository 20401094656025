import { getError } from '@/utils/helpers'
import PosteService from '@/services/PosteService'
import { PosteContext, PosteInterface, PosteListeInterface, StatePosteInterface } from '@/types/Poste'
import { Etat } from '@/types/DossierAcademique'
import Vue from 'vue'

export const namespaced = true

function setPostes(commit: any, response: any) {
    commit('SET_POSTES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StatePosteInterface = {
    postes: [],
    listePostes: [],
    postes_souhaits: [],
    postes_affectations: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    postes_selected: []
}

export const mutations = {
    RESET_STATE(state: StatePosteInterface) {
        Object.assign(state, {
            postes: [],
            listePostes: [],
            postes_souhaits: [],
            postes_affectations: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            postes_selected: []
        })
    },
    SET_POSTES(state: any, postes: Array<PosteInterface>) {
        state.postes = postes
        state.postes_souhaits = postes.filter(
            (p: PosteInterface) => (p.context & PosteContext.CONTEXT_SOUHAIT) === PosteContext.CONTEXT_SOUHAIT
        )
        state.postes_affectations = postes.filter(
            (p: PosteInterface) => (p.context & PosteContext.CONTEXT_SELECTION) === PosteContext.CONTEXT_SELECTION
        )
    },
    SET_MORE_POSTES(state: any, postes: Array<PosteInterface>) {
        state.postes = state.poste.concat(postes)
        state.postes_souhaits = state.postes_souhaits.concat(
            postes.filter(
                (p: PosteInterface) => (p.context & PosteContext.CONTEXT_SOUHAIT) === PosteContext.CONTEXT_SOUHAIT
            )
        )
        state.postes_affectations = state.postes_affectations.concat(
            postes.filter(
                (p: PosteInterface) => (p.context & PosteContext.CONTEXT_SELECTION) === PosteContext.CONTEXT_SELECTION
            )
        )
    },
    SET_LISTE_POSTES(state: any, postes: Array<PosteInterface>) {
        state.listePostes = []
        postes.forEach((item) => {
            let selected = false
            let etat = Etat.ETAT_VIERGE
            for (const i in state.postes_selected) {
                if (state.postes_selected[i].id === item.id) {
                    selected = true
                    etat = Etat.ETAT_DEPOSE
                }
            }
            const itemSurcharge: PosteListeInterface = {
                id: item.id,
                name: item.name,
                type: item.type,
                selected: selected,
                etat: etat
            }
            state.listePostes.push(itemSurcharge)
        })
    },
    SET_MAJ_LISTE_POSTES(state: any, listePostes: any) {
        state.listePostes = listePostes
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_POSTES_SELECTED(state: any, postes: Array<any>) {
        state.postes_selected = postes
    }
}

export const actions = {
    getPostes({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PosteService.getPostes(params)
                .then((response) => {
                    setPostes(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getAllPostes({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PosteService.getAllPostes(payload)
                .then((response) => {
                    setPostes(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreAllPostes({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PosteService.getAllPostes(payload)
                .then((response) => {
                    commit('SET_MORE_POSTES', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getSessionPostes({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PosteService.getSessionPostes(payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    updatePoste({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PosteService.updatePoste(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addPoste({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PosteService.addPoste(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deletePoste({ commit }: { commit: any }, poste_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PosteService.deletePoste(poste_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    postes: (state: any) => {
        return state.postes
    },
    listePostes: (state: any) => {
        return state.listePostes
    },
    postes_souhaits: (state: any) => {
        state.postes_souhaits = state.postes.filter(
            (p: PosteInterface) => (p.context & PosteContext.CONTEXT_SOUHAIT) === PosteContext.CONTEXT_SOUHAIT
        )
        return state.postes_souhaits
    },
    postes_affectations: (state: any) => {
        state.postes_affectations = state.postes.filter(
            (p: PosteInterface) => (p.context & PosteContext.CONTEXT_SELECTION) === PosteContext.CONTEXT_SELECTION
        )
        return state.postes_affectations
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.postes.length
    },
    posteByRegroupement: (state: any) => {
        const dico_postes: { [index: string]: any } = {}

        state.postes.forEach((poste: any) => {
            if (!dico_postes[poste.regroupement]) {
                Vue.set(dico_postes, poste.regroupement, [])
            }

            dico_postes[poste.regroupement].push(poste)
        })

        return dico_postes
    }
}
