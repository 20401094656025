import * as API from '@/services/API'

export default {
    async getRgpdEntity(rgpdEntityId: number) {
        return await API.apiClient.get(`/rgpdentities/${rgpdEntityId}`)
    },
    async getRgpdEntities() {
        return await API.apiClient.get('/rgpdentities')
    },
    async updateRgpdEntity(payload: any) {
        return await API.apiClient.put(`/rgpdentities/${payload.id}`, payload)
    },
    async addRgpdEntity(payload: any) {
        return await API.apiClient.post('/rgpdentities', payload)
    },
    async deleteRgpdEntity(rgpdEntity_id: number) {
        return await API.apiClient.delete(`/rgpdentities/${rgpdEntity_id}`)
    },
    async checkRgpdEntity(rgpdEntity_id: number) {
        return await API.apiClient.get(`/rgpdentities/${rgpdEntity_id}/check`)
    }
}
