import * as API from '@/services/API'
import store from '@/store'

export default {
    getCreneauxDispos(seance_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/seances/${seance_id}/creneaux`)
    },
    getCreneauxDisposSeries(seance_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/seances/${seance_id}/series`)
    },
    updateSeanceCreneaux(seance_id: number, creneau_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/seances/${seance_id}/creneaux/${creneau_id}`)
    },
    deleteSeanceCreneaux(seance_id: number, creneau_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/seances/${seance_id}/creneaux/${creneau_id}`)
    }
}
