import * as API from '@/services/API'
import store from '@/store/index'

export default {
    getContratTypes(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/contrattypes`, { params: payload })
    },
    getContratType(contrat_type_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/contrattypes/${contrat_type_id}`)
    },
    saveContratType(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/contrattypes`, payload)
    },
    updateContratType(contrattype_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/contrattypes/${contrattype_id}`, payload)
    },
    deleteContratType(contrattype_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/contrattypes/${contrattype_id}`)
    },
    getPDF(contrattype_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/contrattypes/${contrattype_id}/pdf?format=b64`, {headers: { 'X-Request-Retry': 0 }})
    }
}
