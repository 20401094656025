import * as API from '@/services/API'
import store from '@/store'

export default {
    async getMatiere(matiereId: number) {
        return await API.apiClient.get(`/matieres/${matiereId}`)
    },
    async getMatieres(payload: any) {
        return await API.apiClient.get('/matieres', { params: payload })
    },
    async getSessionMatieres(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/matieres`, { params: payload })
    },
    async updateMatiere(payload: any) {
        return await API.apiClient.put(`/matieres/${payload.id}`, payload)
    },
    async addMatiere(payload: any) {
        return await API.apiClient.post('/matieres', payload)
    },
    async deleteMatiere(matiere_id: number) {
        return await API.apiClient.delete(`/matieres/${matiere_id}`)
    }
}
