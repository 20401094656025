import * as API from '@/services/API'
import store from '@/store'

export default {
    updateSalle(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/centres/${payload.centre_id}/salles/${payload.id}`, payload)
    },
    addSalle(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/centres/${payload.centre_id}/salles`, payload)
    },
    deleteSalle(centre_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/centres/${centre_id}/salles`)
    },
    updateSalles(centre_id: number, salles: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/centres/${centre_id}/salles_update`, { salles: salles })
    }
}
