import * as API from '@/services/API'
import store from '@/store'

export default {
    async getFournitureAdministrative(fournitureId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/fourniture_administratives/${fournitureId}`)
    },
    async getFournitureAdministratives(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/fourniture_administratives`, { params: payload })
    },
    async updateFournitureAdministrative(fa_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/fourniture_administratives/${fa_id}`, payload)
    },
    async addFournitureAdministrative(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/fourniture_administratives`, payload)
    },
    async deleteFournitureAdministrative(fourniture_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/fourniture_administratives/${fourniture_id}`)
    },
    getPDFFournitureAdministrative(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/fourniture_administratives/${params.fa_id}/documents/${params.doc_id}?format=b64`
        )
    },
    async setFournitureAdministrativesImpressionBac(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/fourniture_administratives_impression_bac`, payload)
    },
    async setConsultationFournituresAdministratives(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/consultation_fournitures_administratives`, payload)
    },
    getImpressionFournitureAdministrativeCentres(centre_id: number, params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/centres/${centre_id}/impression_fourniture_administratives`,
            {
                params: params,
                headers: {
                    'X-Request-Retry': 0
                }
            }
        )
    },
    getImpressionFournitureAdministrativeFournitures(fourniture_id: number, params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/fourniture_administratives/${fourniture_id}/impression`,
            {
                params: params,
                headers: {
                    'X-Request-Retry': 0
                }
            }
        )
    }
}
