import * as API from '@/services/API'
import store from '@/store/index'

export default {
    getCentreFournituresAdministratives(centre_id: string, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/centres/${centre_id}/centre_fourniture_administratives`, {
            params: payload
        })
    },
    saveFournituresAdministratives(centre_id: string, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(
            `/sessions/${session_id}/centres/${centre_id}/centre_fourniture_administratives_mass_update`,
            payload
        )
    },
    updateDateImpressionCentres(centre_id: string, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(
            `/sessions/${session_id}/centres/${centre_id}/centre_fourniture_administratives_imprime`,
            payload
        )
    },
    updateDateImpressionFournitures(fourniture_id: string, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(
            `/sessions/${session_id}/fourniture_administratives/${fourniture_id}/update_date_impression`,
            payload
        )
    },
    getPDF(centre_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/centres/${centre_id}/export_fourniture_administrative`, {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    }
}
