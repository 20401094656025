import { getError } from '@/utils/helpers'
import ReclamationService from '@/services/ReclamationService'
import Vue from 'vue'
import { ReclamationOralInterface, StateReclamationOralInterface } from '@/types/ReclamationOral'

export const namespaced = true

function setReclamationsOral(commit: Function, response: any) {
    commit('SET_RECLAMATIONS_ORAL', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
}

export const state: StateReclamationOralInterface = {
    reclamations_oral: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    reclamationOralSelect: {},
    reclamations_oral_compteurs: []
}

export const mutations = {
    RESET_STATE(state: StateReclamationOralInterface) {
        Object.assign(state, {
            reclamations_oral: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            reclamationSelect: {},
            reclamations_oral_compteurs: []
        })
    },
    SET_RECLAMATIONS_ORAL(state: any, reclamations: Array<ReclamationOralInterface>) {
        state.reclamations_oral = reclamations
    },
    SET_MORE_RECLAMATIONS_ORAL(state: any, reclamations: Array<ReclamationOralInterface>) {
        state.reclamations_oral = state.reclamations_oral.concat(reclamations)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_RECLAMATION_SELECT(state: any, reclamation: ReclamationOralInterface) {
        state.reclamationOralSelect = reclamation
    },
    MAJ_DATAS_RECLAMATION_SELECT_ORAL(state: any, params: any) {
        const tableau_copy = state.reclamations_oral
        const index = state.reclamations_oral.map((item: any) => item.id).indexOf(params.id)
        if (index > -1) {
            const ligne_modif = tableau_copy[index]
            ligne_modif.message_gestionnaire = params.message_gestionnaire
            ligne_modif.submitted_at = params.submitted_at
            ligne_modif.note = params.note
            Vue.set(state.reclamations_oral, index, ligne_modif)

            state.reclamationOralSelect = ligne_modif
        }
    },
    SET_COMPTEURS_RECLAMATIONS_ORAL(state: any, reclamations_oral_compteurs: Array<any>) {
        state.reclamations_oral_compteurs = reclamations_oral_compteurs
    }
}

export const actions = {
    getReclamations({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.getReclamations(params.filters)
                .then((response) => {
                    setReclamationsOral(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreReclamations({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.getReclamations(params.filters)
                .then((response) => {
                    commit('SET_MORE_RECLAMATIONS_ORAL', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getReclamation({ commit }: { commit: any }, reclamation_id: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.getReclamation(reclamation_id)
                .then((response) => {
                    commit('SET_RECLAMATION_SELECT', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateReclamation({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            ReclamationService.updateReclamation(params.reclamation_id, params.payload)
                .then((response) => {
                    commit('MAJ_DATAS_RECLAMATION_SELECT_ORAL', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    },
    addReclamation({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.addReclamation(params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    },
    getReclamationsCompteurs({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.getReclamationsCompteurs(params.filters)
                .then((response) => {
                    commit('SET_COMPTEURS_RECLAMATIONS_ORAL', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addMessage({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            ReclamationService.addMessage(params.reclamation_id, params.payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    },
    dl_pj_message({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            ReclamationService.download_pj(params.reclamation_id, params.message_id, params.format)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    reclamations_oral: (state: any) => {
        return state.reclamations_oral
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.reclamations_oral.length
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    reclamationSelect: (state: any) => {
        return state.reclamationOralSelect
    },
    getCptReclamations: (state: any) => {
        const cptDeclarees = state.reclamations_oral.filter((r: any) => !r.submitted_at && !r.validated_at).length
        const cptTraitees = state.reclamations_oral.filter((r: any) => r.submitted_at && !r.validated_at).length
        const cptSignees = state.reclamations_oral.filter((r: any) => r.submitted_at && r.validated_at).length

        return {
            cptDeclarees: cptDeclarees,
            cptTraitees: cptTraitees,
            cptSignees: cptSignees
        }
    },
    getReclamations_oral_compteurs: (state: any) => {
        return state.reclamations_oral_compteurs
    }
}
