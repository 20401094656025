/* eslint-disable @typescript-eslint/no-unused-vars */
import 'izitoast/dist/css/iziToast.min.css'
import '@/styles/custom-bootstrap.scss'
import Vue from 'vue'

const toast: any = {
    error: (
        message: string,
        title = 'Error',
        onClosed = function () {
            console.log('')
        }
    ) => {
        const idError = 't_error_' + Math.random()
        const errorToaster = {
            id: idError,
            toaster: 'b-toaster-top-right',
            variant: 'danger',
            noCloseButton: true,
            fade: true,
            autoHideDelay: 5000
        }
        const vm = new Vue()
        vm.$bvToast.toast(message, errorToaster)
    },
    info: (
        message: string,
        title = 'Infos',
        onClosed = function () {
            console.log('')
        }
    ) => {
        const idError = 't_infos_' + Math.random()
        const errorToaster = {
            id: idError,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            autoHideDelay: 5000
        }
        const vm = new Vue()
        vm.$bvToast.toast(message, errorToaster)
    },
    success: (
        message: string,
        title = 'Succès',
        onClosed = function () {
            console.log('')
        }
    ) => {
        const idError = 't_infos_' + Math.random()
        const errorToaster = {
            id: idError,
            toaster: 'b-toaster-top-right',
            variant: 'success',
            noCloseButton: true,
            fade: true,
            autoHideDelay: 5000
        }
        const vm = new Vue()
        vm.$bvToast.toast(message, errorToaster)
    }

    /*
    return iZtoast.error({
      title: title,
      message: message,
      position: 'topCenter',
      onClosed: onClosed
    })
  } */
}

export default toast
