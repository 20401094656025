import { getError } from '@/utils/helpers'
import ConcourService from '@/services/ConcourService'
import { ConcourInterface, StateConcours } from '@/types/Concour'

export const namespaced = true
export const namespace = true

function setConcours(commit: any, response: any) {
    commit('SET_CONCOURS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateConcours = {
    concours: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    concourTemp: null
}

export const mutations = {
    RESET_STATE(state: StateConcours) {
        Object.assign(state, {
            concours: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            concourTemp: null
        })
    },
    SET_CONCOUR_TEMP(state: any, concour: ConcourInterface) {
        state.concourTemp = concour
    },
    SET_CONCOURS(state: any, concours: Array<ConcourInterface>) {
        state.concours = concours
    },
    SET_MORE_CONCOURS(state: any, concours: Array<ConcourInterface>) {
        state.concours = state.concours.concat(concours)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    }
}

export const actions = {
    getConcour({ commit }: { commit: any }, id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourService.getConcour(id)
                .then((response) => {
                    commit('SET_CONCOUR_TEMP', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateConcour({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourService.updateConcour(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addConcour({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourService.addConcour(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getConcours({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        commit('SET_CONCOURS', [])
        return new Promise((resolve, reject) => {
            ConcourService.getConcours(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    setConcours(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreConcours({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourService.getConcours(params)
                .then((response) => {
                    commit('SET_MORE_CONCOURS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getConcoursActifs({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourService.getConcoursActifs(params)
                .then((response) => {
                    setConcours(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteConcour({ commit }: { commit: any }, concourId: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourService.deleteConcour(concourId)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    import({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConcourService.import(payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    concours: (state: StateConcours) => {
        return state.concours.filter((concour: any) => concour.banque_id !== null)
    },
    banques: (state: StateConcours) => {
        return state.concours.filter((concour: any) => concour.banque_id === null)
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.concours.length
    },
    concourById: (state: any) => (id: number) => {
        return state.concours.filter((concour: any) => concour.id === id)[0]
    },
    concourTemp: (state: any) => {
        return state.concourTemp
    },
    nbEpreuvesFromPhaseById: (state: any) => (id: number) => {
        let retour = ''
        let tooltip = ''
        const conc = state.concours.filter((concour: any) => concour.id === id)[0]
        if (conc && conc.epreuves_deliberation && conc.epreuves_deliberation.length > 0) {
            const tablePhaseEpreuve: any = {}
            for (let i = 0; i < conc.epreuves_deliberation.length; i++) {
                if (!tablePhaseEpreuve[conc.epreuves_deliberation[i].phase_id]) {
                    tablePhaseEpreuve[conc.epreuves_deliberation[i].phase_id] = 1
                } else {
                    tablePhaseEpreuve[conc.epreuves_deliberation[i].phase_id]++
                }
            }

            for (const pid in tablePhaseEpreuve) {
                if (retour !== '') {
                    retour += '/'
                    tooltip += ' / '
                }

                const phase = conc.phases.filter((phase: any) => phase.phase_id === parseInt(pid))[0]
                if (phase) {
                    tooltip += tablePhaseEpreuve[pid] + ' ' + phase.name + (tablePhaseEpreuve[pid] > 1 ? 's' : '')
                }
                retour += tablePhaseEpreuve[pid]
            }
        }
        return {
            val: retour,
            title: tooltip
        }
    }
}
