import { getError } from '@/utils/helpers'
import BaremeRemunerationService from '@/services/BaremeRemunerationService'
import { BaremeRemunerationInterface, StateBaremeRemuneration } from '@/types/BaremeRemuneration'

export const namespaced = true
export const namespace = true

function setBaremesRemuneration(commit: any, response: any) {
    commit('SET_BAREMES_REMUNERATION', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateBaremeRemuneration = {
    baremesRemuneration: [],
    baremeRemuneration_select: null,
    meta: null,
    links: null,
    loading: false,
    error: null,
    lastPage: 0,
    totalRows: 0
}

export const mutations = {
    RESET_STATE(state: StateBaremeRemuneration) {
        Object.assign(state, {
            baremesRemuneration: [],
            baremeRemuneration_select: null,
            meta: null,
            links: null,
            loading: false,
            error: null,
            lastPage: 0,
            totalRows: 0,
            source_pdf: ''
        })
    },
    SET_BAREMES_REMUNERATION(state: any, baremes: Array<BaremeRemunerationInterface>) {
        state.baremesRemuneration = baremes.sort((a, b) => a.nature.localeCompare(b.nature))
    },
    SET_BAREME_SELECT(state: any, bareme: BaremeRemunerationInterface) {
        state.baremeRemuneration_select = bareme
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_PDF(state: any, source: string) {
        state.source_pdf = source
    }
}

export const actions = {
    getBaremesRemuneration({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            BaremeRemunerationService.getBaremesRemuneration(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    setBaremesRemuneration(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getBaremeRemuneration({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            BaremeRemunerationService.getBaremeRemuneration(params.bareme_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_BAREME_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteBaremeRemuneration({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            BaremeRemunerationService.deleteBaremeRemuneration(params.bareme_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateBaremeRemuneration({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            BaremeRemunerationService.updateBaremeRemuneration(params.bareme_id, params.payload)
                .then((response) => {
                    commit('SET_BAREME_SELECT', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    saveBaremeRemuneration({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            BaremeRemunerationService.saveBaremeRemuneration(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_BAREME_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    baremesRemuneration: (state: StateBaremeRemuneration) => {
        return state.baremesRemuneration
    },
    baremeRemuneration_select: (state: StateBaremeRemuneration) => {
        return state.baremeRemuneration_select
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.baremesRemuneration.length
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    source_pdf: (state: any) => {
        return state.source_pdf
    }
}
