import { getError } from '@/utils/helpers'
import DeontologiesService from '@/services/DeontologiesService'
import { DeontologiesInterface, StateDeontologies } from '@/types/Deontologies'

export const namespaced = true

function setDeontologies(commit: Function, response: any) {
    commit('SET_DEONTOLOGIES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
}

export const state: StateDeontologies = {
    deontologies: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    currentPage: 0,
    lastPage: 0
    // deontologieSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateDeontologies) {
        Object.assign(state, {
            deontologies: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            currentPage: 0,
            lastPage: 0
            // deontologieSelect: {}
        })
    },
    SET_DEONTOLOGIES(state: any, deontologies: Array<DeontologiesInterface>) {
        state.deontologies = deontologies
    },
    SET_MORE_DEONTOLOGIES(state: any, deontologies: Array<DeontologiesInterface>) {
        state.deontologies = state.deontologies.concat(deontologies)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    }
    // SET_DEONTOLOGIE_SELECT (state: any, deontologie: DeontologiesInterface) {
    //     state.deontologieSelect = deontologie
    // }
}

export const actions = {
    fetchDeontologies({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DeontologiesService.getDeontologies(params)
                .then((response) => {
                    setDeontologies(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    fetchMoreDeontologies({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DeontologiesService.getDeontologies(params)
                .then((response) => {
                    commit('SET_MORE_DEONTOLOGIES', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    exportDeontologies({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DeontologiesService.exportDeontologies()
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    getDeontologies: (state: any) => {
        return state.deontologies
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.deontologies.length
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    }
    // getDeontologieSelect: (state: any) => {
    //     return state.deontologieSelect
    // }
}
