import { getError } from '@/utils/helpers'
import ReclassementService from '@/services/ReclassementService'
import { StateReclamationsPostConcoursInterface } from '@/types/Reclassement'
import _ from 'lodash'
import CandidatConcourPhaseListeService from '@/services/CandidatConcourPhaseListeService'

export const namespaced = true

function setReclassementListeCandidat(commit: Function, response: any) {
    commit('SET_RECLASSEMENTS_LISTE_CANDIDATS', response.data.data)
    commit('SET_RECLASSEMENTS_LISTE_CANDIDATS_COPY', response.data.data)
    commit('SET_NB_CANDIDATS_CLASSES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
}

export const state: StateReclamationsPostConcoursInterface = {
    liste_candidats: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    liste_candidats_copy: [],
    reclassementCandidatSelect: {},
    params_liste_candidats: {},
    newReclassementFinal: null,
    nbCandidatsClasses: null,
    savRangSelectCandidat: null
}

export const mutations = {
    RESET_STATE(state: StateReclamationsPostConcoursInterface) {
        Object.assign(state, {
            liste_candidats: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            reclassementCandidatSelect: {},
            params_liste_candidats: {}
        })
    },
    SET_RECLASSEMENTS_LISTE_CANDIDATS(state: any, candidats: Array<StateReclamationsPostConcoursInterface>) {
        state.liste_candidats = candidats
    },
    SET_RECLASSEMENTS_LISTE_CANDIDATS_COPY(state: any, candidats: Array<StateReclamationsPostConcoursInterface>) {
        state.liste_candidats_copy = candidats
    },
    SET_MORE_RECLASSEMENTS_LISTE_CANDIDATS(state: any, candidats: Array<StateReclamationsPostConcoursInterface>) {
        state.liste_candidats = state.liste_candidats.concat(candidats)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_RECLASSEMENT_CANDIDAT_SELECT(state: any, candidatSelect: StateReclamationsPostConcoursInterface) {
        state.reclassementCandidatSelect = candidatSelect
    },
    SET_LISTE_CANDIDATS_COPY(state: any, candidats: Array<StateReclamationsPostConcoursInterface>) {
        state.liste_candidats_copy = candidats
    },
    SET_PARAMS(state: StateReclamationsPostConcoursInterface, params: any) {
        state.params_liste_candidats = params
    },
    SET_NB_CANDIDATS_CLASSES(state: StateReclamationsPostConcoursInterface, liste_candidats: any) {
        state.nbCandidatsClasses = liste_candidats.filter(
            (candidat: { rang_externe: any }) => candidat.rang_externe !== null
        ).length // recup des candidats avec un classement
    }
}

export const actions = {
    setReclassementListeCandidat({ commit }: { commit: any }, data: any) {
        setReclassementListeCandidat(commit, data)
    },
    getListeClassement({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclassementService.getListeCandidats(params)
                .then((response) => {
                    setReclassementListeCandidat(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    validationReclassement({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            const reclamation_id = params.reclamation_id
            ReclassementService.updateReclamation(reclamation_id, params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    liste_candidats: (state: any) => {
        return state.liste_candidats
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.liste_candidats.length
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    reclassementCandidatSelect: (state: any) => {
        return state.reclassementCandidatSelect
    },
    /** le reclassement se fait en local via ce getter : id, nouveau classement, le choix de l'utilisateur sur le filtre  */
    reclassementOnNumberInputChange:
        (state: any) =>
            (candidatId: any, newClassement: any, lastSortable: any, ranking_group_id = null) => {

                state.liste_candidats_copy = _.orderBy(state.liste_candidats_copy, 'rang', 'asc') // on reclasse par rang avant de reclasser le candidat

                const indexOfNextClassement = state.liste_candidats_copy.findIndex(
                    (candidat: { rang: any }) => parseInt(candidat.rang) === parseInt(newClassement)
                )

                // recup index candidat actuel
                const indexOfCandidat = state.liste_candidats_copy.findIndex(
                    (candidat: { candidat: any }) => parseInt(candidat.candidat.id) === parseInt(candidatId)
                )

                const rangOrigine = state.reclassementCandidatSelect && state.reclassementCandidatSelect.rang ? parseInt(state.reclassementCandidatSelect.rang) : null

                // Ajout du rang et du rang_externe du candidat precedent et sauvegarde de l'info
                if (newClassement) {
                    state.newReclassementFinal = parseInt(newClassement)
                    state.liste_candidats_copy[indexOfCandidat].rang = parseInt(newClassement)
                    state.liste_candidats_copy[indexOfCandidat].rang_externe = (state.liste_candidats_copy[indexOfNextClassement] && state.liste_candidats_copy[indexOfNextClassement].rang_externe) ? state.liste_candidats_copy[indexOfNextClassement].rang_externe : null // recup du classement externe du candidat

                    state.liste_candidats_copy[indexOfCandidat].ranking_group_id = ranking_group_id

                    // index d'un candidat avec le classement
                    // supprime la ligne de l ancien index
                    const candidat = state.liste_candidats_copy.splice(indexOfCandidat, 1)
                    // ajout la ligne dans son nouvel espace
                    state.liste_candidats_copy.splice(indexOfNextClassement, 0, candidat[0])
                } else {
                    state.newReclassementFinal = null
                    state.liste_candidats_copy[indexOfCandidat].rang = null
                    state.liste_candidats_copy[indexOfCandidat].rang_externe = parseInt(newClassement)
                    state.liste_candidats_copy[indexOfCandidat].ranking_group_id = ranking_group_id
                    // supprime la ligne de l ancien index
                    const candidat = state.liste_candidats_copy.splice(indexOfCandidat, 1)
                    state.liste_candidats_copy.push(candidat[0])
                }


                // reconstruction du nouveau rang
                let count = 1
                state.liste_candidats_copy.forEach((candidat: { rang: any; isMoved: any }) => {
                    candidat.isMoved = false // utilisé pour changer la couleur du background, les deux if  vont checker les candidats qui ont eu un changement
                    if (
                        (rangOrigine &&
                        rangOrigine < indexOfNextClassement &&
                        count <= indexOfNextClassement &&
                        count >= rangOrigine) ||
                    indexOfNextClassement + 1 === count
                    ) {
                        candidat.isMoved = true
                    } else if (
                        (rangOrigine &&
                        rangOrigine > indexOfNextClassement &&
                        count > indexOfNextClassement &&
                        count <= rangOrigine) ||
                    count === rangOrigine
                    ) {
                        candidat.isMoved = true
                    }
                    candidat.rang = count
                    count++
                })

                // remise en forme du tableau suivant l'ordre demandé par l'utilisateur
                state.liste_candidats_copy = _.orderBy(
                    state.liste_candidats_copy,
                    lastSortable.sort,
                    lastSortable.direction
                )
                state.loading = false
                return state.liste_candidats_copy
            }
}
