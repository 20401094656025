import { getError } from '@/utils/helpers'
import { StateSessions } from '@/types/Session'
import SessionUserService from '@/services/SessionUserService'

export const namespaced = true

export const state: any = {
    sessionUser: null,
    statsValidation: null,
    loading: false,
    error_session_user: null
}

export const mutations = {
    RESET_STATE(state: StateSessions) {
        Object.assign(state, {
            sessionUser: null,
            loading: false,
            error_session_user: null
        })
    },
    SET_SESSION_SELECT(state: any, sessionUser: any) {
        state.sessionUser = sessionUser
    },
    SET_STATS_VALIDATION(state: any, statsValidation: any) {
        state.statsValidation = statsValidation
    },
    SET_LOADING(state: any, etat: boolean) {
        state.loading = etat
    },
    SET_ERROR(state: any, error: any) {
        state.error_session_user = error
    }
}

export const actions = {
    getSessionUser({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SessionUserService.getSessionUser(params)
                .then((response) => {
                    commit('SET_SESSION_SELECT', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateSessionUser({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SessionUserService.updateSessionUser(params.user_id, params.payload)
                .then((response) => {
                    commit('SET_SESSION_SELECT', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getSessionUserStatsvalidation({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SessionUserService.getSessionUserStatsvalidation()
                .then((response) => {
                    commit('SET_STATS_VALIDATION', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateSessionusersIncompatibilites({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SessionUserService.updateSessionusersIncompatibilites(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    },
    updateSessionusersDisponibilites({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SessionUserService.updateSessionusersDisponibilites(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    }
}

export const getters = {
    sessions: (state: any) => {
        return state.sessions
    }
}
