/*
 * This is the initial API interface
 * we set the base URL for the API
 */

import axios from 'axios'
import store from '@/store'
import router from '@/router'

const MAX_RETRIES = 30

export const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL + '/api',
    withCredentials: true // required to handle the CSRF token
})

/*
 * Add a response interceptor
 */
apiClient.interceptors.response.use(
    (response) => {
        return response
    },
    async function (error) {
        // Convertion de la réponse pour récupérer l'erreur du serveur
        const responseType = error.response && error.response.config && error.response.config.responseType ? error.response.config.responseType : null
        if (responseType && error.response.data) {
            if (responseType === 'arraybuffer') {
                error.response.data = JSON.parse(
                    String.fromCharCode(...new Uint8Array(error.response.data))
                )
            }
            if (responseType === 'blob') {
                error.response.data = JSON.parse(await error.response.data.text())
            }
        }

        // console.log('error API')
        if (error.response && [401].includes(error.response.status) && store.getters['auth/authUser']) {
            await store.dispatch('auth/logout')
        }
        if (error.response && [409].includes(error.response.status)) {
            // store.dispatch('auth/logout')
            // console.log('409')
            // console.log(error)
        }
        if (error.response && [419].includes(error.response.status) && store.getters['auth/authUser']) {
            // store.dispatch('auth/logout')
            console.log('419')
            console.log(error)
        }
        if (error.response && [422].includes(error.response.status)) {
            // store.dispatch('auth/logout')
            // console.log('422')
            // console.log(error)
        }
        if (error.response && [423].includes(error.response.status)) {
            // Le password de l'utilisateur a expiré, il est donc redirigé sur la page de changement du mot de passe
            await router.push('/change-password')
        }
        if (error.response && [500].includes(error.response.status)) {
            // store.dispatch('auth/logout')
            console.log('500')
            console.log(error)
        }
        // Réitère la requête si les paramètres correspondent
        if (error.config && error.config.headers && typeof error.config.headers['X-Request-Retry'] === 'number' && (!error.response || error.response.status === 504 || error.response.status === 502)) {
            if (error.config.headers['X-Request-Retry'] < MAX_RETRIES) {
                error.config.headers['X-Request-Retry']++
                return Promise.resolve(await apiClient.request(error.config))
            }
        }

        return Promise.reject(error)
    }
)
