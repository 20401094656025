import CentreService from '@/services/CentreService'
import { SalleInterface, TypeAmenagement } from '@/types/Salle'
import { getError } from '@/utils/helpers'

export const namespaced = true

export const state: any = {
    loading: false,
    error: null,
    dico_salles: [],
    dico_salles_concours: [],
    new_id: -1,
    besoins_centre: [],
    besoins_precedent: [],
    selectComposant: 'comp_salles_ecrit',
    errors_code: [],
    has_error_code: false,
    has_compteurs_incorrect: false
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            loading: false,
            error: null,
            dico_salles: [],
            dico_salles_concours: [],
            new_id: -1,
            besoins_centre: [],
            besoins_precedent: [],
            errors_code: [],
            has_error_code: false,
            has_compteurs_incorrect: false
        })
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_DICO_SALLES(state: any, salles: Array<SalleInterface>) {
        state.dico_salles = salles
    },
    SET_SALLES_CONCOURS(state: any, salle_concours: any) {
        state.dico_salles_concours = state.dico_salles_concours.concat(salle_concours)
    },
    RESET_DICO_SALLES_CONCOURS(state: any) {
        state.dico_salles_concours = []
    },
    ADD_LIGNE_DICO_SALLES(state: any, salle: SalleInterface) {
        state.dico_salles = state.dico_salles.concat(salle)
    },
    DELETE_LIGNE_DICO_SALLES(state: any, salle_id: number) {
        const index = state.dico_salles.findIndex((s: SalleInterface) => s.id === salle_id)
        state.dico_salles.splice(index, 1)
    },
    UPDATE_REPARTITION(state: any, salle_concours: any) {
        const index = state.dico_salles_concours.findIndex(
            (sc: any) => sc.concour_id === salle_concours.concour_id && sc.salle_id === salle_concours.salle_id
        )
        if (index !== -1) {
            state.dico_salles_concours[index].nb_candidats_prevision = salle_concours.nb_candidats_prevision
        }
    },
    PARSE_COLLECTION_SALLES(state: any) {
        for (const s in state.dico_salles) {
            state.dico_salles[s].concours = []
            const dico_salles_lignes = state.dico_salles_concours.filter(
                (salleConcour: any) => salleConcour.salle_id === state.dico_salles[s].id
            )
            for (const dsl in dico_salles_lignes) {
                if (dico_salles_lignes[dsl] && dico_salles_lignes[dsl].nb_candidats_prevision !== null) {
                    const cTemp = {
                        capacite_max: null,
                        concour_id: dico_salles_lignes[dsl].concour_id,
                        nb_candidats: dico_salles_lignes[dsl].nb_candidats,
                        nb_candidats_prevision: dico_salles_lignes[dsl].nb_candidats_prevision,
                        salle_id: dico_salles_lignes[dsl].salle_id
                    }
                    state.dico_salles[s].concours.push(cTemp)
                }
            }
        }
    },
    SET_BESOINS_CENTRE(state: any, besoins: Array<any>) {
        state.besoins_centre = besoins
    },
    ADD_LIGNE_BESOINS_CENTRE(state: any, ligne: any) {
        state.besoins_centre.push(ligne)
    },
    SET_BESOINS_CENTRE_PRECEDENT(state: any, besoins: any) {
        state.besoins_precedent = besoins
    },
    UPDATE_BESOINS_CENTRE(state: any, besoin_poste: any) {
        const index = state.besoins_centre.findIndex((bc: any) => bc.poste_id === besoin_poste.poste_id)
        if (index !== -1) {
            state.besoins_centre[index].nb = besoin_poste.nb
            state.besoins_centre[index].regroupement = besoin_poste.regroupement
        }
    },
    SET_NEW_ID(state: any, new_id: number) {
        state.new_id = new_id
    },
    SET_SELECT_COMPOSANT(state: any, composant: string) {
        state.selectComposant = composant
    },
    SET_ERRORS_CODE(state: any, datas: any) {
        if (datas.type_erreur === 'unicite') {
            state.errors_code[datas.salle_id] = "Le code issu du nom de la salle n'est pas unique."
        } else if (datas.type_erreur === 'taille') {
            state.errors_code[datas.salle_id] = 'Le code issu du nom de la salle doit contenir au moins 2 caractères.'
        }

        state.has_error_code = true
    },
    SET_HAS_COMPTEURS_INCORRECTS(state: any, etat: boolean) {
        state.has_compteurs_incorrect = etat
    }
}

export const actions = {
    manageOrganisationEcrit({ commit }: { commit: any }, elems: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            commit('SET_ERROR', null)
            let datas = {}

            if (elems.params.mode === 'salles') {
                commit('PARSE_COLLECTION_SALLES')
                commit('SET_NEW_ID', -1)

                datas = {
                    centre_id: elems.params.centre_id,
                    salles: state.dico_salles,
                    salles_submit: elems.params.salles_submit,
                    salles_validate: elems.params.salles_validate,
                    commentaire_rejet: elems.params.commentaire_rejet
                }
            } else if (elems.params.mode === 'nb_interv') {
                datas = {
                    centre_id: elems.params.centre_id,
                    besoins: state.besoins_centre,
                    besoins_submit: elems.params.besoins_submit,
                    besoins_validate: elems.params.besoins_validate,
                    commentaire_rejet: elems.params.commentaire_rejet
                }
            }

            CentreService.updateOrganisation(datas)
                .then((response) => {
                    // toast.success('Enregistrement terminé.')
                    commit('SET_LOADING', false)
                    commit('centre/SET_SAVE_IN_PROGRESS', false, { root: true })
                    if (elems.params.mode === 'salles') {
                        commit('SET_DICO_SALLES', response.data.data.salles)
                    }
                    if (elems.params.mode === 'nb_interv') {
                        commit('SET_BESOINS_CENTRE', response.data.data.besoins)
                    }

                    commit('centre/MAJ_COLLECTION_UPDATE', response.data.data, { root: true })
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    dico_salles: (state: any) => {
        return state.dico_salles
    },
    besoins_centre: (state: any) => {
        return state.besoins_centre
    },
    get_total_capacite: (state: any) => {
        let total = 0
        for (const s in state.dico_salles) {
            if (state.dico_salles[s].capacite_max !== null) {
                total += Number(state.dico_salles[s].capacite_max)
            }
        }
        return total
    },
    get_total_repartition: (state: any) => {
        let total = 0
        for (const s in state.dico_salles_concours) {
            total += Number(state.dico_salles_concours[s].nb_candidats_prevision)
        }
        return total
    },
    get_total_intervenants_precedent: (state: any) => (groupe: string) => {
        let total = 0
        const besoins_precedents = state.besoins_precedent.filter((bp: any) => bp.regroupement === groupe)
        for (const s in besoins_precedents) {
            total += besoins_precedents[s].nb ? Number(besoins_precedents[s].nb) : 0
        }
        return total
    },
    get_total_intervenants: (state: any) => (groupe: string) => {
        let total = 0
        const besoins_centre = state.besoins_centre.filter((bp: any) => bp.regroupement === groupe)
        for (const s in besoins_centre) {
            total += besoins_centre[s].nb ? Number(besoins_centre[s].nb) : 0
        }
        return total
    },
    get_total_candidats: () => (ligne: any) => {
        let total = 0
        if (ligne.concours) {
            ligne.concours.forEach((concour: any) => {
                total += ligne.use_real_data_at ? Number(concour.nb_candidats) : Number(concour.nb_candidats_prevision)
            })
        }
        return total
    },
    get_evolution_salles_centre: () => (ligne: any) => {
        let evolution = { signe: '--', color: 'secondary', value: 0 }
        if (ligne.precedent && ligne.precedent.salles_count !== 0) {
            const result: any =
                ((Number(ligne.salles_count) - Number(ligne.precedent.salles_count)) /
                    Number(ligne.precedent.salles_count)) *
                100
            if (Number(ligne.salles_count) > Number(ligne.precedent.salles_count)) {
                evolution = { signe: '+', color: 'success', value: result.toFixed(0) }
            } else if (Number(ligne.salles_count) < Number(ligne.precedent.salles_count)) {
                evolution = { signe: '-', color: 'danger', value: result.toFixed(0) }
            } else if (Number(ligne.salles_count) === Number(ligne.precedent.salles_count)) {
                evolution = { signe: '=', color: 'dark', value: 0 }
            }
        }

        return evolution
    },
    get_evolution_surveillants_centre: () => (ligne: any) => {
        let evolution = { signe: '--', color: 'secondary', value: 0 }

        if (ligne.precedent && ligne.precedent.surveillants_count !== 0) {
            const result: any =
                ((Number(ligne.surveillants_count) - Number(ligne.precedent.surveillants_count)) /
                    Number(ligne.precedent.surveillants_count)) *
                100
            if (Number(ligne.surveillants_count) > Number(ligne.precedent.surveillants_count)) {
                evolution = { signe: '+', color: 'success', value: result.toFixed(0) }
            } else if (Number(ligne.surveillants_count) < Number(ligne.precedent.surveillants_count)) {
                evolution = { signe: '-', color: 'danger', value: result.toFixed(0) }
            } else if (Number(ligne.surveillants_count) === Number(ligne.precedent.surveillants_count)) {
                evolution = { signe: '=', color: 'dark', value: 0 }
            }
        }

        return evolution
    },
    get_ratio_candidat_intervenant: () => (ligne: any) => {
        const nb_candidats: number = getters.get_total_candidats()(ligne)
        let ratio = 0

        if (ligne && ligne.intervenants_count && ligne.intervenants_count !== 0) {
            ratio = nb_candidats / ligne.intervenants_count
        }

        return ratio.toFixed(1)
    },
    get_evolution_candidats: () => (ligne: any) => {
        let evolution = { signe: '--', color: 'secondary', value: 0 }

        if (ligne.precedent) {
            const nb_candidats: number = getters.get_total_candidats()(ligne)
            const nb_candidats_prec: number = getters.get_total_candidats()(ligne.precedent)

            const evolution_pct: any = ((nb_candidats - nb_candidats_prec) / nb_candidats_prec) * 100

            if (nb_candidats > nb_candidats_prec) {
                evolution = { signe: '+', color: 'success', value: evolution_pct.toFixed(0) }
            } else if (nb_candidats < nb_candidats_prec) {
                evolution = { signe: '-', color: 'danger', value: evolution_pct.toFixed(0) }
            } else if (nb_candidats === nb_candidats_prec) {
                evolution = { signe: '=', color: 'dark', value: 0 }
            }
        }

        return evolution
    },
    has_salle_amenagee: (state: any) => {
        if (state.dico_salles && state.dico_salles.length !== 0) {
            const salles_amenagees = state.dico_salles.filter(
                (salle: SalleInterface) => salle.amenagement === TypeAmenagement.AMENAGEMENT_HANDICAP
            )
            if (salles_amenagees.length !== 0) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    },
    has_salle_isolee: (state: any) => {
        if (state.dico_salles && state.dico_salles.length !== 0) {
            const salles_isolees = state.dico_salles.filter(
                (salle: SalleInterface) => salle.amenagement === TypeAmenagement.AMENAGEMENT_ISOLE
            )
            if (salles_isolees.length !== 0) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    },
    has_besoins_centre: (state: any) => {
        if (state.besoins_centre && state.besoins_centre.length !== 0) {
            return true
        } else {
            return false
        }
    },
    get_total_intervenants_with_besoins: () => (besoins: any, groupe: string) => {
        let total = 0
        const besoins_centre = besoins.filter((bp: any) => bp.regroupement === groupe)
        for (const s in besoins_centre) {
            total += besoins_centre[s].nb ? Number(besoins_centre[s].nb) : 0
        }
        return total
    },
    get_evolution_surveillants_centre_with_besoins: () => (en_cours: any, precedent: any) => {
        let evolution = { signe: '--', color: 'secondary', value: 0 }

        const result: any = ((Number(en_cours) - Number(precedent)) / Number(precedent)) * 100
        if (Number(en_cours) > Number(precedent)) {
            evolution = { signe: '+', color: 'success', value: Number(precedent) !== 0 ? result.toFixed(0) : 0 }
        } else if (Number(en_cours) < Number(precedent)) {
            evolution = { signe: '-', color: 'danger', value: Number(precedent) !== 0 ? result.toFixed(0) : 0 }
        } else if (Number(en_cours) === Number(precedent)) {
            evolution = { signe: '=', color: 'dark', value: 0 }
        }

        return evolution
    },
    get_ratio_candidat_intervenant_with_besoins: () => (ligne: any, surv_en_cours: number) => {
        const nb_candidats: number = getters.get_total_candidats()(ligne)
        let ratio = 0

        if (ligne && surv_en_cours && surv_en_cours !== 0) {
            ratio = nb_candidats / surv_en_cours
        }

        return ratio.toFixed(1)
    }
}
