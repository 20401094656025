import { getError } from '@/utils/helpers'
import CouleurService from '@/services/CouleurService'
import { CouleurInterface, StateCouleurs } from '@/types/Couleur'

export const namespaced = true

function setCouleurs(commit: Function, response: any) {
    commit('SET_COULEURS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateCouleurs = {
    couleurs: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    couleurSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateCouleurs) {
        Object.assign(state, {
            couleurs: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            couleurSelect: {}
        })
    },
    SET_COULEURS(state: any, couleurs: Array<CouleurInterface>) {
        state.couleurs = couleurs
    },
    SET_MORE_COULEURS(state: any, couleurs: Array<CouleurInterface>) {
        state.couleurs = state.couleurs.concat(couleurs)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_COULEUR_SELECT(state: any, couleur: CouleurInterface) {
        state.couleurSelect = couleur
    }
}

export const actions = {
    getCouleurs({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CouleurService.getCouleurs(params)
                .then((response) => {
                    setCouleurs(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getCouleur({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CouleurService.getCouleur(params.couleur_id)
                .then((response) => {
                    commit('SET_COULEUR_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreCouleurs({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CouleurService.getCouleur(params.couleur_id)
                .then((response) => {
                    commit('SET_COULEUR_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateCouleur({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CouleurService.updateCouleur(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addCouleur({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CouleurService.addCouleur(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteCouleur({ commit }: { commit: any }, couleur_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CouleurService.deleteCouleur(couleur_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    couleurs: (state: any) => {
        return state.couleurs
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.couleurs.length
    },
    couleurSelect: (state: any) => {
        return state.couleurSelect
    },
    getCouleurById: (state: any) => (id: number) => {
        const couleur = state.couleurs.filter((mat: any) => mat.id === id)[0]
        return couleur
    },
    getCodeCouleur: (state: any) => (id: number) => {
        const couleur = state.couleurs.filter((mat: any) => mat.id === id)[0]
        if (couleur) {
            return couleur.code
        } else {
            return 'NR'
        }
    },
    getCouleursObs: (state: any) => (liste_couleur_obs: any) => {
        const tab_couleurs: Array<any> = []
        for (const mo in liste_couleur_obs) {
            if (liste_couleur_obs[mo].entity_id) {
                const couleur_temp = state.couleurs.find((m: any) => m.id === liste_couleur_obs[mo].entity_id)
                if (couleur_temp) {
                    tab_couleurs.push(couleur_temp)
                }
            }
        }

        return tab_couleurs
    }
}
