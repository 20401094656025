import CandidatService from '@/services/CandidatService'
import PlanificationService from '@/services/PlanificationService'
import SeanceService from '@/services/SeanceService'
import { TypeIndispoCandidat } from '@/types/Candidat'
import { getTypeEnsembleOrder, getTypeEnsembleSpec, TypeEnsemble } from '@/types/Ensemble'
import { TypePassation } from '@/types/Epreuve'
import { EtatPlanificationAuto, getLibelleTypePlanification, TypePlanification } from '@/types/Planification'
import {
    dateIsBetween,
    formatDate,
    formatDateHoursMinutes,
    formatDateYYYYMMDD,
    getError,
    getWeekDayWithDayMonthYear
} from '@/utils/helpers'
import _ from 'lodash'
import Vue from 'vue'
import store from '..'

export const namespaced = true

export const state: any = {
    liste_candidats_planification: [],
    liste_candidats_brute: [],
    loading_planification: false,
    serie_selected: null,
    ensemble_selected: null,
    jours_series: [],
    error_planification: null,
    nb_resultats: 0,
    last_page: 1,
    dico_epreuve_corrections: [],
    show_chevauchement: false,
    creneaux_dispos: [],
    creneaux_dispos_multi: [],
    candidat_id_select: 0,
    seance_id_select: 0,
    creneaux_empty: false,
    historique_en_cours: null,
    historiques: [],
    historiques_sessionActive: [],
    compteurs_globaux: {},
    compteurs_after_planif: {},
    avancement: {},
    stats_ensembles: [],
    planification_en_cours: EtatPlanificationAuto.NON_LANCEE,
    arret_demander: false,
    info_avancement: '',
    regles_session: {},
    error_regles: null,
    historiques_en_cours: [],
    stats_session: [],
    jours_multi_series: {},
    count_dispos_P1: 0,
    count_dispos_P2: 0,
    tab_count_dispos_P1: [],
    tab_count_dispos_P2: [],
    loading_graph: false,
    etendue_trous: [],
    candidat_id_temp: null
}

// Indique si la séance ce déroule le matin ou l'aprés midi
function getPeriodeJournee(creneau_h_debut: Date) {
    let tab_heure: any = formatDateHoursMinutes(creneau_h_debut)
    tab_heure = tab_heure?.split(':')
    if (Number(tab_heure[0]) >= 13) {
        return 'PM'
    } else {
        return 'AM'
    }
}

// Reformatte l'objet candidat pour le push en collection
function prepareCandidat(candidats: Array<any>, show_chevauchement: boolean) {
    for (const c in candidats) {
        // Formattage des tableaux sur chaque jours de la série en prenant compte des jours de chevauchement
        const tab_seances: { [index: string]: any } = {}
        const tab_indispos: { [index: string]: any } = {}
        for (const j in state.jours_series) {
            const jour_in_serie = dateIsBetween(j, state.serie_selected.debut, state.serie_selected.fin, '[]')
            tab_seances[j] = {
                AM: [],
                PM: [],
                show: show_chevauchement ? true : !show_chevauchement && jour_in_serie,
                jour_in_serie: jour_in_serie
            }
        }

        // Collection pour les séances non planifiées
        tab_seances[-1] = {
            NP: []
        }

        // Collection pour les séances non planifiées sur la série du candidat
        tab_seances[-2] = {
            NP: []
        }

        // Gestion des séances
        if (candidats[c].seances.length !== 0) {
            candidats[c].seances = _.orderBy(candidats[c].seances, 'creneau.h_debut', 'asc')
        }

        for (const s in candidats[c].seances) {
            let periode = candidats[c].seances[s].creneau
                ? getPeriodeJournee(candidats[c].seances[s].creneau.h_debut)
                : 'NP'
            const jour = candidats[c].seances[s].creneau
                ? formatDateYYYYMMDD(candidats[c].seances[s].creneau.h_debut)
                : 'NJ'
            const prefixe_matiere =
                candidats[c].seances[s].epreuveCorrection &&
                candidats[c].seances[s].epreuveCorrection.type_passation &&
                candidats[c].seances[s].epreuveCorrection.type_passation === TypePassation.TYPE_PASSATION_TP
                    ? 'TP'
                    : null

            const seance_format = {
                id: candidats[c].seances[s].id,
                epreuve_name: candidats[c].seances[s].epreuveCorrection.name,
                matiere_id: candidats[c].seances[s].epreuveCorrection.matiere_id,
                prefixe_matiere: prefixe_matiere,
                matiere_code: store.getters['matiere/getCodeMatiere'](
                    candidats[c].seances[s].epreuveCorrection.matiere_id
                ),
                creneau_debut: candidats[c].seances[s].creneau
                    ? formatDateHoursMinutes(candidats[c].seances[s].creneau.h_debut)
                    : null,
                creneau_fin: candidats[c].seances[s].creneau
                    ? formatDateHoursMinutes(candidats[c].seances[s].creneau.h_fin)
                    : null,
                creneau_id: candidats[c].seances[s].creneau ? candidats[c].seances[s].creneau.id : null,
                ensemble_id: candidats[c].seances[s].creneau ? candidats[c].seances[s].creneau.ensemble_id : null,
                examinateur_id: candidats[c].seances[s].creneau ? candidats[c].seances[s].creneau.user_id : null,
                centre:
                    candidats[c].seances[s].creneau &&
                    candidats[c].seances[s].creneau.ensemble &&
                    candidats[c].seances[s].creneau.ensemble.centre
                        ? candidats[c].seances[s].creneau.ensemble.centre
                        : null,
                jour: jour,
                etat: candidats[c].seances[s].etat,
                serie_id: candidats[c].seances[s].serie_id,
                periode: periode
            }

            if (candidats[c].seances[s].creneau && tab_seances[jour]) {
                tab_seances[jour][periode].push(seance_format)
                // Au moins un créneau présent sur la journée donc on affiche

                // Pas dans la série mais chevauchement affiché OU jour dans la série
                if ((!tab_seances[jour].jour_in_serie && state.show_chevauchement) || tab_seances[jour].jour_in_serie) {
                    tab_seances[jour].show = true
                } else if (!tab_seances[jour].jour_in_serie && !state.show_chevauchement) {
                    tab_seances[jour].show = false
                }
            } else {
                if (
                    state.serie_selected &&
                    state.serie_selected.id &&
                    candidats &&
                    candidats[c] &&
                    candidats[c].seances &&
                    candidats[c].seances[s] &&
                    candidats[c].seances[s].serie_id &&
                    candidats[c].seances[s].serie_id !== state.serie_selected.id &&
                    candidats[c].seances[s].creneau
                ) {
                    periode = 'NP'
                    tab_seances[-2][periode].push(seance_format)
                } else {
                    tab_seances[-1][periode].push(seance_format)
                }
            }
        }

        // Gestion des indisponibilités
        for (const i in candidats[c].indisponibilites) {
            let periode = ''
            switch (candidats[c].indisponibilites[i].matin_aprem) {
                case TypeIndispoCandidat.TYPE_APRES_MIDI:
                    periode = 'PM'
                    break
                case TypeIndispoCandidat.TYPE_JOURNEE:
                    periode = 'DAY'
                    break
                case TypeIndispoCandidat.TYPE_MATIN:
                    periode = 'AM'
                    break
            }

            tab_indispos[candidats[c].indisponibilites[i].jour] = {
                periode: periode,
                motif: candidats[c].indisponibilites[i].motif
            }
        }

        const candidat_format = {
            id: candidats[c].id,
            name: candidats[c].name,
            first_name: candidats[c].first_name,
            code: candidats[c].code,
            ensembles: candidats[c].ensembles,
            seances: tab_seances,
            indisponibilites: tab_indispos,
            serie: candidats[c].serie ? candidats[c].serie.name : 'Pas de série'
        }
        state.liste_candidats_planification.push(candidat_format)
    }
}

// Retourne le mouvement qui à était fait sur l'action
function getMove(action: any) {
    let move = ''
    // On a un nouveau creneau mais pas d'ancien = AJOUT
    if (action.datas.new_creneau && !action.datas.old_creneau) {
        move = 'ADD'
    }
    // Un nouveau créneau et un ancien = DEPLACEMENT
    else if (action.datas.new_creneau && action.datas.old_creneau) {
        move = 'DEPLACE'
    }
    // Un ancien créneau et pas de nouveau = RETRAIT DU CRENEAU
    else if (!action.datas.new_creneau && action.datas.old_creneau) {
        move = 'DELETE'
    }

    return move
}

// Formatte la chaine de parametre de l'historique
function formatteParametresHistorique(params: any) {
    let banque_name = ''
    let ensemble_name = ''
    let handi = ''
    let type_ensemble_name = ''
    let libelle = 'Toutes les équipes'

    if (params && params.type_ensemble !== null) {
        type_ensemble_name = getTypeEnsembleSpec(params.type_ensemble).libelle
    }

    if (params && params.banque_id) {
        banque_name = store.getters['banque/getBanqueById'](params.banque_id)
            ? store.getters['banque/getBanqueById'](params.banque_id).name
            : '-'
    }

    if (params && params.ensemble_id) {
        ensemble_name = store.getters['ensemble/ensembleById'](params.ensemble_id)
            ? store.getters['ensemble/ensembleById'](params.ensemble_id).name
            : '-'
    }

    if (params && params.handi) {
        handi = 'Candidats ayant un placement particulier'
    }

    // Met en forme le libellé à afficher selon les critères remplis
    if (type_ensemble_name !== '') {
        libelle = type_ensemble_name
    }
    if (banque_name !== '') {
        libelle = type_ensemble_name + ' ' + banque_name
    }
    if (ensemble_name !== '') {
        libelle = type_ensemble_name + ' - ' + ensemble_name
    }
    if (handi) {
        libelle = handi
    }

    return libelle
}

// Formatte l'objet historique
function prepareHistorique(historique: any) {
    const planif_type = getLibelleTypePlanification(historique.type)
    const histo: any = {
        formatte: true,
        libelle: planif_type,
        date: formatDate(historique.created_at),
        etat: historique.etat,
        arret: historique.arret,
        id: historique.id,
        serie_id: historique.serie_id,
        session_id: historique.session_id,
        type: historique.type,
        user_id: historique.user_id,
        user_name: historique.user.first_name.slice(0, 1) + '. ' + historique.user.name,
        params: formatteParametresHistorique(historique.params),
        etats_serie: historique.params,
        actions: []
    }
    for (const a in historique.actions) {
        if (historique.type === TypePlanification.TYPE_PLANIF_MANUEL) {
            const epreuve = store.getters['epreuve/getEpreuveCorrectionById'](
                historique.actions[a].seance.epreuve_correction_id
            )
            const action: any = {
                id: historique.actions[a].id,
                candidat_id: historique.actions[a].seance.candidat.id,
                ensemble_id: historique.actions[a].ensemble_id,
                epreuve_correction_id: historique.actions[a].seance.epreuve_correction_id,
                code_candidat: historique.actions[a].seance.candidat.code,
                code_matiere: epreuve.matiere.code,
                etat: historique.actions[a].etat,
                old_creneau: historique.actions[a].datas.old_creneau,
                new_creneau: historique.actions[a].datas.new_creneau,
                move: getMove(historique.actions[a])
            }

            histo.actions.push(action)
        } else if (historique.type === TypePlanification.TYPE_PLANIF_AUTO) {
            const action: any = {
                id: historique.actions[a].id,
                etat: historique.actions[a].etat,
                info: historique.actions[a].datas.info,
                stats: historique.actions[a].datas.stats
            }
            histo.actions.push(action)
        }
    }

    return histo
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            liste_candidats_planification: [],
            liste_candidats_brute: [],
            loading_planification: false,
            serie_selected: null,
            ensemble_selected: null,
            jours_series: [],
            error_planification: null,
            nb_resultats: 0,
            last_page: 1,
            dico_epreuve_corrections: [],
            show_chevauchement: false,
            creneaux_dispos: [],
            creneaux_dispos_multi: [],
            candidat_id_select: 0,
            seance_id_select: 0,
            creneaux_empty: false,
            historique_en_cours: null,
            historiques: [],
            historiques_sessionActive: [],
            compteurs_globaux: {},
            compteurs_after_planif: {},
            avancement: {},
            stats_ensembles: [],
            planification_en_cours: EtatPlanificationAuto.NON_LANCEE,
            info_avancement: '',
            arret_demander: false,
            regles_session: {},
            error_regles: null,
            historiques_en_cours: [],
            stats_session: [],
            jours_multi_series: {},
            count_dispos_P1: 0,
            count_dispos_P2: 0,
            tab_count_dispos_P1: [],
            tab_count_dispos_P2: [],
            loading_graph: false,
            etendue_trous: [],
            candidat_id_temp: null
        })
    },
    SET_SERIE_SELECTED(state: any, serie_temp: any) {
        state.serie_selected = serie_temp
    },
    SET_ENSEMBLE_SELECTED(state: any, ensemble_temp: any) {
        state.ensemble_selected = ensemble_temp
    },
    SET_JOURS_SERIES(state: any, jours_series: any) {
        state.jours_series = jours_series
    },
    SET_LOADING(state: any, etat: boolean) {
        state.loading_planification = etat
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error_planification = error
    },
    SET_ERROR_REGLES(state: any, error: Array<any>) {
        state.error_regles = error
    },
    SET_LISTE_CANDIDATS_PLANIFICATION(state: any, candidats: any) {
        state.liste_candidats_planification = []
        state.liste_candidats_brute = candidats
        prepareCandidat(candidats, state.show_chevauchement)
    },
    SET_MORE_LISTE_CANDIDATS_PLANIFICATION(state: any, candidats: any) {
        state.liste_candidats_brute.concat(candidats)
        prepareCandidat(candidats, state.show_chevauchement)
    },
    SET_NB_RESULTATS(state: any, nb_c: number) {
        state.nb_resultats = nb_c
    },
    SET_LAST_PAGE(state: any, lp: number) {
        state.last_page = lp
    },
    SET_SHOW_CHEVAUCHEMENT(state: any, etat: boolean) {
        state.show_chevauchement = etat
    },
    SET_CANDIDAT_ID_SELECT(state: any, candidat_id: number) {
        state.candidat_id_select = candidat_id
    },
    SET_SEANCE_ID_SELECT(state: any, seance_id: number) {
        state.seance_id_select = seance_id
    },
    SET_CRENEAUX_DISPOS(state: any, creneaux: any) {
        state.creneaux_empty = false
        const creneaux_possibles = creneaux.possibles
        const creneaux_restants = creneaux.restants
        state.count_dispos_P1 = 0
        state.count_dispos_P2 = 0
        // Formattage des tableaux sur chaque jours de la série en prenant compte des jours de chevauchement
        const tab_creneaux: { [index: string]: any } = {}
        for (const j in state.jours_series) {
            tab_creneaux[j] = {
                AM: [],
                PM: [],
                showDispoAM: false,
                showDispoPM: false,
                count_restants_P1: 0,
                count_restants_P2: 0,
                candidat_id: state.candidat_id_select
            }
        }

        for (const c in creneaux_possibles) {
            const periode = getPeriodeJournee(creneaux_possibles[c].h_debut)
            const jour = creneaux_possibles[c].jour
            const creneau_temp = {
                id: creneaux_possibles[c].id,
                jour: creneaux_possibles[c].jour,
                h_debut: creneaux_possibles[c].h_debut,
                h_fin: creneaux_possibles[c].h_fin,
                user: creneaux_possibles[c].user,
                actif: creneaux_possibles[c].actif,
                etat: creneaux_possibles[c].etat,
                ensemble_id: creneaux_possibles[c].ensemble_id
            }

            // Au moins un push, on affiche la surbrillance
            tab_creneaux[jour][periode].push(creneau_temp)
            if (tab_creneaux[jour][periode].length !== 0 && periode === 'AM') {
                tab_creneaux[jour].showDispoAM = true
            }
            if (tab_creneaux[jour][periode].length !== 0 && periode === 'PM') {
                tab_creneaux[jour].showDispoPM = true
            }
        }

        // Parcours les restants pour les compteurs
        for (const r in creneaux_restants) {
            const jour = creneaux_restants[r].jour

            // Gestion pour les compteurs de TP
            if (creneaux_restants[r].ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_TP) {
                const count_seances = creneaux_restants[r].seances.length
                let nb_prios_1 = creneaux_restants[r].nb_candidats_prio - count_seances
                let nb_prios_2 = creneaux_restants[r].nb_candidats_supp

                if (nb_prios_1 < 0) {
                    nb_prios_2 = creneaux_restants[r].nb_candidats_supp + nb_prios_1
                    nb_prios_1 = 0
                }

                tab_creneaux[jour].count_restants_P1 += nb_prios_1
                state.count_dispos_P1 += nb_prios_1

                tab_creneaux[jour].count_restants_P2 += nb_prios_2
                state.count_dispos_P2 += nb_prios_2
            } else {
                if (creneaux_restants[r].actif === 1) {
                    tab_creneaux[jour].count_restants_P1++
                    state.count_dispos_P1++
                } else {
                    tab_creneaux[jour].count_restants_P2++
                    state.count_dispos_P2++
                }
            }
        }

        state.creneaux_dispos = tab_creneaux

        // Check si on a un retour de créneaux
        if (creneaux_possibles.length === 0) {
            state.creneaux_empty = true
        }
    },
    SET_CRENEAUX_DISPOS_SERIES(state: any, creneaux_series: any) {
        // state.creneaux_empty = false
        // Formattage des tableaux sur chaque jours de la série en prenant compte des jours de chevauchement
        const tab_creneaux: { [index: string]: any } = {}

        for (const s in state.jours_multi_series) {
            tab_creneaux[s] = {}

            state.tab_count_dispos_P1[s] = 0
            state.tab_count_dispos_P2[s] = 0

            for (const j in state.jours_multi_series[s]) {
                tab_creneaux[s][j] = {
                    AM: [],
                    PM: [],
                    showDispoAM: false,
                    showDispoPM: false,
                    count_restants_P1: 0,
                    count_restants_P2: 0,
                    candidat_id: state.candidat_id_select
                }
            }
        }

        for (const s in creneaux_series) {
            for (const i in creneaux_series[s].possibles) {
                const periode: string = getPeriodeJournee(creneaux_series[s].possibles[i].h_debut)
                const jour: string = creneaux_series[s].possibles[i].jour
                const creneau_temp = {
                    id: creneaux_series[s].possibles[i].id,
                    jour: creneaux_series[s].possibles[i].jour,
                    h_debut: creneaux_series[s].possibles[i].h_debut,
                    h_fin: creneaux_series[s].possibles[i].h_fin,
                    user: creneaux_series[s].possibles[i].user,
                    actif: creneaux_series[s].possibles[i].actif,
                    etat: creneaux_series[s].possibles[i].etat,
                    ensemble_id: creneaux_series[s].possibles[i].ensemble_id
                }

                // Au moins un push, on affiche la surbrillance
                tab_creneaux[s][jour][periode].push(creneau_temp)
                if (tab_creneaux[s][jour][periode].length !== 0 && periode === 'AM') {
                    tab_creneaux[s][jour].showDispoAM = true
                }
                if (tab_creneaux[s][jour][periode].length !== 0 && periode === 'PM') {
                    tab_creneaux[s][jour].showDispoPM = true
                }
            }

            // Parcours les restants pour les compteurs
            for (const r in creneaux_series[s].restants) {
                const jour = creneaux_series[s].restants[r].jour
                if (creneaux_series[s].restants[r].actif === 1) {
                    tab_creneaux[s][jour].count_restants_P1++
                    state.tab_count_dispos_P1[s]++
                } else {
                    tab_creneaux[s][jour].count_restants_P2++
                    state.tab_count_dispos_P2[s]++
                }
            }
        }

        state.creneaux_dispos = tab_creneaux
    },
    SET_CRENEAUX_DISPOS_MULTI(state: any, tab: any) {
        state.creneaux_dispos_multi = tab
    },
    RESET_CRENEAUX_DISPOS(state: any) {
        state.creneaux_dispos = []
        state.creneaux_dispos_multi = []
    },
    SET_HISTORIQUE_EN_COURS(state: any, historique: any) {
        if (historique.formatte) {
            state.historique_en_cours = historique
        } else {
            const planif_type = getLibelleTypePlanification(historique.type)
            state.historique_en_cours = {
                formatte: true,
                libelle: planif_type,
                date: formatDate(historique.created_at),
                etat: historique.etat,
                arret: historique.arret,
                id: historique.id,
                serie_id: historique.serie_id,
                session_id: historique.session_id,
                type: historique.type,
                user_id: historique.user_id,
                user_name:
                    store.getters['auth/authUser'].first_name.slice(0, 1) + '. ' + store.getters['auth/authUser'].name,
                params: formatteParametresHistorique(historique.params),
                etats_serie: historique.params,
                actions: []
            }
        }
    },
    SET_HISTORIQUES(state: any, historiques: any) {
        state.historiques = []
        for (const h in historiques) {
            const histo_temp = prepareHistorique(historiques[h])
            state.historiques.push(histo_temp)
        }

        state.historiques = _.orderBy(state.historiques, 'id', 'desc')
    },
    SET_HISTORIQUES_SESSIONACTIVE(state: any, historiques_sessionActive: any) {
        state.historiques_sessionActive = historiques_sessionActive
    },
    MAJ_ADD_SEANCE(state: any, datas: any) {
        // MAJ la séance sur le semainier
        const index_cand: number = state.liste_candidats_planification.findIndex(
            (cand: any) => cand.id === datas.seance.candidat_id
        )
        const candidat_temp: any = state.liste_candidats_planification[index_cand]
        let seance_temp: Array<any> = []
        const periode = getPeriodeJournee(datas.seance.creneau.h_debut)
        // Retire la séances des non affectés
        for (const pe in candidat_temp.seances[-1]) {
            if (pe === 'NP') {
                for (const s in candidat_temp.seances[-1][pe]) {
                    if (candidat_temp.seances[-1][pe][s].id === datas.seance.id) {
                        candidat_temp.seances[-1][pe][s].creneau_id = datas.seance.creneau.id
                        candidat_temp.seances[-1][pe][s].creneau_debut = formatDateHoursMinutes(
                            datas.seance.creneau.h_debut
                        )
                        candidat_temp.seances[-1][pe][s].creneau_fin = formatDateHoursMinutes(
                            datas.seance.creneau.h_fin
                        )
                        candidat_temp.seances[-1][pe][s].examinateur_id = datas.seance.creneau.user_id
                        candidat_temp.seances[-1][pe][s].ensemble_id = datas.seance.creneau.ensemble_id
                        candidat_temp.seances[-1][pe][s].periode = periode
                        candidat_temp.seances[-1][pe][s].etat = datas.seance.etat
                        candidat_temp.seances[-1][pe][s].jour = datas.seance.creneau.jour
                        candidat_temp.seances[-1][pe][s].serie_id = datas.seance.serie_id

                        seance_temp = candidat_temp.seances[-1][pe][s]
                        candidat_temp.seances[-1][pe].splice(s, 1)
                    }
                }
            }
        }

        // MAJ la séance sur le candidat
        if (candidat_temp.seances[datas.seance.creneau.jour]) {
            candidat_temp.seances[datas.seance.creneau.jour][periode].push(seance_temp)
            candidat_temp.seances[datas.seance.creneau.jour][periode] = _.orderBy(
                candidat_temp.seances[datas.seance.creneau.jour][periode],
                'creneau_debut',
                'asc'
            )
            if (state.jours_multi_series[datas.seance.serie_id]) {
                state.jours_multi_series[datas.seance.serie_id][datas.seance.creneau.jour][periode].push(seance_temp)
            }
        } else {
            // Gestion multi série
            const pe_np = 'NP'
            candidat_temp.seances[-2][pe_np].push(seance_temp)
            candidat_temp.seances[-2][pe_np] = _.orderBy(candidat_temp.seances[-2][pe_np], 'creneau_debut', 'asc')
            if (state.jours_multi_series[datas.seance.serie_id]) {
                state.jours_multi_series[datas.seance.serie_id][datas.seance.creneau.jour][periode].push(seance_temp)
            }
        }
    },
    MAJ_UPDATE_SEANCE(state: any, datas: any) {
        // Retrait du old creneau
        const index_cand: number = state.liste_candidats_planification.findIndex(
            (cand: any) => cand.id === datas.seance.candidat_id
        )
        const candidat_temp: any = state.liste_candidats_planification[index_cand]
        let seance_temp: any = []

        if (candidat_temp.seances[datas.seance.creneau.jour]) {
            for (const s in candidat_temp.seances) {
                const seances_am: any = candidat_temp.seances[s]
                for (const p in seances_am) {
                    for (const sam in seances_am[p]) {
                        if (candidat_temp.seances[s][p][sam].id === datas.seance.id) {
                            candidat_temp.seances[s][p][sam].creneau_id = datas.seance.creneau.id
                            candidat_temp.seances[s][p][sam].jour = datas.seance.creneau.jour
                            candidat_temp.seances[s][p][sam].creneau_debut = formatDateHoursMinutes(
                                datas.seance.creneau.h_debut
                            )
                            candidat_temp.seances[s][p][sam].creneau_fin = formatDateHoursMinutes(
                                datas.seance.creneau.h_fin
                            )
                            candidat_temp.seances[s][p][sam].examinateur_id = datas.seance.creneau.user_id
                            candidat_temp.seances[s][p][sam].serie_id = datas.seance.serie_id
                            seance_temp = candidat_temp.seances[s][p][sam]
                            candidat_temp.seances[s][p].splice(sam, 1)

                            break
                        }
                    }
                }
            }
        } else {
            for (const s in state.jours_multi_series) {
                for (const j in state.jours_multi_series[s]) {
                    for (const p in state.jours_multi_series[s][j]) {
                        for (const i in state.jours_multi_series[s][j][p]) {
                            if (state.jours_multi_series[s][j][p][i].id === datas.seance.id) {
                                state.jours_multi_series[s][j][p][i].creneau_id = datas.seance.creneau.id
                                state.jours_multi_series[s][j][p][i].jour = datas.seance.creneau.jour
                                state.jours_multi_series[s][j][p][i].creneau_debut = formatDateHoursMinutes(
                                    datas.seance.creneau.h_debut
                                )
                                state.jours_multi_series[s][j][p][i].creneau_fin = formatDateHoursMinutes(
                                    datas.seance.creneau.h_fin
                                )
                                state.jours_multi_series[s][j][p][i].examinateur_id = datas.seance.creneau.user_id
                                state.jours_multi_series[s][j][p][i].serie_id = datas.seance.serie_id
                                seance_temp = state.jours_multi_series[s][j][p][i]
                                state.jours_multi_series[s][j][p].splice(i, 1)
                                break
                            }
                        }
                    }
                }
            }
        }

        // Ajout du new creneau
        const periode = getPeriodeJournee(datas.seance.creneau.h_debut)
        if (candidat_temp.seances[datas.seance.creneau.jour]) {
            candidat_temp.seances[datas.seance.creneau.jour][periode].push(seance_temp)
            candidat_temp.seances[datas.seance.creneau.jour][periode] = _.orderBy(
                candidat_temp.seances[datas.seance.creneau.jour][periode],
                'creneau_debut',
                'asc'
            )
        }
        // Clean le jours_multi_series
        for (const s in state.jours_multi_series) {
            for (const j in state.jours_multi_series[s]) {
                for (const p in state.jours_multi_series[s][j]) {
                    for (const i in state.jours_multi_series[s][j][p]) {
                        if (state.jours_multi_series[s][j][p][i].id === seance_temp.id) {
                            state.jours_multi_series[s][j][p].splice(i, 1)
                        }
                    }
                }
            }
        }
        if (state.jours_multi_series[datas.seance.serie_id]) {
            state.jours_multi_series[datas.seance.serie_id][datas.seance.creneau.jour][periode].push(seance_temp)
        }
    },
    MAJ_DELETE_SEANCE(state: any, datas: any) {
        // DELETE le créaneau sur la séance
        const index_cand: number = state.liste_candidats_planification.findIndex(
            (cand: any) => cand.id === datas.seance.candidat_id
        )
        const candidat_temp: any = state.liste_candidats_planification[index_cand]
        let seance_temp: any = null

        for (const s in candidat_temp.seances) {
            const seances_am: any = candidat_temp.seances[s]
            for (const p in seances_am) {
                for (const sam in seances_am[p]) {
                    if (candidat_temp.seances[s][p][sam].id === datas.seance.id) {
                        candidat_temp.seances[s][p][sam].creneau_debut = null
                        candidat_temp.seances[s][p][sam].creneau_fin = null
                        candidat_temp.seances[s][p][sam].creneau_id = null
                        candidat_temp.seances[s][p][sam].examinateur_id = null
                        candidat_temp.seances[s][p][sam].serie_id = null
                        candidat_temp.seances[s][p][sam].periode = 'NP'
                        candidat_temp.seances[s][p][sam].jour = null
                        seance_temp = candidat_temp.seances[s][p][sam]

                        candidat_temp.seances[s][p].splice(sam, 1)

                        break
                    }
                }
            }
        }

        // Bascule la séance dans les NP
        for (const pe in candidat_temp.seances[-1]) {
            const has_id = candidat_temp.seances[-1][pe].find((i: any) => i.id === seance_temp.id)
            if (pe === 'NP' && has_id === undefined) {
                candidat_temp.seances[-1][pe].push(seance_temp)
            }
        }

        // Clean le jours_multi_series
        for (const s in state.jours_multi_series) {
            for (const j in state.jours_multi_series[s]) {
                for (const p in state.jours_multi_series[s][j]) {
                    for (const i in state.jours_multi_series[s][j][p]) {
                        if (state.jours_multi_series[s][j][p][i].id === seance_temp.id) {
                            state.jours_multi_series[s][j][p].splice(i, 1)
                        }
                    }
                }
            }
        }
    },
    MAJ_ACTIONS_HISTO_EN_COURS(state: any, datas: any) {
        state.historique_en_cours.actions = []
        // PUSH l'action dans l'historique en cours
        for (const a in datas.actions) {
            if (state.historique_en_cours.type === TypePlanification.TYPE_PLANIF_MANUEL) {
                const epreuve = store.getters['epreuve/getEpreuveCorrectionById'](
                    datas.actions[a].seance.epreuve_correction_id
                )
                const action: any = {
                    id: datas.actions[a].id,
                    candidat_id: datas.actions[a].seance.candidat.id,
                    ensemble_id: datas.actions[a].ensemble_id,
                    epreuve_correction_id: datas.actions[a].seance.epreuve_correction_id,
                    code_candidat: datas.actions[a].seance.candidat.code,
                    code_matiere: epreuve.matiere.code,
                    etat: datas.actions[a].etat,
                    old_creneau: datas.actions[a].datas.old_creneau,
                    new_creneau: datas.actions[a].datas.new_creneau,
                    move: getMove(datas.actions[a])
                }

                state.historique_en_cours.actions.push(action)
            } else if (state.historique_en_cours.type === TypePlanification.TYPE_PLANIF_AUTO) {
                const action: any = {
                    id: datas.actions[a].id,
                    etat: datas.actions[a].etat,
                    info: datas.actions[a].datas.info,
                    stats: datas.actions[a].datas.stats
                }
                state.historique_en_cours.actions.push(action)
            }
        }
    },
    REFRESH_STATS(state: any, datas: any) {
        if (datas && datas.global) {
            state.compteurs_after_planif = datas.global
    
            if (datas.avancement.nbAPlanifier !== 0) {
                state.avancement = datas.avancement
                state.avancement.pct = 0
                state.avancement.manques = 0
    
                if (state.avancement.nbAPlanifier !== 0) {
                    // Calcul du pct d'avancement
                    state.avancement.pct = Math.floor(
                        ((state.avancement.nbPlanifie + state.avancement.nbNonPlanifie) / state.avancement.nbAPlanifier) *
                            100
                    )
                }
    
                state.stats_ensembles = []
                const types_ensembles = getTypeEnsembleOrder()
                for (const t in types_ensembles) {
                    const type_ensemble = types_ensembles[t]
                    for (const e in datas.ensembles[type_ensemble.index]) {
                        const ensemble = store.getters['ensemble/ensembleById'](Number(e))
                        if (ensemble) {
                            const ensemble_temps = {
                                id: e,
                                type_ensemble_index: type_ensemble.index,
                                ordre: type_ensemble.ordre,
                                ensemble_name: ensemble.name,
                                ensemble_concours: ensemble?.concour?.name || null,
                                series: datas.ensembles[type_ensemble.index][e]
                            }
    
                            for (const s in ensemble_temps.series) {
                                if (ensemble_temps.series[s].manque) {
                                    state.avancement.manques += Number(ensemble_temps.series[s].manque.nbManque)
                                }
                            }
    
                            state.stats_ensembles.push(ensemble_temps)
                        }
                    }
                }
                state.stats_ensembles = state.stats_ensembles.sort((a: any, b: any) =>
                    a.type_ensemble_index > b.type_ensemble_index
                        ? 1
                        : a.type_ensemble_index < b.type_ensemble_index
                            ? -1
                            : a.ensemble_name > b.ensemble_name
                                ? 1
                                : a.ensemble_name < b.ensemble_name
                                    ? -1
                                    : 0
                )
            }
        }
    },
    REFRESH_STATS_ARRET(state: any, etat: number) {
        state.avancement.arret = etat
    },
    REFRESH_STATS_ETAT(state: any, etat: number) {
        state.avancement.etat = etat
    },
    REFRESH_STATS_GLOBALES(state: any, datas: any) {
        if (datas && datas.global) {
            state.compteurs_globaux = datas.global
        }
    },
    SET_STATS_SESSION(state: any, datas: any) {
        state.stats_session = datas
    },
    MAJ_STATE_PLANIFICATION(state: any, etat: EtatPlanificationAuto) {
        state.planification_en_cours = etat
    },
    MAJ_INFO_AVANCEMENT(state: any, message: string) {
        state.info_avancement = message
    },
    MAJ_ARRET(state: any, etat: boolean) {
        state.arret_demander = etat
    },
    SET_REGLES_SESSION(state: any, regles: any) {
        state.regles_session = regles
    },
    SET_HISTORIQUES_EN_COURS(state: any, histos_en_cours: any) {
        state.historiques_en_cours = []
        for (const h in histos_en_cours) {
            const histo_temp = prepareHistorique(histos_en_cours[h])
            state.historiques.push(histo_temp)
        }
    },
    RESET_STATS(state: any) {
        state.avancement.pct = 0
        state.avancement.nbAPlanifier = 0
        state.avancement.nbPlanifie = 0
        state.stats_ensembles = []
    },
    REFRESH_COLLECTION_REGLES(state: any, collec: any) {
        state.regles_session = collec
    },
    SET_ETENDUE_TROUS(state: any, stats: any) {
        state.etendue_trous = stats
    },
    SET_LOADING_GRAPH(state: any, etat: boolean) {
        state.loading_graph = etat
    },
    SET_CANDIDAT_TEMP(state: any, candidat_id: number) {
        state.candidat_id_temp = candidat_id
    }
}

export const actions = {
    getCandidatsPlanification({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatService.getCandidatsPlanification(params)
                .then((response) => {
                    commit('SET_LISTE_CANDIDATS_PLANIFICATION', response.data.data)
                    commit('SET_NB_RESULTATS', response.data.meta.total)
                    commit('SET_LAST_PAGE', response.data.meta.last_page)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreCandidatsPlanification({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            CandidatService.getCandidatsPlanification(params)
                .then((response) => {
                    commit('SET_MORE_LISTE_CANDIDATS_PLANIFICATION', response.data.data)
                    commit('SET_NB_RESULTATS', response.data.meta.total)
                    commit('SET_LAST_PAGE', response.data.meta.last_page)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getCreneauxDispos({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            SeanceService.getCreneauxDispos(params.seance_id)
                .then((response) => {
                    commit('SET_CRENEAUX_DISPOS', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getCreneauxDisposSeries({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            SeanceService.getCreneauxDisposSeries(params.seance_id)
                .then((response) => {
                    commit('SET_CRENEAUX_DISPOS_SERIES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateSeance({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            SeanceService.updateSeanceCreneaux(params.seance_id, params.creneau_id)
                .then((response) => {
                    if (params.mode === 'ADD') {
                        commit('MAJ_ADD_SEANCE', response.data.data)
                    } else if (params.mode === 'UPDATE') {
                        commit('MAJ_UPDATE_SEANCE', response.data.data)
                    }
                    commit('MAJ_ACTIONS_HISTO_EN_COURS', response.data.data.histo)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteSeance({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            SeanceService.deleteSeanceCreneaux(params.seance_id, params.creneau_id)
                .then((response) => {
                    commit('MAJ_DELETE_SEANCE', response.data.data)
                    commit('MAJ_ACTIONS_HISTO_EN_COURS', response.data.data.histo)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getHistoPlanif({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            PlanificationService.getHistoPlanif(params.serie_id, params.type_planif)
                .then((response) => {
                    commit('SET_HISTORIQUES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getHistoPlanifSessionActive({ commit }: { commit: any }) {
        return new Promise((resolve, reject) => {
            PlanificationService.getHistoPlanifSessionActive()
                .then((response) => {
                    commit('SET_HISTORIQUES_SESSIONACTIVE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getHistoPlanifEnCours({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            PlanificationService.getHistoPlanif(params.serie_id, params.type_planif)
                .then((response) => {
                    commit('SET_HISTORIQUES_EN_COURS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    createHistoPlanif({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            PlanificationService.createHistoPlanif(params.serie_id)
                .then((response) => {
                    commit('SET_HISTORIQUE_EN_COURS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    validateHistoPlanif({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            PlanificationService.validateHistoPlanif(params.histo_planif_id)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    cancelHistoPlanif({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            PlanificationService.cancelHistoPlanif(params.histo_planif_id)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    undoHistoPlanif({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            PlanificationService.undoHistoPlanif(params.histo_planif_id, params.action_planif_id)
                .then((response) => {
                    commit('MAJ_ACTIONS_HISTO_EN_COURS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    redoHistoPlanif({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            PlanificationService.redoHistoPlanif(params.histo_planif_id, params.action_planif_id)
                .then((response) => {
                    commit('MAJ_ACTIONS_HISTO_EN_COURS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getStatsSession({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            PlanificationService.getStatsPlanif(params.session_id, params.payload)
                .then((response) => {
                    commit('SET_STATS_SESSION', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getStatsPlanif({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            PlanificationService.getStatsPlanif(params.session_id, params.payload)
                .then((response) => {
                    commit('REFRESH_STATS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getStatsSerie({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            PlanificationService.getStatsPlanif(params.session_id, params.payload)
                .then((response) => {
                    commit('REFRESH_STATS_GLOBALES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    planifier({ commit }: { commit: any }, params: any) {
        commit('MAJ_INFO_AVANCEMENT', '')
        commit('MAJ_STATE_PLANIFICATION', EtatPlanificationAuto.EN_COURS)
        return new Promise((resolve, reject) => {
            PlanificationService.planifier(params.session_id, params.payload)
                .then((response) => {
                    commit('SET_HISTORIQUE_EN_COURS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    arreter({ commit }: { commit: any }) {
        return new Promise((resolve, reject) => {
            PlanificationService.arreter()
                .then((response) => {
                    commit('MAJ_ARRET', true)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deletePlanif({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            PlanificationService.deletePlanif(params.session_id, params.payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getAvancement({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            PlanificationService.getAvancement(params.histo_planif_id)
                .then((response) => {
                    commit('REFRESH_STATS', response.data.data.stats)
                    commit('REFRESH_STATS_ARRET', response.data.data.arret)
                    commit('REFRESH_STATS_ETAT', response.data.data.etat)
                    commit('MAJ_INFO_AVANCEMENT', response.data.data.info)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    validateSerie({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            PlanificationService.validateSerie(params.serie_id, params.payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getReglesSession({ commit }: { commit: any }) {
        return new Promise((resolve, reject) => {
            PlanificationService.getReglesSession()
                .then((response) => {
                    commit('SET_REGLES_SESSION', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    saveReglesSession({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            PlanificationService.updateReglesSession(params.session_id, params.payload)
                .then((response) => {
                    commit('SET_REGLES_SESSION', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR_REGLES', getError(error))
                    reject(error)
                })
        })
    },
    getActionsHisto({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            PlanificationService.getActionsHisto(params.histo_id)
                .then((response) => {
                    commit('MAJ_ACTIONS_HISTO_EN_COURS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR_REGLES', getError(error))
                    reject(error)
                })
        })
    },
    getEtendue({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING_GRAPH', true)
        return new Promise((resolve, reject) => {
            PlanificationService.getEtendue(params.serie_id)
                .then((response) => {
                    commit('SET_ETENDUE_TROUS', response.data.data)
                    commit('SET_LOADING_GRAPH', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    commit('SET_LOADING_GRAPH', false)
                    reject(error)
                })
        })
    }
}

export const getters = {
    get_regles_sessions: (state: any) => {
        return state.regles_session
    },
    get_header_params: (state: any) => {
        const headers: { [index: string]: any } = {}

        // Construction de la ligne des headers
        for (const i in state.regles_session) {
            if (state.regles_session[i].json.type === '+' && state.regles_session[i].json.dest !== 'manu') {
                headers[state.regles_session[i].json.dest] = {
                    id: state.regles_session[i].json.dest,
                    name: state.regles_session[i].json.destName
                }
            }
        }

        return headers
    },
    get_lignes_params: (state: any) => {
        const lignes: { [index: string]: any } = {}

        // Construction des lignes de regles
        for (const i in state.regles_session) {
            if (state.regles_session[i].json.type === '+' && state.regles_session[i].json.dest !== 'manu') {
                if (!lignes[state.regles_session[i].json.org]) {
                    lignes[state.regles_session[i].json.org] = {
                        id: state.regles_session[i].json.org,
                        name: state.regles_session[i].json.orgName,
                        dest: []
                    }
                }

                // Add la dest dans le tableau des dest de la lignes
                lignes[state.regles_session[i].json.org].dest.push({
                    cle: state.regles_session[i].cle,
                    id: state.regles_session[i].json.dest,
                    name: state.regles_session[i].json.destName,
                    value_min: state.regles_session[i].json.delta,
                    value_opti: state.regles_session[i].json.best
                })
            }
        }

        return lignes
    },
    prepare_regles_minimum: (state: any) => (lignes: any) => {
        const collec_temps = state.regles_session
        for (const i in collec_temps) {
            for (const l in lignes) {
                for (const d in lignes[l].dest) {
                    if (collec_temps[i].json.org === Number(l) && collec_temps[i].json.dest === lignes[l].dest[d].id) {
                        const temp = collec_temps[i]
                        temp.json.delta = lignes[l].dest[d].value_min
                        Vue.set(collec_temps, i, temp)
                    }
                }
            }
        }

        state.regles_session = collec_temps
    },
    prepare_regles_optimum: (state: any) => (lignes: any) => {
        const collec_temps = state.regles_session
        for (const i in collec_temps) {
            for (const l in lignes) {
                for (const d in lignes[l].dest) {
                    if (collec_temps[i].json.org === Number(l) && collec_temps[i].json.dest === lignes[l].dest[d].id) {
                        const temp = collec_temps[i]
                        temp.json.best = lignes[l].dest[d].value_opti
                        Vue.set(collec_temps, i, temp)
                    }
                }
            }
        }

        state.regles_session = collec_temps
    },
    get_ecart_regles: (state: any) => (opti: boolean) => {
        const ecart_regles = []
        for (const i in state.regles_session) {
            if (opti) {
                if (state.regles_session[i].json.type === 't') {
                    ecart_regles.push(state.regles_session[i])
                }
            } else {
                if (state.regles_session[i].json.dest === 'manu' || state.regles_session[i].json.dest === '*') {
                    ecart_regles.push(state.regles_session[i])
                }
            }
        }
        return ecart_regles
    },
    get_nb_jours_chevauchement: (state: any) => {
        let count_before = 0
        let count_after = 0

        for (const e in state.dico_epreuve_corrections) {
            if (state.dico_epreuve_corrections[e].jour_chevauchement_avant > count_before) {
                count_before = state.dico_epreuve_corrections[e].jour_chevauchement_avant
            }
            if (state.dico_epreuve_corrections[e].jour_chevauchement_apres > count_after) {
                count_after = state.dico_epreuve_corrections[e].jour_chevauchement_apres
            }
        }

        return { jours_avant: count_before, jour_apres: count_after }
    },
    getHistoriqueById: (state: any) => (id: number) => {
        const histo_temp = state.historiques.find((h: any) => h.id === id)
        return histo_temp
    },
    getSelectCreneauxDisposMulti: (state: any) => (series: any) => {
        const tab_temp: any = {}
        for (const s in state.creneaux_dispos) {
            for (const j in state.creneaux_dispos[s]) {
                const key =
                    series.find((serie: any) => serie.id === Number(s)).name + ' - ' + getWeekDayWithDayMonthYear(j)
                if (!tab_temp[key]) {
                    tab_temp[key] = []
                }

                tab_temp[key] = state.creneaux_dispos[s][j]

                const p_am = 'AM'
                const p_pm = 'PM'
                if (state.creneaux_dispos[s][j][p_am].length !== 0) {
                    tab_temp[key].showDispoAM = true
                } else {
                    tab_temp[key].showDispoAM = false
                }
                if (state.creneaux_dispos[s][j][p_pm].length !== 0) {
                    tab_temp[key].showDispoPM = true
                } else {
                    tab_temp[key].showDispoPM = false
                }
            }
        }

        state.creneaux_dispos_multi = tab_temp
    },
    setJoursMultiSeries: (state: any) => (series: any, candidat_seances: any, with_c: boolean) => {
        state.jours_multi_series = {}
        const tab_seances: { [index: string]: any } = {}
        const chevauchement = store.getters['planification/get_nb_jours_chevauchement']

        for (const s in series) {
            let serie_temps: any = null
            if (with_c) {
                serie_temps = store.getters['disponibilite/create_jours_serie_with_chevauchement'](
                    series[s],
                    chevauchement.jours_avant,
                    chevauchement.jours_apres
                )
            } else {
                serie_temps = store.getters['disponibilite/create_jours_serie_with_chevauchement'](series[s], 0, 0)
            }
            tab_seances[series[s].id] = serie_temps

            for (const t in serie_temps) {
                tab_seances[series[s].id][t] = {
                    AM: [],
                    PM: []
                }
            }
        }

        // Réparti les séances planifiées dans la grille des semainiers
        for (const j in candidat_seances) {
            for (const se in candidat_seances[j]) {
                for (const p in candidat_seances[j][se]) {
                    // Si la séance est planifiée
                    if (candidat_seances[j][se][p].creneau_debut !== null) {
                        const serie_id: string = candidat_seances[j][se][p].serie_id
                        const jour: string = candidat_seances[j][se][p].jour
                        const periode: string = candidat_seances[j][se][p].periode
                        tab_seances[serie_id][jour][periode].push(candidat_seances[j][se][p])
                    }
                }
            }
        }

        state.jours_multi_series = tab_seances
    },
    getLibelleParams: () => (params: any) => {
        return formatteParametresHistorique(params)
    }
}
