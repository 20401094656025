import { getError } from '@/utils/helpers'
import BonusCandidatService from '@/services/BonusCandidatService'
import { BonusCandidatInterface, StateBonusCandidats } from '@/types/BonusCandidat'

export const namespaced = true

function setBonusCandidats(commit: Function, response: any) {
    commit('SET_MATIERES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateBonusCandidats = {
    bonusCandidats: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    bonusCandidatSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateBonusCandidats) {
        Object.assign(state, {
            bonusCandidats: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            bonusCandidatSelect: {}
        })
    },
    SET_MATIERES(state: any, bonusCandidats: Array<BonusCandidatInterface>) {
        state.bonusCandidats = bonusCandidats
    },
    SET_MORE_MATIERES(state: any, bonusCandidats: Array<BonusCandidatInterface>) {
        state.bonusCandidats = state.bonusCandidats.concat(bonusCandidats)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_MATIERE_SELECT(state: any, bonusCandidat: BonusCandidatInterface) {
        state.bonusCandidatSelect = bonusCandidat
    }
}

export const actions = {
    getBonusCandidats({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            BonusCandidatService.getBonusCandidats(params)
                .then((response) => {
                    setBonusCandidats(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getBonusCandidat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            BonusCandidatService.getBonusCandidat(params.bonusCandidat_id)
                .then((response) => {
                    commit('SET_MATIERE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreBonusCandidats({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            BonusCandidatService.getBonusCandidat(params.bonusCandidat_id)
                .then((response) => {
                    commit('SET_MATIERE_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateBonusCandidat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            BonusCandidatService.updateBonusCandidat(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addBonusCandidat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            BonusCandidatService.addBonusCandidat(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteBonusCandidat({ commit }: { commit: any }, bonusCandidat_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            BonusCandidatService.deleteBonusCandidat(bonusCandidat_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    bonusCandidats: (state: any) => {
        return state.bonusCandidats
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.bonusCandidats.length
    },
    bonusCandidatSelect: (state: any) => {
        return state.bonusCandidatSelect
    },
    getBonusCandidatById: (state: any) => (id: number) => {
        const bonusCandidat = state.bonusCandidats.filter((mat: any) => mat.id === id)[0]
        return bonusCandidat
    },
    getCodeBonusCandidat: (state: any) => (id: number) => {
        const bonusCandidat = state.bonusCandidats.filter((mat: any) => mat.id === id)[0]
        if (bonusCandidat) {
            return bonusCandidat.code
        } else {
            return 'NR'
        }
    },
    getBonusCandidatsObs: (state: any) => (liste_bonusCandidat_obs: any) => {
        const tab_bonusCandidats: Array<any> = []
        for (const mo in liste_bonusCandidat_obs) {
            if (liste_bonusCandidat_obs[mo].entity_id) {
                const bonusCandidat_temp = state.bonusCandidats.find(
                    (m: any) => m.id === liste_bonusCandidat_obs[mo].entity_id
                )
                if (bonusCandidat_temp) {
                    tab_bonusCandidats.push(bonusCandidat_temp)
                }
            }
        }

        return tab_bonusCandidats
    }
}
