import { getError } from '@/utils/helpers'
import FournitureAdministrativeService from '@/services/FournitureAdministrativeService'
import { FournitureAdministrativeInterface, StateFournitureAdministratives } from '@/types/FournitureAdministrative'

export const namespaced = true

function setFournitureAdministratives(commit: Function, response: any) {
    commit('SET_FOURNITURE_ADMINISTRATIVES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateFournitureAdministratives = {
    fournituresAdministratives: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    fournituresAdministrativeSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateFournitureAdministratives) {
        Object.assign(state, {
            fournituresAdministratives: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            fournitureAdministrativeSelect: {}
        })
    },
    SET_FOURNITURE_ADMINISTRATIVES(state: any, fournituresAdministratives: Array<FournitureAdministrativeInterface>) {
        state.fournituresAdministratives = fournituresAdministratives
    },
    SET_MORE_FOURNITURE_ADMINISTRATIVES(
        state: any,
        fournituresAdministratives: Array<FournitureAdministrativeInterface>
    ) {
        state.fournituresAdministratives = state.fournituresAdministratives.concat(fournituresAdministratives)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_FOURNITURE_ADMINISTRATIVE_SELECT(state: any, fournitureAdministrative: FournitureAdministrativeInterface) {
        state.fournitureAdministrativeSelect = fournitureAdministrative
    }
}

export const actions = {
    getFournitureAdministratives({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FournitureAdministrativeService.getFournitureAdministratives(params)
                .then((response) => {
                    setFournitureAdministratives(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getFournitureAdministrative({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FournitureAdministrativeService.getFournitureAdministrative(params.fournitureAdministrative_id)
                .then((response) => {
                    commit('SET_FOURNITURE_ADMINISTRATIVE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreFournitureAdministratives({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FournitureAdministrativeService.getFournitureAdministrative(params.fournitureAdministrative_id)
                .then((response) => {
                    commit('SET_FOURNITURE_ADMINISTRATIVE_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateFournitureAdministrative({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FournitureAdministrativeService.updateFournitureAdministrative(params.fa_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addFournitureAdministrative({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FournitureAdministrativeService.addFournitureAdministrative(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteFournitureAdministrative({ commit }: { commit: any }, fournitureAdministrative_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FournitureAdministrativeService.deleteFournitureAdministrative(fournitureAdministrative_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPDFFournitureAdministrative({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FournitureAdministrativeService.getPDFFournitureAdministrative(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    setFournitureAdministrativesImpressionBac({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FournitureAdministrativeService.setFournitureAdministrativesImpressionBac(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    setConsultationFournituresAdministratives({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FournitureAdministrativeService.setConsultationFournituresAdministratives(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getImpressionFournitureAdministrativeCentres({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FournitureAdministrativeService.getImpressionFournitureAdministrativeCentres(params.centre_id, params.params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getImpressionFournitureAdministrativeFournitures({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FournitureAdministrativeService.getImpressionFournitureAdministrativeFournitures(params.fourniture_id, params.params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    fournituresAdministratives: (state: any) => {
        return state.fournituresAdministratives
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.fournituresAdministratives.length
    },
    fournitureAdministrativeSelect: (state: any) => {
        return state.fournituresAdministratives
    },
    getFournitureAdministrativeById: (state: any) => (id: number) => {
        const fournitureAdministrative = state.fournituresAdministratives.filter((mat: any) => mat.id === id)[0]
        return fournitureAdministrative
    },
    getCodeFournitureAdministrative: (state: any) => (id: number) => {
        const fournitureAdministrative = state.fournituresAdministratives.filter((mat: any) => mat.id === id)[0]
        if (fournitureAdministrative) {
            return fournitureAdministrative.code
        } else {
            return 'NR'
        }
    },
    getFournitureAdministrativesObs: (state: any) => (liste_fournitureAdministrative_obs: any) => {
        const tab_fournitureAdministratives: Array<any> = []
        for (const mo in liste_fournitureAdministrative_obs) {
            if (liste_fournitureAdministrative_obs[mo].entity_id) {
                const fournitureAdministrative_temp = state.fournituresAdministratives.find(
                    (m: any) => m.id === liste_fournitureAdministrative_obs[mo].entity_id
                )
                if (fournitureAdministrative_temp) {
                    tab_fournitureAdministratives.push(fournitureAdministrative_temp)
                }
            }
        }

        return tab_fournitureAdministratives
    }
}
