import { getError } from '@/utils/helpers'
import DecisionAmenagementService from '@/services/DecisionAmenagementService'
import {DecisionAmenagementInterface} from "@/types/DecisionAmenagement";

export const namespaced = true

export const state: any = {
    decisions: [] as DecisionAmenagementInterface[],
    decision: {} as DecisionAmenagementInterface,
    loading: false,
    error: null
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            decisions: [] as DecisionAmenagementInterface[],
            decision: {} as DecisionAmenagementInterface,
            loading: false,
            error: null
        })
    },
    SET_DECISIONS(state: any, response: any): void {
        let decisions: DecisionAmenagementInterface[] = [] as DecisionAmenagementInterface[]
        if (response && response.data && response.data.data) {
            decisions = response.data.data
        }

        Object.freeze(decisions)
        state.dossiers = decisions
    },
    SET_DECISION(state: any, response: any): void {
        let decision: DecisionAmenagementInterface = {} as DecisionAmenagementInterface
        if (response && response.data && response.data.data) {
            decision = response.data.data
        }

        Object.freeze(decision)
        state.dossiers = decision
    },
    ADD_DECISION(state: any, response: any) {
        let decision: DecisionAmenagementInterface = {} as DecisionAmenagementInterface
        if (response && response.data && response.data.data) {
            decision = response.data.data
        }

        Object.freeze(decision)
        state.decision = decision

        const decisions = JSON.parse(JSON.stringify(state.decisions))
        decisions.push(decision)
        Object.freeze(decisions)
        state.decisions = decisions
    },
    UPDATE_DECISION(state: any, response: any) {
        let decision: DecisionAmenagementInterface = {} as DecisionAmenagementInterface
        if (response && response.data && response.data.data) {
            decision = response.data.data
        }

        Object.freeze(decision)
        state.decision = decision

        const decisions = JSON.parse(JSON.stringify(state.decisions))
        const index = decisions.findIndex((d: DecisionAmenagementInterface) => d.id === decision.id)
        if (index !== -1) {
            decisions[index] = decision
        }
        Object.freeze(decisions)
        state.decisions = decisions
    },
    DELETE_DECISION(state: any, response: any) {
        let decision: DecisionAmenagementInterface = {} as DecisionAmenagementInterface
        if (response && response.data && response.data.data) {
            decision = response.data.data
        }

        Object.freeze(decision)
        state.decision = decision

        const decisions = JSON.parse(JSON.stringify(state.decisions))
        const index = decisions.findIndex((d: DecisionAmenagementInterface) => d.id === decision.id)
        if (index !== -1) {
            decisions.splice(index, 1)
        }
        Object.freeze(decisions)
        state.decisions = decisions
    },
    SET_LOADING(state: any, boolean: boolean): void {
        Object.freeze(boolean)
        state.loading = boolean
    },
    SET_ERROR(state: any, error: any): void {
        if (error) {
            error = getError(error)
        }
        Object.freeze(error)
        state.error = error
    }
}

export const actions = {
    getDecisions({ commit }: { commit: any }, params: { payload: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DecisionAmenagementService.getDecisionsAmenagements(params.payload)
                .then((response) => {
                    commit('SET_DECISIONS', response)
                    commit('SET_ERROR', null)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_DECISIONS', null)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getDecision({ commit }: { commit: any }, params: { decision_id: number; payload: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DecisionAmenagementService.getDecisionAmenagements(params.decision_id, params.payload)
                .then((response) => {
                    commit('SET_DECISION', response)
                    commit('SET_ERROR', null)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_DECISION', null)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    addDecision({ commit }: { commit: any }, params: { payload: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DecisionAmenagementService.addDecisionAmenagement(params.payload)
                .then((response) => {
                    commit('ADD_DECISION', response)
                    commit('SET_ERROR', null)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_DECISION', null)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    updateDecision({ commit }: { commit: any }, params: { decision_id: number; payload: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DecisionAmenagementService.updateDecisionAmenagement(params.decision_id, params.payload)
                .then((response) => {
                    commit('UPDATE_DECISION', response)
                    commit('SET_ERROR', null)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    deleteDecision({ commit }: { commit: any }, params: { decision_id: number }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DecisionAmenagementService.deleteDecisionAmenagement(params.decision_id)
                .then((response) => {
                    commit('DELETE_DECISION', null)
                    commit('SET_ERROR', null)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

// TODO: TypeScript
export const getters = {
    get_decisions: (state: any) => {
        return state.decisions
    },
    get_decision: (state: any) => {
        return state.decision
    },
    get_loading: (state: any) => {
        return state.loading
    },
    get_error: (state: any) => {
        return state.error
    }
}
