import * as API from '@/services/API'
import store from '@/store/index'
import { GenerateFlux } from '@/types/DefinitionBarresListeNotesAdmission'

export default {
    async getDefinitionBarresListeNotesAdmission(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const concour_id = payload.concour_id
        const phase_id = payload.phase_id
        return await API.apiClient.get(
            `/sessions/${session_id}/concours/${concour_id}/phases/${phase_id}/candidatconcourphases`,
            { params: payload.params }
        )
    },
    async getHistoriqueConcourPhase(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const concourphase_id = payload.concourphase_id
        return await API.apiClient.get(`/sessions/${session_id}/concourphases/${concourphase_id}/historique`, payload)
    },
    async exportationListeResultats(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const concour_id = payload.concour_id
        const phase_id = payload.phase_id
        return await API.apiClient.get(
            `/sessions/${session_id}/concours/${concour_id}/phases/${phase_id}/candidatconcourphases_export`,
            {
                ...payload,
                headers: {
                    ...payload.headers,
                    'X-Request-Retry': 0
                }
            }
        )
    },
    async exportationListeClassesFinal(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const concour_id = payload.concour_id
        const phase_id = payload.phase_id
        return await API.apiClient.get(
            `/sessions/${session_id}/concours/${concour_id}/phases/${phase_id}/candidatconcourphases_export_pdf`,
            { params: payload, headers: { 'X-Request-Retry': 0 }}
        )
    },
    async exportationTableauRangParEquipe(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const concour_id = payload.concour_id
        const phase_id = payload.phase_id
        return await API.apiClient.get(
            `/sessions/${session_id}/concours/${concour_id}/phases/${phase_id}/candidatconcourphases_export_equipe_pdf`,
            { params: payload, headers: { 'X-Request-Retry': 0 }}
        )
    },
    async generateExport(flux: GenerateFlux, phaseId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/export_${flux}_resultats/${phaseId}`)
    }
}
