import UserService from '@/services/UserService'
import { RoleInterface, StateUsers, UserInterface } from '@/types/User'
import { getError } from '@/utils/helpers'
import _ from 'lodash'
import Vue from 'vue'

export const namespaced = true

function setUsers(commit: Function, response: any) {
    commit('SET_USERS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.meta?.total || 0)
    commit('SET_CURRENT_PAGE', response.data.meta?.current_page || 1)
    commit('SET_LAST_PAGE', response.data.meta?.last_page || 0)
    commit('SET_TOTAL_PAGE', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateUsers = {
    users: [],
    userSelect: null,
    reportingUserSelect: null,
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    sortby: '',
    abilities: [],
    dashboard: [],
    notifications: [],
    dossieracademiqueId: null,
    dossieradministratifId: null,
    has_poste_aca: null,
    centreId: null,
    centre_organisation_ecrit_validated: null,
    centreTypePassation: null,
    conventionPublished: null,
    fichesIntervenantsPublished: false,
    hasContrat: null,
    maj_notification: false,
    incompats_open: false,
    dispos_open: false,
    planning_open: false,
    roles: [],
    dependencies: []
}

export const mutations = {
    RESET_STATE(state: StateUsers) {
        Object.assign(state, {
            users: [],
            userSelect: null,
            reportingUserSelect: null,
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            sortby: '',
            abilities: [],
            dashboard: [],
            notifications: [],
            dossieracademiqueId: null,
            dossieradministratifId: null,
            has_poste_aca: null,
            centreId: null,
            centre_organisation_ecrit_validated: null,
            centreTypePassation: null,
            conventionPublished: null,
            fichesIntervenantsPublished: false,
            hasContrat: null,
            maj_notification: false,
            incompats_open: false,
            dispos_open: false,
            planning_open: false,
            roles: [],
            dependencies: []
        })
    },
    SET_USERS(state: StateUsers, users: Array<UserInterface>) {
        state.users = []
        state.users = users
    },
    SET_MORE_USERS(state: any, users: Array<UserInterface>) {
        state.users = state.users.concat(users)
    },
    SET_USER_SELECT(state: StateUsers, user: UserInterface) {
        state.userSelect = user

        if (user !== null) {
            const ids: number[] = []
            for (const a in user.abilities) {
                ids.push(user.abilities[a].id)
            }
            state.userSelect.abilities_ids = ids
        }
    },
    SET_REPORTING_USER_SELECT(state: StateUsers, reportingUser: any) {
        state.reportingUserSelect = reportingUser
    },
    SET_META(state: StateUsers, meta: Array<string>) {
        state.meta = meta
    },
    SET_LINKS(state: StateUsers, links: Array<string>) {
        state.links = links
    },
    SET_LOADING(state: StateUsers, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: StateUsers, error: Array<string>) {
        state.error = error
    },
    SET_TOTALROWS(state: StateUsers, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_SORTBY(state: any, params: string) {
        state.sortby = params
    },
    SET_ABILITIES(state: any, abilities: any) {
        state.abilities = abilities
    },
    SET_DATAS_DASHBOARD(state: any, datas: any) {
        state.dashboard = datas
    },
    SET_NOTIFICATIONS(state: any, datas: any) {
        // On travaille sur un tableau et non un objet. Le retour serveur étant désormais un objet, on le convertit en tableau.
        const notifications_array: Array<any> = []
        const keys = Object.keys(datas)
        keys.forEach((key) => {
            notifications_array.push(datas[key])
        })
        state.notifications = notifications_array
    },
    SET_MAJ_NOTIFICATIONS(state: any, datas: any) {
        // Delete des éléments s'ils existe pour refresh
        const i = state.notifications.map((item: any) => item.id).indexOf(datas.notification_id)
        const notif_temp = state.notifications[i]
        notif_temp.status = false
        if (datas.payload.status === 1) {
            notif_temp.status = true
        }
        // Push les nouvelles datas
        state.notifications[i] = notif_temp
        state.maj_notification = true
    },
    SET_USER_DATA(state: any, user: any) {
        const index = state.users.map((u: UserInterface) => u.id).indexOf(user.id)
        Vue.set(state.users, index, user)
    },
    SET_ROLES(state: any, roles: any) {
        state.roles = roles
    },
    SET_DEPENDENCIES(state: any, dependencies: any) {
        state.dependencies = dependencies
    },
    MAJ_ROLE(state: any, role: RoleInterface) {
        const index = state.roles.map((r: RoleInterface) => r.id).indexOf(role.id)
        Vue.set(state.roles, index, role)
    },
    ADD_ROLE(state: any, role: RoleInterface) {
        state.roles.push(role)
    },
    SET_INCOMPATS_OPEN(state: any, etat: boolean) {
        state.incompats_open = etat
    },
    SET_DISPOS_OPEN(state: any, etat: boolean) {
        state.dispos_open = etat
    },
    SET_PLANNING_OPEN(state: any, etat: boolean) {
        state.planning_open = etat
    },
    SET_FICHES_INTERVENANTS_PUBLISHED(state: any, etat: boolean) {
        state.fichesIntervenantsPublished = etat
    },
    ADD_USER(state: any, user: UserInterface) {
        state.users.push(user)
        state.totalRows++
        state.users = _.orderBy(state.users, 'name', 'asc')
    },
    MAJ_USER(state: any, user: UserInterface) {
        const index = state.users.map((u: UserInterface) => u.id).indexOf(user.id)
        Vue.set(state.users, index, user)
        state.users = _.orderBy(state.users, 'name', 'asc')
    },
    SET_HAS_POSTE_ACA(state: any, etat: boolean) {
        state.has_poste_aca = etat
    }
}

export const actions = {
    getUsers({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.getUsers(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    setUsers(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreUsers({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.getUsers(params)
                .then((response) => {
                    commit('SET_MORE_USERS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getUser({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.getUser(params.user_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_USER_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addUser({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.addUser(params)
                .then((response) => {
                    commit('ADD_USER', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateUser({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.updateUser(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_USER_DATA', response.data.data)
                    commit('MAJ_USER', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateSelfUser({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.updateSelfUser(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getAbilities({ commit }: { commit: any }) {
        return new Promise((resolve, reject) => {
            UserService.getAbilities()
                .then((response) => {
                    commit('SET_ABILITIES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getRoles({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.getRoles(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_ROLES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    removeOTP({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.removeOTP(params.user_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    resetPassword({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.resetPassword(params.user_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    saveComment({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.saveCommentaire(params.user_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDashboard({ commit }: { commit: any }, ability: string) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.getDashboard(ability)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_DATAS_DASHBOARD', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getNotifications({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.getNotifications()
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_NOTIFICATIONS', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateNotification({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.updateNotification(params.notification_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_MAJ_NOTIFICATIONS', params)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addRole({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)

        return new Promise((resolve, reject) => {
            UserService.addRole(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('ADD_ROLE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateRole({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.updateRole(params.role_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('MAJ_ROLE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteRole({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.deleteRole(params.role_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getRolesGCI({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.getRolesGCI(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_ROLES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addRoleGCI({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)

        return new Promise((resolve, reject) => {
            UserService.addRoleGCI(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('ADD_ROLE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateRoleGCI({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.updateRoleGCI(params.role_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('MAJ_ROLE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteRoleGCI({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.deleteRoleGCI(params.role_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getRolesEst({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.getRolesEst(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_ROLES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addRoleEst({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.addRoleEst(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('ADD_ROLE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateRoleEst({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.updateRoleEst(payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('MAJ_ROLE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteRoleEst({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.deleteRoleEst(params.role_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDependencies({ commit }: { commit: any }) {
        return new Promise((resolve, reject) => {
            UserService.getDependencies()
                .then((response) => {
                    commit('SET_DEPENDENCIES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getFicheIntervenants({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.getFicheIntervenants(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getExternUsersFromSearch({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.getExternUsersFromSearch(payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getExternUsers({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.getExternUsers(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getExternUser({ commit }: { commit: any }, payload: { user_id: number; params: any}) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.getExternUser(payload.user_id, payload.params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    editExternUser({ commit }: { commit: any }, payload: { user_id: number; data: any}) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.editExternUser(payload.user_id, payload.data)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },

    // -----ReportingUser
    getReportingUser({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.getReportingUser(params.user_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_REPORTING_USER_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getReportingUsers({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.getReportingUsers(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    setUsers(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreReportingUsers({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.getReportingUsers(params)
                .then((response) => {
                    commit('SET_MORE_USERS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addReportingUser({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)

        return new Promise((resolve, reject) => {
            UserService.addReportingUser(params)
                .then((response) => {
                    commit('ADD_USER', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateReportingUser({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.updateReportingUser(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_USER_DATA', response.data.data)
                    commit('MAJ_USER', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteReportingUser({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.deleteReportingUser(params.user_id, params.working_establishment_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    importReportingUsers({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            UserService.importReportingUsers(payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    users: (state: StateUsers) => {
        return state.users
    },
    reportingUserSelect: (state: StateUsers) => {
        return state.reportingUserSelect
    },
    meta: (state: StateUsers) => {
        return state.meta
    },
    links: (state: StateUsers) => {
        return state.links
    },
    loading: (state: StateUsers) => {
        return state.loading
    },
    error: (state: StateUsers) => {
        return state.error
    },
    totalRows: (state: StateUsers) => {
        return state.totalRows
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        if (state.users) {
            return state.users.length
        } else {
            return 0
        }
    },
    sortby: (state: any) => {
        return state.sortby
    },
    dashboard: (state: any) => {
        return state.dashboard
    },
    notifications: (state: any) => {
        return state.notifications
    },
    dossieracademiqueId: (state: any) => {
        return state.dossieracademiqueId
    },
    dossieradministratifId: (state: any) => {
        return state.dossieradministratifId
    },
    centreId: (state: any) => {
        return state.centreId
    },
    centre_organisation_ecrit_validated: (state: any) => {
        return state.centre_organisation_ecrit_validated
    },
    centreTypePassation: (state: any) => {
        return state.centreTypePassation
    },
    hasContrat: (state: any) => {
        return state.hasContrat
    },
    conventionPublished: (state: any) => {
        return state.conventionPublished
    },
    maj_notification: (state: any) => {
        return state.maj_notification
    },
    incompatsOpen: (state: any) => {
        return state.incompats_open
    },
    disposOpen: (state: any) => {
        return state.dispos_open
    },
    fichesIntervenantsPublished: (state: any) => {
        return state.fichesIntervenantsPublished
    },
    userAbilities: (state: any) => {
        return state.abilities
    },
    has_poste_aca: (state: any) => {
        return state.has_poste_aca
    },
    roles: (state: any) => {
        return state.roles
    }
}
