import { getError } from '@/utils/helpers'
import PublishableMessageService from '@/services/PublishableMessageService'
import { PublishableMessageInterface, StatePublishableMessages } from '@/types/PublishableMessage'

export const namespaced = true

function setPublishableMessages(commit: Function, response: any) {
    commit('SET_PUBLISHABLE_MESSAGES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StatePublishableMessages = {
    publishableMessages: [],
    publishables: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    publishableMessageSelect: {}
}

export const mutations = {
    RESET_STATE(state: StatePublishableMessages) {
        Object.assign(state, {
            publishableMessages: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            publishableMessageSelect: {}
        })
    },
    SET_PUBLISHABLE_MESSAGES(state: any, publishableMessages: Array<PublishableMessageInterface>) {
        state.publishableMessages = publishableMessages
        const dicoPublishable: any = {}
        for (let i = 0; i < state.publishableMessages.length; i++) {
            if (!dicoPublishable[state.publishableMessages[i].publishable.id]) {
                dicoPublishable[state.publishableMessages[i].publishable.id] = {
                    publishable: state.publishableMessages[i].publishable,
                    publishableMessages: []
                }
            }
            dicoPublishable[state.publishableMessages[i].publishable.id].publishableMessages.push(
                state.publishableMessages[i]
            )
        }
        state.publishables = []
        for (const index in dicoPublishable) {
            state.publishables.push(dicoPublishable[index])
        }
    },
    SET_MORE_PUBLISHABLE_MESSAGES(state: any, publishableMessages: Array<PublishableMessageInterface>) {
        state.publishableMessages = state.publishableMessages.concat(publishableMessages)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_PUBLISHABLE_MESSAGE_SELECT(state: any, publishableMessage: PublishableMessageInterface) {
        state.publishableMessageSelect = publishableMessage
    }
}

export const actions = {
    getPublishableMessages({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PublishableMessageService.getPublishableMessages(params)
                .then((response) => {
                    setPublishableMessages(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPublishableMessage({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PublishableMessageService.getPublishableMessage(params.publishableMessage_id)
                .then((response) => {
                    commit('SET_PUBLISHABLE_MESSAGE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMorePublishableMessages({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PublishableMessageService.getPublishableMessage(params.publishableMessage_id)
                .then((response) => {
                    commit('SET_PUBLISHABLE_MESSAGE_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updatePublishableMessage({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PublishableMessageService.updatePublishableMessage(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addPublishableMessage({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PublishableMessageService.addPublishableMessage(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deletePublishableMessage({ commit }: { commit: any }, publishableMessage_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            PublishableMessageService.deletePublishableMessage(publishableMessage_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    publishableMessages: (state: any) => {
        return state.publishableMessages
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.publishableMessages.length
    },
    publishableMessageSelect: (state: any) => {
        return state.publishableMessageSelect
    },
    getPublishableMessageById: (state: any) => (id: number) => {
        const publishableMessage = state.publishableMessages.filter((mat: any) => mat.id === id)[0]
        return publishableMessage
    },
    getCodePublishableMessage: (state: any) => (id: number) => {
        const publishableMessage = state.publishableMessages.filter((mat: any) => mat.id === id)[0]
        if (publishableMessage) {
            return publishableMessage.code
        } else {
            return 'NR'
        }
    },
    publishables: (state: any) => {
        return state.publishables
    },
    publishableMessages_by_pid: (state: any) => (publishable_id: number) => {
        return state.publishableMessages.filter((pm: any) => pm.publishable_id === publishable_id)
    },
    getPublishableMessage_by_id: (state: any) => (id: number) => {
        return state.publishableMessages.filter((pm: any) => pm.id === id)[0]
    }
}
