// affectationresponsables
import * as API from '@/services/API'
import store from '@/store/index'

export default {
    async getAffectationResponsable(affectationResponsableId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/affectationresponsables/${affectationResponsableId}`)
    },
    async getAffectationResponsables(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/affectationresponsables`, { params: payload })
    },
    async synchViatique() {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/affectationresponsables`)
    },
    async addAffectationResponsable(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/affectationresponsables`, payload)
    },
    async deleteAffectationResponsable(user_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/affectationsresponsables/${user_id}`)
    },
    getUsersFromSearch(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/affectationresponsables/users_search`, payload)
    },
    addDossierUser(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/affectationsresponsables/create`, payload)
    }
}
