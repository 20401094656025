import {getError} from '@/utils/helpers'
import DefinitionDesBarresStatistiquesConcours from '@/services/DefinitionDesBarresStatistiquesConcoursService'
import {StateDefinitionDesBarresStatistiquesConcours} from '@/types/DefinitionDesBarresStatistiquesConcours'

export const namespaced = true

export const state: StateDefinitionDesBarresStatistiquesConcours = {
    infosStatistiquesConcours: [],
    infosStatConcoursTrie: [],
    graphIsLoading: false,
    error: null
}

export const mutations = {
    RESET_STATE(state: StateDefinitionDesBarresStatistiquesConcours): void {
        Object.assign(state, {
            infosStatistiquesConcours: [],
            graphIsLoading: false
        })
    },
    SET_INFOS_STATISTIQUES_CONCOURS(state: StateDefinitionDesBarresStatistiquesConcours, infosStatistiquesConcours: any): void {
        state.infosStatistiquesConcours = infosStatistiquesConcours
    },
    SET_LOADING(state: StateDefinitionDesBarresStatistiquesConcours, isLoading: boolean): void {
        state.graphIsLoading = isLoading
    },
    SET_ERROR(state: StateDefinitionDesBarresStatistiquesConcours, error: Array<any>): void {
        state.error = error
    }
}

export const actions = {
    getInfosStatistiquesConcours({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            DefinitionDesBarresStatistiquesConcours.getDefinitionDesBarresStatistiquesConcours(payload)
                .then((response): void => {
                    commit('SET_INFOS_STATISTIQUES_CONCOURS', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    // tableau non trié, utiliser pour watch les changements
    stateInfosStatistiquesConcours: (state: StateDefinitionDesBarresStatistiquesConcours): any => {
        return state.infosStatistiquesConcours
    },

    graphIsLoading: (state: StateDefinitionDesBarresStatistiquesConcours): boolean => {
        return state.graphIsLoading
    }
}
