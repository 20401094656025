/* eslint-disable @typescript-eslint/no-unused-vars */
import store from '@/store/index'
import { Ability } from '@/types/Ability'
import { Menu, MenuNiveau1 } from '@/types/Menu'
import { TypePassation } from './Epreuve'

/* **********************************************************
 **** Gestion des élements du menu **************************
 **** id: utilisé pour bootstrap accordion ******************
 **** dataBsParent : id de la balise principale du menu *****
 **** childElement : true s'il y a un sous menu ************
 **** Le premier et le dernier element du menu se ************
 *************************************************************/

const dashboard: Menu = {
    name: 'TABLEAU DE BORD',
    id: 'dashboard',
    auth: [
        Ability.RH_ADM_DOS_OWN_MANAGE,
        Ability.RH_SPS_DOS_OWN_MANAGE,
        Ability.GCI_DESCR_OWN_VIEW,
        Ability.RH_FICHEHORAIRE_OWN_SIGN,
        Ability.INTERV_PLANIF_OWN,
        Ability.INTERV_SUPPLEANT_OWN,
        Ability.GCI_CONVENTION_OWN_VIEW,
        Ability.GCI_ANNEXE_CONVENTION_OWN_VIEW,
        Ability.GCI_ORGA_OWN_VIEW,
        Ability.GCI_SAISIE_INTERV_OWN_VIEW,
        Ability.GCI_FICHES_HORAIRES_OWN_VIEW,
        Ability.GCI_SUIVI_FICHES_HORAIRES_OWN_VIEW,
        Ability.GCI_DESCR_OWN_VIEW,
        Ability.GCI_INFOS_OWN_VIEW,
        Ability.GCI_EXAMINATEURS_OWN_VIEW,
        Ability.GCI_CANDIDATS_OWN_VIEW,
        Ability.GCI_IMPRESSIONS_OWN_VIEW,
        Ability.GCI_PLANNINGS_OWN_VIEW,
        Ability.GCI_DEMISSIONNAIRES_OWN_VIEW,
        Ability.ADM_ESTABLISHMENT_VIEW,
        Ability.EST_ESTABLISHMENT_PARENT_OWN_VIEW,
        Ability.EST_ESTABLISHMENT_OWN_VIEW,
        Ability.EST_INTERV_OWN_VIEW,
        Ability.EST_JOB_OWN_VIEW,
        Ability.EST_CANDIDACY_OWN_VIEW
    ],
    icon: 'home',
    dataBsParent: '#cmp-nav-left',
    childElement: false,
    topElement: true,
    actif: true,
    path: '/dashboard_i'
}

const deliberations: Menu = {
    name: 'DÉLIBÉRATIONS',
    id: 'deliberations',
    auth: [Ability.DLB_PREPJURY_VIEW],
    icon: 'calculator-alt',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    actif: true,
    sousMenu: [
        {
            subName: 'Ajustement des notes',
            auth: [Ability.DLB_PREPJURY_VIEW],
            subIcon: 'abacus',
            actif: true,
            subPath: '/liste_epreuve_correction_resultats'
        },
        {
            subName: 'Préparation des jurys',
            auth: [Ability.DLB_PREPJURY_VIEW],
            subIcon: 'copy',
            actif: false,
            subPath: '/'
        },
        {
            subName: 'Définition des barres',
            auth: [Ability.DLB_PREPJURY_VIEW],
            subIcon: 'greater-than-equal',
            actif: true,
            subPath: '/definition_des_barres'
        },
        {
            subName: 'Publication des notes',
            auth: [Ability.DLB_PREPJURY_VIEW],
            subIcon: 'copy',
            actif: false,
            subPath: '/'
        },
        {
            subName: 'Exportations',
            auth: [Ability.DLB_PREPJURY_VIEW],
            subIcon: 'copy',
            actif: false,
            subPath: '/'
        }
    ]
}
const bourseEmploiOwn: Menu = {
    type: 'OWN',
    name: 'BOURSE EMPLOI',
    id: 'bourseEmploi',
    topElement: true,
    auth: [
        Ability.ADM_ESTABLISHMENT_VIEW,
        Ability.EST_CANDIDACY_OWN_VIEW,
        Ability.EST_ESTABLISHMENT_OWN_VIEW,
        Ability.EST_ESTABLISHMENT_PARENT_OWN_VIEW,
        Ability.EST_INTERV_OWN_VIEW,
        Ability.EST_JOB_OWN_VIEW
    ],
    icon: 'handshake',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    actif: true,
    sousMenu: [
        {
            subName: 'Types d\'établissement',
            auth: [Ability.ADM_ESTABLISHMENT_VIEW],
            subIcon: 'cogs',
            actif: true,
            subPath: '/bourse_emploi/types_etablissement'
        },
        {
            subName: 'Établissements',
            auth: [Ability.ADM_ESTABLISHMENT_VIEW, Ability.EST_ESTABLISHMENT_PARENT_OWN_VIEW, Ability.EST_ESTABLISHMENT_OWN_VIEW, Ability.EST_INTERV_OWN_VIEW, Ability.EST_JOB_OWN_VIEW, Ability.EST_CANDIDACY_OWN_VIEW],
            subIcon: 'school',
            actif: true,
            subPath: '/bourse_emploi/etablissements'
        },
        {
            subName: 'Utilisateurs',
            auth: [Ability.ADM_ESTABLISHMENT_VIEW, Ability.EST_INTERV_OWN_VIEW],
            subIcon: 'users',
            actif: true,
            subPath: '/bourse_emploi/utilisateurs'
        },
        {
            subName: 'Postes',
            auth: [Ability.ADM_ESTABLISHMENT_VIEW, Ability.EST_JOB_OWN_VIEW],
            subIcon: 'file-alt',
            actif: true,
            subPath: '/bourse_emploi/postes'
        },
        {
            subName: 'Candidatures',
            auth: [Ability.ADM_ESTABLISHMENT_VIEW, Ability.EST_CANDIDACY_OWN_VIEW],
            subIcon: 'user',
            actif: true,
            subPath: '/bourse_emploi/candidatures'
        },
        {
            subName: 'Formulaires',
            auth: [Ability.ADM_ESTABLISHMENT_VIEW],
            subIcon: 'file-signature',
            actif: true,
            subPath: '/bourse_emploi/formulaires'
        }
    ]
}

const configurations: Menu = {
    name: 'CONFIGURATIONS',
    id: 'CONFIGURATIONS',
    auth: [Ability.ADM_INI_VIEW, Ability.GC_CENTER_VIEW, Ability.ADM_PAR_VIEW, Ability.ADM_UTIL_VIEW],
    icon: 'cog',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    bottomElement: true,
    actif: true,
    sousMenu: [
        {
            subName: 'Initialisation',
            auth: [Ability.ADM_INI_VIEW],
            subIcon: 'calendar-day',
            actif: true,
            subPath: '/sessions'
        },
        {
            subName: 'Postes',
            auth: [Ability.ADM_INI_VIEW],
            subIcon: 'chalkboard-teacher',
            actif: true,
            subPath: '/postes'
        },
        /*
        {
            subName: 'Matières',
            auth: [Ability.ADM_INI_VIEW],
            subIcon: 'tags',
            actif: true,
            subPath: '/matieres'
        },
        */
        {
            subName: 'Établissements',
            auth: [Ability.ADM_INI_VIEW],
            subIcon: 'building',
            actif: true,
            subPath: '/etablissements'
        },
        {
            subName: 'Villes',
            auth: [Ability.GC_CENTER_VIEW],
            subIcon: 'city',
            actif: true,
            subPath: '/villes'
        },
        {
            subName: 'Modèles de notification',
            auth: [Ability.ADM_PAR_VIEW],
            subIcon: 'bell',
            actif: true,
            subPath: '/notificationTemplates'
        },
        {
            subName: 'Modèles de réponses aux réclamations',
            auth: [Ability.ADM_PAR_VIEW],
            subIcon: 'hand-paper',
            actif: true,
            subPath: '/reclamationTemplates'
        },
        {
            subName: 'RGPD',
            auth: [Ability.ADM_PAR_VIEW],
            subIcon: 'user-shield',
            actif: true,
            subPath: '/etatDataRgpd'
        },
        {
            subName: 'Utilisateurs',
            auth: [Ability.ADM_UTIL_VIEW],
            subIcon: 'user-friends',
            actif: true,
            subPath: '/users'
        }
    ]
}

// Ajout des menus pour les intervenants
const dossieAcademiqueIntervenant: Menu = {
    type: 'OWN',
    name: 'DOSSIER ACADÉMIQUE',
    id: 'dossierAcademiqueIntervenant',
    auth: [Ability.RH_SPS_DOS_OWN_MANAGE, Ability.INTERV_OBSERV_MATIERE_OWN],
    icon: 'graduation-cap',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    actif: true,
    sousMenu: [
        {
            subName: 'Dossier de candidature',
            auth: [Ability.RH_SPS_DOS_OWN_MANAGE, Ability.INTERV_OBSERV_MATIERE_OWN],
            subIcon: 'id-badge',
            actif: true,
            subPath: '/dossier_academique'
        }
    ]
}

const dossieAdministratifIntervenant: Menu = {
    type: 'OWN',
    name: 'DOSSIER ADMINISTRATIF',
    id: 'dossierAdministratifIntervenant',
    auth: [Ability.RH_ADM_DOS_OWN_MANAGE],
    icon: 'folder-open',
    dataBsParent: '#cmp-nav-left',
    childElement: false,
    topElement: true,
    actif: true,
    path: '/dossier_administratif'
}

const groupesIntervenants: Menu = {
    type: 'OWN',
    name: 'GROUPES INTERVENANTS',
    id: 'groupesIntervenants',
    auth: [Ability.RH_SPS_DOS_OWN_MANAGE, Ability.RH_ADM_DOS_OWN_MANAGE],
    icon: 'users',
    dataBsParent: '#cmp-nav-left',
    childElement: false,
    topElement: true,
    actif: !store.getters['auth/can'](Ability.RH_SPS_VIEW),
    path: '/groupes_intervenants'
}

const contratsIntervenant: Menu = {
    type: 'OWN',
    name: 'CONTRATS',
    id: 'contratsIntervenant',
    auth: [Ability.RH_ADM_DOS_OWN_MANAGE],
    icon: 'file-contract',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    actif: true,
    sousMenu: [
        {
            subName: 'Contrats',
            auth: [Ability.RH_ADM_DOS_OWN_MANAGE],
            subIcon: 'file-contract',
            actif: true,
            subPath: '/contrats_intervenant'
        },
        {
            subName: 'Ordres de mission',
            auth: [Ability.RH_ADM_DOS_OWN_MANAGE],
            subIcon: 'subway',
            actif: store.getters['auth/findParameter']('ordreMissionDisabled')?.value !== '1',
            subPath: '/ordres_mission_intervenant'
        },
        {
            subName: 'Demandes de remboursement',
            auth: [Ability.RH_ADM_DOS_OWN_MANAGE],
            subIcon: 'hand-holding-usd',
            actif: store.getters['auth/findParameter']('ordreMissionDisabled')?.value !== '1',
            subPath: '/demandes_remboursement_intervenant'
        }
    ]
}

// Ajout des menus pour les CDC

const CDC_CENTRE: Menu = {
    type: 'OWN',
    name: 'MON CENTRE',
    id: 'own_centre',
    auth: [Ability.GCI_DESCR_OWN_VIEW, Ability.GCI_INFOS_OWN_VIEW],
    icon: 'school',
    dataBsParent: '#cmp-nav-left',
    topElement: true,
    actif: true,
    childElement: true,
    sousMenu: [
        {
            subName: 'Description et contacts',
            auth: [Ability.GCI_DESCR_OWN_VIEW],
            subIcon: 'info-circle',
            actif: true,
            subPath: '/centres/0/description'
        },
        {
            subName: 'Gestion des informations',
            auth: [Ability.GCI_INFOS_OWN_VIEW],
            subIcon: 'map-marker-alt',
            actif: true,
            subPath: '/centres/0/informations'
        }
    ]
}

const CDC_ECRIT: Menu = {
    type: 'OWN',
    name: 'ORGANISATION DES ECRITS',
    id: 'own_ecrit',
    auth: [Ability.GCI_ORGA_OWN_VIEW, Ability.GCI_FOURNITURES_OWN_VIEW],
    icon: 'pen',
    dataBsParent: '#cmp-nav-left',
    topElement: true,
    actif: true,
    childElement: true,
    sousMenu: [
        {
            subName: 'Gestion des salles',
            auth: [Ability.GCI_ORGA_OWN_VIEW],
            subIcon: 'door-closed',
            actif: true,
            subPath: '/centres/0/organisationecrit/salles'
        },
        {
            subName: "Gestion du nombre d'intervenants",
            auth: [Ability.GCI_ORGA_OWN_VIEW],
            subIcon: 'users',
            actif: true,
            subPath: '/centres/0/organisationecrit/nb_intervenants'
        },
        {
            subName: 'Liste des candidats',
            auth: [Ability.GCI_ORGA_OWN_VIEW],
            subIcon: 'graduation-cap',
            actif: true,
            subPath: '/centres/0/candidats_ecrits'
        },
        {
            subName: 'Indisponibilités de réception de livraison',
            auth: [Ability.GCI_FOURNITURES_OWN_VIEW],
            subIcon: 'dolly',
            actif: true,
            subPath: '/centres/0/indisponibilites_reception'
        },
        {
            subName: 'Inventaire des fournitures papetières',
            auth: [Ability.GCI_FOURNITURES_OWN_VIEW],
            subIcon: 'ballot-check',
            actif: true,
            subPath: '/centres/0/inventaire_fp'
        },
        {
            subName: 'Réception des sujets',
            auth: [Ability.GCI_FOURNITURES_OWN_VIEW],
            subIcon: 'file-check',
            actif: true,
            subPath: '/centres/0/reception_sujets'
        },
        {
            subName: 'Réception des fournitures papetières',
            auth: [Ability.GCI_FOURNITURES_OWN_VIEW],
            subIcon: 'box-check',
            actif: true,
            subPath: '/centres/0/reception_fp'
        },
        {
            subName: 'Réception des fournitures administratives',
            auth: [Ability.GCI_FOURNITURES_OWN_VIEW],
            subIcon: 'cabinet-filing',
            actif: true,
            subPath: '/centres/0/reception_fa'
        },
        {
            subName: 'Bibliothèque des documents administratifs',
            auth: [Ability.GCI_FOURNITURES_OWN_VIEW],
            subIcon: 'books',
            actif: true,
            subPath: '/centres/0/bibliotheque_documents_administratifs'
        }
    ]
}

const CDC_ORAUX: Menu = {
    type: 'OWN',
    name: 'ORGANISATION DES ORAUX',
    id: 'own_oraux',
    auth: [Ability.GCI_ORGA_OWN_VIEW],
    icon: 'chalkboard-teacher',
    dataBsParent: '#cmp-nav-left',
    topElement: true,
    actif: true,
    childElement: true,
    sousMenu: [
        {
            subName: 'Affectation des salles',
            auth: [Ability.GCI_ORGA_OWN_VIEW],
            subIcon: 'pen',
            actif: true,
            subPath: '/centres/0/organisationoraux'
        },
        {
            subName: 'Liste des examinateurs',
            auth: [Ability.GCI_EXAMINATEURS_OWN_VIEW],
            subIcon: 'users',
            actif: true,
            subPath: '/centres/0/examinateurs'
        },
        {
            subName: 'Liste des candidats',
            auth: [Ability.GCI_CANDIDATS_OWN_VIEW],
            subIcon: 'users',
            actif: true,
            subPath: '/centres/0/candidats'
        },
        {
            subName: 'Impressions',
            auth: [Ability.GCI_IMPRESSIONS_OWN_VIEW],
            subIcon: 'print',
            actif: true,
            subPath: '/centres/0/impressions'
        },
        {
            subName: 'Plannings',
            auth: [Ability.GCI_PLANNINGS_OWN_VIEW],
            subIcon: 'calendar-alt',
            actif: true,
            subPath: '/centres/0/plannings'
        },
        {
            subName: 'Liste des candidats démissionnaires',
            auth: [Ability.GCI_DEMISSIONNAIRES_OWN_VIEW],
            subIcon: 'user-minus',
            actif: true,
            subPath: '/centres/0/demissionnaires'
        },
        {
            subName: 'Trombinoscope des examinateurs',
            auth: [Ability.GCI_EXAMINATEURS_OWN_VIEW],
            subIcon: 'portrait',
            actif: true,
            subPath: '/trombinoscope_examinateurs_co'
        }
    ]
}

const CDC_CONVENTION: Menu = {
    type: 'OWN',
    name: 'CONVENTION',
    id: 'own_conventions',
    auth: [Ability.GCI_CONVENTION_OWN_VIEW],
    icon: 'file-contract',
    dataBsParent: '#cmp-nav-left',
    topElement: true,
    actif: true,
    childElement: true,
    sousMenu: [
        {
            subName: 'Consultation de la convention',
            auth: [Ability.GCI_CONVENTION_OWN_VIEW],
            subIcon: 'file-contract',
            actif: true,
            subPath: '/centres/0/conventions'
        },
        {
            subName: "Gestion de l'annexe de convention",
            auth: [Ability.GCI_ANNEXE_CONVENTION_OWN_VIEW],
            subIcon: 'file-edit',
            actif: true,
            subPath: '/centres/0/annexeconvention'
        }
    ]
}

const CDC_INTERVENANTS: Menu = {
    type: 'OWN',
    name: 'INTERVENANTS',
    id: 'own_intervenants',
    auth: [Ability.GCI_SAISIE_INTERV_OWN_VIEW],
    icon: 'users',
    dataBsParent: '#cmp-nav-left',
    topElement: true,
    actif: true,
    childElement: true,
    sousMenu: [
        {
            subName: 'Gestion des intervenants',
            auth: [Ability.GCI_SAISIE_INTERV_OWN_VIEW],
            subIcon: 'users',
            actif: true,
            subPath: '/centres/0/intervenants'
        }
    ]
}

const CDC_FICHE_HORAIRE: Menu = {
    type: 'OWN',
    name: 'FICHE HORAIRE',
    id: 'own_fiche_horaire',
    auth: [Ability.RH_FICHEHORAIRE_OWN_SIGN],
    icon: 'watch',
    dataBsParent: '#cmp-nav-left',
    topElement: true,
    actif: true,
    childElement: true,
    sousMenu: [
        {
            subName: 'Signer ma fiche horaire',
            auth: [Ability.RH_FICHEHORAIRE_OWN_SIGN],
            subIcon: 'file-edit',
            actif: true,
            subPath: '/signature_fiche_horaire'
        }
    ]
}

// OBSERVATEUR
const OBSERVATEUR: Menu = {
    type: 'OWN',
    name: 'SUIVI DES ORAUX',
    id: 'own_suivi_oraux',
    auth: [Ability.INTERV_OBSERV_MATIERE_OWN],
    icon: 'chalkboard-teacher',
    dataBsParent: '#cmp-nav-left',
    topElement: true,
    actif: true,
    childElement: true,
    sousMenu: [
        {
            subName: 'Réclamations',
            auth: [Ability.INTERV_OBSERV_MATIERE_OWN],
            subIcon: 'comment-exclamation',
            actif: true,
            subPath: '/reclamations_observateur'
        },
        {
            subName: 'Plannings',
            auth: [Ability.INTERV_OBSERV_MATIERE_OWN],
            subIcon: 'calendar-alt',
            actif: true,
            subPath: '/plannings_observateur'
        },
        {
            subName: 'Présence des examinateurs',
            auth: [Ability.INTERV_OBSERV_MATIERE_OWN],
            subIcon: 'users',
            actif: true,
            subPath: '/presence_examinateurs'
        },
        {
            subName: 'Trombinoscope des examinateurs',
            auth: [Ability.INTERV_OBSERV_MATIERE_OWN],
            subIcon: 'portrait',
            actif: true,
            subPath: '/trombinoscope_examinateurs'
        }
    ]
}

const gestionIncompatExaminateur: Menu = {
    type: 'OWN',
    name: 'GESTION DES ORAUX',
    id: 'gestionDesOraux',
    auth: [Ability.INTERV_PLANIF_OWN, Ability.INTERV_SUPPLEANT_OWN],
    icon: 'clipboard-list',
    dataBsParent: '#cmp-nav-left',
    topElement: true,
    actif: true,
    childElement: true,
    sousMenu: [
        {
            subName: 'Gérer mes incompatibilités',
            auth: [Ability.INTERV_PLANIF_OWN],
            subIcon: 'calendar-times',
            actif: true,
            subPath: '/gestion_incompatibles_examinateur'
        },
        {
            subName: 'Gérer mes disponibilités',
            auth: [Ability.INTERV_PLANIF_OWN],
            subIcon: 'calendar-plus',
            actif: true,
            subPath: '/gestion_disponibilites_examinateur'
        },
        {
            subName: 'Consulter mon planning',
            auth: [Ability.INTERV_PLANIF_OWN],
            subIcon: 'calendar-alt',
            actif: true,
            subPath: '/planning_examinateur'
        },
        {
            subName: 'Consulter mon planning',
            auth: [Ability.INTERV_SUPPLEANT_OWN],
            subIcon: 'calendar-alt',
            actif: true,
            subPath: '/planning_suppleant'
        },
        {
            subName: 'Candidats avec aménagements',
            auth: [Ability.INTERV_PLANIF_OWN],
            subIcon: 'users',
            actif: true,
            subPath: '/liste_candidats_mesures'
        },
        {
            subName: 'Candidats avec aménagements',
            auth: [Ability.INTERV_SUPPLEANT_OWN],
            subIcon: 'users',
            actif: true,
            subPath: '/liste_candidats_mesures_supp'
        },
        {
            subName: 'Liste des candidats démissionnaires',
            auth: [Ability.INTERV_PLANIF_OWN],
            subIcon: 'user-minus',
            actif: true,
            subPath: '/liste_demissionnaires'
        },
        {
            subName: 'Liste des candidats démissionnaires',
            auth: [Ability.INTERV_SUPPLEANT_OWN],
            subIcon: 'user-minus',
            actif: true,
            subPath: '/liste_demissionnaires_supp'
        },
        {
            subName: 'Trombinoscope des examinateurs',
            auth: [Ability.INTERV_PLANIF_OWN],
            subIcon: 'portrait',
            actif: true,
            subPath: '/trombinoscope_examinateurs_ex'
        },
        {
            subName: 'Dépôt de fichier',
            auth: [Ability.INTERV_PLANIF_OWN],
            subIcon: 'file-import',
            actif: true,
            subPath: '/documents_examinateur'
        }
    ]
}

/**
 *
 *
 *
 * Espace nouveau menu niveau2
 *
 *
 *
 * */

// RH centres

const recrutementAcademique: Menu = {
    type: '',
    name: 'RECRUTEMENT ACADÉMIQUE',
    id: 'dossiersAcademiques',
    auth: [Ability.RH_SPS_VIEW],
    icon: 'folder-open',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    actif: true,
    sousMenu: [
        {
            subName: 'Dossiers académiques',
            auth: [Ability.RH_SPS_VIEW],
            subIcon: 'id-badge',
            actif: true,
            subPath: '/dossiers_academiques'
        },
        {
            subName: 'Sélection des intervenants',
            auth: [Ability.RH_SPS_VIEW],
            subIcon: 'user-check',
            actif: true,
            subPath: '/selections'
        },
        {
            subName: 'Affectation des intervenants',
            auth: [Ability.RH_SPS_VIEW],
            subIcon: 'people-arrows',
            actif: true,
            subPath: '/affectations'
        },
        {
            subName: "Equipes d'examinateurs",
            auth: [Ability.ORAL_AFEX_VIEW],
            subIcon: 'users-class',
            actif: true,
            subPath: '/affectation_examinateurs_rh'
        },
        {
            subName: 'Groupes d\'intervenants',
            auth: [Ability.RH_SPS_VIEW],
            subIcon: 'users-class',
            actif: true,
            subPath: '/groupes_intervenants'
        }
    ]
}

const dossierAdministratif: Menu = {
    name: 'DOSSIERS ADMINISTRATIFS',
    id: 'DOSSIERADMINISTRATIFS',
    auth: [Ability.RH_DOSADM_VIEW, Ability.RH_CONTRAT_VIEW, Ability.RH_FRAIS_VIEW],
    icon: 'graduation-cap',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    actif: true,
    sousMenu: [
        {
            subName: 'Types de contrat',
            auth: [Ability.RH_CONTRAT_VIEW],
            subIcon: 'file',
            actif: true,
            subPath: '/contrats_types'
        },
        {
            subName: "Types d'avenant",
            auth: [Ability.RH_CONTRAT_VIEW],
            subIcon: 'file',
            actif: true,
            subPath: '/avenants_types'
        },
        {
            subName: 'Barèmes de rémunérations',
            auth: [Ability.RH_CONTRAT_VIEW],
            subIcon: 'donate',
            actif: true,
            subPath: '/baremes_remunerations'
        },
        {
            subName: 'Dossiers administratifs',
            auth: [Ability.RH_DOSADM_VIEW],
            subIcon: 'folders',
            actif: true,
            subPath: '/dossiers_administratifs'
        },
        {
            subName: 'Contrats',
            auth: [Ability.RH_CONTRAT_VIEW],
            subIcon: 'file-contract',
            actif: true,
            subPath: '/contrats'
        },
        {
            subName: 'Ordres de mission',
            auth: [Ability.RH_FRAIS_VIEW],
            subIcon: 'subway',
            actif: store.getters['auth/findParameter']('ordreMissionDisabled')?.value !== '1',
            subPath: '/ordres_mission'
        },
        {
            subName: 'Demandes de remboursement',
            auth: [Ability.RH_FRAIS_VIEW],
            subIcon: 'hand-holding-usd',
            actif: store.getters['auth/findParameter']('ordreMissionDisabled')?.value !== '1',
            subPath: '/demandes_remboursement'
        }
    ]
}

const signatureDesDocuments: Menu = {
    name: 'SIGNATURE DES DOCUMENTS',
    id: 'signatureDoc',
    auth: [Ability.SIGN_CONTRAT_VIEW, Ability.SIGN_PVA_VIEW, Ability.SIGN_LETTRE_VIEW, Ability.SIGN_CONVENTION_VIEW, Ability.SIGN_CONVENTIONANNEXE_VIEW, Ability.SIGN_FHRECAP_VIEW, Ability.SIGN_RECLAMATION_VIEW, Ability.SIGN_AVENANT_VIEW, Ability.SIGN_AMENAGEMENT_VIEW],
    icon: 'file-signature',
    dataBsParent: '#cmp-nav-left',
    childElement: false,
    actif: true,
    path: '/signature_documents'
}

const gestionCentres: Menu = {
    name: 'GESTION DES CENTRES',
    id: 'gestionSol',
    auth: [Ability.GC_CENTER_VIEW, Ability.RH_SOL_VIEW, Ability.GC_CONVENTION_VIEW],
    icon: 'school',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    actif: true,
    sousMenu: [
        {
            subName: 'Types de convention',
            auth: [Ability.GC_CONVENTION_VIEW],
            subIcon: 'copy',
            actif: true,
            subPath: '/conventions_types'
        },
        {
            subName: 'Centres',
            auth: [Ability.GC_CENTER_VIEW],
            subIcon: 'school',
            actif: true,
            subPath: '/centres_ecrits'
        },
        {
            subName: 'Intervenants',
            auth: [Ability.RH_SOL_VIEW],
            subIcon: 'users',
            actif: true,
            subPath: '/intervenants'
        },
        {
            subName: 'Conventions',
            auth: [Ability.GC_CONVENTION_VIEW],
            subIcon: 'file-alt',
            actif: true,
            subPath: '/conventions'
        }
    ]
}
// Oraux preparation
const preparationOraux: Menu = {
    name: 'Préparation',
    id: 'preparationOraux',
    auth: [
        Ability.ORAL_AFEX_VIEW,
        Ability.ORAL_PREPA_VIEW,
        Ability.ORAL_PLAN_VIEW,
        Ability.CAND_VIEW,
        Ability.GC_CENTER_VIEW
    ],
    icon: 'box-check',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    actif: true,
    sousMenu: [
        {
            subName: 'Candidats',
            auth: [Ability.CAND_VIEW],
            subIcon: 'user-edit',
            actif: true,
            subPath: '/candidats_oraux'
        },
        {
            subName: 'Affectation des examinateurs',
            auth: [Ability.ORAL_AFEX_VIEW],
            subIcon: 'people-arrows',
            actif: true,
            subPath: '/affectation_examinateurs'
        },
        // incompat et dispo
        {
            subName: 'Disponibilités et Incompatibilités',
            auth: [Ability.ORAL_PREPA_VIEW],
            subIcon: 'calendar-times',
            actif: true,
            subPath: '/gestion_examinateurs'
        },
        {
            subName: "Centres d'oral",
            auth: [Ability.GC_CENTER_VIEW],
            subIcon: 'school',
            actif: true,
            subPath: '/centres_oraux'
        }
    ]
}

const organisationDesOraux: Menu = {
    name: 'Organisation',
    id: 'organisationOraux',
    auth: [Ability.ORAL_PLAN_VIEW, Ability.ORAL_GFOU_VIEW],
    icon: 'calendar',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    actif: true,
    sousMenu: [
        {
            subName: 'Planification',
            auth: [Ability.ORAL_PLAN_VIEW],
            subIcon: 'calendar-edit',
            actif: true,
            subPath: '/planification'
        },
        {
            subName: 'Impressions',
            auth: [Ability.ORAL_GFOU_VIEW],
            subIcon: 'print',
            actif: true,
            subPath: '/impressions'
        },
        {
            subName: 'Plannings',
            auth: [Ability.ORAL_SUIVI_VIEW],
            subIcon: 'calendar-alt',
            actif: true,
            subPath: '/plannings'
        },
        {
            subName: 'Démissions',
            auth: [Ability.ORAL_SUIVI_VIEW],
            subIcon: 'user-minus',
            actif: true,
            subPath: '/gestion_demissions'
        },
        {
            subName: 'Suppléants',
            auth: [Ability.ORAL_SUIVI_VIEW],
            subIcon: 'users-medical',
            actif: true,
            subPath: '/suppleant'
        },
        {
            subName: 'Organisation des examinateurs',
            auth: [Ability.ORAL_SUIVI_VIEW],
            subIcon: 'users-medical',
            actif: true,
            subPath: '/organisation_examinateurs'
        },
        {
            subName: 'Séances en anomalies',
            auth: [Ability.ORAL_AFEX_MANAGE],
            subIcon: 'users-medical',
            actif: true,
            subPath: '/seances_en_anomalies'
        },
        {
            subName: 'Problèmes de déontologie',
            auth: [Ability.ORAL_PREPA_VIEW],
            subIcon: 'users',
            actif: true,
            subPath: '/problemes_deontologie'
        }
    ]
}

// Ecrits
// Oraux preparation
const preparationEcrits: Menu = {
    name: 'Préparation',
    id: 'preparationEcrits',
    auth: [
        Ability.ECR_PREPA_VIEW,
        Ability.ECR_SUJET_VIEW,
        Ability.ECR_GFOU_VIEW,
        Ability.CAND_VIEW,
        Ability.GC_CENTER_VIEW
    ],
    icon: 'box-check',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    actif: true,
    sousMenu: [
        {
            subName: 'Candidats',
            auth: [Ability.CAND_VIEW],
            subIcon: 'user-edit',
            actif: true,
            subPath: '/candidats_ecrits'
        },
        {
            subName: 'Affectation des correcteurs',
            auth: [Ability.ECR_PREPA_VIEW],
            subIcon: 'people-arrows',
            actif: true,
            subPath: '/affectation_correcteurs'
        },
        {
            subName: "Centres d'écrit",
            auth: [Ability.GC_CENTER_VIEW],
            subIcon: 'school',
            actif: true,
            subPath: '/centres_ecrits'
        },
        {
            subName: 'Répartition des candidats',
            auth: [Ability.ECR_PREPA_VIEW],
            subIcon: 'exchange-alt',
            actif: true,
            subPath: '/repartition_candidats'
        },
        {
            subName: 'Suivi des sujets & fournitures',
            auth: [Ability.ECR_PREPA_VIEW],
            subIcon: 'clipboard-list-check',
            actif: true,
            subPath: '/suivi_sujets_fournitures'
        },
        {
            subName: 'Production des fournitures',
            auth: [Ability.ECR_GFOU_VIEW],
            subIcon: 'archive',
            actif: true,
            subPath: '/production_fournitures'
        }
    ]
}

// Concours

const inscriptionsConcours: Menu = {
    name: 'Inscriptions',
    id: 'inscriptionsConcours',
    auth: [Ability.CAND_FORMINSC_VIEW, Ability.CAND_DOSSIER_VIEW],
    icon: 'folder-open',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    actif: true,
    sousMenu: [
        {
            subName: 'Formulaires',
            auth: [Ability.CAND_FORMINSC_VIEW],
            subIcon: 'file-signature',
            actif: true,
            subPath: '/formulaires_inscriptions'
        },
        {
            subName: 'Dossiers',
            auth: [Ability.CAND_DOSSIER_VIEW],
            subIcon: 'file-alt',
            actif: true,
            subPath: '/dossiers_inscriptions'
        }
    ]
}

const candidatsConcours: Menu = {
    name: 'candidats',
    id: 'candidatConcours',
    auth: [Ability.CAND_VIEW, Ability.DLB_PUBNOT_VIEW],
    icon: 'user-friends',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    actif: true,
    sousMenu: [
        {
            subName: 'Gestion des Candidats',
            auth: [Ability.CAND_VIEW],
            subIcon: 'user-cog',
            actif: true,
            subPath: '/candidats_concours'
        },
        {
            subName: 'Portail Candidats',
            auth: [Ability.DLB_PUBNOT_VIEW],
            subIcon: 'users-class',
            actif: true,
            subPath: '/gestion_portail_candidats'
        }
    ]
}
const choixPoste: Menu = {
    name: 'Choix de poste',
    id: 'choixPoste',
    auth: [Ability.DLB_CHOIX_POSTES_VIEW],
    icon: 'users-class',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    actif: true,
    sousMenu: [
        {
            subName: 'Listes',
            auth: [Ability.DLB_CHOIX_POSTES_VIEW],
            subIcon: 'users-class',
            actif: true,
            subPath: '/liste'
        },
        {
            subName: 'Candidats',
            auth: [Ability.DLB_CHOIX_POSTES_VIEW],
            subIcon: 'user-cog',
            actif: true,
            subPath: '/liste_candidats'
        },
        {
            subName: 'Postes',
            auth: [Ability.DLB_CHOIX_POSTES_VIEW],
            subIcon: 'users-class',
            actif: true,
            subPath: '/liste_postes'
        },
        {
            subName: 'Historique notifications',
            auth: [Ability.DLB_CHOIX_POSTES_VIEW],
            subIcon: 'users-class',
            actif: true,
            subPath: '/historique_notifications'
        }
    ]
}
const bourseEmploi: Menu = {
    name: 'Bourse à l\'emploi',
    id: 'bourseEmploi',
    auth: [Ability.ADM_ESTABLISHMENT_VIEW, Ability.EST_ESTABLISHMENT_PARENT_OWN_VIEW, Ability.EST_ESTABLISHMENT_OWN_VIEW, Ability.EST_INTERV_OWN_VIEW, Ability.EST_JOB_OWN_VIEW, Ability.EST_CANDIDACY_OWN_VIEW],
    icon: 'handshake',
    dataBsParent: '#cmp-nav-left',
    childElement: false,
    actif: true,
    sousMenu: [
        {
            subName: 'Types d\'établissement',
            auth: [Ability.ADM_ESTABLISHMENT_VIEW],
            subIcon: 'cogs',
            actif: true,
            subPath: '/bourse_emploi/types_etablissement'
        },
        {
            subName: 'Établissements',
            auth: [Ability.ADM_ESTABLISHMENT_VIEW, Ability.EST_ESTABLISHMENT_PARENT_OWN_VIEW, Ability.EST_ESTABLISHMENT_OWN_VIEW, Ability.EST_INTERV_OWN_VIEW, Ability.EST_JOB_OWN_VIEW, Ability.EST_CANDIDACY_OWN_VIEW],
            subIcon: 'school',
            actif: true,
            subPath: '/bourse_emploi/etablissements'
        },
        {
            subName: 'Utilisateurs',
            auth: [Ability.ADM_ESTABLISHMENT_VIEW, Ability.EST_INTERV_OWN_VIEW],
            subIcon: 'users',
            actif: true,
            subPath: '/bourse_emploi/utilisateurs'
        },
        {
            subName: 'Postes',
            auth: [Ability.ADM_ESTABLISHMENT_VIEW, Ability.EST_JOB_OWN_VIEW],
            subIcon: 'file-alt',
            actif: true,
            subPath: '/bourse_emploi/postes'
        },
        {
            subName: 'Candidatures',
            auth: [Ability.ADM_ESTABLISHMENT_VIEW, Ability.EST_CANDIDACY_OWN_VIEW],
            subIcon: 'user',
            actif: true,
            subPath: '/bourse_emploi/candidatures'
        },
        {
            subName: 'Formulaires',
            auth: [Ability.ADM_ESTABLISHMENT_VIEW],
            subIcon: 'file-signature',
            actif: true,
            subPath: '/bourse_emploi/formulaires'
        }
    ]
}

const deliberationsConcours: Menu = {
    name: 'délibérations',
    id: 'DeliberationConcours',
    auth: [Ability.DLB_PREPJURY_VIEW, Ability.EXT_RESULTS_VIEW],
    icon: 'calculator-alt',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    actif: true,
    sousMenu: [
        {
            subName: 'Ajustement des notes',
            auth: [Ability.DLB_PREPJURY_VIEW],
            subIcon: 'abacus',
            actif: true,
            subPath: '/liste_epreuve_correction_resultats'
        },
        {
            subName: 'Décisions jury',
            auth: [Ability.DLB_PREPJURY_VIEW],
            subIcon: 'balance-scale',
            actif: true,
            subPath: '/decisions_jury'
        },
        {
            subName: 'Définition des barres',
            auth: [Ability.DLB_PREPJURY_VIEW],
            subIcon: 'greater-than-equal',
            actif: true,
            subPath: '/definition_des_barres'
        },
        {
            subName: 'Résultats aux concours',
            auth: [Ability.EXT_RESULTS_VIEW],
            subIcon: 'greater-than-equal',
            actif: !store.getters['auth/can'](Ability.DLB_PREPJURY_VIEW),
            subPath: '/resultats'
        }
    ]
}

const reclamationConcours: Menu = {
    name: 'réclamations',
    id: 'reclamationsConcours',
    auth: [Ability.ECR_RECLAM_VIEW, Ability.ORAL_RECLAM_VIEW],
    icon: 'hand-paper',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    actif: true,
    sousMenu: [
        {
            subName: "Réclamation sur les notes d'écrit",
            auth: [Ability.ECR_RECLAM_VIEW],
            subIcon: 'pencil-ruler',
            actif: true,
            subPath: '/reclamations_ecrit'
        },
        {
            subName: "Réclamation sur les notes d'oral",
            auth: [Ability.ORAL_RECLAM_VIEW],
            subIcon: 'comment-exclamation',
            actif: true,
            subPath: '/reclamations_post_concours'
        },
        {
            subName: 'Modèles de réponses aux réclamations',
            auth: [Ability.ADM_PAR_VIEW],
            subIcon: 'hand-paper',
            actif: true,
            subPath: '/reclamationTemplates'
        }
    ]
}

const reclamationOraux: Menu = {
    name: 'réclamations',
    id: 'reclamationsOraux',
    auth: [Ability.ECR_RECLAM_VIEW, Ability.ORAL_RECLAM_VIEW],
    icon: 'hand-paper',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    actif: true,
    sousMenu: [
        {
            subName: "Réclamation sur les séances d'oral ",
            auth: [Ability.ORAL_SEANCE_RECLAM_VIEW],
            subIcon: 'comment-exclamation',
            actif: true,
            subPath: '/reclamations_oral'
        },
        {
            subName: 'Dépôt des fichiers des examinateurs',
            auth: [Ability.ORAL_SEANCE_RECLAM_VIEW],
            subIcon: 'file-alt',
            actif: true,
            subPath: '/liste_documents_examinateur'
        },
        {
            subName: 'Modèles de réponses aux réclamations',
            auth: [Ability.ADM_PAR_VIEW],
            subIcon: 'hand-paper',
            actif: true,
            subPath: '/reclamationTemplates'
        }
    ]
}

/**
 *
 *
 *  Niveau1
 *  le niveau 1 app les composants du dessus
 *  pour tous les niveaux : auth check les droit pour le niveau ou est auth uniquement
 *  niveaux 1 check les droits pour le niveau1 , niveau deux pour le niveau 2 etc...
 *  le niveau 1 doit contenir tous les droits de ses niveaux du dessous
 * le niveau 2 tous les droits de son n3
 *
 * */
const RHCentres: MenuNiveau1 = {
    // Ability.GC_CENTER_VIEW, Ability.RH_SOL_VIEW, Ability.GC_CONVENTION_VIEW
    type: 'MenuNiveau1',
    name: 'RH - CENTRES',
    id: 'rhcentre',
    auth: [
        Ability.RH_SPS_VIEW,
        Ability.RH_DOSADM_VIEW,
        Ability.RH_CONTRAT_VIEW,
        Ability.RH_FRAIS_VIEW,
        Ability.GC_CENTER_VIEW,
        Ability.RH_SOL_VIEW,
        Ability.GC_CONVENTION_VIEW,
        Ability.SIGN_CONTRAT_VIEW,
        Ability.SIGN_PVA_VIEW,
        Ability.SIGN_LETTRE_VIEW,
        Ability.SIGN_CONVENTION_VIEW,
        Ability.SIGN_CONVENTIONANNEXE_VIEW,
        Ability.SIGN_FHRECAP_VIEW,
        Ability.SIGN_RECLAMATION_VIEW,
        Ability.SIGN_AVENANT_VIEW,
        Ability.SIGN_AMENAGEMENT_VIEW
    ],
    icon: 'handshake-alt',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    actif: true,
    dashboardPath: '/dashboard',
    menu: [recrutementAcademique, dossierAdministratif, gestionCentres, signatureDesDocuments]
}

const Oraux: MenuNiveau1 = {
    type: 'MenuNiveau1',
    name: 'ORAUX',
    id: 'OrauxN1',
    auth: [
        Ability.ORAL_AFEX_VIEW,
        Ability.ORAL_PREPA_VIEW,
        Ability.ORAL_PLAN_VIEW,
        Ability.CAND_VIEW,
        Ability.GC_CENTER_VIEW,
        Ability.ORAL_GFOU_VIEW
    ],
    icon: 'chalkboard-teacher',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    actif: true,
    dashboardPath: '/dashboardOraux',
    menu: [preparationOraux, organisationDesOraux, reclamationOraux]
}

const Ecrits: MenuNiveau1 = {
    type: 'MenuNiveau1',
    name: 'ECRITS',
    id: 'EcritsN1',
    auth: [
        Ability.ECR_PREPA_VIEW,
        Ability.ECR_SUJET_VIEW,
        Ability.ECR_GFOU_VIEW,
        Ability.CAND_VIEW,
        Ability.GC_CENTER_VIEW,
        Ability.ECR_RECLAM_VIEW
    ],
    icon: 'pen-alt',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    actif: true,
    dashboardPath: '/dashboardEcrits',
    menu: [preparationEcrits]
}

// Ability.GC_CENTER_VIEW, Ability.RH_SOL_VIEW, Ability.GC_CONVENTION_VIEW
const Concours: MenuNiveau1 = {
    type: 'MenuNiveau1',
    name: 'Concours',
    id: 'ConcoursN1',
    auth: [
        Ability.DLB_CHOIX_POSTES_VIEW,
        Ability.CAND_FORMINSC_VIEW,
        Ability.CAND_VIEW,
        Ability.DLB_PUBNOT_VIEW,
        Ability.DLB_PREPJURY_VIEW,
        Ability.ECR_RECLAM_VIEW,
        Ability.ORAL_RECLAM_VIEW,
        Ability.SIGN_CONTRAT_VIEW,
        Ability.SIGN_PVA_VIEW,
        Ability.SIGN_LETTRE_VIEW,
        Ability.SIGN_CONVENTION_VIEW,
        Ability.SIGN_CONVENTIONANNEXE_VIEW,
        Ability.SIGN_FHRECAP_VIEW,
        Ability.SIGN_RECLAMATION_VIEW,
        Ability.SIGN_AVENANT_VIEW,
        Ability.SIGN_AMENAGEMENT_VIEW,
        Ability.EXT_RESULTS_VIEW
    ],
    icon: 'user-graduate',
    dataBsParent: '#cmp-nav-left',
    childElement: true,
    actif: true,
    dashboardPath: '/dashboardConcours',
    menu: [
        inscriptionsConcours,
        candidatsConcours,
        choixPoste,
        deliberationsConcours,
        reclamationConcours,
        bourseEmploi,
        signatureDesDocuments
    ]
}

const aide: Menu = {
    name: 'AIDE',
    id: 'aide',
    auth: [true],
    icon: 'question',
    dataBsParent: '#cmp-nav-left',
    childElement: false,
    actif: false,
    path: '/aide'
}

const functionsForAuth = {
    // fait avec trois niveaux
    firstElements: [
        dashboard,
        RHCentres,
        Ecrits,
        Oraux,
        Concours,
        CDC_CENTRE,
        CDC_ECRIT,
        CDC_ORAUX,
        CDC_CONVENTION,
        CDC_INTERVENANTS,
        CDC_FICHE_HORAIRE,
        gestionIncompatExaminateur,
        dossieAcademiqueIntervenant,
        contratsIntervenant,
        dossieAdministratifIntervenant,
        groupesIntervenants,
        bourseEmploiOwn,
        OBSERVATEUR
    ],

    // max deux niveaux
    bottomElements: [configurations, aide],

    /**
     * fonction de filtre des droits. Parcours les éléments du menu, renvois un array avec les elements authorisés et les accreditations
     */
    theFilterFunction(menuElements: any) {
        const menuFilter: Set<any> = new Set()

        if (menuElements) {
            for (const element of menuElements) {
                if (element.id === 'inscriptionsConcours') {
                    let concoursInscriptionsEnabled: any = 0
                    if (store && store.state && (store.state as any).auth && (store.state as any).auth.user && (store.state as any).auth.user.parameters) {
                        for (let i = 0; i < ((store.state as any).auth.user.parameters as Array<any>).length; i ++) {
                            if ((store.state as any).auth.user.parameters[i].code === 'concoursInscriptionsEnabled') {
                                concoursInscriptionsEnabled = (store.state as any).auth.user.parameters[i].value
                            }
                        }
                    }
                    if (concoursInscriptionsEnabled === 1 || concoursInscriptionsEnabled === "1") {
                        if (element.auth.indexOf(true) !== -1) {
                            menuFilter.add(element)
                        }
                        for (const ua of element.auth) {
                            if (store.getters['auth/can'](ua)) {
                                menuFilter.add(element)
                            }
                        }
                    }
                } else {
                    if (element.auth.indexOf(true) !== -1) {
                        menuFilter.add(element)
                    }
                    for (const ua of element.auth) {
                        if (store.getters['auth/can'](ua)) {
                            menuFilter.add(element)
                        }
                    }
                }
            }
        }
        let menuFilterArray: any[] = []
        menuFilterArray = Array.from(menuFilter)
        return menuFilterArray
    },

    /**
     * Fonction utilisant userAuth et theFilterFunction. Contrôle le menu et les sous-menus, renvois le tableau final prêt pour SideBarLeft. Utilise theFilterFunction
     */
    menuFiltered(menuElements: Menu[]) {
        // Gestion des dossiers académiques
        if (store.getters['user/dossieracademiqueId'] !== null) {
            if (dossieAcademiqueIntervenant.sousMenu) {
                dossieAcademiqueIntervenant.sousMenu[0].subPath = '/dossier_academique/' + store.getters['user/dossieracademiqueId']
            }

            dossieAcademiqueIntervenant.actif = true
        } else {
            dossieAcademiqueIntervenant.actif = false
        }

        if (store.getters['user/dossieradministratifId'] !== null) {
            dossieAdministratifIntervenant.path =
                '/dossier_administratif/' + store.getters['user/dossieradministratifId']
            dossieAdministratifIntervenant.actif = true
        } else {
            dossieAdministratifIntervenant.actif = false
        }

        if (store.getters['user/hasContrat']) {
            contratsIntervenant.actif = true

            if (!store.getters['user/has_poste_aca']) {
                const sousmenus_contrats: any = contratsIntervenant.sousMenu
                sousmenus_contrats[1].actif = false
                sousmenus_contrats[2].actif = false
            } else {
                const sousmenus_contrats: any = contratsIntervenant.sousMenu
                sousmenus_contrats[1].actif = true
                sousmenus_contrats[2].actif = true
            }
        } else {
            contratsIntervenant.actif = false
        }
        // Gestion du centre
        if (store.getters['user/centreId'] !== null) {
            if (store.getters['auth/is_intervenant_centre']) {
                const sousmenus: any = CDC_CENTRE.sousMenu
                const sousmenus_e: any = CDC_ECRIT.sousMenu
                const sousmenus_i: any = CDC_INTERVENANTS.sousMenu
                const sousmenus_c: any = CDC_CONVENTION.sousMenu
                const sousmenus_o: any = CDC_ORAUX.sousMenu
                if (sousmenus && sousmenus[0]) {
                    sousmenus[0].subPath = '/centres/' + store.getters['user/centreId'] + '/description'
                }
                if (sousmenus && sousmenus[1]) {
                    sousmenus[1].subPath = '/centres/' + store.getters['user/centreId'] + '/informations'
                }

                if (
                    store.getters['auth/can'](Ability.GCI_SAISIE_INTERV_OWN_VIEW, 'App\\Models\\Centre', {
                        id: store.getters['user/centreId']
                    })
                ) {
                    CDC_INTERVENANTS.actif = true
                } else {
                    CDC_INTERVENANTS.actif = false
                }

                if (store.getters['user/centreTypePassation'] === TypePassation.TYPE_PASSATION_ECRIT) {
                    sousmenus_e[0].subPath = '/centres/' + store.getters['user/centreId'] + '/organisationecrit/salles'
                    sousmenus_e[1].subPath =
                        '/centres/' + store.getters['user/centreId'] + '/organisationecrit/nb_intervenants'
                    sousmenus_e[2].subPath = '/centres/' + store.getters['user/centreId'] + '/candidats_ecrits'

                    if (
                        store.getters['auth/can'](Ability.GCI_FOURNITURES_OWN_VIEW, 'App\\Models\\Centre', {
                            id: store.getters['user/centreId']
                        })
                    ) {
                        sousmenus_e[3].subPath = '/centres/' + store.getters['user/centreId'] + '/indisponibilites_reception'
                        sousmenus_e[4].subPath = '/centres/' + store.getters['user/centreId'] + '/inventaire_fp'
                        sousmenus_e[5].subPath = '/centres/' + store.getters['user/centreId'] + '/reception_sujets'
                        sousmenus_e[6].subPath = '/centres/' + store.getters['user/centreId'] + '/reception_fp'
                        sousmenus_e[7].subPath = '/centres/' + store.getters['user/centreId'] + '/reception_fa'
                        sousmenus_e[8].subPath = '/centres/' + store.getters['user/centreId'] + '/bibliotheque_documents_administratifs'
                    }

                    // Check si l'organisation des écrits est validée pour affiché le sous menu intervenants
                    if (store.getters['user/centre_organisation_ecrit_validated']) {
                        sousmenus_i[0].subPath = '/centres/' + store.getters['user/centreId'] + '/intervenants'
                        sousmenus_i[0].actif = true
                    } else {
                        sousmenus_i[0].actif = false
                        CDC_INTERVENANTS.actif = false
                    }
                    if (
                        store.getters['auth/can'](Ability.GCI_ORGA_OWN_VIEW, 'App\\Models\\Centre', {
                            id: store.getters['user/centreId']
                        })
                    ) {
                        CDC_ORAUX.actif = false
                        CDC_ECRIT.actif = true
                        sousmenus_o[0].actif = false
                    } else {
                        CDC_ORAUX.actif = false
                        CDC_ECRIT.actif = false
                        sousmenus_o[0].actif = false
                    }
                } else {
                    CDC_INTERVENANTS.actif = false
                    CDC_ECRIT.actif = false
                }

                if (store.getters['user/centreTypePassation'] === TypePassation.TYPE_PASSATION_ORAL) {
                    sousmenus_o[0].subPath = '/centres/' + store.getters['user/centreId'] + '/organisationoral'
                    sousmenus_o[1].subPath = '/centres/' + store.getters['user/centreId'] + '/examinateurs'
                    sousmenus_o[2].subPath = '/centres/' + store.getters['user/centreId'] + '/candidats'
                    sousmenus_o[3].subPath = '/centres/' + store.getters['user/centreId'] + '/impressions'
                    sousmenus_o[4].subPath = '/centres/' + store.getters['user/centreId'] + '/plannings'
                    sousmenus_o[5].subPath = '/centres/' + store.getters['user/centreId'] + '/demissionnaires'

                    if (
                        store.getters['auth/can'](Ability.GCI_ORGA_OWN_VIEW, 'App\\Models\\Centre', {
                            id: store.getters['user/centreId']
                        })
                    ) {
                        CDC_ORAUX.actif = true
                        CDC_ECRIT.actif = false
                        sousmenus_e[0].actif = false
                    } else {
                        CDC_ORAUX.actif = false
                        CDC_ECRIT.actif = false
                        sousmenus_e[0].actif = false
                    }
                } else {
                    CDC_ORAUX.actif = false
                }

                sousmenus_c[0].subPath = '/centres/' + store.getters['user/centreId'] + '/conventions'
                if (sousmenus_c[1]) {
                    sousmenus_c[1].subPath = '/centres/' + store.getters['user/centreId'] + '/annexeconvention'
                }

                if (
                    store.getters['auth/can'](Ability.GCI_DESCR_OWN_VIEW, 'App\\Models\\Centre', {
                        id: store.getters['user/centreId']
                    })
                ) {
                    CDC_CENTRE.actif = true
                } else {
                    CDC_CENTRE.actif = false
                }
            }
        } else {
            if (store.getters['auth/is_intervenant_centre']) {
                CDC_CENTRE.actif = false
            } else {
                CDC_CENTRE.actif = false
                CDC_ECRIT.actif = false
                CDC_ORAUX.actif = false
                CDC_CONVENTION.actif = false
                CDC_INTERVENANTS.actif = false
            }
        }

        // Gestion de la signature des documents de convention
        const centreSelect = store.getters['centre/centreSelect']
        if (!store.getters['user/conventionPublished']) {
            if (store.getters['auth/is_intervenant_centre']) {
                const sousmenus: any = CDC_CONVENTION.sousMenu
                if (sousmenus && sousmenus[0]) {
                    sousmenus[0].actif = true
                }
                if (sousmenus && sousmenus[1]) {
                    sousmenus[0].actif = true
                }
                if (
                    centreSelect &&
                    centreSelect.convention_published_at &&
                    store.getters['auth/can'](Ability.GCI_CONVENTION_OWN_VIEW, 'App\\Models\\Centre', {
                        id: store.getters['user/centreId']
                    })
                ) {
                    CDC_CONVENTION.actif = true
                    if (sousmenus && sousmenus[0]) {
                        sousmenus[0].actif = true
                    }
                    if (sousmenus && sousmenus[1]) {
                        sousmenus[0].actif = true
                    }
                } else {
                    CDC_CONVENTION.actif = false
                    if (sousmenus && sousmenus[0]) {
                        sousmenus[0].actif = true
                    }
                    if (sousmenus && sousmenus[1]) {
                        sousmenus[0].actif = true
                    }
                }
            }
        }

        // Gestion du module Gestion des oraux examinateur
        // Accés ou non au incompatibilités / disponibilités
        if (store.getters['user/incompats_open']) {
            const sousmenus: any = gestionIncompatExaminateur.sousMenu
            sousmenus[0].actif = store.getters['user/incompats_open']
        }
        if (store.getters['user/dispos_open']) {
            const sousmenus: any = gestionIncompatExaminateur.sousMenu
            sousmenus[1].actif = store.getters['user/dispos_open']
        }
        if (store.getters['user/planning_open']) {
            const sousmenus: any = gestionIncompatExaminateur.sousMenu
            sousmenus[2].actif = store.getters['user/planning_open']
        }

        // Gestion de l'affichage de la bourse à l'emploi
        Concours.auth?.some((ability: any) => {
            if (store.getters['auth/can'](ability)) {
                dashboard.actif = false
                bourseEmploiOwn.actif = false
                return true
            }
        })

        // Menu AIDE
        const aides = store.getters['auth/findParameter']('aides')
        aide.actif = !!(aides && (aides.value?.documents?.length > 0 || aides.value?.videos?.length > 0));

        const menuFiltered = this.theFilterFunction(menuElements)
        for (const element of menuFiltered) {
            if (element.childElement) {
                element.sousMenu = this.theFilterFunction(element.sousMenu)
            }
        }

        return menuFiltered
    }

}


// const filteredMenu = functionsForAuth.menuFiltered(functionsForAuth.menuElements)

export { functionsForAuth }
