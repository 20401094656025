import * as API from '@/services/API'
import store from '@/store'
/* eslint-disable @typescript-eslint/no-explicit-any */
export default {
    async getFormulaire (formulaireId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/formulaireinscriptions/${formulaireId}`)
    },
    async getFormulaires (payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/formulaireinscriptions`, { params: payload })
    },
    async updateFormulaire (payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/formulaireinscriptions/${payload.id}`, payload)
    },
    async addFormulaire (payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/formulaireinscriptions`, payload)
    },
    async deleteFormulaire (formulaire_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/formulaireinscriptions/${formulaire_id}`)
    },
    async getPiloteFormulaire(formulaire_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/formulaireinscriptions/${formulaire_id}/pilote`)
    },
    async getConcoursFormulaire(formulaire_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/formulaireinscriptions/${formulaire_id}/concours`) 
    },
    async getDocument(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/formulaireinscriptions/${payload.formulaire_id}/documents/${payload.documentId}?format=b64`)
    },
    async storeDocument (params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/formulaireinscriptions/${params.formulaire_id}/documents`, params.payload)
    },
    async deleteDocument(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/formulaireinscriptions/${payload.formulaire_id}/documents/${payload.documentId}`)
    },
    async getDocumentReference(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/formulaireinscriptions/${payload.formulaire_id}/reference/documents/${payload.documentId}?format=b64`)
    },
    async storeDocumentReference (params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/formulaireinscriptions/${params.formulaire_id}/reference/documents`, params.payload)
    },
    async deleteDocumentReference(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/formulaireinscriptions/${payload.formulaire_id}/reference/documents/${payload.documentId}`)
    },
    async updateConcoursFormulaire(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/formulaireinscriptions/updateConcours/${payload.id}`, payload)
    },
    async cloneFormulaire(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/formulaireinscriptions/${payload.id}/clone`, payload) 
    }
}
