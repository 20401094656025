import store from '@/store/index'
import * as API from '@/services/API'

export default {
    // recup de la liste des concours/phase
    async getDefinitionDesBarres(params?: any) {
        const session_id = store.getters['auth/user_session_id']

        return await API.apiClient.get(`/sessions/${session_id}/concourphases?filter-deliberation=1`, { params: params })
    },
    async putDefinitionDesBarres(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/concourphases/${payload.concourphase}/barres`, payload)
    },
    async updateConcourPhaseBarThresholdValue(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(
            `/sessions/${session_id}/concourphases/${payload.concourPhase_id}/bar_threshold_value`,
            payload.data
        )
    },
    async validationnDesBarres(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/phases/${payload.phase_id}/bars_validation`)
    }
}
