import * as API from '@/services/API'

export default {
    async getCouleur(couleurId: number) {
        return await API.apiClient.get(`/couleurs/${couleurId}`)
    },
    async getCouleurs(payload: any) {
        return await API.apiClient.get('/couleurs', { params: payload })
    },
    async updateCouleur(payload: any) {
        return await API.apiClient.put(`/couleurs/${payload.id}`, payload)
    },
    async addCouleur(payload: any) {
        return await API.apiClient.post('/couleurs', payload)
    },
    async deleteCouleur(couleur_id: number) {
        return await API.apiClient.delete(`/couleurs/${couleur_id}`)
    }
}
