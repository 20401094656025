import { getError } from '@/utils/helpers'
import CandidatConcourPhaseListeService from '@/services/CandidatConcourPhaseListeService'
import { CandidatConcourPhaseListeInterface, StateCandidatConcourPhaseListes } from '@/types/CandidatConcourPhaseListe'

export const namespaced = true
export const namespace = true

function setCandidatConcourPhaseListes(commit: any, response: any) {
    commit('SET_CANDIDAT_CONCOUR_PHASE_LISTES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_LOADING', false)
}

export const state: StateCandidatConcourPhaseListes = {
    candidatConcourPhaseListes: [],
    meta: null,
    links: null,
    currentPage: 0,
    loading: false,
    error: null,
    totalRows: 0,
    lastPage: 0
}

export const mutations = {
    RESET_STATE(state: StateCandidatConcourPhaseListes) {
        Object.assign(state, {
            candidatConcourPhaseListes: [],
            meta: null,
            links: null,
            currentPage: 0,
            loading: false,
            error: null,
            totalRows: 0,
            lastPage: 0
        })
    },
    SET_CANDIDAT_CONCOUR_PHASE_LISTES(
        state: any,
        candidatConcourPhaseListes: Array<CandidatConcourPhaseListeInterface>
    ) {
        state.candidatConcourPhaseListes = candidatConcourPhaseListes
    },
    SET_MORE_CANDIDAT_CONCOUR_PHASE_LISTES(
        state: any,
        candidatConcourPhaseListes: Array<CandidatConcourPhaseListeInterface>
    ) {
        state.candidatConcourPhaseListes = state.candidatConcourPhaseListes.concat(candidatConcourPhaseListes)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    }
}

export const actions = {
    getCandidatConcourPhaseListe({ commit }: { commit: any }, id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatConcourPhaseListeService.getCandidatConcourPhaseListe(id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateCandidatConcourPhaseListe({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatConcourPhaseListeService.updateCandidatConcourPhaseListe(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addCandidatConcourPhaseListe({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatConcourPhaseListeService.addCandidatConcourPhaseListe(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getCandidatConcourPhaseListes({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        commit('SET_CANDIDAT_CONCOUR_PHASE_LISTES', [])
        return new Promise((resolve, reject) => {
            CandidatConcourPhaseListeService.getCandidatConcourPhaseListes(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    setCandidatConcourPhaseListes(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreCandidatConcourPhaseListes({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatConcourPhaseListeService.getCandidatConcourPhaseListes(params)
                .then((response) => {
                    commit('SET_MORE_CANDIDAT_CONCOUR_PHASE_LISTES', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteCandidatConcourPhaseListe({ commit }: { commit: any }, candidatConcourPhaseListeId: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatConcourPhaseListeService.deleteCandidatConcourPhaseListe(candidatConcourPhaseListeId)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    import({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatConcourPhaseListeService.import(payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    export({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatConcourPhaseListeService.export()
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    exportDecision({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatConcourPhaseListeService.exportDecision(payload.candidatconcourphaseliste)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    exportListe({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatConcourPhaseListeService.exportListe(payload.candidatconcourphaseliste)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    justificatifsChoixPoste ({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatConcourPhaseListeService.justificatifsChoixPoste()
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDocument ({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatConcourPhaseListeService.getDocument(payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    candidatConcourPhaseListes: (state: StateCandidatConcourPhaseListes) => {
        return state.candidatConcourPhaseListes
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },

    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.candidatConcourPhaseListes.length
    },
    candidatConcourPhaseListeById: (state: any) => (id: number) => {
        return state.candidatConcourPhaseListes.filter(
            (candidatConcourPhaseListe: any) => candidatConcourPhaseListe.id === id
        )[0]
    }
}
