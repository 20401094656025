import * as API from '@/services/API'
import { AxiosRequestConfig } from 'axios'
import store from '@/store/index'

export default {
    /* recup des données pour le graphique Graphique */
    async postDefinitionDesBarresAnalysesHypotheses(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const params = payload.options
        const concourPhase = payload.concours_phase
        return await API.apiClient.post(
            `/sessions/${session_id}/concourphases/${concourPhase}/hypotheses_search`,
            params
        )
    },

    /* Recup des valeurs epinglées */
    async getHypothesesEpinglees(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const concour_phase = payload.concour_phase
        return await API.apiClient.get(`/sessions/${session_id}/concourphases/${concour_phase}/hypotheses`)
    },

    /* epingle une hypothese */
    async postHypothesesEpingle(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const params = payload.params
        const concourPhase = payload.concours_phase
        return await API.apiClient.post(`/sessions/${session_id}/concourphases/${concourPhase}/hypotheses`, params)
    },

    /* supprime une hypothese */
    async deleteHypothesesEpingle(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const params = payload.params
        const concourPhase = payload.concours_phase
        return await API.apiClient.delete(
            `/sessions/${session_id}/concourphases/${concourPhase}/hypotheses/${params.id}`
        )
    },

    // exportation du tableau
    async getExportDesBarresStatistiquesConcours(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        const concourPhase = payload.concourPhase
        return await API.apiClient.get(
            `/sessions/${session_id}/concourphases/${concourPhase}/hypotheses_matrices_export`,
            params
        )
    }
}
