import { getError } from '@/utils/helpers'
import DossierAcademiqueService from '@/services/DossierAcademiqueService'
import {
    DatasPersoInterface,
    DatasProInterface,
    Decision,
    DossierAcademiqueInterface,
    DossierSelectionInterface
} from '@/types/DossierAcademique'
import { ConcourInterface } from '@/types/Concour'
import { PosteContext, PosteInterface } from '@/types/Poste'
import Vue from 'vue'

export const namespaced = true

function setDossierAcademique(commit: any, response: any) {
    commit('SET_DOSSIERS_ACADEMIQUES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_LAST_PAGE', response.data.meta ? (response.data.meta.last_page ? response.data.meta.last_page :response.data.meta.total_page)  : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

function setDossierSelection(commit: any, response: any) {
    commit('SET_DOSSIERS_SELECTIONS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

// Dispatche les postes selon leur context
function dispatchDossiersAcademiques(dossiersAcademiques: Array<any>): Array<any> {
    const splitPostes = (postes: Array<any>): any => {
        return {
            postes_souhaits: postes?.filter((p: any): boolean => p.context_type === PosteContext.CONTEXT_SOUHAIT) || [],
            postes_selections: postes?.filter((p: any): boolean => p.context_type === PosteContext.CONTEXT_SELECTION) || [],
            postes_affectations: postes?.filter((p: any): boolean => p.context_type === PosteContext.CONTEXT_AFFECTATION) || []
        }
    }

    for (const d in dossiersAcademiques) {
        if (dossiersAcademiques[d].courant) {
            Object.assign(dossiersAcademiques[d].courant, splitPostes(dossiersAcademiques[d]?.courant?.postes))
        }

        if (dossiersAcademiques[d].precedent) {
            Object.assign(dossiersAcademiques[d].precedent, splitPostes(dossiersAcademiques[d]?.precedent?.postes))
        }
    }
    return dossiersAcademiques
}

export const state = {
    dossiersAcademiques: [],
    dossiersSelections: [],
    dossiersSelectionsNew: [],
    dossiersSelectionsAll: [],
    dossierAcademiqueSelect: {},
    dossierSelectionSelect: {},
    dacaConcours: [],
    avatar: null,
    meta: null,
    links: null,
    loading: false,
    error: null,
    error_conflict: {},
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    sortby: '',
    session_id: 0,
    posteSelect: {},
    hasAvatar: false,
    academie: '',
    typeEtab: '',
    isValide: false,
    countError: 0,
    viewDossier: false,
    dossiers_open: 0,
    pdfLettre: '',
    dossiersAcademiquesOuvertParEtat: [],
    maj_ds: false,
    maj_dsn: false,
    maj_dsall: false
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            dossiersAcademiques: [],
            dossiersSelections: [],
            dossiersSelectionsNew: [],
            dossiersSelectionsAll: [],
            dossierAcademiqueSelect: {},
            dossierSelectionSelect: {},
            dacaConcours: [],
            avatar: null,
            meta: null,
            links: null,
            loading: false,
            error: null,
            error_conflict: {},
            has_response: false,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            sortby: '',
            session_id: 0,
            posteSelect: {},
            hasAvatar: false,
            academie: '',
            typeEtab: '',
            isValide: false,
            countError: 0,
            dossiersOpen: 0,
            pdfLettre: '',
            dossiersAcademiquesOuvertParEtat: []
        })
    },
    SET_DOSSIERS_ACADEMIQUES_OUVERT_PAR_ETAT(state: any, response: any) {
        state.dossiersAcademiquesOuvertParEtat = response.data.data
    },
    SET_DOSSIERS_ACADEMIQUES(state: any, dossiersAcademiques: any) {
        state.dossiersAcademiques = dispatchDossiersAcademiques(dossiersAcademiques)
    },
    SET_MORE_DOSSIERS_ACADEMIQUES(state: any, dossiersAcademiques: any) {
        state.dossiersAcademiques = state.dossiersAcademiques.concat(dispatchDossiersAcademiques(dossiersAcademiques))
    },
    SET_DOSSIERS_SELECTIONS(state: any, dossiersSelection: Array<any>) {
        state.dossiersSelectionsNew = []
        state.dossiersSelections = []
        state.dossiersSelectionsAll = []
        let isNewCandidature = 0

        for (const d in dossiersSelection) {
            isNewCandidature = 0
            const postes_souhaits_precedent = []
            const postes_selections_precedent = []
            const postes_souhaits_courant = []
            const postes_selections_courant = []
            const postes_affectations_precedent = []
            const postes_affectations_courant = []
            // tri des postes
            if (dossiersSelection[d].precedent !== null) {
                for (const p in dossiersSelection[d].precedent.postes) {
                    dossiersSelection[d].precedent.postes[p].matieres = dossiersSelection[d].precedent.matieres
                    dossiersSelection[d].precedent.postes[p].concour = dossiersSelection[d].precedent.concour
                    if (dossiersSelection[d].precedent.postes[p].context_type === PosteContext.CONTEXT_SOUHAIT) {
                        postes_souhaits_precedent.push(dossiersSelection[d].precedent.postes[p])
                    }
                    if (dossiersSelection[d].precedent.postes[p].context_type === PosteContext.CONTEXT_SELECTION) {
                        postes_selections_precedent.push(dossiersSelection[d].precedent.postes[p])
                    }
                    if (dossiersSelection[d].precedent.postes[p].context_type === PosteContext.CONTEXT_AFFECTATION) {
                        postes_affectations_precedent.push(dossiersSelection[d].precedent.postes[p])
                    }
                }
                if (
                    dossiersSelection[d].precedent.decision_selection === Decision.DECISION_AJOURNE ||
                    dossiersSelection[d].precedent.decision_selection === Decision.DECISION_SUSPENDU
                ) {
                    // Nouvelle candidature
                    isNewCandidature = 1
                }
            } else {
                // Nouvelle candidature
                isNewCandidature = 1
            }

            if (dossiersSelection[d].courant !== null) {
                for (const p in dossiersSelection[d].courant.postes) {
                    dossiersSelection[d].courant.postes[p].matieres = dossiersSelection[d].courant.matieres
                    dossiersSelection[d].courant.postes[p].concour = dossiersSelection[d].courant.concour
                    if (dossiersSelection[d].courant.postes[p].context_type === PosteContext.CONTEXT_SOUHAIT) {
                        postes_souhaits_courant.push(dossiersSelection[d].courant.postes[p])
                    }
                    if (dossiersSelection[d].courant.postes[p].context_type === PosteContext.CONTEXT_SELECTION) {
                        postes_selections_courant.push(dossiersSelection[d].courant.postes[p])
                    }
                    if (dossiersSelection[d].courant.postes[p].context_type === PosteContext.CONTEXT_AFFECTATION) {
                        postes_affectations_courant.push(dossiersSelection[d].courant.postes[p])
                    }
                }
            }

            const ds: DossierSelectionInterface = {
                user: dossiersSelection[d].user,
                precedent: dossiersSelection[d].precedent,
                courant: dossiersSelection[d].courant,
                etat: dossiersSelection[d].courant ? dossiersSelection[d].courant.etat : null,
                disponibilite_selection: dossiersSelection[d].courant
                    ? dossiersSelection[d].courant.decision_intervenant_selection
                    : null,
                disponibilite_affectation: dossiersSelection[d].courant
                    ? dossiersSelection[d].courant.decision_intervenant_affectation
                    : null,
                classement: dossiersSelection[d].courant ? dossiersSelection[d].courant.classement : null,
                postes_souhait_courant: postes_souhaits_courant,
                postes_selection_courant: postes_selections_courant,
                postes_affectation_courant: postes_affectations_courant,
                postes_souhait_precedent: postes_souhaits_precedent,
                postes_selection_precedent: postes_selections_precedent,
                postes_affectation_precedent: postes_affectations_precedent
            }

            if (isNewCandidature === 1) {
                state.dossiersSelectionsNew.push(ds)
            } else {
                state.dossiersSelections.push(ds)
            }

            // Dans tous les cas on le met dans une collection commune pour envoi des lettres
            state.dossiersSelectionsAll.push(ds)
        }
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error_conflict = {}
        state.error = error
        state.countError++
    },
    SET_ERROR_MESSAGE(state: any, error: string) {
        state.error = null
        state.error_conflict.message = error
        state.countError++
    },
    SET_ERROR_DATAS(state: any, datas: Array<any>) {
        state.error = null
        state.error_conflict.datas = datas
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_SORTBY(state: any, params: string) {
        state.sortby = params
    },
    SET_DOSSIER_ACADEMIQUE_CURRENT(state: any, dossierAcademique: DossierAcademiqueInterface) {
        state.dossierAcademiqueSelect = dossierAcademique
        state.dossierAcademiqueSelect.hasAvatar = false
        state.avatar = null
        if (state.dossierAcademiqueSelect.documents) {
            // Check si on a un avatar sur le dossier
            for (const item of state.dossierAcademiqueSelect.documents) {
                if (item.type === 'photo') {
                    state.dossierAcademiqueSelect.hasAvatar = true
                }
            }
        }
        // MAJ des postes sélectionnés
        const postes_id = []
        const postes_filter = state.dossierAcademiqueSelect.postes.filter(
            (p: any) => p.context_type === PosteContext.CONTEXT_SOUHAIT
        )
        for (const item of postes_filter) {
            postes_id.push(item.id)
        }
        state.posteSelect = {
            matiere_id: dossierAcademique.matiere_id,
            postes_id: postes_id,
            concour_id: dossierAcademique.concour_id
        }

        // MAJ des datas persos
        let datas_perso: DatasPersoInterface
        if (dossierAcademique.datas_perso === null) {
            datas_perso = {
                civility: '',
                name: '',
                first_name: '',
                birth_name: '',
                birth_date: new Date(),
                birth_place: '',
                address: '',
                email: '',
                telephone: '',
                telephone_fixe: ''
            }
            state.dossierAcademiqueSelect.datas_perso = datas_perso
        }

        // MAJ des datas pro
        let datas_pro: DatasProInterface
        if (dossierAcademique.datas_pro === null) {
            datas_pro = {
                title: '',
                title_other: '',
                function: '',
                function_other: '',
                level_last_diplome: '',
                level_last_diplome_other: '',
                establishment_type: '',
                establishment_id: '',
                establishment_name: '',
                establishment_address: '',
                academy: '',
                other_mission: '',
                other_mission_function: '',
                other_mission_name: '',
                other_mission_name_other: '',
                disciplines_maitrisees: [],
                selected_etablissements: [],
                diplomes: []
            }
            state.dossierAcademiqueSelect.datas_pro = datas_pro
        }

        state.academie = state.dossierAcademiqueSelect.datas_pro.academy
        state.typeEtab = state.dossierAcademiqueSelect.datas_pro.establishment_type
    },
    SET_DOSSIER_SELECTION_CURRENT(state: any, dossierSelection: any) {
        state.dossierSelectionSelect = {
            user: dossierSelection.user,
            precedent: dossierSelection.precedent,
            courant: dossierSelection.courant,
            classement: dossierSelection.courant ? dossierSelection.courant.classement : null,
            etat: dossierSelection.courant ? dossierSelection.courant.etat : null,
            postes_affectation_courant: dossierSelection.postes_affectation_courant,
            postes_souhait_courant: dossierSelection.postes_souhait_courant,
            postes_affectation_precedent: dossierSelection.postes_affectation_precedent,
            postes_souhait_precedent: dossierSelection.postes_souhait_precedent
        }
    },
    SET_SESSION_ID(state: any, session_id: number) {
        state.session_id = session_id
    },
    SET_DOSSIER_ACADEMIQUE_CONCOURS(state: any, concours: Array<ConcourInterface>) {
        state.dacaConcours = concours
    },
    SET_DOSSIER_ACADEMIQUE_AVATAR(state: any, avatar: string) {
        state.avatar = avatar
    },
    UPDATE_DATAS_NAME(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_perso.name = value
    },
    UPDATE_DATAS_FIRST_NAME(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_perso.first_name = value
    },
    UPDATE_DATAS_CIVILITY(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_perso.civility = value
    },
    UPDATE_DATAS_BIRTH_NAME(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_perso.birth_name = value
    },
    UPDATE_DATAS_BIRTH_DATE(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_perso.birth_date = value
    },
    UPDATE_DATAS_BIRTH_PLACE(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_perso.birth_place = value
    },
    UPDATE_DATAS_EMAIL(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_perso.email = value
    },
    UPDATE_DATAS_TELEPHONE(state: any, value: string) {
        if (value !== '') {
            state.dossierAcademiqueSelect.datas_perso.telephone = value
        }
    },
    UPDATE_DATAS_TELEPHONE_FIXE(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_perso.telephone_fixe = value
    },
    UPDATE_DATAS_ADDRESS(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_perso.address = value
    },
    UPDATE_DATAS_TITRE(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_pro.title = value
    },
    UPDATE_DATAS_TITRE_AUTRE(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_pro.title_other = value
    },
    UPDATE_DATAS_FONCTION_ACTUELLE(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_pro.function = value
    },
    UPDATE_DATAS_FONCTION_ACTUELLE_AUTRE(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_pro.function_other = value
    },
    UPDATE_DATAS_DERNIER_DIPLOME(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_pro.level_last_diplome = value
    },
    UPDATE_DATAS_DERNIER_DIPLOME_AUTRE(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_pro.level_last_diplome_other = value
    },
    UPDATE_DATAS_TYPE_ETABLISSEMENT(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_pro.establishment_type = value
        state.typeEtab = value
    },
    UPDATE_DATAS_ACADEMIE(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_pro.academy = value
        state.academie = value
    },
    UPDATE_DATAS_ETABLISSEMENT_ID(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_pro.establishment_id = value
    },
    UPDATE_DATAS_ETABLISSEMENT_CODE(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_pro.establishment_code = value
    },
    UPDATE_DATAS_ETABLISSEMENT_NAME(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_pro.establishment_name = value
    },
    UPDATE_DATAS_ETABLISSEMENT_ADRESSE(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_pro.establishment_address = value
    },
    UPDATE_DATAS_AUTRE_CONCOURS(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_pro.other_mission = value
    },
    UPDATE_DATAS_AUTRE_CONCOURS_NOM(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_pro.other_mission_name = value
    },
    UPDATE_DATAS_AUTRE_CONCOURS_NOM_AUTRE(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_pro.other_mission_name_other = value
    },
    UPDATE_DATAS_AUTRE_CONCOURS_FONCTION(state: any, value: string) {
        state.dossierAcademiqueSelect.datas_pro.other_mission_function = value
    },
    UPDATE_DISCIPLINES_MAITRISEES(state: any, value: Array<any>) {
        state.dossierAcademiqueSelect.datas_pro.disciplines_maitrisees = value
    },
    UPDATE_SELECTED_ETABLISSEMENTS(state: any, value: Array<any>) {
        state.dossierAcademiqueSelect.datas_pro.selected_etablissements = value
    },
    UPDATE_DIPLOMES(state: any, value: Array<any>) {
        state.dossierAcademiqueSelect.datas_pro.diplomes = value
    },
    SET_HAS_AVATAR(state: any, value: boolean) {
        state.hasAvatar = value
    },
    SET_IS_VALIDE(state: any, value: Date) {
        if (value !== null) {
            state.isValide = true
        } else {
            state.isValide = false
        }
    },
    MAJ_ETAT(state: any, params: any) {
        const dossierSel = state.dossiersAcademiques.find((d: any) => d.user_id === params.user_id)
        if (dossierSel) {
            dossierSel.courant.etat = params.etat
        }
    },
    SET_VIEW_DOSSIER(state: any, view: boolean) {
        state.viewDossier = view
    },
    SET_DOSSIERS_OPEN(state: any, dossiers_open: Array<DossierAcademiqueInterface>) {
        state.dossiersOpen = dossiers_open.length
    },
    SET_PDF_LETTRE(state: any, source_pdf: string) {
        state.pdfLettre = source_pdf
    },
    SET_HAS_RESPONSE(state: any, value: boolean) {
        state.has_response = value
    },
    MAJ_COLLECTION_DA(state: any, dossierAcademique: any) {
        // Maj l'élément dans la collection
        const indexDossier =  state.dossiersAcademiques.findIndex((d: any) => d.user.id === dossierAcademique.user.id)  // dossierAcademique.user.id// state.dossiersAcademiques.findIndex((dossier: any) => dossier.courant.id === dossierAcademique.id)
        // set des nouvelles informations
        //Vue.set(state.dossiersAcademiques, indexDossier, dossierAcademique)
        if (!state.dossiersAcademiques[indexDossier]) {
            state.dossiersAcademiques[indexDossier] = {}
        }
        state.dossiersAcademiques[indexDossier].courant = dossierAcademique

        for (const d in state.dossiersAcademiques) {
            if (
                state.dossiersAcademiques[d].courant.postes.length !== undefined &&
                state.dossiersAcademiques[d].courant.postes.length !== 0
            ) {
                state.dossiersAcademiques[d].courant.postes_souhaits = state.dossiersAcademiques[d].courant.postes.filter(
                    (p: PosteInterface) => p.context_type === PosteContext.CONTEXT_SOUHAIT
                )
                state.dossiersAcademiques[d].courant.postes_selections = state.dossiersAcademiques[d].courant.postes.filter(
                    (p: PosteInterface) => p.context_type === PosteContext.CONTEXT_SELECTION
                )
                state.dossiersAcademiques[d].courant.postes_affectations = state.dossiersAcademiques[d].courant.postes.filter(
                    (p: PosteInterface) => p.context_type === PosteContext.CONTEXT_AFFECTATION
                )
            } else {
                state.dossiersAcademiques[d].courant.postes_souhaits = []
                state.dossiersAcademiques[d].courant.postes_selections = []
                state.dossiersAcademiques[d].courant.postes_affectations = []
            }
        }
    },
    MAJ_COLLECTION_DSR(state: any, dossierAcademique: any) {
        // Maj l'élément dans la collection
        const dossiers_copy = state.dossiersSelections
        const index = dossiers_copy.map((dossier: any) => dossier.courant.id).indexOf(dossierAcademique.id)

        if (index) {
            dossiers_copy[index].courant = dossierAcademique
            dossiers_copy[index].etat = dossierAcademique.etat
            dossiers_copy[index].postes_selection_courant = []
            for (const p in dossiers_copy[index].courant.postes) {
                dossiers_copy[index].courant.postes[p].matieres = dossiers_copy[index].courant.matieres
                dossiers_copy[index].courant.postes[p].concour = dossiers_copy[index].courant.concour
                if (dossiers_copy[index].courant.postes[p].context_type === PosteContext.CONTEXT_SELECTION) {
                    dossiers_copy[index].postes_selection_courant.push(dossiers_copy[index].courant.postes[p])
                }
            }
            Vue.set(state.dossiersSelections, index, dossiers_copy[index])
            state.maj_ds = true
        }
    },
    MAJ_COLLECTION_DSN(state: any, dossierAcademique: any) {
        // Maj l'élément dans la collection
        const dossiers_copy = state.dossiersSelectionsNew
        const index = dossiers_copy.map((dossier: any) => dossier.courant.id).indexOf(dossierAcademique.id)
        dossiers_copy[index].courant = dossierAcademique
        dossiers_copy[index].etat = dossierAcademique.etat
        dossiers_copy[index].postes_selection_courant = []

        for (const p in dossiers_copy[index].courant.postes) {
            dossiers_copy[index].courant.postes[p].matieres = dossiers_copy[index].courant.matieres
            dossiers_copy[index].courant.postes[p].concour = dossiers_copy[index].courant.concour
            if (dossiers_copy[index].courant.postes[p].context_type === PosteContext.CONTEXT_SELECTION) {
                dossiers_copy[index].postes_selection_courant.push(dossiers_copy[index].courant.postes[p])
            }
        }

        Vue.set(state.dossiersSelectionsNew, index, dossiers_copy[index])
        state.maj_dsn = true
    },
    MAJ_COLLECTION_DSALL(state: any, dossierAcademique: any) {
        // Maj l'élément dans la collection
        const dossiers_copy = state.dossiersSelectionsAll
        const index = dossiers_copy
            .map((dossier: any) => dossier.courant && dossier.courant.id)
            .indexOf(dossierAcademique.id)

        const postesCopy = dossiers_copy[index].courant.postes
        dossiers_copy[index].courant = dossierAcademique
        dossiers_copy[index].etat = dossierAcademique.etat

        if (dossiers_copy[index].courant.postes) {
            dossiers_copy[index].postes_affectation_courant = []
            for (const p in dossiers_copy[index].courant.postes) {
                dossiers_copy[index].courant.postes[p].matieres = dossiers_copy[index].courant.matieres
                dossiers_copy[index].courant.postes[p].concour = dossiers_copy[index].courant.concour
                if (dossiers_copy[index].courant.postes[p].context_type === PosteContext.CONTEXT_AFFECTATION) {
                    dossiers_copy[index].postes_affectation_courant.push(dossiers_copy[index].courant.postes[p])
                }
            }
        } else {
            dossiers_copy[index].courant.postes = postesCopy
        }
        Vue.set(state.dossiersSelectionsAll, index, dossiers_copy[index])
        state.maj_dsall = true
    },
    MAJ_DS(state: any, etat: boolean) {
        state.maj_ds = etat
    },
    MAJ_DSN(state: any, etat: boolean) {
        state.maj_dsn = etat
    },
    MAJ_DSALL(state: any, etat: boolean) {
        state.maj_dsall = etat
    }
}

export const actions = {
    getDossiersAcademiques({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAcademiqueService.getDossiersAcademiques(params)
                .then((response) => {
                    setDossierAcademique(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreDossiersAcademiques({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAcademiqueService.getDossiersAcademiques(params)
                .then((response) => {
                    commit('SET_MORE_DOSSIERS_ACADEMIQUES', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDossiersSelections({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAcademiqueService.getDossiersSelections(params.session_id, params.matiere_id, params.params)
                .then((response) => {
                    setDossierSelection(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDossierAcademique({ commit }: { commit: any }, dossier_id: number) {
        return new Promise((resolve, reject) => {
            DossierAcademiqueService.getDossierAcademique(dossier_id)
                .then((response) => {
                    commit('SET_DOSSIER_ACADEMIQUE_CURRENT', response.data.data)
                    commit('SET_SESSION_ID', response.data.data.session_id)
                    commit('document/SET_DOCUMENTS', response.data.data.documents, { root: true })
                    commit('poste/SET_POSTES_SELECTED', response.data.data.postes, { root: true })
                    commit('SET_IS_VALIDE', response.data.data.submitted_at)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getConcoursDossierAcademique({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAcademiqueService.getConcours(params.dossier_id)
                .then((response) => {
                    commit('SET_DOSSIER_ACADEMIQUE_CONCOURS', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getAvatar({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DossierAcademiqueService.getAvatar(params.dossier_id)
                .then((response) => {
                    commit('SET_DOSSIER_ACADEMIQUE_AVATAR', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    // commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    storeAvatar({ commit }: { commit: any }, params: any) {
        // commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAcademiqueService.storeAvatar(params.dossier_id, params.payload)
                .then((response) => {
                    commit('SET_HAS_AVATAR', true)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateDossierAcademique({ commit }: { commit: any }, params: any) {
        // commit('SET_LOADING', true)
        // commit('SET_HAS_RESPONSE', false)
        return new Promise((resolve, reject) => {
            DossierAcademiqueService.updateDossierAcademique(params.dossier_id, params.payload)
                .then((response) => {
                    if (params.mode === 'selection') {
                        if (params.new) {
                            commit('MAJ_COLLECTION_DSN', response.data.data)
                        } else {
                            commit('MAJ_COLLECTION_DSR', response.data.data)
                        }
                    } else if (params.mode === 'affectation') {
                        commit('MAJ_COLLECTION_DSALL', response.data.data)
                    } else {
                        commit('SET_DOSSIER_ACADEMIQUE_CURRENT', response.data.data)
                        commit('MAJ_COLLECTION_DA', response.data.data)
                        commit('SET_IS_VALIDE', response.data.data.submitted_at)
                        commit('MAJ_ETAT', { dossier_id: response.data.data.id, etat: response.data.data.etat, user_id: response.data.data.user_id })
                        // commit('SET_HAS_RESPONSE', true)
                    }
                    // commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    // commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    // commit('SET_HAS_RESPONSE', true)
                    reject(error)
                })
        })
    },
    addDossierAcademique({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        state.countError = 0
        return new Promise((resolve, reject) => {
            DossierAcademiqueService.addDossierAcademique(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR_MESSAGE', null)
                    commit('SET_ERROR_DATAS', null)
                    state.countError = 0
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    if (error.response.status === 409) {
                        commit('SET_ERROR_MESSAGE', error.response.data.message)
                        commit('SET_ERROR_DATAS', error.response.data.data)
                        reject(error)
                    } else {
                        commit('SET_ERROR', getError(error))
                        reject(error)
                    }
                })
        })
    },
    getDossiersAcademiquesOuvert({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAcademiqueService.getDossierAcademiqueOuvert(
                params.session_id,
                params.matiere_id,
                params.isNewCandidature,
                params.payload
            )
                .then((response) => {
                    commit('SET_DOSSIERS_ACADEMIQUES_OUVERT_PAR_ETAT', response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    ouvrirDossierAcademique({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAcademiqueService.ouvrirDossierAcademique(
                params.session_id,
                params.matiere_id,
                params.isNewCandidature,
                params.payload
            )
                .then((response) => {
                    commit('SET_DOSSIERS_OPEN', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    envoiLettreIntervenant({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DossierAcademiqueService.envoiLettreIntervenant(params.dossierId)
                .then((response) => {
                    commit('MAJ_COLLECTION_DSALL', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    envoiLettreStatut({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAcademiqueService.envoiLettreStatut(params.session_id, params.matiere_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    transfertStatut({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAcademiqueService.transfertStatut(params.session_id, params.matiere_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    transfertIntervenant({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAcademiqueService.transfertIntervenant(params.dossierId)
                .then((response) => {
                    commit('MAJ_COLLECTION_DSALL', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    previewLettre({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DossierAcademiqueService.previewLettre(params.dossierId)
                .then((response) => {
                    commit('SET_PDF_LETTRE', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getExportDossiersAcademiques({ commit }: { commit: any }) {
        return new Promise((resolve, reject) => {
            DossierAcademiqueService.export_dossiers_academiques()
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    cancelEnvoiLettreIntervenant({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DossierAcademiqueService.cancelEnvoiLettreIntervenant(params.dossierId)
                .then((response) => {
                    commit('MAJ_COLLECTION_DSALL', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteTransfertDossierAcademique({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DossierAcademiqueService.deleteTransfertDossierAcademique(params.dossierId)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteDossierAcademique({ commit }: { commit: any }, dossierId: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAcademiqueService.deleteDossierAcademique(dossierId)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    dossiersAcademiques: (state: any) => {
        return state.dossiersAcademiques
    },
    dossiersSelections: (state: any) => {
        return state.dossiersSelections
    },
    dossiersSelectionsNew: (state: any) => {
        return state.dossiersSelectionsNew
    },
    dossiersSelectionsAll: (state: any) => {
        return state.dossiersSelectionsAll
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    errorConflict: (state: any) => {
        return state.error_conflict
    },
    totalRows: (state: any) => {
        return state.totalRows
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    sortby: (state: any) => {
        return state.sortby
    },
    dossierAcademiqueSelect: (state: any) => {
        return state.dossierAcademiqueSelect
    },
    dossierSelectionSelect: (state: any) => {
        return state.dossierSelectionSelect
    },
    session_id: (state: any) => {
        return state.session_id
    },
    dacaConcours: (state: any) => {
        return state.dacaConcours
    },
    avatar: (state: any) => {
        return state.avatar
    },
    posteSelect: (state: any) => {
        return state.posteSelect
    },
    hasAvatar: (state: any) => {
        return state.hasAvatar
    },
    academie: (state: any) => {
        return state.academie
    },
    typeEtab: (state: any) => {
        return state.typeEtab
    },
    isValide: (state: any) => {
        return state.isValide
    },
    countError: (state: any) => {
        return state.countError
    },
    viewDossier: (state: any) => {
        return state.viewDossier
    },
    dossiersOpen: (state: any) => {
        return state.dossiersOpen
    },
    pdfLettre: (state: any) => {
        return state.pdfLettre
    },
    dossierAcademiqueByUserId: (state: any) => (user_id: any) => {
        return state.dossiersAcademiques.find((d: any) => d.user.id === user_id)
    }
}
