import DocumentationService from '@/services/DocumentationService'
import { getError } from '@/utils/helpers'

export const namespaced = true

export const state = {
    documentations: [],
    documentation: {},
    loading: false,
    error: null
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            documentations: [],
            documentation: {},
            loading: false,
            error: null
        })
    },
    SET_DOCUMENTATIONS(state: any, documentations: Array<any>) {
        state.documentations = documentations
    },
    SET_DOCUMENTATION(state: any, documentation: any) {
        state.documentation = documentation
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: any) {
        state.error = error
    }
}

export const actions = {
    getDocumentations({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DocumentationService.getDocumentations(params)
                .then((response) => {
                    commit('SET_DOCUMENTATIONS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => commit('SET_LOADING', false))
        })
    },
    getDocumentation({ commit }: { commit: any }, documentation_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DocumentationService.getDocumentation(documentation_id)
                .then((response) => {
                    commit('SET_DOCUMENTATION', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => commit('SET_LOADING', false))
        })
    },
    addDocumentation({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DocumentationService.addDocumentation(payload)
                .then((response) => {
                    commit('SET_DOCUMENTATION', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => commit('SET_LOADING', false))
        })
    },
    deleteDocumentation({ commit }: { commit: any }, documentation_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DocumentationService.deleteDocumentation(documentation_id)
                .then((response) => {
                    commit('SET_DOCUMENTATION', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => commit('SET_LOADING', false))
        })
    },
    getPdfDocumentation({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DocumentationService.getPdfDocumentation(params.documentation_id, params.document_id)
                .then((response) => {
                    commit('SET_DOCUMENTATION', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => commit('SET_LOADING', false))
        })
    }
}

export const getters = {
    documentations: (state: any) => {
        return state.documentations
    },
    documentation: (state: any) => {
        return state.documentation
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    }
}
