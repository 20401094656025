import { getError } from '@/utils/helpers'
import GroupeComparaisonConcourService from '@/services/GroupeComparaisonConcourService'

export const namespaced = true

function setGroupeComparaisonConcours(commit: Function, response: any) {
    commit('SET_GROUPE_COMPARAISON_CONCOURS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: any = {
    groupeComparaisonConcours: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    groupeComparaisonConcourSelect: {}
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            groupeComparaisonConcours: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            groupeComparaisonConcourSelect: {}
        })
    },
    SET_GROUPE_COMPARAISON_CONCOURS(state: any, groupeComparaisonConcours: Array<any>) {
        state.groupeComparaisonConcours = groupeComparaisonConcours
    },
    SET_MORE_GROUPE_COMPARAISON_CONCOURS(state: any, groupeComparaisonConcours: Array<any>) {
        state.groupeComparaisonConcours = state.groupeComparaisonConcours.concat(groupeComparaisonConcours)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_GROUPE_COMPARAISON_CONCOUR_SELECT(state: any, groupeComparaisonConcour: any) {
        state.groupeComparaisonConcourSelect = groupeComparaisonConcour
    }
}

export const actions = {
    getGroupeComparaisonConcours({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GroupeComparaisonConcourService.getGroupeComparaisonConcours(params)
                .then((response: any) => {
                    setGroupeComparaisonConcours(commit, response)
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getGroupeComparaisonConcour({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GroupeComparaisonConcourService.getGroupeComparaisonConcour(params.groupeComparaisonConcour_id)
                .then((response: any) => {
                    commit('SET_GROUPE_COMPARAISON_CONCOUR_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error: any) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreGroupeComparaisonConcours({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GroupeComparaisonConcourService.getGroupeComparaisonConcour(params.groupeComparaisonConcour_id)
                .then((response: any) => {
                    commit('SET_GROUPE_COMPARAISON_CONCOUR_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateGroupeComparaisonConcour({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GroupeComparaisonConcourService.updateGroupeComparaisonConcour(params)
                .then((response: any) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addGroupeComparaisonConcour({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GroupeComparaisonConcourService.addGroupeComparaisonConcour(params)
                .then((response: any) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteGroupeComparaisonConcour({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GroupeComparaisonConcourService.deleteGroupeComparaisonConcour(payload)
                .then((response: any) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    groupeComparaisonConcours: (state: any) => {
        return state.groupeComparaisonConcours
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.groupeComparaisonConcours.length
    }
   
}
