import * as API from '@/services/API'
import store from '@/store'

export default {
    getDossierAcademique(dossierId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/dossieracademiques/${dossierId}`)
    },
    getDossiersAcademiques(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/dossieracademiques`, { params: payload })
    },
    getDossiersSelections(sessionId: number, matiereId: number, params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/matieres/${matiereId}/dossieracademiques`, { params: params })
    },
    getConcours(dossierId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/dossieracademiques/${dossierId}/concours`)
    },
    getAvatar(dossierId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/dossieracademiques/${dossierId}/photo`)
    },
    updateDossierAcademique(dossierId: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/dossieracademiques/${dossierId}`, payload)
    },
    storeAvatar(dossierId: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/dossieracademiques/${dossierId}/photo`, payload)
    },
    addDossierAcademique(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/dossieracademiques`, payload)
    },
    getDossierAcademiqueOuvert(sessionId: number, matiereId: number, isNewCandidature: boolean, payload: any) {
        // const session_id = store.getters['auth/user_session_id']
        const nc = isNewCandidature ? 1 : 0
        return API.apiClient.get(
            `/sessions/${sessionId}/matieres/${matiereId}/dossieracademiques/open?nc=${nc}`,
            payload
        )
    },
    ouvrirDossierAcademique(sessionId: number, matiereId: number, isNewCandidature: boolean, payload: any) {
        // const session_id = store.getters['auth/user_session_id']
        const nc = isNewCandidature ? 1 : 0
        return API.apiClient.post(
            `/sessions/${sessionId}/matieres/${matiereId}/dossieracademiques/open?nc=${nc}`,
            payload
        )
    },
    envoiLettreStatut(sessionId: number, matiereId: number, payload: any) {
        // const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${sessionId}/matieres/${matiereId}/dossieracademiques/lettre`, payload)
    },
    envoiLettreIntervenant(dossierId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/dossieracademiques/${dossierId}/lettre`)
    },
    cancelEnvoiLettreIntervenant(dossierId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/dossieracademiques/${dossierId}/lettre/-1`)
    },
    transfertStatut(sessionId: number, matiereId: number, payload: any) {
        // const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${sessionId}/matieres/${matiereId}/dossieracademiques/transfert`, payload)
    },
    transfertIntervenant(dossierId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/dossieracademiques/${dossierId}/transfert`)
    },
    previewLettre(dossierId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/dossieracademiques/${dossierId}/lettre/1?format=b64`)
    },
    export_dossiers_academiques() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/dossieracademiques_export`, {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    },
    deleteTransfertDossierAcademique(dossierId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/dossieracademiques/${dossierId}/transfert`)
    },
    deleteDossierAcademique(dossierId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/dossieracademiques/${dossierId}`)
    }

    // DELETE {{urlapi}}/api/sessions/{sessionId}/dossieracademiques/{dossierAcaId}/transfert
}
