import * as API from '@/services/API'
import store from '@/store/index'
import { JobDescriptionStatus } from '@/types/JobDescription'

export default {
    async getCandidatJobDescription(candidatJobDescriptionId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/candidatjobdescription/${candidatJobDescriptionId}`)
    },
    async getCandidatJobDescriptions(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/candidatjobdescription`, { params: params })
    },
    async putCandidatJobDescriptionStatus(jobDescriptionId: number, status: JobDescriptionStatus) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/candidatjobdescription/${jobDescriptionId}`, { status: status })
    },
    async addCandidacy(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/candidatjobdescription`, payload)
    },
    async searchCandidates(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/candidatjobdescription/searchcandidat`, { params: params })
    },
    async getActivityLog(jobdescriptions_id: number | null) {
        const session_id = store.getters['auth/user_session_id']
        if (jobdescriptions_id) {
            return await API.apiClient.get(`/sessions/${session_id}/activitylogs/candidatjobdescription/${jobdescriptions_id}`)
        } else {
            return await API.apiClient.get(`/sessions/${session_id}/activitylogs/candidatjobdescription`)
        }
    }
}
