import * as API from '@/services/API'
import store from '@/store'

export default {
    async getSession(sessionId: number) {
        const actual_session_id = sessionId || store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${actual_session_id}`)
    },
    async getSessions(payload: any) {
        return await API.apiClient.get('/sessions', { params: payload })
    },
    async getSessionsActives() {
        return await API.apiClient.get('/sessions?active')
    },
    async updateSession(session_id: number, payload: any) {
        const actual_session_id = session_id || store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${actual_session_id}`, payload)
    },
    async cloneSession(payload: any) {
        return await API.apiClient.post(`/sessions/${payload.session_id}/clone`, payload)
    },
    async setGabaritHoraire(sessionId: number, payload: number) {
        const actual_session_id = sessionId || store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${actual_session_id}/fiche_horaire`, payload)
    },
    /* update de la plage de saisie pour les incompatibilités */
    async updateSessionPlages(payload: any) {
        const actual_session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${actual_session_id}/plages`, payload)
    },
    async updateSessionAfex(session_id: number, payload: any) {
        return await API.apiClient.put(`/sessions/${session_id}/afex`, payload)
    },
    async addSession(payload: any) {
        return await API.apiClient.post('/sessions', payload.payload)
    },
    async save_regles(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/regles`, payload)
    },
    async save_publication_inventaire(session_id: any, payload: any) {
        return await API.apiClient.put(`/sessions/${session_id}/inventaire_fournitures`, payload)
    },
    async getSessionsForEditUserAbility(payload: any) {
        return await API.apiClient.get('/sessions?forabilities=1', { params: payload })
    }
}
