// jobDescription

import * as API from '@/services/API'
import store from '@/store/index'
import { AxiosRequestConfig } from 'axios'

export default {
    async getJobDescription(jobDescriptionId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/jobdescriptions/${jobDescriptionId}`)
    },
    async getJobDescriptions(payload: any = null) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/jobdescriptions`, { params: payload })
    },
    async updateJobDescription(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/jobdescriptions/${payload.id}`, payload)
    },
    async addJobDescription(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/jobdescriptions`, payload)
    },
    async deleteJobDescription(jobDescriptionId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/jobdescriptions/${jobDescriptionId}`)
    },
    async export() {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return await API.apiClient.get(
            `/sessions/${session_id}/jobdescriptions_export`,
            params
        )
    },
    async import(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const formData = new FormData()
        formData.append('document', payload)
        return await API.apiClient.post(`/sessions/${session_id}/jobdescription/import`, formData) // , config)
    },
    async addJobDocument(job_id: number, document: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/jobdescriptions/${job_id}/documents`, document)
    },
    async getJobDocument(job_id: number, document_uuid: string) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/jobdescriptions/${job_id}/documents/${document_uuid}?format=b64`)
    },
    async deleteJobDocument(job_id: number, document_uuid: string) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/jobdescriptions/${job_id}/documents/${document_uuid}`)
    },
    async updateStatus(job_id: number, status: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/jobdescriptions/${job_id}/update_status`, { status: status })
    },
    async getActivityLog(jobdescriptions_id: number | null) {
        const session_id = store.getters['auth/user_session_id']
        if (jobdescriptions_id) {
            return await API.apiClient.get(`/sessions/${session_id}/activitylogs/jobdescription/${jobdescriptions_id}`)
        } else {
            return await API.apiClient.get(`/sessions/${session_id}/activitylogs/jobdescription`)
        }
    }
}
