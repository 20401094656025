import * as API from '@/services/API'
import store from '@/store'

export default {
    async getPublishableMessage(publishableMessageId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/publishablemessages/${publishableMessageId}`)
    },
    async getPublishableMessages(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/publishablemessages`, { params: payload })
    },
    async updatePublishableMessage(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/publishablemessages/${payload.id}`, payload)
    },
    async addPublishableMessage(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/publishablemessages`, payload)
    },
    async deletePublishableMessage(publishableMessage_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/publishablemessages/${publishableMessage_id}`)
    }
}
