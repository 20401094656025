import * as API from '@/services/API'
import store from '@/store'
import { AxiosRequestConfig } from 'axios'

export default {
    async getEpreuveCorrectionResultat(epreuveCorrectionResultatId: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(
            `/sessions/${session_id}/epreuvecorrectionresultats/${epreuveCorrectionResultatId}`, { params: payload }
        )
    },
    async getEpreuveCorrectionResultats(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/epreuvecorrectionresultats`, { params: payload })
    },
    // GET /epreuvecorrections/{epreuve_correction_id}/notes
    async getEpreuveCorrectionResultatsNotes(epreuve_correction_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(
            `/sessions/${session_id}/epreuvecorrections/${epreuve_correction_id}/notes`, { params: payload}
        )
    },
    async getEpreuveNotes(epreuveId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/epreuves/${epreuveId}/notes`)
    },
    async updateEpreuveCorrectionResultat(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/epreuvecorrectionresultat/${payload.id}`, payload)
    },
    async addEpreuveCorrectionResultat(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/epreuvecorrectionresultat`, payload)
    },
    async deleteEpreuveCorrectionResultat(epreuveCorrectionResultat_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(
            `/sessions/${session_id}/epreuvecorrectionresultat/${epreuveCorrectionResultat_id}`
        )
    },
    async updateEpreuveCorrectionCorrecteur(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(
            `/sessions/${session_id}/epreuvecorrectioncorrecteurs/${payload.epreuvecorrectioncorrecteur_id}`,
            payload
        )
    },
    saveAdjustement (epreuve_correction_resultat_id: number, adjustement_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(
            `/sessions/${session_id}/epreuvecorrectionresultats/${epreuve_correction_resultat_id}/adjustments/${adjustement_id}/adjust`,
            payload
        )
    },
    valideAdjustement (epreuve_correction_resultat_id: number, adjustement_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(
            `/sessions/${session_id}/epreuvecorrectionresultats/${epreuve_correction_resultat_id}/adjustments/${adjustement_id}/validate`,
            payload
        )
    },
    invalidateAdjustement (epreuve_correction_resultat_id: number, adjustement_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(
            `/sessions/${session_id}/epreuvecorrectionresultats/${epreuve_correction_resultat_id}/adjustments/${adjustement_id}/invalidate`,
            payload
        )
    },
    async exportAjustement(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return await API.apiClient.get(
            `/sessions/${session_id}/epreuvecorrectionresultats/${payload.epreuvecorrection_id}/export`,
            params
        )
    },
    async exportStatistiquesAjustement(concour_id: number, externes: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(
            `/sessions/${session_id}/statistiques_ajustements/concours/${concour_id}/export`,
            {
                params: {
                    format: 'b64',
                    externes: externes
                },
                headers: { 'X-Request-Retry': 0 }
            }
        )
    },
    async exportNotesEpreuvesExternes(concour_id: number, phase_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(
            `/sessions/${session_id}/concours/${concour_id}/phases/${phase_id}/notes_externes_export`,
            {
                headers: {
                    'X-Request-Retry': 0,
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
                responseType: 'arraybuffer'
            }
        )
    },
    async exportModeleImportNotes() {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(
            `/sessions/${session_id}/export_modele_import_notes`,
            {
                params: { format: 'b64' },
                responseType: 'arraybuffer',
                headers: { 'X-Request-Retry': 0 }
            }
        )
    },
    async importNotes(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const formData = new FormData()
        formData.append('document', payload)
        return await API.apiClient.post(`/sessions/${session_id}/import_notes`, formData)
    }
}
