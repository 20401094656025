import * as API from '@/services/API'
import store from '@/store'

export default {
    getReclamations(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/reclamations`, { params: payload })
    },
    getReclamation(reclamation_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/reclamations/${reclamation_id}`)
    },
    updateReclamation(reclamation_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/reclamations/${reclamation_id}`, payload)
    },
    deleteReclamation(reclamation_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/reclamations/${reclamation_id}`)
    },
    addReclamation(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/reclamations`, payload)
    },
    getReclamationsCompteurs(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/reclamations_compteurs`, { params: payload })
    },
    syncRecorrections(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/reclamations_recorrections`, { params: payload })
    },
    exporter(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/reclamations_export`, {
            params: payload,
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    },
    exporterStatistiques(payload: any) {
        // reclamations_statistiques_export
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/reclamations_statistiques_export`, {
            params: payload,
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    },
    addMessage(reclamation_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/reclamations/${reclamation_id}/messages`, payload)
    },
    download_pj(reclamation_id: number, message_id: number, format: string) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/reclamations/${reclamation_id}/messages/${message_id}`, {
            params: { format: format }
        })
    },
    getPDFReclamationCandidat(reclamation_id: number, uuid: string) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/reclamations/${reclamation_id}/pdf_candidat/${uuid}`, {
            params: { format: 'b64' }
        })
    },
    getPDFReponseReclamation(reclamation_id: number, params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/reclamations/${reclamation_id}/pdf_reponse`, {
            params: {
                format: 'b64',
                ...params
            }
        })
    },
    sendRecorrection(reclamation_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/reclamations/${reclamation_id}/recorrection`, payload)
    },
    sendRecorrections(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/reclamations_recorrections`, payload)
    },
    getSimulationReclamation(reclamation_id: number, note: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/reclamations/${reclamation_id}/simulate/${note}`)
    }
}
