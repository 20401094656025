import { getError } from '@/utils/helpers'
import DossierInscriptionService from '@/services/DossierInscriptionService'
import { DossierInscription, ParamsTrie } from '@/types/DossierInscription'

export const namespaced = true

const exemple_params: ParamsTrie = {
    direction: '',
    sort: '',
    page: 1
}

export const state = {
    dossiers: [] as DossierInscription[],
    dossier: {} as DossierInscription,
    publication_id: 0,
    params: exemple_params,
    meta: null,
    loading: false,
    error: null
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            dossiers: [] as DossierInscription[],
            dossier: {} as DossierInscription,
            publication_id: 0,
            params: exemple_params,
            meta: null,
            loading: false,
            error: null
        })
    },
    RESET_PARAMS(state: any): void {
        const params = JSON.parse(JSON.stringify(exemple_params))
        Object.freeze(params)
        state.params = params
    },
    SET_DOSSIERS(state: any, response: any): void {
        let dossiers: DossierInscription[] = []
        if (response && response.data && response.data.data) {
            dossiers = response.data.data
        }

        Object.freeze(dossiers)
        state.dossiers = dossiers
    },
    SET_MORE_DOSSIERS(state: any, response: any): void {
        let dossiers: DossierInscription[] = []
        if (response && response.data && response.data.data) {
            dossiers = response.data.data
        }

        Object.freeze(dossiers)
        state.dossiers = state.dossiers.concat(dossiers)
    },
    SET_DOSSIER(state: any, response: any): void {
        let dossier: DossierInscription = {} as DossierInscription
        if (response && response.data && response.data.data) {
            dossier = response.data.data
        }

        Object.freeze(dossier)
        state.dossier = dossier
    },
    UPDATE_DOSSIER(state: any, response: any): void {
        let dossier: DossierInscription = {} as DossierInscription
        if (response && response.data && response.data.data) {
            dossier = {
                ...state.dossier,
                ...response.data.data,
                publication: {
                    ...state.dossier.publication,
                    ...response.data.data.publication,
                    entity: state.dossier.publication.entity ?? null
                }
            }
        }
        const dossiers = JSON.parse(JSON.stringify(state.dossiers))
        const index = dossiers.findIndex((d: DossierInscription) => d.id === dossier.id)

        Object.freeze(dossier)
        state.dossier = dossier

        const dossier_: DossierInscription = JSON.parse(JSON.stringify(state.dossier))
        if (dossier_.publication) {
            delete dossier_.publication
        }

        dossiers[index] = dossier_
        Object.freeze(dossiers)
        state.dossiers = dossiers
    },
    SET_PUBLICATION_ID(state: any, publication_id: number): void {
        Object.freeze(publication_id)
        state.publication_id = publication_id
    },
    SET_PARAMS(state: any, params: ParamsTrie): void {
        Object.freeze(params)
        state.params = params
    },
    SET_META(state: any, response: any): void {
        let meta = null
        if (response && response.data && response.data.meta) {
            meta = response.data.meta
        }

        Object.freeze(meta)
        state.meta = meta
    },
    SET_LOADING(state: any, boolean: boolean): void {
        Object.freeze(boolean)
        state.loading = boolean
    },
    SET_ERROR(state: any, error: any): void {
        if (error) {
            error = getError(error)
        }
        Object.freeze(error)
        state.error = error
    }
}

export const actions = {
    getDossiersInscription({ commit }: { commit: any }, params: { publication_id: number; payload: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierInscriptionService.getDossiersInscription(params.publication_id, params.payload)
                .then((response) => {
                    commit('SET_DOSSIERS', response)
                    commit('SET_PUBLICATION_ID', params.publication_id)
                    commit('SET_META', response)
                    commit('SET_PARAMS', params.payload)
                    commit('SET_ERROR', null)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_DOSSIERS', [] as DossierInscription[])
                    commit('SET_PUBLICATION_ID', 0)
                    commit('SET_META', null)
                    commit('SET_PARAMS', exemple_params)
                    commit('SET_ERROR', error)
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getMoreDossiersInscription({ commit }: { commit: any }, params: { publication_id: number; payload: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierInscriptionService.getDossiersInscription(params.publication_id, params.payload)
                .then((response) => {
                    commit('SET_MORE_DOSSIERS', response)
                    commit('SET_PUBLICATION_ID', params.publication_id)
                    commit('SET_META', response)
                    commit('SET_PARAMS', params.payload)
                    commit('SET_ERROR', null)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', error)
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getExportDossiersInscription({ commit }: { commit: any }, params: { publication_id: number }) {
        return new Promise((resolve, reject) => {
            DossierInscriptionService.getExportDossiersInscription(params.publication_id)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getDossierInscription({ commit }: { commit: any }, params: { dossier_id: number }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierInscriptionService.getDossierInscription(params.dossier_id)
                .then((response) => {
                    commit('SET_DOSSIER', response)
                    commit('SET_ERROR', null)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_DOSSIER', {} as DossierInscription)
                    commit('SET_ERROR', error)
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    postDossierInscription({ commit }: { commit: any }, params: { payload: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierInscriptionService.postDossierInscription(params.payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', error)
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    putFormulaireDossierInscription(
        { commit }: { commit: any },
        params: {
            dossier_inscription_id: number,
            payload: any
        }
    ) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierInscriptionService.putFormulaireDossierInscription(params.dossier_inscription_id, params.payload)
                .then((response) => {
                    commit('UPDATE_DOSSIER', response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', error)
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    putEtatDossierInscription({ commit }: { commit: any }, params: { dossier_inscription_id: number; payload: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierInscriptionService.putEtatDossierInscription(params.dossier_inscription_id, params.payload)
                .then((response) => {
                    commit('UPDATE_DOSSIER', response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', error)
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    async getDocument({ commit }: { commit: any }, params: { dossier_inscription_id: number; document_id: string }) {
        commit('SET_LOADING', true)
        return await new Promise((resolve, reject) => {
            DossierInscriptionService.getDocument(params.dossier_inscription_id, params.document_id)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', error)
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getDocumentReference(
        { commit }: { commit: any },
        params: { dossier_inscription_id: number; document_uuid: string }
    ) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierInscriptionService.getDocumentReference(params.dossier_inscription_id, params.document_uuid)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', error)
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    postDocument({ commit }: { commit: any }, params: { dossier_inscription_id: number; payload: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierInscriptionService.postDocument(params.dossier_inscription_id, params.payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', error)
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    deleteDocument({ commit }: { commit: any }, params: { dossier_inscription_id: number; document_uuid: string }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierInscriptionService.deleteDocument(params.dossier_inscription_id, params.document_uuid)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', error)
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    postNouveauDossierPapier({ commit }: { commit: any }, params: { publication_id: number; payload: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierInscriptionService.postNouveauDossierPapier(params.publication_id, params.payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', error)
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {
    dossiers: (state: any): DossierInscription[] => {
        return state.dossiers
    },
    dossier: (state: any): DossierInscription => {
        return state.dossier
    },
    params: (state: any): ParamsTrie => {
        return state.params
    },
    meta: (state: any): any => {
        return state.meta
    },
    publication_id: (state: any): number => {
        return state.publication_id
    },
    loading: (state: any): boolean => {
        return state.loading
    },
    error: (state: any): any => {
        return state.error
    }
}
