import { getError } from '@/utils/helpers'
import AvenantService from '@/services/AvenantService'

export const namespaced = true
export const namespace = true

export const state: any = {
    avenant_select: null,
    loading: false,
    error_avenant: null,
    source_pdf_avenant: '',
    reponse_token: null
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            avenant_select: null,
            loading: false,
            error_avenant: null,
            source_pdf_avenant: '',
            reponse_token: null
        })
    },
    SET_AVENANT_SELECT(state: any, avenant: any) {
        state.avenant_select = avenant
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error_avenant = error
    },
    SET_PDF(state: any, source: string) {
        state.source_pdf_avenant = source
    },
    SET_REPONSE_TOKEN(state: any, response: any) {
        state.reponse_token = response
    }
}

export const actions = {
    saveAvenant({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AvenantService.storeAvenant(params.contrat_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_AVENANT_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateAvenant({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AvenantService.updateAvenant(params.contrat_id, params.avenant_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_AVENANT_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getAvenantPDF({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AvenantService.getPDF(params.contrat_id, params.avenant_id)
                .then((response) => {
                    commit('SET_PDF', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteAvenant({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AvenantService.deleteAvenant(params.contrat_id, params.avenant_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getTokenSignature({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AvenantService.getTokenSignature(params.contrat_id, params.avenant_id, params.mode)
                .then((response) => {
                    commit('SET_REPONSE_TOKEN', response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {}
