import { getError } from '@/utils/helpers'
import AvenantTypeService from '@/services/AvenantTypeService'
import { AvenantTypeInterface, StateAvenantType } from '@/types/AvenantType'

export const namespaced = true
export const namespace = true

function setAvenantsTypes(commit: any, response: any) {
    commit('SET_AVENANT_TYPES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateAvenantType = {
    avenanttypes: [],
    avenanttype_select: null,
    meta: null,
    links: null,
    loading: false,
    error_avenant: null,
    lastPage: 0,
    totalRows: 0,
    source_pdf: ''
}

export const mutations = {
    RESET_STATE(state: StateAvenantType) {
        Object.assign(state, {
            avenanttypes: [],
            avenanttype_select: null,
            meta: null,
            links: null,
            loading: false,
            error_avenant: null,
            lastPage: 0,
            totalRows: 0,
            source_pdf: ''
        })
    },
    SET_AVENANT_TYPES(state: any, avenantstypes: Array<AvenantTypeInterface>) {
        state.avenanttypes = avenantstypes
    },
    SET_AVENANT_TYPE(state: any, avenanttype: AvenantTypeInterface) {
        state.avenanttype_select = avenanttype
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error_avenant = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_PDF(state: any, source: string) {
        state.source_pdf = source
    }
}

export const actions = {
    getAvenantTypes({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AvenantTypeService.getAvenantTypes(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    setAvenantsTypes(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getAvenantType({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AvenantTypeService.getAvenantType(params.avenant_type_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_AVENANT_TYPE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateAvenantType({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AvenantTypeService.updateAvenantType(params.avenanttype_id, params.payload)
                .then((response) => {
                    commit('SET_AVENANT_TYPE', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    saveAvenantType({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AvenantTypeService.saveAvenantType(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_AVENANT_TYPE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteAvenantType({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AvenantTypeService.deleteAvenantType(params.avenanttype_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPDF({ commit }: { commit: any }, avenanttype_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AvenantTypeService.getPDF(avenanttype_id)
                .then((response) => {
                    commit('SET_PDF', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    avenanttypes: (state: StateAvenantType) => {
        return state.avenanttypes
    },
    avenanttype_select: (state: StateAvenantType) => {
        return state.avenanttype_select
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error_avenant
    },
    totalRows: (state: any) => {
        return state.concours.length
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    source_pdf: (state: any) => {
        return state.source_pdf
    }
}
