/** GROUPEMENTS ABILITIES */
export function getGroupes() {
    const groupes = [
        {prefixe: 'adm', name: 'Administration', abilities: {}},
        {prefixe: 'cand', name: 'Gestion des candidats', abilities: {}},
        {prefixe: 'gc', name: 'Gestion des centres', abilities: {}},
        {prefixe: 'ecr', name: 'Préparation et Gestion des épreuves écrites', abilities: {}},
        {prefixe: 'oral', name: 'Préparation et Gestion des épreuves orales', abilities: {}},
        {prefixe: 'dlb', name: 'Finalisation et délibération', abilities: {}},
        {prefixe: 'rh', name: 'Recrutement des intervenants', abilities: {}},
        {prefixe: 'sign', name: 'Signature', abilities: {}},
        {prefixe: 'own', name: 'Autres permissions', abilities: {}},
        {prefixe: 'gci', name: 'Gestion des centres - Intervenants', abilities: {}},
        {prefixe: 'est', name: 'Gestion des établissements', abilities: {}}
    ]
    return groupes
}

export enum Ability {
    // Paramétrage des options générales de la plateforme et configuration initiale
    ADM_PAR_VIEW = 'adm.par-view',
    ADM_PAR_MANAGE = 'adm.par-manage',

    // Création et modification des sessions, paramétrages des concours et des épreuves
    ADM_INI_VIEW = 'adm.ini-view',
    ADM_INI_MANAGE = 'adm.ini-manage',

    // Gestion des utilisateurs et de leurs droits
    ADM_UTIL_VIEW = 'adm.util-view',
    ADM_UTIL_MANAGE = 'adm.util-manage',

    // Gestion des candidats d’une session, gestion des inscriptions aux concours et aux épreuves, gestion des mesures d’handicaps et applications des mesures d’handicaps aux candidats
    CAND_VIEW = 'cand-view',
    CAND_MANAGE = 'cand-manage',

    // Gestion des dossiers académiques, de la procédure de sélection et de l’affectation des intervenants scientifiques
    RH_SPS_VIEW = 'rh.sps-view',
    RH_SPS_MANAGE = 'rh.sps-manage',

    // Renseigner son dossier académique pour un intervenant
    RH_SPS_DOS_OWN_MANAGE = 'rh.sps-dos-own-manage',

    // Gestion des dossiers administratifs et validation des dossiers et des pièces justificatives
    RH_DOSADM_VIEW = 'rh.dosadm-view',
    RH_DOSADM_MANAGE = 'rh.dosadm-manage',

    // Gestion des types de contrats, gestion des barèmes de rémunération, affectation d’un contrat à un intervenant
    RH_CONTRAT_VIEW = 'rh.contrat-view',
    RH_CONTRAT_MANAGE = 'rh.contrat-manage',

    // Gestion des ordres de mission et des remboursements des frais
    RH_FRAIS_VIEW = 'rh.frais-view',
    RH_FRAIS_MANAGE = 'rh.frais-manage',

    // Droit de renseigner son dossier administratif, intervenant
    RH_ADM_DOS_OWN_MANAGE = 'rh.adm-dossier-own-manage',

    // Signer les documents
    SIGN_CONTRAT_VIEW = 'sign.contrat-view',
    SIGN_CONTRAT_MANAGE = 'sign.contrat-manage',

    SIGN_PVA_VIEW = 'sign.pva-view',
    SIGN_PVA_MANAGE = 'sign.pva-manage',

    SIGN_LETTRE_VIEW = 'sign.lettre-view',
    SIGN_LETTRE_MANAGE = 'sign.lettre-manage',

    SIGN_CONVENTION_VIEW = 'sign.convention-view',
    SIGN_CONVENTION_MANAGE = 'sign.convention-manage',

    SIGN_CONVENTIONANNEXE_VIEW = 'sign.conventionannexe-view',
    SIGN_CONVENTIONANNEXE_MANAGE = 'sign.conventionannexe-manage',

    SIGN_FHRECAP_VIEW = 'sign.fhrecap-view',
    SIGN_FHRECAP_MANAGE = 'sign.fhrecap-manage',

    SIGN_RECLAMATION_VIEW = 'sign.reclamation-view',
    SIGN_RECLAMATION_MANAGE = 'sign.reclamation-manage',

    SIGN_AVENANT_VIEW = 'sign.avenant-view',
    SIGN_AVENANT_MANAGE = 'sign.avenant-manage',

    SIGN_AMENAGEMENT_VIEW = 'sign.amenagement-view',
    SIGN_AMENAGEMENT_MANAGE = 'sign.amenagement-manage',

    // Gestion et suivi des saisies des intervenants des centres par le chef de centre, validation des listes d’intervenant
    RH_SOL_VIEW = 'rh.sol-view',
    RH_SOL_MANAGE = 'rh.sol-manage',

    // Gérer son centre , chef de centre/correspondant d'oral
    // GC_GESTION_OWN_MANAGE = 'gc.gestion-own-manage',

    // Droit de signer la convention de son centre, chef d'établissement
    // GC_RESP_OWN_MANAGE = 'gc.resp-own-manage',

    // Gestion des centres de passation, définition des adresses et création des gestionnaires et des chefs d'établissement, gestion des villes
    GC_CENTER_VIEW = 'gc.center-view',
    GC_CENTER_MANAGE = 'gc.center-manage',

    // Gestion des types de conventions des centres, affectation d’une convention à un centre, gestion du suivi de la signature des conventions
    GC_CONVENTION_VIEW = 'gc.convention-view',
    GC_CONVENTION_MANAGE = 'gc.convention-manage',

    // Gestion de la préparation des épreuves orales, gestion des salles d’oral, gestion des incompatibilités, gestion des disponibilités des examinateurs, gestion des indisponibilités
    ORAL_PREPA_VIEW = 'oral.prepa-view',
    ORAL_PREPA_MANAGE = 'oral.prepa-manage',

    // Création des équipes d’oral, affectation des équipes sur les centres d’oral, gestion des affectations des examinateurs dans les équipes, gestion de remplacement d’un examinateur
    ORAL_AFEX_VIEW = 'oral.afex-view',
    ORAL_AFEX_MANAGE = 'oral.afex-manage',

    // Gestion des planifications, lancement des algorithmes de dispatching, modification manuelle d’un planning, validation des plannings
    ORAL_PLAN_VIEW = 'oral.plan-view',
    ORAL_PLAN_MANAGE = 'oral.plan-manage',

    // un intervenant gère ses dispos et incompats
    INTERV_PLANIF_OWN = 'interv.planif-own',

    // un intervenant supplée un examinateur
    INTERV_SUPPLEANT_OWN = 'interv.suppleant-own',

    // un intervenant peut signer sa fiche horaire
    RH_FICHEHORAIRE_OWN_SIGN = 'rh.fichehoraire-own-sign',

    // Gestion de la préparation des délibérations, harmonisation et normalisation des notes, définition des barres
    DLB_PREPJURY_VIEW = 'dlb.prepjury-view',
    DLB_PREPJURY_MANAGE = 'dlb.prepjury-manage',

    // Gestion et suivi des publications des notes des différentes phases (admissibilités et admissions)
    DLB_PUBNOT_VIEW = 'dlb.pubnot-view',
    DLB_PUBNOT_MANAGE = 'dlb.pubnot-manage',


    // Gestion de la préparation des épreuves écrites, gestion des salles d’écrits, préparation des convocations des candidats, répartition des candidats dans les centres et dans les salles, gestion des numéros de table
    ECR_PREPA_VIEW = 'ecr.prepa-view',
    ECR_PREPA_MANAGE = 'ecr.prepa-manage',

    // Définition des références des sujets par épreuves, gestion des quantités de sujet par centres et par salles, gestion des échanges avec l’imprimeur
    ECR_SUJET_VIEW = 'ecr.sujet-view',
    ECR_SUJET_MANAGE = 'ecr.sujet-manage',

    // Gestion des modèles de fourniture (candidats autorisés à concourir, à composer, étiquettes de table, PV pour les surveillants, étiquettes de conditionnement et liste d’émargements), génération des fournitures, Suivi des impressions et des livraisons aux centres
    ECR_GFOU_VIEW = 'ecr.gfou-view',
    ECR_GFOU_MANAGE = 'ecr.gfou-manage',

    // Gestion et suivi des réclamations sur les seances des épreuves orales
    ORAL_SEANCE_RECLAM_VIEW = 'oral.seance-reclam-view',
    ORAL_SEANCE_RECLAM_MANAGE = 'oral.seance-reclam-manage',

    // Gestion et suivi des réclamations des épreuves écrites
    ECR_RECLAM_VIEW = 'ecr.reclam-view',
    ECR_RECLAM_MANAGE = 'ecr.reclam-manage',

    // Gestion et suivi des réclamations des épreuves orales
    ORAL_RECLAM_VIEW = 'oral.reclam-view',
    ORAL_RECLAM_MANAGE = 'oral.reclam-manage',

    // Gestion de la publication des plannings pour tous les acteurs, gestion et suivi des saisies d’information des correspondants d’oral, suivi des saisies des notes des examinateurs, publication des convocations des candidats
    ORAL_SUIVI_VIEW = 'oral.suivi-view',
    ORAL_SUIVI_MANAGE = 'oral.suivi-manage',

    // Génération des fournitures (fiche notation, fiche pointage, fiche mesure, etiquette centre, etiquette examinateur), Suivi des impressions
    ORAL_GFOU_VIEW = 'oral.gfou-view',
    ORAL_GFOU_MANAGE = 'oral.gfou-manage',

    // Intervenant qui occupe le poste d'observateur
    INTERV_OBSERV_MATIERE_OWN = 'interv.observ-matiere-own-manage',

    // Intervenant de centre : Chef de centre, responsable, secretaire
    GCI_CONVENTION_OWN_VIEW = 'gci.convention-own-view',
    GCI_CONVENTION_OWN_MANAGE = 'gci.convention-own-manage',
    GCI_CONVENTION_OWN_SIGN = 'gci.convention-own-sign',

    GCI_ANNEXE_CONVENTION_OWN_VIEW = 'gci.annexe-convention-own-view',
    GCI_ANNEXE_CONVENTION_OWN_MANAGE = 'gci.annexe-convention-own-manage',
    GCI_ANNEXE_CONVENTION_OWN_SIGN = 'gci.annexe-convention-own-sign',

    GCI_ORGA_OWN_VIEW = 'gci.orga-own-view',
    GCI_ORGA_OWN_MANAGE = 'gci.orga-own-manage',

    GCI_SAISIE_INTERV_OWN_VIEW = 'gci.saisie-interv-own-view',
    GCI_SAISIE_INTERV_OWN_MANAGE = 'gci.saisie-interv-own-manage',

    GCI_FICHES_HORAIRES_OWN_VIEW = 'gci.fiches-horaires-own-view',
    GCI_FICHES_HORAIRES_OWN_MANAGE = 'gci.fiches-horaires-own-manage',
    GCI_FICHES_HORAIRES_OWN_SIGN = 'gci.fiches-horaires-own-sign',

    GCI_SUIVI_FICHES_HORAIRES_OWN_VIEW = 'gci.suivi-fiches-horaires-own-view',
    GCI_SUIVI_FICHES_HORAIRES_OWN_MANAGE = 'gci.suivi-fiches-horaires-own-manage',

    GCI_DESCR_OWN_VIEW = 'gci.descr-own-view',
    GCI_DESCR_OWN_MANAGE = 'gci.descr-own-manage',

    GCI_INFOS_OWN_VIEW = 'gci.infos-own-view',
    GCI_INFOS_OWN_MANAGE = 'gci.infos-own-manage',

    GCI_EXAMINATEURS_OWN_VIEW = 'gci.examinateurs-own-view',
    GCI_CANDIDATS_OWN_VIEW = 'gci.candidats-own-view',
    GCI_IMPRESSIONS_OWN_VIEW = 'gci.impressions-own-view',
    GCI_PLANNINGS_OWN_VIEW = 'gci.plannings-own-view',
    GCI_DEMISSIONNAIRES_OWN_VIEW = 'gci.demissionnaires-own-view',

    GCI_FOURNITURES_OWN_VIEW = 'gci.fournitures-own-view',
    GCI_FOURNITURES_OWN_MANAGE = 'gci.fournitures-own-manage',

    DLB_CHOIX_POSTES_VIEW = 'dlb.choix-postes-view',
    DLB_CHOIX_POSTES_MANAGE = 'dlb.choix-postes-manage',

    // Gestion des formulaires d'inscriptions
    CAND_FORMINSC_VIEW = 'cand.forminsc-view',
    CAND_FORMINSC_MANAGE = 'cand.forminsc-manage',

    // Gestion des formulaires d'inscriptions
    CAND_DOSSIER_VIEW = 'cand.dossier-view',
    CAND_DOSSIER_MANAGE = 'cand.dossier-manage',

    // pour donner des droits sur des ressources de structure : session ou concours
    RESS_SESSION_VIEW = 'ress.session-view',
    RESS_CONCOUR_VIEW = 'ress.concour-view',

    // Modification des contacts par un chef de centre
    GCI_CONTACTS_OWN_MANAGE = 'gci.contacts-own-manage',

    // to give external stakeholders the rights to review the results
    EXT_RESULTS_VIEW = 'ext.results-view',

    // to give external stakeholders the rights to review the results
    EXT_BARRE_MANAGE = 'ext.barre-manage',

    // Management of establishments and their reporting users
    ADM_ESTABLISHMENT_VIEW = 'adm.establishment-view',
    ADM_ESTABLISHMENT_MANAGE = 'adm.establishment-manage',

    EST_ESTABLISHMENT_PARENT_OWN_MANAGE = 'est.establishment-parent-own-manage',
    EST_ESTABLISHMENT_PARENT_OWN_VIEW = 'est.establishment-parent-own-view',

    EST_ESTABLISHMENT_OWN_MANAGE = 'est.establishment-own-manage',
    EST_ESTABLISHMENT_OWN_VIEW = 'est.establishment-own-view',

    EST_INTERV_OWN_MANAGE = 'est.interv-own-manage',
    EST_INTERV_OWN_VIEW = 'est.interv-own-view',

    EST_JOB_OWN_MANAGE = 'est.job-own-manage',
    EST_JOB_OWN_VIEW = 'est.job-own-view',

    EST_CANDIDACY_OWN_MANAGE = 'est.candidacy-own-manage',
    EST_CANDIDACY_OWN_VIEW = 'est.candidacy-own-view'
}
