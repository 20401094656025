import * as API from '@/services/API'
import store from '@/store/index'

export default {
    getDecisionsAmenagements(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/decisionamenagements`, { params: payload })
    },
    getDecisionAmenagements(decision_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/decisionamenagements/${decision_id}`, { params: payload })
    },
    addDecisionAmenagement(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/decisionamenagements`, payload)
    },
    updateDecisionAmenagement(decision_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/decisionamenagements/${decision_id}`, payload)
    },
    deleteDecisionAmenagement(decision_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/decisionamenagements/${decision_id}`)
    }
}
