import { getError } from '@/utils/helpers'
import { GenerateFlux, StateDefinitionBarresListesNotesAdmission } from '@/types/DefinitionBarresListeNotesAdmission'
import DefinitionBarresListeNotesAdmission from '@/services/DefinitionBarresListeNotesAdmission'

export const namespaced = true

function setListeDesNotes(commit: Function, response: any) {
    commit('SET_TABLEAU_LISTE_NOTES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
}

function resetListeDesNotes(commit: Function) {
    commit('SET_TABLEAU_LISTE_NOTES', [])
    commit('SET_META', null)
    commit('SET_LINKS', null)
    commit('SET_TOTALROWS', 0)
    commit('SET_LAST_PAGE', 0)
    commit('SET_CURRENT_PAGE', 1)
}

export const state: StateDefinitionBarresListesNotesAdmission = {
    tableauListeNotes: [],
    meta: null,
    links: null,
    loading: false,
    loadingGenerate: false,
    error: null,
    errorExport: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    selectedConcour: [],
    params_definition_barres: {},
    historiqueConcourPhase: []
}

export const mutations = {
    RESET_STATE(state: StateDefinitionBarresListesNotesAdmission) {
        Object.assign(state, {
            tableauListeNotes: [],
            meta: null,
            links: null,
            loading: false,
            loadingGenerate: false,
            error: null,
            errorExport: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            params_definition_barres: {},
            historiqueConcourPhase: []
        })
    },
    SET_TABLEAU_LISTE_NOTES(state: StateDefinitionBarresListesNotesAdmission, tableauListeNotes: any) {
        state.tableauListeNotes = tableauListeNotes
    },
    SET_MORE_TABLEAU_LISTE_NOTE(state: StateDefinitionBarresListesNotesAdmission, tableauListeNotes: any) {
        state.tableauListeNotes = state.tableauListeNotes.concat(tableauListeNotes)
    },
    SET_HISTORIQUE_CONCOUR_PHASE(state: StateDefinitionBarresListesNotesAdmission, historiqueConcourPhase: any) {
        state.historiqueConcourPhase = historiqueConcourPhase
    },
    SET_META(state: StateDefinitionBarresListesNotesAdmission, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: StateDefinitionBarresListesNotesAdmission, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: StateDefinitionBarresListesNotesAdmission, loading: boolean) {
        state.loading = loading
    },
    SET_LOADING_GENERATE(state: StateDefinitionBarresListesNotesAdmission, loadingGenerate: GenerateFlux | false): void {
        state.loadingGenerate = loadingGenerate
    },
    SET_ERROR(state: StateDefinitionBarresListesNotesAdmission, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: StateDefinitionBarresListesNotesAdmission, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: StateDefinitionBarresListesNotesAdmission, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: StateDefinitionBarresListesNotesAdmission, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CURRENT_PAGE(state: StateDefinitionBarresListesNotesAdmission, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_PARAMS(state: StateDefinitionBarresListesNotesAdmission, params: any) {
        state.params_definition_barres = params
    },
    SET_ERROR_EXPORT(state: StateDefinitionBarresListesNotesAdmission, error: Array<any>) {
        state.errorExport = error
    }
}

export const actions = {
    getTableauListeDesNotes({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DefinitionBarresListeNotesAdmission.getDefinitionBarresListeNotesAdmission(payload)
                .then((response) => {
                    setListeDesNotes(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreTableauListeDesNotes({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DefinitionBarresListeNotesAdmission.getDefinitionBarresListeNotesAdmission(payload)
                .then((response) => {
                    commit('SET_MORE_TABLEAU_LISTE_NOTE', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    commit('SET_LOADING', false)
                    commit('SET_META', response.data.meta)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getHistoriqueConcourPhase({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DefinitionBarresListeNotesAdmission.getHistoriqueConcourPhase(payload)
                .then((response) => {
                    commit('SET_HISTORIQUE_CONCOUR_PHASE', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    RESET_LISTE_NOTES({ commit }: { commit: any }) {
        resetListeDesNotes(commit)
    },
    exportationAdmission({ commit }: { commit: any }, payload?: any) {
        return new Promise((resolve, reject) => {
            switch (payload.type_export) {
                case 'liste_resultats': {
                    DefinitionBarresListeNotesAdmission.exportationListeResultats(payload)
                        .then((response) => {
                            resolve(response)
                        })
                        .catch((errorExport) => {
                            commit('SET_ERROR_EXPORT', getError(errorExport, false))
                            reject(errorExport)
                        })
                    break
                }
                case 'tableau_rangs_par_equipe': {
                    DefinitionBarresListeNotesAdmission.exportationTableauRangParEquipe(payload)
                        .then((response) => {
                            resolve(response)
                        })
                        .catch((errorExport) => {
                            commit('SET_ERROR_EXPORT', getError(errorExport, false))
                            reject(errorExport)
                        })
                    break
                }
                case 'liste_classes': {
                    DefinitionBarresListeNotesAdmission.exportationListeClassesFinal(payload)
                        .then((response) => {
                            resolve(response)
                        })
                        .catch((errorExport) => {
                            commit('SET_ERROR_EXPORT', getError(errorExport, false))
                            reject(errorExport)
                        })
                    break
                }
            }
        })
    },
    generateExport({ commit }: { commit: any }, payload: { flux: GenerateFlux; phaseId: number }) {
        commit('SET_LOADING_GENERATE', payload.flux)
        return new Promise((resolve, reject): void => {
            DefinitionBarresListeNotesAdmission.generateExport(payload.flux, payload.phaseId)
                .then((response): void => {
                    resolve(response)
                })
                .catch((error): void => {
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING_GENERATE', false)
                })
        })
    }
}

export const getters = {
    tableauListeNotesTrie: (state: StateDefinitionBarresListesNotesAdmission) => {
        return state.tableauListeNotes
    },
    historiqueConcourPhase: (state: StateDefinitionBarresListesNotesAdmission) => {
        return state.historiqueConcourPhase
    },
    meta: (state: StateDefinitionBarresListesNotesAdmission) => {
        return state.meta
    },
    links: (state: StateDefinitionBarresListesNotesAdmission) => {
        return state.links
    },
    loading: (state: StateDefinitionBarresListesNotesAdmission) => {
        return state.loading
    },
    loadingGenerate: (state: StateDefinitionBarresListesNotesAdmission): GenerateFlux | false => {
        return state.loadingGenerate || false
    },
    errorListeNoteAdmission: (state: StateDefinitionBarresListesNotesAdmission) => {
        return state.error
    },
    errorExport: (state: StateDefinitionBarresListesNotesAdmission) => {
        return state.errorExport
    },
    currentPage: (state: StateDefinitionBarresListesNotesAdmission) => {
        return state.currentPage
    },
    lastPage: (state: StateDefinitionBarresListesNotesAdmission) => {
        return state.lastPage
    },
    totalPage: (state: StateDefinitionBarresListesNotesAdmission) => {
        return state.totalPage
    },
    totalRows: (state: StateDefinitionBarresListesNotesAdmission) => {
        return state.totalRows
    }
}
