import { AmenagementInterface } from './Amenagement'
import { PaysInterface } from '@/types/Pays'
import { EtablissementInterface } from '@/types/Etablissement'

export interface CandidatInterface {
    id: number | 0,
    concour_id: number | 0,
    etablissement_id: number | null,
    etablissement_str: string | null,
    ine: string | null,
    civility: number | null,
    code: string | null,
    name: string | null,
    usual_name: string | null,
    first_name: string | null,
    middle_name: string | null,
    demission: number | null,
    demande_demission_at: Date | string | null,
    demission_at: Date | string | null,
    num_anonymat: string | null,
    email: string | null,
    portable: string | null,
    telephone: string | null,
    date_naissance: string | null,
    ville_naissance: string | null,
    arrondissement_naissance: string | null,
    departement_naissance_id: number | null,
    departement_naissance: DataCandidatInterface | null,
    pays_naissance_id: number | null,
    pays_naissance: PaysInterface | null,
    pays_nationalite_id: number | null,
    pays_nationalite: PaysInterface | null,
    nationalite_fr: number | null,
    adresse1: string | null,
    adresse2: string | null,
    code_postal: string | null,
    bureau_distribution: string | null,
    pays_distribution_id: number | null,
    pays_distribution: PaysInterface | null,
    classe: string | null,
    mois_bac: string | null,
    annee_bac: string | null,
    departement_bac_id: number | null,
    bac_id: number | null,
    bac: DataCandidatInterface | null,
    mention_bac: number | null,
    qualite_id: number | null,
    qualite_boursier: DataCandidatInterface | null,
    demande_amenagement: number | null,
    annee_cpge: number | null,
    first_second_year_after_bac: number | null,
    etat_dossier: number | null,
    csp_pere_id: number | null,
    csp_pere: DataCandidatInterface | null,
    csp_mere_id: number | null,
    csp_mere: DataCandidatInterface | null,
    bourses_echelon: number | null,
    serie_id: number | null,
    portail_candidat_blocked: number | null,
    serie: DataCandidatInterface | null,
    concour: any | null,
    concours: any[],
    etablissement: EtablissementInterface | null,
    inscriptions: CandidatEpreuveInterface[],
    resultats: CandidatResultatInterface[],
    amenagements: AmenagementInterface[] | null,
    comments: CandidatCommentaireInterface[] | null,
    incompatibilites: any[] | null,
    indisponibilites: any[] | null,
    dossiers_handicap: any[] | null,
    scolarites: ScolariteInterface[] | null,
    ensembles: any[] | null,
    seances: any[] | null,
    epreuveFac: any[] | null,
    souhait1_ville_id: number | null,
    souhait1: any | null,
    souhait2_ville_id: number | null,
    souhait2: any | null,
    centre_id: number | null,
    centre: any | null,
    salle_id: number | null,
    salle: any | null,
    place: number | null,
    repartition_manuelle: number | null,
    placement_manuel: number | null,
    placement_state: number | null,
    consent_resultats_etab: number | null,
    consent_coord_ecoles: number | null,
    documents: [] | null,
    compte_candidat_id: number | null,
    compteCandidat: any | null,
    dossier_inscription_id: number | null,
    dossierInscription: any | null,
    datas_sup: any | null,
    decisionAmenagements: any[] | null,
    formattedDateNaissance: string | null,
    identite: string | null,
    hasImportantComments: boolean | false,
    hasComments: boolean | false,
    filiere: string | null,
    nationalite: string | null,
    libelle_etat_dossier: string | null,
    autorisation_passation: boolean | null,
    autorisation_resultats: boolean | null
}

export interface GenericInterface {
    code: string | null,
    id: number | 0,
    name: string | null,
    type: number | 0
}

export interface ScolariteInterface {
    candidat_id: number | 0,
    annee_scolaire: string | null,
    type: string | null,
    diplome: string | null,
    etablissement: string | null,
    ville: string | null,
    classe: string | null
}

export interface CandidatCommentaireInterface {
    id: number | 0,
    body: string | null,
    important: boolean | number | false,
    is_public: boolean | false
}

export interface CandidatEpreuveInterface {
    candidat_id: number | 0,
    centre_id?: number | 0,
    cle_epreuve_externe?: string | null,
    demission: number | 0,
    epreuve_id: number | 0,
    notes?: any,
    // note_delib?: number | 0;
    note_brute: number | null,
    note_finale: number | null,
    salle_id?: number | 0,
    statut: number | 0
}

export interface CandidatResultatInterface {
    moyenne: number | 0,
    phase_id: number | 0,
    max_points: number | 0,
    points: number | 0,
    rang: number | 0,
    recu: number | 0,
    bar_rule_results: any[] | null,
    bonus: number | 0
}

export interface DataCandidatInterface {
    id: number | 0,
    code: string | null,
    type: TypeDataCandidat,
    name: string | null
}

export interface StateCandidats {
    candidats: Array<CandidatInterface> | [],
    selectedCandidat: CandidatInterface | null,
    editedCandidat: CandidatInterface | null,
    meta: Array<string> | null,
    loading: boolean | false,
    loading_import: boolean | false,
    totalRows: number | 0,
    currentPage: number | 0,
    lastPage: number | 0,
    totalPage: number | 0,
    liste_dossiers: [],
    datasCandidat: Array<DataCandidatInterface> | [],
    error: any,
    candidatsWithImportantComments: any[],
    loadingDashboardComment: boolean,
    datasRepartitionEquipes: {},
    previsionnelCandidatsEpreuves: Array<any> | []
}

export enum TypeDossier {
    TYPE_DOSSIER_CANDIDATS = 1,
    TYPE_DOSSIER_HANDICAPS = 2,
    TYPE_DOSSIER_SERIES = 3,
    TYPE_DOSSIER_TIPE = 4,
    TYPE_NOTES_TIPE = 5,
    TYPE_CHOIX_VILLES_ECRITS = 6,
    TYPE_IMPORT_CANDIDATS_VIATIQUE = 7,
    TYPE_IMPORT_CANDIDATS_RESULTATS = 8,
    TYPE_IMPORT_CHOIX_POSTES = 9,
    TYPE_IMPORT_CONCOURS_VIATIQUE = 10,
    TYPE_IMPORT_NOTES_EXTERNE = 11,
    TYPE_IMPORT_CANDIDATS_SERIES = 12,
    TYPE_IMPORT_CANDIDATS_JOB = 13
}

export enum EtatTraitementDossier {
    ETAT_NON_LANCER = 0,
    ETAT_EN_COURS = 1,
    ETAT_TRAITE = 2,
    ETAT_ERREUR = 3,
}

export enum TypeDataCandidat {
    TYPE_BAC = 1,
    TYPE_BOURSIER = 2,
    TYPE_CATEGORIE_SOCIO_PROF = 3,
    TYPE_DEPARTEMENT = 4,
}

export enum Civilite {
    CIVILITE_MR = 1,
    CIVILITE_MME = 2,
    CIVILITE_MLLE = 3,
}

export enum AnneeCPGE {
    ANNEE_CPGE_AUTRE = 0,
    ANNEE_CPGE_1 = 1,
    ANNEE_CPGE_3_DEMI = 3,
    ANNEE_CPGE_5_DEMI = 5,
    ANNEE_CPGE_7_DEMI = 7,
}

export enum MentionBAC {
    MENTION_BAC_NON_RENSEIGNE = 0,
    MENTION_BAC_PASSABLE = 1,
    MENTION_BAC_AB = 2,
    MENTION_BAC_B = 3,
    MENTION_BAC_TB = 4,
}

export enum EtatDossierCandidat {
    ETAT_DOSSIER_NON_SIGNE = 0,
    ETAT_DOSSIER_SIGNE = 1,
    ETAT_DOSSIER_PARTIEL_VALIDE = 2,
    ETAT_DOSSIER_VALIDE = 3,
    ETAT_DOSSIER_HORS_DELAI = 4,
}

export enum TypeIndisponibilite {
    TYPE_MATIN = 0,
    TYPE_APRES_MIDI = 1,
    TYPE_JOURNEE = 2,
}

export enum TypeIncompatibilite {
    INCOMPAT_TYPE_STANDARD = 0,
    INCOMPAT_TYPE_DOUBLEMENT_AUTO = 1,
}

export enum TypeBarre {
    TYPE_POINTS = 1,
    TYPE_RANG = 2,
}

export enum TypeIndispoCandidat {
    TYPE_MATIN,
    TYPE_APRES_MIDI,
    TYPE_JOURNEE,
}

export enum CandidatStatut {
    INSCRIT,
    PRESENT,
    ABSENT,
}

export enum PlacementState {
    PLACEMENT_OK,
    PLACEMENT_NON_OK,
}

export enum CandidatConcourPhase {
    DECISION_AUCUNE = 0,
    DECISION_RECU = 1,
    DECISION_NON_RECU = 2
}

export enum CandidatConcour {
    ETAT_INSCRIT,
    ETAT_AUTORISE,
    ETAT_REFUSE
}

export enum CandidatCommentaire {
    TYPE_COMMENTAIRE,
    TYPE_TACHE,
    TYPE_ALERTE
}

export function getIndispoType(indispo_type: TypeIndispoCandidat) {
    switch (indispo_type) {
        case TypeIndispoCandidat.TYPE_APRES_MIDI:
            return { libelle: 'Après-midi' }

        case TypeIndispoCandidat.TYPE_JOURNEE:
            return { libelle: 'Journée entière' }

        case TypeIndispoCandidat.TYPE_MATIN:
            return { libelle: 'Matinée' }
    }
}

export function getEtatTraitementDossier(etat: number) {
    switch (etat) {
        case EtatTraitementDossier.ETAT_EN_COURS:
            return { libelle: 'En cours', color: 'info' }
        case EtatTraitementDossier.ETAT_NON_LANCER:
            return { libelle: 'Non lancé', color: 'secondary' }
        case EtatTraitementDossier.ETAT_TRAITE:
            return { libelle: 'Traité', color: 'success' }
        case EtatTraitementDossier.ETAT_ERREUR:
            return { libelle: 'En erreur', color: 'danger' }
    }
}

export function getEtatDossier(etat: number) {
    switch (etat) {
        case EtatDossierCandidat.ETAT_DOSSIER_NON_SIGNE:
            return { libelle: 'Non signé', color: 'info' }
        case EtatDossierCandidat.ETAT_DOSSIER_SIGNE:
            return { libelle: 'Signé', color: 'info' }
        case EtatDossierCandidat.ETAT_DOSSIER_PARTIEL_VALIDE:
            return { libelle: 'Partiellement validé', color: 'info' }
        case EtatDossierCandidat.ETAT_DOSSIER_VALIDE:
            return { libelle: 'Validé', color: 'info' }
        case EtatDossierCandidat.ETAT_DOSSIER_HORS_DELAI:
            return { libelle: 'Hors délai', color: 'danger' }
    }
}

export function getClasseCPGE(annee: number) {
    switch (annee) {
        case AnneeCPGE.ANNEE_CPGE_AUTRE:
            return { libelle: 'Autre', color: 'info' }
        case AnneeCPGE.ANNEE_CPGE_1:
            return { libelle: '1ère année', color: 'info' }
        case AnneeCPGE.ANNEE_CPGE_3_DEMI:
            return { libelle: '3 / 2', color: 'info' }
        case AnneeCPGE.ANNEE_CPGE_5_DEMI:
            return { libelle: '5 / 2', color: 'info' }
        case AnneeCPGE.ANNEE_CPGE_7_DEMI:
            return { libelle: '7 / 2', color: 'info' }
    }
}

export function getMentionBac(mention: number)  {
    switch (mention) {
        case MentionBAC.MENTION_BAC_NON_RENSEIGNE:
            return { libelle: 'Non renseignée', color: 'info' }
        case MentionBAC.MENTION_BAC_PASSABLE:
            return { libelle: 'Passable', color: 'info' }
        case MentionBAC.MENTION_BAC_AB:
            return { libelle: 'Assez bien', color: 'info' }
        case MentionBAC.MENTION_BAC_B:
            return { libelle: 'Bien', color: 'info' }
        case MentionBAC.MENTION_BAC_TB:
            return { libelle: 'Trés bien', color: 'info' }
    }
}

export function getLibelleCivilite(civilite_type: Civilite) {
    switch (civilite_type) {
        case Civilite.CIVILITE_MR:
            return { libelle: 'Monsieur' }
        case Civilite.CIVILITE_MME:
            return { libelle: 'Madame' }
        case Civilite.CIVILITE_MLLE:
            return { libelle: 'Mademoiselle' }
        default:
            return { libelle: 'Monsieur' }
    }
}

export function getCandidatConcour(type: CandidatConcour): { libelle: string; color: string } {
    switch (type) {
        case CandidatConcour.ETAT_INSCRIT:
            return { libelle: 'Inscrit', color: 'success' }
        case CandidatConcour.ETAT_AUTORISE:
            return { libelle: 'Autorisé à concourir', color: 'info' }
        case CandidatConcour.ETAT_REFUSE:
            return { libelle: 'Refusé', color: 'danger' }
    }
}

export function getCandidatCommentaire(type: CandidatCommentaire): { libelle: string; color: string } {
    switch (type) {
        case CandidatCommentaire.TYPE_COMMENTAIRE:
            return { libelle: 'Commentaire', color: 'secondary' }
        case CandidatCommentaire.TYPE_TACHE:
            return { libelle: 'Tâche', color: 'info' }
        case CandidatCommentaire.TYPE_ALERTE:
            return { libelle: 'Alerte', color: 'warning' }
    }
}
