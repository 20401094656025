import { getError } from '@/utils/helpers'
import JobProfileFormService from '@/services/JobProfileFormService'
import { JobProfileFormInterface, StateJobProfileForms } from '@/types/JobProfileForm'

// ------ job_profile_forms JobProfileForms

export const namespaced = true

function setJobProfileForms(commit: Function, response: any) {
    commit('SET_JOB_PROFILE_FORMS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateJobProfileForms = {
    jobProfileForms: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    jobProfileFormSelect: null,
    piloteJobProfileFormSelect: null,
    concoursJobProfileFormSelect: null
}

export const mutations = {
    RESET_STATE(state: StateJobProfileForms) {
        Object.assign(state, {
            jobProfileForms: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            jobProfileFormSelect: null,
            concoursJobProfileFormSelect: null
        })
    },
    SET_JOB_PROFILE_FORMS(state: any, jobProfileForms: Array<JobProfileFormInterface>) {
        state.jobProfileForms = jobProfileForms
    },
    ADD_JOB_PROFILE_FORM(state: any, jobProfileForm: JobProfileFormInterface) {
        state.jobProfileForms.push(jobProfileForm)
        state.jobProfileFormSelect = jobProfileForm
    },
    UPDATE_JOB_PROFILE_FORM(state: any, jobProfileForm: JobProfileFormInterface) {
        if (state.jobProfileFormSelect && state.jobProfileFormSelect.id === jobProfileForm.id) {
            state.jobProfileFormSelect = jobProfileForm
        }

        const index = state.jobProfileForms.findIndex((form: any) => form.id === jobProfileForm.id)
        if (index !== -1) {
            state.jobProfileForms[index] = jobProfileForm
        }
    },
    REMOVE_JOB_PROFILE_FORM(state: any, jobProfileForm_id: number) {
        const index = state.jobProfileForms.findIndex((form: any) => form.id === jobProfileForm_id)
        if (index !== -1) {
            state.jobProfileForms.splice(index, 1)
        }
        state.jobProfileFormSelect = null
    },
    SET_MORE_JOB_PROFILE_FORMS(state: any, jobProfileForms: Array<JobProfileFormInterface>) {
        state.jobProfileForms = state.jobProfileForms.concat(jobProfileForms)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_JOB_PROFILE_FORM_SELECT(state: any, jobProfileForm: JobProfileFormInterface) {
        state.jobProfileFormSelect = jobProfileForm
    },
    SET_PILOTE_JOB_PROFILE_FORM_SELECT(state: any, pilote: any) {
        state.piloteJobProfileFormSelect = pilote
    },
    SET_CONCOURS_JOB_PROFILE_FORM_SELECT(state: any, concours: any) {
        state.concoursJobProfileFormSelect = concours
    }
}

export const actions = {
    getJobProfileForms({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobProfileFormService.getJobProfileForms(params)
                .then((response) => {
                    setJobProfileForms(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getJobProfileForm({ commit }: { commit: any }, params: any) {
        commit('SET_JOB_PROFILE_FORM_SELECT', null)
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobProfileFormService.getJobProfileForm(params.jobProfileForm_id)
                .then((response) => {
                    commit('SET_JOB_PROFILE_FORM_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreJobProfileForms({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobProfileFormService.getJobProfileForm(params.jobProfileForm_id)
                .then((response) => {
                    commit('SET_JOB_PROFILE_FORM_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateJobProfileForm({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobProfileFormService.updateJobProfileForm(params)
                .then((response) => {
                    commit('UPDATE_JOB_PROFILE_FORM', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addJobProfileForm({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobProfileFormService.addJobProfileForm(params)
                .then((response) => {
                    commit('ADD_JOB_PROFILE_FORM', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteJobProfileForm({ commit }: { commit: any }, jobProfileForm_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobProfileFormService.deleteJobProfileForm(jobProfileForm_id)
                .then((response) => {
                    commit('REMOVE_JOB_PROFILE_FORM', jobProfileForm_id)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    storeDocument({ commit }: { commit: any }, params: any) {
        // commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobProfileFormService.storeDocument(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteDocument({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            JobProfileFormService.deleteDocument(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDocument({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            JobProfileFormService.getDocument(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    storeDocumentReference({ commit }: { commit: any }, params: any) {
        // commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            JobProfileFormService.storeDocumentReference(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteDocumentReference({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            JobProfileFormService.deleteDocumentReference(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDocumentReference({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            JobProfileFormService.getDocumentReference(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    cloneJobProfileForm({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            JobProfileFormService.cloneJobProfileForm(params).then((response) => {
                commit('ADD_JOB_PROFILE_FORM', response.data.data)
                commit('SET_LOADING', false)
                resolve(response)
            }).catch((error) => {
                commit('SET_LOADING', false)
                commit('SET_ERROR', getError(error))
                reject(error)
            })
        })
    }
}

export const getters = {
    jobProfileForms: (state: any): JobProfileFormInterface[] => {
        return state.jobProfileForms
    },
    meta: (state: any): any => {
        return state.meta
    },
    links: (state: any): string | null => {
        return state.links
    },
    loading: (state: any): boolean => {
        return state.loading
    },
    error: (state: any): any => {
        return state.error
    },
    totalRows: (state: any): number => {
        return state.jobProfileForms.length
    },
    jobProfileFormSelect: (state: any): JobProfileFormInterface => {
        return state.jobProfileFormSelect
    },
    piloteJobProfileFormSelect: (state: any) => {
        return state.piloteJobProfileFormSelect
    },
    concoursJobProfileFormSelect: (state: any) => {
        return state.piloteJobProfileFormSelect
    },
    getJobProfileFormById: (state: any) => (id: number) => {
        const jobProfileForm = state.jobProfileForms.filter((mat: any) => mat.id === id)[0]
        return jobProfileForm
    }
}
