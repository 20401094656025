import { getError } from '@/utils/helpers'
import CandidatEpreuveService from '@/services/CandidatEpreuveService'
import { CandidatEpreuveInterface, StateCandidatEpreuves } from '@/types/CandidatEpreuve'

export const namespaced = true

function setCandidatEpreuves(commit: Function, response: any) {
    commit('SET_CANDIDAT_EPREUVES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LOADING', false)
}

export const state: StateCandidatEpreuves = {
    candidatEpreuves: [],
    meta: null,
    loading: false,
    error: null,
    sortby: '',
    candidatEpreuveSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateCandidatEpreuves) {
        Object.assign(state, {
            candidatEpreuves: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            candidatEpreuveSelect: {}
        })
    },
    SET_CANDIDAT_EPREUVES(state: any, candidatEpreuves: Array<CandidatEpreuveInterface>) {
        state.candidatEpreuves = candidatEpreuves
    },
    SET_MORE_CANDIDAT_EPREUVES(state: any, candidatEpreuves: Array<CandidatEpreuveInterface>) {
        state.candidatEpreuves = state.candidatEpreuves.concat(candidatEpreuves)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_SORTBY(state: any, params: string) {
        state.sortby = params
    },
    SET_CANDIDAT_EPREUVE_SELECT(state: any, candidatEpreuve: CandidatEpreuveInterface) {
        state.candidatEpreuveSelect = candidatEpreuve
    }
}

export const actions = {
    getCandidatEpreuves({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatEpreuveService.getCandidatEpreuves(params)
                .then((response) => {
                    setCandidatEpreuves(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreCandidatEpreuves({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatEpreuveService.getCandidatEpreuves(params)
                .then((response) => {
                    commit('SET_MORE_CANDIDAT_EPREUVES', response.data.data)
                    commit('SET_META', response.data.meta)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getCandidatEpreuve({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatEpreuveService.getCandidatEpreuve(params.candidatEpreuve_id)
                .then((response) => {
                    commit('SET_CANDIDAT_EPREUVE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateCandidatEpreuve({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatEpreuveService.updateCandidatEpreuve(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addCandidatEpreuve({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatEpreuveService.addCandidatEpreuve(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteCandidatEpreuve({ commit }: { commit: any }, candidatEpreuve_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatEpreuveService.deleteCandidatEpreuve(candidatEpreuve_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    exportCandidatEpreuves({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatEpreuveService.exportCandidatEpreuves(params)
                .then((response) => {
                    // setDemandesRemboursement(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    candidatEpreuves: (state: any) => {
        return state.candidatEpreuves
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        if (state.meta) {
            return state.meta.links
        } else {
            return ''
        }
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.candidatEpreuves.length
    },
    total: (state: any) => {
        if (state.meta) {
            return state.meta.total
        } else {
            return state.candidatEpreuves.length
        }
    },
    currentPage: (state: any) => {
        if (state.meta) {
            return state.meta.current_page
        } else {
            return 1
        }
    },
    lastPage: (state: any) => {
        if (state.meta) {
            return state.meta.last_page
        } else {
            return 1
        }
    },
    totalPage: (state: any) => {
        if (state.meta) {
            return state.meta.last_page
        } else {
            return 1
        }
    },
    candidatEpreuveSelect: (state: any) => {
        return state.candidatEpreuveSelect
    },
    getCandidatEpreuveById: (state: any) => (id: number) => {
        const candidatEpreuve = state.candidatEpreuves.filter((mat: any) => mat.id === id)[0]
        return candidatEpreuve
    }
}
