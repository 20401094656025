import moment from 'moment-timezone'
import toast from './toast'
// import { getters } from '@/store/modules/Auth'
import Store from '@/store'
import html2canvas from 'html2canvas'
import { TypePassation } from '@/types/Epreuve'
import _ from 'lodash'

declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean
    }
}

/** Controle la taille de la taille du png d'apres sa version en base64  */
export const sizeOfPngInBase64 = (pngBase64: string) => {
    const egalCounter = pngBase64.split('==').length === 2 ? 2 : 1
    const sizeInBytes = pngBase64.length * (3 / 4) - egalCounter
    return sizeInBytes
}

export const getError = (error: any, withtoast = true) => {
    const errorMessage = 'API Error, please try again.'
    if (withtoast && error.response && error.response.data && error.response.data.errors) {
        for (const err in error.response.data.errors) {
            if (error.response.data.errors[err] && error.response.data.errors[err].length > 0) {
                for (let i = 0; i < error.response.data.errors[err].length; i++) {
                    try {
                        if (error.response.data.errors[err][i] !== 'This action is unauthorized.') {
                            toast.error(error.response.data.errors[err][i])
                        }
                    } catch (errorToast) {
                        // console.log('errorToast : ' + errorToast)
                    }
                }
            } else if (error.response.data.errors[err]) {
                if (error.response.data.errors[err].row) {
                    // const retourError = 'erreur ligne' + error.response.data.errors[err].row + ' : ' + error.response.data.errors[err].errors
                    // toast.error(retourError)
                } else {
                    try {
                        if (error.response.data.errors[err] !== 'This action is unauthorized.') {
                            toast.error(error.response.data.errors[err])
                        }
                    } catch (errorToast) {
                        // console.log('errorToast : ' + errorToast)
                    }
                }
            }
        }
    } else if (withtoast && error.response && error.response.data) {
        if (
            error.response.data.message !== 'Unauthenticated.' ||
            error.response.data.message !== 'CSRF token mismatch.'
        ) {
            toast.error(error.response.data.message)
        }
    }
    if (error.name === 'Fetch User') {
        return error.message
    }

    if (!error.response) {
        if (error.config) {
            console.error(`API ${error.config.url} not found`)
        }

        return errorMessage
    }

    if (process.env.NODE_ENV === 'development') {
        console.error('ces erreurs sont affichées car on est en mode : development')
        console.error(error.response.data)
        console.error(error.response.status)
        console.error(error.response.headers)
    }

    if (error.response.data && error.response.data.errors) {
        return error.response.data.errors
    } else if (error.response.data && error.response.data.message) {
        return error.response.data.message
    }

    return errorMessage
}

/** Date prise depuis le serveur, traitée comme une date UTC et rendu sur le fuseau */
export const formatStringServeurToDate = (date: string) => {
    if (date) {
        return moment.utc(date)
    } else {
        return moment.utc()
    }
}

export const formatDate = (date: Date | string | null) => {
    if (date) {
        return moment(String(date)).format('DD/MM/YYYY à HH:mm')
    }
}

/** reformate une date de type string en format DD-MM-YYYY - HHhmm */
export const formatDateVariante = (date: Date | string | null) => {
    if (date) {
        return moment(String(date)).format('DD/MM/YYYY à HH:mm').replace(/:/g, 'h')
    }
}

/** formate une date pour l'afficahge en Jour/Mois */
export const formatDateShort = (date: Date) => {
    if (date) {
        return moment(String(date)).format('DD/MM')
    }
}

/** formate une date pour l'affichage en Jour/Mois [nom_du_jour] */
export const formatDateShortWithDayName = (date: Date) => {
    if (date) {
        return moment(String(date)).format('DD/MM') + ' ' + moment(String(date)).locale('fr').format('dddd')
    }
}

export const formatDateHoursMinutes = (date: Date | number | string) => {
    if (date) {
        return moment(String(date)).format('HH:mm')
    }
}

export const formatStringDate = (date: string, format = 'MM/DD/YYYY HH:mm') => {
    if (format) {
        return moment(date).utc().format(format)
    } else {
        return moment(date).utc().format('MM/DD/YYYY HH:mm')
    }
}

export const formatDayDate = (date: Date) => {
    if (date) {
        const dateString = date.toISOString()
        return moment(dateString).format('DD/MM/YYYY')
    }
}

/** Formatte une date pour affichage nom de document */
export const formatDateDocument = (date: Date) => {
    let date_s = ''
    if (date) {
        date_s = String(moment(String(date)).format('DD-MM-YYYY'))
    }

    return date_s
}

/** Formatte une date pour affichage sans heure */
export const formatDateSinTime = (date: Date | string) => {
    let date_s = ''
    if (date) {
        // date_s = String(moment(String(date)).format('DD/MM/YYYY'))
        date_s = moment(date, [moment.ISO_8601, 'DD/MM/YYYY']).format('DD/MM/YYYY')
    }

    return date_s
}

/** formatte une valeur string de type'YYYY-MM-DD HH:mm:ss' en YYYY-MM-DDTHH:mm:ss[Z] */
export const formatDayHourZDateFromString = (dateString: string) => {
    if (dateString) {
        return moment(dateString).utc().format('YYYY-MM-DDTHH:mm:ss.000000[Z]')
    }
}

/** formatte une valeur string de type'YYYY-MM-DD HH:mm:ss' en YYYY-MM-DDTHH:mm:ss[Z] */
export const formatDayHourZDateFromMoment = (dateMoment: any) => {
    return dateMoment.format('YYYY-MM-DDTHH:mm:ss.000000[Z]')
}

/** récupère la valeur End Of Day d'une date de type string et reformatte en YYYY-MM-DD HH:mm:ss */
export const formatEndOfDayHourZDateFromString = (dateString: string | undefined) => {
    if (dateString) {
        return moment(dateString).endOf('day').format('YYYY-MM-DD HH:mm:ss')
    }
}

/** reformate une date de type string en format valable pour le nom d'enregistrement d'un fichier : DD-MM-YYYY_HH-mm-ss */
export const formatDateDayHourDateForFileSaving = (dateString: string | undefined) => {
    if (dateString) {
        return moment(dateString).format('DD-MM-YYYY_HH-mm-ss')
    }
}

/** ajout organisation journée oral */
export const time_convert = (num: number) => {
    const hours = Math.floor(num / 60)
    const minutes = num % 60
    return hours + ':' + (minutes < 10 ? '0' : '') + minutes
}

/** converti un string hh:mm ou un date time en un hh:mm utc */
export const hoursMinutesToGmt = (time: string) => {
    if (time === '') {
        return ''
    }
    if (time.length > 5) {
        const temp = moment(time, [moment.ISO_8601, 'YYYY-MM-DDTHH:mm:ss.000000[Z]'])
        return temp.utc().format('HH:mm')
    } else {
        const dateTemp = moment(new Date()).format('YYYY-MM-DD')
        const temp = moment(dateTemp + time, [moment.ISO_8601, 'YYYY-MM-DD-HH:mm'])
        return temp.utc().format('HH:mm')
    }
}

/** converti un string hh:mm ou un date time en un hh:mm local */
export const hoursMinutesToLocal = (time: string) => {
    if (time === '') {
        return ''
    }
    if (time.length > 5) {
        const temp = moment(time, [moment.ISO_8601, 'YYYY-MM-DDTHH:mm:ss.000000[Z]'])
        return temp.local().format('HH:mm')
    } else {
        const dateTemp = moment(new Date()).format('YYYY-MM-DD')
        const temp = moment.utc(dateTemp + time, [moment.ISO_8601, 'YYYY-MM-DD-HH:mm'])
        return temp.local().format('HH:mm')
    }
}
/** converti un date time en un hh:mm utc au 01/01/2022 */
export const hoursMinutesToDateTime = (time: string, day: any = null) => {
    if (time === '') {
        return ''
    }
    const date = day? day : new Date()
    const dateTemp = moment(date).format('YYYY-MM-DD')
    const temp = moment(dateTemp + time, [moment.ISO_8601, 'YYYY-MM-DD-HH:mm'])
    return temp.utc().format('YYYY-MM-DDTHH:mm:ss.000000[Z]')
}

/** convertie un hh:mm en minutes */
export const minutes_convert = (time: string) => {
    const hours = parseInt(time.split(':')[0])
    const minutes = parseInt(time.split(':')[1])
    return hours * 60 + minutes
}

/** formatte une valeur string de type'YYYY-MM-DD HH:mm:ss' en valeur moment HH:mm */
export const formatToHoursMinutesFromString = (value: string) => {
    const dateTemp = moment(new Date()).format('YYYY-MM-DD')
    return moment(dateTemp + value, [moment.ISO_8601, 'YYYY-MM-DD-HH:mm'])
}

/** reformatte une valeur en minutes vers un affichage en HH:mm */
export const formatToHourMinutes = (value: number) => {
    return moment.utc(moment.duration(value, 'minutes').asMilliseconds()).format('HH:mm') // formattage en HH:mm
}

/** reformatte une valeur en minutes concernant une plage max de 24h vers un affichage en HH h mm */
export const formatToHourMinutes24HoursMaxHumanizer = (value: number) => {
    if (value > 0) {
        return moment.utc(moment.duration(value, 'minutes').asMilliseconds()).format('HH:mm').replace(/:/g, 'h') // formattage en HHhmm
    } else {
        return '00h00'
    }
}

/** limite aux charactères A-zA-Z0-9 et - les champs dest"és" à saisir un code */
export const codeRestrict = (e: any) => {
    const regPrevent = /^([a-zA-Z0-9-]+)$/u
    const regReplace = /[^a-zA-Z0-9-]/g
    if (!RegExp(regPrevent).test(e.key)) {
        e.preventDefault()
    }
    setTimeout(() => {
        if (!RegExp(regPrevent).test(e.target.value)) {
            e.target.value = e.target.value.replace(regReplace, '')
        }
    }, 500)
}
/** limite aux charactères A-zA-Z0-9 et - les champs dest"és" à saisir un code */
export const codeRestrictCodeImport = (e: any) => {
    const regPrevent = /^([a-zA-Z0-9-;]+)$/u
    const regReplace = /[^a-zA-Z0-9-]/g
    if (!RegExp(regPrevent).test(e.key)) {
        e.preventDefault()
    }
    setTimeout(() => {
        if (!RegExp(regPrevent).test(e.target.value)) {
            e.target.value = e.target.value.replace(regReplace, '')
        }
    }, 500)
}

/** Réduction d'une expression string en un code limité aux charactères A-zA-Z0-9 et - */
export const slugifyToCode = (value: string) => {
    const regReplace = /[^a-zA-Z0-9-]/g
    return value.replace(regReplace, '')
}

/** reformatte une valeur en minutes vers un affichage en HH h mm */
export const totalFormatToHourMinutesHumanizer = (value: number) => {
    // if (value > 0) {
    const split = moment.utc(moment.duration(value, 'minutes').asMilliseconds()).format('D:HH:mm').split(':')
    // console.log(split)
    const hoursFromDay = (Number(split[0]) - 1) * 24
    const hoursFromHours = Number(split[1])
    const totalHours = hoursFromDay + hoursFromHours
    const minutesFromMinutes = String(split[2])
    // console.log(hoursFromDay + ':' + hoursFromHours + ':' + minutesFromMinutes)
    if (isNaN(hoursFromDay) || isNaN(hoursFromHours) || minutesFromMinutes === undefined) {
        return '-'
    } else {
        return (totalHours + ' h ' + minutesFromMinutes).toString()
    }
    /* } else {
        return '00h00'
    } */
}

/** Ajoute n journée(s) à une date */
export const addDaysToDate = (date: any, n: number) => {
    return moment(date).add(n, 'day').toDate()
}

/** Retire n journée(s) à une date */
export const subtractDaysToDate = (date: any, n: number) => {
    return moment(date).subtract(n, 'day').toDate()
}

/** retourne  le jour de la semaine d'une date */
export const getWeekDayOfTime = (time: any) => {
    moment.locale('FR')
    return moment(time).format('dddd')
}

/** donne la valeur unix d'une date */
export const getUnixTime = (time: any) => {
    return moment(time).unix()
}

/** applique une heure:min a un jour donné */
export const setHourMinuteToDate = (date: any, time: string) => {
    const dateTemp = moment(new Date()).format('YYYY-MM-DD')
    const temp = moment.utc(dateTemp + time, [moment.ISO_8601, 'YYYY-MM-DD-HH:mm'])
    const timeBis = temp.local().format('HH:mm')
    const h = moment(date).local()
    h.hour(parseInt(timeBis.split(':')[0]))
    h.minute(parseInt(timeBis.split(':')[1]))
    return h.utc()
}

/** reformatte une date en affichage humain jour mois année */
export const dateHumanizerDisplay = (value: string) => {
    moment.locale('FR')
    return moment(value, 'YYYY-MM-DD').format('Do MMMM YYYY')
}

/** reformatte une date string/valeur de type classique en HH:mm */
export const formatToHourMinutesFromAny = (value: any) => {
    return moment.utc(value).tz(process.env.VUE_APP_TZ).format('HH:mm') // formattage en HH:mm
}

export const base64ToArrayBuffer = (base64: string) => {
    const binary_string = window.atob(base64)
    const len = binary_string.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i)
    }
    return bytes.buffer
}

/** Renvoi la différence entre deux dates en minutes */
export const diffTwoDatesInMinutes = (date_debut: string, date_fin: string) => {
    const date1 = moment(date_debut)
    const date2 = moment(date_fin)
    return date2.diff(date1, 'minutes')
}

/** Renvoi la différence entre deux dates en minutes */
export const diffTwoDatesInDays = (date_debut: string, date_fin: string) => {
    const date1 = moment(new Date(date_debut))
    const date2 = moment(new Date(date_fin))
    return date2.diff(date1, 'days')
}

/** Renvoie la date du jour */
export const dateDuJour = () => {
    return moment().format('YYYY-MM-DD')
}

/** date à l'instant T en valeur moment YYYY-MM-DDTHH:mm:ss[Z] */
export const dateNow = () => {
    return moment().utc().format('YYYY-MM-DDTHH:mm:ss.000000[Z]')
}

/** convert date en valeur utc moment YYYY-MM-DDTHH:mm:ss[Z] */
export const convertDateUTCValeurT = (date: any) => {
    return moment(date).utc().format('YYYY-MM-DDTHH:mm')
}
/** convert date en valeur local moment YYYY-MM-DDTHH:mm:ss[Z] */
export const convertDateLocalValeurT = (date: any) => {
    return moment(date).local().format('YYYY-MM-DDTHH:mm')
}
/** Renvoie la date du jour */
export const dateDuJourFoDocument = () => {
    return moment().format('YYYYMMDD')
}

/** Formatte une date string en date YYYY-MM-DD */
export const formatDateYYYYMMDD = (value: any) => {
    return moment(value).format('YYYY-MM-DD')
}

/** Check si l'élément en paramétre est un object */
export const isObject = (element: any) => {
    let isObject = false
    if (typeof element === 'object') {
        isObject = true
    }
    return isObject
}

/** retourne  le jour de la semaine d'une date suivi de son jour et de son mois */
/** Option pour avoir un retour à la ligne apres le jour de la semaine */
export const getWeekDayWithDayMonth = (time: any, return_line?: boolean) => {
    moment.locale('FR')
    if (return_line) {
        return (
            moment(new Date(time)).format('dddd') +
            '<br />' +
            moment(new Date(time)).format('D') +
            '/' +
            moment(new Date(time)).format('MM')
        )
    } else {
        return (
            moment(new Date(time)).format('dddd') +
            ' ' +
            moment(new Date(time)).format('D') +
            '/' +
            moment(new Date(time)).format('MM')
        )
    }
}
export const getWeekDayWithDayMonthYearShort = (time: any, return_line?: boolean) => {
    moment.locale('FR')
    if (return_line) {
        return (
            moment(new Date(time)).format('dddd') +
            '<br />' +
            moment(new Date(time)).format('D') +
            '/' +
            moment(new Date(time)).format('MM') +
            '/' +
            moment(new Date(time)).format('YYYY')
        )
    } else {
        return (
            moment(new Date(time)).format('dddd') +
            ' ' +
            moment(new Date(time)).format('D') +
            '/' +
            moment(new Date(time)).format('MM') +
            '/' +
            moment(new Date(time)).format('YYYY')
        )
    }
}

/** retourne  le jour de la semaine d'une date suivi de son jour, du mois et de l'année */
export const getWeekDayWithDayMonthYear = (time: any) => {
    moment.locale('FR')
    return (
        moment(time).format('dddd') +
        ' ' +
        moment(time).format('D') +
        ' ' +
        moment(time).format('MMMM') +
        ' ' +
        moment(time).format('YYYY')
    )
}


// Renvoi une date avec précision du libellé du jour de la semaine
export const formatDateLibelle = (date: Date) => {
    if (date) {
        return moment(String(date)).locale('fr').format('dddd </br>DD-MM-YYYY')
    }
}


/** retourne  jour, du mois et de l'année en toute lettre */
export const getDayMonthYear = (time: any) => {
    moment.locale('FR')
    return moment(time).format('D') + ' ' + moment(time).format('MMMM') + ' ' + moment(time).format('YYYY')
}

/** Retourne un boolean pour savoir si une date est entre deux autre dates */
export const dateIsBetween = (date: any, startDate: any, endDate: any, inclusion: any) => {
    if (inclusion !== '..') {
        return moment(date).isBetween(startDate, endDate, undefined, inclusion)
    } else {
        return moment(date).isBetween(startDate, endDate)
    }
}

/** Check si une date est inférieure ou egale à une autre */
export const dateisSameOrBefore = (date: any, dateVerif: any) => {
    return moment(date).isSameOrBefore(dateVerif)
}

/** Retourne une durée entre deux creneaux */
export const durationBetweenDates = (date_debut: any, date_fin: any) => {
    const duration = moment.duration(date_fin.diff(date_debut))
    return duration.as('minutes')
}

/** Convert une date en moment */
export const convertMoment = (date: any) => {
    return moment(date)
}

/**
 * Fonction uilisée dans les tests : permet de récupérer les infos des abilities et dashboard d'un user en fonction de son rôle.
 * @param role définit le role
 * @returns retourne les infos abilities et dashboard en fonction du role
 */
export const getInfoRolesAndDashboard = (role: string) => {
    let userRoleDashboard: any = {}
    switch (role) {
        case 'directeur':
            userRoleDashboard = {
                abilities: {
                    id: 495,
                    civility: 'M.',
                    name: 'USER',
                    first_name: 'Directeur',
                    email: 'directeurN@test.com',
                    telephone: null,
                    code: null,
                    password_changed: true,
                    email_verified: true,
                    rgpd_accepted: true,
                    two_factor: false,
                    roles: [],
                    abilities: [
                        {
                            id: 1,
                            name: 'adm.par-view',
                            title: 'Adm.par view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 2,
                            name: 'adm.par-manage',
                            title: 'Adm.par manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 3,
                            name: 'adm.ini-view',
                            title: 'Adm.ini view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 4,
                            name: 'adm.ini-manage',
                            title: 'Adm.ini manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 5,
                            name: 'adm.util-view',
                            title: 'Adm.util view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 6,
                            name: 'adm.util-manage',
                            title: 'Adm.util manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 7,
                            name: 'cand-view',
                            title: 'Cand view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 8,
                            name: 'cand-manage',
                            title: 'Cand manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 9,
                            name: 'cand.mesure-view',
                            title: 'Cand.mesure view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 10,
                            name: 'cand.mesure-manage',
                            title: 'Cand.mesure manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 11,
                            name: 'gc.center-view',
                            title: 'Gc.center view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 12,
                            name: 'gc.center-manage',
                            title: 'Gc.center manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 13,
                            name: 'gc.convention-view',
                            title: 'Gc.convention view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 14,
                            name: 'gc.convention-manage',
                            title: 'Gc.convention manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 15,
                            name: 'ecr.prepa-view',
                            title: 'Ecr.prepa view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 16,
                            name: 'ecr.prepa-manage',
                            title: 'Ecr.prepa manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 17,
                            name: 'ecr.sujet-view',
                            title: 'Ecr.sujet view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 18,
                            name: 'ecr.sujet-manage',
                            title: 'Ecr.sujet manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 19,
                            name: 'ecr.gfou-view',
                            title: 'Ecr.gfou view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 20,
                            name: 'ecr.gfou-manage',
                            title: 'Ecr.gfou manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 21,
                            name: 'ecr.reclam-view',
                            title: 'Ecr.reclam view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 22,
                            name: 'ecr.reclam-manage',
                            title: 'Ecr.reclam manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 23,
                            name: 'oral.prepa-view',
                            title: 'Oral.prepa view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 24,
                            name: 'oral.prepa-manage',
                            title: 'Oral.prepa manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 25,
                            name: 'oral.afex-view',
                            title: 'Oral.afex view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 26,
                            name: 'oral.afex-manage',
                            title: 'Oral.afex manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 27,
                            name: 'oral.plan-view',
                            title: 'Oral.plan view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 28,
                            name: 'oral.plan-manage',
                            title: 'Oral.plan manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 29,
                            name: 'oral.suivi-view',
                            title: 'Oral.suivi view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 30,
                            name: 'oral.suivi-manage',
                            title: 'Oral.suivi manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 31,
                            name: 'oral.reclam-view',
                            title: 'Oral.reclam view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 32,
                            name: 'oral.reclam-manage',
                            title: 'Oral.reclam manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 33,
                            name: 'dlb.prepjury-view',
                            title: 'Dlb.prepjury view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 34,
                            name: 'dlb.prepjury-manage',
                            title: 'Dlb.prepjury manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 35,
                            name: 'dlb.pubnot-view',
                            title: 'Dlb.pubnot view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 36,
                            name: 'dlb.pubnot-manage',
                            title: 'Dlb.pubnot manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 37,
                            name: 'rh.sps-view',
                            title: 'Rh.sps view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 38,
                            name: 'rh.sps-manage',
                            title: 'Rh.sps manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 39,
                            name: 'rh.dosadm-view',
                            title: 'Rh.dosadm view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 40,
                            name: 'rh.dosadm-manage',
                            title: 'Rh.dosadm manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 41,
                            name: 'rh.contrat-view',
                            title: 'Rh.contrat view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 42,
                            name: 'rh.contrat-manage',
                            title: 'Rh.contrat manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 43,
                            name: 'rh.frais-view',
                            title: 'Rh.frais view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 44,
                            name: 'rh.frais-manage',
                            title: 'Rh.frais manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 45,
                            name: 'rh.sol-view',
                            title: 'Rh.sol view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 46,
                            name: 'rh.sol-manage',
                            title: 'Rh.sol manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 47,
                            name: 'signature-view',
                            title: 'Signature view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 48,
                            name: 'signature-manage',
                            title: 'Signature manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        }
                    ],
                    has_certificate: false
                },
                dashboardComposants: [
                    {
                        cardIcon: 'file-signature',
                        auth: ['signature-view'],
                        allCards: [
                            {
                                key: 'nb_documents_a_signer',
                                cardType: 'REGULAR',
                                auth: ['signature-view'],
                                title: 'SIGNATURE DES DOCUMENTS',
                                numberShow: 0,
                                textShowSingular: 'Document à signer',
                                textShowPlural: 'Documents à signer',
                                link: '/signature_documents',
                                bottomText: '',
                                done: {
                                    doneText: 'Tous les documents sont signés',
                                    graphNumber1: 12,
                                    graphNumber2: 24,
                                    graphText1: 'SPS',
                                    graphText2: 'SOL'
                                }
                            }
                        ]
                    },
                    {
                        cardIcon: 'graduation-cap',
                        auth: ['rh.sps-view'],
                        allCards: [
                            {
                                key: 'dossiers_academique',
                                cardType: 'threeinformations',
                                title: 'Dossiers académiques',
                                auth: ['rh.sps-view'],
                                information1Number: 118,
                                information1TextSingular: 'Dossier publié',
                                information1TextPlural: 'Dossiers publiés',
                                information2Number: 0,
                                information2TextSingular: 'Dossier validé',
                                information2TextPlural: 'Dossiers validés',
                                information3Number: 476,
                                information3TextSingular: 'Dossier au total',
                                information3TextPlural: 'Dossiers au total',
                                link: '/dossiers_academiques',
                                done: {
                                    type: 'string',
                                    doneText: 'doneSingular'
                                }
                            },
                            {
                                key: 'selection_intervenants',
                                cardType: 'progressbar',
                                auth: ['rh.sps-view'],
                                title: 'Sélection des intervenants',
                                fractionNumber1: 2,
                                fractionNumber2: 14,
                                textShowSingular: 'procès-verbal terminé',
                                textShowPlural: 'procès-verbaux terminés',
                                link: '/selections',
                                done: {
                                    type: 'string',
                                    doneText: 'Toutes les sélections sont effectuées'
                                }
                            },
                            {
                                key: 'affectation_intervenants',
                                cardType: 'progressbar',
                                auth: ['rh.sps-view'],
                                title: 'Affectation des intervenants',
                                fractionNumber1: 523,
                                fractionNumber2: 523,
                                textShowSingular: 'Intervenant affecté',
                                textShowPlural: 'Intervenants affectés',
                                link: '/affectations',
                                done: {
                                    type: 'string',
                                    doneText: 'Toutes les affectations sont effectuées'
                                }
                            }
                        ]
                    },
                    {
                        cardIcon: 'folder-open',
                        auth: ['rh.dosadm-view', 'rh.frais-view', 'rh.contrat-view'],
                        allCards: [
                            {
                                key: 'dossiers_administratif',
                                cardType: 'threeinformations',
                                title: 'dossiers administratifs',
                                auth: ['rh.dosadm-view'],
                                information1Number: 1,
                                information1TextSingular: 'Dossier publié',
                                information1TextPlural: 'Dossiers publiés',
                                information2Number: 0,
                                information2TextSingular: 'Dossier validé',
                                information2TextPlural: 'Dossiers validés',
                                information3Number: 118,
                                information3TextSingular: 'Dossier au total',
                                information3TextPlural: 'Dossiers au total',
                                link: '/dossiers_administratifs',
                                done: {
                                    type: 'string',
                                    doneText: 'doneSingular'
                                }
                            },
                            {
                                key: 'contrats',
                                cardType: 'progressbar',
                                auth: ['rh.contrat-view'],
                                title: 'Contrats',
                                fractionNumber1: 1,
                                fractionNumber2: 1,
                                textShowSingular: 'contrat signé',
                                textShowPlural: 'contrats signés',
                                link: '/contrats',
                                done: {
                                    type: 'string',
                                    doneText: 'Tous les contrats sont signés'
                                }
                            },
                            {
                                key: 'ordre_mission_demande_remboursement',
                                cardType: 'duocard',
                                auth: ['rh.frais-view'],
                                titleFirstCard: 'Demandes de remboursement',
                                numberShowFirstCard: 0,
                                textShowSingularFirstCard: 'Remboursement à traiter',
                                textShowPluralFirstCard: 'Remboursements à traiter',
                                linkFirstCard: '/demandes_remboursement',
                                doneFirstCard: {
                                    type: 'doneRegular',
                                    doneText: 'Pas de demandes à traiter'
                                },
                                titleSecondCard: 'Ordres de mission',
                                numberShowSecondCard: 0,
                                textShowSingularSecondCard: 'Ordre de mission à traiter',
                                textShowPluralSecondCard: 'Ordres de mission à traiter',
                                linkSecondCard: '/ordres_mission',
                                doneSecondCard: {
                                    type: 'doneRegular',
                                    doneText: 'Pas de demandes à traiter'
                                }
                            }
                        ]
                    },
                    {
                        cardIcon: 'clipboard-list',
                        auth: ['gc.center-view', 'gc.convention-view'],
                        allCards: [
                            {
                                key: 'nb_centres',
                                cardType: 'REGULAR',
                                auth: ['gc.center-view'],
                                title: 'CENTRES',
                                numberShow: 0,
                                textShowSingular: 'Centre',
                                textShowPlural: 'Centres',
                                link: '/centres',
                                bottomText: '',
                                done: {
                                    doneText: 'Pas de centre à gérer',
                                    graphNumber1: 12,
                                    graphNumber2: 24,
                                    graphText1: 'SPS',
                                    graphText2: 'SOL'
                                }
                            },
                            {
                                key: 'nb_conventions_signees',
                                cardType: 'REGULAR',
                                auth: ['gc.convention-view'],
                                title: 'CONVENTIONS SIGNÉES',
                                numberShow: 0,
                                textShowSingular: 'Convention',
                                textShowPlural: 'Conventions',
                                link: '/conventions',
                                bottomText: '',
                                done: {
                                    doneText: 'Toutes les conventions sont signées',
                                    graphNumber1: 12,
                                    graphNumber2: 24,
                                    graphText1: 'SPS',
                                    graphText2: 'SOL'
                                }
                            }
                        ]
                    }
                ],
                dashboardResponse: {
                    data: {
                        'adm.par-view': [],
                        'adm.par-manage': [],
                        'adm.ini-view': [],
                        'adm.ini-manage': [],
                        'adm.util-view': [],
                        'adm.util-manage': [],
                        'cand-view': [],
                        'cand-manage': [],
                        'cand.mesure-view': [],
                        'cand.mesure-manage': [],
                        'gc.center-view': [],
                        'gc.center-manage': [],
                        'gc.convention-view': [],
                        'gc.convention-manage': [],
                        'ecr.prepa-view': [],
                        'ecr.prepa-manage': [],
                        'ecr.sujet-view': [],
                        'ecr.sujet-manage': [],
                        'ecr.gfou-view': [],
                        'ecr.gfou-manage': [],
                        'ecr.reclam-view': [],
                        'ecr.reclam-manage': [],
                        'oral.prepa-view': [],
                        'oral.prepa-manage': [],
                        'oral.afex-view': [],
                        'oral.afex-manage': [],
                        'oral.plan-view': [],
                        'oral.plan-manage': [],
                        'oral.suivi-view': [],
                        'oral.suivi-manage': [],
                        'oral.reclam-view': [],
                        'oral.reclam-manage': [],
                        'dlb.prepjury-view': [],
                        'dlb.prepjury-manage': [],
                        'dlb.pubnot-view': [],
                        'dlb.pubnot-manage': [],
                        'rh.sps-view': {
                            nb_dossiers_total: 476,
                            nb_dossiers_ouverts: 118,
                            nb_dossiers_valides: 0,
                            nb_pv_total: 14,
                            nb_pv_valides: 2,
                            nb_intervenants_affectation: 523,
                            nb_intervenants_besoin: 523
                        },
                        'rh.sps-manage': [],
                        'rh.dosadm-view': {
                            nb_dossiers_total: 118,
                            nb_dossiers_ouverts: 1,
                            nb_dossiers_valides: 0,
                            nb_contrats_signes: 1,
                            nb_contrats_total: 1,
                            nb_ordres_mission_total: 0,
                            nb_ordres_mission_traites: 0,
                            nb_demandes_remb_total: 0,
                            nb_demandes_remb_traitees: 0
                        },
                        'rh.dosadm-manage': [],
                        'rh.contrat-view': [],
                        'rh.contrat-manage': [],
                        'rh.frais-view': [],
                        'rh.frais-manage': [],
                        'rh.sol-view': { nb_villes_total: 0, nb_centres_total: 0, nb_conventions_signees: 0 },
                        'rh.sol-manage': [],
                        'signature-view': [],
                        'signature-manage': { nb_documents_a_signer: 0 }
                    }
                }
            }
            break
        case 'responsable scientifique':
            userRoleDashboard = {
                abilities: {
                    id: 492,
                    civility: 'M.',
                    name: 'USER',
                    first_name: 'Responsable Scientifique',
                    email: 'responsable.scientifique@test.com',
                    telephone: null,
                    code: null,
                    password_changed: true,
                    email_verified: true,
                    rgpd_accepted: true,
                    two_factor: false,
                    roles: [],
                    abilities: [
                        {
                            id: 1,
                            name: 'adm.par-view',
                            title: 'Adm.par view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 3,
                            name: 'adm.ini-view',
                            title: 'Adm.ini view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 5,
                            name: 'adm.util-view',
                            title: 'Adm.util view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 7,
                            name: 'cand-view',
                            title: 'Cand view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 9,
                            name: 'cand.mesure-view',
                            title: 'Cand.mesure view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 11,
                            name: 'gc.center-view',
                            title: 'Gc.center view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 13,
                            name: 'gc.convention-view',
                            title: 'Gc.convention view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 15,
                            name: 'ecr.prepa-view',
                            title: 'Ecr.prepa view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 17,
                            name: 'ecr.sujet-view',
                            title: 'Ecr.sujet view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 19,
                            name: 'ecr.gfou-view',
                            title: 'Ecr.gfou view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 21,
                            name: 'ecr.reclam-view',
                            title: 'Ecr.reclam view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 22,
                            name: 'ecr.reclam-manage',
                            title: 'Ecr.reclam manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 23,
                            name: 'oral.prepa-view',
                            title: 'Oral.prepa view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 25,
                            name: 'oral.afex-view',
                            title: 'Oral.afex view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 26,
                            name: 'oral.afex-manage',
                            title: 'Oral.afex manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 27,
                            name: 'oral.plan-view',
                            title: 'Oral.plan view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 29,
                            name: 'oral.suivi-view',
                            title: 'Oral.suivi view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 31,
                            name: 'oral.reclam-view',
                            title: 'Oral.reclam view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 33,
                            name: 'dlb.prepjury-view',
                            title: 'Dlb.prepjury view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 34,
                            name: 'dlb.prepjury-manage',
                            title: 'Dlb.prepjury manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 35,
                            name: 'dlb.pubnot-view',
                            title: 'Dlb.pubnot view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 37,
                            name: 'rh.sps-view',
                            title: 'Rh.sps view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 38,
                            name: 'rh.sps-manage',
                            title: 'Rh.sps manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 47,
                            name: 'signature-view',
                            title: 'Signature view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        }
                    ],
                    has_certificate: false
                },
                dashboardComposants: [
                    {
                        cardIcon: 'file-signature',
                        auth: ['signature-view'],
                        allCards: [
                            {
                                key: 'nb_documents_a_signer',
                                cardType: 'REGULAR',
                                auth: ['signature-view'],
                                title: 'SIGNATURE DES DOCUMENTS',
                                numberShow: 0,
                                textShowSingular: 'Document à signer',
                                textShowPlural: 'Documents à signer',
                                link: '/signature_documents',
                                bottomText: '',
                                done: {
                                    doneText: 'Tous les documents sont signés',
                                    graphNumber1: 12,
                                    graphNumber2: 24,
                                    graphText1: 'SPS',
                                    graphText2: 'SOL'
                                }
                            }
                        ]
                    },
                    {
                        cardIcon: 'graduation-cap',
                        auth: ['rh.sps-view'],
                        allCards: [
                            {
                                key: 'dossiers_academique',
                                cardType: 'threeinformations',
                                title: 'Dossiers académiques',
                                auth: ['rh.sps-view'],
                                information1Number: 118,
                                information1TextSingular: 'Dossier publié',
                                information1TextPlural: 'Dossiers publiés',
                                information2Number: 0,
                                information2TextSingular: 'Dossier validé',
                                information2TextPlural: 'Dossiers validés',
                                information3Number: 476,
                                information3TextSingular: 'Dossier au total',
                                information3TextPlural: 'Dossiers au total',
                                link: '/dossiers_academiques',
                                done: {
                                    type: 'string',
                                    doneText: 'doneSingular'
                                }
                            },
                            {
                                key: 'selection_intervenants',
                                cardType: 'progressbar',
                                auth: ['rh.sps-view'],
                                title: 'Sélection des intervenants',
                                fractionNumber1: 2,
                                fractionNumber2: 14,
                                textShowSingular: 'procès-verbal terminé',
                                textShowPlural: 'procès-verbaux terminés',
                                link: '/selections',
                                done: {
                                    type: 'string',
                                    doneText: 'Toutes les sélections sont effectuées'
                                }
                            },
                            {
                                key: 'affectation_intervenants',
                                cardType: 'progressbar',
                                auth: ['rh.sps-view'],
                                title: 'Affectation des intervenants',
                                fractionNumber1: 523,
                                fractionNumber2: 523,
                                textShowSingular: 'Intervenant affecté',
                                textShowPlural: 'Intervenants affectés',
                                link: '/affectations',
                                done: {
                                    type: 'string',
                                    doneText: 'Toutes les affectations sont effectuées'
                                }
                            }
                        ]
                    },
                    {
                        cardIcon: 'clipboard-list',
                        auth: ['gc.center-view', 'gc.convention-view'],
                        allCards: [
                            {
                                key: 'nb_centres',
                                cardType: 'REGULAR',
                                auth: ['gc.center-view'],
                                title: 'CENTRES',
                                numberShow: 0,
                                textShowSingular: 'Centre',
                                textShowPlural: 'Centres',
                                link: '/centres',
                                bottomText: '',
                                done: {
                                    doneText: 'Pas de centre à gérer',
                                    graphNumber1: 12,
                                    graphNumber2: 24,
                                    graphText1: 'SPS',
                                    graphText2: 'SOL'
                                }
                            },
                            {
                                key: 'nb_conventions_signees',
                                cardType: 'REGULAR',
                                auth: ['gc.convention-view'],
                                title: 'CONVENTIONS SIGNÉES',
                                numberShow: 0,
                                textShowSingular: 'Convention',
                                textShowPlural: 'Conventions',
                                link: '/conventions',
                                bottomText: '',
                                done: {
                                    doneText: 'Toutes les conventions sont signées',
                                    graphNumber1: 12,
                                    graphNumber2: 24,
                                    graphText1: 'SPS',
                                    graphText2: 'SOL'
                                }
                            }
                        ]
                    }
                ],
                dashboardResponse: {
                    data: {
                        'adm.par-view': [],
                        'adm.ini-view': [],
                        'adm.util-view': [],
                        'cand-view': [],
                        'cand.mesure-view': [],
                        'gc.center-view': [],
                        'gc.convention-view': [],
                        'ecr.prepa-view': [],
                        'ecr.sujet-view': [],
                        'ecr.gfou-view': [],
                        'ecr.reclam-view': [],
                        'ecr.reclam-manage': [],
                        'oral.prepa-view': [],
                        'oral.afex-view': [],
                        'oral.afex-manage': [],
                        'oral.plan-view': [],
                        'oral.suivi-view': [],
                        'oral.reclam-view': [],
                        'dlb.prepjury-view': [],
                        'dlb.prepjury-manage': [],
                        'dlb.pubnot-view': [],
                        'rh.sps-view': {
                            nb_dossiers_total: 476,
                            nb_dossiers_ouverts: 118,
                            nb_dossiers_valides: 0,
                            nb_pv_total: 14,
                            nb_pv_valides: 2,
                            nb_intervenants_affectation: 523,
                            nb_intervenants_besoin: 523
                        },
                        'rh.sps-manage': [],
                        'signature-view': []
                    }
                }
            }
            break
        case 'gestionniare operationnel':
            userRoleDashboard = {
                abilities: {
                    id: 491,
                    civility: 'M.',
                    name: 'USER',
                    first_name: 'Gestionnaire Operationnel',
                    email: 'gestionnaire.operationnel@test.com',
                    telephone: null,
                    code: null,
                    password_changed: true,
                    email_verified: true,
                    rgpd_accepted: true,
                    two_factor: false,
                    roles: [],
                    abilities: [
                        {
                            id: 1,
                            name: 'adm.par-view',
                            title: 'Adm.par view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 2,
                            name: 'adm.par-manage',
                            title: 'Adm.par manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 3,
                            name: 'adm.ini-view',
                            title: 'Adm.ini view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 4,
                            name: 'adm.ini-manage',
                            title: 'Adm.ini manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 5,
                            name: 'adm.util-view',
                            title: 'Adm.util view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 7,
                            name: 'cand-view',
                            title: 'Cand view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 8,
                            name: 'cand-manage',
                            title: 'Cand manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 9,
                            name: 'cand.mesure-view',
                            title: 'Cand.mesure view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 10,
                            name: 'cand.mesure-manage',
                            title: 'Cand.mesure manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 11,
                            name: 'gc.center-view',
                            title: 'Gc.center view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 12,
                            name: 'gc.center-manage',
                            title: 'Gc.center manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 13,
                            name: 'gc.convention-view',
                            title: 'Gc.convention view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 15,
                            name: 'ecr.prepa-view',
                            title: 'Ecr.prepa view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 16,
                            name: 'ecr.prepa-manage',
                            title: 'Ecr.prepa manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 17,
                            name: 'ecr.sujet-view',
                            title: 'Ecr.sujet view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 18,
                            name: 'ecr.sujet-manage',
                            title: 'Ecr.sujet manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 19,
                            name: 'ecr.gfou-view',
                            title: 'Ecr.gfou view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 20,
                            name: 'ecr.gfou-manage',
                            title: 'Ecr.gfou manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 21,
                            name: 'ecr.reclam-view',
                            title: 'Ecr.reclam view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 22,
                            name: 'ecr.reclam-manage',
                            title: 'Ecr.reclam manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 23,
                            name: 'oral.prepa-view',
                            title: 'Oral.prepa view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 24,
                            name: 'oral.prepa-manage',
                            title: 'Oral.prepa manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 45,
                            name: 'rh.sol-view',
                            title: 'Rh.sol view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 47,
                            name: 'signature-view',
                            title: 'Signature view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        }
                    ],
                    has_certificate: false
                },
                dashboardComposants: [
                    {
                        cardIcon: 'file-signature',
                        auth: ['signature-view'],
                        allCards: [
                            {
                                key: 'nb_documents_a_signer',
                                cardType: 'REGULAR',
                                auth: ['signature-view'],
                                title: 'SIGNATURE DES DOCUMENTS',
                                numberShow: 0,
                                textShowSingular: 'Document à signer',
                                textShowPlural: 'Documents à signer',
                                link: '/signature_documents',
                                bottomText: '',
                                done: {
                                    doneText: 'Tous les documents sont signés',
                                    graphNumber1: 12,
                                    graphNumber2: 24,
                                    graphText1: 'SPS',
                                    graphText2: 'SOL'
                                }
                            }
                        ]
                    },
                    {
                        cardIcon: 'clipboard-list',
                        auth: ['gc.center-view', 'gc.convention-view'],
                        allCards: [
                            {
                                key: 'nb_centres',
                                cardType: 'REGULAR',
                                auth: ['gc.center-view'],
                                title: 'CENTRES',
                                numberShow: 0,
                                textShowSingular: 'Centre',
                                textShowPlural: 'Centres',
                                link: '/centres',
                                bottomText: '',
                                done: {
                                    doneText: 'Pas de centre à gérer',
                                    graphNumber1: 12,
                                    graphNumber2: 24,
                                    graphText1: 'SPS',
                                    graphText2: 'SOL'
                                }
                            },
                            {
                                key: 'nb_conventions_signees',
                                cardType: 'REGULAR',
                                auth: ['gc.convention-view'],
                                title: 'CONVENTIONS SIGNÉES',
                                numberShow: 0,
                                textShowSingular: 'Convention',
                                textShowPlural: 'Conventions',
                                link: '/conventions',
                                bottomText: '',
                                done: {
                                    doneText: 'Toutes les conventions sont signées',
                                    graphNumber1: 12,
                                    graphNumber2: 24,
                                    graphText1: 'SPS',
                                    graphText2: 'SOL'
                                }
                            }
                        ]
                    }
                ],
                dashboardResponse: {
                    data: {
                        'adm.par-view': [],
                        'adm.par-manage': [],
                        'adm.ini-view': [],
                        'adm.ini-manage': [],
                        'adm.util-view': [],
                        'cand-view': [],
                        'cand-manage': [],
                        'cand.mesure-view': [],
                        'cand.mesure-manage': [],
                        'gc.center-view': [],
                        'gc.center-manage': [],
                        'gc.convention-view': [],
                        'ecr.prepa-view': [],
                        'ecr.prepa-manage': [],
                        'ecr.sujet-view': [],
                        'ecr.sujet-manage': [],
                        'ecr.gfou-view': [],
                        'ecr.gfou-manage': [],
                        'ecr.reclam-view': [],
                        'ecr.reclam-manage': [],
                        'oral.prepa-view': [],
                        'oral.prepa-manage': [],
                        'rh.sol-view': { nb_villes_total: 0, nb_centres_total: 0, nb_conventions_signees: 0 },
                        'signature-view': []
                    }
                }
            }
            break
        case 'responsable financier':
            userRoleDashboard = {
                abilities: {
                    id: 13,
                    civility: null,
                    name: 'USER',
                    first_name: 'Responsable Financier',
                    email: 'responsable.financier@exemple.fr',
                    telephone: null,
                    code: null,
                    password_changed: true,
                    email_verified: true,
                    rgpd_accepted: true,
                    two_factor: false,
                    roles: [],
                    abilities: [
                        {
                            id: 11,
                            name: 'gc.center-view',
                            title: 'Gc.center view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 13,
                            name: 'gc.convention-view',
                            title: 'Gc.convention view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 14,
                            name: 'gc.convention-manage',
                            title: 'Gc.convention manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 37,
                            name: 'rh.sps-view',
                            title: 'Rh.sps view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 39,
                            name: 'rh.dosadm-view',
                            title: 'Rh.dosadm view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 40,
                            name: 'rh.dosadm-manage',
                            title: 'Rh.dosadm manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 41,
                            name: 'rh.contrat-view',
                            title: 'Rh.contrat view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 42,
                            name: 'rh.contrat-manage',
                            title: 'Rh.contrat manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 43,
                            name: 'rh.frais-view',
                            title: 'Rh.frais view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 44,
                            name: 'rh.frais-manage',
                            title: 'Rh.frais manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 45,
                            name: 'rh.sol-view',
                            title: 'Rh.sol view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 46,
                            name: 'rh.sol-manage',
                            title: 'Rh.sol manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 47,
                            name: 'signature-view',
                            title: 'Signature view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        }
                    ],
                    has_certificate: false
                },
                dashboardComposants: [
                    {
                        cardIcon: 'file-signature',
                        auth: ['signature-view'],
                        allCards: [
                            {
                                key: 'nb_documents_a_signer',
                                cardType: 'REGULAR',
                                auth: ['signature-view'],
                                title: 'SIGNATURE DES DOCUMENTS',
                                numberShow: 0,
                                textShowSingular: 'Document à signer',
                                textShowPlural: 'Documents à signer',
                                link: '/signature_documents',
                                bottomText: '',
                                done: {
                                    doneText: 'Tous les documents sont signés',
                                    graphNumber1: 12,
                                    graphNumber2: 24,
                                    graphText1: 'SPS',
                                    graphText2: 'SOL'
                                }
                            }
                        ]
                    },
                    {
                        cardIcon: 'graduation-cap',
                        auth: ['rh.sps-view'],
                        allCards: [
                            {
                                key: 'dossiers_academique',
                                cardType: 'threeinformations',
                                title: 'Dossiers académiques',
                                auth: ['rh.sps-view'],
                                information1Number: 118,
                                information1TextSingular: 'Dossier publié',
                                information1TextPlural: 'Dossiers publiés',
                                information2Number: 0,
                                information2TextSingular: 'Dossier validé',
                                information2TextPlural: 'Dossiers validés',
                                information3Number: 476,
                                information3TextSingular: 'Dossier au total',
                                information3TextPlural: 'Dossiers au total',
                                link: '/dossiers_academiques',
                                done: {
                                    type: 'string',
                                    doneText: 'doneSingular'
                                }
                            },
                            {
                                key: 'selection_intervenants',
                                cardType: 'progressbar',
                                auth: ['rh.sps-view'],
                                title: 'Sélection des intervenants',
                                fractionNumber1: 2,
                                fractionNumber2: 14,
                                textShowSingular: 'procès-verbal terminé',
                                textShowPlural: 'procès-verbaux terminés',
                                link: '/selections',
                                done: {
                                    type: 'string',
                                    doneText: 'Toutes les sélections sont effectuées'
                                }
                            },
                            {
                                key: 'affectation_intervenants',
                                cardType: 'progressbar',
                                auth: ['rh.sps-view'],
                                title: 'Affectation des intervenants',
                                fractionNumber1: 523,
                                fractionNumber2: 523,
                                textShowSingular: 'Intervenant affecté',
                                textShowPlural: 'Intervenants affectés',
                                link: '/affectations',
                                done: {
                                    type: 'string',
                                    doneText: 'Toutes les affectations sont effectuées'
                                }
                            }
                        ]
                    },
                    {
                        cardIcon: 'folder-open',
                        auth: ['rh.dosadm-view', 'rh.frais-view', 'rh.contrat-view'],
                        allCards: [
                            {
                                key: 'dossiers_administratif',
                                cardType: 'threeinformations',
                                title: 'dossiers administratifs',
                                auth: ['rh.dosadm-view'],
                                information1Number: 1,
                                information1TextSingular: 'Dossier publié',
                                information1TextPlural: 'Dossiers publiés',
                                information2Number: 0,
                                information2TextSingular: 'Dossier validé',
                                information2TextPlural: 'Dossiers validés',
                                information3Number: 118,
                                information3TextSingular: 'Dossier au total',
                                information3TextPlural: 'Dossiers au total',
                                link: '/dossiers_administratifs',
                                done: {
                                    type: 'string',
                                    doneText: 'doneSingular'
                                }
                            },
                            {
                                key: 'contrats',
                                cardType: 'progressbar',
                                auth: ['rh.contrat-view'],
                                title: 'Contrats',
                                fractionNumber1: 1,
                                fractionNumber2: 1,
                                textShowSingular: 'contrat signé',
                                textShowPlural: 'contrats signés',
                                link: '/contrats',
                                done: {
                                    type: 'string',
                                    doneText: 'Tous les contrats sont signés'
                                }
                            },
                            {
                                key: 'ordre_mission_demande_remboursement',
                                cardType: 'duocard',
                                auth: ['rh.frais-view'],
                                titleFirstCard: 'Demandes de remboursement',
                                numberShowFirstCard: 0,
                                textShowSingularFirstCard: 'Remboursement à traiter',
                                textShowPluralFirstCard: 'Remboursements à traiter',
                                linkFirstCard: '/demandes_remboursement',
                                doneFirstCard: {
                                    type: 'doneRegular',
                                    doneText: 'Pas de demandes à traiter'
                                },
                                titleSecondCard: 'Ordres de mission',
                                numberShowSecondCard: 0,
                                textShowSingularSecondCard: 'Ordre de mission à traiter',
                                textShowPluralSecondCard: 'Ordres de mission à traiter',
                                linkSecondCard: '/ordres_mission',
                                doneSecondCard: {
                                    type: 'doneRegular',
                                    doneText: 'Pas de demandes à traiter'
                                }
                            }
                        ]
                    },
                    {
                        cardIcon: 'clipboard-list',
                        auth: ['gc.center-view', 'gc.convention-view'],
                        allCards: [
                            {
                                key: 'nb_centres',
                                cardType: 'REGULAR',
                                auth: ['gc.center-view'],
                                title: 'CENTRES',
                                numberShow: 0,
                                textShowSingular: 'Centre',
                                textShowPlural: 'Centres',
                                link: '/centres',
                                bottomText: '',
                                done: {
                                    doneText: 'Pas de centre à gérer',
                                    graphNumber1: 12,
                                    graphNumber2: 24,
                                    graphText1: 'SPS',
                                    graphText2: 'SOL'
                                }
                            },
                            {
                                key: 'nb_conventions_signees',
                                cardType: 'REGULAR',
                                auth: ['gc.convention-view'],
                                title: 'CONVENTIONS SIGNÉES',
                                numberShow: 0,
                                textShowSingular: 'Convention',
                                textShowPlural: 'Conventions',
                                link: '/conventions',
                                bottomText: '',
                                done: {
                                    doneText: 'Toutes les conventions sont signées',
                                    graphNumber1: 12,
                                    graphNumber2: 24,
                                    graphText1: 'SPS',
                                    graphText2: 'SOL'
                                }
                            }
                        ]
                    }
                ],
                dashboardResponse: {
                    data: {
                        'gc.center-view': [],
                        'gc.convention-view': [],
                        'gc.convention-manage': [],
                        'rh.sps-view': {
                            nb_dossiers_total: 476,
                            nb_dossiers_ouverts: 118,
                            nb_dossiers_valides: 0,
                            nb_pv_total: 14,
                            nb_pv_valides: 2,
                            nb_intervenants_affectation: 523,
                            nb_intervenants_besoin: 523
                        },
                        'rh.dosadm-view': {
                            nb_dossiers_total: 118,
                            nb_dossiers_ouverts: 1,
                            nb_dossiers_valides: 0,
                            nb_contrats_signes: 1,
                            nb_contrats_total: 1,
                            nb_ordres_mission_total: 0,
                            nb_ordres_mission_traites: 0,
                            nb_demandes_remb_total: 0,
                            nb_demandes_remb_traitees: 0
                        },
                        'rh.dosadm-manage': [],
                        'rh.contrat-view': [],
                        'rh.contrat-manage': [],
                        'rh.frais-view': [],
                        'rh.frais-manage': [],
                        'rh.sol-view': { nb_villes_total: 0, nb_centres_total: 0, nb_conventions_signees: 0 },
                        'rh.sol-manage': [],
                        'signature-view': []
                    }
                }
            }
            break
        case 'gestionnaire exatech':
            userRoleDashboard = {
                abilities: {
                    id: 494,
                    civility: 'M.',
                    name: 'USER',
                    first_name: 'Gestionnaire Exatech',
                    email: 'gestionnaire.exatech@test.com',
                    telephone: null,
                    code: null,
                    password_changed: true,
                    email_verified: true,
                    rgpd_accepted: true,
                    two_factor: false,
                    roles: [],
                    abilities: [
                        {
                            id: 1,
                            name: 'adm.par-view',
                            title: 'Adm.par view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 3,
                            name: 'adm.ini-view',
                            title: 'Adm.ini view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 5,
                            name: 'adm.util-view',
                            title: 'Adm.util view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 6,
                            name: 'adm.util-manage',
                            title: 'Adm.util manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 7,
                            name: 'cand-view',
                            title: 'Cand view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 9,
                            name: 'cand.mesure-view',
                            title: 'Cand.mesure view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 11,
                            name: 'gc.center-view',
                            title: 'Gc.center view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 13,
                            name: 'gc.convention-view',
                            title: 'Gc.convention view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 15,
                            name: 'ecr.prepa-view',
                            title: 'Ecr.prepa view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 17,
                            name: 'ecr.sujet-view',
                            title: 'Ecr.sujet view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 19,
                            name: 'ecr.gfou-view',
                            title: 'Ecr.gfou view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 21,
                            name: 'ecr.reclam-view',
                            title: 'Ecr.reclam view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 23,
                            name: 'oral.prepa-view',
                            title: 'Oral.prepa view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 25,
                            name: 'oral.afex-view',
                            title: 'Oral.afex view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 27,
                            name: 'oral.plan-view',
                            title: 'Oral.plan view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 29,
                            name: 'oral.suivi-view',
                            title: 'Oral.suivi view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 31,
                            name: 'oral.reclam-view',
                            title: 'Oral.reclam view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 33,
                            name: 'dlb.prepjury-view',
                            title: 'Dlb.prepjury view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 35,
                            name: 'dlb.pubnot-view',
                            title: 'Dlb.pubnot view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 37,
                            name: 'rh.sps-view',
                            title: 'Rh.sps view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 39,
                            name: 'rh.dosadm-view',
                            title: 'Rh.dosadm view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 41,
                            name: 'rh.contrat-view',
                            title: 'Rh.contrat view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 43,
                            name: 'rh.frais-view',
                            title: 'Rh.frais view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 45,
                            name: 'rh.sol-view',
                            title: 'Rh.sol view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 47,
                            name: 'signature-view',
                            title: 'Signature view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        }
                    ],
                    has_certificate: false
                },
                dashboardComposants: [
                    {
                        cardIcon: 'file-signature',
                        auth: ['signature-view'],
                        allCards: [
                            {
                                key: 'nb_documents_a_signer',
                                cardType: 'REGULAR',
                                auth: ['signature-view'],
                                title: 'SIGNATURE DES DOCUMENTS',
                                numberShow: 0,
                                textShowSingular: 'Document à signer',
                                textShowPlural: 'Documents à signer',
                                link: '/signature_documents',
                                bottomText: '',
                                done: {
                                    doneText: 'Tous les documents sont signés',
                                    graphNumber1: 12,
                                    graphNumber2: 24,
                                    graphText1: 'SPS',
                                    graphText2: 'SOL'
                                }
                            }
                        ]
                    },
                    {
                        cardIcon: 'graduation-cap',
                        auth: ['rh.sps-view'],
                        allCards: [
                            {
                                key: 'dossiers_academique',
                                cardType: 'threeinformations',
                                title: 'Dossiers académiques',
                                auth: ['rh.sps-view'],
                                information1Number: 118,
                                information1TextSingular: 'Dossier publié',
                                information1TextPlural: 'Dossiers publiés',
                                information2Number: 0,
                                information2TextSingular: 'Dossier validé',
                                information2TextPlural: 'Dossiers validés',
                                information3Number: 476,
                                information3TextSingular: 'Dossier au total',
                                information3TextPlural: 'Dossiers au total',
                                link: '/dossiers_academiques',
                                done: {
                                    type: 'string',
                                    doneText: 'doneSingular'
                                }
                            },
                            {
                                key: 'selection_intervenants',
                                cardType: 'progressbar',
                                auth: ['rh.sps-view'],
                                title: 'Sélection des intervenants',
                                fractionNumber1: 2,
                                fractionNumber2: 14,
                                textShowSingular: 'procès-verbal terminé',
                                textShowPlural: 'procès-verbaux terminés',
                                link: '/selections',
                                done: {
                                    type: 'string',
                                    doneText: 'Toutes les sélections sont effectuées'
                                }
                            },
                            {
                                key: 'affectation_intervenants',
                                cardType: 'progressbar',
                                auth: ['rh.sps-view'],
                                title: 'Affectation des intervenants',
                                fractionNumber1: 523,
                                fractionNumber2: 523,
                                textShowSingular: 'Intervenant affecté',
                                textShowPlural: 'Intervenants affectés',
                                link: '/affectations',
                                done: {
                                    type: 'string',
                                    doneText: 'Toutes les affectations sont effectuées'
                                }
                            }
                        ]
                    },
                    {
                        cardIcon: 'folder-open',
                        auth: ['rh.dosadm-view', 'rh.frais-view', 'rh.contrat-view'],
                        allCards: [
                            {
                                key: 'dossiers_administratif',
                                cardType: 'threeinformations',
                                title: 'dossiers administratifs',
                                auth: ['rh.dosadm-view'],
                                information1Number: 1,
                                information1TextSingular: 'Dossier publié',
                                information1TextPlural: 'Dossiers publiés',
                                information2Number: 0,
                                information2TextSingular: 'Dossier validé',
                                information2TextPlural: 'Dossiers validés',
                                information3Number: 118,
                                information3TextSingular: 'Dossier au total',
                                information3TextPlural: 'Dossiers au total',
                                link: '/dossiers_administratifs',
                                done: {
                                    type: 'string',
                                    doneText: 'doneSingular'
                                }
                            },
                            {
                                key: 'contrats',
                                cardType: 'progressbar',
                                auth: ['rh.contrat-view'],
                                title: 'Contrats',
                                fractionNumber1: 1,
                                fractionNumber2: 1,
                                textShowSingular: 'contrat signé',
                                textShowPlural: 'contrats signés',
                                link: '/contrats',
                                done: {
                                    type: 'string',
                                    doneText: 'Tous les contrats sont signés'
                                }
                            },
                            {
                                key: 'ordre_mission_demande_remboursement',
                                cardType: 'duocard',
                                auth: ['rh.frais-view'],
                                titleFirstCard: 'Demandes de remboursement',
                                numberShowFirstCard: 0,
                                textShowSingularFirstCard: 'Remboursement à traiter',
                                textShowPluralFirstCard: 'Remboursements à traiter',
                                linkFirstCard: '/demandes_remboursement',
                                doneFirstCard: {
                                    type: 'doneRegular',
                                    doneText: 'Pas de demandes à traiter'
                                },
                                titleSecondCard: 'Ordres de mission',
                                numberShowSecondCard: 0,
                                textShowSingularSecondCard: 'Ordre de mission à traiter',
                                textShowPluralSecondCard: 'Ordres de mission à traiter',
                                linkSecondCard: '/ordres_mission',
                                doneSecondCard: {
                                    type: 'doneRegular',
                                    doneText: 'Pas de demandes à traiter'
                                }
                            }
                        ]
                    },
                    {
                        cardIcon: 'clipboard-list',
                        auth: ['gc.center-view', 'gc.convention-view'],
                        allCards: [
                            {
                                key: 'nb_centres',
                                cardType: 'REGULAR',
                                auth: ['gc.center-view'],
                                title: 'CENTRES',
                                numberShow: 0,
                                textShowSingular: 'Centre',
                                textShowPlural: 'Centres',
                                link: '/centres',
                                bottomText: '',
                                done: {
                                    doneText: 'Pas de centre à gérer',
                                    graphNumber1: 12,
                                    graphNumber2: 24,
                                    graphText1: 'SPS',
                                    graphText2: 'SOL'
                                }
                            },
                            {
                                key: 'nb_conventions_signees',
                                cardType: 'REGULAR',
                                auth: ['gc.convention-view'],
                                title: 'CONVENTIONS SIGNÉES',
                                numberShow: 0,
                                textShowSingular: 'Convention',
                                textShowPlural: 'Conventions',
                                link: '/conventions',
                                bottomText: '',
                                done: {
                                    doneText: 'Toutes les conventions sont signées',
                                    graphNumber1: 12,
                                    graphNumber2: 24,
                                    graphText1: 'SPS',
                                    graphText2: 'SOL'
                                }
                            }
                        ]
                    }
                ],
                dashboardResponse: {
                    data: {
                        'adm.par-view': [],
                        'adm.ini-view': [],
                        'adm.util-view': [],
                        'adm.util-manage': [],
                        'cand-view': [],
                        'cand.mesure-view': [],
                        'gc.center-view': [],
                        'gc.convention-view': [],
                        'ecr.prepa-view': [],
                        'ecr.sujet-view': [],
                        'ecr.gfou-view': [],
                        'ecr.reclam-view': [],
                        'oral.prepa-view': [],
                        'oral.afex-view': [],
                        'oral.plan-view': [],
                        'oral.suivi-view': [],
                        'oral.reclam-view': [],
                        'dlb.prepjury-view': [],
                        'dlb.pubnot-view': [],
                        'rh.sps-view': {
                            nb_dossiers_total: 476,
                            nb_dossiers_ouverts: 118,
                            nb_dossiers_valides: 0,
                            nb_pv_total: 14,
                            nb_pv_valides: 2,
                            nb_intervenants_affectation: 523,
                            nb_intervenants_besoin: 523
                        },
                        'rh.dosadm-view': {
                            nb_dossiers_total: 118,
                            nb_dossiers_ouverts: 1,
                            nb_dossiers_valides: 0,
                            nb_contrats_signes: 1,
                            nb_contrats_total: 1,
                            nb_ordres_mission_total: 0,
                            nb_ordres_mission_traites: 0,
                            nb_demandes_remb_total: 0,
                            nb_demandes_remb_traitees: 0
                        },
                        'rh.contrat-view': [],
                        'rh.frais-view': [],
                        'rh.sol-view': { nb_villes_total: 0, nb_centres_total: 0, nb_conventions_signees: 0 },
                        'signature-view': []
                    }
                }
            }
            break
        case 'dev':
            userRoleDashboard = {
                abilities: {
                    id: 493,
                    civility: 'M.',
                    name: 'USER',
                    first_name: 'Dev',
                    email: 'dev@test.com',
                    telephone: null,
                    code: null,
                    password_changed: false,
                    email_verified: true,
                    rgpd_accepted: false,
                    two_factor: false,
                    roles: [],
                    abilities: [
                        {
                            id: 1,
                            name: 'adm.par-view',
                            title: 'Adm.par view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 2,
                            name: 'adm.par-manage',
                            title: 'Adm.par manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 3,
                            name: 'adm.ini-view',
                            title: 'Adm.ini view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 4,
                            name: 'adm.ini-manage',
                            title: 'Adm.ini manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 5,
                            name: 'adm.util-view',
                            title: 'Adm.util view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 6,
                            name: 'adm.util-manage',
                            title: 'Adm.util manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 7,
                            name: 'cand-view',
                            title: 'Cand view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 8,
                            name: 'cand-manage',
                            title: 'Cand manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 9,
                            name: 'cand.mesure-view',
                            title: 'Cand.mesure view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 10,
                            name: 'cand.mesure-manage',
                            title: 'Cand.mesure manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 11,
                            name: 'gc.center-view',
                            title: 'Gc.center view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 12,
                            name: 'gc.center-manage',
                            title: 'Gc.center manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 13,
                            name: 'gc.convention-view',
                            title: 'Gc.convention view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 14,
                            name: 'gc.convention-manage',
                            title: 'Gc.convention manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 15,
                            name: 'ecr.prepa-view',
                            title: 'Ecr.prepa view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 16,
                            name: 'ecr.prepa-manage',
                            title: 'Ecr.prepa manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 17,
                            name: 'ecr.sujet-view',
                            title: 'Ecr.sujet view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 18,
                            name: 'ecr.sujet-manage',
                            title: 'Ecr.sujet manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 19,
                            name: 'ecr.gfou-view',
                            title: 'Ecr.gfou view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 20,
                            name: 'ecr.gfou-manage',
                            title: 'Ecr.gfou manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 21,
                            name: 'ecr.reclam-view',
                            title: 'Ecr.reclam view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 22,
                            name: 'ecr.reclam-manage',
                            title: 'Ecr.reclam manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 23,
                            name: 'oral.prepa-view',
                            title: 'Oral.prepa view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 24,
                            name: 'oral.prepa-manage',
                            title: 'Oral.prepa manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 25,
                            name: 'oral.afex-view',
                            title: 'Oral.afex view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 26,
                            name: 'oral.afex-manage',
                            title: 'Oral.afex manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 27,
                            name: 'oral.plan-view',
                            title: 'Oral.plan view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 28,
                            name: 'oral.plan-manage',
                            title: 'Oral.plan manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 29,
                            name: 'oral.suivi-view',
                            title: 'Oral.suivi view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 30,
                            name: 'oral.suivi-manage',
                            title: 'Oral.suivi manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 31,
                            name: 'oral.reclam-view',
                            title: 'Oral.reclam view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 32,
                            name: 'oral.reclam-manage',
                            title: 'Oral.reclam manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 33,
                            name: 'dlb.prepjury-view',
                            title: 'Dlb.prepjury view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 34,
                            name: 'dlb.prepjury-manage',
                            title: 'Dlb.prepjury manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 35,
                            name: 'dlb.pubnot-view',
                            title: 'Dlb.pubnot view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 36,
                            name: 'dlb.pubnot-manage',
                            title: 'Dlb.pubnot manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 37,
                            name: 'rh.sps-view',
                            title: 'Rh.sps view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 38,
                            name: 'rh.sps-manage',
                            title: 'Rh.sps manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 39,
                            name: 'rh.dosadm-view',
                            title: 'Rh.dosadm view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 40,
                            name: 'rh.dosadm-manage',
                            title: 'Rh.dosadm manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 41,
                            name: 'rh.contrat-view',
                            title: 'Rh.contrat view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 42,
                            name: 'rh.contrat-manage',
                            title: 'Rh.contrat manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 43,
                            name: 'rh.frais-view',
                            title: 'Rh.frais view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 44,
                            name: 'rh.frais-manage',
                            title: 'Rh.frais manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 45,
                            name: 'rh.sol-view',
                            title: 'Rh.sol view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 46,
                            name: 'rh.sol-manage',
                            title: 'Rh.sol manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 47,
                            name: 'signature-view',
                            title: 'Signature view',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        },
                        {
                            id: 48,
                            name: 'signature-manage',
                            title: 'Signature manage',
                            entity_id: null,
                            entity_type: null,
                            only_owned: false,
                            options: [],
                            scope: null
                        }
                    ],
                    has_certificate: false
                },
                dashboardComposants: [
                    {
                        cardIcon: 'file-signature',
                        auth: ['signature-view'],
                        allCards: [
                            {
                                key: 'nb_documents_a_signer',
                                cardType: 'REGULAR',
                                auth: ['signature-view'],
                                title: 'SIGNATURE DES DOCUMENTS',
                                numberShow: 0,
                                textShowSingular: 'Document à signer',
                                textShowPlural: 'Documents à signer',
                                link: '/signature_documents',
                                bottomText: '',
                                done: {
                                    doneText: 'Tous les documents sont signés',
                                    graphNumber1: 12,
                                    graphNumber2: 24,
                                    graphText1: 'SPS',
                                    graphText2: 'SOL'
                                }
                            }
                        ]
                    },
                    {
                        cardIcon: 'graduation-cap',
                        auth: ['rh.sps-view'],
                        allCards: [
                            {
                                key: 'dossiers_academique',
                                cardType: 'threeinformations',
                                title: 'Dossiers académiques',
                                auth: ['rh.sps-view'],
                                information1Number: 118,
                                information1TextSingular: 'Dossier publié',
                                information1TextPlural: 'Dossiers publiés',
                                information2Number: 0,
                                information2TextSingular: 'Dossier validé',
                                information2TextPlural: 'Dossiers validés',
                                information3Number: 476,
                                information3TextSingular: 'Dossier au total',
                                information3TextPlural: 'Dossiers au total',
                                link: '/dossiers_academiques',
                                done: {
                                    type: 'string',
                                    doneText: 'doneSingular'
                                }
                            },
                            {
                                key: 'selection_intervenants',
                                cardType: 'progressbar',
                                auth: ['rh.sps-view'],
                                title: 'Sélection des intervenants',
                                fractionNumber1: 2,
                                fractionNumber2: 14,
                                textShowSingular: 'procès-verbal terminé',
                                textShowPlural: 'procès-verbaux terminés',
                                link: '/selections',
                                done: {
                                    type: 'string',
                                    doneText: 'Toutes les sélections sont effectuées'
                                }
                            },
                            {
                                key: 'affectation_intervenants',
                                cardType: 'progressbar',
                                auth: ['rh.sps-view'],
                                title: 'Affectation des intervenants',
                                fractionNumber1: 523,
                                fractionNumber2: 523,
                                textShowSingular: 'Intervenant affecté',
                                textShowPlural: 'Intervenants affectés',
                                link: '/affectations',
                                done: {
                                    type: 'string',
                                    doneText: 'Toutes les affectations sont effectuées'
                                }
                            }
                        ]
                    },
                    {
                        cardIcon: 'folder-open',
                        auth: ['rh.dosadm-view', 'rh.frais-view', 'rh.contrat-view'],
                        allCards: [
                            {
                                key: 'dossiers_administratif',
                                cardType: 'threeinformations',
                                title: 'dossiers administratifs',
                                auth: ['rh.dosadm-view'],
                                information1Number: 1,
                                information1TextSingular: 'Dossier publié',
                                information1TextPlural: 'Dossiers publiés',
                                information2Number: 0,
                                information2TextSingular: 'Dossier validé',
                                information2TextPlural: 'Dossiers validés',
                                information3Number: 118,
                                information3TextSingular: 'Dossier au total',
                                information3TextPlural: 'Dossiers au total',
                                link: '/dossiers_administratifs',
                                done: {
                                    type: 'string',
                                    doneText: 'doneSingular'
                                }
                            },
                            {
                                key: 'contrats',
                                cardType: 'progressbar',
                                auth: ['rh.contrat-view'],
                                title: 'Contrats',
                                fractionNumber1: 1,
                                fractionNumber2: 1,
                                textShowSingular: 'contrat signé',
                                textShowPlural: 'contrats signés',
                                link: '/contrats',
                                done: {
                                    type: 'string',
                                    doneText: 'Tous les contrats sont signés'
                                }
                            },
                            {
                                key: 'ordre_mission_demande_remboursement',
                                cardType: 'duocard',
                                auth: ['rh.frais-view'],
                                titleFirstCard: 'Demandes de remboursement',
                                numberShowFirstCard: 0,
                                textShowSingularFirstCard: 'Remboursement à traiter',
                                textShowPluralFirstCard: 'Remboursements à traiter',
                                linkFirstCard: '/demandes_remboursement',
                                doneFirstCard: {
                                    type: 'doneRegular',
                                    doneText: 'Pas de demandes à traiter'
                                },
                                titleSecondCard: 'Ordres de mission',
                                numberShowSecondCard: 0,
                                textShowSingularSecondCard: 'Ordre de mission à traiter',
                                textShowPluralSecondCard: 'Ordres de mission à traiter',
                                linkSecondCard: '/ordres_mission',
                                doneSecondCard: {
                                    type: 'doneRegular',
                                    doneText: 'Pas de demandes à traiter'
                                }
                            }
                        ]
                    },
                    {
                        cardIcon: 'clipboard-list',
                        auth: ['gc.center-view', 'gc.convention-view'],
                        allCards: [
                            {
                                key: 'nb_centres',
                                cardType: 'REGULAR',
                                auth: ['gc.center-view'],
                                title: 'CENTRES',
                                numberShow: 0,
                                textShowSingular: 'Centre',
                                textShowPlural: 'Centres',
                                link: '/centres',
                                bottomText: '',
                                done: {
                                    doneText: 'Pas de centre à gérer',
                                    graphNumber1: 12,
                                    graphNumber2: 24,
                                    graphText1: 'SPS',
                                    graphText2: 'SOL'
                                }
                            },
                            {
                                key: 'nb_conventions_signees',
                                cardType: 'REGULAR',
                                auth: ['gc.convention-view'],
                                title: 'CONVENTIONS SIGNÉES',
                                numberShow: 0,
                                textShowSingular: 'Convention',
                                textShowPlural: 'Conventions',
                                link: '/conventions',
                                bottomText: '',
                                done: {
                                    doneText: 'Toutes les conventions sont signées',
                                    graphNumber1: 12,
                                    graphNumber2: 24,
                                    graphText1: 'SPS',
                                    graphText2: 'SOL'
                                }
                            }
                        ]
                    }
                ],
                dashboardResponse: {
                    data: {
                        'adm.par-view': [],
                        'adm.par-manage': [],
                        'adm.ini-view': [],
                        'adm.ini-manage': [],
                        'adm.util-view': [],
                        'adm.util-manage': [],
                        'cand-view': [],
                        'cand-manage': [],
                        'cand.mesure-view': [],
                        'cand.mesure-manage': [],
                        'gc.center-view': [],
                        'gc.center-manage': [],
                        'gc.convention-view': [],
                        'gc.convention-manage': [],
                        'ecr.prepa-view': [],
                        'ecr.prepa-manage': [],
                        'ecr.sujet-view': [],
                        'ecr.sujet-manage': [],
                        'ecr.gfou-view': [],
                        'ecr.gfou-manage': [],
                        'ecr.reclam-view': [],
                        'ecr.reclam-manage': [],
                        'oral.prepa-view': [],
                        'oral.prepa-manage': [],
                        'oral.afex-view': [],
                        'oral.afex-manage': [],
                        'oral.plan-view': [],
                        'oral.plan-manage': [],
                        'oral.suivi-view': [],
                        'oral.suivi-manage': [],
                        'oral.reclam-view': [],
                        'oral.reclam-manage': [],
                        'dlb.prepjury-view': [],
                        'dlb.prepjury-manage': [],
                        'dlb.pubnot-view': [],
                        'dlb.pubnot-manage': [],
                        'rh.sps-view': {
                            nb_dossiers_total: 476,
                            nb_dossiers_ouverts: 118,
                            nb_dossiers_valides: 0,
                            nb_pv_total: 14,
                            nb_pv_valides: 2,
                            nb_intervenants_affectation: 523,
                            nb_intervenants_besoin: 523
                        },
                        'rh.sps-manage': [],
                        'rh.dosadm-view': {
                            nb_dossiers_total: 118,
                            nb_dossiers_ouverts: 1,
                            nb_dossiers_valides: 0,
                            nb_contrats_signes: 1,
                            nb_contrats_total: 1,
                            nb_ordres_mission_total: 0,
                            nb_ordres_mission_traites: 0,
                            nb_demandes_remb_total: 0,
                            nb_demandes_remb_traitees: 0
                        },
                        'rh.dosadm-manage': [],
                        'rh.contrat-view': [],
                        'rh.contrat-manage': [],
                        'rh.frais-view': [],
                        'rh.frais-manage': [],
                        'rh.sol-view': { nb_villes_total: 0, nb_centres_total: 0, nb_conventions_signees: 0 },
                        'rh.sol-manage': [],
                        'signature-view': [],
                        'signature-manage': { nb_documents_a_signer: 0 }
                    }
                }
            }
            break
        default:
            userRoleDashboard = {}
    }

    return userRoleDashboard
}

/** controle si l'utilisateur a les droits et renvois l'icone du tableau a afficher, le label et la valeur de can()  */
export const checkIcone = (ability: any, can?: boolean | true, stringValidated?: string | null) => {
    // const can = Store.getters['auth/can'](ability)
    let iconInfo = { label: 'Éditer', icon: 'pen', canValue: can }
    if (can) {
        iconInfo = { label: 'Éditer', icon: 'pen', canValue: can }
    } else if (!can) {
        iconInfo = { label: 'Voir', icon: 'eye', canValue: can }
    }
    if (stringValidated && stringValidated !== null) {
        iconInfo = { label: 'Voir', icon: 'eye', canValue: can }
    }
    return iconInfo
}

export const formatNumber = (value: number, format = 'fix2') => {
    if (typeof value === 'number') {
        switch (format) {
            case 'fix2':
                // return value.toFixed(2)
                return (Math.round(value * 100) / 100).toFixed(2)
            case 'fix3':
                return value.toFixed(3)
            default:
                return value.toFixed(2)
        }
    } else {
        return value
    }
}

export const getFileNameFromHeader = (headers: any) => {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    if (headers && headers['content-disposition']) {
        const matches = filenameRegex.exec(headers['content-disposition'])
        if (matches != null && matches[1]) {
            return matches[1].replace(/['"]/g, '')
        }
    }
    return null
}

/** controle le premier caractere d'une string et controle si c'est une voyelle. si oui return true  */
export const vowelOrNot = (string: string) => {
    const splitted = string.toLowerCase().split('')
    if (
        splitted[0] === 'a' ||
        splitted[0] === 'e' ||
        splitted[0] === 'i' ||
        splitted[0] === 'o' ||
        splitted[0] === 'u' ||
        splitted[0] === 'y'
    ) {
        return true
    } else {
        return false
    }
}

/** Check si la scrollbar d'un element du dom est visible ou non */
export const scrollbarVisible = (element: HTMLElement) => {
    return element.scrollHeight > element.clientHeight
}

/** Tronque une expression String au nombre de caractères demandé et rajoute "..." à la fin de l'expression */
export const truncateString = (str: string, n: number) => {
    return str ? str.length > n ? str.substring(0, n - 1) + '...' : str : ''
}

/** converti une image base64 en blob */
export const b64toBlob = (b64Data: any, contentType: string) => {
    contentType = contentType || ''
    const sliceSize = 512
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return blob
}

/** Export d'une image base64 au format image/png */
export const exportImgbase64SourceAsImage = (imgbase64_Src: any, rootFileExportName: string) => {
    // on retire le header de la source
    const imgbase64NoHeader = imgbase64_Src.split(';base64,').pop()

    // Transformation en blob
    const imgBlob = b64toBlob(imgbase64NoHeader, 'image/png')

    const currentDate = new Date(Date.now()).toISOString()
    const fileName = rootFileExportName + '_' + formatDateDayHourDateForFileSaving(currentDate) + '.png'

    // export de l'image au format png
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(imgBlob, fileName)
    } else {
        const link = document.createElement('a')
        if (link.download !== undefined) {
            const url = URL.createObjectURL(imgBlob)
            link.setAttribute('id', 'link_downloadExport')
            link.setAttribute('href', url)
            link.setAttribute('download', fileName)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }
}

/** PLUS UTILISE POUR LE MOMENT mais gardé car peut-être utile dans le cas de futurs assemblages d'images. **************************************
 * Procédure évènementielle rattachée au .onload de la liste des images générées pour chaque echarts
 * Permet d'attendre que chaque image soit bien chargée avant de faire les draw dans le ghostCanvas qui va servir de conteneur tampon
 * pour permettre la création d'une source concaténée des images et créer ensuite le blob pour l'export (fonction exportImgbase64SourceAsImage).
 */
export const checkload = () => {
    let nTempImagesLoaded = Store.getters['echartsToolsManage/nTempImagesLoaded']
    const nTempImagesTotal = Store.getters['echartsToolsManage/nTempImagesTotal']

    let imgbase64_src = ''

    nTempImagesLoaded++
    Store.commit('echartsToolsManage/SET_NTEMP_IMAGES_LOADED', nTempImagesLoaded)

    if (nTempImagesLoaded < nTempImagesTotal) {
        return
    }

    // ************** Le chargement des images dans le canvas est terminé, on peut faire la conversion en blob et l'export *****************
    const rootFileExportName = Store.getters['echartsToolsManage/rootFileExportName']
    const nTempImagesImgArray = Store.getters['echartsToolsManage/nTempImagesImgArray']

    // Création du canvas tampon
    const canvas = document.createElement('canvas')
    canvas.id = 'currentCanvas'

    // init des dimensions du ghostCanvas
    canvas.width = nTempImagesImgArray[0].width
    for (const imgIndex in nTempImagesImgArray) {
        // la hauteur est la concaténation de l'ensemble des images des graphes de la liste
        if (parseInt(imgIndex) === 0) {
            canvas.height = nTempImagesImgArray[parseInt(imgIndex)].height
        } else {
            canvas.height = canvas.height + nTempImagesImgArray[parseInt(imgIndex)].height
        }
    }

    // Travail sur la div ghostCanvas
    const ghostCanvas = document.getElementById('ghostCanvas')
    if (ghostCanvas) {
        // Ajout du canvas tampon dans la div ghostCanvas
        ghostCanvas.appendChild(canvas)

        const context = canvas.getContext('2d')
        if (context) {
            let startPositionY = 0

            // Alimentation du canvas tampon avec les images de la liste
            for (const imgIndex in nTempImagesImgArray) {
                if (parseInt(imgIndex) === 0) {
                    context.drawImage(nTempImagesImgArray[parseInt(imgIndex)], 0, 0)
                } else {
                    startPositionY = startPositionY + nTempImagesImgArray[parseInt(imgIndex) - 1].height
                    context.drawImage(nTempImagesImgArray[parseInt(imgIndex)], 0, startPositionY)
                }
            }
        } else {
            console.log('contexte 2d NON trouvé pour le canvas')
        }

        // Récupération de la source du canvas tampon contenant désormais les images
        imgbase64_src = canvas.toDataURL('data/jpeg')

        if (ghostCanvas) {
            // on supprime du DOM
            ghostCanvas.removeChild(canvas)
        }

        // lancement de l'exportation au format image/png
        exportImgbase64SourceAsImage(imgbase64_src, rootFileExportName)
    } else {
        console.log('Element ghostCanvas non trouvé dans le DOM')
    }

    return true
}

/** PLUS UTILISE POUR LE MOMENT mais gardé car peut-être utile dans le cas de futurs assemblages d'images. *********************************************
 * Convertit la liste des echarts passée en paramètre en un blob concaténé et  génère l'export de l'ensemble en une seule et même image.
 * utilise la fonction checkload associée au .onload de chaque image, et qui permet d'alimenter le ghostCanvas pour générer une concaténation des images.
 */
export const exportEchartsAsImage = (listEcharts: any, rootFileExportName: string) => {
    if (listEcharts.length === 1) {
        // On ne convertit qu'un seul Echart
        // console.log('Un seul Echart')
        if (listEcharts[0]) {
            const echart = listEcharts[0]
            const imgbase64 = new Image()
            imgbase64.src = echart.getDataURL({
                pixelRatio: 2,
                backgroundColor: '#fff'
            })
            exportImgbase64SourceAsImage(imgbase64.src, rootFileExportName)
        }
    } else if (listEcharts.length > 1) {
        // On convertit plusieurs Echarts et on va donc faire du merging
        // console.log('Multi Echarts')
        Store.commit('echartsToolsManage/SET_NTEMP_IMAGES_LOADED', 0)
        Store.commit('echartsToolsManage/SET_NTEMP_IMAGES_IMG_ARRAY', [])
        Store.commit('echartsToolsManage/SET_NTEMP_IMAGES_TOTAL', listEcharts.length)
        Store.commit('echartsToolsManage/SET_ROOT_FILE_EXPORT_NAME', rootFileExportName)

        const nTempImagesImgArray: any = []
        for (const indexEchart in listEcharts) {
            nTempImagesImgArray[indexEchart] = new Image()
            nTempImagesImgArray[indexEchart].onload = checkload
            nTempImagesImgArray[indexEchart].src = listEcharts[indexEchart].getDataURL({
                pixelRatio: 2,
                backgroundColor: '#fff'
            })
        }
        Store.commit('echartsToolsManage/SET_NTEMP_IMAGES_IMG_ARRAY', nTempImagesImgArray)
    }

    return true
}

export const exportDivElementAsImage = (elementDomId: string, rootFileExportName: string) => {
    const divToExport = document.getElementById(elementDomId)
    if (divToExport) {
        html2canvas(divToExport, { scale: 3 }).then(function (canvas: any) {
            const imgsrc = canvas.toDataURL('image/png')
            exportImgbase64SourceAsImage(imgsrc, rootFileExportName)
        })
    }
}

/** vérifie la validité de formatage d'une adresse email.
 * En option, on peut préciser si la valeur attendue est obligatoire pour le champ.
 */
export const isEmailValid = (email: string, required?: boolean) => {
    let isValid = true
    let errorEmail = ''
    const reg =
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

    if (!required && (email == null || email === '')) {
        // c'est ok.
        isValid = true
    } else {
        if (required && (email == null || email === '')) {
            // email obligatoire mais non renseigné
            isValid = false
            errorEmail = 'Veuillez rentrer une adresse email'
        } else if (!reg.test(email)) {
            // format de l'email invalide
            isValid = false
            errorEmail = 'Veuillez rentrer une adresse email valide'
        }
    }

    return {
        isValid: isValid,
        errorEmail: errorEmail
    }
}

// Retourne le libellé du poste selon le type d'épreuve
export const getLibellePoste = (epreuveCorrection: any) => {
    let libelle_poste = ''
    const epreuveCorrectionResultatSelect = epreuveCorrection
    if (
        epreuveCorrectionResultatSelect.epreuve_correction &&
        epreuveCorrectionResultatSelect.epreuve_correction.type_passation === TypePassation.TYPE_PASSATION_ECRIT
    ) {
        libelle_poste = 'Correcteurs'
    } else if (
        (epreuveCorrectionResultatSelect.epreuve_correction &&
            epreuveCorrectionResultatSelect.epreuve_correction.type_passation === TypePassation.TYPE_PASSATION_ORAL) ||
        (epreuveCorrectionResultatSelect.epreuve_correction &&
            epreuveCorrectionResultatSelect.epreuve_correction.type_passation === TypePassation.TYPE_PASSATION_TP)
    ) {
        libelle_poste = 'Examinateurs'
    } else if (
        epreuveCorrectionResultatSelect.epreuve_correction &&
        epreuveCorrectionResultatSelect.epreuve_correction.type_passation === TypePassation.TYPE_PASSATION_EXTERNE
    ) {
        libelle_poste = 'Examinateurs'
    }

    return libelle_poste
}

// Applique un opérateur
export const applique_operator = (value1: any, value2: any, operator: string) => {
    switch (operator) {
        case '>=':
            return value1 >= value2
        case '<=':
            return value1 <= value2
    }
}

export const numberWithSpaces = (x: number) => {
    // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    const parts = x.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    return parts.join('.')
}

// Formatage d'un numero de téléphone : +33 X XX XX XX XX
export const format_phone_number = (x: string) => {
    if (x && x !== null && x !== undefined && x !== '') {
        const cleanedNumber = x.replace(/\D/g, '')
        if (cleanedNumber.startsWith('+')) {
            // Format international
            const countryCode = cleanedNumber.slice(1, 3)
            const nationalNumber = cleanedNumber.slice(3).replace(/(.{2})(?=.)/g, '$1 ')
            return '+' + countryCode + ' ' + nationalNumber
        } else if (cleanedNumber.startsWith('00')) {
            // Format international alternatif
            const countryCode = cleanedNumber.slice(2, 4)
            const nationalNumber = cleanedNumber.slice(4)
            return '+' + countryCode + ' ' + nationalNumber
        } else if (cleanedNumber.length === 10 && cleanedNumber.startsWith('0')) {
            // Format fr commencant par 0
            return cleanedNumber.replace(/(.{2})/g, '$1 ').replace(/^./, '+33 ')
        } else if (cleanedNumber.length === 11 && cleanedNumber.startsWith('33')) {
            // Format fr commencant par 33 (sans le 0)
            return cleanedNumber.replace(/^(.{2})(.{1})(.{2})(.{2})(.{2})(.{2})$/, '+$1 $2 $3 $4 $5 $6')
        } else if (cleanedNumber.length === 12) {
            // Format à 12 chiffre
            return cleanedNumber.replace(/^(.{3})(.{1})(.{2})(.{2})(.{2})(.{2})$/, '+$1 $2 $3 $4 $5 $6')
        } else if (cleanedNumber.length === 11) {
            // Format à 11 chiffre
            return cleanedNumber.replace(/^(.{3})(.{2})(.{2})(.{2})(.{2})$/, '+$1 $2 $3 $4 $5')
        } else {
            // le reste ...
            if (cleanedNumber.length % 2 === 0) {
                return cleanedNumber.replace(/(?=(?:..)*$)/g, ' ')
            } else {
                const temp1 = cleanedNumber.slice(0, 3)
                const temp2 = cleanedNumber.slice(3).replace(/(?=(?:..)*$)/g, ' ')
                return '+' + temp1 + ' ' + temp2
            }
            // AGESILAS
        }
    } else {
        return ''
    }
}

// Recuperer la liste des timezones
export const getTimeZones = () => {
    const tabTimeZones: { [index: string]: any } = {}
    const timezones = moment.tz.names()

    for (const t in timezones) {
        // On ne prend que les entrées qui on une mention du continent
        if (timezones[t].indexOf('/') !== -1) {
            // On isole le continent
            const libelle = timezones[t].split('/')
            const continent = libelle[0]

            // On retire le ETC
            if (continent !== 'Etc') {
                if (!tabTimeZones[continent]) {
                    tabTimeZones[continent] = []
                }

                tabTimeZones[continent].push(timezones[t])
            }
        }
    }

    return tabTimeZones
}

/**
 * CALCULS
 */

// Retourne la moyenne d'une collection de notes
export const moyenne = (notes: Array<any>) => {
    const nb_notes = notes.length
    let total_notes = 0
    for (const val of notes) {
        total_notes += val
    }

    return (total_notes/nb_notes).toFixed(2)
}

// Retourne l'écart type d'une collection de notes
export const ecart_type = (notes: Array<any>, moyenne: number) => {
    const nb_notes = notes.length
    if (nb_notes > 2) {
        let variance = 0
        for (const val of notes) {
            variance += Math.pow(val - moyenne, 2)
        }
        return Math.sqrt(variance / nb_notes).toFixed(2)
    }
}

// Retourne le calcul de quantile
export const quantile = (notes: Array<any>, percentage: number) => {
    const nb_notes = notes.length
    if (nb_notes > 0) {
        _.orderBy(notes, 'asc')
        const pos = ((nb_notes - 1) * percentage)
        const base = Math.floor(pos)
        const rest = pos - base

        if (notes[base+1]) {
            return (notes[base] + rest * (notes[base +1] - notes[base])).toFixed(2)
        } else {
            return notes[base].toFixed(2)
        }
    }
}
