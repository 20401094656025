import * as API from '@/services/API'
import store from '@/store'

export default {
    getExaminateurs(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/intervenants`, { params: payload })
    },
    getDisponibilitesExaminateur() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/disponibilites`)
    },
    getPDFDisposExaminateur(user_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/examinateurs_disponibilites/${user_id}/pdf`, {
            headers: { 'X-Request-Retry': 0 },
            params: { format: 'b64' }
        })
    },
    getExaminateurPres(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/presences_examinateurs`, { params: payload })
    },
    getExaminateurPresExcel(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/presences_examinateurs`, {
            params: payload,
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    },
    getExaminateurPresPDF(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/presences_examinateurs`, { params: payload })
    },
    getTrombinoscope(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/trombinoscope`, { params: payload })
    },
    getExaminateursDisponibilite(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/examinateurs_disponibilites`, { params: payload })
    },
    updateExaminateursDisponibilite(params: any) {
        const session_id = store.getters['auth/user_session_id']
        //examinateurs_disponibilites/{user}/ensembles/{ensemble}/series/{serie}/update
        return API.apiClient.post(`/sessions/${session_id}/examinateurs_disponibilites/${params.user_id}/ensembles/${params.ensemble_id}/series/${params.serie_id}/update`, params.payload )
    }
}
