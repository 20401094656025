import { getError } from '@/utils/helpers'
import { ConventionTypeInterface, StateConventionType } from '@/types/ConventionType'
import ConventionTypeService from '@/services/ConventionTypeService'

export const namespaced = true
export const namespace = true

function setConventions(commit: any, response: any) {
    commit('SET_CONVENTION_TYPES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateConventionType = {
    conventiontypes: [],
    conventiontype_select: null,
    meta: null,
    links: null,
    loading: false,
    error: null,
    lastPage: 0,
    totalRows: 0,
    source_pdf: ''
}

export const mutations = {
    RESET_STATE(state: StateConventionType) {
        Object.assign(state, {
            conventiontypes: [],
            conventiontype_select: null,
            meta: null,
            links: null,
            loading: false,
            error: null,
            lastPage: 0,
            totalRows: 0,
            source_pdf: ''
        })
    },
    SET_CONVENTION_TYPES(state: any, conventiontypes: Array<ConventionTypeInterface>) {
        state.conventiontypes = conventiontypes
    },
    SET_CONVENTION_TYPE(state: any, conventiontype: ConventionTypeInterface) {
        state.conventiontype_select = conventiontype
    },
    SET_CONVENTION_TYPE_ADD(state: any, conventiontype: ConventionTypeInterface) {
        state.conventiontype_select = conventiontype
        state.conventiontype_select.centres_count = 0
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_PDF(state: any, source: string) {
        state.source_pdf = source
    }
}

export const actions = {
    getConventionTypes({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConventionTypeService.getConventionTypes()
                .then((response) => {
                    commit('SET_LOADING', false)
                    setConventions(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getConventionType({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConventionTypeService.getConventionType(params.convention_type_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_CONVENTION_TYPE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateConventionType({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConventionTypeService.updateConventionType(params.convention_type_id, params.payload)
                .then((response) => {
                    commit('SET_CONVENTION_TYPE', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    saveConventionType({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConventionTypeService.saveConventionType(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_CONVENTION_TYPE_ADD', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteConventionType({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConventionTypeService.deleteConventionType(params.convention_type_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPDF({ commit }: { commit: any }, conventiontype_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConventionTypeService.getPDF(conventiontype_id)
                .then((response) => {
                    commit('SET_PDF', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPDFAnnexe({ commit }: { commit: any }, conventiontype_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConventionTypeService.getPDFAnnexe(conventiontype_id)
                .then((response) => {
                    commit('SET_PDF', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    conventiontypes: (state: StateConventionType) => {
        return state.conventiontypes
    },
    conventiontype_select: (state: StateConventionType) => {
        return state.conventiontype_select
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.concours.length
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    source_pdf: (state: any) => {
        return state.source_pdf
    }
}
