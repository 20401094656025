export interface SessionInterface {
    id: number,
    sessionId: number | null,
    code: string,
    long_name: string,
    name: string,
    closedAt: Date | null
}

export interface StateSessions {
    sessions: Array<SessionInterface> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    sessionActive: SessionInterface | null,
    sessionSelect: SessionInterface | null,
    currentPage: number | 1
}

// Si structure est validée ou non
export enum etat_structure {
    NON_VALIDEE,
    VALIDEE,
}
