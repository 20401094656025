import * as API from '@/services/API'
import store from '@/store'

export default {
    getPublications() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/publications`)
    },
    updatePublication(publication_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/publications/${publication_id}`, payload)
    },
    tryValidateDatas(publication_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/publications/${publication_id}/try`)
    },
    retryValidateDatas(publication_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/publications/${publication_id}/retry`)
    }
}
