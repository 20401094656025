import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import '@/styles/custom-bootstrap.scss'
import '@/styles/menu.scss'
import 'vue-pdf-app/dist/icons/main.css'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faAbacus,
    faAddressCard,
    faArchive,
    faArrowAltDown,
    faArrowAltSquareDown,
    faArrowAltToBottom,
    faArrowLeft,
    faBalanceScale,
    faBallotCheck,
    faBooks,
    faBoxCheck,
    faBus,
    faCabinetFiling,
    faCalculator,
    faCalculatorAlt,
    faCalendar,
    faCalendarAlt,
    faCalendarCheck,
    faCalendarEdit,
    faChartBar,
    faChevronRight,
    faClipboardListCheck,
    faCommentAltExclamation,
    faCommentExclamation,
    faComments,
    faDolly,
    faDonate,
    faFileCheck,
    faFileEdit,
    faFileImport,
    faFilePlus,
    faFileSpreadsheet,
    faFolders,
    faGreaterThanEqual,
    faHandPaper,
    faHandshake,
    faHandshakeAlt,
    faLifeRing,
    faLockAlt,
    faLockOpen,
    faLockOpenAlt,
    faPaperclip,
    faPaperPlane,
    faPencil,
    faPencilRuler,
    faPeopleArrows,
    faPodium,
    faPollPeople,
    faPrint,
    faPrintSearch,
    faPrintSlash,
    faRedo,
    faRepeatAlt,
    faSortAmountUpAlt,
    faSortSizeUpAlt,
    faStepBackward,
    faStepForward,
    faTasksAlt,
    faThumbtack,
    faTrash,
    faUndo,
    faUser,
    faUserClock,
    faUserCog,
    faUserEdit,
    faUserMinus,
    faUsersClass,
    faUserSlash,
    faUsersMedical,
    faWatch,
    faWindowMinimize,
    faDownload,
    faUpload,
    faProjectDiagram,
    faEdit
} from '@fortawesome/pro-solid-svg-icons'
import {
    faArrowAltCircleDown,
    faArrowAltCircleRight,
    faCheckSquare,
    faClone,
    far,
    faSquare
} from '@fortawesome/free-regular-svg-icons'
import {
    faAlignJustify,
    faArrowCircleRight,
    faAsterisk,
    faBell,
    faBuilding,
    faCalendarDay,
    faCalendarPlus,
    faCalendarTimes,
    faCar,
    faCaretLeft,
    faCaretRight,
    faChalkboardTeacher,
    faCheck,
    faCheckCircle,
    faChevronCircleRight,
    faChevronDown,
    faChevronLeft,
    faCircle,
    faCity,
    faClipboard,
    faClipboardList,
    faClock,
    faCog,
    faCogs,
    faCommentAlt,
    faCommentDots,
    faCopy,
    faCreditCard,
    faCube,
    faDoorClosed,
    faEnvelope,
    faEquals,
    faEraser,
    faExchangeAlt,
    faExclamation,
    faExclamationTriangle,
    faEye,
    faFile,
    faFileAlt,
    faFileContract,
    faFileDownload,
    faFileInvoice,
    faFileInvoiceDollar,
    faFilePdf,
    faFileSignature,
    faFilter,
    faFolder,
    faFolderOpen,
    faGraduationCap,
    faHandHoldingUsd,
    faHome,
    faHourglass,
    faIdBadge,
    faIdCard,
    faInfoCircle,
    faKey,
    faLock,
    faMailBulk,
    faMapMarkerAlt,
    faMarker,
    faMinus,
    faMoneyCheck,
    faMoneyCheckAlt,
    faNotEqual,
    faPen,
    faPenAlt,
    faPenNib,
    faPlane,
    faPlus,
    faPlusCircle,
    faPollH,
    faPortrait,
    faQuestion,
    faSave,
    faSchool,
    faSearch,
    faShare,
    faSpinner,
    faSubway,
    faTable,
    faTag,
    faTags,
    faTimes,
    faTimesCircle,
    faTrain,
    faTrashAlt,
    faUnlink,
    faUnlock,
    faUserCheck,
    faUserFriends,
    faUserGraduate,
    faUsers,
    faUsersCog,
    faUserShield,
    faUserTag,
    faUserTimes,
    faVoteYea,
    faWheelchair
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fal } from '@fortawesome/pro-light-svg-icons'

library.add(
    faSpinner,
    fal,
    far,
    faAbacus,
    faWatch,
    faFileSpreadsheet,
    faCheckSquare,
    faSquare,
    faArrowAltCircleDown,
    faCalendarPlus,
    faCalendarTimes,
    faEquals,
    faCube,
    faWheelchair,
    faQuestion,
    faTable,
    faUnlink,
    faUserGraduate,
    faCopy,
    faFileInvoice,
    faFileDownload,
    faMailBulk,
    faChevronLeft,
    faAlignJustify,
    faEnvelope,
    faMinus,
    faPlus,
    faKey,
    faUnlock,
    faExclamation,
    faPlusCircle,
    faMoneyCheckAlt,
    faMoneyCheck,
    faIdCard,
    faCreditCard,
    faVoteYea,
    faFileSignature,
    faTag,
    faExchangeAlt,
    faChevronDown,
    faClone,
    faLock,
    faChevronCircleRight,
    faArrowCircleRight,
    faUsers,
    faDoorClosed,
    faMapMarkerAlt,
    faSchool,
    faCity,
    faClipboardList,
    faHourglass,
    faCheck,
    faTrain,
    faCar,
    faPlane,
    faCaretRight,
    faCaretLeft,
    faCogs,
    faCheckCircle,
    faEraser,
    faClipboard,
    faTrashAlt,
    faTimesCircle,
    faMarker,
    faCommentDots,
    faFilePdf,
    faPenNib,
    faFileInvoiceDollar,
    faCircle,
    faFile,
    faPollH,
    faGraduationCap,
    faIdBadge,
    faUserCheck,
    faUsersCog,
    faFolderOpen,
    faPen,
    faFolder,
    faFileContract,
    faSubway,
    faHandHoldingUsd,
    faCog,
    faCalendarDay,
    faChalkboardTeacher,
    faTags,
    faBuilding,
    faBell,
    faUserShield,
    faUserFriends,
    faHome,
    faCommentAlt,
    faEye,
    faFileAlt,
    faFilter,
    faTimes,
    faSearch,
    faUserTimes,
    faPodium,
    faPencil,
    faClock,
    faArrowAltToBottom,
    faArrowAltCircleRight,
    faInfoCircle,
    faUserEdit,
    faCalculator,
    faUserClock,
    faPollPeople,
    faExclamationTriangle,
    faChevronRight,
    faBoxCheck,
    faLock,
    faLockOpen,
    faUser,
    faCommentAltExclamation,
    faFileEdit,
    faBus,
    faArrowCircleRight,
    faGreaterThanEqual,
    faCalculatorAlt,
    faArrowLeft,
    faArrowAltDown,
    faArrowAltSquareDown,
    faTasksAlt,
    faStepBackward,
    faStepForward,
    faThumbtack,
    faUndo,
    faRedo,
    faAsterisk,
    faNotEqual,
    faUsersClass,
    faRepeatAlt,
    faAddressCard,
    fal,
    faPeopleArrows,
    faCalendar,
    faHandPaper,
    faPrint,
    faUserCog,
    faDonate,
    faTrash,
    faPencilRuler,
    faCalendarEdit,
    faFolders,
    faUserSlash,
    faCommentExclamation,
    faComments,
    faChalkboardTeacher,
    faHandshake,
    faHandshakeAlt,
    faWindowMinimize,
    faUserMinus,
    faCalendarAlt,
    faPrintSearch,
    faPrint,
    faPrintSlash,
    faChartBar,
    faPaperPlane,
    faPaperclip,
    faUsersMedical,
    faCalendarCheck,
    faSortAmountUpAlt,
    faBalanceScale,
    faSortSizeUpAlt,
    faUserTag,
    faSave,
    faFilePlus,
    faShare,
    faPortrait,
    faPenAlt,
    faBallotCheck,
    faClipboardListCheck,
    faDolly,
    faArchive,
    faBooks,
    faCabinetFiling,
    faFileCheck,
    faLockAlt,
    faLockOpenAlt,
    faLifeRing,
    faFileImport,
    faDownload,
    faUpload,
    faProjectDiagram,
    faEdit
)

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')
