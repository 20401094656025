import * as API from '@/services/API'
import store from '@/store'

export default {
    async getPoste(posteId: number) {
        return await API.apiClient.get(`/poste/${posteId}`)
    },
    async getPostes(payload: any) {
        return await API.apiClient.get(`/postes`, { params: payload })
    },
    async getSessionPostes(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/postes`, { params: payload })
    },
    async getAllPostes(payload: any) {
        return await API.apiClient.get('/postes', { params: payload })
    },
    async updatePoste(payload: any) {
        return await API.apiClient.put(`/postes/${payload.id}`, payload)
    },
    async addPoste(payload: any) {
        return await API.apiClient.post('/postes', payload)
    },
    async deletePoste(poste_id: number) {
        return await API.apiClient.delete(`/postes/${poste_id}`)
    }
}
