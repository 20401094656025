import { getError } from '@/utils/helpers'
import FournitureService from '@/services/FournitureService'
import { FournitureInterface, StateFournitures } from '@/types/Fourniture'

export const namespaced = true

function setFournitures(commit: Function, response: any) {
    commit('SET_FOURNITURES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateFournitures = {
    fournitures: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    fournitureSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateFournitures) {
        Object.assign(state, {
            fournitures: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            fournitureSelect: {}
        })
    },
    SET_FOURNITURES(state: any, fournitures: Array<FournitureInterface>) {
        state.fournitures = fournitures
    },
    SET_MORE_FOURNITURES(state: any, fournitures: Array<FournitureInterface>) {
        state.fournitures = state.fournitures.concat(fournitures)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_FOURNITURE_SELECT(state: any, fourniture: FournitureInterface) {
        state.fournitureSelect = fourniture
    }
}

export const actions = {
    getFournitures({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FournitureService.getFournitures(params)
                .then((response) => {
                    setFournitures(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getFourniture({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FournitureService.getFourniture(params.fourniture_id)
                .then((response) => {
                    commit('SET_FOURNITURE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreFournitures({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FournitureService.getFourniture(params.fourniture_id)
                .then((response) => {
                    commit('SET_FOURNITURE_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateFourniture({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FournitureService.updateFourniture(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addFourniture({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FournitureService.addFourniture(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteFourniture({ commit }: { commit: any }, fourniture_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FournitureService.deleteFourniture(fourniture_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    relaunchSuiviFournitures({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FournitureService.relaunchFournitures()
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    fournitures: (state: any) => {
        return state.fournitures
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.fournitures.length
    },
    fournitureSelect: (state: any) => {
        return state.fournitureSelect
    },
    getFournitureById: (state: any) => (id: number) => {
        const fourniture = state.fournitures.filter((mat: any) => mat.id === id)[0]
        return fourniture
    },
    getCodeFourniture: (state: any) => (id: number) => {
        const fourniture = state.fournitures.filter((mat: any) => mat.id === id)[0]
        if (fourniture) {
            return fourniture.code
        } else {
            return 'NR'
        }
    },
    getFournituresObs: (state: any) => (liste_fourniture_obs: any) => {
        const tab_fournitures: Array<any> = []
        for (const mo in liste_fourniture_obs) {
            if (liste_fourniture_obs[mo].entity_id) {
                const fourniture_temp = state.fournitures.find((m: any) => m.id === liste_fourniture_obs[mo].entity_id)
                if (fourniture_temp) {
                    tab_fournitures.push(fourniture_temp)
                }
            }
        }

        return tab_fournitures
    }
}
