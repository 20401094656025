import { getError } from '@/utils/helpers'
import ReclamationTypeService from '@/services/ReclamationTypeService'
import { ReclamationTypeInterface, StateReclamationTypes } from '@/types/ReclamationType'

export const namespaced = true

function setReclamationTypes(commit: Function, response: any) {
    commit('SET_RECLAMATION_TYPES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateReclamationTypes = {
    reclamationTypes: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    reclamationTypeSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateReclamationTypes) {
        Object.assign(state, {
            reclamationTypes: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            reclamationTypeSelect: {}
        })
    },
    SET_RECLAMATION_TYPES(state: any, reclamationTypes: Array<ReclamationTypeInterface>) {
        state.reclamationTypes = reclamationTypes
    },
    SET_MORE_RECLAMATION_TYPES(state: any, reclamationTypes: Array<ReclamationTypeInterface>) {
        state.reclamationTypes = state.reclamationTypes.concat(reclamationTypes)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_RECLAMATION_TYPE_SELECT(state: any, reclamationType: ReclamationTypeInterface) {
        state.reclamationTypeSelect = reclamationType
    }
}

export const actions = {
    getReclamationTypes({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationTypeService.getReclamationTypes(params)
                .then((response) => {
                    setReclamationTypes(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getReclamationType({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationTypeService.getReclamationType(params.reclamationType_id)
                .then((response) => {
                    commit('SET_RECLAMATION_TYPE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreReclamationTypes({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationTypeService.getReclamationType(params.reclamationType_id)
                .then((response) => {
                    commit('SET_RECLAMATION_TYPE_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateReclamationType({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationTypeService.updateReclamationType(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addReclamationType({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationTypeService.addReclamationType(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteReclamationType({ commit }: { commit: any }, reclamationType_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationTypeService.deleteReclamationType(reclamationType_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    reclamationTypes: (state: any) => {
        return state.reclamationTypes
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.reclamationTypes.length
    },
    reclamationTypeSelect: (state: any) => {
        return state.reclamationTypeSelect
    },
    getReclamationTypeById: (state: any) => (id: number) => {
        return state.reclamationTypes.find((reclamation: any): boolean => reclamation.id === id)
    }
}
