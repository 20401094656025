import DocumentService from '@/services/DocumentService'
import { DocumentInterface } from '@/types/Document'
import { getError } from '@/utils/helpers'
import { Etat } from '@/types/DossierAcademique'

export const namespaced = true

function setDocuments(commit: any, response: any) {
    commit('SET_DOCUMENTS', response.data.data)
    commit('SET_LOADING', false)
}

export const state = {
    documents: [],
    meta: null,
    links: null,
    loading: false,
    errorDocument: null,
    success: null,
    totalRows: 0,
    nb_rejete: 0,
    documentSelect: {}
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            documents: [],
            meta: null,
            links: null,
            loading: false,
            errorDocument: null,
            success: null,
            totalRows: 0,
            nb_rejete: 0,
            documentSelect: {}
        })
    },
    SET_DOCUMENTS(state: any, documents: Array<DocumentInterface>) {
        state.documents = documents
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.errorDocument = error
    },
    SET_SUCCESS(state: any, success: Array<any>) {
        state.success = success
    },
    SET_ADD_DOCUMENT(state: any, document: DocumentInterface) {
        state.documents.push(document)
    },
    SET_REMOVE_DOCUMENT(state: any, document_id: number) {
        for (const i in state.documents) {
            if (state.documents[i].id === document_id) {
                state.documents.splice(i, 1)
            }
        }
    },
    SET_FILENAME(state: any, value: string) {
        state.documentSelect.name = value
    },
    SET_DOCUMENT_TYPE(state: any, value: string) {
        state.documentSelect.type = value
    },
    SET_ID(state: any, value: number) {
        state.documentSelect.id = value
    },
    SET_DOCUMENT_ETAT(state: any, value: number) {
        state.documentSelect.etat = value
    },
    SET_DOCUMENT_SELECT(state: any, value: any) {
        state.documentSelect = value
    },
    SET_DOCUMENT_DATA(state: any, value: any) {
        state.documentSelect.data = value
    },
    SET_NB_PJ_REJETE(state: any) {
        state.nb_rejete++
    },
    RESET_NB_PJ_REJETE(state: any) {
        state.nb_rejete = 0
    }
}

export const actions = {
    storeDocument({ commit }: { commit: any }, params: any) {
        // commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DocumentService.storeDocument(params.dossier_id, params.payload)
                .then((response) => {
                    commit('SET_ADD_DOCUMENT', response.data.data)
                    commit('SET_ERROR', '')
                    commit('SET_ID', response.data.data.id)
                    commit('SET_DOCUMENT_ETAT', Etat.ETAT_DEPOSE)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteDocument({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DocumentService.deleteDocument(params.dossier_id, params.document_id)
                .then((response) => {
                    commit('SET_REMOVE_DOCUMENT', params.document_id)
                    commit('SET_ERROR', '')
                    commit('SET_SUCCESS', 'Suppression réussie')
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDocument({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DocumentService.getDocument(params.dossier_id, params.document_id)
                .then((response) => {
                    commit('SET_DOCUMENT_DATA', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    rejeteDocument({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DocumentService.rejeteDocument(params.dossier_id, params.document_id, params.etat)
                .then((response) => {
                    commit('SET_ERROR', '')
                    commit('SET_DOCUMENT_ETAT', Etat.ETAT_REJETE)
                    commit('SET_NB_PJ_REJETE')
                    resolve(response)
                })
                .catch((error) => {
                    if (error !== '') {
                        commit('SET_ERROR', getError(error))
                    }
                    reject(error)
                })
        })
    },
    storeDocumentAdm({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DocumentService.storeDocumentAdm(params.dossier_id, params.payload)
                .then((response) => {
                    commit('SET_ADD_DOCUMENT', response.data.data)
                    commit('SET_ERROR', '')
                    commit('SET_ID', response.data.data.id)
                    commit('SET_DOCUMENT_ETAT', Etat.ETAT_DEPOSE)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteDocumentAdm({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DocumentService.deleteDocumentAdm(params.dossier_id, params.document_id)
                .then((response) => {
                    commit('SET_REMOVE_DOCUMENT', params.document_id)
                    commit('SET_ERROR', '')
                    commit('SET_SUCCESS', 'Suppression réussie')
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDocumentAdm({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DocumentService.getDocumentAdm(params.dossier_id, params.document_id)
                .then((response) => {
                    commit('SET_DOCUMENT_DATA', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateDocumentAdm({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DocumentService.updateDocumentAdm(params.dossier_id, params.document_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_DOCUMENT_ETAT', Etat.ETAT_REJETE)
                    commit('SET_NB_PJ_REJETE')
                    resolve(response)
                })
                .catch((error) => {
                    if (error !== '') {
                        commit('SET_ERROR', getError(error))
                    }
                    reject(error)
                })
        })
    },
    getDocumentCandidat({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DocumentService.getDocumentCandidat(params.candidat_id, params.document_id, params.b64)
                .then((response) => {
                    commit('SET_DOCUMENT_DATA', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDocumentsExaminateur({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DocumentService.getDocumentsExaminateur(params)
                .then((response) => {
                    // commit('SET_DOCUMENT_DATA', response.data)
                    setDocuments(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    storeDocumentExaminateur({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DocumentService.storeDocumentExaminateur(params.user_id, params.payload)
                .then((response) => {
                    commit('SET_ADD_DOCUMENT', response.data.data)
                    commit('SET_ERROR', '')
                    commit('SET_ID', response.data.data.id)
                    commit('SET_DOCUMENT_ETAT', Etat.ETAT_DEPOSE)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteDocumentExaminateur({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DocumentService.deleteDocumentExaminateur(params.user_id, params.document_id)
                .then((response) => {
                    commit('SET_REMOVE_DOCUMENT', params.document_id)
                    commit('SET_ERROR', '')
                    commit('SET_SUCCESS', 'Suppression réussie')
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDocumentExaminateur({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DocumentService.getDocumentExaminateur(params.user_id, params.document_id)
                .then((response) => {
                    commit('SET_DOCUMENT_DATA', response.data)
                    commit('SET_ERROR', '')
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    documents: (state: any) => {
        return state.documents
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.errorDocument
    },
    success: (state: any) => {
        return state.success
    },
    documentSelect: (state: any) => {
        return state.documentSelect
    },
    nb_rejete: (state: any) => {
        return state.nb_rejete
    }
}
