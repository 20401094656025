import * as API from '@/services/API'
import store from '@/store'

export default {
    updateSessionUser(user_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/sessionusers/${user_id}`, payload)
    },
    getSessionUser(user_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/sessionusers/${user_id}`)
    },
    getSessionUserStatsvalidation() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/sessionusers_statsvalidation`)
    },
    updateSessionusersIncompatibilites(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/sessionusers_incompatibilites`, payload)
    },
    updateSessionusersDisponibilites(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/sessionusers_disponibilites`, payload)
    }
}
