import * as API from '@/services/API'
import store from '@/store'
// ------ job_profile_forms JobProfileForms

export default {
    async getJobProfileForm (formulaireId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/job_profile_forms/${formulaireId}`)
    },
    async getJobProfileForms (payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/job_profile_forms`, { params: payload })
    },
    async updateJobProfileForm (payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/job_profile_forms/${payload.id}`, payload)
    },
    async addJobProfileForm (payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/job_profile_forms`, payload)
    },
    async deleteJobProfileForm (formulaire_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/job_profile_forms/${formulaire_id}`)
    },
    async getDocument(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/job_profile_forms/${payload.formulaire_id}/documents/${payload.documentId}?format=b64`)
    },
    async storeDocument (params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/job_profile_forms/${params.formulaire_id}/documents`, params.payload)
    },
    async deleteDocument(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/job_profile_forms/${payload.formulaire_id}/documents/${payload.documentId}`)
    },
    async getDocumentReference(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/job_profile_forms/${payload.jobProfileForm_id}/reference/documents/${payload.documentId}?format=b64`)
    },
    async storeDocumentReference(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/job_profile_forms/${params.jobProfileForm_id}/reference/documents`, params.payload)
    },
    async deleteDocumentReference(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/job_profile_forms/${payload.jobProfileForm_id}/reference/documents/${payload.documentId}`)
    },
    async cloneJobProfileForm(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/job_profile_forms/${payload.id}/clone`, payload)
    }

    // ------ job_profile_forms JobProfileForms

}
