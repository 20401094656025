import * as API from '@/services/API'
import store from '@/store'
import { AxiosRequestConfig } from 'axios'

export default {
    async getEpreuvesCorrections(payload: any) {
        // page: number, sortby: string) {
        let session_id = store.getters['auth/user_session_id']
        if (payload) {
            if (payload.session_replace) {
                session_id = payload.session_replace
            }
            if (payload.session_id) {
                if (payload.type_passation) {
                    return await API.apiClient.get(
                        `/sessions/${session_id}/epreuvecorrections?typePassation=${payload.type_passation}`,
                        { params: payload.params }
                    )
                } else {
                    return await API.apiClient.get(`/sessions/${session_id}/epreuvecorrections`, {
                        params: payload.params
                    })
                }
            } else if (payload.params) {
                return await API.apiClient.get(`/sessions/${session_id}/epreuvecorrections`, {
                    params: payload.params.filters
                })
            } else {
                return await API.apiClient.get(`/sessions/${session_id}/epreuvecorrections`, {
                    params: payload.filters
                })
            }
        } else {
            return await API.apiClient.get(`/sessions/${session_id}/epreuvecorrections`)
        }
    },
    async updateEpreuveCorrection(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/epreuvecorrections/${payload.id}`, payload)
    },
    async getEpreuves(payload: any) {
        // page: number, sortby: string) {
        const session_id = store.getters['auth/user_session_id']

        // return API.apiClient.get(`/epreuves?page=${page}&${sortby}`)
        // return API.apiClient.get('/epreuves', { params: payload })
        if (payload) {
            if (payload.session_id) {
                if (payload.params) {
                    if (payload.params.filters) {
                        return await API.apiClient.get(`/sessions/${session_id}/epreuves`, {
                            params: payload.params?.filters
                        })
                    } else {
                        return await API.apiClient.get(`/sessions/${session_id}/epreuves`, { params: payload.params })
                    }
                } else {
                    return await API.apiClient.get(`/sessions/${session_id}/epreuves`)
                }
            } else if (payload.params) {
                if (payload.params.filters) {
                    return await API.apiClient.get(`/sessions/${session_id}/epreuves`, {
                        params: payload.params.filters
                    })
                } else {
                    return await API.apiClient.get(`/sessions/${session_id}/epreuves`, { params: payload.params })
                }
            } else {
                return await API.apiClient.get(`/sessions/${session_id}/epreuves`, { params: payload.filters })
            }
        } else {
            return await API.apiClient.get(`/sessions/${session_id}/epreuves`)
        }
    },
    async getEpreuve(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/epreuves/${payload.id}`)
    },
    async getConcoursGroupeEpreuves(payload: any) {
        // page: number, sortby: string) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/concours_groupe_epreuves/${payload.type_ensemble}`)
    },
    async addEpreuve(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/epreuves`, payload)
    },
    async updateEpreuve(epreuve: any) {
        /*
        const params = {
            id: epreuve.id,
            name: epreuve.name,
            code: epreuve.code,
            estimation_nb_candidats: epreuve.estimation_nb_candidats,
            passation_at: new Date(epreuve.passation_at).toISOString(),
            duree: epreuve.duree
        }
        */
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/epreuves/${epreuve.id}`, epreuve)
    },
    async deleteEpreuve(epreuveId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/epreuves/${epreuveId}`)
    },
    async getTypeEpreuves() {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/typeepreuves`)
    },
    async addTypeEpreuve(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/typeepreuves`, payload)
    },
    async updateTypeEpreuve(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/typeepreuves/${payload.id}`, payload)
    },
    async updateEpreuveCalendrier(epreuve: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/epreuves/${epreuve.id}/calendrier`, epreuve)
    },
    async deleteTypeEpreuve(typeEpreuveId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/typeepreuves/${typeEpreuveId}`)
    },
    async exportCalendrierEpreuves() {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                // 'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return await API.apiClient.get(`/sessions/${session_id}/epreuves_calendrier_export`, params)
    },
    async updateEpreuveCorrectionPlacesPrevues(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/epreuvecorrections/${payload.id}/places_prevues`, payload.params)
    }
}
