// seances_anomalies
import * as API from '@/services/API'
import store from '@/store'

export default {
    async getSeanceAnomalie(seanceAnomalieId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/seances_anomalies/${seanceAnomalieId}`)
    },
    async getSeanceAnomalies(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/seances_anomalies`, { params: payload })
    },
    async updateSeanceAnomalie(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/seances_anomalies/${payload.id}`, payload)
    },
    async addSeanceAnomalie(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/seances_anomalies`, payload)
    },
    async deleteSeanceAnomalie(seanceAnomalie_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/seances_anomalies/${seanceAnomalie_id}`)
    }
}
