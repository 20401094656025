import { getError } from '@/utils/helpers'
import DefinitionDesBarres from '@/services/DefinitionDesBarresService'
import { StateDefinitionDesBarres } from '@/types/DefinitionDesBarres'

export const namespaced = true

function setDefinitionDesBarres(commit: Function, response: any) {
    commit('SET_TABLEAU_CONCOURS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
}

export const state: StateDefinitionDesBarres = {
    tableauDesConcours: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    selectedConcour: [],
    selectedTab: 'listeDesNotes',
    work_in_progress: false,
    old_saisie: [],
    saisie_en_cours: []
}

export const mutations = {
    RESET_STATE(state: StateDefinitionDesBarres) {
        Object.assign(state, {
            tableauDesConcours: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            work_in_progress: false,
            old_saisie: [],
            new_saisie: [],
            saisie_en_cours: []
        })
    },
    SET_TABLEAU_CONCOURS(state: any, tableauDesConcours: any) {
        state.tableauDesConcours = tableauDesConcours
    },
    SET_MORE_TABLEAU_CONCOURS(state: any, tableauDesConcours: any) {
        state.tableauDesConcours = state.tableauDesConcours.concat(tableauDesConcours)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_SELECTED_CONCOUR(state: any, concour: any) {
        state.selectedConcour = concour
    },
    SET_SELECTED_TAB(state: any, tab: string) {
        state.selectedTab = tab
    },
    SET_WORK_PROGRESS (state: any, etat: boolean) {
        state.work_in_progress = etat
    },
    RESET_OLD_SAISIE (state: any) {
        state.old_saisie = []
    },
    RESET_SAISIE_EN_COURS (state: any) {
        state.saisie_en_cours = []
    },
    UPDATE_CONCOURS_BY_ID(state: any, data: any) {
        for (let i = 0; i < state.tableauDesConcours.length; i++) {
            if (state.tableauDesConcours[i].id === data.id) {
                state.tableauDesConcours[i] = data
            }
        }
    }

}

export const actions = {
    getTableauDesConcours({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DefinitionDesBarres.getDefinitionDesBarres(params)
                .then((response) => {
                    setDefinitionDesBarres(commit, response)
                    commit('SET_LOADING', false)
                    commit('SET_META', state.meta)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreTableauDesConcours({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DefinitionDesBarres.getDefinitionDesBarres(params)
                .then((response) => {
                    commit('SET_MORE_TABLEAU_CONCOURS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    commit('SET_META', state.meta)

                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },

    getListe({ commit }: { commit: any }) {
        return new Promise((resolve, reject) => {
            DefinitionDesBarres.getDefinitionDesBarres()
                .then((response) => {
                    commit('SET_LISTE_INTERVENANTS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },

    putDefinitionDesBarres({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DefinitionDesBarres.putDefinitionDesBarres(payload)
                .then((response) => {
                    commit('UPDATE_CONCOURS_BY_ID', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateConcourPhaseBarThresholdValue({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DefinitionDesBarres.updateConcourPhaseBarThresholdValue(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('UPDATE_CONCOURS_BY_ID', response.data.data)
                    resolve(response)
                    
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    validationnDesBarres({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DefinitionDesBarres.validationnDesBarres(payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }

}

export const getters = {
    tableauDesConcours: (state: any) => {
        return state.tableauDesConcours
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    totalRows: (state: any) => {
        return state.totalRows
    },
    selectedConcour: (state: any) => {
        return state.selectedConcour
    },
    selectedTab: (state: any) => {
        return state.selectedTab
    },
    work_in_progress: (state: any) => {
        return state.work_in_progress
    }
}
