import { MatiereInterface } from '@/types/Matiere'
import { ConcourInterface } from '@/types/Concour'

export enum PosteType {
    TYPE_EXTERNE = 0,
    TYPE_PEDAGOGIQUE = 1,
    TYPE_LOGISTIQUE = 2,
}

export enum PosteContext {
    CONTEXT_SOUHAIT = 1,
    CONTEXT_SELECTION = 2,
    CONTEXT_AFFECTATION = 4,
    CONTEXT_RESPONSABLE_CENTRE = 8, // spécifique au poste chef d'établissement
    CONTEXT_CHEF_CENTRE = 16, // spécifique au poste chef de centre pour les écrits
    CONTEXT_CORRESPONDANT_ORAL = 32, // spécifique au poste correspondant d'oral
    CONTEXT_SECRETAIRE = 64, // spécifique au poste secrétaire
}

export enum EntityType {
    ET_EPREUVE_CORRECTION = 'App\\Models\\EpreuveCorrection',
    ET_MATIERE = 'App\\Models\\Matiere',
    ET_CENTRE = 'App\\Models\\Centre',
}

interface PosteTypeSpec {
    index: number,
    libelle: string
}

interface EntityTypeSpec {
    value: string,
    libelle: string
}

interface PosteContextSpec {
    index: number,
    libelle: string
}

export function getEntityTypeSpec(posteType: EntityType): EntityTypeSpec {
    switch (posteType) {
        case EntityType.ET_EPREUVE_CORRECTION:
            return { value: 'App\\Models\\EpreuveCorrection', libelle: 'Épreuve de correction' }
        case EntityType.ET_MATIERE:
            return { value: 'App\\Models\\Matiere', libelle: 'Matière' }
        case EntityType.ET_CENTRE:
            return { value: 'App\\Models\\Centre', libelle: 'Centre' }
        default:
            return { value: 'App\\Models\\EpreuveCorrection', libelle: 'Épreuve de correction' }
    }
}

export function getPosteTypeSpec(posteType: PosteType): PosteTypeSpec {
    switch (posteType) {
        case PosteType.TYPE_EXTERNE:
            return { index: 0, libelle: 'Externe' }
        case PosteType.TYPE_PEDAGOGIQUE:
            return { index: 1, libelle: 'Pédagogique' }
        case PosteType.TYPE_LOGISTIQUE:
            return { index: 2, libelle: 'Opérationnel' }
        default:
            return { index: 1, libelle: 'Pédagogique' }
    }
}

export function getEntityTypeShortFormat(posteType: EntityType): string {
    switch (posteType) {
        case EntityType.ET_EPREUVE_CORRECTION:
            return 'epreuvecorrection'
        case EntityType.ET_MATIERE:
            return 'matière'
        case EntityType.ET_CENTRE:
            return 'centre'
        default:
            return 'epreuvecorrection'
    }
}

export function getPosteContextToArray(posteContext: number) {
    const retour = []
    if (PosteContext.CONTEXT_SOUHAIT & posteContext) {
        retour.push(PosteContext.CONTEXT_SOUHAIT)
    }
    if (PosteContext.CONTEXT_SELECTION & posteContext) {
        retour.push(PosteContext.CONTEXT_SELECTION)
    }
    if (PosteContext.CONTEXT_AFFECTATION & posteContext) {
        retour.push(PosteContext.CONTEXT_AFFECTATION)
    }
    return retour
}

export function getPosteContextFromArray(posteContexts: Array<number>) {
    let retour = 0
    for (let i = 0; i < posteContexts.length; i++) {
        retour += posteContexts[i]
    }
    return retour
}

export function getPosteContextSpec(posteContext: PosteContext): PosteContextSpec {
    const retour: string[] = []
    if (PosteContext.CONTEXT_SOUHAIT & posteContext) {
        retour.push('Souhait')
    }
    if (PosteContext.CONTEXT_SELECTION & posteContext) {
        retour.push('Sélection')
    }
    if (PosteContext.CONTEXT_AFFECTATION & posteContext) {
        retour.push('Affectation')
    }
    return { index: posteContext, libelle: retour.join(', ') }
}

export interface PosteInterface {
    id: number,
    type: number,
    name: string,
    context: number,
    context_type: number,
    entity_type: string,
    type_passation: number,
    matieres: MatiereInterface[],
    regroupement: string | '',
    concour: ConcourInterface,
    epreuve_name: string | '',
    options: JSON,
    options_affectes: string
}

export interface PosteListeInterface {
    id: number,
    type: number,
    name: string,
    selected: boolean | false,
    etat: number | 0
}

export interface StatePosteInterface {
    postes: Array<PosteInterface> | [],
    listePostes: Array<PosteListeInterface> | [],
    postes_souhaits: Array<PosteListeInterface> | [],
    postes_affectations: Array<PosteListeInterface> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    postes_selected: Array<any> | []
}


export enum decision_poste {
    DECISION_EN_ATTENTE = 0,
    DECISION_POSTE_ACCEPTE = 1,
    DECISION_RENONCIATION = 2,
    DECISION_RENONCIATION_DEFINITIVE = 3
}

export function getDecisionPosteString (decision: decision_poste) {
    switch (decision) {
        case decision_poste.DECISION_EN_ATTENTE:
            return 'En Attente'
        case decision_poste.DECISION_POSTE_ACCEPTE:
            return 'Poste accepté'
        case decision_poste.DECISION_RENONCIATION:
            return 'Renonciation'
        case decision_poste.DECISION_RENONCIATION_DEFINITIVE:
            return 'Renonciation définitive'
    }
}



