import { getError } from '@/utils/helpers'
import { StateDefinitionDesBarresStatistiquesEpreuves } from '@/types/DefinitionDesBarresStatistiquesEpreuves'

import DefinitionDesBarresStatistiquesEpreuves from '@/services/DefinitionDesBarresStatistiquesEpreuvesService'

export const namespaced = true

function setStatistiquesEpreuves(commit: Function, response: any) {
    commit('SET_TABLEAU_STATISTIQUES_EPREUVES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
}

export const state: StateDefinitionDesBarresStatistiquesEpreuves = {
    tableauStatistiquesEpreuves: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    selectedConcour: []
}

export const mutations = {
    RESET_STATE(state: StateDefinitionDesBarresStatistiquesEpreuves) {
        Object.assign(state, {
            tableauStatistiquesEpreuves: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0
        })
    },
    SET_TABLEAU_STATISTIQUES_EPREUVES(
        state: StateDefinitionDesBarresStatistiquesEpreuves,
        tableauStatistiquesEpreuves: any
    ) {
        state.tableauStatistiquesEpreuves = tableauStatistiquesEpreuves
    },
    SET_MORE_STATISTIQUES_EPREUVES(
        state: StateDefinitionDesBarresStatistiquesEpreuves,
        tableauStatistiquesEpreuves: any
    ) {
        state.tableauStatistiquesEpreuves = state.tableauStatistiquesEpreuves.concat(tableauStatistiquesEpreuves)
    },
    SET_META(state: StateDefinitionDesBarresStatistiquesEpreuves, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: StateDefinitionDesBarresStatistiquesEpreuves, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: StateDefinitionDesBarresStatistiquesEpreuves, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: StateDefinitionDesBarresStatistiquesEpreuves, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: StateDefinitionDesBarresStatistiquesEpreuves, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: StateDefinitionDesBarresStatistiquesEpreuves, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: StateDefinitionDesBarresStatistiquesEpreuves, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CURRENT_PAGE(state: StateDefinitionDesBarresStatistiquesEpreuves, currentPage: number) {
        state.currentPage = currentPage
    }
}

export const actions = {
    getTableauStatistiquesEpreuves({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DefinitionDesBarresStatistiquesEpreuves.getDefinitionDesBarresStatistiquesEpreuves(payload)
                .then((response) => {
                    setStatistiquesEpreuves(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreTableauStatistiquesEpreuves({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DefinitionDesBarresStatistiquesEpreuves.getDefinitionDesBarresStatistiquesEpreuves(payload)
                .then((response) => {
                    commit('SET_MORE_STATISTIQUES_EPREUVES', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    commit('SET_LOADING', false)
                    commit('SET_META', response.data.meta)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    RESET_STATISTIQUES_EPREUVES({ commit }: { commit: any }) {
        commit.RESET_STATE()
    }
}

export const getters = {
    tableauStatistiquesEpreuves: (state: StateDefinitionDesBarresStatistiquesEpreuves) => {
        return state.tableauStatistiquesEpreuves
    },
    meta: (state: StateDefinitionDesBarresStatistiquesEpreuves) => {
        return state.meta
    },
    links: (state: StateDefinitionDesBarresStatistiquesEpreuves) => {
        return state.links
    },
    loading: (state: StateDefinitionDesBarresStatistiquesEpreuves) => {
        return state.loading
    },
    error: (state: StateDefinitionDesBarresStatistiquesEpreuves) => {
        return state.error
    },
    currentPage: (state: StateDefinitionDesBarresStatistiquesEpreuves) => {
        return state.currentPage
    },
    lastPage: (state: StateDefinitionDesBarresStatistiquesEpreuves) => {
        return state.lastPage
    },
    totalPage: (state: StateDefinitionDesBarresStatistiquesEpreuves) => {
        return state.totalPage
    },
    totalRows: (state: StateDefinitionDesBarresStatistiquesEpreuves) => {
        return state.totalRows
    }
}
