import { getError } from '@/utils/helpers'
import DocumentSignableService from '@/services/DocumentSignableService'
import { DocumentSignableInterface, DocumentSignableType, StateDocumentSignable } from '@/types/DocumentSignable'

export const namespaced = true

function setDocumentsSignables(commit: any, response: any) {
    commit('SET_DOCUMENTS_SIGNABLES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_TOTALROWS', response.data.data.length)
}

function setMoreDocumentsSignables(commit: any, response: any) {
    commit('SET_MORE_DOCUMENTS_SIGNABLES', response.data.data)
    // commit('SET_META', response.data.meta)
    // commit('SET_LINKS', response.data.links)
    // commit('SET_LAST_PAGE', (response.data.meta ? response.data.meta.last_page : 1))
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    // commit('SET_TOTALROWS', response.data.data.length)
}

function parse_getEnumTypesDeDocuments(response: any) {
    let parseddatas: any = []
    const enumTypes: Array<any> = []

    parseddatas = JSON.parse(JSON.stringify(response.data.data.entity_type))

    const keys = Object.keys(parseddatas)
    keys.forEach((key) => {
        enumTypes.push({ key: key, name: parseddatas[key] })
    })

    return enumTypes
}

export const state: StateDocumentSignable = {
    documentsSignables: [],
    documentsSignablesToSign: [],
    documentSignableSelect: {},
    documentSignableTypes: [],
    source_pdf: '',
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0
    // sortby: ''
}

export const mutations = {
    RESET_STATE(state: StateDocumentSignable) {
        Object.assign(state, {
            documentsSignables: [],
            documentsSignablesToSign: [],
            documentSignableSelect: {},
            documentSignableTypes: [],
            source_pdf: '',
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            sortby: ''
        })
    },
    SET_DOCUMENT_SIGNABLE_TYPES(state: any, documentSignableTypes: Array<DocumentSignableType>) {
        state.documentSignableTypes = documentSignableTypes
    },
    SET_DOCUMENTS_SIGNABLES(state: any, documentsSignables: Array<DocumentSignableInterface>) {
        state.documentsSignables = documentsSignables
        state.documentsSignablesToSign = state.documentsSignables.filter(
            (d: DocumentSignableInterface) => d.validated_at === null
        )
    },
    SET_MORE_DOCUMENTS_SIGNABLES(state: any, documentsSignables: any) {
        state.documentsSignables = state.documentsSignables.concat(documentsSignables)
        state.documentsSignablesToSign = state.documentsSignables.filter(
            (d: DocumentSignableInterface) => d.validated_at === null
        )
    },
    SET_DOCUMENT_SIGNABLE_CURRENT(state: any, documentSignable: DocumentSignableInterface) {
        state.documentSignableSelect = documentSignable
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_SORTBY(state: any, params: string) {
        state.sortby = params
    },
    SET_PDF_SOURCE(state: any, source: string) {
        state.source_pdf = source
    }
}

export const actions = {
    getDocumentsSignables({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DocumentSignableService.getDocumentsSignables(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    setDocumentsSignables(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreDocumentsSignables({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DocumentSignableService.getDocumentsSignables(params)
                .then((response) => {
                    setMoreDocumentsSignables(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDocumentSignablePdf({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DocumentSignableService.getDocumentSignablePdf(params.entity_type, params.documentId)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_PDF_SOURCE', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    signDocumentSignable({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DocumentSignableService.signDocumentSignable(params.entity_type, params.documentId, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    signDocumentsSignables({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DocumentSignableService.signDocumentsSignables(params.payload)
                .then((response) => {
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getEnumTypesDeDocuments({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DocumentSignableService.getEnumTypesDeDocuments()
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_DOCUMENT_SIGNABLE_TYPES', parse_getEnumTypesDeDocuments(response))
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getTokenSignature({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DocumentSignableService.getTokenSignature(params)
                .then((response) => {
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getTokensSignature({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DocumentSignableService.getTokensSignature(params)
                .then((response) => {
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    documentsSignableTypes: (state: StateDocumentSignable) => {
        return state.documentSignableTypes
    },
    documentsSignables: (state: StateDocumentSignable) => {
        return state.documentsSignables
    },
    documentsSignablesToSign: (state: StateDocumentSignable) => {
        return state.documentsSignablesToSign
    },
    documentSignable_select: (state: StateDocumentSignable) => {
        return state.documentSignableSelect
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.totalRows
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    sortby: (state: any) => {
        return state.sortby
    },
    source_pdf: (state: any) => {
        return state.source_pdf
    },
    toSignedDocumentsNumber(state: any) {
        let toSignedDocumentsNumber = 0
        const filter_doc = state.documentsSignables.filter((d: DocumentSignableInterface) => d.validated_at === null)
        toSignedDocumentsNumber = filter_doc.length
        return toSignedDocumentsNumber
    }
}
