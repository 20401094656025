import { getError } from '@/utils/helpers'
import { StateDefinitionDesBarresListesNotes } from '@/types/DefinitionDesBarresListeNotes'

import DefinitionDesBarresListeNotes from '@/services/DefinitionDesBarresListeNotes'

export const namespaced = true

function setListeDesNotes(commit: Function, response: any) {
    commit('SET_TABLEAU_LISTE_NOTES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
}

function resetListeDesNotes(commit: Function) {
    commit('SET_TABLEAU_LISTE_NOTES', [])
    commit('SET_META', null)
    commit('SET_LINKS', null)
    commit('SET_TOTALROWS', 0)
    commit('SET_LAST_PAGE', 0)
    commit('SET_CURRENT_PAGE', 1)
}

export const state: StateDefinitionDesBarresListesNotes = {
    tableauListeNotes: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    errorExport: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    selectedConcour: [],
    params_definition_barres: {}
}

export const mutations = {
    RESET_STATE(state: StateDefinitionDesBarresListesNotes) {
        Object.assign(state, {
            tableauListeNotes: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            params_definition_barres: {}
        })
    },
    SET_TABLEAU_LISTE_NOTES(state: StateDefinitionDesBarresListesNotes, tableauListeNotes: any) {
        state.tableauListeNotes = tableauListeNotes
    },
    SET_MORE_TABLEAU_LISTE_NOTE(state: StateDefinitionDesBarresListesNotes, tableauListeNotes: any) {
        state.tableauListeNotes = state.tableauListeNotes.concat(tableauListeNotes)
    },
    SET_META(state: StateDefinitionDesBarresListesNotes, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: StateDefinitionDesBarresListesNotes, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: StateDefinitionDesBarresListesNotes, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: StateDefinitionDesBarresListesNotes, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: StateDefinitionDesBarresListesNotes, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: StateDefinitionDesBarresListesNotes, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: StateDefinitionDesBarresListesNotes, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CURRENT_PAGE(state: StateDefinitionDesBarresListesNotes, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_PARAMS(state: StateDefinitionDesBarresListesNotes, params: any) {
        state.params_definition_barres = params
    },
    SET_ERROR_EXPORT(state: StateDefinitionDesBarresListesNotes, error: Array<any>) {
        state.errorExport = error
    }
}

export const actions = {
    getTableauListeDesNotes({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DefinitionDesBarresListeNotes.getDefinitionDesBarresListeNotes(payload)
                .then((response) => {
                    setListeDesNotes(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreTableauListeDesNotes({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DefinitionDesBarresListeNotes.getDefinitionDesBarresListeNotes(payload)
                .then((response) => {
                    commit('SET_MORE_TABLEAU_LISTE_NOTE', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    commit('SET_LOADING', false)
                    commit('SET_META', response.data.meta)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getCandidatAbs({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DefinitionDesBarresListeNotes.getDefinitionDesBarresListeNotes(payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    RESET_LISTE_NOTES({ commit }: { commit: any }) {
        resetListeDesNotes(commit)
    },
    exportListeNotes({ commit }: { commit: any }, payload?: any) {
        return new Promise((resolve) => {
            DefinitionDesBarresListeNotes.exportationDesListeNotes(payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((errorExport) => {
                    commit('SET_ERROR_EXPORT', getError(errorExport))
                })
        })
    },
    exportListeNotesJO({ commit }: { commit: any }, payload?: any) {
        return new Promise((resolve) => {
            DefinitionDesBarresListeNotes.exportationDesListeNotesJO(payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((errorExport) => {
                    commit('SET_ERROR_EXPORT', getError(errorExport))
                })
        })
    },
    exportListeCandidatAdmissiblesCng({ commit }: { commit: any }, payload?: any) {
        return new Promise((resolve, reject) => {
            DefinitionDesBarresListeNotes.exportListeCandidatAdmissiblesCng(payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((errorExport) => {
                    commit('SET_ERROR_EXPORT', getError(errorExport, false))
                    reject(errorExport)
                })
        })
    }
}

export const getters = {
    tableauListeNotesTrie: (state: StateDefinitionDesBarresListesNotes) => {
        return state.tableauListeNotes
    },
    meta: (state: StateDefinitionDesBarresListesNotes) => {
        return state.meta
    },
    links: (state: StateDefinitionDesBarresListesNotes) => {
        return state.links
    },
    loading: (state: StateDefinitionDesBarresListesNotes) => {
        return state.loading
    },
    error: (state: StateDefinitionDesBarresListesNotes) => {
        return state.error
    },
    errorExport: (state: StateDefinitionDesBarresListesNotes) => {
        return state.errorExport
    },
    currentPage: (state: StateDefinitionDesBarresListesNotes) => {
        return state.currentPage
    },
    lastPage: (state: StateDefinitionDesBarresListesNotes) => {
        return state.lastPage
    },
    totalPage: (state: StateDefinitionDesBarresListesNotes) => {
        return state.totalPage
    },
    totalRows: (state: StateDefinitionDesBarresListesNotes) => {
        return state.totalRows
    }
}
