import * as API from '@/services/API'
import store from '@/store'
// ------ job_description_forms JobDescriptionForms

export default {
    async getJobDescriptionForm (formulaireId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/job_description_forms/${formulaireId}`)
    },
    async getJobDescriptionForms (payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/job_description_forms`, { params: payload })
    },
    async updateJobDescriptionForm (payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/job_description_forms/${payload.id}`, payload)
    },
    async addJobDescriptionForm (payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/job_description_forms`, payload)
    },
    async deleteJobDescriptionForm (formulaire_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/job_description_forms/${formulaire_id}`)
    },
    async getDocument(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/job_description_forms/${payload.formulaire_id}/documents/${payload.documentId}?format=b64`)
    },
    async storeDocument (params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/job_description_forms/${params.formulaire_id}/documents`, params.payload)
    },
    async deleteDocument(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/job_description_forms/${payload.formulaire_id}/documents/${payload.documentId}`)
    },
    async getDocumentReference(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/job_description_forms/${payload.jobDescriptionForm_id}/reference/documents/${payload.documentId}?format=b64`)
    },
    async storeDocumentReference(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/job_description_forms/${params.jobDescriptionForm_id}/reference/documents`, params.payload)
    },
    async deleteDocumentReference(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/job_description_forms/${payload.jobDescriptionForm_id}/reference/documents/${payload.documentId}`)
    },
    async cloneJobDescriptionForm(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/job_description_forms/${payload.id}/clone`, payload)
    }

    // ------ job_description_forms JobDescriptionForms

}
