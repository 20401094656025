import { getError } from '@/utils/helpers'
import GroupeIntervenantService from '@/services/GroupeIntervenantService'
import { GroupeIntervenantInterface, StateGroupeIntervenant } from '@/types/GroupeIntervenant'

export const namespaced = true

export const state: StateGroupeIntervenant = {
    intervenants: [],
    meta: null,
    links: null,
    loading: false,
    error: null
}

export const mutations = {
    RESET_STATE(state: StateGroupeIntervenant): void {
        Object.assign(state, {
            intervenants: [],
            meta: null,
            links: null,
            loading: false,
            error: null
        })
    },
    SET_INTERVENANTS(state: any, intervenants: Array<GroupeIntervenantInterface>): void {
        state.intervenants = intervenants
    },
    SET_MORE_INTERVENANTS(state: any, intervenants: Array<GroupeIntervenantInterface>): void {
        state.intervenants = state.intervenants.concat(intervenants)
    },
    SET_META(state: any, meta: Array<any>): void {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>): void {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean): void {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>): void {
        state.error = error
    }
}

export const actions = {
    getGroupeIntervenants({ commit }: { commit: any }, params: { payload: any; prevent_store?: boolean }) {
        commit('SET_ERROR', null)
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GroupeIntervenantService.getGroupeIntervenants(params.payload)
                .then((response: any): void => {
                    if (!params.prevent_store) {
                        commit('SET_INTERVENANTS', response.data.data)
                        commit('SET_META', response.data.meta)
                        commit('SET_LINKS', response.data.links)
                    }
                    resolve(response)
                })
                .catch((error: any): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getMoreGroupeIntervenants({ commit }: { commit: any }, params: { payload: any }) {
        commit('SET_ERROR', null)
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GroupeIntervenantService.getGroupeIntervenants(params.payload)
                .then((response: any): void => {
                    commit('SET_MORE_INTERVENANTS', response.data.data)
                    commit('SET_META', response.data.meta)
                    commit('SET_LINKS', response.data.links)
                    resolve(response)
                })
                .catch((error: any): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {
    intervenants: (state: any): Array<GroupeIntervenantInterface> => {
        return state.intervenants
    },
    meta: (state: any): any => {
        return state.meta
    },
    links: (state: any): any => {
        return state.links
    },
    loading: (state: any): boolean => {
        return state.loading
    },
    error: (state: any): any => {
        return state.error
    },
    current_page: (state: any): number => {
        return state.meta ? state.meta.current_page : 1
    },
    last_page: (state: any): number => {
        return state.meta ? state.meta.last_page : 1
    },
    total: (state: any): number => {
        return state.meta ? state.meta.total : 0
    }
}
