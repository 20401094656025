import { getError } from '@/utils/helpers'
import ListeService from '@/services/ListeService'
import { ListeInterface, StateListes } from '@/types/Liste'

export const namespaced = true

function setListes(commit: Function, response: any) {
    commit('SET_LISTES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateListes = {
    listes: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    listeSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateListes) {
        Object.assign(state, {
            listes: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            listeSelect: {}
        })
    },
    SET_LISTES(state: any, listes: Array<ListeInterface>) {
        state.listes = listes
    },
    SET_MORE_LISTES(state: any, listes: Array<ListeInterface>) {
        state.listes = state.listes.concat(listes)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LISTE_SELECT(state: any, liste: ListeInterface) {
        state.listeSelect = liste
    }
}

export const actions = {
    getListes({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ListeService.getListes(params)
                .then((response) => {
                    setListes(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getListe({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ListeService.getListe(params.liste_id)
                .then((response) => {
                    commit('SET_LISTE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreListes({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ListeService.getListe(params.liste_id)
                .then((response) => {
                    commit('SET_LISTE_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateListe({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ListeService.updateListe(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addListe({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ListeService.addListe(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteListe({ commit }: { commit: any }, liste_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ListeService.deleteListe(liste_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    import({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ListeService.import(payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    listes: (state: any) => {
        return state.listes
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.listes.length
    },
    listeSelect: (state: any) => {
        return state.listeSelect
    },
    getListeById: (state: any) => (id: number) => {
        const liste = state.listes.filter((mat: any) => mat.id === id)[0]
        return liste
    }
}
