/* eslint-disable @typescript-eslint/no-unused-vars */
import {getError} from '@/utils/helpers'
import DefinitionDesBarresAnalysesHypotheses from '@/services/DefinitionDesBarresAnalysesHypotheses'

export const namespaced = true

function setTableauHypothese(commit: Function, response: any) {
    commit('SET_TABLEAU_HYPOTHESE', response.data.data)
}

function setHypotheseEpinglees(commit: Function, response: any) {
    commit('SET_HYPOTHESES_EPINGLEES', response.data.data)
}

function resetTableauHypothese(commit: Function) {
    commit('RESET_STATE')
}

export const state: any = {
    tableauHypothese: [],
    hypothesesEpinglees: [],
    loading: false,
    error: null
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            tableauHypothese: [],
            hypothesesEpinglees: [],
            loading: false,
            error: null
        })
    },
    SET_TABLEAU_HYPOTHESE(state: any, tableauHypothese: any) {
        state.tableauHypothese = tableauHypothese
    },
    SET_HYPOTHESES_EPINGLEES(state: any, hypothesesEpinglees: any) {
        state.hypothesesEpinglees = hypothesesEpinglees
    },
    SET_MORE_TABLEAU_LISTE_NOTE(state: any, tableauHypothese: any) {
        state.tableauHypothese = state.tableauHypothese.concat(tableauHypothese)
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    RESET_ERROR(state: any) {
        state.error = null
    },
    ADD_PINNED_TO_HYPOTHESES_EPINGLEES(state: any, newPinned: any) {
        // Verification que la valeur n est pas deja dans le tableau
        if (
            state.hypothesesEpinglees.findIndex(
                (pinned: any) =>
                    pinned.datas.barre1 === newPinned.datas.barre1 && pinned.datas.barre2 === newPinned.datas.barre2
            ) === -1
        ) {
            state.hypothesesEpinglees.push(newPinned)
        }
    },
    // Suppression d une hypothese epinglee
    DELETE_PINNED_TO_HYPOTHESES_EPINGLEES(state: any, deletePinned: any) {
        if (state.hypothesesEpinglees.length === 1) {
            state.hypothesesEpinglees = []
        } else {
            state.hypothesesEpinglees = state.hypothesesEpinglees.filter(
                (pinned: { id: any }) => pinned.id !== deletePinned.id
            )
        }
    },
    // suppression du tableau de recherche d une hypothese qudn celle ci s ajoute aux epinglees
    DELETE_PINNED_TO_TABLEAU_HYPOTHESE_EPINGLEES(state: any, deletePinned: any) {
        state.tableauHypothese = state.tableauHypothese.filter((pinned: any) => {
            return (
                pinned.datas.barre1 !== deletePinned.datas.barre1 &&
                pinned.datas.rejet_global !== deletePinned.datas.rejet_global &&
                pinned.datas.barre2 !== deletePinned.datas.barre2
            )
        })
    }
}

export const actions = {
    getTableauHypothese({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            if (payload) { 
                DefinitionDesBarresAnalysesHypotheses.postDefinitionDesBarresAnalysesHypotheses(payload)
                    .then((response) => {
                        setTableauHypothese(commit, response)
                        commit('SET_LOADING', false)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_LOADING', false)
                        commit('SET_ERROR', getError(error))
                        reject(error)
                    })
            }
        })
    },
    getHypothesesEpinglees({ commit }: { commit: any }, payload?: any) {
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            DefinitionDesBarresAnalysesHypotheses.getHypothesesEpinglees(payload)
                .then((response) => {
                    setHypotheseEpinglees(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    postHypothesesEpinglees({ commit }: { commit: any }, payload?: any) {
        commit('RESET_ERROR')
        if (
            state.hypothesesEpinglees.findIndex(
                (pinned: any) =>
                    pinned.datas.barre1 === payload.params.datas.barre1 &&
                    pinned.datas.barre2 === payload.params.datas.barre2
            ) === -1
        ) {
            return new Promise((resolve, reject) => {
                DefinitionDesBarresAnalysesHypotheses.postHypothesesEpingle(payload)
                    .then((response) => {
                        commit('ADD_PINNED_TO_HYPOTHESES_EPINGLEES', response.data.data)
                        // commit('DELETE_PINNED_TO_TABLEAU_HYPOTHESE_EPINGLEES', response.data.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_ERROR', getError(error))
                        reject(error)
                    })
            })
        }
    },
    deleteHypothesesEpinglees({ commit }: { commit: any }, payload?: any) {
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            DefinitionDesBarresAnalysesHypotheses.deleteHypothesesEpingle(payload)
                .then((response) => {
                    commit('DELETE_PINNED_TO_HYPOTHESES_EPINGLEES', payload.params)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    RESET_TABLEAU_HYPOTHESE({ commit }: { commit: any }) {
        commit('RESET_ERROR')
        resetTableauHypothese(commit)
    },

    exportTableauHypothese({ commit }: { commit: any }, payload?: any) {
        return new Promise((resolve) => {
            DefinitionDesBarresAnalysesHypotheses.getExportDesBarresStatistiquesConcours(payload).then((response) => {
                resolve(response)
            })
        })
    }
}

export const getters = {
    tableauHypotheseTrie: (state: any) => {
        return state.tableauHypothese
    },
    loading: (state: any) => {
        return state.loading
    },
    getError: (state: any) => {
        return state.error
    },
    hypothesesEpinglees: (state: any) => {
        return state.hypothesesEpinglees
    }
}
