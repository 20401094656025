import { apiClient } from '@/services/API'
import store from '@/store/index'
import { AxiosRequestConfig } from 'axios'

export default {
    async getDeontologies(payload: Record<string, string | number> | undefined) {
        const session_id = store.getters['auth/user_session_id']
        return await apiClient.get(`/sessions/${session_id}/probleme_deontologies`, { params: payload })
    },
    async exportDeontologies() {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return await apiClient.get(`/sessions/${session_id}/probleme_deontologies_export`, params)
    }
}
