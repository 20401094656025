import DisponibiliteService from '@/services/DisponibiliteService'
import { EtatCreneau, EtatDispo, getMessageControle, StateDisponibilite } from '@/types/Disponibilites'
import { TypePassation } from '@/types/Epreuve'
import {
    addDaysToDate,
    convertMoment,
    dateIsBetween,
    dateNow,
    diffTwoDatesInDays,
    diffTwoDatesInMinutes,
    formatDateYYYYMMDD,
    formatDayHourZDateFromMoment,
    formatStringServeurToDate,
    getError,
    hoursMinutesToLocal,
    subtractDaysToDate
} from '@/utils/helpers'
import _ from 'lodash'
import moment from 'moment'
import Vue from 'vue'

export const namespaced = true

export const state: StateDisponibilite = {
    date_start: '',
    date_end: '',
    error_dispos: null,
    loading: false,
    loading_interface: false,
    examinateur_select: 0,
    pause_count: 0,
    creneaux_examinateur: [],
    creneaux_TP: [],
    creneaux_prio: 0,
    creneaux_non_prio: 0,
    creneaux_actifs: 0,
    candidats_reels: [],
    candidats_reels_total: 0,
    lock_creneau: [],
    messages_regles_journees: [],
    compteurs_series_journees: [],
    compteurs_serie_journee_TP: [],
    count_ligne_heure: 0
}

function createPlage(
    compteur: number,
    h_debut: any,
    h_fin: any,
    duree_plage: number,
    pause_debut: any,
    pause_fin: any,
    jour_serie_id: number,
    fromTp: boolean
) {
    let h_temp: any = null
    const results: Array<any> = []
    let pause_count = -1
    for (let index = 0; index <  Math.ceil(compteur); index++) {
        if (h_temp === null) {
            h_temp = h_debut.clone()
        } else {
            h_temp.add(duree_plage, 'minutes')
        }
        let plage_index: any = null
        if (fromTp) {

            plage_index = h_temp.format('HH:mm')
        } else {

            plage_index = h_temp.format('HH')
        }

        results.push({
            i: index,
            plage_moment_debut: h_temp.clone(),
            plage_moment_fin: h_fin.clone(),
            plage_utc: h_temp.format('HH:mm'),
            pause_debut: pause_debut,
            pause_fin: pause_fin,
            plage_index: plage_index,
            id: jour_serie_id,
            rdvs: []
        })
        pause_count++
    }

    return { results: results, pause_count: pause_count }
}

export const mutations: any = {
    RESET_STATE(state: StateDisponibilite) {
        Object.assign(state, {
            date_start: '',
            date_end: '',
            error_dispos: null,
            loading: false,
            loading_interface: false,
            examinateur_select: 0,
            pause_count: 0,
            creneaux_examinateur: [],
            creneaux_TP: [],
            creneaux_prio: 0,
            creneaux_non_prio: 0,
            creneaux_actifs: 0,
            candidats_reels: [],
            candidats_reels_total: 0,
            lock_creneau: [],
            messages_regles_journees: [],
            compteurs_series_journees: [],
            compteurs_serie_journee_TP: [],
            count_ligne_heure: 0
        })
    },
    SET_DATE_START(state: StateDisponibilite, date_start: string) {
        state.date_start = date_start
    },
    SET_END_START(state: StateDisponibilite, date_end: string) {
        state.date_end = date_end
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error_dispos = error
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_LOADING_INTERFACE(state: any, loading: boolean) {
        state.loading_interface = loading
    },
    SET_CRENEAUX(state: any, creneaux: Array<any>) {
        state.creneaux_examinateur = creneaux
    },
    SET_ADD_CRENEAU(state: any, creneaux: Array<any>) {
        creneaux.forEach((creneau: any) => {
            state.creneaux_examinateur.push(creneau)
            // Contexte TP
            state.creneaux_TP.push(creneau)
        })
    },
    SET_UPDATE_CRENEAU(state: any, creneau: any) {
        const index_c = state.creneaux_examinateur.findIndex((c: any) => c.id === creneau.id)
        if (index_c !== -1) {
            Vue.set(state.creneaux_examinateur, index_c, creneau)
        }

        // Contexte TP
        const index_tp = state.creneaux_TP.findIndex((c: any) => c.id === creneau.id)
        if (index_tp !== -1) {
            Vue.set(state.creneaux_TP, index_tp, creneau)
        }
    },
    SET_DELETE_CRENEAU(state: any, creneau_id: number) {
        const index_c = state.creneaux_examinateur.findIndex((c: any) => c.id === creneau_id)
        state.creneaux_examinateur.splice(index_c, 1)

        // Contexte TP
        const index_tp = state.creneaux_TP.findIndex((c: any) => c.id === creneau_id)
        state.creneaux_TP.splice(index_tp, 1)
    },
    RESET_LOCK_CRENEAU(state: any) {
        state.lock_creneau = []
    },
    SET_CRENEAUX_TP(state: any, creneaux: Array<any>) {
        state.creneaux_TP = creneaux
    },
    SET_CANDIDATS_REELS(state: any, compteurs: Array<any>) {
        state.candidats_reels = []
        state.candidats_reels_total = 0
        for (const c in compteurs) {
            state.candidats_reels[c] = compteurs[c]
            state.candidats_reels_total += compteurs[c]
        }
    }
}

export const actions: any = {
    getCreneaux({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DisponibiliteService.getCreneaux(params.user_id)
                .then((response) => {
                    commit('SET_CRENEAUX', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    saveMinimas({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DisponibiliteService.updateMinimas(params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    saveCreneau({ commit }: { commit: any }, params: any) {
        // console.log('-----saveCreneau  debut---'  + new Date().toISOString())
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DisponibiliteService.addCreneau(params.payload)
                .then((response) => {
                    // console.log('-----saveCreneau before set---'  + new Date().toISOString())
                    commit('SET_ADD_CRENEAU', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                    // console.log('-----saveCreneau fin---'  + new Date().toISOString())
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateCreneau({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DisponibiliteService.updateCreneau(params.creneau_id, params.payload)
                .then((response) => {
                    commit('SET_UPDATE_CRENEAU', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteCreneau({ commit }: { commit: any }, params: any) {
        // console.log('-----deleteCreneau debut---'  + new Date().toISOString())
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DisponibiliteService.deleteCreneau(params.creneau_id)
                .then((response) => {
                    // console.log('-----deleteCreneau before set---'  + new Date().toISOString())
                    commit('SET_DELETE_CRENEAU', params.creneau_id)
                    commit('SET_LOADING', false)
                    resolve(response)
                    // console.log('-----deleteCreneau fin---'  + new Date().toISOString())
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    cloneSerie({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DisponibiliteService.cloneSerie(params.user_id, params.serie_id, params.ensemble_id)
                .then((response) => {
                    commit('SET_CRENEAUX', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getCreneauxEnsemble({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DisponibiliteService.getCreneauxEnsemble(params.ensemble_id)
                .then((response) => {
                    commit('SET_CRENEAUX_TP', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    clearSerie({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DisponibiliteService.clearSerie(params.user_id, params.serie_id, params.ensemble_id)
                .then((response) => {
                    commit('SET_CRENEAUX', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getCandidatsReels({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DisponibiliteService.getCandidatsReels(params.examinateur_id, params.ensemble_id)
                .then((response) => {
                    commit('SET_CANDIDATS_REELS', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    export_equipes({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DisponibiliteService.exporter_equipe(params.ensemble_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    export_candidats_reels({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DisponibiliteService.export_candidats_reels()
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    export_seances_contract({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DisponibiliteService.export_seances_contract()
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters: any = {
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.centres.length
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    loading: (state: any) => {
        return state.loading
    },
    dispos_is_open: () => (session_active: any) => {
        const todayDate = dateNow()

        if (
            session_active &&
            session_active.disponibilites_start_at === null &&
            session_active.disponibilites_end_at === null
        ) {
            return false
        }
        if (
            session_active &&
            session_active.disponibilites_start_at &&
            session_active.disponibilites_end_at &&
            todayDate
        ) {
            const todayDateFormatted = formatDateYYYYMMDD(todayDate)
            const sessionEndDateFormatted = formatDateYYYYMMDD(session_active.disponibilites_end_at)
            return session_active.disponibilites_start_at <= todayDate && todayDateFormatted <= sessionEndDateFormatted
        } else if (
            session_active &&
            session_active.disponibilites_start_at === null &&
            session_active.disponibilites_end_at &&
            todayDate
        ) {
            const todayDateFormatted = formatDateYYYYMMDD(todayDate)
            const sessionEndDateFormatted = formatDateYYYYMMDD(session_active.disponibilites_end_at)
            return todayDateFormatted <= sessionEndDateFormatted
        } else {
            return false
        }
    },
    get_priorite_next: () => (priorite_value: number) => {
        let priorite_next = 0
        if (priorite_value) {
            priorite_next = (10 * priorite_value) / 100
        }
        return { libelle: '+' + Math.ceil(priorite_next), value: Math.ceil(priorite_next) }
    },
    get_priorite_total: () => (priorite_value: number, nb_series: number) => {
        let priorite_total = 0
        if (priorite_value) {
            priorite_total = nb_series * priorite_value
        }
        return priorite_total
    },
    create_jours_serie: () => (serie: any, jours_series_epreuve?: any, h_perso?: any) => {
        const journees: { [index: string]: any } = {}
        // Créer toutes les journées de la série
        const daysDiff = diffTwoDatesInDays(serie.debut, serie.fin)
        let currentDay = serie.debut
        for (let j = 0; j < daysDiff + 1; j++) {
            journees[formatDateYYYYMMDD(currentDay)] = []
            currentDay = addDaysToDate(currentDay, 1)
        }

        if (jours_series_epreuve) {
            for (const js in jours_series_epreuve) {
                if (journees[jours_series_epreuve[js].jour]) {
                    journees[jours_series_epreuve[js].jour] = jours_series_epreuve[js]
                    if (h_perso && h_perso.h_start && h_perso.h_start !== '' && h_perso.h_end && h_perso.h_end !== '' && h_perso.h_start_break && h_perso.h_start_break !== '' && h_perso.h_end_break && h_perso.h_end_break !== '') {
                        if (h_perso.h_start) {
                            journees[jours_series_epreuve[js].jour].h_debut =  formatStringServeurToDate(journees[jours_series_epreuve[js].jour].h_debut).format('YYYY-MM-DD') + ' ' + formatStringServeurToDate(h_perso.h_start).format('HH:mm:') + '00'
                        }

                        if(h_perso.h_end){
                            journees[jours_series_epreuve[js].jour].h_fin =  formatStringServeurToDate(journees[jours_series_epreuve[js].jour].h_debut).format('YYYY-MM-DD') + ' ' + formatStringServeurToDate(h_perso.h_end).format('HH:mm:') + '00'
                        }

                        if(h_perso.h_start_break && h_perso.h_start_break !== ''){
                            journees[jours_series_epreuve[js].jour].h_pause_debut =  formatStringServeurToDate(journees[jours_series_epreuve[js].jour].h_debut).format('YYYY-MM-DD') + ' ' + formatStringServeurToDate(h_perso.h_start_break).format('HH:mm:') + '00'
                        } else {
                            journees[jours_series_epreuve[js].jour].h_pause_debut = null
                        }

                        if(h_perso.h_end_break && h_perso.h_end_break !== ''){
                            journees[jours_series_epreuve[js].jour].h_pause_fin =  formatStringServeurToDate(journees[jours_series_epreuve[js].jour].h_debut).format('YYYY-MM-DD') + ' ' + formatStringServeurToDate(h_perso.h_end_break).format('HH:mm:') + '00'
                        } else {
                            journees[jours_series_epreuve[js].jour].h_pause_fin = null
                        }
                    }
                }
            }
        }

        return journees
    },
    create_jours_serie_with_chevauchement: () => (serie: any, nb_jours_avant: number, nb_jours_apres: number) => {
        const journees: { [index: string]: any } = {}
        // Créer toutes les journées de la série en prenant en compte les jours de chevauchement détecté dans les épreuves
        const debut = nb_jours_avant !== 0 ? subtractDaysToDate(serie.debut, nb_jours_avant) : serie.debut
        const fin = nb_jours_apres !== 0 ? addDaysToDate(serie.fin, nb_jours_apres) : serie.fin
        const daysDiff = diffTwoDatesInDays(debut.toString(), fin.toString())
        let currentDay = debut
        for (let j = 0; j <= daysDiff + 1; j++) {
            journees[formatDateYYYYMMDD(currentDay)] = []
            currentDay = addDaysToDate(currentDay, 1)
        }

        return journees
    },
    genere_plages:
        (state: any) => (jours_series: Array<any>, epreuve_correction: any, is_responsable: any, sessionUser: any, fromTp = false) => {
            const plages: { [index: string]: any } = {}
            let duree_composition: number = epreuve_correction.duree_composition
            const duree_preparation: number = epreuve_correction.duree_preparation
            const duree_plage =
                epreuve_correction.type_passation !== TypePassation.TYPE_PASSATION_TP ? 60 : duree_composition
            const duree_totale: number = duree_composition + duree_preparation
            let no_resp_but_creneau = false
            let heure_deja_add = false

            // Adaptation de l'affichage si le creneau de l'examinateur à une durée différente de ce qui est prévue dans l'épreuve
            if (state.creneaux_examinateur && state.creneaux_examinateur.length !== 0) {
                const total_time_creneau = diffTwoDatesInMinutes(
                    state.creneaux_examinateur[0].h_debut,
                    state.creneaux_examinateur[0].h_fin
                )
                duree_composition = total_time_creneau - duree_preparation
            }

            // Check si le candidat a des creneaux hors journée
            for (const js in jours_series) {
                if (state.creneaux_examinateur && state.creneaux_examinateur.length !== 0) {
                    const h_fin: any = formatStringServeurToDate(jours_series[js]?.h_fin || jours_series[js].h_end_max)
                    // Check si l'utilisateur à des creneaux hors journée
                    const creneaux_hors_journee = state.creneaux_examinateur.filter(
                        (cr: any) =>
                            (cr.jour === jours_series[js].jour &&
                                formatStringServeurToDate(cr.h_debut).isSameOrAfter(h_fin)) ||
                            (cr.jour === jours_series[js].jour &&
                                formatStringServeurToDate(cr.h_fin).isSameOrAfter(h_fin))
                    )
                    // Si l'examinateur à des créneaux hors journées on lui montre la ligne
                    if (creneaux_hors_journee.length !== 0) {
                        no_resp_but_creneau = true
                    }
                }
            }

            // Init de la structure en partant de la structure d'une série pour intégrer des potentielles journées cachées
            for (const js in jours_series) {
                // Check si on a une journée inactive. Si c'est le cas on clone une journee active
                if (jours_series[js] && jours_series[js].id === undefined) {
                    jours_series[js] = {
                        jour: js,
                        id: 0,
                        serie_id: epreuve_correction.series[0].serie_id,
                        h_debut: epreuve_correction.h_debut ? moment.utc(`${js} ${moment.utc(epreuve_correction.h_debut).format('HH:mm:ss')}`).toISOString() : null,
                        h_start_min: epreuve_correction.h_start_min ? moment.utc(`${js} ${moment.utc(epreuve_correction.h_start_min).format('HH:mm:ss')}`).toISOString() : null,
                        h_fin: epreuve_correction.h_fin ? moment.utc(`${js} ${moment.utc(epreuve_correction.h_fin).format('HH:mm:ss')}`).toISOString() : null,
                        h_end_max: epreuve_correction.h_end_max ? moment.utc(`${js} ${moment.utc(epreuve_correction.h_end_max).format('HH:mm:ss')}`).toISOString() : null,
                        h_pause_debut: epreuve_correction.h_pause_debut ? moment.utc(`${js} ${moment.utc(epreuve_correction.h_pause_debut).format('HH:mm:ss')}`).toISOString() : null,
                        h_pause_fin: epreuve_correction.h_pause_fin ? moment.utc(`${js} ${moment.utc(epreuve_correction.h_pause_fin).format('HH:mm:ss')}`).toISOString() : null
                    }

                    const hDebut = moment.utc(epreuve_correction.h_debut)
                    const hDebutTarget = moment.utc(jours_series[js].h_debut)
                    if (hDebut.local().format('HH') !== hDebutTarget.local().format('HH')) {
                        const diff = Number(hDebut.local().format('HH')) - Number(hDebutTarget.local().format('HH'))
                        jours_series[js].h_debut = moment.utc(jours_series[js].h_debut).add(diff, 'hours').toISOString()
                        jours_series[js].h_start_min = moment.utc(jours_series[js].h_start_min).add(diff, 'hours').toISOString()
                        jours_series[js].h_fin = moment.utc(jours_series[js].h_fin).add(diff, 'hours').toISOString()
                        jours_series[js].h_end_max = moment.utc(jours_series[js].h_end_max).add(diff, 'hours').toISOString()
                        jours_series[js].h_pause_debut = moment.utc(jours_series[js].h_pause_debut).add(diff, 'hours').toISOString()
                        jours_series[js].h_pause_fin = moment.utc(jours_series[js].h_pause_fin).add(diff, 'hours').toISOString()
                    }
                }

                plages[js] = []

                // Un tableau pour chaque heure de début dans la journée
                const h_debut: any = formatStringServeurToDate(jours_series[js].h_debut || jours_series[js].h_start_min).local()
                const h_pause_debut: any = jours_series[js].h_pause_debut ? formatStringServeurToDate(jours_series[js].h_pause_debut).local() : null
                const h_pause_fin: any = jours_series[js].h_pause_fin ? formatStringServeurToDate(jours_series[js].h_pause_fin).local() : null
                let h_fin: any = formatStringServeurToDate(jours_series[js].h_fin || jours_series[js].h_end_max).local()

                // Si l'utilisateur connecté est un responsable, on ajoute une heure de plus à la fin de la journée
                // Les disponibilités de l'utilisateur doivent être soumises
                // Si l'utilisateur n'est pas responsable mais qu'il a des créneaux hors journée on doit afficher la ligne
                if (
                    ((is_responsable && sessionUser.disponibilites_submitted_at) || no_resp_but_creneau) &&
                    epreuve_correction.type_passation !== TypePassation.TYPE_PASSATION_TP
                ) {
                    h_fin = h_fin.add(duree_plage, 'minutes')
                    heure_deja_add = true
                }

                // Générations des plages de la journée
                // Prise en comptes des pauses
                const jours = []

                if (h_pause_debut !== null && h_pause_debut !== '') {
                    const count_plages_avant_pause = diffTwoDatesInMinutes(h_debut, h_pause_debut) / duree_plage
                    const count_plages_apres_pause = diffTwoDatesInMinutes(h_pause_fin, h_fin) / duree_plage

                    // Création des plages suite à la pause
                    jours.push(
                        createPlage(
                            count_plages_avant_pause,
                            h_debut,
                            h_fin,
                            duree_plage,
                            h_pause_debut,
                            h_pause_fin,
                            jours_series[js].id,
                            fromTp
                        ).results
                    )
                    jours.push(
                        createPlage(
                            count_plages_apres_pause,
                            h_pause_fin,
                            h_fin,
                            duree_plage,
                            h_pause_debut,
                            h_pause_fin,
                            jours_series[js].id,
                            fromTp
                        ).results
                    )
                    state.pause_count = createPlage(
                        count_plages_apres_pause,
                        h_pause_fin,
                        h_fin,
                        duree_plage,
                        h_pause_debut,
                        h_pause_fin,
                        jours_series[js].id,
                        fromTp
                    ).pause_count
                } else {
                    const count_plages = diffTwoDatesInMinutes(h_debut, h_fin) / duree_plage
                    jours.push(
                        createPlage(count_plages, h_debut, h_fin, duree_plage, null, null, jours_series[js].id,fromTp).results
                    )
                    state.pause_count = null
                }

                for (const j in jours) {
                    for (const p in jours[j]) {
                        plages[jours_series[js].jour].push(jours[j][p])
                    }
                }
            }

            // Création des rdvs
            let count_p = 0
            for (const j in plages) {
                if (plages[j][0]) {
                    // Journée ouverte
                    let fin_journee = false
                    const h_rdv_debut: any = plages[j][0].plage_moment_debut ? plages[j][0].plage_moment_debut.clone() : null
                    let h_fin_journee: any = plages[j][0].plage_moment_fin ? plages[j][0].plage_moment_fin.clone() : null
                    const pause_debut: any = plages[j][0].pause_debut ? plages[j][0].pause_debut.clone() : null
                    const pause_fin: any = plages[j][0].pause_fin ? plages[j][0].pause_fin.clone() : null

                    // Si l'utilisateur connecté est un responsable, on ajoute une heure de plus à la fin de la journée
                    // Les disponibilités de l'utilisateur doivent être soumises
                    // Si l'utilisateur n'est pas responsable mais qu'il a des créneaux hors journée on doit afficher la ligne
                    if (
                        ((is_responsable && sessionUser.disponibilites_submitted_at && !heure_deja_add) ||
                            (no_resp_but_creneau && !heure_deja_add)) &&
                        epreuve_correction.type_passation !== TypePassation.TYPE_PASSATION_TP
                    ) {
                        h_fin_journee = h_fin_journee.add(duree_composition, 'minutes')
                    }

                    let debut = h_rdv_debut
                    while (!fin_journee) {
                        let rdv: any = {}
                        rdv = {
                            index: count_p,
                            h_debut: debut.clone(),
                            h_fin: debut.clone().add(duree_totale, 'minutes'),
                            actif: null,
                            etat: EtatDispo.ETAT_PRIORITAIRE,
                            id: 0,
                            plage_utc: debut.format('HH:mm')
                        }

                        let plage_select: any = null
                        if (fromTp) {
                            plage_select = plages[j].find((p: any) => p.plage_index === debut.format('HH:mm'))
                        } else {
                            plage_select = plages[j].find((p: any) => p.plage_index === debut.format('HH'))
                        }

                        if (plage_select) {
                            plage_select.rdvs.push(rdv)

                            // Ajout duree composition
                            debut.add(duree_composition, 'minutes')

                            // prendre en compte la pause si définie
                            if (pause_debut !== null && pause_fin !== null &&
                                (debut.isBetween(pause_debut, pause_fin) ||
                                debut.clone().add(duree_totale, 'minutes').isBetween(pause_debut, pause_fin) ||
                                debut.isSameOrBefore(pause_debut) && debut.clone().add(duree_totale, 'minutes').isSameOrAfter(pause_fin))){
                                // console.log('creneau dans la pause')
                                debut = pause_fin
                            }

                            // Regarde si le creneau depasse la fin de journee
                            if (debut.clone().add(duree_totale, 'minutes').isAfter(h_fin_journee)) {
                                fin_journee = true
                            }

                            count_p++
                        } else {
                            fin_journee = true
                        }
                    }
                }
            }
            return plages
        },
    genere_ligne_rdvs: (state: any) => (plages: Array<any>) => {
        state.count_ligne_heure = 0
        const rdvs: { [index: string]: any } = {}
        for (const j in plages) {
            for (const p in plages[j]) {
                if (!rdvs[plages[j][p].plage_utc]) {
                    rdvs[plages[j][p].plage_utc] = []
                    state.count_ligne_heure++
                }
                rdvs[plages[j][p].plage_utc].push({
                    heure_plage_moment: plages[j][p].plage_moment_debut,
                    index: plages[j][p].plage_index,
                    jours: { j: j, rdvs: plages[j][p].rdvs, jour_actif: plages[j][p].id !== 0 ? 1 : 0 }
                })
            }
        }
        return rdvs
    },
    get_compteurs_serie_journee: (state: any) => (series: Array<any>) => {
        const compteurs_serie_journee: Array<any> = []
        const dates_series: Array<any> = []
        state.creneaux_prio = 0
        state.creneaux_actifs = 0
        state.creneaux_non_prio = 0

        for (const s in series) {
            if (!compteurs_serie_journee[series[s].id]) {
                compteurs_serie_journee[series[s].id] = {
                    code_serie: series[s].name,
                    prio: 0,
                    non_prio: 0,
                    actifs: 0,
                    journees: []
                }
            }

            dates_series.push({ serie_id: series[s].id, date_debut: series[s].debut, date_fin: series[s].fin })
        }

        for (const r in state.creneaux_examinateur) {
            const rdv_select = state.creneaux_examinateur[r]
            // Check la date du creneaux pour savoir dans quelle série il est compté
            for (const d in dates_series) {
                const startDate: any = dates_series[d].date_debut
                const endDate: any = dates_series[d].date_fin
                const date: any = rdv_select.jour
                const isBetween = dateIsBetween(date, startDate, endDate, '[]')

                if (isBetween && rdv_select.id) {
                    if (rdv_select.etat === EtatDispo.ETAT_PRIORITAIRE) {
                        compteurs_serie_journee[dates_series[d].serie_id].prio++
                        state.creneaux_prio++
                    }
                    if (rdv_select.etat === EtatDispo.ETAT_NON_PRIORITAIRE) {
                        compteurs_serie_journee[dates_series[d].serie_id].non_prio++
                        state.creneaux_non_prio++
                    }

                    if (rdv_select.actif === EtatCreneau.CRENEAU_ACTIF) {
                        compteurs_serie_journee[dates_series[d].serie_id].actifs++
                        state.creneaux_actifs++
                    }

                    if (!compteurs_serie_journee[dates_series[d].serie_id].journees[rdv_select.jour]) {
                        compteurs_serie_journee[dates_series[d].serie_id].journees[rdv_select.jour] = []
                    }

                    compteurs_serie_journee[dates_series[d].serie_id].journees[rdv_select.jour].push({
                        jour: rdv_select.jour,
                        etat: rdv_select.etat,
                        creneau: rdv_select,
                        creneau_h_debut_moment: convertMoment(rdv_select.h_debut)
                    })
                }
            }
        }

        state.compteurs_series_journees = compteurs_serie_journee
    },
    get_compteurs_serie_journee_by_ensemble: (state: any) => (series: Array<any>, ensemble_id: any) => {
        const compteurs_serie_journee: Array<any> = []
        const dates_series: Array<any> = []
        state.creneaux_prio = 0
        state.creneaux_actifs = 0
        state.creneaux_non_prio = 0

        for (const s in series) {
            if (!compteurs_serie_journee[series[s].id]) {
                compteurs_serie_journee[series[s].id] = {
                    code_serie: series[s].name,
                    prio: 0,
                    non_prio: 0,
                    actifs: 0,
                    journees: []
                }
            }

            dates_series.push({ serie_id: series[s].id, date_debut: series[s].debut, date_fin: series[s].fin })
        }

        for (const r in state.creneaux_examinateur) {
            if (parseInt(state.creneaux_examinateur[r].ensemble_id) === parseInt(ensemble_id)) {
                const rdv_select = state.creneaux_examinateur[r]
                // Check la date du creneaux pour savoir dans quelle série il est compté
                for (const d in dates_series) {
                    const startDate: any = dates_series[d].date_debut
                    const endDate: any = dates_series[d].date_fin
                    const date: any = rdv_select.jour
                    const isBetween = dateIsBetween(date, startDate, endDate, '[]')

                    if (isBetween && rdv_select.id) {
                        if (rdv_select.etat === EtatDispo.ETAT_PRIORITAIRE) {
                            compteurs_serie_journee[dates_series[d].serie_id].prio++
                            state.creneaux_prio++
                        }
                        if (rdv_select.etat === EtatDispo.ETAT_NON_PRIORITAIRE) {
                            compteurs_serie_journee[dates_series[d].serie_id].non_prio++
                            state.creneaux_non_prio++
                        }

                        if (rdv_select.actif === EtatCreneau.CRENEAU_ACTIF) {
                            compteurs_serie_journee[dates_series[d].serie_id].actifs++
                            state.creneaux_actifs++
                        }

                        if (!compteurs_serie_journee[dates_series[d].serie_id].journees[rdv_select.jour]) {
                            compteurs_serie_journee[dates_series[d].serie_id].journees[rdv_select.jour] = []
                        }

                        compteurs_serie_journee[dates_series[d].serie_id].journees[rdv_select.jour].push({
                            jour: rdv_select.jour,
                            etat: rdv_select.etat,
                            creneau: rdv_select,
                            creneau_h_debut_moment: convertMoment(rdv_select.h_debut)
                        })
                    }
                }
            }
        }

        state.compteurs_series_journees = compteurs_serie_journee
        return compteurs_serie_journee
    },
    check_regles_creneaux: (state: any) => (slot_heures: Array<any>, epreuve_correction: any) => {
        state.lock_creneau = []
        const journees: any = []
        const duree_composition: number = epreuve_correction.duree_composition
        const max_cand_jour: number = epreuve_correction.nb_cand_jour
        const max_cand_demi_jour: number = epreuve_correction.nb_cand_demi_jour
        const max_consecutif_autorise = 6 * 60
        const max_consecutif_avant_pause = 5 * 60
        const duree_pause_min = 45
        const heure_demi_journee = 13

        for (const r in slot_heures) {
            _.orderBy(slot_heures[r], 'h_debut', 'asc')
            for (const h in slot_heures[r]) {
                if (!journees[r]) {
                    journees[r] = {
                        jour_m: formatStringServeurToDate(r),
                        max_consecutif: 0,
                        tps_journee: 0,
                        nb_blocs: 0,
                        nb_creneaux_matin: 0,
                        nb_creneaux_aprem: 0,
                        duree_bloc: [],
                        plages: []
                    }
                }

                for (const c in slot_heures[r][h]) {
                    // TODO ne pas crére de plage durant la pause, ou rdv a déjà supprimé ?
                    const plage: any = {
                        dbg: hoursMinutesToLocal(
                            slot_heures[r][h][c].h_debut.clone().format('YYYY-MM-DDTHH:mm:ss.000000[Z]')
                        ),
                        h_debut: formatDayHourZDateFromMoment(slot_heures[r][h][c].h_debut.clone()),
                        h_fin: formatDayHourZDateFromMoment(slot_heures[r][h][c].h_fin.clone()),
                        h_debut_m: slot_heures[r][h][c].h_debut.clone(),
                        h_fin_m: slot_heures[r][h][c].h_fin.clone(),
                        creneau: null,
                        disabled: false,
                        // checked: false,
                        previous: null,
                        next: null,
                        bloc_numero: 0
                    }

                    journees[r].plages.push(plage)

                    journees[r].tps_journee = journees[r].tps_journee + duree_composition
                }
            }
        }

        // Inclus les creneaux examinateurs
        for (const j in journees) {
            if (!state.lock_creneau[j]) {
                state.lock_creneau[j] = []
            }

            if (!state.messages_regles_journees[j]) {
                state.messages_regles_journees[j] = {
                    message_creneaux: []
                }
            }

            const heure_demi_journee_moment = journees[j].jour_m
                .clone()
                .tz(process.env.VUE_APP_TZ)
                .hours(heure_demi_journee)
                .minutes(0)
                .tz('UTC')
            journees[j].heure_demi_journee_moment = heure_demi_journee_moment
            let last_plage: any = null
            let plage: any = null
            // premier parcours pour lier les plages
            last_plage = null
            for (const c in journees[j].plages) {
                journees[j].plages[c].creneau = state.creneaux_examinateur.find(
                    (ce: any) => ce.jour === j && ce.h_debut === journees[j].plages[c].h_debut
                )

                if (last_plage) {
                    last_plage.next = journees[j].plages[c]
                    journees[j].plages[c].previous = last_plage
                }

                if (journees[j].plages[c].creneau) {
                    if (journees[j].plages[c].h_debut_m.isBefore(heure_demi_journee_moment)) {
                        journees[j].nb_creneaux_matin++
                    } else {
                        journees[j].nb_creneaux_aprem++
                    }
                }

                last_plage = journees[j].plages[c]
            }

            // second parcours pour former les blocs
            last_plage = null
            const blocs: any = []
            let blocOuvert = false
            let numero = 0
            for (const c in journees[j].plages) {
                plage = journees[j].plages[c]
                if (!blocOuvert && plage.creneau) {
                    blocOuvert = true
                    numero++
                }

                if (blocOuvert && !plage.creneau) {
                    let duree_pause = 0
                    let current = plage
                    let parcourir = true
                    while (parcourir) {
                        if (!current.creneau) {
                            duree_pause += duree_composition
                            current = current.next
                            parcourir = !!(current && current.next)
                        } else {
                            parcourir = false
                        }
                    }

                    if (duree_pause >= duree_pause_min) {
                        blocOuvert = false
                        numero++
                    }
                }
                plage.bloc_numero = numero
                if (!blocs[numero]) {
                    blocs[numero] = []
                }
                blocs[numero].push(plage)
            }

            let nbBlocWithCreneau = 0
            const dicoDureeBloc = []
            for (const c in blocs) {
                const count = blocs[c].length * duree_composition
                dicoDureeBloc[blocs[c][0].bloc_numero] = count
                if (blocs[c][0].creneau) {
                    nbBlocWithCreneau++
                    if (count > journees[j].max_consecutif) {
                        journees[j].max_consecutif = count
                    }
                }
            }

            // dernier parcours pour disabler
            const disabled = true
            for (const c in journees[j].plages) {
                plage = journees[j].plages[c]
                // si un le nombre de creneau de matinée est supérieur au max demi jour de l'épreuve on disabled les plages restants jusqu'a 13h00
                if (journees[j].nb_creneaux_matin >= max_cand_demi_jour) {
                    if (
                        !journees[j].plages[c].creneau &&
                        journees[j].plages[c].h_debut_m.isBefore(heure_demi_journee_moment)
                    ) {
                        journees[j].plages[c].disabled = disabled
                        state.messages_regles_journees[j].message_creneaux[c] = getMessageControle(
                            'max_cand_demi_journee',
                            max_cand_demi_jour,
                            duree_pause_min
                        )
                    }
                }

                // si un le nombre de creneau d'aprem est supérieur au max demi jour de l'épreuve on disabled les plages restants aprés 13h00
                if (journees[j].nb_creneaux_aprem >= max_cand_demi_jour) {
                    if (
                        !journees[j].plages[c].creneau &&
                        journees[j].plages[c].h_debut_m.isSameOrAfter(heure_demi_journee_moment)
                    ) {
                        journees[j].plages[c].disabled = disabled
                        state.messages_regles_journees[j].message_creneaux[c] = getMessageControle(
                            'max_cand_demi_journee',
                            max_cand_demi_jour,
                            duree_pause_min
                        )
                    }
                }

                // si un le nombre total de creneau est supérieur au max de candidat à la journée on disabled
                if (journees[j].nb_creneaux_matin + journees[j].nb_creneaux_aprem >= max_cand_jour) {
                    if (!journees[j].plages[c].creneau) {
                        journees[j].plages[c].disabled = disabled
                        state.messages_regles_journees[j].message_creneaux[c] = getMessageControle(
                            'max_cand_journee',
                            max_cand_jour,
                            duree_pause_min
                        )
                    }
                }

                // si un bloc de 6h, disable le reste
                if (journees[j].max_consecutif >= max_consecutif_autorise) {
                    if (!journees[j].plages[c].creneau) {
                        journees[j].plages[c].disabled = disabled
                        state.messages_regles_journees[j].message_creneaux[c] = getMessageControle(
                            'max_6h',
                            max_consecutif_autorise / 60,
                            duree_pause_min
                        )
                    }
                }

                // si plusieurs blocs, il faut tester le respect de la pause au bout de 5h
                if (nbBlocWithCreneau > 1) {
                    if (journees[j].max_consecutif > max_consecutif_avant_pause) {
                        if (!journees[j].plages[c].creneau) {
                            journees[j].plages[c].disabled = disabled
                            state.messages_regles_journees[j].message_creneaux[Number(c) - 1] = getMessageControle(
                                'max_5h',
                                max_consecutif_avant_pause / 60,
                                duree_pause_min
                            )
                        }
                    }

                    const duree_bloc = dicoDureeBloc[plage.bloc_numero]

                    const is_first_of_bloc = !plage.previous || plage.bloc_numero !== plage.previous.bloc_numero
                    const is_last_of_bloc = !plage.next || plage.bloc_numero !== plage.next.bloc_numero

                    if (plage.creneau) {
                        if (duree_bloc > max_consecutif_avant_pause && duree_bloc < max_consecutif_autorise) {
                            if (!journees[j].plages[c].creneau) {
                                journees[j].plages[c].disabled = disabled
                                state.messages_regles_journees[j].message_creneaux[Number(c) - 1] = getMessageControle(
                                    'max_5h',
                                    max_consecutif_avant_pause / 60,
                                    duree_pause_min
                                )
                            }
                        }

                        if (duree_bloc === max_consecutif_avant_pause) {
                            // si un bloc de 5h et 1 autre : bloquer durée de pause avant et après
                            if (is_first_of_bloc) {
                                let intervalleAvant = 0
                                let previous = plage.previous
                                while (intervalleAvant < duree_pause_min) {
                                    // cette plage ne peut pas etre utilisée
                                    if (previous) {
                                        if (!previous.creneau) {
                                            previous.disabled = disabled
                                            state.messages_regles_journees[j].message_creneaux[Number(c) - 1] =
                                                getMessageControle(
                                                    'max_5h',
                                                    max_consecutif_avant_pause / 60,
                                                    duree_pause_min
                                                )
                                        }
                                        previous = previous.previous
                                    }

                                    intervalleAvant += duree_composition
                                }
                            }

                            if (is_last_of_bloc) {
                                let intervalleApres = 0
                                let next = plage.next
                                while (intervalleApres < duree_pause_min) {
                                    // cette plage ne peut pas etre utilisée
                                    if (next) {
                                        if (!next.creneau) {
                                            next.disabled = disabled
                                            state.messages_regles_journees[j].message_creneaux[Number(c) + 1] =
                                                getMessageControle(
                                                    'max_5h',
                                                    max_consecutif_avant_pause / 60,
                                                    duree_pause_min
                                                )
                                        }
                                        next = next.next
                                    }

                                    intervalleApres += duree_composition
                                }
                            }
                        }
                    } else {
                        // pour une plage sans creneau, tester si son activation entraine un etat incoherent
                        // ssi la duree de la plage sans creneau dont elle fait partie est < pause apres qu'elle ait été activée
                        if (duree_bloc - duree_composition < duree_pause_min) {
                            let nouvelle_duree_bloc_suite_a_fusion = duree_bloc
                            if (dicoDureeBloc[plage.bloc_numero - 1]) {
                                nouvelle_duree_bloc_suite_a_fusion += dicoDureeBloc[plage.bloc_numero - 1]
                            }
                            if (dicoDureeBloc[plage.bloc_numero + 1]) {
                                nouvelle_duree_bloc_suite_a_fusion += dicoDureeBloc[plage.bloc_numero + 1]
                            }
                            if (nouvelle_duree_bloc_suite_a_fusion > max_consecutif_avant_pause) {
                                // et que le nouveau bloc formé dépasse 5h
                                plage.disabled = disabled
                                state.messages_regles_journees[j].message_creneaux[Number(c)] = getMessageControle(
                                    'max_5h',
                                    max_consecutif_avant_pause / 60,
                                    duree_pause_min
                                )
                            }
                        }
                    }
                }
            }
        }
        state.lock_creneau = journees
    },
    get_compteurs_serie_journee_TP: (state: any) => (series: Array<any>) => {
        const creneaux_TP_null = state.creneaux_TP.filter((creneau: any) => creneau.user_id === null)
        const compteurs_serie_journee: Array<any> = []
        const dates_series: Array<any> = []

        state.creneaux_prio = 0
        state.creneaux_non_prio = 0

        for (const s in series) {
            let total_series_prio = 0
            let total_series_supp = 0
            // Création des journées
            const journees: { [index: string]: any } = {}
            const daysDiff = diffTwoDatesInDays(series[s].debut, series[s].fin)
            let currentDay = series[s].debut
            for (let j = 0; j < daysDiff + 1; j++) {
                journees[formatDateYYYYMMDD(currentDay)] = []
                const periodes: any = []

                for (const ctp in creneaux_TP_null) {
                    if (creneaux_TP_null[ctp].jour === formatDateYYYYMMDD(currentDay)) {
                        total_series_prio += creneaux_TP_null[ctp].nb_candidats_prio
                        total_series_supp += creneaux_TP_null[ctp].nb_candidats_supp
                        periodes.push({
                            h: creneaux_TP_null[ctp].h_debut,
                            prio: creneaux_TP_null[ctp].nb_candidats_prio,
                            non_prio: creneaux_TP_null[ctp].nb_candidats_supp
                        })
                    }
                }

                journees[formatDateYYYYMMDD(currentDay)] = periodes
                currentDay = addDaysToDate(currentDay, 1)
            }

            if (!compteurs_serie_journee[series[s].id]) {
                compteurs_serie_journee[series[s].id] = {
                    code_serie: series[s].name,
                    prio: total_series_prio,
                    non_prio: total_series_supp,
                    actifs: 0,
                    journees: journees
                }
            }

            // Load des totaux complet pour toutes les séries
            state.creneaux_prio += total_series_prio
            state.creneaux_non_prio += total_series_supp

            dates_series.push({ serie_id: series[s].id, date_debut: series[s].debut, date_fin: series[s].fin })
        }

        return compteurs_serie_journee
    },
    get_creneaux_TP: (state: any) => (plages: any, examinateur_equipes: any) => {
        const creneaux: { [index: string]: any } = {}

        // Parcours de preparation de la structure du tableau
        for (const e in examinateur_equipes) {
            if (!creneaux[examinateur_equipes[e].id]) {
                if (examinateur_equipes[e].id !== null) {
                    creneaux[examinateur_equipes[e].id] = []
                    // Ajout des rdvs pour chacun des examinateurs
                    for (const j in plages) {
                        creneaux[examinateur_equipes[e].id][j] = []
                        for (const p in plages[j]) {
                            for (const d in plages[j][p].rdvs) {
                                const rdv_select = {
                                    actif: null,
                                    etat: plages[j][p].rdvs[d].etat,
                                    h_debut: plages[j][p].rdvs[d].h_debut,
                                    h_fin: plages[j][p].rdvs[d].h_fin,
                                    id: 0,
                                    index: 0,
                                    user_id: 0,
                                    nb_candidats_prio: 0,
                                    nb_candidats_supp: 0
                                }
                                creneaux[examinateur_equipes[e].id][j].push(rdv_select)
                            }
                        }
                    }
                }
            }
        }

        // Création de la collection pour les compteurs
        creneaux[-1] = []
        // Ajout des rdvs pour chacun des examinateurs
        for (const j in plages) {
            creneaux[-1][j] = []
            for (const p in plages[j]) {
                for (const d in plages[j][p].rdvs) {
                    const rdv_select = {
                        actif: null,
                        etat: plages[j][p].rdvs[d].etat,
                        h_debut: plages[j][p].rdvs[d].h_debut,
                        h_fin: plages[j][p].rdvs[d].h_fin,
                        id: 0,
                        index: 0,
                        user_id: 0,
                        nb_candidats_prio: 0,
                        nb_candidats_supp: 0
                    }
                    creneaux[-1][j].push(rdv_select)
                }
            }
        }

        // Check les dispos cochées pour chacun des examinateurs
        for (const e in creneaux) {
            const creneaux_exam = state.creneaux_TP.filter((ex: any) => ex.user_id === Number(e))
            for (const c in creneaux_exam) {
                if (creneaux[e][creneaux_exam[c].jour]) {
                    for (const d in creneaux[e][creneaux_exam[c].jour]) {
                        const creneau_select = creneaux[e][creneaux_exam[c].jour][d]
                        if (creneau_select.h_debut.isSame(formatStringServeurToDate(creneaux_exam[c].h_debut))) {
                            creneaux[e][creneaux_exam[c].jour][d].id = creneaux_exam[c].id
                            creneaux[e][creneaux_exam[c].jour][d].user_id = creneaux_exam[c].user_id
                            creneaux[e][creneaux_exam[c].jour][d].actif = creneaux_exam[c].actif
                            creneaux[e][creneaux_exam[c].jour][d].etat = creneaux_exam[c].etat
                            creneaux[e][creneaux_exam[c].jour][d].nb_candidats_prio = creneaux_exam[c].nb_candidats_prio
                            creneaux[e][creneaux_exam[c].jour][d].nb_candidats_supp = creneaux_exam[c].nb_candidats_supp
                            creneaux[e][creneaux_exam[c].jour][d].creator_id = creneaux_exam[c].creator_id
                            creneaux[e][creneaux_exam[c].jour][d].concour_id = creneaux_exam[c].concour_id
                        }
                    }
                }
            }
        }

        // Gestion des créneaux compteurs
        const creneaux_exam = state.creneaux_TP.filter((ex: any) => ex.user_id === null)
        for (const c in creneaux_exam) {
            if (creneaux[-1][creneaux_exam[c].jour]) {
                for (const d in creneaux[-1][creneaux_exam[c].jour]) {
                    const creneau_select = creneaux[-1][creneaux_exam[c].jour][d]
                    if (creneau_select.h_debut.isSame(formatStringServeurToDate(creneaux_exam[c].h_debut))) {
                        creneaux[-1][creneaux_exam[c].jour][d].id = creneaux_exam[c].id
                        creneaux[-1][creneaux_exam[c].jour][d].user_id = null
                        creneaux[-1][creneaux_exam[c].jour][d].actif = creneaux_exam[c].actif
                        creneaux[-1][creneaux_exam[c].jour][d].etat = creneaux_exam[c].etat
                        creneaux[-1][creneaux_exam[c].jour][d].nb_candidats_prio = creneaux_exam[c].nb_candidats_prio
                        creneaux[-1][creneaux_exam[c].jour][d].nb_candidats_supp = creneaux_exam[c].nb_candidats_supp
                        creneaux[-1][creneaux_exam[c].jour][d].creator_id = creneaux_exam[c].creator_id
                        creneaux[-1][creneaux_exam[c].jour][d].concour_id = creneaux_exam[c].concour_id
                    }
                }
            }
        }

        return creneaux
    },
    get_creneaux: (state: any) => (plages: any) => {
        const creneaux: { [index: string]: any } = {}
        // Parcours de preparation de la structure du tableau
        for (const p in plages) {
            creneaux[p] = []
            for (const u in plages[p]) {
                creneaux[p]['_' + plages[p][u].plage_index] = []

                for (const r in plages[p][u].rdvs) {
                    const rdv = {
                        actif: null,
                        etat: plages[p][u].rdvs[r].etat,
                        id: 0,
                        h_debut: plages[p][u].rdvs[r].h_debut,
                        h_fin: plages[p][u].rdvs[r].h_fin
                    }
                    creneaux[p]['_' + plages[p][u].plage_index].push(rdv)
                }
            }
        }

        // Check si les dispos sont cochées par l'examinateur
        for (const c in creneaux) {
            const filter_jour = state.creneaux_examinateur.filter((cr: any) => cr.jour === c)
            for (const f in filter_jour) {
                const h_debut: any = formatStringServeurToDate(filter_jour[f].h_debut).local()
                const h_debut_index: any = h_debut.format('HH')
                if (creneaux[c]['_' + h_debut_index] && filter_jour[f].id) {
                    for (const cr in creneaux[c]['_' + h_debut_index]) {
                        if (
                            creneaux[c]['_' + h_debut_index][cr].h_debut.local().isSame(
                                formatStringServeurToDate(filter_jour[f].h_debut).local()
                            )
                        ) {
                            creneaux[c]['_' + h_debut_index][cr].id = filter_jour[f].id
                            creneaux[c]['_' + h_debut_index][cr].actif = filter_jour[f].actif
                            creneaux[c]['_' + h_debut_index][cr].etat = filter_jour[f].etat
                            creneaux[c]['_' + h_debut_index][cr].user_id = filter_jour[f].user_id
                            creneaux[c]['_' + h_debut_index][cr].creator_id = filter_jour[f].creator_id
                            creneaux[c]['_' + h_debut_index][cr].seances_count = filter_jour[f].seances
                                ? filter_jour[f].seances.length
                                : 0
                            creneaux[c]['_' + h_debut_index][cr].ensemble_id = filter_jour[f].ensemble_id
                        }
                    }
                }
            }
        }
        return creneaux
    },
    applique_regles_creneaux: () => (is_responsable: boolean, session_user: any) => {
        if (is_responsable && session_user.disponibilites_submitted_at) {
            // Les règles ne s'applique pas
            return false
        } else {
            return true
        }
    }
}



