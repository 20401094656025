import * as API from '@/services/API'
import store from '@/store'

export default {
    getOrdreMission(ordreMissionId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/ordremissions/${ordreMissionId}`)
    },
    getOrdreMissions(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/ordremissions`, { params: payload })
    },
    updateOrdreMission(ordreMissionId: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/ordremissions/${ordreMissionId}`, payload)
    },
    updateOrdreMissionByIntervenant(ordreMissionId: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/ordremissions/${ordreMissionId}`, payload)
    },
    addOrdreMission(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/ordremissions`, payload)
    },
    deleteOrdreMission(ordreMissionId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/ordremissions/${ordreMissionId}`)
    },
    getDocumentOrdreMission(ordreMissionId: number, documentId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/ordremissions/${ordreMissionId}/documents/${documentId}?format=b64`)
    }
}
