import * as API from '@/services/API'

export default {
    async getDomaine(domaineId: number) {
        return await API.apiClient.get(`/domaines/${domaineId}`)
    },
    async getDomaines(payload: any) {
        return await API.apiClient.get('/domaines', { params: payload })
    },
    async updateDomaine(DomaineId: number, payload: any) {
        return await API.apiClient.put(`/domaines/${DomaineId}`, payload)
    },
    async addDomaine(payload: any) {
        return await API.apiClient.post('/domaines', payload)
    },
    async deleteDomaine(domaine_id: number) {
        return await API.apiClient.delete(`/domaines/${domaine_id}`)
    }
}
