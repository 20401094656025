import { getError } from '@/utils/helpers'
import TourneeService from '@/services/TourneeService'

export const namespaced = true

function setTournees(commit: Function, response: any) {
    commit('SET_TOURNEES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
}

export const state: any = {
    tournees: [],
    tournee: {},
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            tournees: [],
            tournee: {},
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0
        })
    },
    SET_TOURNEES(state: any, tournees: any) {
        state.tournees = tournees
    },
    SET_MORE_TOURNEES(state: any, tournees: any) {
        state.tournees = state.tournees.concat(tournees)
    },
    SET_TOURNEE(state: any, tournee: any) {
        state.tournee = tournee
    },
    ADD_TOURNEE(state: any, tournee: any) {
        state.tournee = tournee
        state.tournees.push(tournee)
    },
    UPDATE_TOURNEE(state: any, tournee: any) {
        state.tournee = tournee
        state.tournees = state.tournees.map((item: any) => {
            if (item.id === tournee.id) {
                return tournee
            }
            return item
        })
    },
    DELETE_TOURNEE(state: any, tournee_id: number) {
        if (state.tournee.id === tournee_id) {
            state.tournee = {}
        }
        state.tournees = state.tournees.filter((tournee: any) => tournee.id !== tournee_id)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    }
}

export const actions = {
    getTournees({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            TourneeService.getTournees(params)
                .then((response) => {
                    setTournees(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getTournee({ commit }: { commit: any }, tournee_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            TourneeService.getTournee(tournee_id)
                .then((response) => {
                    commit('SET_TOURNEE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getMoreTournees({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            TourneeService.getTournees(params.formulaire_id)
                .then((response) => {
                    commit('SET_MORE_TOURNEES', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    createTournee({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            TourneeService.postTournee(params)
                .then((response) => {
                    commit('ADD_TOURNEE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    updateTournee({ commit }: { commit: any }, params: { tournee_id: number; payload: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            TourneeService.putTournee(params.tournee_id, params.payload)
                .then((response) => {
                    commit('UPDATE_TOURNEE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    deleteTournee({ commit }: { commit: any }, tournee_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            TourneeService.deleteTournee(tournee_id)
                .then((response) => {
                    commit('DELETE_TOURNEE', tournee_id)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {
    tournees: (state: any): [] => {
        return state.tournees
    },
    tournee: (state: any): {} => {
        return state.tournee
    },
    meta: (state: any): any => {
        return state.meta
    },
    links: (state: any): string | null => {
        return state.links
    },
    loading: (state: any): boolean => {
        return state.loading
    },
    error: (state: any): any => {
        return state.error
    },
    totalRows: (state: any): number => {
        return state.totalRows
    }
}
