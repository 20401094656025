import * as API from '@/services/API'
import store from '@/store'

export default {
    // liste des types de document pour alimentation des filtres
    async getEnumTypesDeDocuments() {
        // GET {{url}}/api/documentsignables/filters
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/documentsignables/filters`)
    },
    // liste des documents à signer
    async getDocumentsSignables(payload: any) {
        // GET {{url}}/api/documentsignables
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/documentsignables`, { params: payload })
    },
    // afficher le document pdf à signer
    async getDocumentSignablePdf(entity_type: string, documentId: number) {
        // GET {{url}}/api/documentsignables/{entity}/{id}
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/documentsignables/${entity_type}/${documentId}?format=b64`)
    },
    // signer le document
    async signDocumentSignable(entity_type: string, documentId: number, payload: any) {
        // PUT {{url}}/api/documentsignables/{entity}/{id} payload : {"signature": ""}
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(
            `/sessions/${session_id}/documentsignables/${entity_type}/${documentId}`,
            payload
        )
    },
    async signDocumentsSignables(payload: any) {
        // PUT {{url}}/api/documentsignables/{entity}/{id} payload : {"signature": ""}
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/documentsignables`, payload)
    },
    async getTokenSignature(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(
            `/sessions/${session_id}/documentsignables/${params.model}/${params.id}/token_signature?mode=${params.mode}`
        )
    },
    async getTokensSignature(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(
            `/sessions/${session_id}/documentsignables/token_signature?mode=${params.mode}`,
            params.payload
        )
    }
}
