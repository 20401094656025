import { getError } from '@/utils/helpers'
import ImpressionsService from '@/services/ImpressionsService'
import { StateImpressionCentreInterface } from '@/types/Fourniture'

export const namespaced = true

function setTableauImpressionCentre(commit: Function, response: any) {
    commit('SET_TABLEAU_IMPRESSION_CENTRE', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_LOADING_RECHERCHE', false)
    commit('SET_TOTAL', response.data.meta ? response.data.meta.total : 1)
}

export const state: StateImpressionCentreInterface = {
    tableauImpressionCentre: [],
    loadingRecherche: false,
    loadingPdf: false,
    pdfView: [],
    meta: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    total: 0,
    error: null
}

export const mutations = {
    RESET_STATE(state: StateImpressionCentreInterface) {
        Object.assign(state, {
            tableauImpressionCentre: [],
            loadingRecherche: false,
            error: null,
            loadingPdf: false,
            pdfView: [],
            meta: null
        })
    },
    SET_TABLEAU_IMPRESSION_CENTRE(state: StateImpressionCentreInterface, tableauImpressionsCentre: any) {
        state.tableauImpressionCentre = tableauImpressionsCentre
    },
    SET_MORE_TABLEAU_IMPRESSION_CENTRE(state: any, tableauImpressionCentre: any) {
        state.tableauImpressionCentre = state.tableauImpressionCentre.concat(tableauImpressionCentre)
    },
    SET_LOADING_RECHERCHE(state: StateImpressionCentreInterface, loadingRecherche: boolean) {
        state.loadingRecherche = loadingRecherche
    },
    SET_PDF(state: StateImpressionCentreInterface, pdf: any) {
        state.pdfView = pdf
    },
    SET_LOADING_PDF(state: StateImpressionCentreInterface, loadingPdf: boolean) {
        state.loadingPdf = loadingPdf
    },
    SET_ERROR(state: StateImpressionCentreInterface, error: Array<any>) {
        state.error = error
    },
    RESET_ERROR(state: StateImpressionCentreInterface) {
        state.error = null
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL(state: any, totalPage: number) {
        state.total = totalPage
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    RESET_META(state: any) {
        state.meta = null
        state.totalRows = 0
        state.currentPage = 1
        state.lastPage = 0
        state.total = 0
    },
    RESET_TABLEAU_IMPRESSION(state: any) {
        state.tableauImpressionCentre = []
    }
}

export const actions = {
    getTableauImpressionCentre({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING_RECHERCHE', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            ImpressionsService.recherche(payload)
                .then((response) => {
                    setTableauImpressionCentre(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_RECHERCHE', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreTableauImpressionCentre({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING_RECHERCHE', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            ImpressionsService.recherche(payload)
                .then((response) => {
                    commit('SET_MORE_TABLEAU_IMPRESSION_CENTRE', response.data.data)
                    commit('SET_LOADING_RECHERCHE', false)
                    commit('SET_META', response.data.meta)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_RECHERCHE', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPdf({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING_PDF', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            ImpressionsService.getPDF(payload)
                .then((response) => {
                    commit('SET_PDF', response.data)
                    commit('SET_LOADING_PDF', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_PDF', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    RESET_TABLEAU_IMPRESSION_CENTRE({ commit }: { commit: any }) {
        commit('RESET_ERROR')
        commit('RESET_TABLEAU_IMPRESSION_CENTRE')
    },

    CONFIRMATION_IMPRESSION({ commit }: { commit: any }, payload?: any) {
        // commit('SET_LOADING_IMPRESSION', true)
        commit('RESET_ERROR')
        return new Promise((resolve, reject) => {
            ImpressionsService.recherche(payload)
                .then((response) => {
                    setTableauImpressionCentre(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_RECHERCHE', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    tableauImpressionsCentreTrie: (state: StateImpressionCentreInterface) => {
        return state.tableauImpressionCentre
    },
    loadingRecherche: (state: StateImpressionCentreInterface) => {
        return state.loadingRecherche
    },
    loadingPdf: (state: StateImpressionCentreInterface) => {
        return state.loadingPdf
    },
    getPdf: (state: StateImpressionCentreInterface) => {
        return state.pdfView
    },
    getError: (state: StateImpressionCentreInterface) => {
        return state.error
    },
    meta: (state: any) => {
        return state.meta
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    total: (state: any) => {
        return state.total
    }
}
