import { getError } from '@/utils/helpers'
import candidatIncompatible from '@/services/CandidatIncompatibleService'
import {
    AddCandidat,
    InvalidatedSubmissionIncomp,
    RejectSubmitIncomp,
    RequestSearchByCandidat,
    SearchByEtablissement,
    StateCandidatIcompatible,
    ValidatedSubmissionIncomp
} from '@/types/CandidatIncompatible'

export const namespaced = true

/** Trie d'un tableau, groupe par par une propriete (dans mon utilisation, par etablissement, puis filiere)   */
function groupBy(tableauObjets: any[], propriete: string | number) {
    return tableauObjets.reduce(function (acc: { [x: string]: any[] }, obj: { [x: string]: any }) {
        const cle = obj[propriete]
        if (!acc[cle]) {
            acc[cle] = []
        }
        acc[cle].push(obj)
        return acc
    }, {})
}

/* Trie de l'affichage des etablissements par ville */
function sortBy(array: any) {
    return array.sort(function (a: { ville: string }, b: { ville: any }) {
        return a.ville.localeCompare(b.ville)
    })
}

/** ajout de toutes les infos pour le tableau des candidats incompatibles */
function setCandidatsIncompatibles(commit: Function, response: any) {
    commit('SET_CandidatsIncompatibles', response.data.data)
    commit('SET_LOADING', false)
}

/** fonction d'affichage des couleurs des saisies d'incompatibilité dans la table  */
export function getIncompSpec(infoSessionUser: any): any {
    if (
        infoSessionUser &&
        infoSessionUser.incompatibilites_submitted_at != null &&
        infoSessionUser.incompatibilites_validated_at === null
    ) {
        return { index: 1, libelle: 'Incompatibilités soumises', color: 'info' }
    }
    if (infoSessionUser && infoSessionUser.incompatibilites_validated_at != null) {
        return { index: 3, libelle: 'Incompatibilités validées', color: 'success' }
    }
    if (infoSessionUser && infoSessionUser.commentaire_rejet && infoSessionUser.commentaire_rejet.incompatibilites) {
        return { index: 4, libelle: 'Attente de correction', color: 'danger' }
    } else {
        return { index: 0, libelle: 'Non défini', color: 'tertiary' }
    }
}

export const state: StateCandidatIcompatible = {
    listeCandidatsIncompatibles: [],
    candidatSelectedByUser: [],
    examinateurSelectionne: [],
    resultatRecherche: [],
    resultatRechercheCandidat: [],
    metaRechercheCandidat: [],
    resultatRechercheEtablissement: [],
    selectedCandidat: [],
    allEtablissements: [],
    isLoading: false,
    isLoadingRechercheEtablissement: false,
    isLoadingRechercheCandidat: false,
    submitted_at: null,
    validated_at: null,
    comment_reject: null,
    error: ''
}

export const mutations: any = {
    SET_CANDIDATS_SELECTED_BY_USER(state: StateCandidatIcompatible, selection: any) {
        state.candidatSelectedByUser = selection
    },

    SET_CandidatsIncompatibles(state: StateCandidatIcompatible, CandidatsIncompatibles: Array<any>) {
        for (const candidat of CandidatsIncompatibles) {
            if (candidat.name && candidat.first_name) {
                candidat.identite =
                    candidat.name.toUpperCase() +
                    ' ' +
                    candidat.first_name[0].toUpperCase() +
                    candidat.first_name.slice(1)
            }
        }
        state.listeCandidatsIncompatibles = CandidatsIncompatibles
    },

    SET_EXAMINATEUR_SELECTIONNE(state: StateCandidatIcompatible, examinateur: any) {
        state.examinateurSelectionne = examinateur
        state.submitted_at = null
        state.validated_at = null
        state.comment_reject = null
        if (examinateur && examinateur.infosessionuser) {
            state.submitted_at = examinateur.infosessionuser.incompatibilites_submitted_at
            state.validated_at = examinateur.infosessionuser.incompatibilites_validated_at
            if (
                examinateur.infosessionuser.commentaire_rejet &&
                examinateur.infosessionuser.commentaire_rejet.incompatibilites
            ) {
                state.comment_reject = examinateur.infosessionuser.commentaire_rejet.incompatibilites
            }
        }
    },

    DELETE_CandidatsIncompatibles(state: StateCandidatIcompatible, candidatsIds: Array<any>) {
        state.listeCandidatsIncompatibles = state.listeCandidatsIncompatibles.filter(
            (candidat: any) => candidatsIds.indexOf(candidat.id) === -1
        )
    },

    SET_ALL_ETABLISSEMENTS(state: StateCandidatIcompatible, etablissement: any) {
        state.allEtablissements = etablissement
    },

    SET_LOADING(state: StateCandidatIcompatible, loading: boolean) {
        state.isLoading = loading
    },

    SET_LOADING_RECHERCHE_ETABLISSEMENT(state: StateCandidatIcompatible, loading: boolean) {
        state.isLoadingRechercheEtablissement = loading
    },

    SET_LOADING_RECHERCHE_CANDIDAT(state: StateCandidatIcompatible, loading: boolean) {
        state.isLoadingRechercheCandidat = loading
    },

    SET_META_RECHERCHE_CANDIDAT(state: StateCandidatIcompatible, meta: any) {
        state.metaRechercheCandidat = meta
    },

    RESET_STATE(state: StateCandidatIcompatible) {
        state.listeCandidatsIncompatibles = []
    },

    RESET_SEARCH(state: StateCandidatIcompatible) {
        state.resultatRecherche = []
        state.resultatRechercheCandidat = []
        state.selectedCandidat = []
        state.resultatRechercheEtablissement = []
    },

    RESET_LISTE_INCOMPATIBLE(state: StateCandidatIcompatible) {
        state.listeCandidatsIncompatibles = []
    },

    RESET_STATE_SUBMISSION(state: StateCandidatIcompatible) {
        state.submitted_at = null
        state.validated_at = null
        state.comment_reject = null
    },

    RESET_SELECTED_EXAMINATEUR(state: StateCandidatIcompatible) {
        state.examinateurSelectionne = []
    },

    SET_RESULT_SEARCH_ETABLISSEMENT(state: StateCandidatIcompatible, result: any) {
        state.resultatRechercheEtablissement = result
    },

    SET_RESULT_SEARCH(state: StateCandidatIcompatible, result: any) {
        state.resultatRecherche = result
    },

    /**  Mise en memoire du candidat sélectionné lors de la recherche par candidat */
    SET_SELECTED_CANDIDAT(state: StateCandidatIcompatible, result: any) {
        const selection = [{ candidat_id: result }]
        state.selectedCandidat = selection
    },

    SET_SUBMITTED_AT(state: StateCandidatIcompatible, submitted: string) {
        state.submitted_at = submitted
    },

    SET_VALIDATED_AT(state: StateCandidatIcompatible, validated_at: string) {
        state.validated_at = validated_at
    },

    SET_COMMENT_REJECT(state: StateCandidatIcompatible, comment_reject: string) {
        state.comment_reject = comment_reject
    },

    SET_ERROR(state: StateCandidatIcompatible, error: Array<any>) {
        state.error = error
    },
    MAJ_DELETE_CANDIDATS(state: StateCandidatIcompatible, candidats_ids: Array<any>) {
        for (const c in candidats_ids) {
            const indexCandidat = state.listeCandidatsIncompatibles.findIndex(
                (cand: any) => cand.id === candidats_ids[c].candidat_id
            )
            if (indexCandidat !== -1) {
                state.listeCandidatsIncompatibles.splice(indexCandidat, 1)
            }
        }
    }
}

export const actions: any = {
    getCandidatsIncompatibles({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            candidatIncompatible
                .getCandidatsIncompatibles(params)
                .then((response) => {
                    setCandidatsIncompatibles(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    reject(error)
                })
        })
    },

    /* Ajout de candidats incompatibles */
    addCandidats({ commit }: { commit: any }, params: AddCandidat) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            candidatIncompatible
                .addCandidats(params)
                .then((response) => {
                    setCandidatsIncompatibles(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },

    deleteCandidatsIncompatibles({ commit }: { commit: any }, params: any) {
        // commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            candidatIncompatible
                .deleteCandidatsIncompatibles(params)
                .then((response) => {
                    commit('MAJ_DELETE_CANDIDATS', params.candidats)
                    // setCandidatsIncompatibles(commit, response)
                    // commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    reject(error)
                })
        })
    },

    /*
     *
     *   Recherche par établissement
     *
     */

    GET_ALL_ETABLISSEMENTS({ commit }: { commit: any }) {
        return new Promise((resolve, reject) => {
            candidatIncompatible
                .getAllEtablissements()
                .then((response) => {
                    commit('SET_ALL_ETABLISSEMENTS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    RECHERCHE_PAR_ETABLISSEMENT({ commit }: { commit: any }, params: SearchByEtablissement) {
        commit('SET_LOADING_RECHERCHE_ETABLISSEMENT', true)

        return new Promise((resolve, reject) => {
            candidatIncompatible
                .rechercheParEtablissement(params)
                .then((response) => {
                    commit('SET_RESULT_SEARCH_ETABLISSEMENT', response.data.data)
                    commit('SET_LOADING_RECHERCHE_ETABLISSEMENT', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_RECHERCHE_ETABLISSEMENT', false)
                    reject(error)
                })
        })
    },

    RECHERCHE_PAR_CANDIDAT({ commit }: { commit: any }, params: RequestSearchByCandidat) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            candidatIncompatible
                .rechercheParCandidat(params)
                .then((response) => {
                    commit('SET_RESULT_SEARCH', response.data.data)
                    commit('SET_META_RECHERCHE_CANDIDAT', response.data.meta)
                    commit('SET_LOADING', false)

                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)

                    reject(error)
                })
        })
    },

    /*

        Gestion des soumissions

    */
    GET_STATE_SUBMISSION({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            candidatIncompatible
                .getValidationListeIncompatibles(params)
                .then((response) => {
                    commit('SET_SUBMITTED_AT', response.data.data.incompatibilites_submitted_at)
                    commit('SET_VALIDATED_AT', response.data.data.incompatibilites_validated_at)
                    if (response.data.data.commentaire_rejet) {
                        commit('SET_COMMENT_REJECT', response.data.data.commentaire_rejet.incompatibilites)
                    }
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },

    SUBMIT_INCOMP({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            params = {
                user_id: params.user_id,
                incompatibilites_submit: 1
            }
            candidatIncompatible
                .putValidationListeIncompatibles(params)
                .then((response) => {
                    commit('SET_SUBMITTED_AT', response.data.data.incompatibilites_submitted_at)
                    commit('SET_VALIDATED_AT', response.data.data.incompatibilites_validated_at)
                    if (response.data.data.commentaire_rejet) {
                        commit('SET_COMMENT_REJECT', response.data.data.commentaire_rejet.incompatibilites)
                    }
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },

    /* Annulation de la soumission */
    REJECT_INCOMP({ commit }: { commit: any }, params: RejectSubmitIncomp) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            params = {
                user_id: params.user_id,
                incompatibilites_submit: 0,
                commentaire_rejet: params.commentaire_rejet
            }

            candidatIncompatible
                .putValidationListeIncompatibles(params)
                .then((response) => {
                    commit('SET_SUBMITTED_AT', response.data.data.incompatibilites_submitted_at)
                    commit('SET_VALIDATED_AT', response.data.data.incompatibilites_validated_at)
                    if (response.data.data.commentaire_rejet) {
                        commit('SET_COMMENT_REJECT', response.data.data.commentaire_rejet.incompatibilites)
                    }
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },

    VALIDATION_INCOMP({ commit }: { commit: any }, params: ValidatedSubmissionIncomp) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            params = {
                user_id: params.user_id,
                incompatibilites_validate: 1
            }

            candidatIncompatible
                .putValidationListeIncompatibles(params)
                .then((response) => {
                    commit('SET_SUBMITTED_AT', response.data.data.incompatibilites_submitted_at)
                    commit('SET_VALIDATED_AT', response.data.data.incompatibilites_validated_at)
                    if (response.data.data.commentaire_rejet) {
                        commit('SET_COMMENT_REJECT', response.data.data.commentaire_rejet.incompatibilites)
                    }
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },

    /* Invalider la soumission */
    INVALID_INCOMP({ commit }: { commit: any }, params: InvalidatedSubmissionIncomp) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            params = {
                user_id: params.user_id,
                incompatibilites_validate: 0
            }

            candidatIncompatible
                .putValidationListeIncompatibles(params)
                .then((response) => {
                    commit('SET_SUBMITTED_AT', response.data.data.incompatibilites_submitted_at)
                    commit('SET_VALIDATED_AT', response.data.data.incompatibilites_validated_at)
                    if (response.data.data.commentaire_rejet) {
                        commit('SET_COMMENT_REJECT', response.data.data.commentaire_rejet.incompatibilites)
                    }

                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters: any = {
    /*    listeCandidatsIncompatibles(state: { listeCandidatsIncompatibles: any }) {
        return state.listeCandidatsIncompatibles
    }, */

    error: (state: any) => {
        return state.error
    },
    listIsLoading(state: { isLoading: boolean }) {
        return state.isLoading
    },

    loadingRechercheEtablissement(state: { isLoadingRechercheEtablissement: boolean }) {
        return state.isLoadingRechercheEtablissement
    },

    selectedExaminateur: (state: StateCandidatIcompatible) => {
        return state.examinateurSelectionne.id
    },

    /* Retourne un tableau a deux dimension (trie par etablissement, puis filiere) */
    listeCandidatsIncompatiblesGroupee: (state: StateCandidatIcompatible) => {
        const candidatsParEtablissement = groupBy(state.listeCandidatsIncompatibles, 'etablissement_id')
        const groupeParFiliere = []

        for (const i in candidatsParEtablissement) {
            groupeParFiliere.push(groupBy(candidatsParEtablissement[i], 'concour_id'))
        }
        return groupeParFiliere
    },

    /* Liste des candidats séléctionné par l'examinateur / admin  */
    candidatSelected(state: { candidatSelectedByUser: any }) {
        return state.candidatSelectedByUser
    },

    allEtablissements(state: { allEtablissements: any }) {
        return sortBy(state.allEtablissements)
    },

    resultatRechercheParEtablissement(state: { resultatRechercheEtablissement: any }) {
        const candidatsParEtablissement = groupBy(state.resultatRechercheEtablissement, 'etablissement_id')
        const groupeParFiliere = []

        for (const i in candidatsParEtablissement) {
            groupeParFiliere.push(groupBy(candidatsParEtablissement[i], 'concour_id'))
        }
        return groupeParFiliere
    },

    resultatRechercheParCandidat(state: { resultatRecherche: any }) {
        return state.resultatRecherche
    },

    /** recupération des id afin de transmettre au back  */
    resultatRechercheParEtablissementTrie(state: { resultatRechercheEtablissement: any }) {
        const result = []
        for (let index = 0; index < state.resultatRechercheEtablissement.length; index++) {
            result.push({ candidat_id: state.resultatRechercheEtablissement[index].id })
        }
        return result
    },

    incompatibleIsSubmitted: (state: StateCandidatIcompatible) => {
        if (state.submitted_at != null) {
            return true
        } else {
            return false
        }
    },

    incompatibleIsValidated: (state: StateCandidatIcompatible) => {
        if (state.validated_at != null) {
            return true
        } else {
            return false
        }
    },

    incompatibleIsRejected: (state: StateCandidatIcompatible) => {
        if (state.comment_reject === '' || state.comment_reject == null) {
            return false
        } else {
            return true
        }
    },

    get_commentaire_rejet: (state: StateCandidatIcompatible) => {
        if (state.comment_reject) {
            return state.comment_reject
        }
    },

    get_submitted_at(state: StateCandidatIcompatible) {
        return state.submitted_at
    },

    get_validated_at(state: StateCandidatIcompatible) {
        return state.validated_at
    },

    get_number_of_incomp(state: StateCandidatIcompatible) {
        return state.listeCandidatsIncompatibles.length
    },

    get_meta_recherche_Candidat(state: StateCandidatIcompatible) {
        return state.metaRechercheCandidat
    }
}

