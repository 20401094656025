import { getError } from '@/utils/helpers'
import DemissionService from '@/services/DemissionService'
import { state_demission, type_demission } from '@/types/Demission'
import Vue from 'vue'

export const namespaced = true

export const state: any = {
    demissions_concours: [],
    demissions_epreuves_fac: [],
    meta: null,
    loading: false,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    loading_demission: false,
    error_demission: null
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            demissions_concours: [],
            demissions_epreuves_fac: [],
            meta: null,
            loading: false,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            loading_demission: false,
            error_demissionv: null
        })
    },
    SET_DEMISSIONS_CONCOURS(state: any, demissions: any) {
        state.demissions_concours = demissions
    },
    SET_MORE_DEMISSIONS(state: any, demissions: any) {
        state.demissions_concours = state.demissions_concours.concat(demissions)
    },
    SET_DEMISSIONS_EPREUVES_FAC(state: any, demissions: any) {
        state.demissions_epreuves_fac = demissions
    },
    SET_MORE_DEMISSIONS_EPREUVES_FAC(state: any, demissions: any) {
        state.demissions_epreuves_fac = state.demissions_epreuves_fac.concat(demissions)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading_demission = loading
    },
    SET_ERROR(state: any, error: any) {
        state.error_demission = error
    },
    MAJ_DEMISSION_CONCOURS(state: any, demission: any) {
        const index = state.demissions_concours.map((c: any) => c.id).indexOf(demission.id)
        Vue.set(state.demissions_concours, index, demission)
    },
    MAJ_DEMISSION_EPREUVES(state: any, demission: any) {
        const index = state.demissions_epreuves_fac.map((c: any) => c.id).indexOf(demission.id)
        Vue.set(state.demissions_epreuves_fac, index, demission)
    },
    ADD_DEMISSION_CONCOURS(state: any, demission: any) {
        state.demissions_concours.push(demission)
    },
    ADD_DEMISSION_EPREUVES(state: any, demission: any) {
        state.demissions_epreuves_fac.push(demission)
    }
}

export const actions = {
    getDemissions({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DemissionService.getDemissions(params.type, params.params)
                .then((response) => {
                    if (params.type === type_demission.CONCOURS) {
                        commit('SET_DEMISSIONS_CONCOURS', response.data.data)
                    } else if (params.type === type_demission.EPREUVES_FAC) {
                        commit('SET_DEMISSIONS_EPREUVES_FAC', response.data.data)
                    }

                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    commit('SET_META', response.data.meta ? response.data.meta : null)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreDemissions({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DemissionService.getDemissions(params.type, params.params)
                .then((response) => {
                    if (params.type === type_demission.CONCOURS) {
                        commit('SET_MORE_DEMISSIONS_CONCOURS', response.data.data)
                    } else if (params.type === type_demission.EPREUVES_FAC) {
                        commit('SET_MORE_DEMISSIONS_EPREUVES_FAC', response.data.data)
                    }
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateDemission({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DemissionService.updateDemission(params.type, params.candidat_id, params.payload)
                .then((response) => {
                    if (params.type === type_demission.CONCOURS) {
                        commit('MAJ_DEMISSION_CONCOURS', response.data.data)
                    } else if (params.type === type_demission.EPREUVES_FAC) {
                        commit('MAJ_DEMISSION_EPREUVES', response.data.data)
                    }
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    postDemission({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DemissionService.postDemission(params.type, params.candidat_id, params.payload)
                .then((response) => {
                    if (params.type === type_demission.CONCOURS) {
                        commit('ADD_DEMISSION_CONCOURS', response.data.data)
                    } else if (params.type === type_demission.EPREUVES_FAC) {
                        commit('ADD_DEMISSION_EPREUVES', response.data.data)
                    }
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    exportDemission({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DemissionService.exportDemission(params)
                .then((response) => {
                    // setDemandesRemboursement(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    demissions_concours: (state: any) => {
        return state.demissions_concours
    },
    demissions_epreuves_fac: (state: any) => {
        return state.demissions_epreuves_fac
    },
    selectDemission: (state: any) => {
        return state.selectedDemission
    },
    meta: (state: any) => {
        return state.meta
    },
    loading_demission: (state: any) => {
        return state.loading_demission
    },
    totalRows: (state: any) => {
        return state.totalRows
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    error_demission: (state: any) => {
        return state.error_demission
    },
    getCptDemissions_epreuve: (state: any) => {
        const cptDeclarees = state.demissions_epreuves_fac.length
        const cptValidees = state.demissions_epreuves_fac.filter(
            (d: any) => d.epreuveFac[0].demission === state_demission.DEMISSION_TRAITE_OUI
        ).length

        return {
            cptDeclarees: cptDeclarees,
            cptValidees: cptValidees
        }
    },
    getCptDemissions_concours: (state: any) => {
        const cptDeclarees = state.demissions_concours.length
        const cptValidees = state.demissions_concours.filter(
            (d: any) => d.demission === state_demission.DEMISSION_TRAITE_OUI
        ).length

        return {
            cptDeclarees: cptDeclarees,
            cptValidees: cptValidees
        }
    }
}
