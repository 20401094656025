import * as API from '@/services/API'
import { AxiosRequestConfig } from 'axios'
import store from '@/store/index'

export default {
    async getAmenagement(amenagementId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/amenagements/${amenagementId}`)
    },
    async getAmenagements(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/amenagements`, { params: payload })
    },
    async updateAmenagement(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/amenagements/${payload.id}`, payload)
    },
    async addAmenagement(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/amenagements`, payload)
    },
    async deleteAmenagement(amenagement_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/amenagements/${amenagement_id}`)
    },
    importAmenagements(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/amenagements_import`, payload)
    },
    exportAmenagements() {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return API.apiClient.get(`/sessions/${session_id}/amenagements_export`, params)
    }
}
