import CentreService from '@/services/CentreService'
import { dateDuJour, diffTwoDatesInMinutes, getError, totalFormatToHourMinutesHumanizer } from '@/utils/helpers'
import Vue from 'vue'

export const namespaced = true

export const state: any = {
    gabarit: [],
    gabaritForSave: [],
    loading: false,
    error: null,
    errorGabaritFicheHoraireJournees: [],
    errorGabaritFicheHoraireCategories: [],
    loadingGabaritFicheHoraire: false,
    appliquerFicheATous: false,
    reponse_token: null,
    centre_user_fh: null
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            gabarit: [],
            gabaritForSave: [],
            loading: false,
            error: null,
            errorGabaritFicheHoraireJournees: [],
            errorGabaritFicheHoraireCategories: [],
            loadingGabaritFicheHoraire: false,
            appliquerFicheATous: false,
            reponse_token: null,
            centre_user_fh: null
        })
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_LOADING_GABARIT_FICHE_HORAIRE(state: any, loading: boolean) {
        state.loadingGabaritFicheHoraire = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_GABARIT_FICHE_HORAIRE(state: any, datas: any) {
        state.gabarit = datas
        state.gabarit.transverse = false
    },
    UPDATE_JOURNEES_GABARIT(state: any, journees: any) {
        // Check si on a des erreurs de cohérence
        state.errorGabaritFicheHoraireJournees = []
        for (const j in journees) {
            let p: any = 0
            for (p in journees[j].plages) {
                // incohérence entre une plage début et une fin
                const diff = diffTwoDatesInMinutes(journees[j].plages[p].debut, journees[j].plages[p].fin)
                if (diff <= 0) {
                    state.errorGabaritFicheHoraireJournees[j] =
                        "L'heure de début doit être inférieure à l'heure de fin."
                }

                // Incohérence entre une plage fin (p-1) et une plage début (p)
                if (journees[j].plages[p - 1]) {
                    const diff = diffTwoDatesInMinutes(journees[j].plages[p - 1].fin, journees[j].plages[p].debut)
                    if (diff <= 0) {
                        state.errorGabaritFicheHoraireJournees[j] = 'Les heures des plages doivent être successives.'
                    } else if (diff < 30) {
                        state.errorGabaritFicheHoraireJournees[j] = 'La pause méridienne minimale est de 30 minutes.'
                    }
                }
            }

            Vue.set(state.gabarit.journees, j, journees[j])
        }
    },
    UPDATE_AUTRES_GABARIT(state: any, autres: any) {
        state.gabarit.autres = autres
    },
    SET_APPLIQUER_A_TOUS(state: any, appliquerFicheATous: boolean) {
        // state.appliquerFicheATous = appliquerFicheATous
        state.gabarit.transverse = appliquerFicheATous
    },
    RESET_GABARIT_FICHE_HORAIRE(state: any) {
        state.gabarit = []
        state.errorGabaritFicheHoraireJournees = []
        state.errorGabaritFicheHoraireCategories = []
    },
    SET_REPONSE_TOKEN(state: any, response: any) {
        state.reponse_token = response
    },
    SET_CENTRE_USER_FH(state: any, centre_user_fh: any) {
        state.centre_user_fh = centre_user_fh
    }
}

export const actions = {
    updateFicheHorairePosteUser({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.updateFicheHorairePosteUser(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    publierFichesHoraires({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.publierFichesHoraires()
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    publierFichesHorairesIntervenants({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.publierFichesHorairesIntervenants(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPdfFicheHoraire({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.getPdfFicheHoraire(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getFicheHoraire({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.getFicheHoraire(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_CENTRE_USER_FH', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    signatureFicheHoraire({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.signatureFicheHoraire(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getTokenSignatureFicheHoraire({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.getTokenSignatureFicheHoraire(params)
                .then((response) => {
                    commit('SET_REPONSE_TOKEN', response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPdfFichesHoraire({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.getPdfFichesHoraire(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    signatureFichesHoraire({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.signatureFichesHoraire(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getTokenSignatureFichesHoraire({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreService.getTokenSignatureFichesHoraire(params)
                .then((response) => {
                    commit('SET_REPONSE_TOKEN', response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    appliquerFicheATous: (state: any) => {
        return state.appliquerFicheATous
    },
    errorGabaritFicheHoraireJournees: (state: any) => {
        return state.errorGabaritFicheHoraireJournees
    },
    errorGabaritCategorie: (state: any) => {
        return state.errorGabaritCategorie
    },
    /* Retourne le total formatter en heure d'une journee (ligne) */
    get_total_journee: () => (journee: any) => {
        let minutes = 0
        for (const p in journee.plages) {
            if (
                journee.plages[p].debut !== null &&
                journee.plages[p].debut !== '' &&
                journee.plages[p].fin !== null &&
                journee.plages[p].fin !== ''
            ) {
                const diff = diffTwoDatesInMinutes(journee.plages[p].debut, journee.plages[p].fin)
                minutes += diff
            }
        }
        return minutes !== 0 ? totalFormatToHourMinutesHumanizer(minutes) : '-'
    },
    get_total_fiche_horaire: () => (fiche: any) => {
        let minutes = 0

        if (fiche && fiche.journees) {
            for (const j in fiche.journees) {
                for (const p in fiche.journees[j].plages) {
                    if (
                        fiche.journees[j].plages[p].debut !== null &&
                        fiche.journees[j].plages[p].debut !== '' &&
                        fiche.journees[j].plages[p].fin !== null &&
                        fiche.journees[j].plages[p].fin !== ''
                    ) {
                        const diff = diffTwoDatesInMinutes(
                            fiche.journees[j].plages[p].debut,
                            fiche.journees[j].plages[p].fin
                        )
                        minutes += diff
                    }
                }
            }
        }

        if (fiche && fiche.autres) {
            for (const c in fiche.autres) {
                if (fiche.autres[c].total) {
                    minutes += fiche.autres[c].total
                }
            }
        }

        return totalFormatToHourMinutesHumanizer(minutes)
    },
    can_save_gabarit: (state: any) => {
        // Vérification qu'on est au moins une journée + Vérification de la présence d'erreurs
        // console.log('----state.errorGabaritFicheHoraireJournees.length : ' + state.errorGabaritFicheHoraireJournees.length)
        if (
            state.gabarit &&
            state.gabarit.journees &&
            state.gabarit.journees.length !== 0 &&
            state.errorGabaritFicheHoraireJournees.length === 0
        ) {
            return true
        } else {
            return false
        }
    },
    get_minutes_from_hours: () => (date_hours: any) => {
        const date_test = dateDuJour() + ' 00:00:00'
        const diff = diffTwoDatesInMinutes(date_test, date_hours)

        return diff
    }
}
