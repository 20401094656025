import { getError } from '@/utils/helpers'
import ParameterService from '@/services/ParameterService'

export const namespaced = true

export const state = {
    parameters: [],
    parameter: {},
    loading: false,
    error: null
}

export const mutations = {
    RESET_STATE(state: any): void {
        Object.assign(state, {
            parameters: [],
            parameter: {},
            loading: false,
            error: null
        })
    },
    SET_PARAMETERS(state: any, parameters: any): void {
        state.parameters = parameters
    },
    SET_PARAMETER(state: any, parameter: any): void {
        state.parameter = parameter
    },
    SET_LOADING(state: any, loading: boolean): void {
        state.loading = loading
    },
    SET_ERROR(state: any, error: any): void {
        state.error = error
    }
}

export const actions = {
    getAides({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            ParameterService.getAides(params)
                .then((response): void => {
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {
    parameters: (state: any): any => {
        return state.parameters
    },
    parameter: (state: any): any => {
        return state.parameter
    },
    loading: (state: any): boolean => {
        return state.loading
    },
    error: (state: any): any => {
        return state.error
    }
}
