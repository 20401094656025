export enum code_publishable {
    RESULTATS_ADMISSIBILITE = 'resultats_admissibilite',
    RESULTATS_ADMISSION = 'resultats_admission',
    RESULTATS_BANQUE_ADMISSIBILITE = 'resultats_banque_admissibilite',
    RESULTATS_BANQUE_ADMISSION = 'resultats_banque_admission',
    RECLAMATIONS_ECRITS = 'reclamations_ecrits',
    RECLAMATIONS_ORAUX = 'reclamations_oraux',
    RECLAMATIONS_POSTCONCOURS = 'reclamations_postconcours',
    DEMISSIONS_ADMISSION = 'demissions_admission',
    CONVOCATIONS_ADMISSION = 'informations_admission',
    PLANNING_ADMISSION = 'planning_admission',
    PLANNING_ADMISSION_SANS_TP = 'planning_admission_sans_tp',
    INFORMATIONS_ADMISSIBILITE = 'informations_admissibilite',
    PLANNING_ADMISSIBILITE = 'planning_admissibilite',
    DECISION_AMENAGEMENTS = 'decision_amenagements',
    DECISION_AMENAGEMENTS_ADMISSION = 'decision_amenagements_admission',
}

export enum display_group_publishable {
    RESULTATS = 'resultats',
    RECLAMATIONS = 'reclamations',
    ORGANISATION = 'organisation',
    INSCRIPTIONS = 'inscription',
    CHOIX_POSTE = 'choix_poste',
    COPIE = 'copie',
    BOURSE_EMPLOI = 'bourse_emploi'
}

export enum display_interface_publishable {
    RESULTATS_ADMISSIBILITE = 'resultats_admissibilite',
    RESULTATS_ADMISSION = 'resultats_admission',
    RECLAMATIONS_ECRIT = 'reclamations_ecrits',
    RECLAMATIONS_ORAUX = 'reclamations_oraux',
    RECLAMATIONS_POSTCONCOURS = 'reclamations_postconcours',
    DEMISSIONS_ADMISSION = 'demissions_admission',
    CONVOCATIONS_ADMISSION = 'convocations_admission',
    CONVOCATIONS_ADMISSIBILITE = 'convocations_admissibilite',
    COPIE = 'copie',
    BOURSE_EMPLOI = 'bourse_emploi'
}

export enum choix_poste {
    CHOIX_POSTE_PRIORITAIRE = 'choix_poste_prioritaire',
    CHOIX_POSTE_VOEUX = 'choix_poste_voeux',
    CHOIX_POSTE_DECISION = 'choix_poste_decision',
}

export interface Publishable {
    id: number | 0,
    code: string | '',
    name: string | '',
    display_group: string | '',
    display_interface: string | '',
    route: string | '',
    entity_type: string | '',
    entity_properties_to_check: EntityPropertiesToCheck,
    libelle_check: LibelleCheck,
    ordre: number | 0
}

export interface EntityPropertiesToCheck {
    validated: number | 0
}

export interface LibelleCheck {
    datas_ok: string | '',
    datas_ko: string | '',
    datas_erreur_transfert: string | ''
}

export function displayGroupPublishableToString(displayGroupPublishable: display_group_publishable): string {
    switch (displayGroupPublishable) {
        case display_group_publishable.RESULTATS:
            return 'Résultats'
        case display_group_publishable.RECLAMATIONS:
            return 'Réclamations'
        case display_group_publishable.ORGANISATION:
            return 'Organisation'
        default:
            return ''
    }
}
