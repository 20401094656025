import * as API from '@/services/API'
import store from '@/store'

export default {
    async getEnsembleAffectationsExaminateurs(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/ensembles_affectations_examinateurs`, {
            params: payload
        })
    },
    async getInfoTotauxAffectationsExaminateurs() {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/ensembles_affectations_examinateurs_compteurs`)
    },
    async getListeIntervenants(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/affectations_examinateurs`, { params: payload })
    },
    async updateAffectationsEnsemble(ensemble_id: number, numero: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(
            `/sessions/${session_id}/ensembles_affectations_examinateurs/${ensemble_id}/numero/${numero}`,
            payload
        )
    },
    async exportationAffectationsExaminateurs(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/ensembles_affectations_examinateurs_export`, {
            ...payload,
            headers: {
                ...payload.headers,
                'X-Request-Retry': 0
            }
        })
    }
}
