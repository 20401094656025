import { getError } from '@/utils/helpers'
import BanqueService from '@/services/BanqueService'
import { BanqueInterface, StateBanques } from '@/types/Banque'

export const namespaced = true
export const namespace = true

function setBanques(commit: any, response: any) {
    commit('SET_BANQUES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateBanques = {
    banques: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    banqueTemp: null
}

export const mutations = {
    RESET_STATE(state: StateBanques) {
        Object.assign(state, {
            banques: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            banqueTemp: null
        })
    },
    SET_BANQUE_TEMP(state: any, banque: BanqueInterface) {
        state.banqueTemp = banque
    },
    SET_BANQUES(state: any, banques: Array<BanqueInterface>) {
        state.banques = banques
    },
    SET_MORE_BANQUES(state: any, banques: Array<BanqueInterface>) {
        state.banques = state.banques.concat(banques)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    }
}

export const actions = {
    getBanques({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        commit('SET_BANQUES', [])
        return new Promise((resolve, reject) => {
            BanqueService.getBanques(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    setBanques(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreBanques({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            BanqueService.getBanques(params)
                .then((response) => {
                    commit('SET_MORE_BANQUES', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getBanquesActives({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            BanqueService.getBanquesActives()
                .then((response) => {
                    setBanques(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getBanque({ commit }: { commit: any }, id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            BanqueService.getBanque(id)
                .then((response) => {
                    commit('SET_BANQUE_TEMP', response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateBanque({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            BanqueService.updateBanque(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addBanque({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            BanqueService.addBanque(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteBanque({ commit }: { commit: any }, banque_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            BanqueService.deleteBanque(banque_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    reject(error)
                })
        })
    }
}

export const getters = {
    banques: (state: StateBanques) => {
        return state.banques
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.concours.length
    },
    getBanqueById: (state: StateBanques) => (id: number) => {
        return state.banques.find((b: any) => b.id === id)
    }
}
