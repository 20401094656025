import { getError } from '@/utils/helpers'
import CentreSujetService from '@/services/CentreSujetService'
import { CentreSujetInterface, StateCentreSujet } from '@/types/CentreSujet'
import _ from 'lodash'

export const namespaced = true

function setCentreSujets(commit: Function, response: any) {
    commit('SET_CENTRE_SUJETS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateCentreSujet = {
    centre_sujets: [],
    meta: null,
    links: null,
    loading_cs: false,
    error_cs: null,
    totalRows: 0,
    centre_sujet_select: {}
}

export const mutations = {
    RESET_STATE(state: StateCentreSujet) {
        Object.assign(state, {
            centre_epreuves: [],
            meta: null,
            links: null,
            loading_cs: false,
            error_cs: null,
            totalRows: 0,
            centre_epreuve_select: {}
        })
    },
    SET_CENTRE_SUJETS(state: any, centre_sujets: Array<CentreSujetInterface>) {
        state.centre_sujets = _.orderBy(centre_sujets, 'epreuve.sujet.code', 'asc')
    },
    SET_MORE_CENTRE_SUJETS(state: any, centre_sujets: Array<CentreSujetInterface>) {
        state.centre_sujets = state.centre_sujets.concat(centre_sujets)
        state.centre_sujets = _.orderBy(centre_sujets, 'epreuve.sujet.code', 'asc')
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading_cs = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error_cs = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_CENTRE_SUJET_SELECT(state: any, centre_sujet: CentreSujetInterface) {
        state.centre_sujet_select = centre_sujet
    }
}

export const actions = {
    getCentreSujets({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreSujetService.getCentreSujets(payload.centre_id, payload.params)
                .then((response) => {
                    setCentreSujets(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreCentreSujets({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreSujetService.getCentreSujets(payload.centre_id, payload.params)
                .then((response) => {
                    commit('SET_MORE_CENTRE_EPREUVES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    saveReceptionSujets({ commit }: { commit: any }, payload?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreSujetService.saveReceptionSujets(payload.centre_id, payload.params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPDF({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CentreSujetService.getPDF(payload.centre_id)
                .then((response) => {
                    commit('SET_PDF', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    centre_epreuves: (state: any) => {
        return state.centre_epreuves
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading_ce
    },
    error: (state: any) => {
        return state.error_ce
    },
    totalRows: (state: any) => {
        return state.sujets.length
    },
    centre_epreuve_select: (state: any) => {
        return state.centre_epreuve_select
    },
    get_nb_commande: () => (centre_sujet: any, params: any) => {
        let nb_commande = 0

        if (centre_sujet.nb_candidats !== 0) {
            // Add le nb de candidats
            nb_commande += centre_sujet.nb_candidats

            // Add la marge de sécurité
            let marge = (nb_commande * params.marge_securite) / 100

            // Check si le résultat de la marge est entre le min et le max
            // Si inférieur au min -> Marge = Min
            // Si supérieure au max -> Marge = Max
            if (marge < params.min_sujets && params.min_sujets !== 0) {
                marge = params.min_sujets
            }
            if (marge > params.max_sujets && params.max_sujets !== 0) {
                marge = params.max_sujets
            }

            nb_commande += marge

            // Add des sujets supplémentaires
            nb_commande += params.sujets_supp
        }

        return Math.ceil(nb_commande)
    }
}
