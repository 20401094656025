import { getError } from '@/utils/helpers'
import TempsSupplementaireService from '@/services/TempsSupplementaireService'
import { StateTempsSupplementaires, TempsSupplementaireInterface } from '@/types/TempsSupplementaire'

export const namespaced = true

function setTempsSupplementaires(commit: Function, response: any) {
    commit('SET_TEMPS_SUPPLEMENTAIRES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateTempsSupplementaires = {
    tempsSupplementaires: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    tempsSupplementaireSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateTempsSupplementaires) {
        Object.assign(state, {
            tempsSupplementaires: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            tempsSupplementaireSelect: {}
        })
    },
    SET_TEMPS_SUPPLEMENTAIRES(state: any, tempsSupplementaires: Array<TempsSupplementaireInterface>) {
        state.tempsSupplementaires = tempsSupplementaires
    },
    SET_MORE_TEMPS_SUPPLEMENTAIRES(state: any, tempsSupplementaires: Array<TempsSupplementaireInterface>) {
        state.tempsSupplementaires = state.tempsSupplementaires.concat(tempsSupplementaires)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_TEMPS_SUPPLEMENTAIRE_SELECT(state: any, tempsSupplementaire: TempsSupplementaireInterface) {
        state.tempsSupplementaireSelect = tempsSupplementaire
    }
}

export const actions = {
    getTempsSupplementaires({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            TempsSupplementaireService.getTempsSupplementaires(params)
                .then((response) => {
                    setTempsSupplementaires(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getTempsSupplementaire({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            TempsSupplementaireService.getTempsSupplementaire(params.tempsSupplementaire_id)
                .then((response) => {
                    commit('SET_TEMPS_SUPPLEMENTAIRE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreTempsSupplementaires({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            TempsSupplementaireService.getTempsSupplementaire(params.tempsSupplementaire_id)
                .then((response) => {
                    commit('SET_TEMPS_SUPPLEMENTAIRE_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateTempsSupplementaire({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            TempsSupplementaireService.updateTempsSupplementaire(params.tempsSupplementaire_id, params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addTempsSupplementaire({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            TempsSupplementaireService.addTempsSupplementaire(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteTempsSupplementaire({ commit }: { commit: any }, tempsSupplementaire_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            TempsSupplementaireService.deleteTempsSupplementaire(tempsSupplementaire_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    tempsSupplementaires: (state: any) => {
        state.tempsSupplementaires.sort((a: TempsSupplementaireInterface, b: TempsSupplementaireInterface) => {
            const codeA = a.code.toUpperCase() // ignore upper and lowercase
            const codeB = b.code.toUpperCase() // ignore upper and lowercase
            if (codeA < codeB) {
                return -1
            }
            if (codeA > codeB) {
                return 1
            }
            return 0
        })
        return state.tempsSupplementaires
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.tempsSupplementaires.length
    },
    tempsSupplementaireSelect: (state: any) => {
        return state.tempsSupplementaireSelect
    },
    getTempsSupplementaireById: (state: any) => (id: number) => {
        const tempsSupplementaire = state.tempsSupplementaires.filter((mat: any) => mat.id === id)[0]
        return tempsSupplementaire
    },
    getCodeTempsSupplementaire: (state: any) => (id: number) => {
        const tempsSupplementaire = state.tempsSupplementaires.filter((mat: any) => mat.id === id)[0]
        if (tempsSupplementaire) {
            return tempsSupplementaire.code
        } else {
            return 'NR'
        }
    },
    getTempsSupplementairesObs: (state: any) => (liste_tempsSupplementaire_obs: any) => {
        const tab_tempsSupplementaires: Array<any> = []
        for (const mo in liste_tempsSupplementaire_obs) {
            if (liste_tempsSupplementaire_obs[mo].entity_id) {
                const tempsSupplementaire_temp = state.tempsSupplementaires.find(
                    (m: any) => m.id === liste_tempsSupplementaire_obs[mo].entity_id
                )
                if (tempsSupplementaire_temp) {
                    tab_tempsSupplementaires.push(tempsSupplementaire_temp)
                }
            }
        }

        return tab_tempsSupplementaires
    }
}
