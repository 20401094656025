import { getError } from '@/utils/helpers'
import SujetService from '@/services/SujetService'
import { StateSujets, SujetInterface } from '@/types/Sujet'

export const namespaced = true

function setSujets(commit: Function, response: any) {
    commit('SET_SUJETS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

function setPrevisionnelSujets(commit: Function, response: any) {
    commit('SET_PREVISIONNEL_SUJETS', response.data.data)
    commit('SET_LOADING', false)
}

export const state: StateSujets = {
    sujets: [],
    previsionnelSujets: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    sujetSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateSujets) {
        Object.assign(state, {
            sujets: [],
            previsionnelSujets: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            sujetSelect: {}
        })
    },
    SET_SUJETS(state: any, sujets: Array<SujetInterface>) {
        state.sujets = sujets
    },
    SET_PREVISIONNEL_SUJETS(state: any, previsionnelSujets: Array<any>) {
        state.previsionnelSujets = previsionnelSujets
    },
    SET_MORE_SUJETS(state: any, sujets: Array<SujetInterface>) {
        state.sujets = state.sujets.concat(sujets)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_SUJET_SELECT(state: any, sujet: SujetInterface) {
        state.sujetSelect = sujet
    }
}

export const actions = {
    getSujets({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SujetService.getSujets(params)
                .then((response) => {
                    setSujets(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getSujet({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SujetService.getSujet(params.sujet_id)
                .then((response) => {
                    commit('SET_SUJET_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreSujets({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SujetService.getSujet(params.sujet_id)
                .then((response) => {
                    commit('SET_SUJET_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateSujet({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SujetService.updateSujet(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addSujet({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SujetService.addSujet(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteSujet({ commit }: { commit: any }, sujet_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SujetService.deleteSujet(sujet_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPrevisionnelSujets({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SujetService.getPrevisionnelSujets(params)
                .then((response) => {
                    setPrevisionnelSujets(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updatePrevisionnelSujets({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SujetService.updatePrevisionnelSujets(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateMargePrevisionnelSujets({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SujetService.updateMargePrevisionnelSujets(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    exportPrevisionnelSujets({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SujetService.exportPrevisionnelSujets()
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    saveMargeCommande({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            SujetService.saveMargeCommande(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPDFEtiquettesSujetsExport({ commit }: { commit: any }, payload?: any) {
        return new Promise((resolve, reject) => {
            SujetService.getPDFEtiquettesSujetsExport(payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPDFEtiquettesSujetsAmenagementsExport({ commit }: { commit: any }) {
        return new Promise((resolve, reject) => {
            SujetService.getPDFEtiquettesSujetsAmenagementExport()
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getEtiquettesSujetsExport({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            SujetService.getEtiquettesSujetsExport(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getEtiquettesCantinesExport({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SujetService.getEtiquettesCantinesExport()
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    sujets: (state: any) => {
        return state.sujets
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.sujets.length
    },
    sujetSelect: (state: any) => {
        return state.sujetSelect
    },
    getSujetById: (state: any) => (id: number) => {
        const sujet = state.sujets.filter((mat: any) => mat.id === id)[0]
        return sujet
    },
    getCodeSujet: (state: any) => (id: number) => {
        const sujet = state.sujets.filter((mat: any) => mat.id === id)[0]
        if (sujet) {
            return sujet.code
        } else {
            return 'NR'
        }
    },
    getSujetsObs: (state: any) => (liste_sujet_obs: any) => {
        const tab_sujets: Array<any> = []
        for (const mo in liste_sujet_obs) {
            if (liste_sujet_obs[mo].entity_id) {
                const sujet_temp = state.sujets.find((m: any) => m.id === liste_sujet_obs[mo].entity_id)
                if (sujet_temp) {
                    tab_sujets.push(sujet_temp)
                }
            }
        }

        return tab_sujets
    },
    previsionnelSujets: (state: any) => {
        return state.previsionnelSujets
    }
}
