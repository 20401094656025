// liste

import * as API from '@/services/API'
import store from '@/store/index'

export default {
    async getListe(listeId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/liste/${listeId}`)
    },
    async getListes(payload: any = null) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/listes`, { params: payload })
    },
    async updateListe(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/listes/${payload.id}`, payload)
    },
    async addListe(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/listes`, payload)
    },
    async deleteListe(listeId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/listes/${listeId}`)
    },
    async import(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const formData = new FormData()
        formData.append('document', payload)
        return await API.apiClient.post(`/sessions/${session_id}/liste/import`, formData) // , config)
    }
}
