































































































import { Ability } from '@/types/Ability'
import { formatDate } from '@/utils/helpers'
import _ from 'lodash'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { etat_structure } from '@/types/Session'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'isAdmin', 'can', 'cannot']),
        ...mapGetters('centre', ['centres', 'getNameCentreById']),
        ...mapGetters('session', ['sessions']),
        ...mapState('auth', ['maj', 'user_session_id', 'changepassword', 'acceptRGPD', 'verifyemail']),
        ...mapGetters('user', ['centreId']),
        Ability() {
            return Ability
        }
    }
})

export default class NavBarTop extends Vue {
    libelle_page = 'ACCUEIL'
    displayedTitle = ''
    maj_planified: string | undefined
    sessionsStructValide: Array<any> = []
    session_selectionnee = ''
    session_user_id = null
    is_intervenant = false
    loading_session = false

    centre_user_select_name = ''
    is_mobile_device = false

    @Prop() title?: any | ''

    @Watch('maj')
    maj_date_to_string() {
        this.maj_planified = ''
        if (this.$store.state.auth.maj && this.$store.state.auth.maj.planified_at) {
            this.maj_planified = ''
            this.maj_planified +=
                'Une maintenance est prévue pour le <strong>' +
                formatDate(this.$store.state.auth.maj.planified_at) +
                '</strong>.'
            // this.maj_planified += ' Nous vous prions de bien vouloir nous excuser pour la gêne occasionnée.'
            if (this.$store.state.auth.maj.end_at) {
                this.maj_planified +=
                    ' Votre plateforme sera de nouveau accessible à partir du <strong>' +
                    formatDate(this.$store.state.auth.maj.end_at) +
                    '</strong>.'
            }
        }
    }

    @Watch('centreId', { immediate: true })
    wCentreId() {
        this.centre_user_select_name = this.$store.getters['centre/getNameCentreById'](
            this.$store.getters['user/centreId']
        )
    }

    @Watch('centres')
    loadNameCentre() {
        // Récupère le nom du centre si un intervenant à un centreId sélectionné
        if (this.$store.getters['user/centreId']) {
            this.centre_user_select_name = this.$store.getters['centre/getNameCentreById'](
                this.$store.getters['user/centreId']
            )
        }
    }

    @Watch('user_session_id', { immediate: true, deep: true })
    @Watch('authUser')
    // @Watch('changepassword')
    @Watch('acceptRGPD')
    // @Watch('verifyemail')
    setSessions() {
        // Système de sélection de session aprés vérification de l'initialisation du compte
        if (
            this.$store.getters['auth/authUser'] !== null &&
            this.$store.getters['auth/isPasswordChanged'] &&
            this.$store.getters['auth/isEmailVerified'] &&
            this.$store.getters['auth/isAcceptedRGPD']
        ) {
            // Seulement pour les gestionnaires
            if (
                !this.$store.state.session.loading &&
                (this.$store.state.session.sessions === null || this.$store.state.session.sessions.length === 0)
            ) {
                this.$store.dispatch('session/getSessions').then(() => {
                    this.setSessionsSuite()
                })
            } else if (!this.$store.state.session.loading) {
                this.setSessionsSuite()
            }
        }
    }

    setSessionsSuite() {
        if (
            this.$store.getters['auth/authUser'].abilities.filter((a: any) => a.name.indexOf('own') !== -1).length === 0
        ) {
            this.is_intervenant = false
            // Récupère la liste des sessions
            // recuperation des sessions avec une structure valide
            this.sessionsStructValide = _.orderBy(this.$store.state.session.sessions, 'id', 'desc').filter(
                (session) => session.structure_valide === etat_structure.VALIDEE
            )
            // Si on a deja une session enregistrée, on la récupère du store
            if (window.sessionStorage && window.sessionStorage.user_session_id) {
                this.$store.state.auth.user_session_id = Number(window.sessionStorage.user_session_id)
            } else {
                // ... sinon on prend la première session de la liste
                if (this.sessionsStructValide && this.sessionsStructValide[0]) {
                    this.$store.state.auth.user_session_id = this.sessionsStructValide[0].id
                    window.sessionStorage.setItem('user_session_id', this.sessionsStructValide[0].id)
                } else {
                    this.$store.state.auth.user_session_id = this.$store.state.session.sessions[0].id
                    window.sessionStorage.setItem('user_session_id', this.$store.state.session.sessions[0].id)
                    this.$router.push('/sessions')
                }
            }

            // Maj le libellé
            const sessionTemp = this.sessionsStructValide.find(
                (s: any) => s.id === this.$store.state.auth.user_session_id
            )
            if (sessionTemp) {
                this.session_selectionnee = sessionTemp.name
                this.$store.commit('session/SET_SESSION_SELECT', sessionTemp)
            } else {
                if (this.$route.path.includes('/session/') || this.$route.path.includes('/editconcour/')) {
                    this.session_selectionnee = 'Initialisation'
                }
            }

            this.session_user_id = this.$store.state.auth.user_session_id
            // })
        } else {
            // this.$store.dispatch('session/getSessions').then(() => {
            this.is_intervenant = true
            // Récupère les sessions auxquelles l'intervenant a droit
            const sessions_intervenants = this.$store.getters['session/get_sessions_intervenants'](
                this.$store.state.auth.user.abilities
            )
            // Si on a plus d'une session, on affiche le popup de sélection
            if (sessions_intervenants.length > 1 && this.$store.state.auth.user_session_id === null) {
                this.$store.commit('auth/SET_MULTI_SESSION', true)
            } else if (this.$store.state.auth.user_session_id === null) {
                // On considère l'unique session comme la session active pour l'intervenant
                const sessions = _.orderBy(sessions_intervenants, 'id', 'desc')
                this.$store.state.auth.user_session_id = sessions[0].id
                window.sessionStorage.setItem('user_session_id', sessions[0].id)

                // Maj le libellé
                this.session_selectionnee = sessions.find(
                    (s: any) => s.id === this.$store.state.auth.user_session_id
                ).name
                this.$store.commit(
                    'session/SET_SESSION_SELECT',
                    sessions.find((s: any) => s.id === this.$store.state.auth.user_session_id)
                )

                this.session_user_id = this.$store.state.auth.user_session_id
            }
        }
    }

    @Watch('sessions', { immediate: true, deep: true })
    wSessions() {
        this.sessionsStructValide = _.orderBy(this.$store.state.session.sessions, 'id', 'desc').filter(
            (session) => session.structure_valide === etat_structure.VALIDEE
        )
    }

    stop(e: any) {
        e.stopPropagation()
    }

    // Récupère et stocke le choix user sur la session sélectionnée
    select_user_session_id(e: any) {
        window.sessionStorage.setItem('user_session_id', e.target.value)
        window.location.href = '/dashboard'
    }

    // Déconnexion
    logout() {
        this.$store.dispatch('auth/logout').then(() => {
            this.$store.dispatch('reset')
        })
    }

    routeToDashboard() {
        const authUser = this.$store.getters['auth/authUser']
        const userId = this.$store.getters['auth/authUser'].id
        if (authUser) {
            if (
                localStorage &&
                localStorage['dernierDashboardUtilise' + userId] &&
                typeof localStorage['dernierDashboardUtilise' + userId] === 'string' &&
                localStorage['dernierDashboardUtilise' + userId] !== ''
            ) {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                this.$router.push(localStorage['dernierDashboardUtilise' + userId]).catch(() => {})
            }
            /*  controle des droits et redirection en fonction si pas de local Storage */
            // redirection pour les RH
            else if (
                this.$store.getters['auth/can'](Ability.RH_SPS_VIEW) ||
                this.$store.getters['auth/can'](Ability.RH_DOSADM_VIEW) ||
                this.$store.getters['auth/can'](Ability.RH_CONTRAT_VIEW) ||
                this.$store.getters['auth/can'](Ability.RH_FRAIS_VIEW) ||
                this.$store.getters['auth/can'](Ability.GC_CENTER_VIEW) ||
                this.$store.getters['auth/can'](Ability.RH_SOL_VIEW) ||
                this.$store.getters['auth/can'](Ability.GC_CONVENTION_VIEW)
            ) {
                this.$router.push('/dashboard').catch(() => {
                    console.log('')
                })
            }
            // redirection pour Oraux
            else if (
                this.$store.getters['auth/can'](Ability.ORAL_AFEX_VIEW) ||
                this.$store.getters['auth/can'](Ability.ORAL_PREPA_VIEW) ||
                this.$store.getters['auth/can'](Ability.ORAL_PLAN_VIEW) ||
                this.$store.getters['auth/can'](Ability.CAND_VIEW) ||
                this.$store.getters['auth/can'](Ability.GC_CENTER_VIEW)
            ) {
                this.$router.push('/dashboardOraux').catch(() => {
                    console.log('')
                })
            }
            // redirection concours
            else if (
                this.$store.getters['auth/can'](Ability.CAND_VIEW) ||
                this.$store.getters['auth/can'](Ability.DLB_PREPJURY_VIEW) ||
                this.$store.getters['auth/can'](Ability.SIGN_CONTRAT_VIEW) ||
                this.$store.getters['auth/can'](Ability.SIGN_PVA_VIEW) ||
                this.$store.getters['auth/can'](Ability.SIGN_LETTRE_VIEW) ||
                this.$store.getters['auth/can'](Ability.SIGN_CONVENTION_VIEW) ||
                this.$store.getters['auth/can'](Ability.SIGN_CONVENTIONANNEXE_VIEW) ||
                this.$store.getters['auth/can'](Ability.SIGN_FHRECAP_VIEW) ||
                this.$store.getters['auth/can'](Ability.SIGN_RECLAMATION_VIEW) ||
                this.$store.getters['auth/can'](Ability.SIGN_AVENANT_VIEW) ||
                this.$store.getters['auth/can'](Ability.SIGN_AMENAGEMENT_VIEW)
            ) {
                this.$router.push('/dashboardConcours').catch(() => {
                    console.log('')
                })
            }
            // redirection si pas de resultat precedent
            else {
                this.$router.push('/dashboard').catch(() => {
                    console.log('')
                })
            }
        } else {
            this.$router.push('/dashboard').catch(() => {
                console.log('')
            })
        }
    }

    mounted() {
        // Detection si le device est un mobile
        this.is_mobile_device = 'ontouchstart' in document.documentElement
    }
}
