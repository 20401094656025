import { getError } from '@/utils/helpers'
import GestionParticuliereService from '@/services/GestionParticuliereService'
import { GestionParticuliereInterface, StateGestionParticulieres } from '@/types/GestionParticuliere'

export const namespaced = true

function setGestionParticulieres(commit: Function, response: any) {
    commit('SET_GESTION_PARTICUILERES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateGestionParticulieres = {
    gestionParticulieres: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    gestionParticuliereSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateGestionParticulieres) {
        Object.assign(state, {
            gestionParticulieres: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            gestionParticuliereSelect: {}
        })
    },
    SET_GESTION_PARTICUILERES(state: any, gestionParticulieres: Array<GestionParticuliereInterface>) {
        state.gestionParticulieres = gestionParticulieres
    },
    SET_MORE_GESTION_PARTICUILERES(state: any, gestionParticulieres: Array<GestionParticuliereInterface>) {
        state.gestionParticulieres = state.gestionParticulieres.concat(gestionParticulieres)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_GESTION_PARTICUILERE_SELECT(state: any, gestionParticuliere: GestionParticuliereInterface) {
        state.gestionParticuliereSelect = gestionParticuliere
    }
}

export const actions = {
    getGestionParticulieres({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GestionParticuliereService.getGestionParticulieres(params)
                .then((response) => {
                    setGestionParticulieres(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getGestionParticuliere({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GestionParticuliereService.getGestionParticuliere(params.gestionParticuliere_id)
                .then((response) => {
                    commit('SET_GESTION_PARTICUILERE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreGestionParticulieres({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GestionParticuliereService.getGestionParticuliere(params.gestionParticuliere_id)
                .then((response) => {
                    commit('SET_GESTION_PARTICUILERE_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateGestionParticuliere({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GestionParticuliereService.updateGestionParticuliere(params.gestionParticuliere_id, params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addGestionParticuliere({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GestionParticuliereService.addGestionParticuliere(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteGestionParticuliere({ commit }: { commit: any }, gestionParticuliere_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GestionParticuliereService.deleteGestionParticuliere(gestionParticuliere_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    gestionParticulieres: (state: any) => {
        return state.gestionParticulieres
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.gestionParticulieres.length
    },
    gestionParticuliereSelect: (state: any) => {
        return state.gestionParticuliereSelect
    },
    getGestionParticuliereById: (state: any) => (id: number) => {
        const gestionParticuliere = state.gestionParticulieres.filter((mat: any) => mat.id === id)[0]
        return gestionParticuliere
    },
    getCodeGestionParticuliere: (state: any) => (id: number) => {
        const gestionParticuliere = state.gestionParticulieres.filter((mat: any) => mat.id === id)[0]
        if (gestionParticuliere) {
            return gestionParticuliere.code
        } else {
            return 'NR'
        }
    },
    getGestionParticulieresObs: (state: any) => (liste_gestionParticuliere_obs: any) => {
        const tab_gestionParticulieres: Array<any> = []
        for (const mo in liste_gestionParticuliere_obs) {
            if (liste_gestionParticuliere_obs[mo].entity_id) {
                const gestionParticuliere_temp = state.gestionParticulieres.find(
                    (m: any) => m.id === liste_gestionParticuliere_obs[mo].entity_id
                )
                if (gestionParticuliere_temp) {
                    tab_gestionParticulieres.push(gestionParticuliere_temp)
                }
            }
        }

        return tab_gestionParticulieres
    }
}
