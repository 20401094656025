import * as API from '@/services/API'
import store from '@/store/index'

export default {
    getAvenantTypes(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/avenanttypes`, { params: params })
    },
    getAvenantType(avenant_type_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/avenanttypes/${avenant_type_id}`)
    },
    saveAvenantType(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/avenanttypes`, payload)
    },
    updateAvenantType(avenant_type_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/avenanttypes/${avenant_type_id}`, payload)
    },
    deleteAvenantType(avenant_type_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/avenanttypes/${avenant_type_id}`)
    },
    getPDF(avenant_type_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/avenanttypes/${avenant_type_id}/pdf?format=b64`, {headers: { 'X-Request-Retry': 0 }})
    }
}
