import * as API from '@/services/API'
import store from '@/store'

export default {
    async getAjustement(ajustementId: number) {
        return await API.apiClient.get(`/ajustements/${ajustementId}`)
    },
    async getAjustements(payload: any) {
        return await API.apiClient.get('/ajustements', { params: payload })
    },
    async updateAjustement(payload: any) {
        return await API.apiClient.put(`/ajustements/${payload.id}`, payload)
    },
    async addAjustement(payload: any) {
        return await API.apiClient.post('/ajustements', payload)
    },
    async deleteAjustement(ajustement_id: number) {
        return await API.apiClient.delete(`/ajustements/${ajustement_id}`)
    },
    simulateSeuil(epreuve_correction_id: number, adjustment: string, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/epreuvecorrections/${epreuve_correction_id}/adjustments/${adjustment}/simulate`, payload)
    }
}
