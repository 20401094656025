import EpreuveService from '@/services/EpreuveService'
import { EpreuveInterface, PvInterface, StateEpreuves, TypePassation } from '@/types/Epreuve'
import { getError } from '@/utils/helpers'
import _ from 'lodash'

export const namespaced = true

function setEpreuves(commit: any, response: any) {
    commit('SET_EPREUVES', response.data.data)
}

function setEpreuvesCorrections(commit: any, response: any) {
    commit('SET_EPREUVES_CORRECTIONS', response.data.data)
}

function setEpreuvesCorrectionsPrecedente(commit: any, response: any) {
    commit('SET_EPREUVES_CORRECTIONS_PRECEDENTE', response.data.data)
}

export const state: StateEpreuves = {
    epreuves: [],
    epreuvesCorrections: [],
    epreuvesCorrectionsPrecedent: [],
    byMatieres: [],
    matieres: [],
    pvs: [],
    postes: null,
    besoinCourant: null,
    loading: false,
    error: null,
    meta: null,
    links: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    sortby: '',
    typeEpreuves: []
}

export const mutations = {
    RESET_STATE(state: StateEpreuves) {
        Object.assign(state, {
            epreuves: [],
            epreuvesCorrections: [],
            epreuvesCorrectionsPrecedent: [],
            byMatieres: [],
            matieres: [],
            pvs: [],
            postes: null,
            besoinCourant: null,
            loading: false,
            error: null,
            meta: null,
            links: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            sortby: '',
            typeEpreuves: []
        })
    },
    SET_EPREUVES(state: any, epreuves: Array<EpreuveInterface>) {
        state.epreuves = epreuves
    },
    SET_EPREUVES_CORRECTIONS(state: any, epreuves: Array<EpreuveInterface>) {
        state.epreuvesCorrections = epreuves
    },
    SET_EPREUVES_CORRECTIONS_PRECEDENTE(state: any, epreuves: Array<EpreuveInterface>) {
        state.epreuvesCorrectionsPrecedent = epreuves
    },
    SET_PVS(state: any, pvs: Array<PvInterface>) {
        state.pvs = pvs
        for (let i = 0; i < state.pvs.length; i++) {
            if (state.postes != null || !state.postes[state.pvs[i].poste.regroupement]) {
                state.postes[state.pvs[i].poste.regroupement] = state.pvs[i].poste.regroupement
            }
        }
    },
    SET_BESOIN_COURANT(state: any, besoin: any) {
        state.besoinCourant = besoin
    },
    BUILD_BY_MATIERES(state: StateEpreuves) {
        const byMatieresTemp: {
            [matiere_id: number]: {
                matiere_id: number,
                session_id: number,
                matiere_name: string,
                epreuves: Array<EpreuveInterface>
            }
        } = {}
        for (let i = 0; i < state.epreuvesCorrections.length; i++) {
            if (byMatieresTemp[state.epreuvesCorrections[i].matiere_id] == null) {
                byMatieresTemp[state.epreuvesCorrections[i].matiere_id] = {
                    matiere_id: state.epreuvesCorrections[i].matiere_id,
                    session_id: state.epreuvesCorrections[i].session_id,
                    matiere_name: state.epreuvesCorrections[i].matiere
                        ? state.epreuvesCorrections[i].matiere.name
                        : '-',
                    epreuves: []
                }
                byMatieresTemp[state.epreuvesCorrections[i].matiere_id].epreuves = []
            }
            ;(byMatieresTemp[state.epreuvesCorrections[i].matiere_id].epreuves as Array<any>).push(
                state.epreuvesCorrections[i]
            )
        }
        const byMatieres: Array<any> = []
        const matieres: Array<any> = []
        for (const matiere_id in byMatieresTemp) {
            // byMatieres.push(byMatieresTemp[matiere_id])
            byMatieres.push({
                isMatiere: true,
                isSuppl: false,
                matiere_id: byMatieresTemp[matiere_id].matiere_id,
                session_id: byMatieresTemp[matiere_id].session_id,
                matiere_name: byMatieresTemp[matiere_id].matiere_name,
                nbEpreuves: byMatieresTemp[matiere_id].epreuves.length - 1
            })
            matieres.push({
                matiere_id: byMatieresTemp[matiere_id].matiere_id,
                session_id: byMatieresTemp[matiere_id].session_id,
                matiere_name: byMatieresTemp[matiere_id].matiere_name,
                nbEpreuves: byMatieresTemp[matiere_id].epreuves.length - 1,
                correcteurs: {
                    nb: 0,
                    total: 0,
                    diffSessPrec: 0
                },
                examinateurs: {
                    nb: 0,
                    total: 0,
                    diffSessPrec: 0
                },
                avancemmentPct: 0,
                intervManquant: 0,
                etatAvancementPv: 0,
                avancementEnvoiLettre: {
                    nb: 0,
                    total: 0
                }
            })
            for (let j = 0; j < byMatieresTemp[matiere_id].epreuves.length; j++) {
                byMatieres.push({ isMatiere: false, isSuppl: false, epreuve: byMatieresTemp[matiere_id].epreuves[j] })
            }
            byMatieres.push({ isMatiere: false, isSuppl: true, nbSuppl: 0 })
        }
        state.matieres = matieres
        state.byMatieres = byMatieres
    },
    SET_BY_MATIERES(state: any, byMatieres: Array<any>) {
        state.byMatieres = byMatieres
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_SORTBY(state: any, params: string) {
        state.sortby = params
    },
    SET_TYPE_EPREUVES(state: StateEpreuves, data: any) {
        state.typeEpreuves = data
    }
}

export const actions = {
    getEpreuvesCorrections({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EpreuveService.getEpreuvesCorrections(params) // page, sortby)
                .then((response) => {
                    if (params && params.isPrecedente) {
                        setEpreuvesCorrectionsPrecedente(commit, response)
                        commit('SET_LOADING', false)
                        commit('BUILD_BY_MATIERES', state)
                    } else {
                        setEpreuvesCorrections(commit, response)
                        commit('SET_LOADING', false)
                        commit('BUILD_BY_MATIERES', state)
                    }
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getEpreuves({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        commit('SET_EPREUVES', [])
        return new Promise((resolve, reject) => {
            EpreuveService.getEpreuves(params) // page, sortby)
                .then((response) => {
                    if (!params?.isPrecedente) {
                        setEpreuves(commit, response)
                        commit('SET_LOADING', false)
                        resolve(response)
                        // commit('BUILD_BY_MATIERES', state)
                    }
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getEpreuve({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EpreuveService.getEpreuve(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getTypeEpreuves({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        commit('SET_TYPE_EPREUVES', [])
        return new Promise((resolve, reject) => {
            EpreuveService.getTypeEpreuves() // page, sortby)
                .then((response) => {
                    if (!params.isPrecedente) {
                        commit('SET_TYPE_EPREUVES', response.data.data)
                        commit('SET_LOADING', false)
                        resolve(response)
                        // commit('BUILD_BY_MATIERES', state)
                    }
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getConcoursGroupeEpreuves({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        commit('SET_EPREUVES', [])
        return new Promise((resolve, reject) => {
            EpreuveService.getConcoursGroupeEpreuves(params) // page, sortby)
                .then((response) => {
                    if (!params.isPrecedente) {
                        // setEpreuves(commit, response)
                        commit('SET_LOADING', false)
                        resolve(response)
                        // commit('BUILD_BY_MATIERES', state)
                    }
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addEpreuve({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EpreuveService.addEpreuve(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateEpreuve({ commit }: { commit: any }, epreuve: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EpreuveService.updateEpreuve(epreuve)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateEpreuveCalendrier({ commit }: { commit: any }, epreuve: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EpreuveService.updateEpreuveCalendrier(epreuve)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateEpreuveCorrection({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EpreuveService.updateEpreuveCorrection(payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteEpreuve({ commit }: { commit: any }, epreuveId: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EpreuveService.deleteEpreuve(epreuveId)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addTypeEpreuve({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EpreuveService.addTypeEpreuve(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateTypeEpreuve({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EpreuveService.updateTypeEpreuve(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteTypeEpreuve({ commit }: { commit: any }, typeEpreuveId: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EpreuveService.deleteTypeEpreuve(typeEpreuveId)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    exportCalendrierEpreuves({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EpreuveService.exportCalendrierEpreuves()
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateEpreuveCorrectionPlacesPrevues({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EpreuveService.updateEpreuveCorrectionPlacesPrevues(payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    loading: (state: any) => {
        return state.loading
    },
    epreuves: (state: any): Array<EpreuveInterface> => {
        return state.epreuves
    },
    epreuvesCorrections: (state: any): Array<EpreuveInterface> => {
        return state.epreuvesCorrections
    },
    epreuvesCorrectionsPrecedent: (state: any): Array<EpreuveInterface> => {
        return state.epreuvesCorrectionsPrecedent
    },
    byMatieres: (state: any) => {
        return state.byMatieres
    },
    matieres: (state: any) => {
        return state.matieres
    },
    pvs: (state: any) => {
        return state.pvs
    },
    besoinCourant: (state: any) => {
        return state.besoinCourant
    },
    error: (state: any) => {
        return state.error
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    totalRows: (state: any) => {
        return state.epreuves.length
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.epreuves.length
    },
    sortby: (state: any) => {
        return state.sortby
    },
    getEpreuveById: (state: any) => (id: number): EpreuveInterface => {
        const epreuve = state.epreuves.filter((epreu: any) => epreu.id === id)[0]
        return epreuve
    },
    getEpreuveCorrectionById: (state: any) => (id: number): EpreuveInterface => {
        const epreuve = state.epreuvesCorrections.filter((epreu: any) => epreu.id === id)[0]
        return epreuve
    },
    getFirstChildById: (state: any) => (id: number): EpreuveInterface => {
        const epreuve = state.epreuves.filter((epreu: any) => epreu.id_epreuve_maitresse === id)[0]
        return epreuve
    },
    getEstimationNbCandidatsOfChildById: (state: any) => (id: number) => {
        let nb_cands = 0
        const epreuves = state.epreuves.filter((epreu: any) => epreu.id_epreuve_maitresse === id)
        for (let i = 0; i < epreuves.length; i++) {
            nb_cands += epreuves[i].estimation_nb_candidats
        }
        return nb_cands
    },
    getStringFiliereOfEpreuveCorrectionById: (state: any) => (id: number) => {
        const epreuve = state.epreuvesCorrections.filter((epreu: any) => epreu.id === id)[0]
        return epreuve?.concours
            ?.map((concour: any) => concour.name)
            .join(', ') || ''
    },
    getEpreuveCorrectionByMatiereId: (state: any) => (matiere_id: number) => {
        return state.epreuvesCorrections.filter((epreu: any) => epreu.matiere_id === matiere_id)
    },
    getEpreuveByTypePassation: (state: any) => (typePassation: number): EpreuveInterface => {
        return state.epreuves.filter((epreu: any) => epreu.type_passation === typePassation)
    },
    getEpreuveCorrectionByTypePassation: (state: any) => (typePassation: number) => {
        return state.epreuvesCorrections.filter((epreu: any) => epreu.type_passation === typePassation)
    },
    getEpreuveCorrectionByTypePassationAndMatiereId: (state: any) => (typePassation: number, matiere_id: number) => {
        return state.epreuvesCorrections.filter(
            (epreu: any) => epreu.type_passation === typePassation && epreu.matiere_id === matiere_id
        )
    },
    getEpreuveCorrectionByTypePassationAndConcourId: (state: any) => (typePassation: number, concour_id: number) => {
        return state.epreuvesCorrections.filter(
            (epreu: any) => epreu.type_passation === typePassation && epreu.concours[0].id === concour_id
        )
    },
    epreuveOraux: (state: any): Array<EpreuveInterface> => {
        const epreuves = state.epreuves.filter((epreu: any) => {
            return epreu.type_passation === TypePassation.TYPE_PASSATION_ORAL
        })
        return epreuves
    },
    hasEpreuveTp: (state: any) => (inscriptions: any) => {
        let result = false
        for (const i in inscriptions) {
            const epreuve_test = state.epreuvesCorrections.find((ep: any) => ep.id === inscriptions[i].id)

            if (epreuve_test && epreuve_test.type_passation === TypePassation.TYPE_PASSATION_TP) {
                result = true
            }
        }

        return result
    },
    getEpreuvesDelibCandidat: (state: any) => (concours: any) => {
        let epreuves_deliberation = []
        for (const c in concours) {
            const concour = concours[c]
            for (const e in concour.epreuves_deliberation) {
                const epreuveSearch = state.epreuves.find(
                    (ep: any) => concour.epreuves_deliberation[e].epreuve_id === ep.id
                )
                if (epreuveSearch) {
                    epreuves_deliberation.push(epreuveSearch)
                }
            }
        }
        epreuves_deliberation = [...new Set(epreuves_deliberation)]
        return epreuves_deliberation
    },
    orderedEpreuves: () => (epreuves: any): Array<EpreuveInterface> => {
        return _.orderBy(epreuves, 'ordre', 'asc')
    },
    epreuves_corrections_filter: (state: any) => (filters: any): Array<EpreuveInterface> => {
        const epreuves_filter = []

        for (const f in filters) {
            const ep_filtre = state.epreuvesCorrections.filter((ep: any) => ep.type_passation === filters[f])

            for (const e in ep_filtre) {
                epreuves_filter.push(ep_filtre[e])
            }
        }

        return epreuves_filter
    },
    getNameEpreuveById: (state: any) => (id: number) => {
        const epreuve = state.epreuves.filter((epr: any) => epr.id === id)[0]
        return epreuve.name
    },
    getEpreuvePhase: (state: any) => (inscriptions: any) => {
        const tab_datas = []
        for (const i in inscriptions) {
            const epreuve_temp = state.epreuves.find((ep: any) => ep.id === inscriptions[i].epreuve_id)

            if (epreuve_temp && epreuve_temp.type_passation === TypePassation.TYPE_PASSATION_ECRIT) {
                tab_datas.push(epreuve_temp)
            }
        }

        return tab_datas
    },
    getEpreuvePhase_ORAL: (state: any) => (inscriptions: any) => {
        const tab_datas = []
        for (const i in inscriptions) {
            const epreuve_temp = state.epreuves.find((ep: any) => ep.id === inscriptions[i].epreuve_id)

            if (
                (epreuve_temp && epreuve_temp.type_passation === TypePassation.TYPE_PASSATION_ORAL) ||
                (epreuve_temp && epreuve_temp.type_passation === TypePassation.TYPE_PASSATION_TP)
            ) {
                tab_datas.push(epreuve_temp)
            }
        }

        return tab_datas
    },
    getEpreuvePhase_POST_CONCOURS: (state: any) => (inscriptions: any) => {
        const tab_datas = []
        for (const i in inscriptions) {
            const epreuve_temp = state.epreuves.find((ep: any) => ep.id === inscriptions[i].epreuve_id)
            if (
                (epreuve_temp && epreuve_temp.type_passation === TypePassation.TYPE_PASSATION_ORAL) ||
                (epreuve_temp && epreuve_temp.type_passation === TypePassation.TYPE_PASSATION_TP)
            ) {
                tab_datas.push(epreuve_temp)
            }
        }

        return tab_datas
    },
    getEpreuvePhase_with_id: (state: any) => (inscriptions: any, phase_id: number) => {
        const tab_datas = []
        for (const i in inscriptions) {
            const epreuve_temp = state.epreuves.find((ep: any) => ep.id === inscriptions[i].epreuve_id)

            if (
                epreuve_temp &&
                epreuve_temp.phase_id === phase_id &&
                epreuve_temp.type_passation !== TypePassation.TYPE_EPREUVE_MAITRESSE
            ) {
                tab_datas.push(epreuve_temp)
            }
        }

        return tab_datas
    },
    getEpreuveByConcour_id: (state: any) => (concour_id: number) => {
        const result = state.epreuves.filter((epreu: any) => epreu.concour_id === concour_id)
        return result
    },
    typeEpreuves: (state: StateEpreuves) => {
        return state.typeEpreuves
    },
    typeEpreuveById: (state: StateEpreuves) => (typeepreuve_id: number) => {
        const result = state.typeEpreuves.filter((typeepreuve: any) => typeepreuve.id === typeepreuve_id)
        return result
    }
}
