export const namespaced = true

export const state: any = {
    nTempImagesLoaded: 0,
    nTempImagesTotal: 0,
    nTempImagesImgArray: [],
    rootFileExportName: 'Graphe',
    currentChartNbTotalOutliers: [],
    arrayChartOutliers: []
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            nTempImagesLoaded: 0,
            nTempImagesTotal: 0,
            nTempImagesImgArray: [],
            rootFileExportName: 'Graphe',
            arrayChartOutliersTotal: [],
            arrayChartOutliersDetail: []
        })
    },
    SET_NTEMP_IMAGES_LOADED(state: any, nTempImagesLoaded: number) {
        state.nTempImagesLoaded = nTempImagesLoaded
    },
    SET_NTEMP_IMAGES_TOTAL(state: any, nTempImagesTotal: number) {
        state.nTempImagesTotal = nTempImagesTotal
    },
    SET_NTEMP_IMAGES_IMG_ARRAY(state: any, nTempImagesImgArray: number) {
        state.nTempImagesImgArray = nTempImagesImgArray
    },
    SET_ROOT_FILE_EXPORT_NAME(state: any, rootFileExportName: number) {
        state.rootFileExportName = rootFileExportName
    },
    REINIT_ARRAYS_CHART_OUTLIERS(state: any) {
        state.arrayChartOutliersTotal = []
        state.arrayChartOutliersDetail = []
    }
}

export const actions = {
    //
}

export const getters = {
    nTempImagesLoaded(state: any) {
        return state.nTempImagesLoaded
    },
    nTempImagesTotal(state: any) {
        return state.nTempImagesTotal
    },
    rootFileExportName(state: any) {
        return state.rootFileExportName
    },
    nTempImagesImgArray(state: any) {
        return state.nTempImagesImgArray
    },
    arrayChartOutliersTotal(state: any) {
        return state.arrayChartOutliersTotal
    },
    arrayChartOutliersDetail(state: any) {
        return state.arrayChartOutliersDetail
    }
}

// Fonction qui permet de gérer l'incrément du tableau de compteur détaillé des outliers
export function incrementArrayChartOutliersDetail(refChart: string, outlierValue: any) {
    if (!state.arrayChartOutliersDetail[refChart]) {
        state.arrayChartOutliersDetail[refChart] = {}
        state.arrayChartOutliersTotal[refChart] = 1
    } else {
        state.arrayChartOutliersTotal[refChart] = state.arrayChartOutliersTotal[refChart] + 1
    }
    if (state.arrayChartOutliersDetail[refChart][outlierValue]) {
        state.arrayChartOutliersDetail[refChart][outlierValue] = state.arrayChartOutliersDetail[refChart][outlierValue] + 1
    } else {
        state.arrayChartOutliersDetail[refChart][outlierValue] = 1
    }
}

// Fonction qui permet de gérer l'incrément du compteur du nbr d'outliers du graphique
export function getCurrentNbOfCurrentOutliers(refChart: string, outlierValue: any) {
    let outlierNumber = 0
    if (state.arrayChartOutliersDetail[refChart] && state.arrayChartOutliersDetail[refChart][outlierValue]) {
        outlierNumber = state.arrayChartOutliersDetail[refChart][outlierValue]
    }
    return outlierNumber
}
