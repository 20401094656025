import WorkingEstablishmentService from '@/services/WorkingEstablishmentService'
import { StateWorkingEstablishments, WorkingEstablishmentInterface } from '@/types/WorkingEstablishment'
import { getError } from '@/utils/helpers'
import Vue from 'vue'

export const namespaced = true

function setWorkingEstablishments(commit: Function, response: any) {
    commit('SET_WORKING_ESTABLISHMENTS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
}

export const state: StateWorkingEstablishments = {
    workingEstablishments: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    workingEstablishmentSelect: {},
    currentPage: 0,
    lastPage: 0,
    skipList: false
}

export const mutations = {
    RESET_STATE(state: StateWorkingEstablishments) {
        Object.assign(state, {
            workingEstablishments: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            workingEstablishmentSelect: {},
            currentPage: 0,
            lastPage: 0,
            skipList: false
        })
    },
    SET_WORKING_ESTABLISHMENTS(state: any, workingEstablishments: Array<WorkingEstablishmentInterface>) {
        state.workingEstablishments = workingEstablishments
    },
    SET_MORE_WORKING_ESTABLISHMENTS(state: any, workingEstablishments: Array<WorkingEstablishmentInterface>) {
        state.workingEstablishments = state.workingEstablishments.concat(workingEstablishments)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_WORKING_ESTABLISHMENT_SELECT(state: any, workingEstablishment: WorkingEstablishmentInterface) {
        state.workingEstablishmentSelect = workingEstablishment
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    ADD_ETAB(state: any, etab: WorkingEstablishmentInterface) {
        state.workingEstablishments.push(etab)
    },
    MAJ_ETAB(state: any, etab: WorkingEstablishmentInterface) {
        const index = state.workingEstablishments.map((e: WorkingEstablishmentInterface) => e.id).indexOf(etab.id)
        Vue.set(state.workingEstablishments, index, etab)
    },
    SET_SKIP_LIST(state: any, skipList: boolean) {
        state.skipList = skipList
    }
}

export const actions = {
    getWorkingEstablishments({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            WorkingEstablishmentService.getWorkingEstablishments(params)
                .then((response) => {
                    if (!params.skipStore) {
                        setWorkingEstablishments(commit, response)
                    }
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreWorkingEstablishments({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            WorkingEstablishmentService.getWorkingEstablishments(params)
                .then((response) => {
                    // setWorkingEstablishments(commit, response)
                    commit('SET_MORE_WORKING_ESTABLISHMENTS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getWorkingEstablishment({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            WorkingEstablishmentService.getWorkingEstablishment(params.working_establishment_id)
                .then((response) => {
                    commit('SET_WORKING_ESTABLISHMENT_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateWorkingEstablishment({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            WorkingEstablishmentService.updateWorkingEstablishment(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_WORKING_ESTABLISHMENT_SELECT', response.data.data)
                    commit('MAJ_ETAB', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addWorkingEstablishment({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            WorkingEstablishmentService.addWorkingEstablishment(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('SET_WORKING_ESTABLISHMENT_SELECT', response.data.data)
                    commit('ADD_ETAB', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteWorkingEstablishment({ commit }: { commit: any }, working_establishment_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            WorkingEstablishmentService.deleteWorkingEstablishment(working_establishment_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    importWorkingEstablishment({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            WorkingEstablishmentService.importWorkingEstablishment(payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getActivityLog({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            WorkingEstablishmentService.getActivityLog(params.working_establishment_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    usersSearch({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            WorkingEstablishmentService.usersSearch(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    workingEstablishments: (state: any) => {
        return state.workingEstablishments
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.meta ? state.meta.last_page : 1
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.workingEstablishments.length
    },
    workingEstablishmentSelect: (state: any) => {
        return state.workingEstablishmentSelect
    },
    getWorkingEstablishmentById: (state: any) => (etab_id: number) => {
        return state.workingEstablishments.find((workingEstablishment: any) => workingEstablishment.id === etab_id)
    },
    skipList: (state: any) => {
        return state.skipList
    }
}
