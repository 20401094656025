import * as API from '@/services/API'
import store from '@/store/index'

export default {
    getCompteCandidats(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/compte_candidats`, { params: payload })
    },
    updateCompteCandidats(compte_candidat_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/compte_candidats/${compte_candidat_id}`, payload)
    }
}
