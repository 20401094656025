import * as API from '@/services/API'
import store from '@/store/index'

export default {
    getAdresseCentres(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/adressesCentre`, { params: payload })
    },
    getAdresseCentre(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/centres/${payload.centre_id}/adresses/${payload.id}`)
    },
    updateAdresseCentre(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/centres/${payload.centre_id}/adresses/${payload.id}`, payload)
    },
    addAdresseCentre(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/centres/${payload.centre_id}/adresses`, payload)
    },
    deleteAdresseCentre(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/centres/${payload.centre_id}/adresses/${payload.id}`)
    }
}
