import { getError } from '@/utils/helpers'
import AjustementService from '@/services/AjustementService'
import { AjustementInterface, StateAjustements } from '@/types/Ajustement'

export const namespaced = true

function setAjustements(commit: Function, response: any) {
    commit('SET_AJUSTEMENTS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateAjustements = {
    ajustements: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    ajustementSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateAjustements) {
        Object.assign(state, {
            ajustements: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            ajustementSelect: {}
        })
    },
    SET_AJUSTEMENTS(state: any, ajustements: Array<AjustementInterface>) {
        state.ajustements = ajustements
    },
    SET_MORE_AJUSTEMENTS(state: any, ajustements: Array<AjustementInterface>) {
        state.ajustements = state.ajustements.concat(ajustements)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_MATIERE_SELECT(state: any, ajustement: AjustementInterface) {
        state.ajustementSelect = ajustement
    }
}

export const actions = {
    getAjustements({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AjustementService.getAjustements(params)
                .then((response) => {
                    setAjustements(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getAjustement({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AjustementService.getAjustement(params.ajustement_id)
                .then((response) => {
                    commit('SET_AJUSTEMENT_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreAjustements({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AjustementService.getAjustement(params.ajustement_id)
                .then((response) => {
                    commit('SET_AJUSTEMENT_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateAjustement({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AjustementService.updateAjustement(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addAjustement({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AjustementService.addAjustement(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteAjustement({ commit }: { commit: any }, ajustement_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AjustementService.deleteAjustement(ajustement_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    simulateSeuils({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AjustementService.simulateSeuil(params.epreuve_correction_id, params.adjustment, params.payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    ajustements: (state: any) => {
        return state.ajustements
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.ajustements.length
    },
    ajustementSelect: (state: any) => {
        return state.ajustementSelect
    },
    getAjustementById: (state: any) => (id: number) => {
        const ajustement = state.ajustements.filter((mat: any) => mat.id === id)[0]
        return ajustement
    },
    getCodeAjustement: (state: any) => (id: number) => {
        const ajustement = state.ajustements.filter((mat: any) => mat.id === id)[0]
        if (ajustement) {
            return ajustement.code
        } else {
            return 'NR'
        }
    },
    getAjustementsObs: (state: any) => (liste_ajustement_obs: any) => {
        const tab_ajustements: Array<any> = []
        for (const mo in liste_ajustement_obs) {
            if (liste_ajustement_obs[mo].entity_id) {
                const ajustement_temp = state.ajustements.find((m: any) => m.id === liste_ajustement_obs[mo].entity_id)
                if (ajustement_temp) {
                    tab_ajustements.push(ajustement_temp)
                }
            }
        }

        return tab_ajustements
    }
}
