import * as API from '@/services/API'
import store from '@/store/index'

export default {
    getBaremesRemuneration(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/baremeremunerations`, { params: params })
    },
    getBaremeRemuneration(bareme_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/baremeremunerations/${bareme_id}`)
    },
    deleteBaremeRemuneration(bareme_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/baremeremunerations/${bareme_id}`)
    },
    updateBaremeRemuneration(bareme_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/baremeremunerations/${bareme_id}`, payload)
    },
    saveBaremeRemuneration(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/baremeremunerations`, payload)
    }
}
