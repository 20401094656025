import axios from 'axios'
import store from '@/store'
import toast from '@/utils/toast'

export const authClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true // required to handle the CSRF token
})

/*
 * Add a response interceptor
 */
authClient.interceptors.response.use(
    (response) => {
        return response
    },
    function (error) {
        if (error.response && [401].includes(error.response.status) && store.getters['auth/authUser']) {
            store.dispatch('auth/logout')
        } else if (error.response && [419].includes(error.response.status) && store.getters['auth/authUser']) {
            // store.dispatch('auth/logout')
            console.log('419')
            console.log(error)
        } else if (error.response && [500].includes(error.response.status)) {
            // store.dispatch('auth/logout')
            console.log('500')
            console.log(error)
            toast.error('Une erreur serveur est survenue, veuillez réessayer ultérieurement', 'Erreur')
        }

        return Promise.reject(error)
    }
)

export default {
    async login(payload: any) {
        await authClient.get('/sanctum/csrf-cookie')
        return authClient.post('/login', payload)
    },
    logout() {
        return authClient.post('/logout')
    },
    async forgotPassword(payload: any) {
        await authClient.get('/sanctum/csrf-cookie')
        return authClient.post('/forgot-password', payload)
    },
    getAuthUser() {
        return authClient.get('/api/user')
    },
    async resetPassword(payload: any) {
        await authClient.get('/sanctum/csrf-cookie')
        return authClient.post('/reset-password', payload)
    },
    updatePassword(payload: any) {
        return authClient.put('/user/password', payload)
    },
    async registerUser(payload: any) {
        await authClient.get('/sanctum/csrf-cookie')
        return authClient.post('/register', payload)
    },
    sendVerification(payload: any) {
        return authClient.post('/email/verification-notification', payload)
    },
    updateUser(payload: any) {
        return authClient.put('/user/profile-information', payload)
    },
    sendAcceptRGPD() {
        return authClient.post('/api/rgpd')
    },
    twoFactorAuthentication() {
        return authClient.post('/user/two-factor-authentication')
    },
    deleteTwoFactorAuthentication() {
        return authClient.delete('/user/two-factor-authentication')
    },
    twoFactorQrCode() {
        return authClient.get('/user/two-factor-qr-code')
    },
    twoFactorRecoveryCodes() {
        return authClient.get('/user/two-factor-recovery-codes')
    },
    twoFactorChallenge(payload: any) {
        return authClient.post('/two-factor-challenge', payload)
    }
}
