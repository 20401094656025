// affectationcorrecteurs
import * as API from '@/services/API'
import store from '@/store/index'

export default {
    async getAffectationCorrecteur(affectationCorrecteurId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/affectationcorrecteurs/${affectationCorrecteurId}`)
    },
    async getAffectationCorrecteurs(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/affectationcorrecteurs`, { params: params })
    },
    async getReclamationCorrecteurs(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/reclamationcorrecteurs`, { params: params })
    },
    async synchViatique() {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/affectationcorrecteurs`)
    },
    async addAffectationCorrecteur(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/affectationcorrecteurs`, payload)
    },
    async deleteAffectationCorrecteur(affectationCorrecteur_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/affectationcorrecteurs/${affectationCorrecteur_id}`)
    }
}
