import { getError } from '@/utils/helpers'
import EnsembleService from '@/services/EnsembleService'
import { EnsembleInterface, StateEnsembles, TypeEnsemble } from '@/types/Ensemble'
import SessionService from '@/services/SessionService'
import store from '@/store/index'
import Vue from 'vue'

export const namespaced = true

function setEnsembles(commit: Function, response: any) {
    commit('SET_ENSEMBLES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateEnsembles = {
    ensembles: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    ensembleSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateEnsembles) {
        Object.assign(state, {
            ensembles: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            ensembleSelect: {}
        })
    },
    SET_ENSEMBLES(state: any, ensembles: Array<EnsembleInterface>) {
        state.ensembles = ensembles
    },
    SET_MORE_ENSEMBLES(state: any, ensembles: Array<EnsembleInterface>) {
        state.ensembles = state.ensembles.concat(ensembles)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_ENSEMBLE_SELECT(state: any, ensemble: EnsembleInterface) {
        state.ensembleSelect = ensemble
    }
}

export const actions = {
    getEnsembles({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EnsembleService.getEnsembles(params)
                .then((response) => {
                    if (!params || JSON.stringify(params) === '{"params":{"perPage":10000}}') {
                        response.data.data.sort((a: EnsembleInterface, b: EnsembleInterface) => {
                            return a.type_ensemble > b.type_ensemble
                                ? 1
                                : a.type_ensemble === b.type_ensemble
                                    ? a.name > b.name
                                        ? 1
                                        : -1
                                    : -1
                        })
                    }
                    setEnsembles(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getEnsemble({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EnsembleService.getEnsemble(params.ensemble_id)
                .then((response) => {
                    commit('SET_ENSEMBLE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreEnsembles({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EnsembleService.getEnsembles(params)
                .then((response) => {
                    commit('SET_ENSEMBLE_SELECT', response.data.data)
                    commit('SET_META', response.data.meta)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateEnsemble({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EnsembleService.updateEnsemble(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addEnsemble({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EnsembleService.addEnsemble(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteEnsemble({ commit }: { commit: any }, ensemble_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EnsembleService.deleteEnsemble(ensemble_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    validateEnsembles({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SessionService.updateSessionAfex(params.session_id, { ensembles_validate: params.ensembles_validate })
                .then((response) => {
                    // EnsembleService.validateEnsembles(params).then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getExcelEnsembles({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EnsembleService.getExcelEnsembles(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    ensembles: (state: any) => {
        return state.ensembles
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.ensembles.length
    },
    ensembleSelect: (state: any) => {
        return state.ensembleSelect
    },
    lastPage: (state: any) => {
        if (state.meta && state.meta.lastPage) {
            return state.meta.lastPage
        } else {
            return 1
        }
    },
    ensembleById: (state: any) => (id: number) => {
        const ensemble = state.ensembles.filter((ensemble: any) => ensemble.id === id)[0]
        return ensemble
    },
    ensembleNameIsNotUnique: (state: any) => (id: number, name: string) => {
        for (let i = 0; i < state.ensembles.length; i++) {
            if (name === state.ensembles[i].name && id !== state.ensembles[i].id) {
                return true
            }
        }
        return false
    },
    ensemblesByType: (state: any) => {
        const tab_ensembles: { [index: string]: any } = {}
        for (const e in state.ensembles) {
            if (!tab_ensembles[state.ensembles[e].type_ensemble]) {
                tab_ensembles[state.ensembles[e].type_ensemble] = []
            }

            tab_ensembles[state.ensembles[e].type_ensemble].push(state.ensembles[e])
        }
        return tab_ensembles
    },
    ensembleByBanques: (state: any) => {
        const tab_ensembles: Array<any> = []
        const temps_concours: { [index: string]: any } = {}

        for (const e in state.ensembles) {
            if (state.ensembles[e].type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
                const ensemble_temps = {}
                if (!temps_concours[state.ensembles[e].type_ensemble]) {
                    temps_concours[state.ensembles[e].type_ensemble] = {}
                }
                // Ventilation par concours
                if (!temps_concours[state.ensembles[e].type_ensemble][state.ensembles[e].concour.code]) {
                    temps_concours[state.ensembles[e].type_ensemble][state.ensembles[e].concour.code] = []
                    // Option intermédiaire de toutes les équipes interclassement d'une banque
                    Vue.set(ensemble_temps, 'id', state.ensembles[e].concour.id)
                    Vue.set(
                        ensemble_temps,
                        'name',
                        "Toutes les équipes d'interclassement de " + state.ensembles[e].concour.code
                    )
                    Vue.set(ensemble_temps, 'concour', state.ensembles[e].concour.id)
                    Vue.set(ensemble_temps, 'type_ensemble', state.ensembles[e].type_ensemble)
                    Vue.set(ensemble_temps, 'equipe_groupe', true)
                    Vue.set(ensemble_temps, 'key', 'type_ensemble_concour')
                    tab_ensembles.push(ensemble_temps)
                }
            }
        }
        return tab_ensembles
    },
    getFiliereFromEnsemble: () => (ensemble: any) => {
        let dataFiliere = ''
        if (ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
            dataFiliere = ensemble.concour.name
        } else {
            for (let i = 0; i < ensemble.groupeEpreuve.epreuves.length; i++) {
                for (let j = 0; j < ensemble.groupeEpreuve.epreuves[i].epreuves.length; j++) {
                    const concTemp = store.getters['concour/concourById'](
                        ensemble.groupeEpreuve.epreuves[i].epreuves[j].concour_id
                    )
                    if (concTemp) {
                        if (dataFiliere.length > 0) {
                            dataFiliere += ', '
                        }
                        const codeConcours = store.getters['concour/concourById'](
                            ensemble.groupeEpreuve.epreuves[i].epreuves[j].concour_id
                        ).code
                        dataFiliere += codeConcours
                    }
                }
            }
        }
        return dataFiliere
    }
}
