import { getError } from '@/utils/helpers'
import HistoriqueNotificationService from '@/services/HistoriqueNotificationService'
import { HistoriqueNotificationInterface, StateHistoriqueNotifications } from '@/types/HistoriqueNotification'

export const namespaced = true

function setHistoriqueNotifications(commit: Function, response: any) {
    commit('SET_HISTORIQUE_NOTIFICATIONS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateHistoriqueNotifications = {
    historiqueNotifications: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    historiqueNotificationSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateHistoriqueNotifications) {
        Object.assign(state, {
            historiqueNotifications: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            historiqueNotificationSelect: {}
        })
    },
    SET_HISTORIQUE_NOTIFICATIONS(state: any, historiqueNotifications: Array<HistoriqueNotificationInterface>) {
        state.historiqueNotifications = historiqueNotifications
    },
    SET_MORE_HISTORIQUE_NOTIFICATIONS(state: any, historiqueNotifications: Array<HistoriqueNotificationInterface>) {
        state.historiqueNotifications = state.historiqueNotifications.concat(historiqueNotifications)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_HISTORIQUE_NOTIFICATION_SELECT(state: any, historiqueNotification: HistoriqueNotificationInterface) {
        state.historiqueNotificationSelect = historiqueNotification
    }
}

export const actions = {
    getHistoriqueNotifications({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            HistoriqueNotificationService.getHistoriqueNotifications(params)
                .then((response) => {
                    setHistoriqueNotifications(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getHistoriqueNotification({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            HistoriqueNotificationService.getHistoriqueNotification(params.historiqueNotification_id)
                .then((response) => {
                    commit('SET_HISTORIQUE_NOTIFICATION_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreHistoriqueNotifications({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            HistoriqueNotificationService.getHistoriqueNotifications(params)
                .then((response) => {
                    commit('SET_MORE_HISTORIQUE_NOTIFICATIONS', response.data.data)
                    // commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateHistoriqueNotification({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            HistoriqueNotificationService.updateHistoriqueNotification(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addHistoriqueNotification({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            HistoriqueNotificationService.addHistoriqueNotification(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteHistoriqueNotification({ commit }: { commit: any }, historiqueNotification_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            HistoriqueNotificationService.deleteHistoriqueNotification(historiqueNotification_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    export({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            HistoriqueNotificationService.export()
                .then((response) => {
                    // setDemandesRemboursement(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    historiqueNotifications: (state: any) => {
        return state.historiqueNotifications
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.historiqueNotifications.length
    },
    historiqueNotificationSelect: (state: any) => {
        return state.historiqueNotificationSelect
    },
    getHistoriqueNotificationById: (state: any) => (id: number) => {
        const historiqueNotification = state.historiqueNotifications.filter((mat: any) => mat.id === id)[0]
        return historiqueNotification
    },
    getCodeHistoriqueNotification: (state: any) => (id: number) => {
        const historiqueNotification = state.historiqueNotifications.filter((mat: any) => mat.id === id)[0]
        if (historiqueNotification) {
            return historiqueNotification.code
        } else {
            return 'NR'
        }
    },
    getHistoriqueNotificationsObs: (state: any) => (liste_historiqueNotification_obs: any) => {
        const tab_historiqueNotifications: Array<any> = []
        for (const mo in liste_historiqueNotification_obs) {
            if (liste_historiqueNotification_obs[mo].entity_id) {
                const historiqueNotification_temp = state.historiqueNotifications.find(
                    (m: any) => m.id === liste_historiqueNotification_obs[mo].entity_id
                )
                if (historiqueNotification_temp) {
                    tab_historiqueNotifications.push(historiqueNotification_temp)
                }
            }
        }

        return tab_historiqueNotifications
    },
    lastPage: (state: any) => {
        if (state && state.meta && state.meta.last_page) {
            
            return state.meta.last_page
        } else {
            return 1
        }
    }

}
