import * as API from '@/services/API'
import store from '@/store'

export default {
    getVilles(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/villes`, { params: payload })
    },
    getVille(villeId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/villes/${villeId}`)
    },
    updateVille(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/villes/${payload.id}`, payload)
    },
    addVille(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/villes`, payload)
    },
    deleteVille(ville_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/villes/${ville_id}`)
    },
    getZones(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/zones`, { params: payload })
    },
    addZone(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/zones`, payload)
    },
    updateZone(zoneId: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/zones/${zoneId}`, payload)
    },
    deleteZone(zoneId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/zones/${zoneId}`)
    }
}
