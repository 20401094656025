import * as API from '@/services/API'
import store from '@/store/index'

export default {
    async getConcourPhase(concourPhaseId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/concourphases/${concourPhaseId}`)
    },
    async getConcourPhases(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/concourphases`, { params: payload })
    },
    async updateConcourPhase(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/concourphases/${payload.id}`, payload)
    },
    async addConcourPhase(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/concourphases`, payload)
    },
    async deleteConcourPhase(concourPhase_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/concourphases/${concourPhase_id}`)
    },
    async updateConcourPhaseAjustement(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(
            `/sessions/${session_id}/concourphases/${payload.concourPhase_id}/ajustements`,
            payload
        )
    },
    async getViatiqueResultState() {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/viatique/resultats`)
    },
    async startViatiqueResult() {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/viatique/resultats`)
    },
    async getConcourPhaseAjustementCompteurs() {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/concourphases_ajustements_compteurs`)
    },
    async getConcourPhaseCompteurs(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/concourphases/${payload.concourphase_id}/compteurs`)
    },
    async exportConcoursPhasePV(phase_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/concourphases/export_pv/${phase_id}`, {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    },
    async exportConcoursPhaseExterne(phase_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/concourphases/export_extern/${phase_id}`, {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    },
    async exportConcoursPhaseComparaisonConcours(phase_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/concourphases_exportcomparaisonconcours/${phase_id}`, {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    }
}
