export enum state_demission {
    DEMISSION_AUCUNE,
    DEMISSION_TRAITE_OUI,
    DEMISSION_DEMANDE,
    DEMISSION_TRAITE_NON,
    DEMISSION_DEMANDE_VUE,
}

export enum type_demission {
    CONCOURS = 'concours',
    EPREUVES_FAC = 'epreuve',
}
