import * as API from '@/services/API'

export default {
    async getTempsSupplementaire(tempsSupplementaireId: number) {
        return await API.apiClient.get(`/temps_supplementaires/${tempsSupplementaireId}`)
    },
    async getTempsSupplementaires(payload: any) {
        return await API.apiClient.get('/temps_supplementaires', { params: payload })
    },
    async updateTempsSupplementaire(TempsSupplementaireId: number, payload: any) {
        return await API.apiClient.put(`/temps_supplementaires/${TempsSupplementaireId}`, payload)
    },
    async addTempsSupplementaire(payload: any) {
        return await API.apiClient.post('/temps_supplementaires', payload)
    },
    async deleteTempsSupplementaire(tempsSupplementaire_id: number) {
        return await API.apiClient.delete(`/temps_supplementaires/${tempsSupplementaire_id}`)
    }
}
