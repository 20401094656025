import { getError } from '@/utils/helpers'
import SalleService from '@/services/SalleService'
import { StateSalles } from '@/types/Salle'

export const namespaced = true

export const state: StateSalles = {
    salleSelect: {
        id: 0,
        name: '',
        code: '',
        capacite_std: 0,
        capacite_max: 0,
        type: 0,
        amenagement: 0,
        concours: [],
        centre_id: 0,
        total_capacite: 0
    },
    loading: false,
    save_in_progress: false,
    soumettre_ok: false,
    error: null
}

export const mutations = {
    RESET_STATE(state: StateSalles) {
        Object.assign(state, {
            salleSelect: {},
            loading: false,
            save_in_progress: false,
            error: null
        })
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_SAVE_IN_PROGRESS(state: any, etat: boolean) {
        state.save_in_progress = etat
    },
    SET_SOUMETTRE(state: any, etat: boolean) {
        state.soumettre_ok = etat
    }
}

export const actions = {
    updateSalle({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SalleService.updateSalle(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('centre/MAJ_SALLE_CENTRE_SELECT', response.data.data, { root: true })
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addSalle({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SalleService.addSalle(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('centre/ADD_SALLE_CENTRE_SELECT', response.data.data, { root: true })
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteSalle({ commit }: { commit: any }, matiere_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SalleService.deleteSalle(matiere_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateSalles({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SalleService.updateSalles(params.centre_id, params.salles)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('centre/MAJ_SALLES_CENTRE_SELECT', response.data.data, { root: true })
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    salleSelect: (state: any) => {
        return state.salleSelect
    },
    save_in_progress: (state: any) => {
        return state.save_in_progress
    },
    soumettre_ok: (state: any) => {
        return state.soumettre_ok
    }
}
