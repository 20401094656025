import { getError } from '@/utils/helpers'
import AffectationResponsableService from '@/services/AffectationResponsableService'
import { AffectationResponsableInterface, StateAffectationResponsables } from '@/types/AffectationResponsable'

export const namespaced = true

function setAffectationResponsables(commit: Function, response: any) {
    commit('SET_AFFECTATION_RESPONSABLES', response.data.data)
    commit('SET_META', response.meta)
    commit('SET_LINKS', response.links)
    commit('SET_TOTALROWS', response.data.length)
    // groupSeries(commit, response.data.data)
    commit('SET_LOADING', false)
}

export const state: StateAffectationResponsables = {
    affectationResponsables: [],
    meta: null,
    links: null,
    loading: false,
    loading_search: false,
    error: null,
    error_conflict: {
        datas: [],
        message: null
    },
    countError: 0,
    totalRows: 0,
    affectationResponsableSelect: {},
    usersFromSearch: null
}

export const mutations = {
    RESET_STATE(state: StateAffectationResponsables) {
        Object.assign(state, {
            affectationResponsables: [],
            meta: null,
            links: null,
            loading: false,
            loading_search: false,
            error: null,
            error_conflict: {
                datas: [],
                message: null
            },
            countError: 0,
            totalRows: 0,
            affectationResponsableSelect: {},
            usersFromSearch: null
        })
    },
    SET_AFFECTATION_RESPONSABLES(
        state: StateAffectationResponsables,
        affectationResponsables: Array<AffectationResponsableInterface>
    ) {
        state.affectationResponsables = affectationResponsables
    },
    SET_MORE_AFFECTATION_RESPONSABLES(state: any, affectationResponsables: Array<AffectationResponsableInterface>) {
        state.affectationResponsables = state.affectationResponsables.concat(affectationResponsables)
    },
    SET_META(state: StateAffectationResponsables, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: StateAffectationResponsables, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: StateAffectationResponsables, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR_FULL(state: any, error: Array<any>) {
        state.error = error
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error_conflict = {
            datas: [],
            message: null
        }
        state.error = error
        state.countError++
    },
    SET_ERROR_CONFLICT(state: any, error: any) {
        state.error_conflict = {
            datas: error.data,
            message: error.message
        }
        state.countError++
    },
    SET_ERROR_DATAS(state: any, datas: Array<any>) {
        state.error_conflict.datas = datas
    },
    SET_TOTALROWS(state: StateAffectationResponsables, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_AFFECTATION_RESPONSABLE_SELECT(
        state: StateAffectationResponsables,
        affectationResponsable: AffectationResponsableInterface
    ) {
        state.affectationResponsableSelect = affectationResponsable
    },
    /**
     * met a jours les salles id des affectations
     * @param state
     * @param affectationResponsables tableau retourné par l'update sur le serveur (incomplet d'ou la mise a jours des donnée du store plutot qu'un remplacement)
     */
    UPDATE_AFFECTATION_RESPONSABLES(
        state: StateAffectationResponsables,
        affectationResponsables: Array<AffectationResponsableInterface>
    ) {
        for (let i = 0; i < state.affectationResponsables.length; i++) {
            for (let j = 0; j < affectationResponsables.length; j++) {
                if (state.affectationResponsables[i].id === affectationResponsables[j].id) {
                    state.affectationResponsables[i].salle_id = affectationResponsables[j].salle_id
                }
            }
        }
    },
    SET_LOADING_SEARCH(state: any, loading: boolean) {
        state.loading_search = loading
    },
    SET_USER_FROM_SEARCH(state: any, usersFromSearch: Array<any>) {
        state.usersFromSearch = usersFromSearch
    }
}

export const actions = {
    getAffectationResponsables({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationResponsableService.getAffectationResponsables(params)
                .then((response) => {
                    setAffectationResponsables(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getAffectationResponsable({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationResponsableService.getAffectationResponsable(params.affectationResponsable_id)
                .then((response) => {
                    commit('SET_AFFECTATION_RESPONSABLE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreAffectationResponsables({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationResponsableService.getAffectationResponsable(params.affectationResponsable_id)
                .then((response) => {
                    commit('SET_AFFECTATION_RESPONSABLE_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    synchViatique({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationResponsableService.synchViatique()
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addAffectationResponsable({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationResponsableService.addAffectationResponsable(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteAffectationResponsable({ commit }: { commit: any }, affectationResponsable_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationResponsableService.deleteAffectationResponsable(affectationResponsable_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getUsersFromSearch({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING_SEARCH', true)
        return new Promise((resolve, reject) => {
            AffectationResponsableService.getUsersFromSearch(params.payload)
                .then((response) => {
                    commit('SET_LOADING_SEARCH', false)
                    commit('SET_USER_FROM_SEARCH', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addDossierUser({ commit }: { commit: any }, params: any) {
        // commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationResponsableService.addDossierUser(params)
                .then((response) => {
                    // commit('SET_LOADING', false)
                    commit('SET_ERROR_FULL', null)
                    resolve(response)
                })
                .catch((error) => {
                    // commit('SET_LOADING', false)
                    if (error.response.status === 409) {
                        commit('SET_ERROR_CONFLICT', error.response.data)
                        reject(error)
                    } else {
                        commit('SET_ERROR_FULL', getError(error))
                        reject(error)
                    }
                })
        })
    }
}

export const getters = {
    affectationResponsables: (state: StateAffectationResponsables) => {
        return state.affectationResponsables
    },
    meta: (state: StateAffectationResponsables) => {
        return state.meta
    },
    links: (state: StateAffectationResponsables) => {
        return state.links
    },
    loading: (state: StateAffectationResponsables) => {
        return state.loading
    },
    error: (state: StateAffectationResponsables) => {
        return state.error
    },
    totalRows: (state: StateAffectationResponsables) => {
        return state.affectationResponsables.length
    },
    affectationResponsableSelect: (state: StateAffectationResponsables) => {
        return state.affectationResponsableSelect
    },
    loading_search: (state: any) => {
        return state.loading_search
    },
    errorConflict: (state: any) => {
        return state.error_conflict
    },
    countError: (state: any) => {
        return state.countError
    }
}
