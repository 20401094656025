import * as API from '@/services/API'
import store from '@/store'

export default {
    async getTournee(tournee_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/tournees/${tournee_id}`)
    },
    async getTournees(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/tournees`, { params: payload })
    },
    async postTournee(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/tournees`, payload)
    },
    async putTournee(tournee_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/tournees/${tournee_id}`, payload)
    },
    async deleteTournee(tournee_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/tournees/${tournee_id}`)
    }
}
