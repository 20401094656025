import { getError } from '@/utils/helpers'
import GroupeExaminateurService from '@/services/GroupeExaminateurService'
import { GroupeExaminateurInterface, StateGroupeExaminateurs } from '@/types/GroupeExaminateur'

export const namespaced = true

function setGroupeExaminateurs(commit: Function, response: any) {
    commit('SET_GROUPE_EXAMINATEURS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateGroupeExaminateurs = {
    groupeExaminateurs: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    groupeExaminateurSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateGroupeExaminateurs) {
        Object.assign(state, {
            groupeExaminateurs: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            groupeExaminateurSelect: {}
        })
    },
    SET_GROUPE_EXAMINATEURS(state: any, groupeExaminateurs: Array<GroupeExaminateurInterface>) {
        state.groupeExaminateurs = groupeExaminateurs
    },
    SET_MORE_GROUPE_EXAMINATEURS(state: any, groupeExaminateurs: Array<GroupeExaminateurInterface>) {
        state.groupeExaminateurs = state.groupeExaminateurs.concat(groupeExaminateurs)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_GROUPE_EXAMINATEUR_SELECT(state: any, groupeExaminateur: GroupeExaminateurInterface) {
        state.groupeExaminateurSelect = groupeExaminateur
    }
}

export const actions = {
    getGroupeExaminateurs({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GroupeExaminateurService.getGroupeExaminateurs(params)
                .then((response) => {
                    setGroupeExaminateurs(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getGroupeExaminateur({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GroupeExaminateurService.getGroupeExaminateur(params.groupeExaminateur_id)
                .then((response) => {
                    commit('SET_GROUPE_EXAMINATEUR_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreGroupeExaminateurs({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GroupeExaminateurService.getGroupeExaminateur(params.groupeExaminateur_id)
                .then((response) => {
                    commit('SET_GROUPE_EXAMINATEUR_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateGroupeExaminateur({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GroupeExaminateurService.updateGroupeExaminateur(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addGroupeExaminateur({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GroupeExaminateurService.addGroupeExaminateur(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteGroupeExaminateur({ commit }: { commit: any }, groupeExaminateur_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            GroupeExaminateurService.deleteGroupeExaminateur(groupeExaminateur_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    groupeExaminateurs: (state: any) => {
        return state.groupeExaminateurs
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.groupeExaminateurs.length
    },
    groupeExaminateurSelect: (state: any) => {
        return state.groupeExaminateurSelect
    },
    getGroupeExaminateurById: (state: any) => (id: number) => {
        const groupeExaminateur = state.groupeExaminateurs.filter((mat: any) => mat.id === id)[0]
        return groupeExaminateur
    }
}
