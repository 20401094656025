import { getError } from '@/utils/helpers'
import DomaineService from '@/services/DomaineService'
import { DomaineInterface, StateDomaines } from '@/types/Domaine'

export const namespaced = true

function setDomaines(commit: Function, response: any) {
    commit('SET_DOMAINES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateDomaines = {
    domaines: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    domaineSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateDomaines) {
        Object.assign(state, {
            domaines: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            domaineSelect: {}
        })
    },
    SET_DOMAINES(state: any, domaines: Array<DomaineInterface>) {
        state.domaines = domaines
    },
    SET_MORE_DOMAINES(state: any, domaines: Array<DomaineInterface>) {
        state.domaines = state.domaines.concat(domaines)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_DOMAINE_SELECT(state: any, domaine: DomaineInterface) {
        state.domaineSelect = domaine
    }
}

export const actions = {
    getDomaines({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DomaineService.getDomaines(params)
                .then((response) => {
                    setDomaines(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDomaine({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DomaineService.getDomaine(params.domaine_id)
                .then((response) => {
                    commit('SET_DOMAINE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreDomaines({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DomaineService.getDomaine(params.domaine_id)
                .then((response) => {
                    commit('SET_DOMAINE_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateDomaine({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DomaineService.updateDomaine(params.domaine_id, params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addDomaine({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DomaineService.addDomaine(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteDomaine({ commit }: { commit: any }, domaine_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DomaineService.deleteDomaine(domaine_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    domaines: (state: any) => {
        return state.domaines
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.domaines.length
    },
    domaineSelect: (state: any) => {
        return state.domaineSelect
    },
    getDomaineById: (state: any) => (id: number) => {
        const domaine = state.domaines.filter((mat: any) => mat.id === id)[0]
        return domaine
    },
    getCodeDomaine: (state: any) => (id: number) => {
        const domaine = state.domaines.filter((mat: any) => mat.id === id)[0]
        if (domaine) {
            return domaine.code
        } else {
            return 'NR'
        }
    },
    getDomainesObs: (state: any) => (liste_domaine_obs: any) => {
        const tab_domaines: Array<any> = []
        for (const mo in liste_domaine_obs) {
            if (liste_domaine_obs[mo].entity_id) {
                const domaine_temp = state.domaines.find((m: any) => m.id === liste_domaine_obs[mo].entity_id)
                if (domaine_temp) {
                    tab_domaines.push(domaine_temp)
                }
            }
        }

        return tab_domaines
    }
}
