import { getError } from '@/utils/helpers'
import dashboardConcoursService from '@/services/DashboardConcoursService'

export const namespaced = true

export const state: any = {
    dashboard_concours_informations: [],
    loading_dashboard_concours: false,
    dashboard_error: null
}

export const mutations = {
    SET_DASHBOARD_CONCOURS_INFORMATIONS(state: any, data: any) {
        state.dashboard_concours_informations = data
    },
    SET_LOADING_DASHBOARD(state: any, loading: boolean) {
        state.loading_dashboard = loading
    },
    SET_DASHBOARD_ERROR(state: any, error: Array<any>) {
        state.dashboard_error = error
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.dashboard_error = error
    }
}

export const actions = {
    getDashboardInformations({ commit }: { commit: any }) {
        commit('SET_LOADING_DASHBOARD', true)
        return new Promise((resolve, reject) => {
            dashboardConcoursService
                .getDashboardInfos()
                .then((response) => {
                    commit('SET_DASHBOARD_CONCOURS_INFORMATIONS', response.data.data)
                    commit('SET_LOADING_DASHBOARD', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING_DASHBOARD', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    dashboard_concours_informations(state: any) {
        return state.dashboard_concours_informations
    },
    loading_dashboard(state: any) {
        return state.loading_dashboard_concours
    }
}
