import * as API from '@/services/API'
import store from '@/store'

export default {
    getDossiersInscription(publication_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/publications/${publication_id}/dossierinscriptions`, { params: payload })
    },
    getExportDossiersInscription(publication_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/publications/${publication_id}/dossierinscriptions/export`,
            {
                responseType: 'blob',
                headers: { 'X-Request-Retry': 0 }
            }
        )
    },
    getDossierInscription(dossier_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/dossierinscriptions/${dossier_id}`)
    },
    postDossierInscription(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/dossierinscriptions`, payload)
    },
    putFormulaireDossierInscription(dossier_inscription_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/dossierinscriptions/${dossier_inscription_id}`, payload)
    },
    putEtatDossierInscription(dossier_inscription_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(
            `/sessions/${session_id}/dossierinscriptions/${dossier_inscription_id}/updateEtat`,
            payload
        )
    },
    getDocument(dossier_inscription_id: number, document_id: string) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/dossierinscriptions/${dossier_inscription_id}/documents/${document_id}?format=b64`
        )
    },
    getDocumentReference(dossier_inscription_id: number, document_uuid: string) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(
            `/sessions/${session_id}/dossierinscriptions/${dossier_inscription_id}/documents/reference/${document_uuid}?format=b64`
        )
    },
    postDocument(dossier_inscription_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(
            `/sessions/${session_id}/dossierinscriptions/${dossier_inscription_id}/documents`,
            payload
        )
    },
    deleteDocument(dossier_inscription_id: number, document_uuid: string) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(
            `/sessions/${session_id}/dossierinscriptions/${dossier_inscription_id}/documents/${document_uuid}`
        )
    },
    postNouveauDossierPapier(publication_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/publications/${publication_id}/dossierinscriptions`, payload)
    }
}
