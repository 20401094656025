import { PosteContext } from '@/types/Poste'
import Vue from 'vue'

export const namespaced = true

export const state: any = {
    loading: false,
    error: null
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            loading: false,
            error: null
        })
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    }
}

export const actions = {
    // Rien pour le moment
}

interface DicoUserPoste {
    [index: string]: Array<any>
}

export const getters = {
    /** Retourne une collection d'intervenant par poste */
    users_by_postes: () => (centreusers: Array<any>, postes: Array<any>, besoins_centre: Array<any>) => {
        const dico_users_poste: { [index: string]: any } = {}
        // Création de la structure de la collection des postes et des lignes
        for (const i in postes) {
            Vue.set(dico_users_poste, postes[i].id, [])

            const nb_besoins = besoins_centre.find((bc: any) => bc.poste_id === postes[i].id)

            // Création des lignes
            if (nb_besoins) {
                for (let p = 0; p <= nb_besoins.nb - 1; p++) {
                    Vue.set(dico_users_poste[postes[i].id], p, [])
                }
            }
        }

        if (centreusers && centreusers.length !== 0) {
            // Met les utilisateurs existants dans la collection avec un dispatch par ligne selon leur temps de travail
            centreusers.forEach((cu: any) => {
                cu.postes.forEach((poste: any) => {
                    if (!dico_users_poste[poste.id]) {
                        Vue.set(dico_users_poste, poste.id, [])
                    }
                    if (!dico_users_poste[poste.id][poste.numero]) {
                        Vue.set(dico_users_poste[poste.id], poste.numero, [])
                    }

                    dico_users_poste[poste.id][poste.numero].push({
                        user: cu.user,
                        poste_id: poste.id,
                        poste: poste,
                        ligne_bis: false,
                        index: poste.numero
                    })
                })
            })
        }

        for (const d in dico_users_poste) {
            // Ligne bis pour les temps non complets sur l'existant ou ligne vide s'il manque des places
            for (const po in dico_users_poste[d]) {
                const ligne_time = getters.ligne_time()(dico_users_poste[d][po])

                if (ligne_time < 100 && dico_users_poste[d][po].length === 1) {
                    dico_users_poste[d][po].push({
                        user: { name: '', first_name: '' },
                        poste_id: d,
                        poste: {},
                        ligne_bis: true,
                        index: null
                    })
                } else if (ligne_time < 100 && dico_users_poste[d][po].length === 0) {
                    dico_users_poste[d][po].push({
                        user: { name: '', first_name: '' },
                        poste_id: d,
                        poste: {},
                        ligne_bis: false,
                        index: null
                    })
                }
            }
        }

        return dico_users_poste
    },
    /** Compte le temps des intervenants renseignés dans la collection du poste */
    compteur_by_poste: () => (postes_ligne: Array<any>) => {
        let compteur = 0
        postes_ligne.forEach((poste_ligne) => {
            poste_ligne.forEach((ligne: any) => {
                switch (ligne.poste.temps) {
                    case 100:
                        compteur += 1
                        break
                    case 75:
                        compteur += 0.75
                        break
                    case 50:
                        compteur += 0.5
                        break
                    case 25:
                        compteur += 0.25
                        break
                }
            })
        })

        return compteur
    },
    /** Retourne true or false si un poste est totalement renseigné */
    poste_full_time: () => (users_poste: any, besoins_poste: any) => {
        if (besoins_poste) {
            const fulltime = besoins_poste.nb * 100
            let time = 0

            for (const u in users_poste) {
                if (users_poste[u].poste && users_poste[u].poste.temps) {
                    time += users_poste[u].poste.temps
                }
            }

            if (fulltime === time) {
                return true
            } else {
                return false
            }
        }
    },
    remaining_time_poste: () => (users_poste: any, besoins_poste: any) => {
        let restant = 0
        if (besoins_poste) {
            const fulltime = besoins_poste.find((bp: any) => bp.poste_id === users_poste[0].poste_id).nb * 100
            let time = 0

            for (const u in users_poste) {
                if (users_poste[u].poste && users_poste[u].poste.temps) {
                    time += Number(users_poste[u].poste.temps)
                }
            }

            restant = fulltime - time
        }

        return restant
    },
    ligne_time: () => (ligne_users: any) => {
        let time = 0
        for (const u in ligne_users) {
            if (ligne_users[u].poste && ligne_users[u].poste.temps) {
                time += Number(ligne_users[u].poste.temps)
            }
        }

        return time
    },
    remaining_time_ligne: () => (ligne_users: any) => {
        let restant = 0
        let time = 0
        for (const u in ligne_users) {
            if (ligne_users[u].poste && ligne_users[u].poste.temps) {
                time += Number(ligne_users[u].poste.temps)
            }
        }

        restant = 100 - time
        return restant
    },
    poste_valide: () => (postes_ligne: Array<any>) => {
        let is_valide = true
        postes_ligne.forEach((poste_ligne) => {
            poste_ligne.forEach((ligne: any) => {
                if (
                    ligne.poste.context === PosteContext.CONTEXT_RESPONSABLE_CENTRE ||
                    ligne.poste.context === PosteContext.CONTEXT_SECRETAIRE
                ) {
                    is_valide = false
                }
            })
        })

        return is_valide
    },
    list_complete: () => (dico_postes: any, besoins_centre: any) => {
        let total_affectation = 0
        let total_besoins = 0
        for (const l in dico_postes) {
            // Check si le poste doit etre compté dans les intervenants
            const is_poste_valide = getters.poste_valide()(dico_postes[l])
            if (is_poste_valide) {
                total_affectation += getters.compteur_by_poste()(dico_postes[l])
            }
        }

        for (const b in besoins_centre) {
            total_besoins += besoins_centre[b].nb ? besoins_centre[b].nb : 0
        }
        if (total_affectation === total_besoins) {
            return true
        } else {
            return false
        }
    },
    intervenants_has_real_time: () => (centre_users: any) => {
        let useless_real_time = 0
        for (const cu in centre_users) {
            for (const p in centre_users[cu].postes) {
                if (
                    !centre_users[cu].postes[p].fiche_horaire &&
                    centre_users[cu].postes[p].context !== PosteContext.CONTEXT_RESPONSABLE_CENTRE &&
                    centre_users[cu].postes[p].context !== PosteContext.CONTEXT_SECRETAIRE
                ) {
                    useless_real_time++
                }
            }
        }
        if (useless_real_time !== 0) {
            return false
        } else {
            return true
        }
    }
}
