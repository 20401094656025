import { PosteInterface } from './Poste'
import { UserInterface } from './User'

export enum Etat {
    ETAT_INVISIBLE,
    ETAT_VIERGE,
    ETAT_DEPOSE,
    ETAT_VALIDE,
    ETAT_REJETE,
}

interface EtatSpec {
    index: number,
    libelle: string,
    color: string
}

export function getEtatSpec(etat: Etat): EtatSpec {
    switch (etat) {
        case Etat.ETAT_INVISIBLE:
            return { index: 0, libelle: 'Non publié', color: 'tertiary' }
        case Etat.ETAT_VIERGE:
            return { index: 1, libelle: 'Publié', color: 'secondary' }
        case Etat.ETAT_DEPOSE:
            return { index: 2, libelle: 'Déposé', color: 'info' }
        case Etat.ETAT_VALIDE:
            return { index: 3, libelle: 'Validé', color: 'success' }
        case Etat.ETAT_REJETE:
            return { index: 4, libelle: 'Rejeté', color: 'danger' }
        default:
            return { index: 1, libelle: 'Publié', color: 'secondary' }
    }
}

export function getEtatForFilterSelect(etat: string): EtatSpec {
    switch (etat) {
        case 'ETAT_INVISIBLE':
            return { index: 0, libelle: 'Non publié', color: 'tertiary' }
        case 'ETAT_VIERGE':
            return { index: 1, libelle: 'Publié', color: 'secondary' }
        case 'ETAT_DEPOSE':
            return { index: 2, libelle: 'Déposé', color: 'info' }
        case 'ETAT_VALIDE':
            return { index: 3, libelle: 'Validé', color: 'success' }
        case 'ETAT_REJETE':
            return { index: 4, libelle: 'Rejeté', color: 'danger' }
        default:
            return { index: 1, libelle: 'Publié', color: 'secondary' }
    }
}

export enum Decision {
    DECISION_AUCUNE,
    DECISION_RETENU,
    DECISION_NON_RETENU,
    DECISION_AJOURNE,
    DECISION_SUSPENDU,
    DECISION_NON_RECONDUIT,
    DECISION_DEMISSION,
}

interface DecisionSpec {
    index: number,
    libelle: string
}

export function getDecisionSpec(decision: Decision | number): DecisionSpec {
    switch (decision) {
        case Decision.DECISION_AUCUNE:
            return { index: 0, libelle: 'Aucune décision' }
        case Decision.DECISION_RETENU:
            return { index: 1, libelle: 'Retenu' }
        case Decision.DECISION_NON_RETENU:
            return { index: 2, libelle: 'Non retenu' }
        case Decision.DECISION_AJOURNE:
            return { index: 3, libelle: 'Ajourné' }
        case Decision.DECISION_SUSPENDU:
            return { index: 4, libelle: 'Suspendu' }
        case Decision.DECISION_NON_RECONDUIT:
            return { index: 5, libelle: 'Non reconduit' }
        case Decision.DECISION_DEMISSION:
            return { index: 6, libelle: 'Démission' }
        default:
            return { index: 0, libelle: 'Aucune décision' }
    }
}

export enum DecisionIntervenant {
    DECISION_INTERVENANT_MASQUE,
    DECISION_INTERVENANT_ATTENTE,
    DECISION_INTERVENANT_OUI,
    DECISION_INTERVENANT_NON,
}

export interface DossierAcademiqueInterface {
    id: number | 0,
    session_id: number | 0,
    user_id: number | 0,
    matiere_id: number | 0,
    datas_perso: DatasPersoInterface,
    datas_pro: DatasProInterface,
    submitted_at: Date,
    relaunched_at: Date,
    concour_id: number | 0,
    decision_selection: number | 0,
    decision_affectation: number | 0,
    decision_intervenant_selection: number | 0,
    decision_intervenant_affectation: number | 0,
    documents: object | {},
    postes: Array<PosteInterface> | [],
    postes_selection: Array<PosteInterface> | [],
    postes_affectation: Array<PosteInterface> | [],
    postes_souhait: Array<PosteInterface> | [],
    etat: Etat | Etat.ETAT_VIERGE,
    etat_form_perso: Etat | Etat.ETAT_VIERGE,
    etat_form_pro: Etat | Etat.ETAT_VIERGE,
    etat_pieces: Etat | Etat.ETAT_VIERGE,
    etat_souhait: Etat | Etat.ETAT_VIERGE,
    hasAvatar: boolean | false,
    user: UserInterface | {},
    classement: number | null
}

export interface PosteSelectInterface {
    matiere_id: number | 0,
    concour_id: number | 0,
    postes_id: Array<number> | []
}

export interface DatasPersoInterface {
    civility: string | '',
    name: string | '',
    first_name: string | '',
    birth_name: string | '',
    birth_date: Date,
    birth_place: string | '',
    address: string | '',
    email: string | '',
    telephone: string | '',
    telephone_fixe: string | ''
}

export interface DatasProInterface {
    title: string | '',
    title_other: string | '',
    level_last_diplome: string | '',
    level_last_diplome_other: string | '',
    function: string | '',
    function_other: string | '',
    academy: string | '',
    establishment_type: string | '',
    establishment_id: string | '',
    establishment_name: string | '',
    establishment_address: string | '',
    other_mission: string | '',
    other_mission_name: string | '',
    other_mission_name_other: string | '',
    other_mission_function: string | '',
    disciplines_maitrisees: Array<any> | [],
    selected_etablissements: Array<any> | [],
    diplomes: Array<any> | []
}

export interface DossierSelectionInterface {
    user: DatasPersoInterface | null,
    precedent: DossierAcademiqueInterface | null,
    courant: DossierAcademiqueInterface | null,
    etat: Etat | null,
    classement: number | null,
    disponibilite_selection: Etat | null,
    disponibilite_affectation: Etat | null,
    postes_souhait_courant: Array<PosteInterface> | [],
    postes_selection_courant: Array<PosteInterface> | [],
    postes_affectation_courant: Array<PosteInterface> | [],
    postes_souhait_precedent: Array<PosteInterface> | [],
    postes_selection_precedent: Array<PosteInterface> | [],
    postes_affectation_precedent: Array<PosteInterface> | []
}
