import * as API from '@/services/API'
import store from '@/store/index'

/** return la session du params / payload s'il existe, sinon le choix de l utilisateur  */
export function filterSessionControleur(paramsPayload: any | undefined) {
    if (paramsPayload && paramsPayload !== undefined) {
        switch (true) {
            case paramsPayload['filter-session'] &&
                paramsPayload['filter-session'] !== null &&
                paramsPayload['filter-session'] !== undefined:
                return paramsPayload['filter-session']
            case paramsPayload.session_id &&
                paramsPayload.session_id !== null &&
                paramsPayload.session_id !== undefined:
                return paramsPayload.session_id
            default:
                return store.getters['auth/user_session_id']
        }
    } else {
        return store.getters['auth/user_session_id']
    }
}

export default {
    getBanque(concourId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/concours/${concourId}`)
    },
    getBanques(payload: any = null) {
        const session_id = filterSessionControleur(payload)
        if (payload) {
            if (payload.session_id) {
                return API.apiClient.get(`/sessions/${session_id}/concours?banque&session_id=${payload.session_id}`, {
                    params: payload.params?.filters
                })
            } else if (payload.params) {
                return API.apiClient.get(`/sessions/${session_id}/concours?banque`, { params: payload.params.filters })
            } else {
                return API.apiClient.get(`/sessions/${session_id}/concours?banque`, { params: payload.filters })
            }
        } else {
            return API.apiClient.get(`/sessions/${session_id}/concours?banque`)
        }
    },
    getBanquesActives() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/concours?banque`)
    },
    updateBanque(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/concours/${payload.id}`, payload)
    },
    addBanque(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/concours`, payload)
    },
    deleteBanque(concourId: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/concours/${concourId}`)
    }
}
