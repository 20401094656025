import { getError } from '@/utils/helpers'
import DocumentTypeService from '@/services/DocumentTypeService'
import { DocumentTypeInterface } from '@/types/DocumentType'

export const namespaced = true

function setDocumenttypes(commit: any, response: any) {
    commit('SET_DOCUMENTTYPES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state = {
    documenttypes: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            documenttypes: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0
        })
    },
    SET_DOCUMENTTYPES(state: any, documenttypes: Array<DocumentTypeInterface>) {
        state.documenttypes = documenttypes
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    }
}

export const actions = {
    getDocumenttypes({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DocumentTypeService.getDocumentTypes()
                .then((response) => {
                    setDocumenttypes(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    documenttypes: (state: any) => {
        return state.documenttypes
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.documenttypes.length
    }
}
