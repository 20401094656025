import * as API from '@/services/API'
import store from '@/store'
import { AxiosRequestConfig } from 'axios'

export default {
    getCreneaux(user_id: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/examinateurs_disponibilites/${user_id}`)
    },
    updateMinimas(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/epreuvecorrections_afex`, payload)
    },
    addCreneau(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/creneaux`, payload)
    },
    updateCreneau(creneau_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/creneaux/${creneau_id}`, payload)
    },
    deleteCreneau(creneau_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${session_id}/creneaux/${creneau_id}`)
    },
    cloneSerie(user_id: number, serie_id: number, ensemble_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(
            `/sessions/${session_id}/examinateurs_disponibilites/${user_id}/ensembles/${ensemble_id}/series/${serie_id}/clone`
        )
    },
    getCreneauxEnsemble(ensemble_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/examinateurs_disponibilites_ensemble/${ensemble_id}`)
    },
    clearSerie(user_id: number, serie_id: number, ensemble_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(
            `/sessions/${session_id}/examinateurs_disponibilites/${user_id}/ensembles/${ensemble_id}/series/${serie_id}/delete`
        )
    },
    getCandidatsReels(examinateur_id: number, ensemble_id: number) {
        const session_id = store.getters['auth/user_session_id']
        if (ensemble_id) {
            return API.apiClient.get(
                `/sessions/${session_id}/examinateurs_disponibilites/${examinateur_id}/reels?ensemble_id=${ensemble_id}`
            )
        } else {
            return API.apiClient.get(`/sessions/${session_id}/examinateurs_disponibilites/${examinateur_id}/reels`)
        }
    },
    exporter_equipe(ensemble_id: number) {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        if (ensemble_id) {
            return API.apiClient.get(
                `/sessions/${session_id}/examinateurs_disponibilites_export/${ensemble_id}`,
                params
            )
        } else {
            return API.apiClient.get(`/sessions/${session_id}/examinateurs_disponibilites_export`, params)
        }
    },
    export_candidats_reels() {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return API.apiClient.get(`/sessions/${session_id}/examinateurs_disponibilites_export_compare_candidat`, params)
    },
    export_seances_contract() {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return API.apiClient.get(`/sessions/${session_id}/examinateurs_disponibilites_export_compare_contrat`, params)
    }
}
