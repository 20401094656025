import { getError } from '@/utils/helpers'
import CandidatJobDescriptionService from '@/services/CandidatJobDescriptionService'
import {
    CandidatJobDescriptionInterface,
    CandidatJobDescriptionStatus,
    StateCandidatJobDescriptions
} from '@/types/CandidatJobDescription'
import { JobDescriptionStatus } from '@/types/JobDescription'
import JobDescriptionService from '@/services/JobDescriptionService'
import _ from 'lodash'

export const namespaced = true

function setCandidatJobDescriptions(commit: Function, response: any): void {
    commit('SET_CANDIDAT_JOB_DESCRIPTIONS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateCandidatJobDescriptions = {
    candidatJobDescriptions: [],
    candidatJobDescriptionSelect: null,
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0
}

export const mutations = {
    RESET_STATE(state: StateCandidatJobDescriptions): void {
        Object.assign(state, {
            candidatJobDescriptions: [],
            candidatJobDescriptionSelect: null,
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0
        })
    },
    SET_CANDIDAT_JOB_DESCRIPTIONS(state: any, candidatJobDescriptions: Array<CandidatJobDescriptionInterface>): void {
        state.candidatJobDescriptions = candidatJobDescriptions
    },
    SET_MORE_CANDIDAT_JOB_DESCRIPTIONS(state: any, candidatJobDescriptions: Array<CandidatJobDescriptionInterface>): void {
        state.candidatJobDescriptions = state.candidatJobDescriptions.concat(candidatJobDescriptions)
    },
    SET_CANDIDAT_JOB_DESCRIPTION_SELECT(state: any, jobDescription: CandidatJobDescriptionInterface): void {
        state.candidatJobDescriptionSelect = jobDescription
    },
    UPDATE_CANDIDAT_JOB_DESCRIPTION(state: any, datas: { jobDescription: CandidatJobDescriptionInterface | CandidatJobDescriptionInterface[]; targetId: number }): void {
        const updateStore = (jobDescription: CandidatJobDescriptionInterface): void => {
            const index: number = state.candidatJobDescriptions.findIndex((item: CandidatJobDescriptionInterface): boolean => item.id === jobDescription.id)
            if (index !== -1) {
                state.candidatJobDescriptions[index] = Object.assign({}, state.candidatJobDescriptions[index], jobDescription)

                if (state.candidatJobDescriptions[index].id === datas.targetId) {
                    state.candidatJobDescriptionSelect = state.candidatJobDescriptions[index]
                }
            }
        }

        let firstJobDescription: CandidatJobDescriptionInterface
        if (_.isArray(datas.jobDescription)) {
            datas.jobDescription.forEach((item: CandidatJobDescriptionInterface): void => updateStore(item))
            firstJobDescription = datas.jobDescription[0]
        } else {
            updateStore(datas.jobDescription)
            firstJobDescription = datas.jobDescription
        }

        state.candidatJobDescriptions.forEach((item: CandidatJobDescriptionInterface): void => {
            if (item.job_description_id === firstJobDescription.job_description_id) {
                item.jobDescription = firstJobDescription.jobDescription
            }
        })
    },
    SET_META(state: any, meta: Array<any>): void {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>): void {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean): void {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>): void {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number): void {
        state.totalRows = totalRows
    }
}

export const actions = {
    getCandidatJobDescription({ commit }: { commit: any }, candidatJobDescriptionId: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            CandidatJobDescriptionService.getCandidatJobDescription(candidatJobDescriptionId)
                .then((response): void => {
                    commit('SET_CANDIDAT_JOB_DESCRIPTION_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getCandidatJobDescriptions({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            CandidatJobDescriptionService.getCandidatJobDescriptions(params)
                .then((response): void => {
                    setCandidatJobDescriptions(commit, response)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreCandidatJobDescriptions({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            CandidatJobDescriptionService.getCandidatJobDescriptions(params)
                .then((response): void => {
                    commit('SET_MORE_CANDIDAT_JOB_DESCRIPTIONS', response.data.data)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    putCandidatJobDescriptionStatus({ commit }: { commit: any }, params: { id: number; status: JobDescriptionStatus }) {
        return new Promise((resolve, reject): void => {
            CandidatJobDescriptionService.putCandidatJobDescriptionStatus(params.id, params.status)
                .then((response): void => {
                    commit('UPDATE_CANDIDAT_JOB_DESCRIPTION', { jobDescription: response.data.data, targetId: params.id })
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addCandidacy({ commit }: { commit: any }, payload: any) {
        return new Promise((resolve, reject): void => {
            CandidatJobDescriptionService.addCandidacy(payload)
                .then((response): void => {
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    searchCandidates({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject): void => {
            CandidatJobDescriptionService.searchCandidates(params)
                .then((response): void => {
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getActivityLog({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatJobDescriptionService.getActivityLog(params.candidatJobDescription_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    candidatJobDescriptions: (state: any): Array<CandidatJobDescriptionInterface> => {
        return state.candidatJobDescriptions
    },
    candidatJobDescriptionSelect: (state: any): CandidatJobDescriptionInterface => {
        return state.candidatJobDescriptionSelect
    },
    meta: (state: any): any => {
        return state.meta
    },
    links: (state: any): any => {
        return state.links
    },
    loading: (state: any): boolean => {
        return state.loading
    },
    error: (state: any): any => {
        return state.error
    },
    totalRows: (state: any): number => {
        return state.candidatJobDescriptions.length
    },
    lastPage: (state: any): number => {
        return state.meta?.last_page || 1
    },
    totalPage: (state: any): number => {
        return state.meta?.total || 1
    }
}
