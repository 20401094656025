import { getError } from '@/utils/helpers'
import ReclamationService from '@/services/ReclamationService'
import Vue from 'vue'
import {
    ReclamationsPostConcoursInterface,
    StateReclamationPostConcoursInterface
} from '@/types/ReclamationPostConcours'

export const namespaced = true

function setReclamationsPostConcours(commit: Function, response: any) {
    commit('SET_RECLAMATIONS_POST_CONCOURS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
}

export const state: StateReclamationPostConcoursInterface = {
    reclamations_post_concours: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    reclamationPCSelect: {},
    reclamations_postC_compteurs: []
}

export const mutations = {
    RESET_STATE(state: StateReclamationPostConcoursInterface) {
        Object.assign(state, {
            reclamations_post_concours: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            reclamationPCSelect: {},
            reclamations_postC_compteurs: []
        })
    },
    SET_RECLAMATIONS_POST_CONCOURS(state: any, reclamations: Array<ReclamationsPostConcoursInterface>) {
        state.reclamations_post_concours = reclamations
    },
    SET_MORE_RECLAMATIONS_POST_CONCOURS(state: any, reclamations: Array<ReclamationsPostConcoursInterface>) {
        state.reclamations_post_concours = state.reclamations_post_concours.concat(reclamations)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_RECLAMATION_SELECT(state: any, reclamation: ReclamationsPostConcoursInterface) {
        state.reclamationPCSelect = reclamation
    },
    MAJ_DATAS_RECLAMATION_SELECT_POST_CONCOURS(state: any, params: any) {
        const tableau_copy = state.reclamations_post_concours
        const index = state.reclamations_post_concours.map((item: any) => item.id).indexOf(params.id)
        if (index > -1) {
            const ligne_modif = tableau_copy[index]
            ligne_modif.message_gestionnaire = params.message_gestionnaire
            ligne_modif.submitted_at = params.submitted_at
            ligne_modif.note = params.note
            Vue.set(state.reclamations_post_concours, index, ligne_modif)
        }
    },
    MAJ_RECLAMATION_PC_SELECT(state: any, reclamation: any) {
        const savPCCandidat = state.reclamationPCSelect
        savPCCandidat.message_gestionnaire = reclamation.message_gestionnaire
        savPCCandidat.submitted_at = reclamation.submitted_at
        savPCCandidat.note = reclamation.note
        state.reclamationPCSelect = savPCCandidat
    },
    SET_COMPTEURS_RECLAMATIONS_POSTC(state: any, reclamations_postC_compteurs: Array<any>) {
        state.reclamations_postC_compteurs = reclamations_postC_compteurs
    }
}

export const actions = {
    getReclamations({ commit }: { commit: any }, params: any) {
        commit('SET_ERROR', null)
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.getReclamations(params.filters)
                .then((response) => {
                    setReclamationsPostConcours(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreReclamations({ commit }: { commit: any }, params: any) {
        commit('SET_ERROR', null)
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.getReclamations(params.filters)
                .then((response) => {
                    commit('SET_MORE_RECLAMATIONS_POST_CONCOURS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateReclamation({ commit }: { commit: any }, params: any) {
        commit('SET_ERROR', null)
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.updateReclamation(params.reclamation_id, params.payload)
                .then((response) => {
                    commit('MAJ_DATAS_RECLAMATION_SELECT_POST_CONCOURS', response.data.data)
                    commit('SET_RECLAMATION_SELECT', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    },
    deleteReclamation({ commit }: { commit: any }, params: any) {
        commit('SET_ERROR', null)
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.deleteReclamation(params.reclamation_id)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    addReclamation({ commit }: { commit: any }, params: any) {
        commit('SET_ERROR', null)
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.addReclamation(params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    },
    getReclamation({ commit }: { commit: any }, reclamation_id: any) {
        commit('SET_ERROR', null)
        return new Promise((resolve, reject) => {
            ReclamationService.getReclamation(reclamation_id)
                .then((response) => {
                    commit('SET_RECLAMATION_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getReclamationsCompteurs({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.getReclamationsCompteurs(params.filters)
                .then((response) => {
                    commit('SET_COMPTEURS_RECLAMATIONS_POSTC', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    sendRecorrection({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.sendRecorrection(params.reclamation_id, params.payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    sendRecorrections({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.sendRecorrections(payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {
    reclamations_post_concours: (state: any) => {
        return state.reclamations_post_concours
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.reclamations_post_concours.length
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    reclamationSelect: (state: any) => {
        return state.reclamationPCSelect
    },
    getCptReclamations: (state: any) => {
        const cptDeclarees = state.reclamations_post_concours.filter(
            (r: any) => !r.submitted_at && !r.validated_at
        ).length
        const cptTraitees = state.reclamations_post_concours.filter(
            (r: any) => r.submitted_at && !r.validated_at
        ).length
        const cptSignees = state.reclamations_post_concours.filter((r: any) => r.submitted_at && r.validated_at).length

        return {
            cptDeclarees: cptDeclarees,
            cptTraitees: cptTraitees,
            cptSignees: cptSignees
        }
    },
    PCSelectReclassementOpen: (state: any) => {
        // eslint-disable-next-line no-unneeded-ternary
        const isOpen =
            state.reclamationPCSelect && state.reclamationPCSelect.decision && state.reclamationPCSelect.decision === 1
                ? true
                : false
        return isOpen
    },
    getReclamations_postC_compteurs: (state: any) => {
        return state.reclamations_postC_compteurs
    }
}
