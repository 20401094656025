import * as API from '@/services/API'

export default {
    async getPhase(phaseId: number) {
        return await API.apiClient.get(`/phases/${phaseId}`)
    },
    async getPhases(payload: any) {
        return await API.apiClient.get('/phases', { params: payload })
    },
    async updatePhase(payload: any) {
        return await API.apiClient.put(`/phases/${payload.id}`, payload)
    },
    async addPhase(payload: any) {
        return await API.apiClient.post('/phases', payload)
    },
    async deletePhase(phase_id: number) {
        return await API.apiClient.delete(`/phases/${phase_id}`)
    }
}
