import { getError } from '@/utils/helpers'
import DossierAdministratifService from '@/services/DossierAdministratifService'
import {
    DatasCotisationInterface,
    DatasPersoInterface,
    DatasProInterface,
    DatasSituationInterface,
    DossierAdministratifInterface,
    Etat,
    EtatDepot
} from '@/types/DossierAdministratif'
import { PosteInterface } from '@/types/Poste'
import Vue from 'vue'

export const namespaced = true

function setDossierAdministratif(commit: any, response: any) {
    commit('SET_DOSSIERS_ADMINISTRATIFS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

function maj_etat_dossier(state: any, dossier: any) {
    const dossiers_copy = state.dossiersAdministratifs
    const index = state.dossiersAdministratifs.map((item: any) => item.id).indexOf(dossier.id)

    if (dossiers_copy[index]) {
        dossiers_copy[index].etat = dossier.etat
        dossiers_copy[index].etat_infos_contrat = Etat.ETAT_VIERGE
        if (dossier.etat_form_perso > dossier.etat_form_situation) {
            dossiers_copy[index].etat_infos_contrat = dossier.etat_form_situation
        }
        if (dossier.etat_form_situation > dossier.etat_form_perso) {
            dossiers_copy[index].etat_infos_contrat = dossier.etat_form_perso
        }
        if (dossier.etat_form_perso === Etat.ETAT_DEPOSE && dossier.etat_form_situation === Etat.ETAT_DEPOSE) {
            dossiers_copy[index].etat_infos_contrat = Etat.ETAT_DEPOSE
        } else if (dossier.etat_form_perso === Etat.ETAT_VALIDE && dossier.etat_form_situation === Etat.ETAT_VALIDE) {
            dossiers_copy[index].etat_infos_contrat = Etat.ETAT_VALIDE
        }

        const ligne_modif = dossiers_copy[index]
        Vue.set(state.dossiersAdministratifs, index, ligne_modif)
    }
}

export const state = {
    dossiersAdministratifs: [],
    dossierAdministratifSelect: {},
    contratSelect: null,
    contrats: [],
    reponse_token: null,
    source_pdf: '',
    meta: null,
    links: null,
    loading: false,
    error: null,
    errorUpload: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    viewDossier: false,
    isValide: false,
    avatar: '',
    dossiersAdministratifsOuvertParEtat: [],
    dossiersOpen: [],
    nb_relance: 0,
    postes_affectations_sans_contrat: [],
    contrats_create: [],
    contrats_documents: [],
    set_bulletin: false,
    set_attestation: false
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            dossiersAdministratifs: [],
            dossierAdministratifSelect: {},
            contratSelect: null,
            contrats: [],
            source_pdf: '',
            meta: null,
            links: null,
            loading: false,
            error: null,
            errorUpload: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            viewDossier: false,
            isValide: false,
            avatar: '',
            dossiersAdministratifsOuvertParEtat: [],
            dossiersOpen: [],
            nb_relance: 0,
            postes_affectations_sans_contrat: [],
            contrats_create: [],
            contrats_documents: [],
            set_bulletin: false,
            set_attestation: false
        })
    },
    SET_DOSSIERS_ADMINISTRATIFS_OUVERT_PAR_ETAT(state: any, response: any) {
        state.dossiersAdministratifsOuvertParEtat = response.data.data
    },
    SET_DOSSIERS_ADMINISTRATIFS(state: any, dossiersAdministratifs: Array<DossierAdministratifInterface>) {
        state.dossiersAdministratifs = dossiersAdministratifs

        for (const d in state.dossiersAdministratifs) {
            maj_etat_dossier(state, state.dossiersAdministratifs[d])
            state.dossiersAdministratifs[d].ordres_mission = []

            // Etat des contrats côté responsable + etat signature
            if (state.dossiersAdministratifs[d].contrats && state.dossiersAdministratifs[d].contrats.length !== 0) {
                const contrats_invisibles = state.dossiersAdministratifs[d].contrats.filter(
                    (c: any) => c.etat === Etat.ETAT_INVISIBLE
                )
                const contrats_vierge = state.dossiersAdministratifs[d].contrats.filter(
                    (c: any) => c.etat === Etat.ETAT_VIERGE
                )
                const contrats_intervenant_ok = state.dossiersAdministratifs[d].contrats.filter(
                    (c: any) => c.etat === Etat.ETAT_DEPOSE
                )
                const contrats_total_signatures = state.dossiersAdministratifs[d].contrats.filter(
                    (c: any) => c.etat === Etat.ETAT_VALIDE
                )

                const ordres_mission = state.dossiersAdministratifs[d].contrats.filter(
                    (c: any) => c.ordre_mission !== null
                )

                if (contrats_total_signatures.length !== 0) {
                    state.dossiersAdministratifs[d].etat_contrats_interne = 'success'
                    state.dossiersAdministratifs[d].etat_signature_contrat = 'success'
                    state.dossiersAdministratifs[d].etat_contrats = Etat.ETAT_VALIDE
                }
                if (contrats_intervenant_ok.length !== 0) {
                    state.dossiersAdministratifs[d].etat_contrats_interne = 'info'
                    state.dossiersAdministratifs[d].etat_signature_contrat = 'info'
                    state.dossiersAdministratifs[d].etat_contrats = Etat.ETAT_DEPOSE
                }
                if (contrats_vierge.length !== 0) {
                    state.dossiersAdministratifs[d].etat_contrats_interne = 'secondary'
                    state.dossiersAdministratifs[d].etat_signature_contrat = 'light'
                    state.dossiersAdministratifs[d].etat_contrats = Etat.ETAT_VIERGE
                }
                if (contrats_invisibles.length !== 0) {
                    state.dossiersAdministratifs[d].etat_contrats_interne = 'light'
                    state.dossiersAdministratifs[d].etat_signature_contrat = 'light'
                    state.dossiersAdministratifs[d].etat_contrats = Etat.ETAT_INVISIBLE
                }

                // Check si un ordre de mission est dispo pour le dossier
                if (ordres_mission.length !== 0) {
                    for (const o in ordres_mission) {
                        state.dossiersAdministratifs[d].ordres_mission.push(ordres_mission[o].ordre_mission)
                    }
                }
            } else {
                state.dossiersAdministratifs[d].etat_contrats_interne = 'light'
                state.dossiersAdministratifs[d].etat_signature_contrat = 'light'
            }

            // Gestion des etats PJ
            if (state.dossiersAdministratifs[d].documents && state.dossiersAdministratifs[d].documents.length !== 0) {
                for (const doc in state.dossiersAdministratifs[d].documents) {
                    state.dossiersAdministratifs[d]['etat_doc_' + state.dossiersAdministratifs[d].documents[doc].type] =
                        state.dossiersAdministratifs[d].documents[doc].etat
                }
            }

            // Documents des contrats
            // Check pour chaque contrats si on a un bulletin de salaire et une attestation Pôle emploi
            let count_bulletins = 0
            let count_attestations = 0
            for (const c in state.dossiersAdministratifs[d].contrats) {
                if (state.dossiersAdministratifs[d].contrats[c].documents) {
                    const bulletins = state.dossiersAdministratifs[d].contrats[c].documents.filter(
                        (d: any) => d.type === 'bulletin'
                    )
                    count_bulletins += bulletins.length

                    const attestations = state.dossiersAdministratifs[d].contrats[c].documents.filter(
                        (d: any) => d.type === 'attestation_pole_emploi'
                    )
                    count_attestations += attestations.length
                }
            }

            if (
                state.dossiersAdministratifs[d].contrats &&
                count_bulletins !== 0 &&
                state.dossiersAdministratifs[d].contrats.length === count_bulletins
            ) {
                state.dossiersAdministratifs[d].contrats_has_bulletin = EtatDepot.DEPOT_COMPLET
            } else if (
                state.dossiersAdministratifs[d].contrats &&
                count_bulletins !== state.dossiersAdministratifs[d].contrats.length &&
                state.dossiersAdministratifs[d].contrats.length !== 0 &&
                count_bulletins !== 0
            ) {
                state.dossiersAdministratifs[d].contrats_has_bulletin = EtatDepot.DEPOT_EN_COURS
            } else {
                state.dossiersAdministratifs[d].contrats_has_bulletin = EtatDepot.NON_DEPOSER
            }

            if (
                state.dossiersAdministratifs[d].contrats &&
                count_attestations !== 0 &&
                state.dossiersAdministratifs[d].contrats.length === count_attestations
            ) {
                state.dossiersAdministratifs[d].contrats_has_attestation = EtatDepot.DEPOT_COMPLET
            } else if (
                state.dossiersAdministratifs[d].contrats &&
                count_attestations !== state.dossiersAdministratifs[d].contrats.length &&
                state.dossiersAdministratifs[d].contrats.length !== 0 &&
                count_attestations !== 0
            ) {
                state.dossiersAdministratifs[d].contrats_has_attestation = EtatDepot.DEPOT_EN_COURS
            } else {
                state.dossiersAdministratifs[d].contrats_has_attestation = EtatDepot.NON_DEPOSER
            }
        }
    },
    SET_MORE_DOSSIERS_ADMINISTRATIFS(state: any, dossiersAdministratifs: Array<DossierAdministratifInterface>) {
        state.dossiersAdministratifs = state.dossiersAdministratifs.concat(dossiersAdministratifs)

        for (const d in state.dossiersAdministratifs) {
            state.dossiersAdministratifs[d].ordres_mission = []

            // Etat des contrats côté responsable + etat signature
            if (state.dossiersAdministratifs[d].contrats && state.dossiersAdministratifs[d].contrats.length !== 0) {
                const contrats_invisibles = state.dossiersAdministratifs[d].contrats.filter(
                    (c: any) => c.etat === Etat.ETAT_INVISIBLE
                )
                const contrats_vierge = state.dossiersAdministratifs[d].contrats.filter(
                    (c: any) => c.etat === Etat.ETAT_VIERGE
                )
                const contrats_intervenant_ok = state.dossiersAdministratifs[d].contrats.filter(
                    (c: any) => c.etat === Etat.ETAT_DEPOSE
                )
                const contrats_total_signatures = state.dossiersAdministratifs[d].contrats.filter(
                    (c: any) => c.etat === Etat.ETAT_VALIDE
                )

                const ordres_mission = state.dossiersAdministratifs[d].contrats.filter(
                    (c: any) => c.ordre_mission !== null
                )

                if (contrats_total_signatures.length !== 0) {
                    state.dossiersAdministratifs[d].etat_contrats_interne = 'success'
                    state.dossiersAdministratifs[d].etat_signature_contrat = 'success'
                    state.dossiersAdministratifs[d].etat_contrats = Etat.ETAT_VALIDE
                }
                if (contrats_intervenant_ok.length !== 0) {
                    state.dossiersAdministratifs[d].etat_contrats_interne = 'info'
                    state.dossiersAdministratifs[d].etat_signature_contrat = 'info'
                    state.dossiersAdministratifs[d].etat_contrats = Etat.ETAT_DEPOSE
                }
                if (contrats_vierge.length !== 0) {
                    state.dossiersAdministratifs[d].etat_contrats_interne = 'secondary'
                    state.dossiersAdministratifs[d].etat_signature_contrat = 'light'
                    state.dossiersAdministratifs[d].etat_contrats = Etat.ETAT_VIERGE
                }
                if (contrats_invisibles.length !== 0) {
                    state.dossiersAdministratifs[d].etat_contrats_interne = 'light'
                    state.dossiersAdministratifs[d].etat_signature_contrat = 'light'
                    state.dossiersAdministratifs[d].etat_contrats = Etat.ETAT_INVISIBLE
                }

                // Check si un ordre de mission est dispo pour le dossier
                if (ordres_mission.length !== 0) {
                    for (const o in ordres_mission) {
                        state.dossiersAdministratifs[d].ordres_mission.push(ordres_mission[o].ordre_mission)
                    }
                }
            } else {
                state.dossiersAdministratifs[d].etat_contrats_interne = 'light'
                state.dossiersAdministratifs[d].etat_signature_contrat = 'light'
            }

            // Gestion des etats PJ
            if (state.dossiersAdministratifs[d].documents && state.dossiersAdministratifs[d].documents.length !== 0) {
                for (const doc in state.dossiersAdministratifs[d].documents) {
                    state.dossiersAdministratifs[d]['etat_doc_' + state.dossiersAdministratifs[d].documents[doc].type] =
                        state.dossiersAdministratifs[d].documents[doc].etat
                }
            }

            // Documents des contrats
            // Check pour chaque contrats si on a un bulletin de salaire et une attestation Pôle emploi
            let count_bulletins = 0
            let count_attestations = 0
            for (const c in state.dossiersAdministratifs[d].contrats) {
                if (state.dossiersAdministratifs[d].contrats[c].documents) {
                    const bulletins = state.dossiersAdministratifs[d].contrats[c].documents.filter(
                        (d: any) => d.type === 'bulletin'
                    )
                    count_bulletins += bulletins.length

                    const attestations = state.dossiersAdministratifs[d].contrats[c].documents.filter(
                        (d: any) => d.type === 'attestation_pole_emploi'
                    )
                    count_attestations += attestations.length
                }
            }

            if (
                count_bulletins !== 0 &&
                state.dossiersAdministratifs[d].contrats &&
                state.dossiersAdministratifs[d].contrats.length === count_bulletins
            ) {
                state.dossiersAdministratifs[d].contrats_has_bulletin = EtatDepot.DEPOT_COMPLET
            } else if (
                state.dossiersAdministratifs[d].contrats &&
                count_bulletins !== state.dossiersAdministratifs[d].contrats.length &&
                state.dossiersAdministratifs[d].contrats.length !== 0 &&
                count_bulletins !== 0
            ) {
                state.dossiersAdministratifs[d].contrats_has_bulletin = EtatDepot.DEPOT_EN_COURS
            } else {
                state.dossiersAdministratifs[d].contrats_has_bulletin = EtatDepot.NON_DEPOSER
            }

            if (
                count_attestations !== 0 &&
                state.dossiersAdministratifs[d].contrats &&
                state.dossiersAdministratifs[d].contrats.length === count_attestations
            ) {
                state.dossiersAdministratifs[d].contrats_has_attestation = EtatDepot.DEPOT_COMPLET
            } else if (
                state.dossiersAdministratifs[d].contrats &&
                count_attestations !== state.dossiersAdministratifs[d].contrats.length &&
                state.dossiersAdministratifs[d].contrats.length !== 0 &&
                count_attestations !== 0
            ) {
                state.dossiersAdministratifs[d].contrats_has_attestation = EtatDepot.DEPOT_EN_COURS
            } else {
                state.dossiersAdministratifs[d].contrats_has_attestation = EtatDepot.NON_DEPOSER
            }
        }
    },
    SET_DOSSIER_ADMINISTRATIF_CURRENT(state: any, dossierAdministratif: DossierAdministratifInterface) {
        state.dossierAdministratifSelect = dossierAdministratif
        state.dossierAdministratifSelect.hasAvatar = false
        state.avatar = null
        if (state.dossierAdministratifSelect.documents) {
            // Check si on a un avatar sur le dossier
            for (const item of state.dossierAdministratifSelect.documents) {
                if (item.type === 'photo') {
                    state.dossierAdministratifSelect.hasAvatar = true
                }
            }
        }

        // MAJ des datas persos
        let datas_perso: DatasPersoInterface
        if (!dossierAdministratif.datas_perso || dossierAdministratif.datas_perso === null) {
            datas_perso = {
                civility: '',
                name: '',
                first_name: '',
                birth_name: '',
                birth_date: new Date(),
                birth_place: '',
                nationality: '',
                numero_secu: '',
                address: '',
                email: '',
                telephone: '',
                telephone_fixe: ''
            }
            state.dossierAdministratifSelect.datas_perso = datas_perso
        }

        // MAJ situations & cotisations
        let situations: Array<DatasSituationInterface>
        let cotisations: Array<DatasCotisationInterface>

        // Check la version du dossier adm pour la structure des infos statutaires
        if (dossierAdministratif.datas_situation && !dossierAdministratif.datas_situation.version) {
            // Converti la structure
            state.dossierAdministratifSelect.datas_situation = {
                version: 2,
                situations: dossierAdministratif.datas_situation,
                cotisations: []
            }
        }
        if (!dossierAdministratif.datas_situation || dossierAdministratif.datas_situation === null) {
            situations = []
            cotisations = []

            state.dossierAdministratifSelect.datas_situation = {
                version: 2,
                situations: situations,
                cotisations: cotisations
            }
        }

        // MAJ des datas pros
        let datas_pro: DatasProInterface
        if (!dossierAdministratif.datas_pro || dossierAdministratif.datas_pro === null) {
            datas_pro = { function_name: '', function_email: '', function_telephone: '', function_address: '' }
            state.dossierAdministratifSelect.datas_pro = datas_pro
        }
    },
    SET_CONTRAT_CURRENT(state: any, contrat: any) {
        state.contratSelect = contrat
    },
    SET_CONTRATS(state: any, contrats: any) {
        state.contrats = contrats
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_ERROR_UPLOAD(state: any, error: Array<any>) {
        state.errorUpload = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    UPDATE_DATAS_NAME(state: any, value: string) {
        state.dossierAdministratifSelect.datas_perso.name = value
    },
    UPDATE_DATAS_FIRST_NAME(state: any, value: string) {
        state.dossierAdministratifSelect.datas_perso.first_name = value
    },
    UPDATE_DATAS_CIVILITY(state: any, value: string) {
        state.dossierAdministratifSelect.datas_perso.civility = value
    },
    UPDATE_DATAS_BIRTH_NAME(state: any, value: string) {
        state.dossierAdministratifSelect.datas_perso.birth_name = value
    },
    UPDATE_DATAS_BIRTH_DATE(state: any, value: string) {
        state.dossierAdministratifSelect.datas_perso.birth_date = value
    },
    UPDATE_DATAS_BIRTH_PLACE(state: any, value: string) {
        state.dossierAdministratifSelect.datas_perso.birth_place = value
    },
    UPDATE_DATAS_NATIONALITY(state: any, value: string) {
        state.dossierAdministratifSelect.datas_perso.nationality = value
    },
    UPDATE_DATAS_NUMERO_SECU(state: any, value: string) {
        state.dossierAdministratifSelect.datas_perso.numero_secu = value
    },
    UPDATE_DATAS_EMAIL(state: any, value: string) {
        state.dossierAdministratifSelect.datas_perso.email = value
    },
    UPDATE_DATAS_TELEPHONE(state: any, value: string) {
        if (value !== '') {
            state.dossierAdministratifSelect.datas_perso.telephone = value
        }
    },
    UPDATE_DATAS_ADDRESS(state: any, value: string) {
        state.dossierAdministratifSelect.datas_perso.address = value
    },
    UPDATE_FUNCTION_NAME(state: any, value: string) {
        state.dossierAdministratifSelect.datas_pro.function_name = value
    },
    UPDATE_FUNCTION_EMAIL(state: any, value: string) {
        state.dossierAdministratifSelect.datas_pro.function_email = value
    },
    UPDATE_FUNCTION_TELEPHONE(state: any, value: string) {
        state.dossierAdministratifSelect.datas_pro.function_telephone = value
    },
    UPDATE_FUNCTION_ADDRESS(state: any, value: string) {
        state.dossierAdministratifSelect.datas_pro.function_address = value
    },
    SET_IS_VALIDE(state: any, dossierAdmin: any) {
        if (
            dossierAdmin.etat_form_perso === Etat.ETAT_DEPOSE &&
            dossierAdmin.etat_form_situation === Etat.ETAT_DEPOSE &&
            dossierAdmin.submitted_at !== null
        ) {
            state.isValide = true
        } else {
            state.isValide = false
        }
    },
    MAJ_ETAT(state: any, params: any) {
        const dossierSel = state.dossiersAdministratifs.find((dossier: any) => {
            return dossier.id === params.dossier_id
        })
        dossierSel.etat = params.etat
    },
    SET_DOSSIER_ADMINISTRATIF_AVATAR(state: any, avatar: string) {
        state.avatar = avatar
    },
    SET_VIEW_DOSSIER(state: any, view: boolean) {
        state.viewDossier = view
    },
    SET_PDF_SOURCE(state: any, source: string) {
        state.source_pdf = source
    },
    SET_REPONSE_TOKEN(state: any, response: any) {
        state.reponse_token = response
    },
    SET_DOSSIERS_OPEN(state: any, dossiers_open: Array<DossierAdministratifInterface>) {
        state.dossiersOpen = dossiers_open.length
    },
    SET_RELANCE_NB(state: any, nb_relance: number) {
        state.nb_relance = nb_relance
    },
    SET_POSTES_AFFECTATIONS_SANS_CONTRAT(state: any, postes_affectations: Array<PosteInterface>) {
        state.postes_affectations_sans_contrat = postes_affectations
    },
    SET_CONTRATS_CREATE(state: any, contrats: Array<any>) {
        state.contrats_create = contrats
    },
    SET_COLLECTION_DOCUMENTS_CONTRAT(state: any, documents: any) {
        state.contrats_documents = state.contrats_documents.concat(documents)
    },
    SET_BULLETINS(state: any, etat: boolean) {
        state.set_bulletin = etat
    },
    SET_ATTESTATIONS(state: any, etat: boolean) {
        state.set_attestation = etat
    },
    MAJ_PRESENCE_BULLETINS(state: any, params: any) {
        const dossiers_copy = state.dossiersAdministratifs
        const index = state.dossiersAdministratifs.map((item: any) => item.id).indexOf(params.dossier_id)

        dossiers_copy[index].contrats_has_bulletin = params.etat
        const ligne_modif = dossiers_copy[index]

        Vue.set(state.dossiersAdministratifs, index, ligne_modif)
    },
    MAJ_PRESENCE_ATTESTATIONS(state: any, params: any) {
        const dossiers_copy = state.dossiersAdministratifs
        const index = state.dossiersAdministratifs.map((item: any) => item.id).indexOf(params.dossier_id)
        dossiers_copy[index].contrats_has_attestation = params.etat
        const ligne_modif = dossiers_copy[index]
        Vue.set(state.dossiersAdministratifs, index, ligne_modif)
    },
    MAJ_ETATS_DOSSIERS(state: any, dossier: any) {
        maj_etat_dossier(state, dossier)
        /*
        const dossiers_copy = state.dossiersAdministratifs
        const index = state.dossiersAdministratifs.map((item: any) => item.id).indexOf(dossier.id)

        if (dossiers_copy[index]) {
            dossiers_copy[index].etat = dossier.etat
            dossiers_copy[index].etat_infos_contrat = Etat.ETAT_VIERGE
            if (dossier.etat_form_perso > dossier.etat_form_situation) {
                dossiers_copy[index].etat_infos_contrat = dossier.etat_form_situation
            }
            if (dossier.etat_form_situation > dossier.etat_form_perso) {
                dossiers_copy[index].etat_infos_contrat = dossier.etat_form_perso
            }
            if (dossier.etat_form_perso === Etat.ETAT_DEPOSE && dossier.etat_form_situation === Etat.ETAT_DEPOSE) {
                dossiers_copy[index].etat_infos_contrat = Etat.ETAT_DEPOSE
            } else if (dossier.etat_form_perso === Etat.ETAT_VALIDE && dossier.etat_form_situation === Etat.ETAT_VALIDE) {
                dossiers_copy[index].etat_infos_contrat = Etat.ETAT_VALIDE
            }

            const ligne_modif = dossiers_copy[index]
            Vue.set(state.dossiersAdministratifs, index, ligne_modif)
        }
        */
    },
    MAJ_ORDRE_MISSION(state: any, params: any) {
        const dossiers_copy = state.dossiersAdministratifs
        const index = state.dossiersAdministratifs.map((item: any) => item.id).indexOf(params.dossier_id)

        // Maj état ordres missions
        if (dossiers_copy[index].ordres_mission) {
            for (const o in dossiers_copy[index].ordres_mission) {
                if (dossiers_copy[index].ordres_mission[o].id === params.ordre_mission.id) {
                    dossiers_copy[index].ordres_mission[o] = params.ordre_mission
                }
            }
            const ligne_modif = dossiers_copy[index]
            Vue.set(state.dossiersAdministratifs, index, ligne_modif)
        }
    },
    MAJ_CONTRATS(state: any, contrat: any) {
        const dossiers_copy = state.dossiersAdministratifs
        const index = state.dossiersAdministratifs.map((item: any) => item.id).indexOf(contrat.dossier_administratif_id)

        // Maj état contrats
        if (dossiers_copy[index]) {
            dossiers_copy[index].contrats.push(contrat)
            const ligne_modif = dossiers_copy[index]
            Vue.set(state.dossiersAdministratifs, index, ligne_modif)
        }
    },
    MAJ_CONTRATS_UPDATE(state: any, contrat: any) {
        const dossiers_copy = state.dossiersAdministratifs
        const index = state.dossiersAdministratifs.map((item: any) => item.id).indexOf(contrat.dossier_administratif_id)

        // Maj état contrats
        const index_contrat = dossiers_copy[index].contrats.map((item: any) => item.id).indexOf(contrat.id)
        Vue.set(dossiers_copy[index].contrats, index_contrat, contrat)

        if (dossiers_copy[index].contrats && dossiers_copy[index].contrats.length !== 0) {
            const contrats_invisibles = dossiers_copy[index].contrats.filter((c: any) => c.etat === Etat.ETAT_INVISIBLE)
            const contrats_vierge = dossiers_copy[index].contrats.filter((c: any) => c.etat === Etat.ETAT_VIERGE)
            const contrats_intervenant_ok = dossiers_copy[index].contrats.filter(
                (c: any) => c.etat === Etat.ETAT_DEPOSE
            )
            const contrats_total_signatures = dossiers_copy[index].contrats.filter(
                (c: any) => c.etat === Etat.ETAT_VALIDE
            )

            if (contrats_total_signatures.length !== 0) {
                dossiers_copy[index].etat_contrats_interne = 'success'
                dossiers_copy[index].etat_signature_contrat = 'success'
            }
            if (contrats_intervenant_ok.length !== 0) {
                dossiers_copy[index].etat_contrats_interne = 'info'
                dossiers_copy[index].etat_signature_contrat = 'info'
            }
            if (contrats_vierge.length !== 0) {
                dossiers_copy[index].etat_contrats_interne = 'secondary'
                dossiers_copy[index].etat_signature_contrat = 'light'
            }
            if (contrats_invisibles.length !== 0) {
                dossiers_copy[index].etat_contrats_interne = 'light'
                dossiers_copy[index].etat_signature_contrat = 'light'
            }
        } else {
            dossiers_copy[index].etat_contrats_interne = 'light'
            dossiers_copy[index].etat_signature_contrat = 'light'
        }

        const ligne_modif = dossiers_copy[index]
        Vue.set(state.dossiersAdministratifs, index, ligne_modif)
    }
}

export const actions = {
    getDossiersAdministratifs({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.getDossiersAdministratifs(params)
                .then((response) => {
                    setDossierAdministratif(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreDossiersAdministratifs({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.getDossiersAdministratifs(params)
                .then((response) => {
                    commit('SET_MORE_DOSSIERS_ADMINISTRATIFS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDossierAdministratif({ commit }: { commit: any }, dossier_id: number) {
        return new Promise((resolve, reject) => {
            DossierAdministratifService.getDossierAdministratif(dossier_id)
                .then((response) => {
                    commit('SET_DOSSIER_ADMINISTRATIF_CURRENT', response.data.data)
                    commit('document/SET_DOCUMENTS', response.data.data.documents, { root: true })
                    commit('MAJ_ETATS_DOSSIERS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateDossierAdministratif({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DossierAdministratifService.updateDossierAdministratif(params.dossier_id, params.payload)
                .then((response) => {
                    commit('SET_DOSSIER_ADMINISTRATIF_CURRENT', response.data.data)
                    commit('SET_IS_VALIDE', response.data.data)
                    commit('MAJ_ETATS_DOSSIERS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getAvatar({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DossierAdministratifService.getAvatar(params.dossier_id)
                .then((response) => {
                    commit('SET_DOSSIER_ADMINISTRATIF_AVATAR', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    storeAvatar({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DossierAdministratifService.storeAvatar(params.dossier_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    storeCodeIntervenant({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DossierAdministratifService.storeCodeIntervenant(params.dossier_id, params.payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    storeContrat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.storeContrat(params.dossier_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('MAJ_CONTRATS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateContrat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.updateContrat(params.dossier_id, params.contrat_id, params.payload)
                .then((response) => {
                    commit('SET_CONTRAT_CURRENT', response.data)
                    commit('MAJ_CONTRATS_UPDATE', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getContrats({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.getContrats(params.dossier_id)
                .then((response) => {
                    commit('SET_CONTRATS', response.data.data)
                    commit('SET_LOADING', false)
                    if (params.mode === 'bulletin') {
                        commit('SET_BULLETINS', true)
                        commit('SET_ATTESTATIONS', false)
                    } else if (params.mode === 'attestation') {
                        commit('SET_BULLETINS', false)
                        commit('SET_ATTESTATIONS', true)
                    }
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getContrat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.getContrat(params.dossier_id, params.contrat_id)
                .then((response) => {
                    commit('SET_CONTRAT_CURRENT', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getContratPDF({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.getPDF(params.dossier_id, params.contrat_id)
                .then((response) => {
                    commit('SET_PDF_SOURCE', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDossierPDF({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.getDossierPDF(params.dossier_id)
                .then((response) => {
                    commit('SET_PDF_SOURCE', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteContrat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.deleteContrat(params.dossier_id, params.contrat_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getTokenSignature({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.getTokenSignature(params.dossier_id, params.contrat_id, params.mode)
                .then((response) => {
                    commit('SET_REPONSE_TOKEN', response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDossiersAdministratifsOuvert({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            DossierAdministratifService.getDossierAdministratifOuvert(params.filter)
                .then((response) => {
                    commit('SET_DOSSIERS_ADMINISTRATIFS_OUVERT_PAR_ETAT', response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    ouvrirDossiersAdministratifs({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.ouvrirDossierAdministratif(params)
                .then((response) => {
                    commit('SET_DOSSIERS_OPEN', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    relaunchDossiersAdministratifs({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.relaunchDossierAdministratif(params)
                .then((response) => {
                    commit('SET_DOSSIERS_OPEN', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    relaunchContratsDossiersAdministratifs({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.relaunchContratDossierAdministratif(params)
                .then((response) => {
                    commit('SET_DOSSIERS_OPEN', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    relaunchOrdreMissionDossiersAdministratifs({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.relaunchOrdreMissionDossierAdministratif(params.payload)
                .then((response) => {
                    commit('SET_RELANCE_NB', response.data.data.length)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPostesAffectationsSansContrat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.getPostesAffectationsSansContrat(params)
                .then((response) => {
                    commit('SET_POSTES_AFFECTATIONS_SANS_CONTRAT', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    storeMultiContrats({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.storeMultiContrats(params.payload)
                .then((response) => {
                    commit('SET_CONTRATS_CREATE', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDocumentsContrat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.getDocumentsContrat(params.dossier_id, params.contrat_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    uploadDocumentContrat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.uploadDocumentContrat(params.dossier_id, params.contrat_id, params.payload)
                .then((response) => {
                    commit('SET_COLLECTION_DOCUMENTS_CONTRAT', response.data.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPDFDocumentContrat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.getPdfDocumentContrat(params.dossier_id, params.contrat_id, params.document_id)
                .then((response) => {
                    commit('SET_PDF_SOURCE', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteDocumentContrat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.deleteDocumentContrat(params.dossier_id, params.contrat_id, params.document_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    uploadArchive({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.uploadArchive(params.payload)
                .then((response) => {
                    commit('SET_COLLECTION_DOCUMENTS_CONTRAT', response.data.data)
                    commit('SET_LOADING', false)
                    console.log('response', response)
                    resolve(response)
                })
                .catch((error) => {
                    console.log('error uploadArchive', error)
                    commit('SET_LOADING', false)
                    commit('SET_ERROR_UPLOAD', error)
                    reject(error)
                })
        })
    },
    getExportPaie({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.exportPaie(params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    uploadQuantity({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            DossierAdministratifService.uploadQuantity(params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    }
}

export const getters = {
    loading: (state: any) => {
        return state.loading
    },
    dossiersAdministratifs: (state: any) => {
        return state.dossiersAdministratifs
    },
    dossierAdministratifSelect: (state: any) => {
        return state.dossierAdministratifSelect
    },
    contrats: (state: any) => {
        return state.contrats
    },
    avatar: (state: any) => {
        return state.avatar
    },
    totalRows: (state: any) => {
        return state.totalRows
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    viewDossier: (state: any) => {
        return state.viewDossier
    },
    error: (state: any) => {
        return state.error
    },
    errorUpload: (state: any) => {
        return state.errorUpload
    },
    isValide: (state: any) => {
        return state.isValide
    },
    contratSelect: (state: any) => {
        return state.contratSelect
    },
    sourcePdf: (state: any) => {
        return state.source_pdf
    },
    responseToken: (state: any) => {
        return state.response_token
    },
    postes_affectations_sans_contrat: (state: any) => {
        return state.postes_affectations_sans_contrat
    },
    intervenantFonctionnaire: (state: any) => {
        const isFonctionnaire = state.dossierAdministratifSelect.datas_situation.situations.find(
            (f: any) => f.name === 'Fonctionnaire'
        )
        return isFonctionnaire ? isFonctionnaire.selected : false
    },
    intervenantASP: (state: any) => {
        const isASP = state.dossierAdministratifSelect.datas_situation.situations.find(
            (f: any) => f.name === 'Agent du secteur public'
        )
        return isASP ? isASP.selected : false
    },
    contrats_documents: (state: any) => {
        return state.contrats_documents
    }
}
