import { getError } from '@/utils/helpers'
import ReclamationService from '@/services/ReclamationService'
import { ReclamationsInterface, StateReclamationsInterface, TypeReclamation } from '@/types/Reclamation'
import Vue from 'vue'

export const namespaced = true

function setReclamationsEcrit(commit: Function, response: any) {
    commit('SET_RECLAMATIONS_ECRIT', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
}

export const state: StateReclamationsInterface = {
    reclamations_ecrit: [],
    reclamations_oral: [],
    reclamations_post: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    currentPage: 1,
    lastPage: 0,
    totalPage: 0,
    reclamationSelect: {},
    reclamations_ecrit_compteurs: []
}

export const mutations = {
    RESET_STATE(state: StateReclamationsInterface) {
        Object.assign(state, {
            reclamations_ecrit: [],
            reclamations_oral: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            currentPage: 1,
            lastPage: 0,
            totalPage: 0,
            reclamationSelect: {},
            reclamations_ecrit_compteurs: []
        })
    },
    SET_RECLAMATIONS_ECRIT(state: any, reclamations: Array<ReclamationsInterface>) {
        state.reclamations_ecrit = reclamations
    },
    SET_MORE_RECLAMATIONS_ECRIT(state: any, reclamations: Array<ReclamationsInterface>) {
        state.reclamations_ecrit = state.reclamations_ecrit.concat(reclamations)
    },
    SET_RECLAMATIONS_ORAL(state: any, reclamations: Array<ReclamationsInterface>) {
        state.reclamations_oral = reclamations
    },
    SET_MORE_RECLAMATIONS_ORAL(state: any, reclamations: Array<ReclamationsInterface>) {
        state.reclamations_oral = state.reclamations_oral.concat(reclamations)
    },
    SET_RECLAMATIONS_POST(state: any, reclamations: Array<ReclamationsInterface>) {
        state.reclamations_post = reclamations
    },
    SET_MORE_RECLAMATIONS_POST(state: any, reclamations: Array<ReclamationsInterface>) {
        state.reclamations_post = state.reclamations_post.concat(reclamations)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    SET_TOTAL_PAGE(state: any, totalPage: number) {
        state.totalPage = totalPage
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_RECLAMATION_SELECT(state: any, reclamation: ReclamationsInterface) {
        state.reclamationSelect = reclamation
    },
    MAJ_DATAS_RECLAMATION_SELECT_ECRIT(state: any, params: any) {
        const tableau_copy = state.reclamations_ecrit
        const index = state.reclamations_ecrit.map((item: any) => item.id).indexOf(params.id)
        if (index > -1) {
            const ligne_modif = tableau_copy[index]
            ligne_modif.message_gestionnaire = params.message_gestionnaire
            ligne_modif.submitted_at = params.submitted_at
            ligne_modif.note = params.note
            Vue.set(state.reclamations_ecrit, index, ligne_modif)
        }
    },
    MAJ_DATAS_RECLAMATION_SELECT_ORAL(state: any, params: any) {
        const tableau_copy = state.reclamations_oral
        const index = state.reclamations_oral.map((item: any) => item.id).indexOf(params.id)
        if (index > -1) {
            const ligne_modif = tableau_copy[index]
            ligne_modif.message_gestionnaire = params.message_gestionnaire
            ligne_modif.submitted_at = params.submitted_at
            ligne_modif.note = params.note
            Vue.set(state.reclamations_oral, index, ligne_modif)
        }
    },
    MAJ_DATAS_RECLAMATION_SELECT_POST(state: any, params: any) {
        const tableau_copy = state.reclamations_ecrit
        const index = state.reclamations_ecrit.map((item: any) => item.id).indexOf(params.id)
        if (index > -1) {
            const ligne_modif = tableau_copy[index]
            ligne_modif.message_gestionnaire = params.message_gestionnaire
            ligne_modif.submitted_at = params.submitted_at
            ligne_modif.note = params.note
            Vue.set(state.reclamations_post, index, ligne_modif)
        }
    },
    SET_COMPTEURS_RECLAMATIONS_ECRIT(state: any, reclamations_ecrit_compteurs: Array<any>) {
        state.reclamations_ecrit_compteurs = reclamations_ecrit_compteurs
    }
}

export const actions = {
    getReclamations({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.getReclamations(params.filters)
                .then((response) => {
                    setReclamationsEcrit(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreReclamations({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.getReclamations(params.filters)
                .then((response) => {
                    if (params.type === 'ECRIT') {
                        commit('SET_MORE_RECLAMATIONS_ECRIT', response.data.data)
                    } else if (params.type === 'ORAL') {
                        commit('SET_MORE_RECLAMATIONS_ORAL', response.data.data)
                    } else if (params.type === TypeReclamation.TYPE_NOTE_ORAL) {
                        commit('SET_MORE_RECLAMATIONS_POST', response.data.data)
                    }

                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
                    commit('SET_TOTALROWS', state.totalRows + response.data.data.length)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateReclamation({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.updateReclamation(params.reclamation_id, params.payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    if (params.type === 'ECRIT') {
                        commit('MAJ_DATAS_RECLAMATION_SELECT_ECRIT', response.data.data)
                    } else if (params.type === 'ORAL') {
                        commit('MAJ_DATAS_RECLAMATION_SELECT_ORAL', response.data.data)
                    } else if (params.type === TypeReclamation.TYPE_NOTE_ORAL) {
                        commit('MAJ_DATAS_RECLAMATION_SELECT_POST', response.data.data)
                    }

                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    },
    addReclamation({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.addReclamation(payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error, false))
                    reject(error)
                })
        })
    },
    getReclamationsCompteurs({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.getReclamationsCompteurs(params.filters)
                .then((response) => {
                    commit('SET_COMPTEURS_RECLAMATIONS_ECRIT', response.data)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getPDFReclamationCandidat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.getPDFReclamationCandidat(params.reclamation_id, params.uuid)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })

    },
    getPDFReponseReclamation({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        commit('SET_ERROR', null)
        return new Promise((resolve, reject) => {
            ReclamationService.getPDFReponseReclamation(payload.reclamation_id, payload.params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    export({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.exporter(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    exportStatistiques({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.exporterStatistiques(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    syncRecorrections({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            ReclamationService.syncRecorrections(params)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getSimulationReclamation({ commit }: { commit: any }, params: { reclamation_id: number; note: number }) {
        return new Promise((resolve, reject) => {
            ReclamationService.getSimulationReclamation(params.reclamation_id, params.note)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    reclamations_ecrit: (state: any) => {
        return state.reclamations_ecrit
    },
    reclamations_oral: (state: any) => {
        return state.reclamations_oral
    },
    reclamations_post: (state: any) => {
        return state.reclamations_post
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.reclamations_ecrit.length
    },
    currentPage: (state: any) => {
        return state.currentPage
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    totalPage: (state: any) => {
        return state.totalPage
    },
    reclamationSelect: (state: any) => {
        return state.reclamationSelect
    },
    getCptReclamations: (state: any) => {
        const cptDeclarees = state.reclamations_ecrit.filter((r: any) => !r.submitted_at && !r.validated_at).length
        const cptTraitees = state.reclamations_ecrit.filter((r: any) => r.submitted_at && !r.validated_at).length
        const cptSignees = state.reclamations_ecrit.filter((r: any) => r.submitted_at && r.validated_at).length

        return {
            cptDeclarees: cptDeclarees,
            cptTraitees: cptTraitees,
            cptSignees: cptSignees
        }
    },
    getReclamations_ecrit_compteurs: (state: any) => {
        return state.reclamations_ecrit_compteurs
    }
}
