import { ConcourInterface } from './Concour'
import { MatiereInterface } from './Matiere'

export enum TypePassation {
    TYPE_EPREUVE_MAITRESSE = -1,
    TYPE_PASSATION_EXTERNE = 0,
    TYPE_PASSATION_ECRIT = 1,
    TYPE_PASSATION_ORAL = 2,
    TYPE_PASSATION_TP = 3,
}

interface TypePassationSpec {
    index: number,
    libelle: string,
    icon: string
}

export function getTypePassationSpec(typePassation: TypePassation): TypePassationSpec {
    switch (typePassation) {
        case TypePassation.TYPE_EPREUVE_MAITRESSE:
            return { index: -1, libelle: 'Maîtresse', icon: 'eye' }
        case TypePassation.TYPE_PASSATION_EXTERNE:
            return { index: 0, libelle: 'Externe', icon: 'arrow-circle-right' }
        case TypePassation.TYPE_PASSATION_ECRIT:
            return { index: 1, libelle: 'Écrit', icon: 'file-alt' }
        case TypePassation.TYPE_PASSATION_ORAL:
            return { index: 2, libelle: 'Oral', icon: 'comment-alt' }
        case TypePassation.TYPE_PASSATION_TP:
            return { index: 3, libelle: 'TP', icon: 'tag' }
        default:
            return { index: 0, libelle: 'Externe', icon: 'arrow-circle-right' }
    }
}

export interface EpreuveInterface {
    concours: Array<ConcourInterface> | [],
    concour_id: number | 0,
    id: number | 0,
    epreuve_correction_id?: number | 0,
    code: string | '',
    matiere_id: number | 0,
    session_id: number | 0,
    matiere: MatiereInterface,
    name: string | '',
    long_name: string | '',
    type_passation: number | 0,
    passation_at: Date | null,
    duree: number | 0,
    estimation_nb_candidats: number | 0,
    phase_id: number | 0,
    option: any | null,
    obligatoire: any | null,
    taux_tirage: number | 0,
    tirage_aleatoire: any | null
}

export interface StateEpreuves {
    epreuves: Array<EpreuveInterface> | [],
    epreuvesCorrections: Array<EpreuveInterface> | [],
    epreuvesCorrectionsPrecedent: Array<EpreuveInterface> | [],
    byMatieres: Array<any> | [],
    matieres: Array<any> | [],
    pvs: Array<PvInterface> | [],
    postes: any | null,
    besoinCourant: any | null,
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    currentPage: number | 0,
    lastPage: number | 0,
    totalPage: number | 0,
    sortby: string | '',
    typeEpreuves: Array<any> | []
}

export interface PvInterface {
    id: number | 0,
    sessionId: number | 0,
    matiereId: number | 0,
    epreuvecorrectionsCount: number | 0,
    matiere: {
        id: number | 0,
        name: string | ''
    },
    datas: any | null,
    besoins: any | null,
    submittedAt: any | null,
    validatedAt: any | null
}
