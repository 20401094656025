// reclamation_types
import * as API from '@/services/API'
import store from '@/store'

export default {
    async getReclamationType(ReclamationTypeId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/reclamation_types/${ReclamationTypeId}`)
    },
    async getReclamationTypes(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/reclamation_types`, { params: payload })
    },
    async updateReclamationType(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/reclamation_types/${payload.id}`, payload)
    },
    async addReclamationType(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/reclamation_types`, payload)
    },
    async deleteReclamationType(ReclamationType_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/reclamation_types/${ReclamationType_id}`)
    }
}
