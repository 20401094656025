import * as API from '@/services/API'
import store from '@/store'

export default {
    async getGroupeExaminateurUser(groupeExaminateurUserId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/groupe_examinateur_users/${groupeExaminateurUserId}`)
    },
    async getGroupeExaminateurUsers(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/groupe_examinateur_users`, { params: payload })
    },
    async updateGroupeExaminateurUser(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/groupe_examinateur_users/${payload.id}`, payload)
    },
    async addGroupeExaminateurUser(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/groupe_examinateur_users`, payload)
    },
    async deleteGroupeExaminateurUser(groupeExaminateurUser_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(
            `/sessions/${session_id}/groupe_examinateur_users/${groupeExaminateurUser_id}`
        )
    }
}
