import { getError } from '@/utils/helpers'
import EpreuveCorrectionResultatService from '@/services/EpreuveCorrectionResultatService'
import { EpreuveCorrectionResultatInterface, StateEpreuveCorrectionResultats } from '@/types/EpreuveCorrectionResultat'

export const namespaced = true

function setEpreuveCorrectionResultats(commit: Function, response: any) {
    commit('SET_EPREUVE_CORRECTION_RESULTATS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LOADING', false)
}

export const state: StateEpreuveCorrectionResultats = {
    epreuveCorrectionResultats: [] as StateEpreuveCorrectionResultats['epreuveCorrectionResultats'],
    meta: null as StateEpreuveCorrectionResultats['meta'],
    loading: false as StateEpreuveCorrectionResultats['loading'],
    error: null as StateEpreuveCorrectionResultats['error'],
    notes: null as StateEpreuveCorrectionResultats['notes'],
    notes_globales: null as StateEpreuveCorrectionResultats['notes_globales'],
    epreuveCorrectionResultatSelect: {} as StateEpreuveCorrectionResultats['epreuveCorrectionResultatSelect'],
    epreuveCorrectionCorrecteurs: null as any
}

export const mutations = {
    RESET_STATE(state: StateEpreuveCorrectionResultats) {
        Object.assign(state, {
            epreuveCorrectionResultats: [] as StateEpreuveCorrectionResultats['epreuveCorrectionResultats'],
            meta: null as StateEpreuveCorrectionResultats['meta'],
            loading: false as StateEpreuveCorrectionResultats['loading'],
            notes: null as StateEpreuveCorrectionResultats['notes'],
            notes_globales: null as StateEpreuveCorrectionResultats['notes_globales'],
            epreuveCorrectionResultatSelect: {} as StateEpreuveCorrectionResultats['epreuveCorrectionResultatSelect'],
            epreuveCorrectionCorrecteurs: null as any
        })
    },
    SET_EPREUVE_CORRECTION_RESULTATS(state: any, epreuveCorrectionResultats: Array<EpreuveCorrectionResultatInterface>): void {
        state.epreuveCorrectionResultats = epreuveCorrectionResultats
    },
    SET_EPREUVE_CORRECTION_RESULTATS_NOTES(state: any, notes: any): void {
        state.notes = notes
    },
    SET_EPREUVE_CORRECTION_RESULTATS_NOTES_GLOBALES(state: any, notes: any): void {
        state.notes_globales = notes
    },
    SET_MORE_EPREUVE_CORRECTION_RESULTATS(state: any, epreuveCorrectionResultats: Array<EpreuveCorrectionResultatInterface>): void {
        state.epreuveCorrectionResultats = state.epreuveCorrectionResultats.concat(epreuveCorrectionResultats)
    },
    UPDATE_EPREUVE_CORRECTION_CORRECTEURS(state: any, data: any): void {
        const index = state.epreuveCorrectionCorrecteurs.map((c: any) => c.id).indexOf(data.id)
        state.epreuveCorrectionCorrecteurs[index].ajustements_params = data.ajustements_params
        state.epreuveCorrectionCorrecteurs[index].ecart_type_notes_finales = data.ecart_type_notes_finales
        state.epreuveCorrectionCorrecteurs[index].epreuveCorrectionResultat = data.epreuveCorrectionResultat
        state.epreuveCorrectionCorrecteurs[index].mediane_notes_finales = data.mediane_notes_finales
        state.epreuveCorrectionCorrecteurs[index].moyenne_notes_finales = data.moyenne_notes_finales
        state.epreuveCorrectionCorrecteurs[index].nb_note_max_finales = data.nb_note_max_finales
        state.epreuveCorrectionCorrecteurs[index].nb_note_min_finales = data.nb_note_min_finales
        state.epreuveCorrectionCorrecteurs[index].premier_quartile_notes_finales = data.premier_quartile_notes_finales
        state.epreuveCorrectionCorrecteurs[index].troisieme_quartile_notes_finales = data.troisieme_quartile_notes_finales
    },
    SET_META(state: any, meta: Array<any>): void {
        state.meta = meta
    },
    SET_LOADING(state: any, loading: boolean): void {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>): void {
        state.error = error
    },
    SET_EPREUVE_CORRECTION_RESULTAT_SELECT(state: any, epreuveCorrectionResultat: EpreuveCorrectionResultatInterface): void {
        state.epreuveCorrectionResultatSelect = epreuveCorrectionResultat
    },
    SET_EPREUVE_CORRECTION_CORRECTEURS(state: any, epreuveCorrectionCorrecteurs: Array<any>): void {
        state.epreuveCorrectionCorrecteurs = epreuveCorrectionCorrecteurs
    }
}

export const actions = {
    getEpreuveCorrectionResultats({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            EpreuveCorrectionResultatService.getEpreuveCorrectionResultats(params)
                .then((response): void => {
                    setEpreuveCorrectionResultats(commit, response)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getEpreuveCorrectionResultatsNotes({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            EpreuveCorrectionResultatService.getEpreuveCorrectionResultatsNotes(params.epreuve_correction_id, params.payload)
                .then((response): void => {
                    commit('SET_EPREUVE_CORRECTION_RESULTATS_NOTES', response.data.data)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getEpreuveNotes({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            EpreuveCorrectionResultatService.getEpreuveNotes(params.epreuve_id)
                .then((response): void => {
                    commit('SET_EPREUVE_CORRECTION_RESULTATS_NOTES', response.data.data)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getMoreEpreuveCorrectionResultats({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            EpreuveCorrectionResultatService.getEpreuveCorrectionResultats(params)
                .then((response): void => {
                    commit('SET_MORE_EPREUVE_CORRECTION_RESULTATS', response.data.data)
                    commit('SET_META', response.data.meta)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getEpreuveCorrectionResultat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            EpreuveCorrectionResultatService.getEpreuveCorrectionResultat(params.epreuveCorrectionResultat_id, params.payload)
                .then((response): void => {
                    commit('SET_EPREUVE_CORRECTION_RESULTAT_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error): void => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateEpreuveCorrectionResultat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            EpreuveCorrectionResultatService.updateEpreuveCorrectionResultat(params)
                .then((response): void => {
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    addEpreuveCorrectionResultat({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            EpreuveCorrectionResultatService.addEpreuveCorrectionResultat(params)
                .then((response): void => {
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    deleteEpreuveCorrectionResultat({ commit }: { commit: any }, epreuveCorrectionResultat_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            EpreuveCorrectionResultatService.deleteEpreuveCorrectionResultat(epreuveCorrectionResultat_id)
                .then((response): void => {
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    updateEpreuveCorrectionCorrecteur({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            EpreuveCorrectionResultatService.updateEpreuveCorrectionCorrecteur(params)
                .then((response): void => {
                    commit('UPDATE_EPREUVE_CORRECTION_CORRECTEURS', response.data.data)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    saveAdjustement({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            EpreuveCorrectionResultatService.saveAdjustement(params.epreuve_correction_resultat_id, params.adjustement_id, params.payload)
                .then((response): void => {
                    if (!params.prevent_store) {
                        commit('SET_EPREUVE_CORRECTION_RESULTAT_SELECT', response.data.data)
                    }
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    validateAdjustement({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            EpreuveCorrectionResultatService.valideAdjustement(params.epreuve_correction_resultat_id, params.adjustement_id, params.payload)
                .then((response): void => {
                    commit('SET_EPREUVE_CORRECTION_RESULTAT_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    invalidateAdjustement({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            EpreuveCorrectionResultatService.invalidateAdjustement(params.epreuve_correction_resultat_id, params.adjustement_id, params.payload)
                .then((response): void => {
                    commit('SET_EPREUVE_CORRECTION_RESULTAT_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    exportAjustements({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            EpreuveCorrectionResultatService.exportAjustement(params)
                .then((response): void => {
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    exportStatistiquesAjustement({ commit }: { commit: any }, params: { concour_id: number; externes: number }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            EpreuveCorrectionResultatService.exportStatistiquesAjustement(params.concour_id, params.externes)
                .then((response): void => {
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    exportNotesEpreuvesExternes({ commit }: { commit: any }, params: { concour_id: number; phase_id: number }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            EpreuveCorrectionResultatService.exportNotesEpreuvesExternes(params.concour_id, params.phase_id)
                .then((response): void => {
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    exportModeleImportNotes({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            EpreuveCorrectionResultatService.exportModeleImportNotes()
                .then((response): void => {
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    importNotes({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            EpreuveCorrectionResultatService.importNotes(params)
                .then((response): void => {
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {
    epreuveCorrectionResultats: (state: any): StateEpreuveCorrectionResultats['epreuveCorrectionResultats'] => {
        return state.epreuveCorrectionResultats
    },
    meta: (state: any): StateEpreuveCorrectionResultats['meta'] => {
        return state.meta
    },
    links: (state: any) => {
        if (state.meta) {
            return state.meta.links
        } else {
            return null
        }
    },
    loading: (state: any): StateEpreuveCorrectionResultats['loading'] => {
        return state.loading
    },
    error: (state: any): StateEpreuveCorrectionResultats['error'] => {
        return state.error
    },
    total: (state: any): any => {
        return state.meta?.total || state.epreuveCorrectionResultats.length
    },
    currentPage: (state: any): any => {
        return state.meta?.current_page || 1
    },
    lastPage: (state: any): any => {
        return state.meta?.last_page || 1
    },
    totalPage: (state: any): any => {
        return state.meta?.total_page || 1
    },
    sortBy: (state: any): any => {
        return state.meta?.sortby || ''
    },
    totalRows: (state: any): any => {
        return state.epreuveCorrectionResultats.length
    },
    epreuveCorrectionResultatSelect: (state: any): any => {
        return state.epreuveCorrectionResultatSelect
    },
    getEpreuveCorrectionResultatById: (state: any): any => (id: number): any => {
        return state.epreuveCorrectionResultats.filter((m: any): boolean => m.id === id)[0]
    },
    notes: (state: any): any => {
        return state.notes
    },
    epreuveCorrectionCorrecteurs: (state: any): any => {
        return state.epreuveCorrectionCorrecteurs
    },
    dicoNotesByUserId: (state: any): any => (corrector_group_id = 0, nb_class = 20, note_max = 20, epreuve_id = 0): any => {
        let notes_data = state.notes

        let epSource: any = state.epreuveCorrectionResultatSelect

        if (epreuve_id !== 0) {
            for (let i = 0; i < state.epreuveCorrectionResultatSelect.epreuve_correction.epreuves.length; i++) {
                if (state.epreuveCorrectionResultatSelect.epreuve_correction.epreuves[i].id === epreuve_id) {
                    epSource = state.epreuveCorrectionResultatSelect.epreuve_correction.epreuves[i].epreuveResultat
                    break
                }
            }
        }

        let ecart_type_notes_brutes = epSource.ecart_type_notes_brutes
        let ecart_type_notes_finales = epSource.ecart_type_notes_finales

        let mediane_notes_brutes = epSource.mediane_notes_brutes
        let mediane_notes_finales = epSource.mediane_notes_finales

        let moyenne_notes_brutes = epSource.moyenne_notes_brutes
        let moyenne_notes_finales = epSource.moyenne_notes_finales

        let premier_quartile_notes_brutes = epSource.premier_quartile_notes_brutes
        let premier_quartile_notes_finales = epSource.premier_quartile_notes_finales

        let troisieme_quartile_notes_brutes = epSource.troisieme_quartile_notes_brutes
        let troisieme_quartile_notes_finales = epSource.troisieme_quartile_notes_finales

        let nb_note_max_brutes = epSource.nb_note_max_brutes
        let nb_note_max_finales = epSource.nb_note_max_finales

        let nb_note_min_brutes = epSource.nb_note_min_brutes
        let nb_note_min_finales = epSource.nb_note_min_finales

        if (corrector_group_id > 0) {
            notes_data = notes_data.filter((note: any): boolean => note.corrector_group_id === corrector_group_id)
            const ecc = state.epreuveCorrectionCorrecteurs.filter((ecctemp: any): boolean => ecctemp.scope_id === corrector_group_id)[0]
            ecart_type_notes_brutes = ecc.ecart_type_notes_brutes
            ecart_type_notes_finales = ecc.ecart_type_notes_finales

            mediane_notes_brutes = ecc.mediane_notes_brutes
            mediane_notes_finales = ecc.mediane_notes_finales

            moyenne_notes_brutes = ecc.moyenne_notes_brutes
            moyenne_notes_finales = ecc.moyenne_notes_finales

            premier_quartile_notes_finales = ecc.premier_quartile_notes_finales
            premier_quartile_notes_brutes = ecc.premier_quartile_notes_brutes

            troisieme_quartile_notes_finales = ecc.troisieme_quartile_notes_finales
            troisieme_quartile_notes_brutes = ecc.troisieme_quartile_notes_brutes

            nb_note_max_brutes = ecc.nb_note_max_brutes
            nb_note_max_finales = ecc.nb_note_max_finales

            nb_note_min_brutes = ecc.nb_note_min_brutes
            nb_note_min_finales = ecc.nb_note_min_finales
        }
        const dicoNotesBrut: { [note: number]: { total: number } } = {}
        const liste_brute: Array<number> = []
        const dicoNotesfinale: { [note: number]: { total: number } } = {}
        const liste_finale: Array<number> = []
        const liste_ajustements: any = []


        for (const keynotes in notes_data) {
            liste_brute.push(notes_data[keynotes].note_brute)
            liste_finale.push(notes_data[keynotes].note_finale)

            if (!dicoNotesBrut[notes_data[keynotes].note_brute]) {
                dicoNotesBrut[notes_data[keynotes].note_brute] = { total: 0 }
            }
            dicoNotesBrut[notes_data[keynotes].note_brute].total++

            if (!dicoNotesfinale[notes_data[keynotes].note_finale]) {
                dicoNotesfinale[notes_data[keynotes].note_finale] = { total: 0 }
            }
            dicoNotesfinale[notes_data[keynotes].note_finale].total++

            if (notes_data[keynotes].notes) {
                for (const n in notes_data[keynotes].notes) {
                    if (!liste_ajustements[n]) {
                        liste_ajustements[n] = []
                    }
                    liste_ajustements[n].push(notes_data[keynotes].notes[n])
                }
            }
        }

        const tableBruteTotal: any = []
        const tableFinaleTotal: any = []
        const table_ajustements: any = []

        const step: number = note_max / nb_class

        for (let i = 0; i <= note_max; i = i + step) {
            let ttBrut = 0
            for (let j = 0; j < liste_brute.length; j++) {
                if (
                    liste_brute[j] >= i &&
                        ((i + step < note_max && liste_brute[j] < i + step) ||
                            (i + step === note_max && liste_brute[j] <= i + step))
                ) {
                    ttBrut++
                }
            }
            tableBruteTotal.push(ttBrut)

            let ttFinale = 0
            for (let k = 0; k < liste_finale.length; k++) {
                if (
                    liste_finale[k] >= i &&
                        ((i + step < note_max && liste_finale[k] < i + step) ||
                            (i + step === note_max && liste_finale[k] <= i + step))
                ) {
                    ttFinale++
                }
            }
            tableFinaleTotal.push(ttFinale)

            // Parcours dynamique des ajustements pour alimentation du tableau des notes
            for (const a in liste_ajustements) {
                let ttAjustement = 0
                for (let m = 0; m < liste_ajustements[a].length; m++) {
                    if (
                        liste_ajustements[a][m] >= i &&
                            ((i + step < note_max && liste_ajustements[a][m] < i + step) ||
                                (i + step === note_max && liste_ajustements[a][m] <= i + step))
                    ) {
                        ttAjustement++
                    }
                }
                if (!table_ajustements[a]) {
                    table_ajustements[a] = []
                }
                table_ajustements[a].push(ttAjustement)
            }
        }

        return {
            brute: { dico: dicoNotesBrut, table: tableBruteTotal, liste: liste_brute },
            finale: { dico: dicoNotesfinale, table: tableFinaleTotal, liste: liste_finale },
            ajustements: { table: table_ajustements, liste: liste_ajustements },
            ecart_type_notes_brutes: ecart_type_notes_brutes,
            ecart_type_notes_finales: ecart_type_notes_finales,
            mediane_notes_brutes: mediane_notes_brutes,
            mediane_notes_finales: mediane_notes_finales,
            moyenne_notes_brutes: moyenne_notes_brutes,
            moyenne_notes_finales: moyenne_notes_finales,
            premier_quartile_notes_finales: premier_quartile_notes_finales,
            premier_quartile_notes_brutes: premier_quartile_notes_brutes,
            troisieme_quartile_notes_finales: troisieme_quartile_notes_finales,
            troisieme_quartile_notes_brutes: troisieme_quartile_notes_brutes,
            nb_note_max_brutes: nb_note_max_brutes,
            nb_note_max_finales: nb_note_max_finales,
            nb_note_min_brutes: nb_note_min_brutes,
            nb_note_min_finales: nb_note_min_finales
        }
    },
    getepreuveCorrectionCorrecteurById: (state: any): any => (id: number) => {
        return state.epreuveCorrectionCorrecteurs.filter((mat: any): boolean => mat.id === id)[0]
    },
    tableAjustementParams: (state: any): any => {
        const retour: any = []
        if (state.epreuveCorrectionResultatSelect && state.epreuveCorrectionResultatSelect.ajustements_params) {
            for (const item in state.epreuveCorrectionResultatSelect.ajustements_params) {
                if (state.epreuveCorrectionResultatSelect.ajustements_params[item].validated_at === undefined) {
                    state.epreuveCorrectionResultatSelect.ajustements_params[item].validated_at = null
                }
                retour.push({ ajustement: item, data: state.epreuveCorrectionResultatSelect.ajustements_params[item] })
            }
        }
        return retour
    },
    getAjustementParamsByLabel: (state: any): any => (label: string) => {
        if (state.epreuveCorrectionResultatSelect && state.epreuveCorrectionResultatSelect.ajustements_params) {
            for (const item in state.epreuveCorrectionResultatSelect.ajustements_params) {
                if (item === label) {
                    return state.epreuveCorrectionResultatSelect.ajustements_params[item]
                }
            }
        }
    },
    ajustementIsValidable: (): any => (indexAjustement: number, tableAjustementParams: Array<any>) => {
        if (indexAjustement > 0 && tableAjustementParams[indexAjustement - 1]) {
            if (tableAjustementParams[indexAjustement - 1].data.validated_at === null) {
                return false
            }
        }
        if (indexAjustement < tableAjustementParams.length && tableAjustementParams[indexAjustement + 1]) {
            if (tableAjustementParams[indexAjustement + 1].data.validated_at !== null) {
                return false
            }
        }
        return true
    },
    ajustementIsEditable: (): any => (indexAjustement: number, tableAjustementParams: Array<any>): boolean => {
        if (tableAjustementParams[indexAjustement]) {
            if (tableAjustementParams[indexAjustement].data.validated_at !== null) {
                return false
            }
        }
        if (indexAjustement > 0 && tableAjustementParams[indexAjustement - 1]) {
            if (tableAjustementParams[indexAjustement - 1].data.validated_at === null) {
                return false
            }
        }
        if (indexAjustement < tableAjustementParams.length && tableAjustementParams[indexAjustement + 1]) {
            if (tableAjustementParams[indexAjustement + 1].data.validated_at !== null) {
                return false
            }
        }
        return true
    },
    getAjustement: (state: any): any => (index: number): any => {
        if (state.epreuveCorrectionResultatSelect.epreuve_correction && state.epreuveCorrectionResultatSelect.epreuve_correction.phase.ajustements[index]) {
            return state.epreuveCorrectionResultatSelect.epreuve_correction.phase.ajustements[index]
        } else {
            return null
        }
    },
    getIndexAjustementPrec: (state: any): any => (index_actual: any): any => {
        let index_prec = null
        for (const a in state.epreuveCorrectionResultatSelect.epreuve_correction.phase.ajustements) {
            if (a === index_actual) {
                return index_prec
            } else {
                index_prec = a
            }
        }

        return index_prec
    }
}
