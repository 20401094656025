import * as API from '@/services/API'
import store from '@/store'

export default {
    async getGroupeExaminateur(groupeExaminateurId: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/groupe_examinateurs/${groupeExaminateurId}`)
    },
    async getGroupeExaminateurs(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/groupe_examinateurs`, { params: payload })
    },
    async updateGroupeExaminateur(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/groupe_examinateurs/${payload.id}`, payload)
    },
    async addGroupeExaminateur(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/groupe_examinateurs`, payload)
    },
    async deleteGroupeExaminateur(groupeExaminateur_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.delete(`/sessions/${session_id}/groupe_examinateurs/${groupeExaminateur_id}`)
    }
}
