import { UserInterface } from './User'

export enum Etat {
    ETAT_INVISIBLE,
    ETAT_VIERGE,
    ETAT_DEPOSE,
    ETAT_VALIDE,
    ETAT_REJETE,
}

export enum EtatDepot {
    NON_DEPOSER,
    DEPOT_EN_COURS,
    DEPOT_COMPLET,
}

interface EtatSpec {
    index: number,
    libelle: string,
    color: string
}

export function getEtatSpec(etat: Etat): EtatSpec {
    switch (etat) {
        case Etat.ETAT_INVISIBLE:
            return { index: 0, libelle: 'Invisible', color: 'tertiary' }
        case Etat.ETAT_VIERGE:
            return { index: 1, libelle: 'Vierge', color: 'secondary' }
        case Etat.ETAT_DEPOSE:
            return { index: 2, libelle: 'Déposé', color: 'info' }
        case Etat.ETAT_VALIDE:
            return { index: 3, libelle: 'Validé', color: 'success' }
        case Etat.ETAT_REJETE:
            return { index: 4, libelle: 'Rejeté', color: 'danger' }
        default:
            return { index: 1, libelle: 'Vierge', color: 'secondary' }
    }
}

export function getEtatSpecContrat(etat: Etat): EtatSpec {
    switch (etat) {
        case Etat.ETAT_INVISIBLE:
            return { index: 0, libelle: 'En création', color: 'tertiary' }
        case Etat.ETAT_VIERGE:
            return { index: 1, libelle: 'Publié', color: 'secondary' }
        case Etat.ETAT_DEPOSE:
            return { index: 2, libelle: "Signé par l'intervenant", color: 'info' }
        case Etat.ETAT_VALIDE:
            return { index: 3, libelle: 'Signé par le concours', color: 'success' }
        default:
            return { index: 1, libelle: 'Vierge', color: 'secondary' }
    }
}

export function getEtatForFilterSelect(etat: string): EtatSpec {
    switch (etat) {
        case 'ETAT_INVISIBLE':
            return { index: 0, libelle: 'Non Publié', color: 'light' }
        case 'ETAT_VIERGE':
            return { index: 1, libelle: 'Vierge', color: 'secondary' }
        case 'ETAT_DEPOSE':
            return { index: 2, libelle: 'Déposé', color: 'info' }
        case 'ETAT_VALIDE':
            return { index: 3, libelle: 'Validé', color: 'success' }
        case 'ETAT_REJETE':
            return { index: 4, libelle: 'Rejeté', color: 'danger' }
        default:
            return { index: 1, libelle: 'Vierge', color: 'secondary' }
    }
}

export interface DossierAdministratifInterface {
    id: number | 0,
    session_id: number | 0,
    user_id: number | 0,
    etat: Etat | 0,
    user: UserInterface | null,
    datas_perso: DatasPersoInterface,
    datas_situation: {
        version: number | null,
        situations: Array<DatasSituationInterface> | [],
        cotisations: Array<DatasCotisationInterface> | []
    },
    datas_pro: DatasProInterface
}

export interface DatasPersoInterface {
    civility: string | '',
    name: string | '',
    first_name: string | '',
    birth_name: string | '',
    birth_date: Date,
    birth_place: string | '',
    nationality: string | '',
    numero_secu: string | '',
    address: string | '',
    email: string | '',
    telephone: string | '',
    telephone_fixe: string | ''
}

export interface DatasProInterface {
    function_name: string | '',
    function_address: string | '',
    function_email: string | '',
    function_telephone: string | ''
}

export interface DatasSituationInterface {
    id: number,
    name: string,
    selected: boolean | false
}

export interface DatasCotisationInterface {
    id: number,
    name: string,
    selected: boolean | false,
    value: string | ''
}

