import { getError } from '@/utils/helpers'
import CandidatJobService from '@/services/CandidatJobService'
import { CandidatJobInterface, StateCandidatJobs } from '@/types/CandidatJob'


export const namespaced = true

function setJobCandidats(commit: Function, response: any) {
    commit('SET_JOB_CANDIDATS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_LOADING', false)
}

export const state: StateCandidatJobs = {
    candidatJobs: [],
    meta: null,
    links: null,
    loading: false,
    error: null
}

export const mutations = {
    RESET_STATE(state: StateCandidatJobs): void {
        Object.assign(state, {
            candidatJobs: [],
            meta: null,
            links: null,
            loading: false,
            error: null
        })
    },
    SET_JOB_CANDIDATS(state: any, candidatJobs: Array<CandidatJobInterface>): void {
        state.candidatJobs = candidatJobs
    },
    SET_MORE_JOB_CANDIDATS(state: any, candidatJobs: Array<CandidatJobInterface>): void {
        state.candidatJobs = state.candidatJobs.concat(candidatJobs)
    },
    SET_META(state: any, meta: Array<any>): void {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>): void {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean): void {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>): void {
        state.error = error
    }
}

export const actions = {
    getCandidatJobs({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatJobService.getCandidatJobs(params)
                .then((response) => {
                    setJobCandidats(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreCandidatJobs({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatJobService.getCandidatJobs(params)
                .then((response) => {
                    commit('SET_JOB_CANDIDAT_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    importCandidatJobs({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatJobService.importCandidatJobs(payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getDocument({ commit }: { commit: any }, params: { candidatJob_id: number; document_uuid: string }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            CandidatJobService.getDocument(params.candidatJob_id, params.document_uuid)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    candidatJobs: (state: any): CandidatJobInterface[] => {
        return state.candidatJobs
    },
    meta: (state: any): any => {
        return state.meta
    },
    links: (state: any): string | null => {
        return state.links
    },
    loading: (state: any): boolean => {
        return state.loading
    },
    error: (state: any): any => {
        return state.error
    }
}
