import { getError } from '@/utils/helpers'
import AffectationExaminateurService from '@/services/AffectationExaminateurService'
import {
    AffectationExaminateurGroupedSerieInterface,
    AffectationExaminateurInterface,
    StateAffectationExaminateurs
} from '@/types/AffectationExaminateur'
import CentreService from '@/services/CentreService'

export const namespaced = true

function setAffectationExaminateurs(commit: Function, response: any) {
    commit('SET_AFFECTATION_EXAMINATEURS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    // groupSeries(commit, response.data.data)
    commit('SET_LOADING', false)
}

export const state: StateAffectationExaminateurs = {
    affectationExaminateurs: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    affectationExaminateurSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateAffectationExaminateurs) {
        Object.assign(state, {
            affectationExaminateurs: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            affectationExaminateurSelect: {}
        })
    },
    SET_AFFECTATION_EXAMINATEURS(
        state: StateAffectationExaminateurs,
        affectationExaminateurs: Array<AffectationExaminateurInterface>
    ) {
        state.affectationExaminateurs = affectationExaminateurs
    },
    SET_MORE_AFFECTATION_EXAMINATEURS(state: any, affectationExaminateurs: Array<AffectationExaminateurInterface>) {
        state.affectationExaminateurs = state.affectationExaminateurs.concat(affectationExaminateurs)
    },
    SET_META(state: StateAffectationExaminateurs, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: StateAffectationExaminateurs, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: StateAffectationExaminateurs, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: StateAffectationExaminateurs, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: StateAffectationExaminateurs, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_AFFECTATION_EXAMINATEUR_SELECT(
        state: StateAffectationExaminateurs,
        affectationExaminateur: AffectationExaminateurInterface
    ) {
        state.affectationExaminateurSelect = affectationExaminateur
    },
    /**
     * met a jours les salles id des affectations
     * @param state
     * @param affectationExaminateurs tableau retourné par l'update sur le serveur (incomplet d'ou la mise a jours des donnée du store plutot qu'un remplacement)
     */
    UPDATE_AFFECTATION_EXAMINATEURS(
        state: StateAffectationExaminateurs,
        affectationExaminateurs: Array<AffectationExaminateurInterface>
    ) {
        for (let i = 0; i < state.affectationExaminateurs.length; i++) {
            for (let j = 0; j < affectationExaminateurs.length; j++) {
                if (state.affectationExaminateurs[i].id === affectationExaminateurs[j].id) {
                    state.affectationExaminateurs[i].salle_id = affectationExaminateurs[j].salle_id
                }
            }
        }
    }
}

export const actions = {
    getAffectationExaminateurs({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationExaminateurService.getAffectationExaminateurs(params)
                .then((response) => {
                    setAffectationExaminateurs(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getAffectationExaminateur({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationExaminateurService.getAffectationExaminateur(params.affectationExaminateur_id)
                .then((response) => {
                    commit('SET_AFFECTATION_EXAMINATEUR_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreAffectationExaminateurs({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationExaminateurService.getAffectationExaminateur(params.affectationExaminateur_id)
                .then((response) => {
                    commit('SET_AFFECTATION_EXAMINATEUR_SELECT', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateAffectationExaminateur({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationExaminateurService.updateAffectationExaminateur(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addAffectationExaminateur({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationExaminateurService.addAffectationExaminateur(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteAffectationExaminateur({ commit }: { commit: any }, affectationExaminateur_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationExaminateurService.deleteAffectationExaminateur(affectationExaminateur_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    /**
     * allege la liste des affectations avant des les envoyer
     */
    updateAfectationExaminateursSalles({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        commit('SET_ERROR', null)
        return new Promise((resolve, reject) => {
            const affectations = []
            for (let i = 0; i < params.affectationsGrouped.length; i++) {
                for (const key in params.affectationsGrouped[i].series) {
                    for (
                        let j = 0;
                        j < params.affectationsGrouped[i].series[key].affectationExaminateur_ids.length;
                        j++
                    ) {
                        affectations.push({
                            id: params.affectationsGrouped[i].series[key].affectationExaminateur_ids[j],
                            salle_id: params.affectationsGrouped[i].series[key].salle_id
                        })
                    }
                }
            }
            const payload = {
                centre_id: params.centre_id,
                salles: params.salles,
                affectations: affectations,
                salles_submit: params.salles_submit,
                salles_validate: params.salles_validate,
                commentaire_rejet: params.commentaire_rejet
            }
            CentreService.updateOrganisation(payload)
                .then((response) => {
                    commit('UPDATE_AFFECTATION_EXAMINATEURS', response.data.data.affectations)
                    commit('SET_LOADING', false)
                    resolve(response)

                    // commentaire_rejet{besoins_salles: "mon commentaire"}
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getAffectationRecapitulatif({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            AffectationExaminateurService.getAffectationRecapitulatif()
                .then((response) => {
                    // setDemandesRemboursement(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    affectationExaminateurs: (state: StateAffectationExaminateurs) => {
        return state.affectationExaminateurs
    },
    /**
     * regroupe les series dans les affectations en vue d'une utilisation dans la gestions des affectations / series
     * ne modifie pas de valeur dans le store, juste un reformatage des données pour uneutilisation plus aisée
     * @return une tableau de type affectationExaminateursSerieGrouped
     */
    affectationExaminateursSerieGrouped: (state: StateAffectationExaminateurs) => {
        const dico_distinct_ensemble: { [indice: string]: AffectationExaminateurGroupedSerieInterface } = {}
        for (let i = 0; i < state.affectationExaminateurs.length; i++) {
            let key = ''
            if (
                state.affectationExaminateurs[i].ensemble &&
                state.affectationExaminateurs[i].ensemble.type_ensemble === 0
            ) {
                key =
                    state.affectationExaminateurs[i].epreuve_correction_id +
                    '_' +
                    state.affectationExaminateurs[i].ensemble_id +
                    '_' +
                    state.affectationExaminateurs[i].numero
            } else {
                key =
                    state.affectationExaminateurs[i].epreuve_correction_id +
                    '_' +
                    state.affectationExaminateurs[i].ensemble_id
            }
            const distinctSeriesInEpreuveCorrection: any = {}
            if (!dico_distinct_ensemble[key]) {
                const ensemble_name = state.affectationExaminateurs[i].ensemble
                    ? state.affectationExaminateurs[i].ensemble.name +
                      '' +
                      (state.affectationExaminateurs[i].numero &&
                      state.affectationExaminateurs[i].numero > 1 &&
                      state.affectationExaminateurs[i].ensemble.type_ensemble === 0
                          ? ' ( ' + state.affectationExaminateurs[i].numero + ' )'
                          : '')
                    : ''
                let epreuveCorrection_name = state.affectationExaminateurs[i].epreuveCorrection
                    ? state.affectationExaminateurs[i].epreuveCorrection.name
                    : state.affectationExaminateurs[i].ensemble &&
                      state.affectationExaminateurs[i].ensemble.type_ensemble === 0 &&
                      state.affectationExaminateurs[i].ensemble.groupeEpreuve
                        ? state.affectationExaminateurs[i].ensemble.groupeEpreuve.name
                        : ''
                if (state.affectationExaminateurs[i].ensemble && state.affectationExaminateurs[i].ensemble.concour) {
                    epreuveCorrection_name =
                        state.affectationExaminateurs[i].ensemble.concour.name + ' ' + epreuveCorrection_name
                }

                if (
                    state.affectationExaminateurs[i].epreuveCorrection &&
                    state.affectationExaminateurs[i].epreuveCorrection.series &&
                    state.affectationExaminateurs[i].epreuveCorrection.series.length > 0
                ) {
                    for (let j = 0; j < state.affectationExaminateurs[i].epreuveCorrection.series.length; j++) {
                        distinctSeriesInEpreuveCorrection[
                            state.affectationExaminateurs[i].epreuveCorrection.series[j].serie_id
                        ] = true
                    }
                } else if (
                    state.affectationExaminateurs[i].ensemble &&
                    state.affectationExaminateurs[i].ensemble.type_ensemble === 0 &&
                    state.affectationExaminateurs[i].ensemble.groupeEpreuve &&
                    state.affectationExaminateurs[i].ensemble.groupeEpreuve.epreuves
                ) {
                    for (let k = 0; k < state.affectationExaminateurs[i].ensemble.groupeEpreuve.epreuves.length; k++) {
                        for (
                            let l = 0;
                            l < state.affectationExaminateurs[i].ensemble.groupeEpreuve.epreuves[k].series.length;
                            l++
                        ) {
                            distinctSeriesInEpreuveCorrection[
                                state.affectationExaminateurs[i].ensemble.groupeEpreuve.epreuves[k].series[l].serie_id
                            ] = true
                        }
                    }
                }
                dico_distinct_ensemble[key] = {
                    series: {},
                    tableSeries: [],
                    ensemble_name: ensemble_name,
                    distinctSeriesInEpreuveCorrection: distinctSeriesInEpreuveCorrection,
                    epreuveCorrection_name: epreuveCorrection_name,
                    user_name: state.affectationExaminateurs[i].user ? state.affectationExaminateurs[i].user.name : '',
                    user_id: state.affectationExaminateurs[i].user ? state.affectationExaminateurs[i].user.id : 0,
                    numero: state.affectationExaminateurs[i].numero
                }
            }
            if (!dico_distinct_ensemble[key].series['serie_' + state.affectationExaminateurs[i].serie_id]) {
                const serieKey = 'serie_' + state.affectationExaminateurs[i].serie_id
                dico_distinct_ensemble[key].tableSeries.push(serieKey as string)
                dico_distinct_ensemble[key].series[serieKey] = {
                    // affectationExaminateur_id: state.affectationExaminateurs[i].id,
                    affectationExaminateur_ids: [state.affectationExaminateurs[i].id],
                    serie_id: state.affectationExaminateurs[i].serie_id,
                    salle_id: state.affectationExaminateurs[i].salle_id
                }
            } else {
                dico_distinct_ensemble[key].series[
                    'serie_' + state.affectationExaminateurs[i].serie_id
                ].affectationExaminateur_ids.push(state.affectationExaminateurs[i].id)
            }
        }
        const affectationExaminateursSerieGrouped = []
        for (const key in dico_distinct_ensemble) {
            affectationExaminateursSerieGrouped.push(dico_distinct_ensemble[key])
        }
        return affectationExaminateursSerieGrouped
    },
    meta: (state: StateAffectationExaminateurs) => {
        return state.meta
    },
    links: (state: StateAffectationExaminateurs) => {
        return state.links
    },
    loading: (state: StateAffectationExaminateurs) => {
        return state.loading
    },
    error: (state: StateAffectationExaminateurs) => {
        return state.error
    },
    totalRows: (state: StateAffectationExaminateurs) => {
        return state.affectationExaminateurs.length
    },
    affectationExaminateurSelect: (state: StateAffectationExaminateurs) => {
        return state.affectationExaminateurSelect
    },
    check_salle_equipe_serie: () => (ensembles: any, series: any) => {
        const tab_errors: any = []
        // Parcours les ensembles
        for (const e in ensembles) {
            // Parcours les series dans un ensemble et regarde si un salle_id est présent
            for (const s in ensembles[e].series) {
                if (
                    ensembles[e].series[s].salle_id === null &&
                    ensembles[e].distinctSeriesInEpreuveCorrection[ensembles[e].series[s].serie_id]
                ) {
                    // Pas de salle affectées je recupére l'ensemble dans un tableau d'erreur pour affichage
                    tab_errors.push({
                        ensemble_name: ensembles[e].ensemble_name,
                        epreuve_correction: ensembles[e].epreuveCorrection_name,
                        numero: ensembles[e].numero,
                        serie: series.find((serie: any) => serie.id === ensembles[e].series[s].serie_id).name,
                        user_name: ensembles[e].user_name
                    })
                }
            }
        }

        return tab_errors
    }
}
