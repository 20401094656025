import { getError } from '@/utils/helpers'
import SerieService from '@/services/SerieService'
import { SerieInterface, StateSeries } from '@/types/Serie'

export const namespaced = true

function setSeries(commit: Function, response: any) {
    commit('SET_SERIES', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
}

export const state: StateSeries = {
    series: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    serieSelect: {}
}

export const mutations = {
    RESET_STATE(state: StateSeries) {
        Object.assign(state, {
            series: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            serieSelect: {}
        })
    },
    SET_SERIES(state: any, series: Array<SerieInterface>) {
        state.series = series
    },
    SET_MORE_SERIES(state: any, series: Array<SerieInterface>) {
        state.series = state.series.concat(series)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_SERIE_SELECT(state: any, serie: SerieInterface) {
        state.serieSelect = serie
    }
}

export const actions = {
    getSeries({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SerieService.getSeries(params)
                .then((response) => {
                    setSeries(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getSerie({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SerieService.getSerie(params.serie_id)
                .then((response) => {
                    commit('SET_SERIE_SELECT', response.data.data)
                    resolve(response)
                    commit('SET_LOADING', false)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreSeries({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SerieService.getSerie(params.serie_id)
                .then((response) => {
                    commit('SET_MORE_SERIES', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateSerie({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SerieService.updateSerie(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateSeries({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SerieService.updateSeries(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    setSeries(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addSerie({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SerieService.addSerie(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteSerie({ commit }: { commit: any }, serie_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            SerieService.deleteSerie(serie_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    series: (state: any) => {
        return state.series
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.series.length
    },
    serieSelect: (state: any) => {
        return state.serieSelect
    },
    isplanificationsLaunched: (state: any) => {
        let planificationsLaunched = false
        if (state.series) {
            for (const indexSerie in state.series) {
                if (state.series[indexSerie].planifie) {
                    planificationsLaunched = true
                    break
                }
            }
        }
        return planificationsLaunched
    },
    serieById: (state: any) => (id: number) => {
        return state.series.filter((serie: any) => serie.id === id)[0]
    },
    getNameSerieById: (state: any) => (id: number) => {
        const epreuve = state.series.filter((s: any) => s.id === id)[0]
        return epreuve.name
    }
}
