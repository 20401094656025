import { getError } from '@/utils/helpers'
import EtablissementService from '@/services/EtablissementService'
import { EtablissementInterface, StateEtablissements } from '@/types/Etablissement'
import Vue from 'vue'

export const namespaced = true

function setEtablissements(commit: Function, response: any) {
    commit('SET_ETABLISSEMENTS', response.data.data)
    commit('SET_META', response.data.meta)
    commit('SET_LINKS', response.data.links)
    commit('SET_TOTALROWS', response.data.data.length)
    commit('SET_LOADING', false)
    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
    commit('SET_LAST_PAGE', response.data.meta ? response.data.meta.last_page : 1)
}

export const state: StateEtablissements = {
    etablissements: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
    totalRows: 0,
    etablissementSelect: {},
    currentPage: 0,
    lastPage: 0
}

export const mutations = {
    RESET_STATE(state: StateEtablissements) {
        Object.assign(state, {
            etablissements: [],
            meta: null,
            links: null,
            loading: false,
            error: null,
            totalRows: 0,
            etablissementSelect: {},
            currentPage: 0,
            lastPage: 0
        })
    },
    SET_ETABLISSEMENTS(state: any, etablissements: Array<EtablissementInterface>) {
        state.etablissements = etablissements
    },
    SET_MORE_ETABLISSEMENTS(state: any, etablissements: Array<EtablissementInterface>) {
        state.etablissements = state.etablissements.concat(etablissements)
    },
    SET_META(state: any, meta: Array<any>) {
        state.meta = meta
    },
    SET_LINKS(state: any, links: Array<any>) {
        state.links = links
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<any>) {
        state.error = error
    },
    SET_TOTALROWS(state: any, totalRows: number) {
        state.totalRows = totalRows
    },
    SET_ETABLISSEMENT_SELECT(state: any, etablissement: EtablissementInterface) {
        state.etablissementSelect = etablissement
    },
    SET_CURRENT_PAGE(state: any, currentPage: number) {
        state.currentPage = currentPage
    },
    SET_LAST_PAGE(state: any, lastPage: number) {
        state.lastPage = lastPage
    },
    ADD_ETAB(state: any, etab: EtablissementInterface) {
        state.etablissements.push(etab)
    },
    MAJ_ETAB(state: any, etab: EtablissementInterface) {
        const index = state.etablissements.map((e: EtablissementInterface) => e.id).indexOf(etab.id)
        Vue.set(state.etablissements, index, etab)
    }
}

export const actions = {
    getEtablissements({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EtablissementService.getEtablissements(params)
                .then((response) => {
                    setEtablissements(commit, response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getMoreEtablissements({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EtablissementService.getEtablissements(params)
                .then((response) => {
                    // setEtablissements(commit, response)
                    commit('SET_MORE_ETABLISSEMENTS', response.data.data)
                    commit('SET_CURRENT_PAGE', response.data.meta ? response.data.meta.current_page : 1)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getEtablissement({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EtablissementService.getEtablissement(params.etablissement_id)
                .then((response) => {
                    commit('SET_ETABLISSEMENT_SELECT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    updateEtablissement({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EtablissementService.updateEtablissement(params)
                .then((response) => {
                    // 422
                    commit('SET_LOADING', false)
                    commit('MAJ_ETAB', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    addEtablissement({ commit }: { commit: any }, params: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EtablissementService.addEtablissement(params)
                .then((response) => {
                    commit('SET_LOADING', false)
                    commit('ADD_ETAB', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    deleteEtablissement({ commit }: { commit: any }, etablissement_id: number) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EtablissementService.deleteEtablissement(etablissement_id)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    importEtablissement({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EtablissementService.importEtablissement(payload)
                .then((response) => {
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    },
    getEtablissementsByAcademie({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            EtablissementService.getEtablissementsByAcademie(params.academie, params.type)
                .then((response) => {
                    setEtablissements(commit, response)
                    commit('SET_LOADING', false)
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    etablissements: (state: any) => {
        return state.etablissements
    },
    meta: (state: any) => {
        return state.meta
    },
    links: (state: any) => {
        return state.links
    },
    loading: (state: any) => {
        return state.loading
    },
    lastPage: (state: any) => {
        return state.lastPage
    },
    error: (state: any) => {
        return state.error
    },
    totalRows: (state: any) => {
        return state.etablissements.length
    },
    etablissementSelect: (state: any) => {
        return state.etablissementSelect
    },
    getEtablissementById: (state: any) => (etab_id: number) => {
        return state.etablissements.find((etablissement: any) => etablissement.id === etab_id)
    }
}
