import * as API from '@/services/API'

export default {
    async getConfigPhase(configPhaseId: number) {
        return await API.apiClient.get(`/configphases/${configPhaseId}`)
    },
    async getConfigPhases(payload: any) {
        return await API.apiClient.get('/configphases', { params: payload })
    },
    async updateConfigPhase(payload: any) {
        return await API.apiClient.put(`/configphases/${payload.id}`, payload)
    },
    async addConfigPhase(payload: any) {
        return await API.apiClient.post('/configphases', payload)
    },
    async deleteConfigPhase(configPhase_id: number) {
        return await API.apiClient.delete(`/configphases/${configPhase_id}`)
    }
}
