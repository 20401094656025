import * as API from '@/services/API'
import store from '@/store/index'
import { AxiosRequestConfig } from 'axios'

export default {
    getCandidats(payload: any) {
        const session_id = store.getters['auth/user_session_id']

        return API.apiClient.get(`/sessions/${session_id}/candidats`, { params: payload })
    },
    getCandidat(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/candidats/${payload.id}`)
    },
    updateCandidat(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/candidats/${payload.id}`, payload)
    },
    updateEnsembleCandidat(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(
            `/sessions/${session_id}/candidats/${payload.id}/ensemble/${payload.oldEnsembleOld_id}/new/${payload.newEnsemble_id}`,
            payload
        )
    },
    addCommentCandidat(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/comments/candidat/${payload.candidat_id}`, payload)
    },
    updateCommentCandidat(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(
            `/sessions/${session_id}/comments/candidat/${payload.candidat_id}/${payload.comment_id}`,
            payload
        )
    },
    deleteCommentCandidat(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(
            `/sessions/${session_id}/comments/candidat/${payload.candidat_id}/${payload.comment_id}`,
            payload
        )
    },
    updateCommentsCandidat(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${session_id}/multicomments/candidat/${payload.id}`, payload)
    },
    getDossiers(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/dossiers`, { params: payload })
    },
    updateDossiers(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/dossiers/${payload.dossier_id}`)
    },
    getDatasCandidat(payload: any) {
        // const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get('/datascandidats', { params: payload })
    },
    /** recuperation des candidats avec commentaires importants pour affichage du dashboardOraux  */
    getCandidatsWithImportantComments() {
        /* page=1&sort=user.name&direction=desc */
        const session_id = store.getters['auth/user_session_id']
        const params = {
            sort: 'updated_at',
            direction: 'desc'
        }
        return API.apiClient.get(`/sessions/${session_id}/comments/candidat`, { params: params })
    },
    getCandidatsPlanification(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/candidatplanifications`, { params: payload })
    },
    getEtatPrerequisRepartitionEquipes() {
        const user_session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${user_session_id}/candidats/tirage`)
    },
    lancementRepartitionEquipes() {
        const user_session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${user_session_id}/candidats/tirage`)
    },
    annulerRepartitionEquipes() {
        const user_session_id = store.getters['auth/user_session_id']
        return API.apiClient.delete(`/sessions/${user_session_id}/candidats/tirage`)
    },
    getPrevisionnelTransfere(publication_id: number, payload: any) {
        const user_session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${user_session_id}/candidats/previsionnelTransfere/${publication_id}`, { params: payload })
    },
    postTransfereDossier(publication_id: number, payload: any) {
        const user_session_id = store.getters['auth/user_session_id']
        return API.apiClient.post(`/sessions/${user_session_id}/candidats/transfere/${publication_id}`, payload)
    },
    getHistoriqueTransfert() {
        const user_session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${user_session_id}/candidats/historique`)
    },
    updatePortail(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.put(`/sessions/${session_id}/candidats/${payload.candidat_id}/portail`, payload)
    },
    getCandidatsAmenagements(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/candidatamenagements`, { params: payload })
    },
    getExportCandidatsAmenagements() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/candidatamenagements_export`, {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    },
    getPDFAmenagementsOral(candidat_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/fournitures_fmo/${candidat_id}`, {
            params: { format: 'b64', headers: { 'X-Request-Retry': 0 } }
        })
    },
    getPDFAmenagementsEcrit(candidat_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/fournitures_fme/${candidat_id}`, {
            params: { format: 'b64', headers: { 'X-Request-Retry': 0 } }
        })
    },
    getCandidatsCdc(params: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/centres/${params.centre_id}/candidats`, { params: params })
    },
    getCandidatsCdcExport(centre_id: any) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/centres/${centre_id}/candidats_export`, {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    },
    getExportCandidatsAdmissibles() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/admissibles`, {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    },
    getExportCandidatsAdmission(banque_id: number, phase_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/candidats_export_recu/${banque_id}/${phase_id}`, {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    },
    async getPrevionnelCandidatEpreuves(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/previsionnel_candidat_epreuves`, { params: payload })
    },
    async updatePrevionnelCandidatEpreuves(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.put(`/sessions/${session_id}/previsionnel_candidat_epreuves`, payload)
    },
    getDemandeAmenagementCandidatEcritExport() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/demande_amenagement_candidat_ecrit_export`, {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    },
    getDemandeAmenagementCandidatOralExport() {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/demande_amenagement_candidat_oral_export`, {
            headers: {
                'X-Request-Retry': 0,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        })
    },
    getRelevePdf(candidat_id: number, concour_id: number, phase_id: number) {
        const session_id = store.getters['auth/user_session_id']
        return API.apiClient.get(`/sessions/${session_id}/candidats/${candidat_id}/concours/${concour_id}/phases/${phase_id}/releve?format=b64`)
    },
    exportCandidatsConcours() {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return API.apiClient.get(`/sessions/${session_id}/export_candidats_concours`, params)
    },
    exportCandidatsInscrits() {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return API.apiClient.get(`/sessions/${session_id}/candidats_export`, params)
    },
    async exportTemplateSeries() {
        const session_id = store.getters['auth/user_session_id']
        const params: AxiosRequestConfig = {
            headers: {
                'X-Request-Retry': 0,
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        }
        return await API.apiClient.get(`/sessions/${session_id}/candidats/exporttemplateseries`, params)
    },
    async importSeries(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const formData = new FormData()
        formData.append('document', payload)
        return await API.apiClient.post(`/sessions/${session_id}/candidates_series_import`, formData)
    },
    async import(payload: any) {
        const session_id = store.getters['auth/user_session_id']
        const formData = new FormData()
        formData.append('document', payload)
        return await API.apiClient.post(`/sessions/${session_id}/candidats/import`, formData) // , config)
    },
    async retryPublication(candidat_id: number, phase_id: number, payload: any) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.post(`/sessions/${session_id}/candidats/${candidat_id}/retry/${phase_id}`, payload)
    },
    async getConvocationCandidat(candidat_id : number, phase_id : number) {
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(`/sessions/${session_id}/candidats/${candidat_id}/convocation/${phase_id}?format=b64`)
    }
}
