import store from '@/store/index'
import * as API from '@/services/API'

export default {
    async getDefinitionDesBarresStatistiquesEpreuves(payload: any) {
        const concour_id = payload.concour_id
        const phase_id = payload.phase_id
        const session_id = store.getters['auth/user_session_id']
        return await API.apiClient.get(
            `/sessions/${session_id}/concours/${concour_id}/phases/${phase_id}/epreuveresultats`,
            { params: payload.params }
        )
    }
}
